import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import { useCookies } from "react-cookie";
import moment from "moment";
import {
  Drawer,
  Typography,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import styles from "../TableAllProjectInProfile.module.css";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import TableCustom from "components/table/TableCustom";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import { Search, FilterAlt, Add } from "@mui/icons-material";
import helper from "utils/helper";
import TableActiveIcon from "components/tableActiveIcon";
import { getAllCarrierInProject } from "utils/api/project/project";
import ModalAddCarrierInProject from "./ModalAddCarrierInProject";
import SelectRowValue from "components/button/SelectRowValue";

const TableAllCarrierInProject = () => {
  const { user_id, project_id, user_code, project_code, profile_id } =
    useParams();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [mainInfo, setMainInfo] = useState({});
  const [openAddCarrier, setOpenAddCarrier] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));

  const [filter, setFilter] = useState({
    sort_by: "updated_at",
    search_by: "project_name",
    search_val: "",
    start_date: dateFrom,
    end_date: dateTo,
    page: 1,
    per_page: 5,
    total: 0,
  });

  const columns = [
    {
      id: "created_at",
      label: "วันที่สร้างรายการ",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.created_at),
    },
    {
      id: "updated_at",
      label: "วันที่แก้ไขข้อมูลล่าสุด",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.updated_at),
    },
    {
      id: "user_code",
      label: "รหัสผู้ใช้งาน",
      align: "center",
      format: (value) => value?.profile?.user_info?.user_code,
    },
    {
      id: "name",
      label: "ชื่อโปรไฟล์",
      align: "left",
      format: (value) => value?.profile?.company_name,
    },
    {
      id: "active",
      label: "สถานะใช้งาน",
      align: "center",
      width: "10%",
      format: (value) => {
        return <TableActiveIcon active_status={value.active} />;
      },
    },
  ];

  const MenuProject = [
    {
      label: "รายละเอียดโครงการ",
      path: "",
      //   header: "ข้อมูลทั่วไป",
    },
    {
      label: "แม่แบบงาน",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/template`,
    },
    {
      label: "ควาามคืบหน้าโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/project-progression`,
    },
    {
      label: "ควาามคืบหน้าในการหาผู้ขนส่ง",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/carrier-progression`,
    },
    {
      label: "ผู้ขนส่งในโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/carrier-in-project`,
      active: true,
    },
    {
      label: "ผู้รับผิดชอบโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/project-admin`,
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${project_code}`,
      path: "",
    },
    {
      label: "ผู้ขนส่งในโครงการ",
      path: "",
    },
  ];

  const filterGeneral = [
    {
      name: "สถานะใช้งาน",
      value: "active",
      options: [
        { value: true, name: "ใช้งาน" },
        { value: false, name: "ระงับ" },
      ],
    },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "project_name", name: "ชื่อโครงการ" },
                { value: "company_name", name: "ชื่อบริษัท" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"project_name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "13px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "20px",
              gap: "20px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterProject = { ...filter, page: 1 };
                getCarrierInProject(filterProject);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
            />
            {/* <FieldSelect
              name={"จำนวนแถว"}
              options={[
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 20, name: "20" },
                { value: 50, name: "50" },
                { value: 100, name: "100" },
              ]}
              defaultValue={5}
              value={filter.per_page}
              onChange={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
              closePaddingBottom={true}
            /> */}
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    getCarrierInProject(filter);
  }, []);

  useEffect(() => {
    console.log("dataDetail", dataDetail);
  }, [dataDetail]);

  const getCarrierInProject = async (_filter) => {
    setLoading(true);
    await getAllCarrierInProject(project_id, _filter)
      .then((response) => {
        if (response.data.code === 1000) {
          setLoading(false);
          setDataDetail(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          window.location.reload();
        }}
      />
      <Drawer
        anchor="right"
        open={advanceFilter}
        onClose={() => {
          setAdvanceFilter(false);
          const filterProject = { ...filter, page: 1 };
          getCarrierInProject(filterProject);
        }}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
            <div className="general">
              {filterGeneral &&
                filterGeneral.map((value) => {
                  return (
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filter[value?.value]}
                      // defaultValue={[]}
                      onChange={(val) => {
                        setFilter({ ...filter, [value?.value]: val });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      // multiSelect={true}
                      // showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  );
                })}
            </div>
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() =>
                  setFilter({
                    sort_by: "updated_at",
                    search_by: "project_name",
                    search_val: "",
                    start_date: dateFrom,
                    end_date: dateTo,
                    page: 1,
                    per_page: 5,
                    total: 0,
                    active: [],
                  })
                }
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Container>
        <SubMenu
          menus={MenuProject}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={dataDetail}
            pagination={filter}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
            onSelectedRow={(val) => console.log(val)}
            href={`/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/carrier-in-project/detail/`}
          />
        </div>
        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() => setOpenAddCarrier(true)}
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>
      </Container>
      {openAddCarrier ? (
        <ModalAddCarrierInProject
          open={openAddCarrier}
          handleClose={() => setOpenAddCarrier(false)}
          alertSuccess={() => {
            setSuccessAlert(true);
          }}
          alertError={(e) => {
            setErrorAlert(e);
          }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default TableAllCarrierInProject;
