import React, { useEffect, useState } from "react";
import "./workPublicDetail.css";
import { useParams } from "react-router-dom";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import SwipeComponent from "./swipeComponent";
import MapWithCall from "components/map/MapWithCall";
import HelperMap from "utils/helperMap";
import API from "../../utils/api/work/work";
import helper from "utils/helper";
import LoadingBackground from "components/loading/LoadingBackground";
import WorkStatus from "components/work/workStatus";
import Line from "components/layout/line";
import SelectProduct from "components/work/selectProduct";
import SelectService from "components/work/selectService";
import SelectDateTime from "components/work/selectDateTime";
import SelectLocation from "components/work/selectLocation";
import SelectTruck from "components/work/selectTruck";
import SelectCarrier from "components/work/selectCarrier";
import SelectRemark from "components/work/selectRemark";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const api = API.create();
const WorkPublicDetail = () => {
  const { id_secret } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(false);
  const [call, setCall] = useState();
  const [markerStatus, setMarkerStatus] = useState();
  const [driverLoc, setDriverLoc] = useState();
  const [workDetail, setWorkDetail] = useState();
  const [datetime, setDatetime] = useState({});
  const [socketId, setSocketId] = useState("test");
  const [loadMap, setLoadmap] = useState(false);
  const [deposit, setDeposit] = useState({
    has_deposit: 0,
    deposit_amount: "",
  });
  const [service, setService] = useState({
    has_document_return: false,
    has_labor: false,
    labor_load_amount: 0,
    labor_unload_amount: 0,
  });
  const [distanceTime, setDistanceTime] = useState(null);
  const [location, setLocation] = useState([
    {
      type: 0,
      name: "",
      lat: "",
      long: "",
      address: "",
      district: "",
      subdistrict: "",
      province: "",
      postal_code: "",
      contact_name: "",
      contact_phone: "",
      place_name: "",
      place_type_id: 0,
      remark: "",
      document: "",
      document_file: [],
      product: [
        {
          name: "",
          product_id: "",
          product_name: "",
          product_size: "",
          product_width: "",
          product_length: "",
          product_height: "",
          product_volume: "",
          product_weight: "",
          product_count_type: "",
          product_amount: "",
          product_image: [null, null, null, null, null],
          product_image_per_route: [],
        },
      ],
    },
    {
      type: 1,
      name: "",
      lat: "",
      long: "",
      address: "",
      district: "",
      subdistrict: "",
      province: "",
      postal_code: "",
      contact_name: "",
      contact_phone: "",
      place_name: "",
      place_type_id: 0,
      remark: "",
      document: "",
      document_file: [],
      product: [
        {
          name: "",
          product_id: "",
          product_name: "",
          product_size: "",
          product_width: "",
          product_length: "",
          product_height: "",
          product_volume: "",
          product_weight: "",
          product_count_type: "",
          product_amount: "",
          product_image: [null, null, null, null, null],
          product_image_per_route: [],
        },
      ],
    },
  ]);

  useEffect(() => {
    if (loadMap) {
      setMarker();
      if (
        workDetail?.workTrackings.filter(
          (e) => e.work_status === 5 || e.work_status === 6
        ).length > 0
      ) {
        getDriver();
        setInterval(() => {
          getDriver();
        }, 20000);
      }
    }
  }, [loadMap]);

  useEffect(() => {
    console.log("workDetail", workDetail);
    console.log("loadMap", loadMap);
  }, [loadMap, workDetail]);
  useEffect(() => {
    getWorkDetail();
  }, []);

  const getWorkDetail = () => {
    setLoading(true);
    let body = {
      id_secret: id_secret,
    };
    api
      .getWorkDetailPublic(body)
      .then(async (response) => {
        console.log("getWorkDetail", response);
        let data = response.data.results;
        console.log(response.data.results);

        // console.log(bread);
        response.data.results.truck_type.image =
          helper.pathImage() + response.data.results.truck_type.image;
        setWorkDetail(response.data.results);
        data.workTrackings = data.workTrackings.reverse();
        let loc = await helper.workDetailProduct(
          response.data.results.workLocations
        );
        setService({
          has_document_return: data.has_document_return,
          has_labor: data.has_labor,
          labor_load_amount: data.labor_load_amount ?? 0,
          labor_unload_amount: data.labor_unload_amount ?? 0,
        });
        setLocation(loc);
        setDatetime({
          date: {
            startDate: data.load_date_start,
            endDate: data.load_date_end,
          },
          time: {
            time_start: data.load_time_start.slice(0, 5),
            time_end: data.load_time_end.slice(0, 5),
          },
        });
        setDistanceTime({
          allInterval: data.total_duration,
          allDistance: data.total_distance,
          orderDistance: data.total_distance,
          orderInterval: helper.secToText(data.total_duration),
        });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const getDriver = async () => {
    await api
      .getDriverLoc({
        work_id: workDetail?.id,
        client_id: socketId,
      })
      .then((response) => {
        console.log("getDriverLoc", response.data.results);
        setDriverLoc({
          lat: response.data.results.lat,
          long: response.data.results.long,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setMarker = async () => {
    let has_marker = false;
    let list = [];
    for (const { ele, index } of location.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.lat !== "" && ele.long !== "") {
        // console.log(ele.lat, ele.long);
        const dot = await new window.longdo.Marker(
          { lat: ele.lat, lon: ele.long },
          {
            draggable: false,
            clickable: true,
            weight: window.longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class=""><img src="' +
                (ele.type === 0
                  ? require("../../assets/image/work/pickup_pin.png")
                  : require("../../assets/image/work/send_pin.png")) +
                '" style="height: 30px"/></div>',
            },
          }
        );
        list.push(dot);
      }
    }
    setCall(list);
    let mark_status = await HelperMap.getMarkerFromStatus(
      workDetail?.workTrackings
    );
    setMarkerStatus(mark_status);
    console.log("mark_status", mark_status);
  };
  return (
    <div className={!isMobile ? "containerForWeb" : "container"}>
      {loading ? <LoadingBackground /> : null}
      <div style={{ padding: 15, fontSize: 20, fontWeight: 500 }}>
        {workDetail?.work_code ?? ""}
      </div>
      <MapWithCall call={call} marker={markerStatus} driver={driverLoc} />
      <SwipeComponent
        onSwipe={() => {
          if (!loadMap) {
            setLoadmap(true);
          }
        }}
        headerContent={
          <div className="headSwipe">
            <div
              style={{
                width: 50,
                height: 5,
                backgroundColor: "#8ca3ba",
                position: "absolute",
                top: 2.5,
                transform: "translate(-50%, -50%)",
                borderRadius: "0px 0px 10px 10px",
                left: "50%",
              }}
            ></div>
            <div>ระยะทางประมาณ {distanceTime?.orderDistance ?? 0} กม.</div>
            <div>
              ระยะเวลาเดินทางประมาณ {distanceTime?.orderInterval ?? "- ชั่วโมง"}
            </div>
          </div>
        }
      >
        {workDetail && (
          <div>
            <div style={{ opacity: 0.5, textAlign: "center" }}>ติดตามสถานะ</div>
            <Line opacity={0.5} />
            <WorkStatus data={workDetail?.workTrackings ?? null} />
            <Line opacity={0.5} />
            <div style={{ pointerEvents: "none" }}>
              <div
                style={{ padding: 13, textAlign: "center", fontWeight: 500 }}
              >
                รายละเอียดงาน
              </div>
              <SelectLocation value={location} is_detail />
              <Line />
              <SelectTruck value={workDetail?.truck_type} is_detail />
              <Line />
              <SelectProduct
                value={location.length > 0 ? location[0].product : null}
                is_detail
              />
              <Line />
              {service?.has_document_return || service?.has_labor ? (
                // <SelectService
                //   value={service}
                //   is_detail
                //   truck={workDetail?.truck_type}
                //   //   service_price={price}
                // />
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    textAlign: "center",
                    padding: "10px 0px 10px 0px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "5px 10px 5px 10px",
                    }}
                  >
                    <img
                      src={require("../../assets/image/work/service.png")}
                      height={40}
                      alt={"service"}
                    />
                  </div>
                  <div style={{ flex: 1, textAlignLast: "start" }}>
                    <div
                      style={{
                        fontWeight: 500,
                        opacity:
                          service.has_document_return || service.has_labor
                            ? 1
                            : 0.5,
                      }}
                    >
                      {"บริการเสริม"}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        opacity:
                          service.has_document_return || service.has_labor
                            ? 1
                            : 0.3,
                      }}
                    >
                      {service.has_document_return || service.has_labor ? (
                        <div>
                          {service.has_document_return ? (
                            <div>ต้องการส่งเอกสารนำกลับ</div>
                          ) : null}
                          {service.has_labor &&
                          service.labor_load_amount > 0 ? (
                            <div>
                              ต้องการคนงานยกสินค้าขึ้น{" "}
                              {service.labor_load_amount} คน
                            </div>
                          ) : null}
                          {service.has_labor &&
                          service.labor_unload_amount > 0 ? (
                            <div>
                              ต้องการคนงานยกสินค้าลง{" "}
                              {service.labor_unload_amount} คน
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        "ต้องการส่งเอกสารนำกลับ ต้องการคนงานยกสินค้าขึ้นลง"
                      )}
                    </div>
                  </div>
                  <div style={{ alignSelf: "center" }}>
                    <ArrowForwardIosIcon
                      sx={{ color: "#121f43", opacity: 0.3 }}
                    />
                  </div>
                </div>
              ) : null}

              <Line />
              <SelectDateTime value={datetime} is_detail />
              <Line />
              {workDetail?.carrier ? (
                <SelectCarrier
                  carrier={workDetail?.carrier}
                  driver={workDetail?.driver}
                  truck={
                    workDetail?.workTrackings?.filter(
                      (e) => e.work_status === 4
                    ).length > 0
                      ? workDetail?.workTrackings?.filter(
                          (e) => e.work_status === 4
                        )[0].workTrackingDetail.truck
                      : null
                  }
                />
              ) : null}
              <Line />
              {workDetail?.work_remark && workDetail?.work_remark !== "" ? (
                <div>
                  <SelectRemark value={workDetail?.work_remark} is_detail />
                  <Line />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </SwipeComponent>
    </div>
  );
};

export default WorkPublicDetail;
