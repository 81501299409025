import instance from "../../axiosInstance";

export const getAllService = async (params = {}) => {
  try {
    return await instance.get(`api/master/service`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllProductType = async (params = {}) => {
  try {
    return await instance.get(`api/master/product-type`, {
      params: { ...params, sort_field: ["order_number"], sort_order: [1] },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllOptional = async () => {
  try {
    return await instance.get(`api/master/optional`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllQualification = async () => {
  try {
    return await instance.get(`api/master/qualification`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllBank = async () => {
  try {
    return await instance.get(`api/master/bank`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllAssociation = async () => {
  try {
    return await instance.get(`api/master/association`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllCarrier = async () => {
  return await instance.get(`api/master/getAllCarrier`);
};

export const getAllShipper = async () => {
  return await instance.get(`api/master/getAllShipper`);
};

export const getAllProject = async () => {
  return await instance.get(`api/master/getAllProject`);
};

export const getAllPlatform = async () => {
  try {
    return await instance.get(`api/master/platform`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getTripStatus = async () => {
  try {
    return await instance.get(`/api/master/trip-status`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getWorkTradeStatus = async () => {
  try {
    return await instance.get(`/api/master/work-trade-status`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
export const getTradePaymentStatus = async () => {
  try {
    return await instance.get(`/api/master/trade-payment-status`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
