import React from "react";
import { CircularProgress } from "@mui/material";

const LoadingBackground = () => {
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.3)",
        display: "flex",
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 100,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};

export default LoadingBackground;
