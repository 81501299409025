import SubMenu from "components/layout/SubMenu.js";
import SubMenuCustom from "components/layout/SubMenu.js/SubMenuCustom";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import "./TemplateWork.css";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import API from "utils/api/work/work";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import helper from "utils/helper";
import InputUpload from "components/input/inputUpload";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import io from "socket.io-client";
import { styled } from "@mui/material/styles";
import TableCustom from "components/table/TableCustom";
import InputNormal from "components/input/inputNormal";
import Line from "components/layout/line";
const api = API.create();

const columns = [
  {
    id: "no",
    label: "ลำดับงาน",
    align: "center",
    //   width: "13%",
    format: (value) => value.no,
  },
  {
    id: "row",
    label: "ลำดับแถวที่ผิดพลาด",
    align: "center",
    //   width: "13%",
    format: (value) => value.row,
  },
  {
    id: "column",
    label: "คอลัมน์ที่ผิดพลาด",
    align: "center",
    //   width: "13%",
    format: (value) => value.column,
  },
  {
    id: "error",
    label: "ข้อผิดพลาด",
    align: "center",
    //   width: "13%",
    format: (value) => value.error,
  },
  {
    id: "example",
    label: "ตัวอย่างข้อมูล",
    align: "center",
    //   width: "13%",
    format: (value) => value.example,
  },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 40,
  borderRadius: 10,
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(18, 31, 67, 0.3)",
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#121f43" : "#308fe8",
  },
}));

export default function ImportTemplate(props) {
  const { refresh = () => {} } = props;
  const [file, setFile] = useState();
  const [socketId, setSocketId] = useState();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [returnData, setReturnData] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm({});
  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_ENPOINT, {
      auth: { Authorization: helper.getCookie("access_token") },
    }); // Replace with your backend URL
    // Event handler for receiving messages from the server
    socket.on("message", (data) => {
      console.log("Received message from server:", data);
      console.log("socket", socket.id);
      setSocketId(socket.id);
      // Handle the message as needed in your React component
    });
    socket.on("uploading", (data) => {
      console.log("uploading:", data);
      setProgress(data);
      //   console.log("socket", socket.id);
      // setSocketId(socket.id);
      // Handle the message as needed in your React component
    });
    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  const importWork = (data) => {
    console.log("data", data);
    setUploading(true);
    setProgress(0);
    let formData = new FormData();
    formData.append("import_work", data.import_work.file);
    formData.append("shipper_fuel_rate_start", data.shipper_fuel_rate_start);
    formData.append("shipper_fuel_rate_end", data.shipper_fuel_rate_end);
    formData.append("carrier_fuel_rate_start", data.carrier_fuel_rate_start);
    formData.append("carrier_fuel_rate_end", data.carrier_fuel_rate_end);
    formData.append("client_id", socketId);

    api
      .importTemplate(formData)
      .then((response) => {
        setReturnData(response.data.results);
        let filter = response.data.results.filter((e) => !e.success);

        let table = [];
        filter.forEach((e) => {
          e.result.forEach((ele) => {
            table.push({ no: e.no, ...ele });
          });
        });
        console.log("filter.", filter);
        setTotal(filter.length);
        setUploading(false);
        setData(table);
        console.log("table", table);
        if (filter.length === 0) {
          Swal.fire({
            // position: 'top-end',
            icon: "success",
            title: "บันทึก Template เสร็จสิ้น",
            // text: "เลขที่งาน " + response.data.results,
            showConfirmButton: false,
            timer: 1500,
            willClose: () => {
              refresh();
            },
          });
        }
      })
      .catch((e) => {
        setUploading(false);
        Swal.fire({
          timer: 2000,
          title: "error: " + e.response.data.code,
          text:
            e.response.data.description?.message ?? e.response.data.description,
          icon: "error",
          showConfirmButton: false,
        });
        console.log(e);
      });
  };

  const downloadTemplate = async () => {
    // var link = document.createElement("a");
    // link.download = "test";
    // link.href =
    //   process.env.REACT_APP_API_ENPOINT +
    //   "api/work/download-template-import-work";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    await api
      .downloadTemplateTemplate()
      .then((response) => {
        console.log(response);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "TemplateImportTemplate.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="post-work-page">
      <div className="template-work-body">
        <div className="post-work-body-flex">
          <div className="post-work-box">
            <div style={{ textAlign: "center" }}>สำหรับลูกค้า</div>
            <Line width={2} />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันเริ่มต้น*"
                  onChange={onChange}
                  value={value}
                  error={errors.shipper_fuel_rate_start}
                  type="number"
                />
              )}
              name="shipper_fuel_rate_start"
              defaultValue=""
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันสิ้นสุด*"
                  onChange={onChange}
                  value={value}
                  error={errors.shipper_fuel_rate_end}
                  type="number"
                />
              )}
              name="shipper_fuel_rate_end"
              defaultValue=""
            />

            <div style={{ textAlign: "center", marginTop: 20 }}>
              สำหรับผู้ขนส่ง
            </div>
            <Line width={2} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันเริ่มต้น*"
                  onChange={onChange}
                  value={value}
                  error={errors.carrier_fuel_rate_start}
                  type="number"
                />
              )}
              name="carrier_fuel_rate_start"
              defaultValue=""
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันสิ้นสุด*"
                  onChange={onChange}
                  value={value}
                  error={errors.carrier_fuel_rate_end}
                  type="number"
                />
              )}
              name="carrier_fuel_rate_end"
              defaultValue=""
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputUpload
                  id={"import-work" + Math.random(10)}
                  value={value}
                  label={"ไฟล์นำเข้า*"}
                  onChange={onChange}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  error={errors.import_work}
                />
              )}
              name="import_work"
              defaultValue=""
            />
            <Button
              type="submit"
              fullWidth
              variant="outline"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{
                borderStyle: "solid",
                borderWidth: 1,
                color: "#8ca3ba",
                borderColor: "#8ca3ba",
                height: 50,
              }}
              color="secondary"
              onClick={() => {
                downloadTemplate();
              }}
            >
              ดาวน์โหลดแม่แบบไฟล์นำเข้า
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              color="secondary"
              onClick={handleSubmit(importWork)}
              // disabled={!file || uploading}
            >
              บันทึก
            </Button>
            {uploading ? (
              <div style={{ position: "relative", marginTop: 20 }}>
                <BorderLinearProgress variant="determinate" value={progress} />
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 8,
                    color: "white",
                    textAlign: "center",
                  }}
                  className="import-work-progress"
                >
                  {/* <span style={{ color: progress >= 50 ? "white" : "#121f43" }}> */}
                  {progress}
                  {/* </span>
                  <span style={{ color: progress >= 52 ? "white" : "#121f43" }}> */}
                  {"%"}
                  {/* </span> */}
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ flex: 1 }}>
            <TableCustom
              columns={columns}
              dataSource={data}
              pagination={{
                page: page,
                per_page: 5,
                total: total,
              }}
              setPagination={(val) => {
                setPage(val.page);
              }}
              //   onSelectedRow={(val) => console.log(val)}
              //   href={`/user/`}
              // checkBox={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
