import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeCustomization from "themes/mui";
import antdTheme from "themes/antd";
import { ConfigProvider } from "antd";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "components/context/AuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ConfigProvider theme={antdTheme}>
      <ThemeCustomization>
        <CookiesProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </CookiesProvider>
      </ThemeCustomization>
    </ConfigProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
