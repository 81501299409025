import React, { useEffect, useState } from "react";
import Loading from "components/loading/Loading";
import SubMenu from "components/layout/SubMenu.js";
import { Grid, Avatar, TextField, InputAdornment } from "@mui/material";
import { Search, RotateRight } from "@mui/icons-material";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSelect from "components/field/FieldSelect";
import API from "utils/api/work/work";
import "./PostWork.css";
const api = API.create();
const menus = [
  {
    label: "ข้อมูลงาน",
    path: "/work",
    active: true,
  },
  {
    label: "ติดตามงานทั้งระบบ",
    path: "/work-tracking",
  },
];

const breadcrumbs = [
  {
    label: "ข้อมูลงาน",
    path: null,
  },
];
const TrackingWork = () => {
  const [loading, setLoading] = useState(false);
  const [filterWork, setFilterWork] = useState({
    start_date: "",
    end_date: "",
    search_by: "",
    search_val: "",
  });
  const [allWork, setAllWork] = useState(null);

  useEffect(() => {
    console.log("allWork", allWork);
  }, [allWork]);

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className="gridItem" item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterWork?.start_date ?? "",
                end_date: filterWork?.end_date ?? "",
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterWork({
                  ...filterWork,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={"gridItem"} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "company_name", name: "ชื่อโปรไฟล์ของผู้ประกาศงาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"name"}
              value={filterWork.search_by}
              onChange={(val) =>
                setFilterWork({ ...filterWork, search_by: val })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={"gridItem"} item xs={12} sm={12} md={12} lg={5.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "13px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterWork.search_val ? filterWork.search_val : ""}
              onChange={(val) =>
                setFilterWork({ ...filterWork, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={1.5}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                const filter = { ...filterWork };
                countWork(filter);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                const filter = { ...filterWork };
                countWork(filter);
              }}
            >
              <RotateRight sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  //get countWork for first time
  useEffect(() => {
    countWork(filterWork);
  }, []);

  //get countWork every 1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      countWork(filterWork);
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(interval);
  }, [filterWork, api]);

  const countWork = async (_filter) => {
    console.log("countWork", _filter);
    setLoading(true);
    await api
      .countAllWork(_filter)
      .then((res) => {
        setLoading(false);
        console.log("count all work", res);
        setAllWork(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <div style={{ height: "90vh" }}>
      {loading && <Loading />}
      <div className="post-work-body">
        <div className="post-work-body-flex">
          <div style={{ width: 400 }}>
            <SubMenu menus={menus} />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <SubMenu breadcrumbs={breadcrumbs} />
          </div>
        </div>
        {filterBar()}
        <div
          style={{
            padding: "20px 0",
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ maxWidth: "70vw" }}
          >
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FFC675",
                }}
              >
                <div className="count-number">{allWork?.find_truck ?? 0}</div>
                <div className="status-work">กำลังหารถ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">{allWork?.accept ?? 0}</div>
                <div className="status-work">รับงานแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">{allWork?.assign ?? 0}</div>
                <div className="status-work">กำหนดรถและคนขับแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">
                  {allWork?.driver_accept ?? 0}
                </div>
                <div className="status-work">คนขับรับทราบงานแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">{allWork?.driving ?? 0}</div>
                <div className="status-work">ออกเดินทางแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">{allWork?.at_origin ?? 0}</div>
                <div className="status-work">รถกำลังถึงจุดรับสินค้า</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">
                  {allWork?.receive_product ?? 0}
                </div>
                <div className="status-work">รับสินค้าแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">
                  {allWork?.at_destination ?? 0}
                </div>
                <div className="status-work">รถกำลังถึงจุดส่งสินค้า</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">{allWork?.send_product ?? 0}</div>
                <div className="status-work">ส่งสินค้าแล้ว</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#121F43",
                }}
              >
                <div className="count-number">
                  {allWork?.wait_doc_return ?? 0}
                </div>
                <div className="status-work">รอจัดส่งเอกสารนำกลับ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8C8DFF",
                }}
              >
                <div className="count-number">
                  {allWork?.sending_doc_return ?? 0}
                </div>
                <div className="status-work">กำลังส่งเอกสารนำกลับ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#27CE88",
                }}
              >
                <div className="count-number">{allWork?.finish ?? 0}</div>
                <div className="status-work">เสร็จสิ้นงาน</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8CA3BA",
                }}
              >
                <div className="count-number">
                  {allWork?.annouce_expired ?? 0}
                </div>
                <div className="status-work">ประกาศหมดอายุ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8CA3BA",
                }}
              >
                <div className="count-number">{allWork?.delete_work ?? 0}</div>
                <div className="status-work">ลบประกาศ</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#8CA3BA",
                }}
              >
                <div className="count-number">{allWork?.cancel ?? 0}</div>
                <div className="status-work">ยกเลิกงาน</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FF316F",
                }}
              >
                <div className="count-number">
                  {allWork?.may_receive_late ?? 0}
                </div>
                <div className="status-work">อาจเข้ารับสินค้าช้า</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FF316F",
                }}
              >
                <div className="count-number">
                  {allWork?.may_send_late ?? 0}
                </div>
                <div className="status-work">อาจเข้าส่งสินค้าช้า</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} className="grid-center">
              <div
                className="grid-box"
                style={{
                  backgroundColor: "#FF316F",
                }}
              >
                <div className="count-number">{allWork?.problem ?? 0}</div>
                <div className="status-work">แจ้งปัญหา</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default TrackingWork;
