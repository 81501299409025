import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as R from "ramda";
import { useCookies } from "react-cookie";
import FormModal from "components/modal/FormModal";
import CustomModal from "components/modal/CustomModal";
import API from "utils/api/auth/auth";
import styles from "./Home.module.css";
import helper from "utils/helper";
import { Box, Tab, Tabs, Grid, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HomeImage from "assets/image/truck_home.svg";

const api = API.create();

const Home = () => {
  const navigate = useNavigate();
  const { windowWidth: width, windowHeight: height } = helper.useWindowSize();
  const [listMenu, setListMenu] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 10,
  });
  const [openModal, setOpenModal] = useState({
    type: "",
    open: false,
    title: "",
    description: "",
  });
  const [, , removeAccessToken] = useCookies(["access_token"]);

  const menu_work = [
    {
      image: require("../../assets/image/home/User.png"),
      label: "ข้อมูลผู้ใช้งาน",
      link: "/user",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Project.png"),
      label: "ข้อมูลโครงการ",
      link: "/project",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Truck.png"),
      label: "ข้อมูลรถ",
      link: "/truck",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Insurance.png"),
      label: "ประกันภัยสินค้า",
      link: "",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/PostWork.png"),
      label: "ประกาศงาน",
      link: "/post-work",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Work.png"),
      label: "ข้อมูลงาน",
      link: "/work",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/DriverTracking.png"),
      label: "ติดตามคนขับทั้งหมด",
      link: "/tracking-driver",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/Finance.png"),
      label: "ข้อมูลการเงิน",
      link: "/finance/shipper/unpaid",
      color: "#8c8dff",
    },
    {
      image: require("../../assets/image/home/buy.png"),
      label: "จัดการการซื้อขาย",
      link: "/trade",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/customer.png"),
      label: "รายชื่อผู้ซื้อ",
      link: "/trade/customer",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/jobber.png"),
      label: "รายชื่อผู้ขาย",
      link: "/trade/jobber",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/trade_finance.png"),
      label: "ข้อมูลการเงินซื้อขาย",
      link: "/trade-finance",
      color: "#ffc675",
    },
    {
      image: require("../../assets/image/home/Graph.png"),
      label: "แผนภูมิและกราฟ",
      link: "/dashboard/work",
      color: "#27ce88",
    },
    {
      image: require("../../assets/image/home/Admin.png"),
      label: "จัดการผู้ดูแลระบบ",
      link: "/admin",
      color: "#27ce88",
    },
    {
      image: require("../../assets/image/home/MasterData.png"),
      label: "จัดการ Master Data",
      link: "/master-data/term-and-condition",
      color: "#27ce88",
    },
    {
      image: require("../../assets/image/home/LogOut.png"),
      label: "ออกจากระบบ",
      color: "#27ce88",
      // link: "",
      onClick: () => {
        setOpenModal({
          type: "logOut",
          open: true,
          title: "ยืนยันออกระบบ",
          description: "ยืนยันออกระบบ?",
        });
      },
    },
  ];

  // useEffect(() => {
  //   const total_page = Math.ceil(initial_menu.length / pagination.per_page);
  //   const last_menu_index = pagination.page * pagination.per_page;
  //   const start_menu_index = last_menu_index - pagination.per_page;

  //   let _list_menu = [...initial_menu];
  //   // _list_menu = _list_menu.splice(start_menu_index, last_menu_index);
  //   if (pagination.page === 2 && pagination.per_page === 4) {
  //     _list_menu = _list_menu.splice(start_menu_index, last_menu_index - 4);
  //   } else {
  //     _list_menu = _list_menu.splice(start_menu_index, last_menu_index);
  //   }
  //   setListMenu(_list_menu);

  //   // eslint-disable-next-line
  // }, [pagination]);

  // useEffect(() => {
  //   if (isMobile) {
  //     setPagination({ ...pagination, per_page: 12 });
  //   } else if (width > 576 && width <= 768) {
  //     setPagination({ ...pagination, per_page: 4 });
  //   } else if (width > 768 && width <= 992) {
  //     setPagination({ ...pagination, per_page: 6 });
  //   } else if (width > 992 && width <= 1460) {
  //     setPagination({ ...pagination, per_page: 8 });
  //   } else {
  //     setPagination({ ...pagination, per_page: 10 });
  //   }

  //   // eslint-disable-next-line
  // }, [width]);

  const renderModal = () => {
    if (openModal.open === true) {
      return (
        <CustomModal
          open={openModal.open}
          title={openModal.title}
          onCancel={() => {
            setOpenModal({
              type: "",
              open: false,
              title: "",
              description: "",
            });
          }}
          onConfirm={async () => {
            if (openModal.type === "logOut") {
              await api
                .logout()
                .then((res) => {
                  removeAccessToken("access_token");
                  navigate("/login");
                })
                .catch((e) => console.log(e));
            }

            setOpenModal({
              type: "",
              open: false,
              title: "",
              description: "",
            });
          }}
        >
          <div>{openModal.description}</div>
        </CustomModal>
      );
    }
  };

  // const renderMenu = () => {
  //   return isMobile ? (
  //     <div className={styles.cardContainer}>
  //       {listMenu.map((menu) => {
  //         return (
  //           <div
  //             className={styles.card}
  //             onClick={() => {
  //               if (!R.isNil(menu.link)) {
  //                 navigate(menu.link);
  //               }

  //               if (!R.isNil(menu.onClick)) {
  //                 menu.onClick();
  //               }
  //             }}
  //           >
  //             <div className={styles.imageContainer}>
  //               <img
  //                 src={menu.image}
  //                 alt={menu.name}
  //                 draggable={false}
  //                 width={140}
  //                 height={140}
  //               />
  //             </div>
  //             <p>{menu.label}</p>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   ) : (
  //     <div className={styles.cardContainer}>
  //       {listMenu.map((menu) => {
  //         return (
  //           <div
  //             className={styles.card}
  //             onClick={() => {
  //               if (!R.isNil(menu.link)) {
  //                 navigate(menu.link);
  //               }

  //               if (!R.isNil(menu.onClick)) {
  //                 menu.onClick();
  //               }
  //             }}
  //           >
  //             <div className={styles.imageContainer}>
  //               <img src={menu.image} alt={menu.name} draggable={false} />
  //             </div>
  //             <p>{menu.label}</p>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  // const renderPagination = () => {
  //   const number_of_page = Math.ceil(initial_menu.length / pagination.per_page);

  //   return (
  //     <div className={styles.paginationContainer}>
  //       {Array.from({ length: number_of_page }, (_, index) => {
  //         return (
  //           <div
  //             key={index + 1}
  //             className={`${styles.paginationSpot} ${
  //               index + 1 === pagination.page ? styles.active : styles.inActive
  //             }`}
  //             onClick={() => {
  //               setPagination({ ...pagination, page: index + 1 });
  //             }}
  //           />
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const renderWork = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minHeight: "75vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <img
            src={HomeImage}
            alt={"truck home"}
            // draggable={false}
            // height={24}
            // resizeMode={"contain"}
            width={"80%"}
            height={"auto"}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "80%",
            }}
          >
            {menu_work?.map((ele, index) => (
              <Grid
                item
                key={index}
                lg={1.5}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    borderBottom: `4px solid ${ele.color}`,
                    bgcolor: "white",
                    padding: "20px",
                    width: "150px",
                    height: "150px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    if (!R.isNil(ele.link)) {
                      navigate(ele.link);
                    }

                    if (!R.isNil(ele.onClick)) {
                      ele.onClick();
                    }
                  }}
                >
                  <div>
                    <img
                      src={ele.image}
                      alt={ele.name}
                      draggable={false}
                      width={70}
                      height={70}
                    />
                  </div>

                  <div style={{ fontSize: 12 }}>{ele?.label}</div>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    );
  };
  // const renderTrade = () => {
  //   return (
  //     <Box sx={{ width: "100%", typography: "body1" }}>
  //       <Grid container spacing={2} sx={{ justifyContent: "center" }}>
  //         {menu_trade?.map((ele, index) => (
  //           <Grid item key={index}>
  //             <Box
  //               sx={{
  //                 bgcolor: "white",
  //                 padding: "20px",
  //                 width: "180px",
  //                 height: "180px",
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 borderRadius: 5,
  //               }}
  //             >
  //               <img
  //                 src={ele.image}
  //                 alt={ele.name}
  //                 draggable={false}
  //                 width={100}
  //                 height={100}
  //               />
  //               {ele?.label}
  //             </Box>
  //           </Grid>
  //         ))}
  //       </Grid>
  //     </Box>
  //   );
  // };

  // const renderOthers = () => {
  //   return (
  //     <Box sx={{ width: "100%", typography: "body1" }}>
  //       <Grid container spacing={2} sx={{ justifyContent: "center" }}>
  //         {menu_others?.map((ele, index) => (
  //           <Grid item key={index}>
  //             <Box
  //               sx={{
  //                 bgcolor: "white",
  //                 padding: "20px",
  //                 width: "180px",
  //                 height: "180px",
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 borderRadius: 5,
  //               }}
  //             >
  //               <img
  //                 src={ele.image}
  //                 alt={ele.name}
  //                 draggable={false}
  //                 width={100}
  //                 height={100}
  //               />
  //               {ele?.label}
  //             </Box>
  //           </Grid>
  //         ))}
  //       </Grid>
  //     </Box>
  //   );
  // };
  return (
    <div className={styles.container}>
      {/* {renderMenu()} */}
      {/* {renderPagination()} */}
      {renderWork()}
      {/* {renderTrade()}
      {renderOthers()} */}
      {/* {renderModal()} */}
    </div>
  );
};

export default Home;
