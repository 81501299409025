import React from "react";
import { TextField } from "@mui/material";
import currency from "currency.js";

const FieldNumber = ({
  value = ``,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  label = "",
  subLabel = "",
  error = false,
  helperText = "",
  readOnly = false,
  style = {},
  type = "int",
  precision = "2",
}) => {
  let handleBlur;

  if (type === "float") {
    handleBlur = (e) => {
      const newValue = e.target.value;
      e.target.value = currency(newValue, {
        precision: precision,
        separator: ",",
        pattern: "#",
      }).format();
      onChange(e);
    };
  } else {
    handleBlur = (e) => {
      const newValue = e.target.value;
      e.target.value = currency(newValue, {
        precision: 0,
        separator: ",",
        pattern: "#",
      }).format();
      onChange(e);
    };
  }

  const handleInput = (e) => {
    const newValue = e.target.value;
    let cleanedValue;
    if (type === "float") {
      cleanedValue = newValue.replace(/[^0-9.,]/g, "");
    } else {
      cleanedValue = newValue.replace(/[^0-9,]/g, "");
    }

    // If type is float, format the number using currency.js
    e.target.value = cleanedValue;
    onChange(e);
  };

  return (
    <div style={{ paddingBottom: readOnly ? "23px" : 0 }}>
      <TextField
        type="text"
        // id="standard-basic"
        label={
          label && subLabel ? (
            <div style={{ paddingTop: -5 }}>
              <div
                style={{
                  display: "block",
                  marginBottom: "-5px",
                  marginTop: "-5px",
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                {label}
              </div>
              <div
                style={{
                  fontSize: "10px",
                  display: "block",
                  lineHeight: 1,
                  margin: 0,
                }}
              >
                {subLabel}
              </div>
            </div>
          ) : (
            label
          )
        }
        value={value}
        onChange={handleInput}
        onBlur={handleBlur}
        variant="standard"
        sx={{
          bgcolor: readOnly ? "rgba(140, 163, 186, 0.20)" : "white",
          width: "-webkit-fill-available",
          "& .MuiInputBase-input": {
            padding: "4px 14px 5px 14px",
          },
          "& .MuiFormLabel-root": {
            padding: "0 14px",
          },
          "& .MuiFormHelperText-root": {
            padding: "0 14px",
          },
          paddingBottom: error || readOnly ? 0 : "23px",
          ...style,
        }}
        error={error}
        helperText={helperText}
        InputProps={{
          readOnly: readOnly,
        }}
      />
      {/* {disabled ? <div style={{ paddingBottom: "25px" }}></div> : ""} */}
    </div>
  );
};

export default FieldNumber;
