import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import FormModal from "components/modal/FormModal";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./CreateFavoriteShipperGroupModal.module.css";
import { createShipperFavoriteGroup } from "utils/api/profile/shipperFavoriteGroup";

const CreateFavoriteShipperGroupModal = ({
  profile_id = null,
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const form_confim_btn = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const renderForm = () => {
    const list_form = [
      {
        name: "name",
        field_type: "text",
        field_label: "ชื่อกลุ่มผู้ให้บริการที่ชื่นชอบ*",
        rules: {
          required: "กรุณาใส่ชื่อกลุ่มผู้ให้บริการที่ชื่นชอบ",
        },
      },
      {
        name: "description",
        field_type: "text",
        field_label: "คำอธิบาย",
      },
    ];

    return (
      <div>
        {list_form.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const handleCreateFavShipperGroup = async () => {
    setIsLoading(true);
    try {
      let data = getValues();
      data = { ...data, profile_id: Number(profile_id), active: true };

      await createShipperFavoriteGroup(data).then((create_resp) => {
        if (create_resp.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
        setOpen(false);
      });
    } catch (error) {
      console.log("error,", error);

      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setOpen(false);
    }
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  return (
    <>
      <FormModal
        title={`เพิ่มกลุ่มผู้ให้บริการที่ชื่นชอบ`}
        open={open}
        confirmTextBtn={"บันทึก"}
        cancelTextBtn={"ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          reset();
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handleCreateFavShipperGroup();
          })}
        >
          {renderForm()}

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateFavoriteShipperGroupModal;
