// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FinanceTable tr {
  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);
  border-radius: 10px;
}

.FinanceTable thead th {
  background-color: var(--mui-palette-secondary-main) !important;
  color: #fff !important;
}

.FinanceTable td:first-child,
.FinanceTable th:first-child {
  border-radius: 10px 0 0 10px;
}

.FinanceTable td:last-child,
.FinanceTable th:last-child {
  border-radius: 0 10px 10px 0;
}

.FinanceTable tbody td {
  background-color: #fff;
}

.FinanceTable table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.FinanceTable .rowChecked td,
.FinanceTable .rowChecked th {
  border-top: 2px solid var(--mui-palette-secondary-main) !important;
  border-bottom: 2px solid var(--mui-palette-secondary-main) !important;
}

.FinanceTable .rowChecked td,
.FinanceTable .rowChecked th {
  border-top: 2px solid var(--mui-palette-secondary-main) !important;
  border-bottom: 2px solid var(--mui-palette-secondary-main) !important;
}

.FinanceTable .rowChecked td:first-child,
.FinanceTable .rowChecked th:first-child {
  border-left: 2px solid var(--mui-palette-secondary-main) !important;
}

.FinanceTable .rowChecked td:last-child,
.rowChecked th:last-child {
  border-right: 2px solid var(--mui-palette-secondary-main) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/table/FinanceTable/FinanceTable.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,mBAAmB;AACrB;;AAEA;EACE,8DAA8D;EAC9D,sBAAsB;AACxB;;AAEA;;EAEE,4BAA4B;AAC9B;;AAEA;;EAEE,4BAA4B;AAC9B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;;EAEE,kEAAkE;EAClE,qEAAqE;AACvE;;AAEA;;EAEE,kEAAkE;EAClE,qEAAqE;AACvE;;AAEA;;EAEE,mEAAmE;AACrE;;AAEA;;EAEE,oEAAoE;AACtE","sourcesContent":[".FinanceTable tr {\n  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);\n  border-radius: 10px;\n}\n\n.FinanceTable thead th {\n  background-color: var(--mui-palette-secondary-main) !important;\n  color: #fff !important;\n}\n\n.FinanceTable td:first-child,\n.FinanceTable th:first-child {\n  border-radius: 10px 0 0 10px;\n}\n\n.FinanceTable td:last-child,\n.FinanceTable th:last-child {\n  border-radius: 0 10px 10px 0;\n}\n\n.FinanceTable tbody td {\n  background-color: #fff;\n}\n\n.FinanceTable table {\n  border-collapse: separate;\n  border-spacing: 0 10px;\n}\n\n.FinanceTable .rowChecked td,\n.FinanceTable .rowChecked th {\n  border-top: 2px solid var(--mui-palette-secondary-main) !important;\n  border-bottom: 2px solid var(--mui-palette-secondary-main) !important;\n}\n\n.FinanceTable .rowChecked td,\n.FinanceTable .rowChecked th {\n  border-top: 2px solid var(--mui-palette-secondary-main) !important;\n  border-bottom: 2px solid var(--mui-palette-secondary-main) !important;\n}\n\n.FinanceTable .rowChecked td:first-child,\n.FinanceTable .rowChecked th:first-child {\n  border-left: 2px solid var(--mui-palette-secondary-main) !important;\n}\n\n.FinanceTable .rowChecked td:last-child,\n.rowChecked th:last-child {\n  border-right: 2px solid var(--mui-palette-secondary-main) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
