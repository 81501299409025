import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import Title from "./Title";
import * as currency from "currency.js";

const testdata = [
  {
    name: "Page A",
    active: 800,
    cancel: 200,
    percent: 0,
  },
  {
    name: "Page B",
    active: 600,
    cancel: 100,
    percent: 0,
  },
  {
    name: "Page C",
    active: 800,
    cancel: 200,
    percent: 0,
  },
  {
    name: "Page D",
    active: 600,
    cancel: 100,
    percent: 0,
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const active_data = payload[0].payload.active;
    const cancel_data = payload[0].payload.cancel;
    const matching_data = payload[0].payload["%Matching"];
    return (
      <div className="chart-tooltip">
        <p className="chart-tooltip-text">{`${label}`}</p>
        <p className="chart-tooltip-text">{`Accept: ${active_data}`}</p>
        <p className="chart-tooltip-text">{`Cancel: ${cancel_data}`}</p>
        <p className="chart-tooltip-text">{`%Matching: ${matching_data}`}</p>
      </div>
    );
  }

  return null;
};

export default function BiaxialBarChart({ title, data = testdata }) {
  const [rawData, setRawData] = React.useState(null);
  const [maxY, setMaxY] = React.useState(0);
  const maxY2 = 100;

  useEffect(() => {
    const maxY = data.reduce((oldVal, newVal) => {
      const sum = parseInt(newVal.active) + parseInt(newVal.cancel);
      if (sum > oldVal) {
        return sum;
      }
      return oldVal;
    }, 0);
    const newData = data.map((item) => {
      const active = parseInt(item.active) ?? 0;
      const cancel = parseInt(item.cancel) ?? 0;
      const n = active + cancel;
      const percent = (active / n) * 100;
      const formatPercent = currency(percent, { symbol: "" }).format();
      // return {
      //   name: item.name,
      //   "Accept Job": parseInt(item.active),
      //   "Cancel Job": parseInt(item.cancel),
      //   "%Matching": formatPercent,
      // };
      return {
        name: item.name,
        "Accept Job": formatPercent,
        "Cancel Job": currency(100 - formatPercent, { symbol: "" }).format(),
        "%Matching": formatPercent,
        active: currency(active, { symbol: "" }).format(),
        cancel: currency(cancel, { symbol: "" }).format(),
      };
    });
    setMaxY(maxY);
    setRawData(newData);
  }, [data]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>{title}</Title>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={rawData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {/* <YAxis yAxisId="left" orientation="left" domain={[0, maxY]} />
          <YAxis yAxisId="right" orientation="right" domain={[0, maxY2]} />
          <ReferenceLine
            yAxisId="right"
            y={95}
            stroke="red"
            label="Target 95%"
          />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="Accept Job" fill="#0a1247" />
          <Bar yAxisId="left" dataKey="Cancel Job" fill="#af4424" />
          <Line yAxisId="right" dataKey="%Matching" stroke="#6a329f" /> */}
          <YAxis yAxisId="left" orientation="left" domain={[0, maxY2]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <ReferenceLine
            yAxisId="left"
            y={95}
            stroke="red"
            label="Target 95%"
          />
          <Bar yAxisId="left" dataKey="Accept Job" fill="#0a1247" />
          <Bar yAxisId="left" dataKey="Cancel Job" fill="#af4424" />
          <Line yAxisId="left" dataKey="%Matching" stroke="#6a329f" />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}
