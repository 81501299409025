import React from "react";
import { PieChart, Pie, Cell, Tooltip, Text, Label } from "recharts";
import Title from "./Title";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";

export default function Chart({ data, title, title2, unit }) {
  const sumValue = data.reduce((prev, curr) => {
    return prev + curr.value;
  }, 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0) {
      const desc = payload[0].payload;
      return (
        <div className="chart-tooltip">
          <div style={{ display: "inline-block" }}>
            <CircleIcon
              sx={{
                fontSize: "10px",
                color: `#${desc.color}`,
                marginBottom: "10px",
              }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              marginLeft: "10px",
              textAlign: "center",
            }}
          >
            <span className="chart-tooltip-text">
              {desc.name}: {desc.percent.toFixed(2)} %
            </span>
            <br />
            <span className="chart-tooltip-text text-gray">
              {desc.value} {unit}
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => {
    return (
      <Box component="div" px={{ width: "100%", textAlign: "center" }}>
        {data.map((d, index) => (
          <span
            style={{
              paddingRight: index !== data.length - 1 ? "20px" : "0",
            }}
            key={index}
          >
            <CircleIcon sx={{ fontSize: "10px", color: `#${d.color}` }} />{" "}
            {d.name}
          </span>
        ))}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Title>{title2}</Title>
      <Box
        sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}
      >
        <PieChart width={300} height={200}>
          <Pie
            data={data}
            cx={135}
            innerRadius={50}
            outerRadius={84}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={`#${entry.color}`} />
            ))}
            <Label
              content={(props) => {
                const positioningProps = {
                  x: 140,
                  y: 90,
                  textAnchor: "middle",
                  verticalAnchor: "middle",
                };

                const positioningProps2 = {
                  x: 140,
                  y: 110,
                  textAnchor: "middle",
                  verticalAnchor: "middle",
                };

                const presentationProps = {
                  fill: "#8ca3ba",
                };

                const presentationProps2 = {
                  fill: "#121f43",
                };

                return (
                  <>
                    <Text
                      {...positioningProps}
                      {...presentationProps}
                      className="PieChart-label"
                    >{`ปริมาณ${unit}รวม`}</Text>
                    <Text
                      {...positioningProps2}
                      {...presentationProps2}
                      className="PieChart-label"
                    >
                      {sumValue}
                    </Text>
                  </>
                );
              }}
            />
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </Box>

      <CustomLegend />
    </React.Fragment>
  );
}
