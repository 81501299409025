import React from "react";
import { Avatar, IconButton } from "@mui/material";
import { Add, Save } from "@mui/icons-material";

const FloatingButton = ({
  onClick = () => {},
  button_type = "add",
  disabled = false,
}) => {
  return (
    <div
      style={{
        pointerEvents: disabled === true ? "none" : null,
        opacity: disabled === true ? 0.5 : 1,
      }}
    >
      <Avatar
        sx={{
          position: "fixed",
          bottom: "35px",
          right: "97px",
          width: 100,
          height: 100,
          bgcolor: "secondary.main",
        }}
        onClick={() => {
          onClick();
        }}
      >
        {button_type === "add" && (
          <IconButton type="submit">
            <Add sx={{ color: "white", fontSize: "70px" }} />
          </IconButton>
        )}
        {button_type === "save" && (
          <IconButton type="submit">
            <Save sx={{ color: "white", fontSize: "70px" }} />
          </IconButton>
        )}
      </Avatar>
    </div>
  );
};

export default FloatingButton;
