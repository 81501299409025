import { ArrowForwardIosOutlined, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import ModalWork from "./modalWork";
import Line from "components/layout/line";
import { getReceiptName } from "utils/api/finance/other";
import {
  getAllProfileReceiptName,
  getProfileReceiptName,
} from "utils/api/profile/profileReceiptName";
// import { Pagination } from "antd";
import { Button, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputDisable from "components/input/inputDisable";
import Pagination from "components/pagination/Pagination";

const SelectBilling = (props) => {
  const [show, setShow] = useState(false);
  const { value, onChange = () => {}, profile_id } = props;
  const [billList, setBillList] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    per_page: 5,
    sort_field: "updated_at",
    sort_order: -1,
    active: true,
  });
  const [showDetail, setShowDetail] = useState(false);

  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
    setShowDetail(null);
  };
  useEffect(() => {
    if (show && profile_id) {
      getBilling();
    }
  }, [show, profile_id]);
  useEffect(() => {
    getBilling();
  }, [filter]);
  const getBilling = async () => {
    let receipt = await getAllProfileReceiptName(profile_id, filter);
    console.log(receipt);

    setBillList(receipt.data.data.results);
    setTotal(receipt.data.data.total);
  };
  const getDetail = async (id) => {
    let receipt = await getProfileReceiptName(id);
    console.log(receipt);
    setShowDetail(receipt.data.data.results);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          clickShow();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("../../assets/image/work/bill.png")
            }
            height={40}
            alt={"bill"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              color: value?.company_name ? "#121f43" : "#ff316f",
            }}
          >
            ชื่อและที่อยู่ในการออกใบเสร็จ*
          </div>
          <div style={{ fontSize: 13, opacity: value?.company_name ? 1 : 0.3 }}>
            {value?.company_name ?? "โปรดระบุชื่อและที่อยู่ในการออกใบเสร็จ"}
          </div>
        </div>
        <div style={{ alignSelf: "center" }}>
          <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
        </div>
      </div>
      <ModalWork
        open={show}
        minHeight
        enableClose
        close={() => {
          clickClose();
        }}
      >
        {showDetail ? (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <InputDisable
              value={
                showDetail.profile_type === 0 ? "บุคคลธรรมดา" : "นิติบุคคล"
              }
              label={"ประเภทผู้ที่รับใบเสร็จ*"}
            />
            <InputDisable
              value={showDetail.company_name}
              label={"ชื่อผู้รับใบเสร็จ*"}
            />
            <InputDisable value={showDetail.branch_code} label={"รหัสสาขา*"} />
            <InputDisable
              value={showDetail.tax_id}
              label={"เลขประจำตัวผู้เสียภาษี*"}
            />
            <InputDisable
              value={showDetail.phone_number}
              label={"เบอร์โทรศัพท์*"}
            />
            <InputDisable value={showDetail.address} label={"ที่อยู่*"} />
            <InputDisable
              value={showDetail.subdistrict?.name}
              label={"แขวง/ตำบล*"}
            />
            <InputDisable
              value={showDetail.district?.name}
              label={"เขต/อำเภอ*"}
            />
            <InputDisable
              value={showDetail.province?.name}
              label={"จังหวัด*"}
            />
            <InputDisable
              value={showDetail.subdistrict?.postal_code}
              label={"รหัสไปรษณีย์*"}
            />
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outline"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    color: "#8ca3ba",
                    borderColor: "#8ca3ba",
                    height: 50,
                  }}
                  color="secondary"
                  onClick={() => {
                    setShowDetail(null);
                  }}
                >
                  ย้อนกลับ
                </Button>
              </div>
              <div style={{ flex: 1, marginLeft: 20 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={() => {
                    onChange(showDetail);
                    clickClose();
                  }}
                >
                  ยืนยัน
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>ชื่อในการออกใบเสร็จ</div>
            <Line />
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_value}
              onChange={(val) => {
                setFilter({ ...filter, company_name: val.target.value });
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={() => {
                getBilling();
                onChange(null);
              }}
            >
              ค้นหา
            </Button>
            <div>
              {billList.map((ele, index) => {
                return (
                  <div
                    key={"fav" + index}
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      padding: 10,
                      borderColor: ele.id === value?.id ? "#121f43" : "",
                      color: ele.id === value?.id ? "#121f43" : "",
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      console.log(ele);
                      getDetail(ele.id);
                      // setShowDetail(ele);
                    }}
                  >
                    {ele.company_name}
                  </div>
                );
              })}
            </div>
            <div style={{ textAlign: "center" }}>
              <Pagination
                pagination={{
                  page: filter.page,
                  per_page: filter.per_page,
                  total: total,
                }}
                setPagination={(val) => {
                  console.log(val);
                  setFilter({
                    ...filter,
                    per_page: val.per_page,
                    page: val.page,
                  });
                  // getFavPlace({
                  //   ...filter,
                  //   per_page: val.per_page,
                  //   page: val.page,
                  // })
                }}
                // loading={loading}
              />
            </div>
          </div>
        )}
      </ModalWork>
    </div>
  );
};
export default SelectBilling;
