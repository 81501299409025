import instance from "../../axiosInstance";

export const getAllProfileReceiptName = async (
  profile_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `api/profile/profile-receipt-name/${profile_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getProfileReceiptName = async (id = null, params = {}) => {
  try {
    return await instance.get(`api/profile/profile-receipt-name/detail/${id}`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createProfileReceiptName = async (body = {}) => {
  try {
    return await instance.post(`api/profile/profile-receipt-name`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateProfileReceiptName = async (id = null, body = {}) => {
  try {
    return await instance.patch(`api/profile/profile-receipt-name/${id}`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
