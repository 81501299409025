import axios from "axios";
import { withCookies, Cookies } from "react-cookie";
import helper from "utils/helper";
const setAPI = async () => {
  // console.log(ENV.API_ENDPOINT_01);
  return axios.create({
    baseURL: process.env.REACT_APP_API_ENPOINT,
    timeout: 100000,
  });
};

const create = () => {
  const login = async (data) => {
    let api = await setAPI();
    let user = {
      username: data.username,
      password: data.password,
    };
    return api.post("api/auth/login", user, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    // helper.getCookie("access_token")
  };

  const resetPassowrd = async (data) => {
    let api = await setAPI();
    let user = {
      email: data.email,
    };
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/auth/forgot-password" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  const checkOtp = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/auth/check-otp" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };

  const setNewPassword = async (data) => {
    let api = await setAPI();
    return api.post("api/auth/reset-password", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };

  const logout = async (data) => {
    let api = await setAPI();
    return api.delete("api/auth/logout", {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });

    // helper.getCookie("access_token")
  };
  return {
    login,
    resetPassowrd,
    checkOtp,
    setNewPassword,
    logout,
  };
};

export default { create };
