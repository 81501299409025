// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableAdmin_buttonCreateAdmin__VXx3Q {
  position: fixed;
  bottom: 48px;
  right: 97px;
}

.TableAdmin_gridItem__rmdbh {
  padding-right: 20px;
  /* flex: 0.225; */
}

.TableAdmin_tableHeadDetail__thUeN {
  background-color: var(--mui-palette-primary-main);
  height: 50px;
}

.TableAdmin_headPermission__mFfV1 {
  padding: 0;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.TableAdmin_listPermission__bMrjI {
  padding: 15px;
  /* color: var(--mui-palette-grey-main); */
  font-size: 16px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/TableAdmin.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iDAAiD;EACjD,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yCAAyC;EACzC,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".buttonCreateAdmin {\n  position: fixed;\n  bottom: 48px;\n  right: 97px;\n}\n\n.gridItem {\n  padding-right: 20px;\n  /* flex: 0.225; */\n}\n\n.tableHeadDetail {\n  background-color: var(--mui-palette-primary-main);\n  height: 50px;\n}\n\n.headPermission {\n  padding: 0;\n  color: white;\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.listPermission {\n  padding: 15px;\n  /* color: var(--mui-palette-grey-main); */\n  font-size: 16px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonCreateAdmin": `TableAdmin_buttonCreateAdmin__VXx3Q`,
	"gridItem": `TableAdmin_gridItem__rmdbh`,
	"tableHeadDetail": `TableAdmin_tableHeadDetail__thUeN`,
	"headPermission": `TableAdmin_headPermission__mFfV1`,
	"listPermission": `TableAdmin_listPermission__bMrjI`
};
export default ___CSS_LOADER_EXPORT___;
