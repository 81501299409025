import React from "react";

const Button = ({
  children,
  type = `submit`,
  disabled = false,
  onClick = () => {},
  style = {},
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        borderRadius: `10px`,
        background: disabled === false ? `#121f43` : `rgba(18, 31, 67, 0.5)`,
        boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.2)`,
        border: disabled === false ? null : "none",
        color: `var(--light-background, #fff)`,
        textAlign: `center`,
        fontFamily: `Kanit`,
        fontSize: `16px`,
        fontStyle: `normal`,
        fontWeight: 500,
        lineHeight: `normal`,
        padding: `13px 38px`,
        width: `100%`,
        height: `50px`,
        flexShrink: 0,
        cursor: disabled === false ? `pointer` : null,
        ...style,
      }}
    >
      {children}
    </button>
  );
};

export default Button;
