import { Box, Container, Grid, Paper, TextField } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import AreaChart from "components/chart/AreaChart";
import StackedAreaChart from "components/chart/StackedAreaChart";
import PieChart from "components/chart/PieChart";
import StackedBarChart2Group from "components/chart/StackedBarChart2Group";
import { subMenuDasdboard } from "utils/submenuoptions";
import moment from "moment";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import SearchButton from "components/button/SearchButton";
import ResetButton from "components/button/ResetButton";
import Loading from "components/loading/Loading";
import { getDashbaordProfile } from "utils/api/dashbaord";

const DashboardProfile = () => {
  const [isReady, setIsReady] = useState(false);
  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [groupBy, setGroupBy] = useState(4);
  const [filterBy, setFilterBy] = useState("projectID");
  const [filter, setFilter] = useState(null);
  const [dashboard1, setDashboard1] = useState(null);
  const [dashboard2, setDashboard2] = useState(null);
  const [dashboard3, setDashboard3] = useState(null);
  const [dashboard4, setDashboard4] = useState(null);
  const [dashboard5, setDashboard5] = useState(null);
  const [dashboard6, setDashboard6] = useState(null);
  const [dashboard7, setDashboard7] = useState(null);

  useEffect(() => {
    setActiveSubMenu(2);
    setSubMenuBreadcrumbs([
      {
        label: "แผนภูมิและกราฟ",
        path: null,
      },
      {
        label: "แผนภูมิและกราฟผู้ใช้งาน",
        path: null,
      },
    ]);
    fetchDashboard();
  }, []);

  const monthToNumber = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames.indexOf(month);
  };

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNumber < 10 ? "0" + weekNumber : weekNumber.toString();
  };

  const generateDates = (dateFrom, dateTo, groupBy) => {
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);
    const generatedDates = [];

    if (groupBy === 1) {
      for (
        let year = fromDate.getFullYear();
        year <= toDate.getFullYear();
        year++
      ) {
        generatedDates.push(year.toString());
      }
    } else if (groupBy === 2) {
      const startYear = fromDate.getFullYear();
      const endYear = toDate.getFullYear();
      const startMonth = fromDate.getMonth();
      const endMonth = toDate.getMonth();

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const date = new Date(year, month, 1);
          generatedDates.push(formatDate2(date));
        }
      }
    } else if (groupBy === 3) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        const weekNumber = getWeekNumber(currentDate);
        const year = currentDate.getFullYear();
        generatedDates.push(`w${weekNumber}/${year}`);
        currentDate.setDate(currentDate.getDate() + 7);
      }
    } else if (groupBy === 4) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        generatedDates.push(formatDate4(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return generatedDates;
  };

  const formatDate1 = (dateString) => {
    return moment(dateString).format("YYYY");
  };

  const formatDate2 = (dateString) => {
    return moment(dateString).format("MMM YYYY");
  };
  const formatDate3 = (dateString) => {
    return dateString;
  };

  const formatDate4 = (dateString) => {
    return moment(dateString).format("DD MMM YYYY");
  };

  async function fetchDashboard() {
    setIsReady(false);
    const requestData = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
      groupBy: groupBy,
    };
    // if (filterBy !== "" && filter !== "") {
    //   requestData[filterBy] = filter;
    // }
    try {
      const response = await getDashbaordProfile(requestData);
      if (response.status === 200) {
        const res = response.data.data;
        const rawData = res.map((item) => {
          if (groupBy === 1) {
            item.groupdate = formatDate1(item.groupdate);
          } else if (groupBy === 2) {
            item.groupdate = formatDate2(item.groupdate);
          } else if (groupBy === 3) {
            item.groupdate = formatDate3(item.groupdate);
          } else if (groupBy === 4) {
            item.groupdate = formatDate4(item.groupdate);
          }
          return item;
        });
        const generatedDates = generateDates(dateFrom, dateTo, groupBy);
        generatedDates.forEach((generatedDate) => {
          if (!rawData.some((item) => item.groupdate === generatedDate)) {
            rawData.push({
              groupdate: generatedDate,
              profile: "0",
              profile_shipper: "0",
              profile_shipper_corporate: "0",
              profile_carrier: "0",
              profile_carrier_corporate: "0",
              profile_driver: "0",
              profile_driver_corporate: "0",
              profile_approve_pending: "0",
              profile_approve_approved: "0",
              profile_approve_editing: "0",
              profile_approve_rejected: "0",
            });
          }
        });

        if (groupBy === 2) {
          rawData.sort((a, b) => {
            const [aMonth, aYear] = a.groupdate.split(" ");
            const [bMonth, bYear] = b.groupdate.split(" ");
            const aMonthNumber = monthToNumber(aMonth);
            const bMonthNumber = monthToNumber(bMonth);

            if (aYear === bYear) {
              return aMonthNumber - bMonthNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 3) {
          rawData.sort((a, b) => {
            const aWeekYear = a.groupdate.split(" ")[0];
            const bWeekYear = b.groupdate.split(" ")[0];
            const [aWeek, aYear] = aWeekYear.split("/");
            const [bWeek, bYear] = bWeekYear.split("/");
            const aWeekNumber = parseInt(aWeek.split("w")[1]);
            const bWeekNumber = parseInt(bWeek.split("w")[1]);
            if (aYear === bYear) {
              return aWeekNumber - bWeekNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 4) {
          rawData.sort((a, b) => {
            const dateA = new Date(a.groupdate);
            const dateB = new Date(b.groupdate);
            return dateA - dateB;
          });
        } else {
          rawData.sort((a, b) => a.groupdate.localeCompare(b.groupdate));
        }

        const data = rawData.map((value, index) => {
          const target = 10;
          const targetDiff = (100 / target) * value.profile - 100;
          const prevProfile = index === 0 ? "NaN" : rawData[index - 1].profile;
          const prevDiff = (100 / prevProfile) * value.profile - 100;
          const targetDiffText =
            targetDiff >= 0
              ? `+${targetDiff.toFixed(2)}%`
              : `${targetDiff.toFixed(2)}%`;
          const prevDiffText =
            prevDiff >= 0
              ? `+${prevDiff.toFixed(2)}%`
              : `${prevDiff.toFixed(2)}%`;
          return {
            name: value.groupdate,
            value: value.profile,
            data: {
              จำนวนผู้ใช้งาน: value.profile,
              เป้าหมาย: `${target}`,
              ส่วนต่างจากเป้าหมาย: targetDiffText,
              จำนวนผู้ใช้งานก่อนหน้า: `${prevProfile}`,
              ส่วนต่างจากจำนวนผู้ใช้งานก่อนหน้า: prevDiffText,
            },
          };
        });

        const data2 = rawData.map((value, index) => {
          const profile =
            parseFloat(value.profile_carrier) +
            parseFloat(value.profile_driver) +
            parseFloat(value.profile_shipper);
          const profile_corporate =
            parseFloat(value.profile_carrier_corporate) +
            parseFloat(value.profile_driver_corporate) +
            parseFloat(value.profile_shipper_corporate);
          return {
            name: value.groupdate,
            value: [profile, profile_corporate, profile + profile_corporate],
            data: {
              บุคคลทั่วไป: profile,
              นิติบุคคล: profile_corporate,
              รวม: profile + profile_corporate,
            },
          };
        });

        const data3 = rawData.map((value, index) => {
          const shipper =
            parseFloat(value.profile_shipper) +
            parseFloat(value.profile_shipper_corporate);
          const carrier =
            parseFloat(value.profile_carrier) +
            parseFloat(value.profile_carrier_corporate);

          const driver =
            parseFloat(value.profile_driver) +
            parseFloat(value.profile_driver_corporate);
          return {
            name: value.groupdate,
            value: [shipper, carrier, driver, shipper + carrier + driver],
            data: {
              ลูกค้า: shipper,
              ผู้ขนส่ง: carrier,
              คนขับรถ: driver,
              รวม: shipper + carrier + driver,
            },
          };
        });

        let data4 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] +
                parseFloat(curr.profile_shipper) +
                parseFloat(curr.profile_carrier) +
                parseFloat(curr.profile_driver),
              prev[1] +
                parseFloat(curr.profile_shipper_corporate) +
                parseFloat(curr.profile_carrier_corporate) +
                parseFloat(curr.profile_driver_corporate),
            ];
          },
          [0, 0]
        );

        let data5 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] +
                parseFloat(curr.profile_shipper) +
                parseFloat(curr.profile_shipper_corporate),
              prev[1] +
                parseFloat(curr.profile_carrier) +
                parseFloat(curr.profile_carrier_corporate),
              prev[2] +
                parseFloat(curr.profile_driver) +
                parseFloat(curr.profile_driver_corporate),
            ];
          },
          [0, 0, 0]
        );

        const data6 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            profile_shipper: value.profile_shipper,
            profile_shipper_corporate: value.profile_shipper_corporate,
            profile_carrier: value.profile_carrier,
            profile_carrier_corporate: value.profile_carrier_corporate,
            profile_driver: value.profile_driver,
            profile_driver_corporate: value.profile_driver_corporate,
          };
        });

        let data7 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.profile_approve_pending),
              prev[1] + parseFloat(curr.profile_approve_approved),
              prev[2] + parseFloat(curr.profile_approve_editing),
              prev[3] + parseFloat(curr.profile_approve_rejected),
            ];
          },
          [0, 0, 0, 0]
        );

        setDashboard1(data);
        setDashboard2(data2);
        setDashboard3(data3);
        setDashboard4([
          {
            name: "บุคคลทั่วไป",
            value: data4[0],
            percent: (data4[0] / (data4[0] + data4[1])) * 100,
            color: "FFC675",
          },
          {
            name: "นิติบุคคล",
            value: data4[1],
            percent: (data4[1] / (data4[0] + data4[1])) * 100,
            color: "8C8DFF",
          },
        ]);
        setDashboard5([
          {
            name: "ลูกค้า",
            value: data5[0],
            percent: (data5[0] / (data5[0] + data5[1] + data5[2])) * 100,
            color: "FFC675",
          },
          {
            name: "ผู้ขนส่ง",
            value: data5[1],
            percent: (data5[1] / (data5[0] + data5[1] + data5[2])) * 100,
            color: "27CE88",
          },
          {
            name: "คนขับรถ",
            value: data5[2],
            percent: (data5[2] / (data5[0] + data5[1] + data5[2])) * 100,
            color: "8C8DFF",
          },
        ]);
        setDashboard6(data6);
        setDashboard7([
          {
            name: "รอการอนุมัติ",
            value: data7[0],
            percent:
              (data7[0] / (data7[0] + data7[1] + data7[2] + data7[3])) * 100,
            color: "FFC675",
          },
          {
            name: "อนุมัติแล้ว",
            value: data7[1],
            percent:
              (data7[1] / (data7[0] + data7[1] + data7[2] + data7[3])) * 100,
            color: "FF316F",
          },
          {
            name: "แก้ไขเพิ่มเติม",
            value: data7[2],
            percent:
              (data7[2] / (data7[0] + data7[1] + data7[2] + data7[3])) * 100,
            color: "F9A38A",
          },
          {
            name: "ไม่อนุมัติ",
            value: data7[3],
            percent:
              (data7[3] / (data7[0] + data7[1] + data7[2] + data7[3])) * 100,
            color: "8C8DFF",
          },
        ]);
      }
      setIsReady(true);
    } catch (error) {
      setIsReady(true);
      console.error("Error fetching Dashboard:", error);
    }
  }

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  const handleGroupby = (val) => {
    setGroupBy(val);
  };

  const handleDate = (val) => {
    setDateFrom(val[0]);
    setDateTo(val[1]);
  };

  const handleFilterBy = (val) => {
    setFilterBy(val);
  };

  const handleFilter = (val) => {
    setFilter(val);
  };

  const handleSearch = () => {
    fetchDashboard();
  };

  const handleReset = () => {
    setDateFrom(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setDateTo(moment().format("YYYY-MM-DD"));
    setGroupBy(4);
    setFilterBy("");
    setFilter("");
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          height: "100px",
          width: "100%",
          padding: "26px 37px",
          borderRadius: "10px",
          background: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: 1, name: "ปี" },
                { value: 2, name: "เดือน" },
                { value: 3, name: "สัปดาห์" },
                { value: 4, name: "วัน" },
              ]}
              value={groupBy}
              onChange={handleGroupby}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: dateFrom,
                end_date: dateTo,
              }}
              onChange={handleDate}
            />
          </Grid>
          {/* <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`ค้นหาจาก`}
              options={[{ value: "projectID", name: "ชื่อโครงการ" }]}
              value={filterBy}
              onChange={handleFilterBy}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldSearch
              className="w-100"
              value={filter}
              onChange={handleFilter}
            />
          </Grid> */}
          <Grid item xs={2}>
            <SearchButton onClick={handleSearch} />
            <ResetButton styles={{ marginLeft: "5px" }} onClick={handleReset} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderDasdboard = () => {
    return (
      <div className="div-chart">
        <Box
          className="box-inline box-white"
          sx={{
            height: "625px",
            minWidth: "1150px",
            padding: "18px 25px",
          }}
        >
          <AreaChart title="จำนวนผู้ใช้งาน" data={dashboard1} color="2499EF" />
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "800px", height: "300px", padding: "18px 25px" }}
          >
            <StackedAreaChart
              title="จำนวนผู้ใช้งานแยกตามประเภทโปรไฟล์"
              data={dashboard2}
              color={["FFC675", "FF316F", "2499EF"]}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "800px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <StackedAreaChart
              title="จำนวนผู้ใช้งานแยกตามบทบาทผู้ใช้งานในโปรไฟล์"
              data={dashboard3}
              color={["FFC675", "27CE88", "2499EF"]}
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "325px", height: "300px", padding: "18px 25px" }}
          >
            <PieChart
              data={dashboard4}
              title="สัดส่วนจำนวนผู้ใช้งาน"
              title2="แยกตามประเภทโปรไฟล์"
              unit="ผู้ใช้งาน"
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "325px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <PieChart
              data={dashboard5}
              title="สัดส่วนจำนวนผู้ใช้งาน"
              title2="แยกตามบทบาทผู้ใช้งานในโปรไฟล์"
              unit="ผู้ใช้งาน"
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "800px", height: "300px", padding: "18px 25px" }}
          >
            <StackedBarChart2Group
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทการชำระเงิน"
              data={dashboard6}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "500px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <PieChart
              data={dashboard7}
              title="สัดส่วนจำนวนผู้ใช้งาน"
              title2="แยกตามบทบาทผู้ใช้งานในโปรไฟล์"
              unit="ผู้ใช้งาน"
            />
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <Box className="DashbordWork">
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
      </Container>
      {isReady === false ? <Loading /> : renderDasdboard()}
    </Box>
  );
};

export default DashboardProfile;
