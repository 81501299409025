import { Box, Container, FormControl, Grid } from "@mui/material";
import SearchButton from "components/button/SearchButton";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import moment from "moment";
import { useEffect, useState } from "react";
import "../finance.css";
import { subMenuTradeFinance } from "utils/submenuoptions";
import FieldDatePicker from "components/field/FieldDatePicker";
import { notification } from "antd";
import FinanceTable from "components/table/FinanceTable";
import helper from "utils/helper";
import FieldInput from "components/field/FieldInput";
import FieldNumber from "components/field/FieldNumber";
import {
  cancelBillingnote,
  createPaid,
  getBillingnoteGroup,
} from "utils/api/trade-finance/buyer";
import UpdateStatusButton2way from "components/button/finance/UpdateStatusButton2way";
import * as currency from "currency.js";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import { getAllBank } from "utils/api/masterData/service";
import SelectRowValue from "components/button/SelectRowValue";

const TradeBuyerBillingNoteGroup = () => {
  //form filter
  const [formSearch, setFormSearch] = useState({
    sortBy: "bill_date",
    dateFrom: moment().subtract(365, "days").format("YYYY-MM-DD 00:00:00"),
    dateTo: moment().add(365, "days").format("YYYY-MM-DD 23:59:59"),
    filterBy: "order_code",
    filter: "",
    profile_type: "ALL",
    payment_type: "ALL",
    work_status: "ALL",
  });
  const [formSearchOld, setFormSearchOld] = useState({});
  //datatable
  const [selectAll, setSelectAll] = useState(false);
  const [isBusy, setIsBusy] = useState(true);
  const [rowSelected, setRowSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [items, setItems] = useState([]);
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  //updatestatus modal
  const [updateStatusForm, setUpdateStatusForm] = useState({
    actual_date: moment().format("YYYY-MM-DD kk:mm"),
    bill_codes: [],
    amount_per_trip: 0,
    vat_per_trip: 0,
    total_per_trip: 0,
    decimal: 2,
    amount_of_transfer_actual: 0,
    bank_id: 1,
    ref: "",
    file: "",
    credit_remark: "",
    payment_due_date: moment().format("YYYY-MM-DD kk:mm"),
    unit_price: 0,
  });
  const [error, setError] = useState({
    actual_date: false,
    bill_codes: false,
    amount_per_trip: false,
    vat_per_trip: false,
    total_per_trip: false,
    decimal: false,
    amount_of_transfer_actual: false,
    bank_id: false,
    ref: false,
    file: false,
    payment_due_date: false,
    unit_price: false,
  });

  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuTradeFinance]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  useEffect(() => {
    setActiveSubMenu(4);
    setSubMenuBreadcrumbs([
      {
        label: "จัดการการซื้อขาย",
        path: null,
      },
      {
        label: "ข้อมูลการเงินผู้ซื้อ",
        path: null,
      },
      {
        label: "ประวัติการวางบิล",
        path: null,
      },
    ]);
    handleSearch();
    getBank();
  }, []);

  useEffect(() => {
    if (rowSelected.length > 0) {
      const customer_id = rowSelected[0].customer_id;
      setItems((prevItems) =>
        prevItems.map((item) => {
          if (item.customer_id !== customer_id) {
            return { ...item, rowDisabled: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setItems((prevItems) =>
        prevItems.map((item) => {
          return { ...item, rowDisabled: false };
        })
      );
    }
  }, [rowSelected]);

  useEffect(() => {
    handleSearch();
  }, [pagination.per_page]);

  const handleOnChangeSearch = (e, key) => {
    if (key === "date") {
      setFormSearch({ ...formSearch, dateFrom: e[0], dateTo: e[1] });
    } else {
      setFormSearch({ ...formSearch, [key]: e });
    }
  };

  const getBank = async () => {
    await getAllBank()
      .then((response) => {
        let results = response.data.data.results;
        setBankOptions(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = async (
    resetCheckBox = false,
    searchByPage = false,
    page = 1
  ) => {
    setIsBusy(true);
    setSelectAll(false);
    setDisabledSelectAll(false);
    const formdata = searchByPage ? formSearchOld : formSearch;
    setFormSearchOld(formdata);
    let queryParams = {
      dateFrom: moment(formdata.dateFrom).toISOString(),
      dateTo: moment(formdata.dateTo).toISOString(),
      sort_field: [formdata.sortBy],
      sort_order: [-1],
      page: page,
      per_page: pagination.per_page,
    };
    if (formdata.filterBy && formdata.filter) {
      queryParams = {
        ...queryParams,
        [formdata.filterBy]: formdata.filter.trim(),
      };
    }
    queryParams = helper.filteredParams(queryParams);
    try {
      const response = await getBillingnoteGroup(queryParams);
      if (response) {
        const { result, total, page, per_page } = response.data.data;
        const uniqueProjectIds = [
          ...new Set(result.map((item) => item.customer_id)),
        ];

        const updatedResult = result.map((item) => {
          let rowIsActive = false;
          let rowDisabled = false;

          if (!resetCheckBox) {
            if (rowSelected.length > 0) {
              const customer_id = rowSelected[0].customer_id;
              rowIsActive = rowSelected.some((selectedItem) => {
                return selectedItem.key === item.key;
              });
              rowDisabled = customer_id !== item.customer_id;
            }
          }

          return {
            ...item,
            rowIsActive: rowIsActive,
            rowDisabled: rowDisabled,
          };
        });

        setItems(updatedResult);
        setPagination({
          page: page,
          per_page: per_page,
          total: total,
        });
        setIsBusy(false);
        setDisabledSelectAll(uniqueProjectIds.length > 1);
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "error.response.data.description"
      );
    }
  };

  const handleUpdatestatusFormChange = (name, event) => {
    if (["actual_date", "payment_due_date", "bank_id", "file"].includes(name)) {
      const value = event;
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "decimal") {
      const value = event;
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["bill_codes"].push(val["key"]);
            } else if (
              ["amount_per_trip", "vat_per_trip", "total_per_trip"].includes(
                key
              )
            ) {
              result[key] = currency(result[key]).add(val[key]).value;
            }
          });
          return result;
        },
        {
          bill_codes: [],
          amount_per_trip: 0,
        }
      );
      const vat = currency(groupdata.amount_per_trip).multiply(0.07).value;
      const total = currency(groupdata.amount_per_trip).add(vat).value;
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display",
        value
      );
      const vat_per_trip = helper.formatNumeric(vat, "display", value);
      const total_per_trip = helper.formatNumeric(total, "display", value);
      setUpdateStatusForm((prevData) => ({
        ...prevData,
        [name]: value,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
        amount_of_transfer_actual: total_per_trip,
      }));
    } else {
      const value = helper.sanitizeInput(event.target.value);
      if (["vat_per_trip"].includes(name)) {
        const amount = updateStatusForm.amount_per_trip;
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
          total_per_trip: currency(amount, {
            precision: updateStatusForm.decimal,
            symbol: "",
          })
            .add(value)
            .format(),
        }));
      } else if (name === "credit_remark") {
        const lines = value.split("\n").slice(0, 8);
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          credit_remark: lines.join("\n"),
        }));
      } else if (name === "unit_price") {
        if (!isNaN(value) && value.trim() !== "") {
          setUpdateStatusForm((prevData) => ({
            ...prevData,
            unit_price: parseFloat(value),
          }));
        }
      } else {
        setUpdateStatusForm((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleOnclickUpdateStatusButton = async () => {
    if (rowSelected.length > 0) {
      const groupdata = rowSelected.reduce(
        (result, val) => {
          Object.keys(val).forEach((key) => {
            if (key === "key") {
              result["bill_codes"].push(val["key"]);
            } else if (
              ["amount_per_trip", "vat_per_trip", "total_per_trip"].includes(
                key
              )
            ) {
              result[key] = currency(result[key]).add(val[key]).value;
            } else if (["payment_date_forecast"].includes(key)) {
              if (val[key] !== null) {
                result["payment_date_forecast"].push(val[key]);
              }
            }
          });
          return result;
        },
        {
          bill_codes: [],
          amount_per_trip: 0,
          vat_per_trip: 0,
          total_per_trip: 0,
          payment_date_forecast: [],
        }
      );
      const amount_per_trip = helper.formatNumeric(
        groupdata.amount_per_trip,
        "display"
      );
      const vat_per_trip = helper.formatNumeric(
        groupdata.vat_per_trip,
        "display"
      );
      const total_per_trip = helper.formatNumeric(
        groupdata.total_per_trip,
        "display"
      );
      let min_payment_date_forecast = null;
      if (groupdata.payment_date_forecast.length > 0) {
        min_payment_date_forecast = new Date(
          Math.min(
            ...groupdata.payment_date_forecast.map((date) => new Date(date))
          )
        ).toISOString();
      }
      setUpdateStatusForm({
        actual_date: moment().format("YYYY-MM-DD kk:mm"),
        bill_codes: groupdata.bill_codes,
        amount_per_trip: amount_per_trip,
        vat_per_trip: vat_per_trip,
        total_per_trip: total_per_trip,
        decimal: 2,
        amount_of_transfer_actual: total_per_trip,
        bank_id: 1,
        ref: "",
        file: "",
        credit_remark: "",
        payment_due_date:
          min_payment_date_forecast === null
            ? moment().format("YYYY-MM-DD kk:mm")
            : moment(min_payment_date_forecast).format("YYYY-MM-DD kk:mm"),
      });
      return true;
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณาเลือกงานมากกว่า 1 งาน"
      );
      return false;
    }
  };

  const handleSubmitUpdateStatus = async () => {
    const insertData = {
      bill_codes: updateStatusForm.bill_codes,
      actual_date: updateStatusForm.actual_date,
      amount_per_trip: currency(updateStatusForm.amount_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      vat_per_trip: currency(updateStatusForm.vat_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      total_per_trip: currency(updateStatusForm.total_per_trip, {
        precision: updateStatusForm.decimal,
      }).value,
      decimal: updateStatusForm.decimal,
      amount_of_transfer_actual: currency(
        updateStatusForm.amount_of_transfer_actual
      ).value,
      bank_id: updateStatusForm.bank_id,
      ref: updateStatusForm.ref,
      file: updateStatusForm.file,
      credit_remark: updateStatusForm.credit_remark,
      payment_due_date: updateStatusForm.payment_due_date,
      unit_price: updateStatusForm.unit_price,
    };
    const newError = {};
    Object.entries(insertData).forEach(([key, value]) => {
      if (!["credit_remark"].includes(key)) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === 0
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });
    const check_actual_date = moment(
      updateStatusForm.actual_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    const check_payment_due_date = moment(
      updateStatusForm.payment_due_date,
      "YYYY-MM-DD kk:mm"
    ).isValid();
    newError.actual_date = !check_actual_date;
    newError.payment_due_date = !check_payment_due_date;
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await createPaid(insertData);
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
          setRowSelected([]);
          handleSearch(true);
          return true;
        }
      } catch (error) {
        console.log(error.response.data.description);
        openNotificationWithIcon("error", "Notification Error", "Error");
        return false;
      }
    } else {
      setError(newError);
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
    return false;
  };

  const handleCancelStatus = async () => {
    try {
      const response = await cancelBillingnote(rowSelected[0].bill_code);
      if (response) {
        openNotificationWithIcon(
          "success",
          "Notification Success",
          response.data.description
        );
        setRowSelected([]);
        handleSearch(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
      return false;
    }
  };

  const handlePagination = (p) => {
    handleSearch(false, true, p.page);
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          height: "100px",
          width: "100%",
          padding: "26px 37px",
          borderRadius: "10px",
          background: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`กรองและจัดเรียงตาม`}
              options={[{ value: "bill_date", name: "วันที่วางบิลล่าสุด" }]}
              value={formSearch.sortBy}
              onChange={(e) => handleOnChangeSearch(e, "sortBy")}
            />
          </Grid>
          <Grid item xs={3}>
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: formSearch.dateFrom,
                end_date: formSearch.dateTo,
              }}
              onChange={(e) => handleOnChangeSearch(e, "date")}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: "order_code", name: "เลขที่คําสั่งซื้อ" },
                { value: "invoice_code", name: "เลขที่ใบแจ้งหนี้" },
                { value: "bill_code", name: "เลขที่ใบวางบิล" },
              ]}
              value={formSearch.filterBy}
              onChange={(e) => handleOnChangeSearch(e, "filterBy")}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldSearch
              className="w-100"
              value={formSearch.filter}
              onChange={(e) => handleOnChangeSearch(e, "filter")}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchButton onClick={handleSearch} />
            <UpdateStatusButton2way
              title="ชำระเงิน / ยกเลิกการวางบิล"
              description="คุณต้องการชำระเงินหรือยกเลิกการวางบิล?"
              textUpdateBtn="ชำระเงิน"
              textCancelBtn="ยกเลิกการวางบิล"
              cancelDescription="ยืนยันยกเลิกการวางบิล?"
              onClick={handleOnclickUpdateStatusButton}
              onConfirmCancel={handleCancelStatus}
              onConfirmUpdate={handleSubmitUpdateStatus}
              selectedLength={rowSelected.length}
              openNotificationWithIcon={openNotificationWithIcon}
            >
              {renderFormUpdateStatus()}
            </UpdateStatusButton2way>
            <FormControl sx={{ marginLeft: "10px" }}>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                }}
              />
              {/* <FieldSelect
                className="w-100"
                name="จำนวนแถว"
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                value={pagination.per_page}
                onChange={(e) => setPagination({ ...pagination, per_page: e })}
              /> */}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderFormUpdateStatus = () => {
    return (
      <Box sx={{ width: "360px" }}>
        <form className="form-updatestatus" autoComplete="off">
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันเวลาที่ต้องการชำระเงิน *"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน)"
              value={updateStatusForm.actual_date}
              onChange={(e) => handleUpdatestatusFormChange("actual_date", e)}
              showTime
              error={error.actual_date}
              helperText={error.actual_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldDatePicker
              className="w-100"
              label="วันที่กำหนดชำระเงิน*"
              subLabel="(ค่าเริ่มต้น: วันและเวลาปัจจุบัน) (ลูกค้าเงินสด load_time_end +1 วัน)"
              value={updateStatusForm.payment_due_date}
              onChange={(e) =>
                handleUpdatestatusFormChange("payment_due_date", e)
              }
              showTime
              error={error.payment_due_date}
              helperText={error.payment_due_date ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="รวมจำนวนคำสั่ง"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.bill_codes.length}
              readOnly
              error={error.bill_codes}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name="จำนวนทศนิยม"
              options={[
                { value: 2, name: "2" },
                { value: 4, name: "4" },
                { value: 6, name: "6" },
              ]}
              value={updateStatusForm.decimal}
              onChange={(e) => handleUpdatestatusFormChange("decimal", e)}
              error={error.decimal}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาซื้อ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.amount_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.amount_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมภาษีมูลค่าเพิ่ม"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.vat_per_trip}
              onChange={(e) => handleUpdatestatusFormChange("vat_per_trip", e)}
              type="float"
              precision={updateStatusForm.decimal}
              error={error.vat_per_trip}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="รวมราคาซื้อสุทธิ"
              subLabel="(ผลรวมจากการเลือก)"
              value={updateStatusForm.total_per_trip}
              type="float"
              precision={updateStatusForm.decimal}
              readOnly
              error={error.total_per_trip}
            />
          </FormControl>

          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="Unit Price*"
              value={updateStatusForm.unit_price}
              onChange={(e) => handleUpdatestatusFormChange("unit_price", e)}
              error={error.unit_price}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldNumber
              className="w-100"
              label="จำนวนเงินที่ชำระจริง*"
              subLab=" (ค่าเริ่มต้น: รวมราคาลูกค้าสุทธิ)"
              value={updateStatusForm.amount_of_transfer_actual}
              onChange={(e) =>
                handleUpdatestatusFormChange("amount_of_transfer_actual", e)
              }
              type="float"
              error={error.amount_of_transfer_actual}
              helperText={
                error.amount_of_transfer_actual ? "จำเป็นต้องระบุ" : ""
              }
              precision={updateStatusForm.decimal}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldSelect
              className="w-100"
              name={`ธนาคารที่ชำระ*`}
              options={bankOptions}
              value={updateStatusForm.bank_id}
              onChange={(e) => handleUpdatestatusFormChange("bank_id", e)}
              error={error.bank_id}
              errorText={error.bank_id ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="ref*"
              value={updateStatusForm.ref}
              onChange={(e) => handleUpdatestatusFormChange("ref", e)}
              error={error.ref}
              helperText={error.ref ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldUpload
              name={"สลิปโอนเงิน*"}
              icon={<UploadFile />}
              value={updateStatusForm.file}
              accept="image/png, image/jpeg, application/pdf"
              onChange={(e) => handleUpdatestatusFormChange("file", e)}
              error={error.file}
              helperText={error.file ? "จำเป็นต้องระบุ" : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FieldInput
              className="w-100"
              label="หมายเหตุ"
              value={updateStatusForm.credit_remark}
              onChange={(e) => handleUpdatestatusFormChange("credit_remark", e)}
              multiline={true}
              maxRows={4}
            />
          </FormControl>
        </form>
      </Box>
    );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderTable = () => {
    const fields = [
      {
        key: "actual_date",
        label: "วันที่วางบิล",
        tdclass: "text-center",
        render: "date",
      },
      {
        key: "bill_code",
        label: "เลขที่ใบวางบิล",
        tdclass: "text-center",
      },
      {
        key: "customer_name",
        label: "ผู้ซื้อ",
      },
      {
        key: "project_name",
        label: "ชื่อโครงการ",
      },
      // {
      //   key: "trade_jobber",
      //   label: "ผู้ขาย",
      //   tdclass: "text-center",
      // },
      {
        key: "amount_per_trip",
        label: "ราคาสุทธิ",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "vat_per_trip",
        label: "ภาษีมูลค่าเพิ่ม",
        tdclass: "text-end",
        render: "currency",
      },
      {
        key: "total_per_trip",
        label: "จำนวนเงินรวมทั้งสิ้น",
        tdclass: "text-end",
        render: "currency",
      },
    ];

    return (
      <>
        <FinanceTable
          items={items}
          setItems={setItems}
          fields={fields}
          totalRows={items.length}
          setRowSelected={setRowSelected}
          rowSelected={rowSelected}
          pagination={pagination}
          setPagination={handlePagination}
          isBusy={isBusy}
          disabledSelectAll={disabledSelectAll}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          type="buyer"
        />
      </>
    );
  };

  return (
    <div className="Finance ShipperUnpiad">
      {contextHolder}
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
        {renderTable()}
      </Container>
    </div>
  );
};

export default TradeBuyerBillingNoteGroup;
