import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import { getAllFuelRate } from "../../../../utils/api/masterData/fuelRate";
import SubMenu from "components/layout/SubMenu.js";
import FilterTab from "components/filterTab/FilterTab";
import { subMenuMasterData } from "utils/submenuoptions";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import TableCustom from "components/table/TableCustom";
import TableActiveIcon from "components/tableActiveIcon";
import FloatingButton from "components/button/FloatingButton";
import { getAllWemovedriveVersion } from "utils/api/masterData/appVersion";
import CreateWemovedriveVersionModal from "./CreateWemovedriveVersionModal";
import helper from "utils/helper";
import AlertModal from "components/modal/AlertModal";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "version_code",
    label: "เวอร์ชัน",
    align: "center",
    format: (value) => {
      return value.version_code ? value.version_code : "-";
    },
  },
  {
    id: "detail",
    label: "คำอธิบาย",
    align: "center",
    format: (value) => {
      return value.detail ? value.detail : "-";
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const WemovedriveVersion = () => {
  const navigate = useNavigate();
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `version_code`,
    search_val: ``,
  };

  const [wemovedriveVersion, setWemovedriveVersion] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });

    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
  }) => {
    const params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      page: page,
      per_page: per_page,
      [filter.search_field]: filter.search_val,
    };

    handleGetAllWemovedriveVersion({
      page: page,
      per_page: per_page,
      new_params: params,
    });
    setParams(params);
  };

  const handleGetAllWemovedriveVersion = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }

      const resp = await getAllWemovedriveVersion(new_params);
      setWemovedriveVersion(resp.data.data.results);
      setPagination({
        page: page,
        per_page: per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error handleGetAllWemovedriveVersion: ", error);
      setIsLoading(false);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`อัพเดทเวอร์ชัน WeMoveDrive`}
        menus={subMenuMasterData.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `อัพเดทเวอร์ชัน WeMoveDrive` ? true : false,
          };
        })}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "อัพเดทเวอร์ชัน WeMoveDrive",
            path: null,
          },
        ]}
      />
    );
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchFieldOption={{
          value: filter.search_field,
          options: [{ value: "version_code", name: "เวอร์ชัน" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchValueOption={{
          onPressEnter: (field_type, val) => {
            handleGetDataByNewParams({ page: 1 });
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchButtonOption={{
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetAllWemovedriveVersion({
              page: 1,
              per_page: value,
            });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    return (
      <div>
        <FieldSelect
          name="กรองและจัดเรียงตาม"
          value={"updated_at"}
          options={[
            {
              value: "updated_at",
              name: "วันที่แก้ไขข้อมูลล่าสุด",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("updated_at", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldDateRangePicker
          name={`วันเริ่มต้น - วันสิ้นสุด`}
          popupClassName="FieldDateRangePicker-drawer-popup"
          value={{
            start_date: filter.start_date,
            end_date: filter.end_date,
          }}
          onChange={(val) => {
            onChangeFilter(`date_duration`, val);
          }}
          style={{ width: `100%`, marginBottom: 26 }}
        />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={wemovedriveVersion}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          handleGetAllWemovedriveVersion({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        href={`/master-data/app-version/wemovedrive-version/`}
      />
    );
  };
  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <>
      <Container>
        {renderSubMenu()}
        {renderFilterTab()}
        {renderTable()}

        <FloatingButton
          button_type="add"
          onClick={() => {
            setOpenCreateModal(true);
          }}
        />
      </Container>

      <CreateWemovedriveVersionModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        updateTableData={async () => {
          handleGetAllWemovedriveVersion({ page: 1 });
        }}
      />

      {renderModal()}
    </>
  );
};

export default WemovedriveVersion;
