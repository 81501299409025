import {
  Avatar,
  Container,
  Drawer,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import TableAntdCustom from "components/table/TableAntd/TableAntdCustom";
import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  cancelTradeTransport,
  cancelOrderTrade,
  getAllOrder,
} from "utils/api/trade/trade";
import { render } from "@testing-library/react";
import helper from "utils/helper";
import ButtonCustom from "components/ButtonCustom";
import styles from "./TableTrade.module.css";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import {
  Search,
  Add,
  Edit,
  Delete,
  FilterAlt,
  RotateRight,
} from "@mui/icons-material";
import ModalAddOrder from "./ModalAddOrder";
import CustomModal from "components/modal/CustomModal";
import ModalTransport from "./ModalTransport";
import AlertModal from "components/modal/AlertModal";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ModalPaidTransport from "./ModalPaidTransport";
import Loading from "components/loading/LoadingBackground";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import {
  getTradePaymentStatus,
  getWorkTradeStatus,
} from "utils/api/masterData/service";
import ModalUpdateStatusTransport from "./ModalUpdateStatusTransport";
import SelectRowValue from "components/button/SelectRowValue";
const TableTrade = () => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModalOrder, setOpenModalOrder] = useState({
    open: false,
    type: "",
    data: null,
  });
  const [openModalTransport, setOpenModalTransport] = useState({
    open: false,
    type: "",
    data: null,
    order_id: null,
    order_code: null,
    trade_project_id: null,
  });
  const [openModalUpdateStatusTransport, setOpenModalUpdateStatusTransport] =
    useState({
      open: false,
      data: null,
    });
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [cancelModal, setCancelModal] = useState({ open: false, id: null });
  const [cancelTransport, setCancelTransport] = useState({
    open: false,
    id: null,
  });
  const [editData, setEditData] = useState(null);
  const [editDataTransport, setEditDataTransport] = useState(null);
  const [dataOrder, setDataOrder] = useState([]);
  const dateTo = moment().format("YYYY-MM-DD 23:59:59");
  const dateFrom = moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00");
  const [paidTransport, setPaidTransport] = useState({ open: false, id: null });
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [listWorkTradeStatus, setListWorkTradeStatus] = useState([]);
  const [listTradePaymentStatus, setListTradePaymentStatus] = useState([]);
  const [filter, setFilter] = useState({
    sort_by: "created_at",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
  });

  console.log("dataOrder", dataOrder);
  const subMenu = [
    {
      label: "รายการสินค้า",
      path: "/trade",
    },
    {
      label: "รายชื่อผู้ซื้อ",
      path: "/trade/customer",
    },
    {
      label: "รายชื่อผู้ขายสินค้า",
      path: "/trade/jobber",
    },
  ];
  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: null,
    },
  ];

  useEffect(() => {
    getOrder(filter);
  }, [filter.page, filter.per_page]);

  useEffect(() => {
    getWorkTradeStatusMaster();
    getTradePaymentStatusMaster();
  }, []);
  const getWorkTradeStatusMaster = async () => {
    await getWorkTradeStatus()
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 1000) {
          const results = res.data.data.results;
          setListWorkTradeStatus(
            results?.map((res) => ({ value: res.id, name: res.name }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getTradePaymentStatusMaster = async () => {
    await getTradePaymentStatus()
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 1000) {
          const results = res.data.data.results;
          setListTradePaymentStatus(
            results?.map((res) => ({ value: res.id, name: res.name }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getOrder = async (_filter) => {
    setLoading(true);
    await getAllOrder(_filter)
      .then((res) => {
        console.log(res);
        const results = res.data.results;
        results.forEach((item) => {
          item.trade_transports.forEach((option) => {
            option.sell_amount_unit = item.sell_amount_unit;
          });
        });

        setDataOrder(results);
        setFilter({ ...filter, total: res.data.total });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  const handleCancelOrder = async (id) => {
    await cancelOrderTrade(id)
      .then((res) => {
        if (res.data.code === 1000) {
          setCancelModal({ open: false, id: null });
          getOrder(filter);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCancelTransport = async (id) => {
    await cancelTradeTransport(id)
      .then((res) => {
        if (res.data.code === 1000) {
          setCancelTransport({ open: false, id: null });
          getOrder(filter);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columnsMain = [
    {
      title: "เลขที่ใบสั่งซื้อ",
      dataIndex: "order_code",
      key: "order_code",
    },
    {
      title: "วันที่สั่งซื้อ",
      //   dataIndex: "puchase_date",
      key: "puchase_date",

      render: (val) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {val?.puchase_date
              ? helper.ToConvertDateTimeForTable(val?.puchase_date, false)
              : ""}
          </div>
        );
      },
    },
    {
      title: "ลูกค้า",
      key: "customer",
      render: (value) => {
        return <div>{value?.trade_project.trade_customers.company_name}</div>;
      },
    },
    {
      title: "โครงการ",
      key: "project",
      render: (value) => <div>{value.trade_project.project_name}</div>,
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "จำนวนสินค้าที่ซื้อขาย",
      // dataIndex: "sell_amount",
      key: "sell_amount",

      render: (val) => {
        const status = val.trade_transports.filter(
          (value) => value?.trip_status !== 3
        );
        let sumary = 0;
        if (status.length !== 0) {
          sumary = status.reduce((accumulator, currentItem) => {
            return (
              (accumulator ? accumulator : 0) +
              (currentItem?.amount_per_trip
                ? parseFloat(currentItem?.amount_per_trip)
                : 0)
            );
          }, 0);
        }

        const amount = val?.sell_amount - sumary;

        const resultSellAmount = val?.sell_amount
          ? helper.addComma(parseFloat(val?.sell_amount)?.toFixed(2)) +
            " " +
            (val?.sell_amount_unit || "")
          : null;

        const balance =
          amount > 0 ? `(เหลือ  ${helper.addComma(amount?.toFixed(2))})` : "";
        const over =
          amount < 0
            ? `(เกิน  ${helper.addComma(Math.abs(amount)?.toFixed(2))})`
            : "";

        return (
          <div>
            <div>{resultSellAmount || ""}</div>
            <div style={{ color: balance ? "red" : "blue", fontSize: "11px" }}>
              {balance ? balance : over}
            </div>
          </div>
        );
      },
    },
    {
      title: "รวมราคาสินค้าที่ขาย (บาท)",
      // dataIndex: "sell_price",
      key: "sell_price",
      render: (val) =>
        val?.sell_price
          ? helper.addComma(parseFloat(val?.sell_price)?.toFixed(2))
          : null,
    },
    {
      title: "สถานะงานล่าสุด",
      key: "work_trade_status",

      render: (val) => {
        return (
          <div
            style={{
              color: val?.workTradeStatus?.id === 3 ? "#27ce88" : "#121f43",
            }}
          >
            {val?.workTradeStatus?.name}
          </div>
        );
      },
    },
    {
      title: "สถานะการชำระเงินล่าสุด",
      //   dataIndex: "trade_payment_status",
      key: "trade_payment_status",

      render: (val) => {
        return (
          <div
            style={{
              color: val?.tradePaymentStatus?.id === 3 ? "#27ce88" : "#121f43",
            }}
          >
            {val?.tradePaymentStatus?.name}
          </div>
        );
      },
    },
    {
      title: "รวมชำระแล้ว",
      key: "paid",

      render: (val) => {
        const paid = val.trade_transports.filter(
          (value) => value?.buyer_payment_status === 7
        );
        let sumary = 0;
        if (paid.length !== 0) {
          sumary = paid.reduce((accumulator, currentItem) => {
            return (
              (accumulator ? accumulator : 0) +
              (currentItem?.sell_per_trip
                ? parseFloat(currentItem?.sell_per_trip)
                : 0)
            );
          }, 0);
        }

        return <div>{helper.addComma(sumary.toFixed(2))}</div>;
      },
    },
    {
      title: "รวมค้างชำระทั้งหมด",
      key: "unpaid",

      render: (val) => {
        const paid = val.trade_transports.filter(
          (value) =>
            value?.buyer_payment_status !== 2 &&
            value?.buyer_payment_status !== 7
        );
        let sumary = 0;
        console.log("paid", paid);
        if (paid.length !== 0) {
          sumary = paid.reduce((accumulator, currentItem) => {
            return (
              (accumulator ? accumulator : 0) +
              (currentItem?.sell_per_trip
                ? parseFloat(currentItem?.sell_per_trip)
                : 0)
            );
          }, 0);
        }

        return <div>{helper.addComma(sumary.toFixed(2))}</div>;
      },
    },
    {
      title: "ทำรายการ",
      key: "actions",
      render: (val) => (
        <Grid container spacing={2} style={{ minWidth: "140px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              variant="rounded"
              sx={{
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() =>
                val?.active
                  ? setOpenModalOrder({ open: true, type: "edit", data: val })
                  : null
              }
            >
              <Edit
                sx={{
                  color: val?.active ? "secondary.main" : "gray.main",
                  fontSize: 23,
                }}
              />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              variant="rounded"
              sx={{
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() =>
                val?.active
                  ? setOpenModalTransport({
                      open: true,
                      type: "create",
                      data: null,
                      order_id: val?.id,
                      order_code: val?.order_code,
                      trade_project_id: val?.trade_project_id,
                    })
                  : null
              }
            >
              <Add
                sx={{
                  color: val?.active ? "secondary.main" : "gray.main",
                  fontSize: 23,
                }}
              />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              variant="rounded"
              sx={{
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() => {
                const allTransportInactive = val?.trade_transports.every(
                  (value) => value?.active === false
                );
                return val?.active && allTransportInactive
                  ? setCancelModal({ open: true, id: val?.id })
                  : null;
              }}
            >
              <Delete
                sx={{
                  color: (() => {
                    const allTransportInactive = val?.trade_transports.every(
                      (value) => value?.active === false
                    );
                    return val?.active && allTransportInactive
                      ? "secondary.main"
                      : "gray.main";
                  })(),
                  fontSize: 23,
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      ),
    },
    {
      title: "ผู้ทำรายการ",
      key: "admin",
      render: (val) => {
        return (
          <div>{val.admin_info.firstname + " " + val.admin_info.lastname}</div>
        );
      },
    },
  ];

  const columnsExpand = [
    {
      title: "เลขที่ใบสั่งซื้อ",
      dataIndex: "order_code",
      key: "order_code",
      render: (val, row) => {
        return (
          <div style={{ textAlign: "center" }}>
            <span>{val}</span>
            <br />
            <span>#{row.id}</span>
          </div>
        );
      },
    },
    {
      title: "วันที่ส่งเสร็จ",
      key: "sent_date",
      render: (val) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {val?.sent_date
              ? helper.ToConvertDateTimeForTable(val?.sent_date, false)
              : ""}
          </div>
        );
      },
    },
    {
      title: "ชื่อจุดส่ง",
      // dataIndex: "trade_destination",
      key: "destination_name",
      render: (val) => {
        return val?.destination_name ? (
          <Typography
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
              fontSize: "14px",
            }}
            onClick={() =>
              window.open(
                `https://maps.google.com/?q=${
                  val?.trade_destination
                    ? val?.trade_destination
                    : val?.destination_name
                }`,
                "_blank"
              )
            }
          >
            {val?.destination_name}
          </Typography>
        ) : null;
      },
    },
    {
      title: "ผู้ขาย",
      key: "trade_jobber",
      render: (val) => val?.tradeJobber?.jobber_name,
    },
    {
      title: "จำนวนสินค้าที่ซื้อขายจริง",
      // dataIndex: "amount_per_trip",
      key: "amount_per_trip",
      render: (val) =>
        val?.amount_per_trip
          ? helper.addComma(parseFloat(val?.amount_per_trip)?.toFixed(2)) +
            " " +
            val?.sell_amount_unit
          : null,
    },
    {
      title: "ราคาสินค้าที่ซื้อ (บาท)",
      // dataIndex: "buy_per_trip",
      key: "buy_per_trip",
      render: (val) =>
        val?.buy_per_trip
          ? helper.addComma(parseFloat(val?.buy_per_trip)?.toFixed(2))
          : null,
    },
    {
      title: "ราคาสินค้าที่ขาย (บาท)",
      // dataIndex: "sell_per_trip",
      key: "sell_per_trip",
      render: (val) =>
        val?.sell_per_trip
          ? helper.addComma(parseFloat(val?.sell_per_trip)?.toFixed(2))
          : null,
    },
    {
      title: "ราคาขนส่ง(บาท)",
      // dataIndex: "sell_per_trip",
      key: "carrier_freight_before_tax",
      render: (val) =>
        val?.carrier_freight_before_tax
          ? helper.addComma(
              parseFloat(val?.carrier_freight_before_tax)?.toFixed(2)
            )
          : null,
    },
    {
      title: "กำไร (บาท)",
      // dataIndex: "profit_bath",
      key: "profit_bath",
      render: (val) =>
        val?.profit_bath
          ? helper.addComma(parseFloat(val?.profit_bath)?.toFixed(2))
          : null,
    },
    {
      title: "กำไร (%)",
      // dataIndex: "profit_percent",
      key: "profit_percent",
      render: (val) =>
        val?.profit_percent
          ? helper.addComma(parseFloat(val?.profit_percent)?.toFixed(2))
          : null,
    },
    {
      title: "สถานะการจัดส่ง",
      key: "trip_status",
      render: (val) => {
        return (
          <div
            style={{
              color:
                val?.tripStatus?.id === 1
                  ? "#27ce88"
                  : val?.tripStatus?.id === 3
                  ? "#ff316f"
                  : "#ffc675",
            }}
          >
            {val?.tripStatus?.name}
          </div>
        );
      },
    },
    {
      title: "สถานะการชำระเงินผู้ซื้อ",
      key: "buyer_payment_status",
      render: (val) => {
        return (
          <div
            style={{
              color:
                val?.buyerPaymentStatus?.id === 1
                  ? "blue"
                  : val?.buyerPaymentStatus?.id === 2 ||
                    val?.buyerPaymentStatus?.id === 4 ||
                    val?.buyerPaymentStatus?.id === 6 ||
                    val?.buyerPaymentStatus?.id === 8
                  ? "#ff316f"
                  : val?.buyerPaymentStatus?.id === 7
                  ? "#27ce88"
                  : "#ffc675",
            }}
          >
            {val?.buyerPaymentStatus?.name}
          </div>
        );
      },
    },
    {
      title: "สถานะการชำระเงินผู้ขาย",
      key: "seller_payment_status",
      render: (val) => {
        return (
          <div
            style={{
              color:
                val?.sellerPaymentStatus?.id === 1
                  ? "blue"
                  : val?.sellerPaymentStatus?.id === 2 ||
                    val?.sellerPaymentStatus?.id === 4 ||
                    val?.sellerPaymentStatus?.id === 6 ||
                    val?.sellerPaymentStatus?.id === 8
                  ? "#ff316f"
                  : val?.sellerPaymentStatus?.id === 7
                  ? "#27ce88"
                  : "#ffc675",
            }}
          >
            {val?.sellerPaymentStatus?.name}
          </div>
        );
      },
    },
    {
      title: "สถานะการชำระเงินผู้ขนส่ง",
      key: "carrier_payment_status",
      render: (val) => {
        return (
          <div
            style={{
              color:
                val?.carrierPaymentStatus?.id === 1
                  ? "blue"
                  : val?.carrierPaymentStatus?.id === 4 ||
                    val?.carrierPaymentStatus?.id === 6 ||
                    val?.carrierPaymentStatus?.id === 8
                  ? "#ff316f"
                  : val?.carrierPaymentStatus?.id === 7 ||
                    val?.carrierPaymentStatus === null ||
                    val?.carrierPaymentStatus?.id === 2
                  ? "#27ce88"
                  : "#ffc675",
            }}
          >
            {val?.carrierPaymentStatus === null ||
            val?.carrierPaymentStatus?.id === 2
              ? "ไม่มีค่าขนส่ง"
              : val?.carrierPaymentStatus?.name}
          </div>
        );
      },
    },
    {
      title: "วันที่ชำระเงิน",
      key: "payment_date",
      render: (val) => {
        // return val?.payment_date ? (
        return (
          <div>
            {val?.payment_date
              ? helper.ToConvertDateTimeForTable(val?.payment_date, false)
              : null}
          </div>
        );
        // ) : (
        //   <ButtonCustom
        //     name="ชำระ"
        //     style={{ padding: 0 }}
        //     onClick={() => {
        //       setPaidTransport({ open: true, id: val?.id });
        //     }}
        //     disabled={!val?.active}
        //   />
        // );
      },
    },
    {
      title: "ทำรายการ",
      key: "actions",
      render: (val) => (
        <Grid container spacing={2} style={{ minWidth: "140px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              variant="rounded"
              sx={{
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              // onClick={() =>
              //   val?.active &&
              //   val?.buyer_payment_status === 1 &&
              //   val?.seller_payment_status === 1
              //     ? setOpenModalTransport({
              //         open: true,
              //         type: "edit",
              //         data: val,
              //         order_id: null,
              //         order_code: null,
              //         trade_project_id: null,
              //       })
              //     : null
              // }
              onClick={() =>
                setOpenModalTransport({
                  open: true,
                  type: "edit",
                  data: val,
                  order_id: null,
                  order_code: null,
                  trade_project_id: null,
                })
              }
            >
              <Edit
                sx={{
                  // color:
                  //   val?.active &&
                  //   val?.buyer_payment_status === 1 &&
                  //   val?.seller_payment_status === 1
                  //     ? "secondary.main"
                  //     : "gray.main",
                  color: "secondary.main",
                  fontSize: 23,
                }}
              />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              variant="rounded"
              sx={{
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              // onClick={() =>
              //   val?.active &&
              //   val?.seller_payment_status === 1 &&
              //   val?.carrier_payment_status <= 2
              //     ? setOpenModalUpdateStatusTransport({
              //         open: true,
              //         data: val,
              //       })
              //     : null
              // }
              onClick={() =>
                setOpenModalUpdateStatusTransport({
                  open: true,
                  data: val,
                })
              }
            >
              <RotateRight
                sx={{
                  // color:
                  //   val?.active &&
                  //   val?.seller_payment_status === 1 &&
                  //   val?.carrier_payment_status <= 2
                  //     ? "secondary.main"
                  //     : "gray.main",
                  color: "secondary.main",
                  fontSize: 23,
                }}
              />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Avatar
              variant="rounded"
              sx={{
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() =>
                val?.active &&
                val?.buyer_payment_status === 1 &&
                val?.seller_payment_status === 1 &&
                val?.carrier_payment_status <= 2
                  ? setCancelTransport({ open: true, id: val?.id })
                  : null
              }
            >
              <Delete
                sx={{
                  color:
                    val?.active &&
                    val?.buyer_payment_status === 1 &&
                    val?.seller_payment_status === 1 &&
                    val?.carrier_payment_status <= 2
                      ? "secondary.main"
                      : "gray.main",
                  fontSize: 23,
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      ),
    },
    {
      title: "ผู้ทำรายการ",
      key: "admin",
      render: (val) => {
        return (
          <div>{val.admin_info.firstname + " " + val.admin_info.lastname}</div>
        );
      },
    },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"created_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "order_code", name: "เลขที่ใบสั่งซื้อ" },
                { value: "customer_name", name: "ลูกค้า" },
                { value: "project", name: "โครงการ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterSearch = { ...filter, page: 1 };
                getOrder(filterSearch);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <div>
              <SelectRowValue
                onSelect={(val) => {
                  setFilter({ ...filter, per_page: val });
                }}
              />
              {/* <FieldSelect
                name={"จำนวนแถว"}
                options={[
                  { value: 5, name: "5" },
                  { value: 10, name: "10" },
                  { value: 20, name: "20" },
                  { value: 50, name: "50" },
                  { value: 100, name: "100" },
                ]}
                defaultValue={5}
                value={filter.per_page}
                onChange={(val) => {
                  setFilter({ ...filter, per_page: val });
                }}
                closePaddingBottom={true}
                fullWidth={true}
              /> */}
            </div>
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate("/");
        }}
      />

      <Drawer
        anchor="right"
        open={advanceFilter}
        // open={true}
        onClose={() => {
          setAdvanceFilter(false);
          const filterA = { ...filter, page: 1 };
          getOrder(filterA);
        }}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available", paddingTop: "10px" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
            <FieldSelect
              name={"สถานะงานล่าสุด"}
              options={listWorkTradeStatus}
              style={{
                width: "-webkit-fill-available",
                paddingTop: "10px",
              }}
              value={filter.work_trade_status}
              onChange={(val) =>
                setFilter({ ...filter, work_trade_status: val })
              }
              closePaddingBottom={true}
            />{" "}
            <FieldSelect
              name={"สถานะการชำระเงินล่าสุด"}
              options={listTradePaymentStatus}
              style={{
                width: "-webkit-fill-available",
                paddingTop: "10px",
              }}
              value={filter.trade_payment_status}
              onChange={(val) =>
                setFilter({ ...filter, trade_payment_status: val })
              }
              closePaddingBottom={true}
            />
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() => {
                  setFilter({
                    sort_by: "created_at",
                    start_date: "",
                    end_date: "",
                    page: 1,
                    per_page: 5,
                    total: 0,
                  });
                }}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>

      <Container>
        {/*  <SubMenu
          menus={subMenu.map((menu) => {
            return {
              label: menu.label,
              path: menu.path,
              active: menu.label === `รายการสินค้า` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        /> */}
        <div style={{ paddingTop: "20px" }}></div>
        {filterBar()}

        <div style={{ paddingTop: "20px" }}>
          <TableAntdCustom
            mainColunmns={columnsMain}
            expandColumns={columnsExpand}
            mainData={dataOrder}
            onChange={(key, row) => setSelectedRows(row)}
            pagination={filter}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
            expandable={true}
            nameSubDataArray="trade_transports"
          />
        </div>
        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() =>
            setOpenModalOrder({ open: true, type: "create", data: null })
          }
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>
      </Container>
      {openModalOrder && (
        <ModalAddOrder
          open={openModalOrder.open}
          type={openModalOrder.type}
          handleClose={() => {
            setOpenModalOrder({ open: false, type: "", data: null });
            getOrder(filter);
          }}
          editData={openModalOrder.data}
        />
      )}
      <ModalTransport
        open={openModalTransport?.open}
        type={openModalTransport?.type}
        handleClose={() => {
          setOpenModalTransport({
            open: false,
            type: "",
            data: null,
            order_id: null,
            order_code: null,
            trade_project_id: null,
          });
          getOrder(filter);
        }}
        orderId={openModalTransport?.order_id}
        orderCode={openModalTransport?.order_code}
        editData={openModalTransport?.data}
        tradeProjectId={openModalTransport?.trade_project_id}
      />
      <ModalUpdateStatusTransport
        open={openModalUpdateStatusTransport?.open}
        handleClose={() => {
          setOpenModalUpdateStatusTransport({
            open: false,
            data: null,
          });
          getOrder(filter);
        }}
        editData={openModalUpdateStatusTransport?.data}
        tradeProjectId={openModalTransport?.trade_project_id}
      />

      <CustomModal
        open={cancelModal.open}
        title={"ยืนยันการยกเลิก"}
        onCancel={() => {
          setCancelModal({ open: false, id: null });
        }}
        onConfirm={async () => {
          handleCancelOrder(cancelModal.id);
        }}
      ></CustomModal>
      <CustomModal
        open={cancelTransport.open}
        title={"ยืนยันการยกเลิก"}
        onCancel={() => {
          setCancelTransport({ open: false, id: null });
        }}
        onConfirm={async () => {
          handleCancelTransport(cancelTransport.id);
        }}
      ></CustomModal>
      <ModalPaidTransport
        open={paidTransport.open}
        handleClose={() => {
          setPaidTransport({ open: false, id: null });
          getOrder(filter);
        }}
        idTransport={paidTransport.id}
      />
    </React.Fragment>
  );
};

export default TableTrade;
