import instance from "../../axiosInstance";
import helper from "utils/helper";

export const getAllWorkPublic = async (param) => {
  try {
    return await instance.get(`/api/work/public/get-all-work-public`, {
      params: param,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
