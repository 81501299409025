import { FormControl, Input, InputLabel } from "@mui/material";
import React from "react";
import "./inputDisable.css";
export default function InputNormal({
  error,
  value,
  onChange,
  label,
  id,
  name,
  subFirst,
  subSec,
  type = "text",
  extraType,
  maxLength,
  multiline = false,
  disable = false,
  rows = 1,
}) {
  return (
    <div
      className={
        "inputNormal " +
        (error ? "errorLabel" : "") +
        (disable ? " inputDisable" : "")
      }
    >
      <FormControl variant="standard" autoFocus fullWidth margin="none">
        <InputLabel color={error ? "error" : "primary"}>{label}</InputLabel>
        <Input
          type={type}
          error={error}
          id={id}
          label={label}
          value={value}
          name={name}
          multiline={multiline}
          rows={rows}
          onChange={(val) => {
            if (maxLength) {
              if (val.target.value.length <= maxLength) {
                if (extraType && extraType === "phone") {
                  if (val.target.value !== "") {
                    if (/^[0-9]/.test(val.target.value)) {
                      onChange(val);
                    }
                  } else {
                    onChange(val);
                  }
                } else {
                  onChange(val);
                }
              }
            } else {
              onChange(val);
            }
          }}
          sx={{
            backgroundColor: disable ? "rgb(140,163,186,0.2)" : "",
          }}
          disabled={disable}
        />
        <div className="inputNormalSub">
          <div>{subFirst}</div>
          <div>{subSec}</div>
        </div>
      </FormControl>
    </div>
  );
}
