import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { formLabelClasses } from "@mui/material";
const FieldPassword = ({
  name = ``,
  nameDescription = ``,
  onChange = () => {},
  style = {},
  error = false,
  textError = ``,
  value = ``,
  closePaddingBottom = false,
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        paddingBottom: error || closePaddingBottom ? 0 : "23px",
        ...style,
      }}
    >
      <InputLabel
        htmlFor="standard-adornment-password"
        sx={{
          color: error ? "error.main" : "grey.main",
          fontWeight: 500,
          lineHeight: nameDescription ? 1 : "",
          "& p": {
            fontSize: "10px",
          },
          [`&.${formLabelClasses.focused}`]: {
            marginTop: 0,
            "& p": {
              fontSize: "10px",
            },
          },
          [`&.${formLabelClasses.filled}`]: {
            marginTop: `0 !important`,
            "& p": {
              fontSize: "10px",
            },
          },
          marginTop: nameDescription ? "-10px" : "",
          padding: "0 14px",
        }}
      >
        {name}
        <br />
        {nameDescription && (
          <p style={{ margin: "5px 0" }}>{nameDescription}</p>
        )}
      </InputLabel>
      <Input
        id="standard-adornment-password"
        type={showPassword ? "text" : "password"}
        sx={{
          // width: "-webkit-fill-available",
          "& .MuiInput-input#standard-adornment-password": {
            padding: value ? "4px 14px 5px 14px" : "",
          },
        }}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <VisibilityOff
                  sx={{ color: error ? "error.main" : "grey.main" }}
                />
              ) : (
                <Visibility
                  sx={{ color: error ? "error.main" : "grey.main" }}
                />
              )}
            </IconButton>
          </InputAdornment>
        }
        onChange={(e) => {
          onChange(e.target.value);
        }}
        error={error}
      />
      {error && textError ? (
        <p
          style={{
            fontSize: "12px",
            fontFamily: "Kanit",
            lineHeight: 1.66,
            color: theme.palette.error.main,
            marginTop: "3px",
            marginBottom: 0,
            padding: "0 14px",
          }}
        >
          {textError}
        </p>
      ) : (
        ""
      )}
    </FormControl>
  );
};

export default FieldPassword;
