import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import TableActiveIcon from "components/tableActiveIcon";
import FloatingButton from "components/button/FloatingButton";
import UserDetailSubMenu from "components/layout/SubMenu.js/UserDetailSubMenu";
import FilterTab from "components/filterTab/FilterTab";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import TableCustom from "components/table/TableCustom";
import { getUserById } from "utils/api/user/user";
import { getAllCarrierInFavoriteGroup } from "utils/api/profile/carrierInFavoriteGroup";
import { getShipperFavoriteGroup } from "utils/api/profile/shipperFavoriteGroup";
import CreateCarrierInFavoriteGroupModal from "./CreateCarrierInFavoriteGroupModal";
import helper from "utils/helper";
import SubMenu from "components/layout/SubMenu.js";
import AlertModal from "components/modal/AlertModal";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "profile.user_info.user_code",
    label: "รหัสผู้ใช้งาน",
    align: "center",
    format: (value) => {
      return value.profile.user_info.user_code;
    },
  },
  {
    id: "profile.company_name",
    label: "ชื่อโปรไฟล์",
    align: "center",
    format: (value) => {
      return value.profile.company_name;
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const CarrierInFavoriteGroup = () => {
  const { user_id, profile_id, shipper_favorite_group_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `company_name`,
    search_val: ``,
    active: "all",
  };
  const [carrierInFavGroup, setCarrierInFavGroup] = useState([]);
  const [groupInfo, setGroupInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });
    handleGetFavShipperGroup();
    handleGetUserById();

    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
  }) => {
    const params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      page: page,
      per_page: per_page,
      [filter.search_field]: filter.search_val,
      active:
        filter.active !== `all`
          ? filter.active === `true`
            ? true
            : false
          : null,
    };

    handleGetCarrierInFavGroup({
      page: page,
      per_page: per_page,
      new_params: params,
    });
    setParams(params);
  };

  const handleGetCarrierInFavGroup = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }

      const resp = await getAllCarrierInFavoriteGroup(
        shipper_favorite_group_id,
        new_params
      );
      setCarrierInFavGroup(resp.data.data.results);
      setPagination({
        page: page,
        per_page: per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllCarrierInFavoriteGroup: ", error);
      setIsLoading(false);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
    }
  };

  const handleGetFavShipperGroup = async () => {
    setIsLoading(true);
    try {
      const resp = await getShipperFavoriteGroup(shipper_favorite_group_id);
      const data = resp.data.data.results;
      setGroupInfo(data);
    } catch (error) {
      setIsLoading(false);
      console.log("error getShipperFavoriteGroup: ", error);
    }
  };

  const handleGetUserById = async () => {
    try {
      const resp = await getUserById(user_id);
      if (resp.data.code === 1000) {
        setUserInfo(resp.data.data);
      }
    } catch (error) {
      console.log("error getUserById: ", error);
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`สมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ`}
        pathbackward={`${
          location.pathname.split("/shipper-favorite-group/")[0]
        }/shipper-favorite-group`}
        menus={[
          {
            label: "รายละเอียดกลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: `/user/${user_id}/profile/${profile_id}/shipper-favorite-group/detail/${shipper_favorite_group_id}`,
            active: true,
          },
          {
            label: "สมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: `/user/${user_id}/profile/${profile_id}/shipper-favorite-group/${shipper_favorite_group_id}/carrier-in-favorite-group`,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label:
              !R.isNil(userInfo) && !R.isEmpty(userInfo)
                ? userInfo.user_code
                : ``,
            path: null,
          },
          {
            label: "กลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: null,
          },
          {
            label:
              !R.isNil(groupInfo) && !R.isEmpty(groupInfo)
                ? groupInfo.name
                : ``,
            path: null,
          },
          {
            label: "สมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: null,
          },
        ]}
      />
    );

    // return (
    //   <UserDetailSubMenu
    //     label={`สมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ`}
    //     // pathbackward={-1}
    //     pathbackward={`/user/${user_id}/shipper-favorite-group/${profile_id}/`}
    //     profile_id={profile_id}
    //     user_id={user_id}
    //     breadcrumbs={[
    //       {
    //         label: "ข้อมูลผู้ใช้งาน",
    //         path: null,
    //       },
    //       {
    //         label:
    //           !R.isNil(userInfo) && !R.isEmpty(userInfo)
    //             ? userInfo.user_code
    //             : ``,
    //         path: null,
    //       },
    //       {
    //         label: "กลุ่มผู้ให้บริการที่ชื่นชอบ",
    //         path: null,
    //       },
    //       {
    //         label:
    //           !R.isNil(groupInfo) && !R.isEmpty(groupInfo)
    //             ? groupInfo.name
    //             : ``,
    //         path: null,
    //       },
    //       {
    //         label: "สมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ",
    //         path: null,
    //       },
    //     ]}
    //   />
    // );
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchFieldOption={{
          value: filter.search_field,
          options: [{ value: "company_name", name: "ชื่อโปรไฟล์" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchValueOption={{
          onPressEnter: (field_type, val) => {
            handleGetDataByNewParams({ page: 1 });
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchButtonOption={{
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetCarrierInFavGroup({
              page: 1,
              per_page: value,
            });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    const list_form = [
      {
        name: "กรองและจัดเรียงตาม",
        field_type: "select",
        value: "updated_at",
        options: [
          {
            value: "updated_at",
            name: "วันที่แก้ไขข้อมูลล่าสุด",
          },
        ],
        onChange: (val) => {
          onChangeFilter("updated_at", val);
        },
      },
      {
        name: "วันเริ่มต้น - วันสิ้นสุด",
        field_type: "dateRangePicker",
        value: { start_date: filter.start_date, end_date: filter.end_date },
        onChange: (val) => {
          onChangeFilter(`date_duration`, val);
        },
      },
      {
        name: "สถานะใช้งาน",
        field_type: "select",
        value: filter.active,
        options: [
          {
            value: "all",
            name: "ทั้งหมด",
          },
          {
            value: "true",
            name: "ใช้งาน",
          },
          {
            value: "false",
            name: "ไม่ใช้งาน",
          },
        ],
        onChange: (val) => {
          onChangeFilter(`active`, val);
        },
      },
    ];
    return (
      <div>
        {list_form.map((menu, index) => {
          if (menu.field_type === `select`) {
            return (
              <FieldSelect
                key={index}
                name={menu.name ? menu.name : ""}
                multiSelect={menu.multi_select}
                value={menu.value ? menu.value : ""}
                options={menu.options ? menu.options : []}
                onChange={menu.onChange}
                style={{ width: `100%` }}
              />
            );
          } else if (menu.field_type === `dateRangePicker`) {
            return (
              <FieldDateRangePicker
                key={index}
                name={menu.name}
                popupClassName="FieldDateRangePicker-drawer-popup"
                value={menu.value}
                onChange={menu.onChange}
                style={{ width: `100%`, marginBottom: 26 }}
              />
            );
          }
        })}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={carrierInFavGroup}
        pagination={pagination}
        loading={isLoading}
        setPagination={async (val) => {
          handleGetCarrierInFavGroup({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        href={`/user/${user_id}/profile/${profile_id}/shipper-favorite-group/${shipper_favorite_group_id}/carrier-in-favorite-group/`}
      />
    );
  };
  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <>
      <Container>
        {renderSubMenu()}
        {renderFilterTab()}
        {renderTable()}

        <FloatingButton
          button_type="add"
          onClick={() => {
            setOpenCreateModal(true);
          }}
        />
      </Container>

      <CreateCarrierInFavoriteGroupModal
        shipper_favorite_group_id={shipper_favorite_group_id}
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        updateTableData={async () => {
          handleGetCarrierInFavGroup({ page: 1 });
        }}
      />

      {renderModal()}
    </>
  );
};

export default CarrierInFavoriteGroup;
