import React, { useRef, useMemo, useState } from "react";
import * as R from "ramda";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { LongdoMap, map, longdo } from "../../map/LongdoMap";
import helperMap from "../../../utils/helperMap";
import ENV from "utils/environment";
import styles from "./Map.module.css";
import helper from "utils/helper";
import ReactDOMServer from "react-dom/server";

const Map = ({
  routeOptimize = {
    point: [],
    total_distance: null,
    total_time: null,
  },
}) => {
  const { ref } = useRef();

  useMemo(() => {
    setMarker();
  }, [routeOptimize.point]);

  async function setMarker() {
    try {
      if (!R.isEmpty(routeOptimize.point)) {
        await map.Overlays.clear();
        await map.Route.clear();

        routeOptimize.point.forEach(async (ele, index) => {
          const point_info = ele.value;

          const myComponentHtml = ReactDOMServer.renderToString(
            <div className={styles.point_wrapper}>{ele.field_name}</div>
          );
          const markerIconHtml = '<div class="">' + myComponentHtml + "</div>";

          if (point_info.lat && point_info.long) {
            const dot = await new window.longdo.Marker(
              { lat: point_info.lat, lon: point_info.long },
              {
                draggable: false,
                clickable: true,
                weight: window.longdo.OverlayWeight.Top,
                icon: {
                  html: markerIconHtml,
                },
              }
            );

            await map.Overlays.add(dot);
            await map.Route.add({ lat: point_info.lat, lon: point_info.long });
            if (index === 0) {
              await map.location(
                { lat: point_info.lat, lon: point_info.long },
                true
              );
            }
          }
        });

        await map.Route.line("road", { lineColor: "#121f43" });
        await map.Route.mode(longdo.RouteMode.Cost);
        await map.Route.enableRoute(longdo.RouteType.Tollway, false);
        await map.Route.label(longdo.RouteLabel.Hide, true);
        await map.Route.search();
      } else {
        await map.Overlays.clear();
        await map.Route.clear();
      }
    } catch (error) {
      console.log("error setMarker: ", error);
    }
  }

  const renderMap = () => {
    return (
      <div
        className="tracking-body-map"
        style={{
          // position: "relative",
          height: "80.5vmin",
        }}
      >
        <LongdoMap
          id={"post-work-main"}
          ref={ref}
          map_key={ENV.MAP_KEY[ENV.ENV].KEY_3}
        />
      </div>
    );
  };

  const renderListRouteOptimiza = () => {
    return !R.isEmpty(routeOptimize.point) ? (
      <div className={styles.list_route_optimiza_container}>
        {!R.isEmpty(routeOptimize.point) ? (
          <div className={styles.text_container}>ผลลัพธ์</div>
        ) : null}

        <div className={styles.route_container}>
          {routeOptimize?.point?.map((item, index) => {
            return (
              <div className={styles.wrapper}>
                <div className={styles.point_wrapper}>{item.field_name}</div>
                {index < routeOptimize.point.length - 1 ? (
                  <TrendingFlatIcon
                    style={{ margin: "0px 2px", fontSize: 60 }}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  return (
    <div className={styles.route_optimiza_map_container}>
      {renderMap()}
      {renderListRouteOptimiza()}
    </div>
  );
};

export default Map;
