import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import { getAllStlStandardSize } from "utils/api/masterData/stlStandardSize";

const SelectProduct = (props) => {
  const [show, setShow] = useState(false);

  const {
    value = [[]],
    onChange = () => {},
    productType,
    workType,
    is_detail = false,
  } = props;
  console.log("selectproduct", props);
  const [stlSize, setStlSize] = useState([]);
  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
  };
  const getWeight = () => {
    let weight = 0;
    value.forEach((e) => {
      if (e.product_weight) weight += parseInt(e.product_weight);
    });
    return weight === 0 || isNaN(weight) ? "-" : weight;
  };
  const getVolume = () => {
    let volume = 0;
    value.forEach((e) => {
      if (e.product_volume) volume += parseFloat(e.product_volume);
    });
    return volume === 0 || isNaN(volume) ? "-" : volume.toFixed(2);
  };
  const getSize = () => {
    console.log("size");
    console.log();
    let text = "";
    if (value && value.length > 0) {
      let s = stlSize.filter((e) => e.id === value[0].product_size);
      if (s.length > 0) {
        text = s[0].name ? s[0].name + " " : "";
      }
      return text;
    }
  };
  useEffect(() => {
    if (workType === "stl") {
      getStl();
    }
  }, [workType]);
  const getStl = async () => {
    let stl = await getAllStlStandardSize();
    setStlSize(stl.data.data.results);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          clickShow();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("../../assets/image/work/product.png")
            }
            height={40}
            alt={"product"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              color:
                value[0].name && value[0].name !== "" ? "#121f43" : "#ff316f",
            }}
          >
            {"รายละเอียดสินค้าที่จัดส่ง*"}
          </div>
          <div
            style={{
              fontSize: 13,
              opacity: value[0].name && value[0].name !== "" ? 1 : 0.3,
            }}
          >
            {value[0].name && value[0].name !== ""
              ? value.map((ele, index) => {
                  return (
                    <label key={"name_product" + index}>{ele.name} </label>
                  );
                })
              : "ระบุประเภทสินค้า ชนิดสินค้า และน้ำหนัก"}
            {value[0].product_volume !== "" ? (
              <div>
                {(workType === "stl" && value[0].product_size
                  ? getSize()
                  : "") +
                  "น้ำหนักรวม " +
                  getWeight() +
                  " ก.ก. ปริมาตรรวม " +
                  getVolume() +
                  " ลบ.ม."}
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ alignSelf: "center" }}>
          <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
        </div>
      </div>
      <ModalSelectProduct
        open={show}
        value={value}
        onChange={(val) => {
          onChange(val);
        }}
        workType={workType}
        close={() => {
          if (is_detail) {
            clickClose();
          } else {
            Swal.fire({
              title: "ยกเลิกการเปลี่ยนแปลง ?",
              showDenyButton: true,
              denyButtonText: "ยกเลิก",
              confirmButtonText: `ดำเนินการต่อ`,
            }).then((result) => {
              if (result.isConfirmed) {
                onChange([
                  {
                    product_id: "",
                    product_name: "",
                    product_size: "",
                    product_width: "",
                    product_length: "",
                    product_height: "",
                    product_volume: "",
                    product_weight: "",
                    product_count_type: "",
                    product_amount: "",
                    product_image: [null, null, null, null, null],
                  },
                ]);
                clickClose();
              }
            });
          }
        }}
        confirm={() => {
          clickClose();
        }}
        productType={productType}
        is_detail={is_detail}
      />
    </div>
  );
};
export default SelectProduct;
