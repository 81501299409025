import UpdateIcon from "@mui/icons-material/Update";
import { useRef, useState } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import helper from "utils/helper";
import { Button as AndButton } from "antd";
import { CSSTransition } from "react-transition-group";

const UpdateStatusButtonV2 = ({
  classNames = "",
  styles = {},
  onConfirm = () => {},
  title = "",
  children,
  onClick = () => {},
  onCancel = () => {},
  onCheckPage1 = () => {},
  children2,
}) => {
  const [open, setOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const nodeRef1 = useRef(null);
  const nodeRef2 = useRef(null);

  const handlerOnClick = () => {
    onClick().then((result) => {
      if (result) {
        setOpen(true);
      } else {
        setOpen(false);
        setCurrentPage(1);
      }
    });
  };

  const handlerOnClickX = () => {
    setOpen(false);
    setCurrentPage(1);
  };

  const handleCancel = () => {
    if (!helper.IsEmptyFunction(onCancel)) {
      onCancel();
    } else {
      setOpen(false);
      setCurrentPage(1);
    }
  };

  const handlerNextPage = async () => {
    setDisabledBtn(true);
    try {
      const result = await onCheckPage1();
      if (result) {
        setCurrentPage(2);
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
    } finally {
      setDisabledBtn(false);
    }
  };

  const handlerConfrimUpdate = async () => {
    setDisabledBtn(true);
    try {
      const result = await onConfirm();
      if (result) {
        handlerOnClickX();
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาด:", error);
    } finally {
      setDisabledBtn(false);
    }
  };

  const renderXBtn = () => (
    <IconButton
      onClick={handlerOnClickX}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M8.73655 7.5L14.9548 0.30138C15.059 0.181749 14.9714 0 14.8103 0H12.92C12.8086 0 12.702 0.0483128 12.6286 0.131135L7.5 6.06902L2.37142 0.131135C2.30035 0.0483128 2.19375 0 2.08005 0H0.189699C0.0286162 0 -0.0590318 0.181749 0.0451981 0.30138L6.26346 7.5L0.0451981 14.6986C0.0218496 14.7253 0.00687072 14.7579 0.00203912 14.7926C-0.00279249 14.8273 0.00272616 14.8626 0.0179404 14.8943C0.0331547 14.926 0.0574256 14.9529 0.0878709 14.9716C0.118316 14.9903 0.153657 15.0002 0.189699 15H2.08005C2.19139 15 2.29798 14.9517 2.37142 14.8689L7.5 8.93098L12.6286 14.8689C12.6996 14.9517 12.8062 15 12.92 15H14.8103C14.9714 15 15.059 14.8182 14.9548 14.6986L8.73655 7.5Z"
          fill="#8CA3BA"
        />
      </svg>
    </IconButton>
  );

  return (
    <>
      <AndButton
        className={classNames}
        variant="text"
        style={{
          width: "55px",
          height: "57px",
          flexShrink: "0",
          filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
          padding: "8px",
          border: 0,
          ...styles,
        }}
        onClick={handlerOnClick}
      >
        <UpdateIcon sx={{ fontSize: "40px", color: "#121F43" }} />
      </AndButton>

      <Modal open={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            minWidth: "00px",
            borderRadius: "20px",
            background: "#FFF",
            boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
            padding: "25px",
          }}
        >
          {renderXBtn()}
          <Box>
            {title !== "" && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#8CA3BA",
                    textAlign: "center",
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  {title}
                </Typography>
                <hr />
              </>
            )}
          </Box>
          <Box>
            {currentPage === 1 && (
              <CSSTransition
                in={currentPage === 1}
                classNames="fade"
                nodeRef={nodeRef1}
                timeout={300}
                unmountOnExit
              >
                <div ref={nodeRef1}>
                  <div
                    style={{
                      overflowY: "auto",
                      height: "72vh",
                      padding: "10px 0px",
                    }}
                  >
                    <Box>{children}</Box>
                  </div>
                  <Box className={`text-center`}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{
                        width: "160px",
                        height: "50px",
                        borderRadius: "10px",
                        border: "1px solid #8CA3BA",
                      }}
                      onClick={handleCancel}
                      disabled={disabledBtn}
                    >
                      ยกเลิก
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handlerNextPage}
                      style={{
                        width: "160px",
                        height: "50px",
                        borderRadius: "10px",
                        border: "1px solid #8CA3BA",
                        marginLeft: "15px",
                      }}
                      disabled={disabledBtn}
                    >
                      ถัดไป
                    </Button>
                  </Box>
                </div>
              </CSSTransition>
            )}

            {currentPage === 2 && (
              <CSSTransition
                in={currentPage === 2}
                classNames="fade"
                nodeRef={nodeRef2}
                timeout={300}
                unmountOnExit
              >
                <div ref={nodeRef2}>
                  <div
                    style={{
                      overflowY: "auto",
                      height: "72vh",
                      padding: "10px 0px",
                    }}
                  >
                    <Box>{children2}</Box>
                  </div>
                  <Box className={`text-center`}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{
                        width: "160px",
                        height: "50px",
                        borderRadius: "10px",
                        border: "1px solid #8CA3BA",
                      }}
                      onClick={() => setCurrentPage(1)}
                      disabled={disabledBtn}
                    >
                      ย้อนกลับ
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handlerConfrimUpdate}
                      style={{
                        width: "160px",
                        height: "50px",
                        borderRadius: "10px",
                        border: "1px solid #8CA3BA",
                        marginLeft: "15px",
                      }}
                      disabled={disabledBtn}
                    >
                      ยืนยัน
                    </Button>
                  </Box>
                </div>
              </CSSTransition>
            )}
          </Box>

          {/* {currentPage === 1 && (
            <>
              <div
                style={{
                  overflowY: "auto",
                  height: "72vh",
                  padding: "10px 0px",
                }}
              >
                <Box>{children}</Box>
              </div>
              <Box className={`text-center`}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{
                    width: "160px",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid #8CA3BA",
                  }}
                  onClick={handleCancel}
                  disabled={disabledBtn}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handlerNextPage}
                  style={{
                    width: "160px",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid #8CA3BA",
                    marginLeft: "15px",
                  }}
                  disabled={disabledBtn}
                >
                  ถัดไป
                </Button>
              </Box>
            </>
          )}
          {currentPage === 2 && (
            <>
              <div
                style={{
                  overflowY: "auto",
                  height: "72vh",
                  padding: "10px 0px",
                }}
              >
                <Box>test</Box>
              </div>
              <Box className={`text-center`}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{
                    width: "160px",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid #8CA3BA",
                  }}
                  onClick={() => setCurrentPage(1)}
                  disabled={disabledBtn}
                >
                  ย้อนกลับ
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handlerConfrimUpdate}
                  style={{
                    width: "160px",
                    height: "50px",
                    borderRadius: "10px",
                    border: "1px solid #8CA3BA",
                    marginLeft: "15px",
                  }}
                  disabled={disabledBtn}
                >
                  ยืนยัน
                </Button>
              </Box>
            </>
          )} */}
        </div>
      </Modal>
    </>
  );
};

export default UpdateStatusButtonV2;
