import React, { useEffect, useState } from "react";
import styles from "./TableAllProjectInProfile.module.css";
import Loading from "components/loading/LoadingBackground";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Avatar, Typography } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import { useForm, Controller } from "react-hook-form";
import {
  getProjectById,
  editProject,
  calculateCreditProject,
} from "utils/api/project/project";
import { AccountCircle, StarRateRounded, Save } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import {
  ActiveStatus,
  FreightType,
  PaymentType,
  ProjectFrequencyType,
  ReminderDate,
  WorkType,
} from "utils/enum";
import FieldInput from "components/field/FieldInput";
import FieldUpload from "components/field/FieldUpload";
import { getAllService } from "utils/api/masterData/service";
import AlertModal from "components/modal/AlertModal";

const DetailProject = () => {
  const { user_id, user_code, profile_id, project_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [creditInfo, setCreditInfo] = useState(null);
  const [serviceMaster, setServiceMaster] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: "",
    desc: "",
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  const shipper_payment_type = watch("shipper_payment_type");
  const MenuProject = [
    {
      label: "รายละเอียดโครงการ",
      path: "",
      active: true,
      //   header: "ข้อมูลทั่วไป",
    },
    {
      label: "แม่แบบงาน",
      path: `/user/${dataDetail?.profile.user_info.id}/${dataDetail?.profile.user_info.user_code}/profile/${dataDetail?.profile_id}/project/${project_id}/${dataDetail?.project_code}/template`,
    },
    {
      label: "ควาามคืบหน้าโครงการ",
      path: `/user/${dataDetail?.profile.user_info.id}/${dataDetail?.profile.user_info.user_code}/profile/${dataDetail?.profile_id}/project/${project_id}/${dataDetail?.project_code}/project-progression`,
    },
    {
      label: "ควาามคืบหน้าในการหาผู้ขนส่ง",
      path: `/user/${dataDetail?.profile.user_info.id}/${dataDetail?.profile.user_info.user_code}/profile/${dataDetail?.profile_id}/project/${project_id}/${dataDetail?.project_code}/carrier-progression`,
    },
    {
      label: "ผู้ขนส่งในโครงการ",
      path: `/user/${dataDetail?.profile.user_info.id}/${dataDetail?.profile.user_info.user_code}/profile/${dataDetail?.profile_id}/project/detail/${project_id}/${dataDetail?.project_code}/carrier-in-project`,
    },
    {
      label: "ผู้รับผิดชอบโครงการ",
      path: `/user/${dataDetail?.profile.user_info.id}/${dataDetail?.profile.user_info.user_code}/profile/${dataDetail?.profile_id}/project/detail/${project_id}/${dataDetail?.project_code}/project-admin`,
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${dataDetail?.profile.user_info.user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${dataDetail?.project_code}`,
      path: "",
    },
    {
      label: "รายละเอียดโครงการ",
      path: "",
    },
  ];

  useEffect(() => {
    getServiceMS();
  }, []);

  useEffect(() => {
    if (project_id) {
      getDetailProject(project_id);
      getCreditInfo();
    }
  }, [project_id]);

  useEffect(() => {
    console.log("dataDetail", dataDetail);
    if (dataDetail) {
      const newInitialFormValues = {};
      newInitialFormValues.active = String(dataDetail?.active) ?? "";
      newInitialFormValues.is_default = String(dataDetail?.is_default) ?? "";
      newInitialFormValues.is_public = String(dataDetail?.is_public) ?? "";
      newInitialFormValues.name = dataDetail?.name ?? "";
      newInitialFormValues.service_id = dataDetail?.service_id ?? "";
      newInitialFormValues.project_frequency_type =
        dataDetail?.project_frequency_type ?? "";
      newInitialFormValues.work_type = dataDetail?.work_type ?? "";
      newInitialFormValues.has_truck_check = dataDetail?.has_truck_check ?? "";
      newInitialFormValues.has_driver_training =
        dataDetail?.has_driver_training ?? "";
      newInitialFormValues.has_driver_health =
        dataDetail?.has_driver_health ?? "";
      newInitialFormValues.freight_type = dataDetail?.freight_type ?? "";
      newInitialFormValues.shipper_payment_type =
        dataDetail?.shipper_payment_type ?? "";
      newInitialFormValues.credit_term = dataDetail?.credit_term ?? "";
      newInitialFormValues.credit_length_date =
        dataDetail?.credit_length_date ?? "";
      newInitialFormValues.credit_bill_date =
        dataDetail?.credit_bill_date ?? "";
      newInitialFormValues.credit_remark = dataDetail?.credit_remark ?? "";
      newInitialFormValues.reminder_date =
        dataDetail?.project_reminder_shipper ?? "";
      newInitialFormValues.contract_file = dataDetail?.contract_file ?? "";
      newInitialFormValues.credit_limit = dataDetail?.credit_limit ?? "";
      setInitialFormValues(newInitialFormValues);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail, setValue]);

  useEffect(() => {
    console.log("creditInfo", creditInfo);
  }, [creditInfo]);

  const getCreditInfo = async () => {
    if (project_id) {
      setLoading(true);
      const param = {
        project_payment_type: "shipper",
      };
      await calculateCreditProject(project_id, param)
        .then((response) => {
          if (response.data.code === 1000) {
            const rest = response.data.data;
            setCreditInfo(rest);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  const getServiceMS = async () => {
    await getAllService()
      .then((response) => {
        console.log(response);
        let results = response.data.data.results;
        setServiceMaster(
          ...serviceMaster,
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          })) || []
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDetailProject = async (id) => {
    setLoading(true);
    await getProjectById(id)
      .then((response) => {
        console.log("response", response);
        if (response.data.code === 1000) {
          const rest = response.data.data;
          const remind = [];
          rest.project_reminder_shipper.map((val) => {
            remind.push(val.reminder_date);
          });
          rest["project_reminder_shipper"] = remind;
          setDataDetail(rest);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (shipper_payment_type !== 1) {
      setValue("credit_term", "");
      setValue("credit_length_date", "");
      setValue("credit_bill_date", "");
      setValue("credit_remark", "");
      setValue("reminder_date", "");
      setValue("contract_file", "");
      setValue("credit_limit", "");
    }
  }, [shipper_payment_type]);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log("onSubmit", data);
    const changedData = {};
    //check array
    if (
      data.reminder_date &&
      JSON.stringify(data.reminder_date) !==
        JSON.stringify(initialFormValues.reminder_date)
    ) {
      changedData.reminder_date = data.reminder_date;
    }

    Object.keys(data).forEach((fieldName) => {
      if (
        Array.isArray(data[fieldName]) &&
        JSON.stringify(data[fieldName]) !==
          JSON.stringify(initialFormValues[fieldName])
      ) {
        changedData[fieldName] = data[fieldName];
      } else if (
        !Array.isArray(data[fieldName]) &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        changedData[fieldName] = data[fieldName];
        if (data[fieldName] === "") {
          changedData[fieldName] = null;
        } else {
          changedData[fieldName] = data[fieldName];
        }
      }
    });

    console.log("changedData", changedData);
    const GForm = new FormData();
    Object.keys(changedData).forEach((key) => {
      if (Array.isArray(changedData[key])) {
        GForm.append(key, JSON.stringify(changedData[key]));
      } else if (changedData[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, changedData[key]);
      }
    });

    if (project_id) {
      await editProject(project_id, GForm)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal({
              open: true,
              title: "บันทึก",
              desc: "บันทึกข้อมูลสำเร็จ",
            });
            console.log("edit Success");
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window?.location?.reload();
            }
          }
        });
    }
  };

  const renderCard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Typography fontSize={"20px"} fontWeight={500} sx={{ p: "13px" }}>
          {dataDetail?.project_code || ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่สร้างรายการ{" "}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่แก้ไขข้อมูลล่าสุด{" "}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ""}
        </Typography>
        <Controller
          name="active"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"สถานะใช้งาน*"}
                nameDescription="(ค่าเริ่มต้น : ใช้งาน)"
                options={ActiveStatus}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="is_default"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"โครงการเริ่มต้น*"}
                nameDescription="(ค่าเริ่มต้น : ใช่)"
                options={[
                  { value: true, name: "ใช่" },
                  { value: false, name: "ไม่ใช่" },
                ]}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"ชื่อโครงการ*"}
                subLabel="(ค่าเริ่มต้น : โครงการเริ่มต้น)"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="service_id"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"ประเภทบริการ*"}
                nameDescription="(ค่าเริ่มต้น : เหมาคัน)"
                options={serviceMaster}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="project_frequency_type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"ความถี่งาน*"}
                nameDescription="(ค่าเริ่มต้น : ชั่วคราวเที่ยวเดียว)"
                options={ProjectFrequencyType}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="work_type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"ลักษณะงาน*"}
                nameDescription="(ค่าเริ่มต้น : งานภายในประเทศ)"
                options={WorkType}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="has_truck_check"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"มีการตรวจสภาพรถ*"}
                nameDescription="(ค่าเริ่มต้น : ไม่ใช่)"
                options={[
                  { value: true, name: "ใช่" },
                  { value: false, name: "ไม่ใช่" },
                ]}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="has_driver_training"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"มีการอบรมพนักงานขับรถ*"}
                nameDescription="(ค่าเริ่มต้น : ไม่ใช่)"
                options={[
                  { value: true, name: "ใช่" },
                  { value: false, name: "ไม่ใช่" },
                ]}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="has_driver_health"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"มีการตรวจสุขภาพพนักงานขับรถ*"}
                nameDescription="(ค่าเริ่มต้น : ไม่ใช่)"
                options={[
                  { value: true, name: "ใช่" },
                  { value: false, name: "ไม่ใช่" },
                ]}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
    );
  };
  const renderCard2 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          name="freight_type"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <FieldSelect
                name={"ประเภทการคิดค่าขนส่ง*"}
                nameDescription="(ค่าเริ่มต้น : งานขาไป)"
                options={FreightType}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="shipper_payment_type"
          control={control}
          // rules={{
          //   validate: (value) => {
          //     if (creditInfo?.all_work_unpaid > 0) {
          //       return `กรุณาเคลียร์ยอดค้างชำระทั้งหมดก่อนเปลี่นประเภทการชำระเงิน`;
          //     }
          //     return true;
          //   },
          // }}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"ประเภทการชำระเงินของลูกค้า*"}
                nameDescription="(ค่าเริ่มต้น : เงินสด)"
                options={PaymentType}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                error={!!errors.shipper_payment_type}
                errorText={
                  errors.shipper_payment_type
                    ? errors.shipper_payment_type.message
                    : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="credit_term"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"จำนวนวันเครดิตเทอม*"}
                type="number"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <Controller
          name="credit_length_date"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"รอบเวลาที่นับการใช้งาน*"}
                subLabel="(บังคับถ้าเป็นเครดิต)"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <Controller
          name="credit_bill_date"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"วันที่วางบิลของทุกเดือน*"}
                subLabel="(บังคับถ้าเป็นเครดิต)"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <Controller
          name="credit_remark"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"หมายเหตุเครดิต"}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <Controller
          name="reminder_date"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"วันที่ต้องการเตือนวางบิล*"}
                nameDescription="(บังคับถ้าเป็นเครดิต)"
                options={ReminderDate}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <Controller
          name="contract_file"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldUpload
                name={"ไฟล์สัญญา"}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <Controller
          name="credit_limit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label={"วงเงินเครดิตที่ได้รับ"}
                subLabel="(บังคับถ้าเป็นเครดิต)"
                type="number"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                readOnly={shipper_payment_type === 0 ? true : false}
              />
            </div>
          )}
        />
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"วงเงินเครดิตที่ใช้ไป"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              shipper_payment_type === 1
                ? helper.addCommaWith2digit(creditInfo?.credit_used)
                : ""
            }
          />
        </div>
      </div>
    );
  };
  const renderCard3 = () => {
    return (
      <div className={styles.cardDetail}>
        <div style={{ width: "100%", paddingTop: "20px" }}>
          <FieldInput
            label={"วงเงินเครดิตคงเหลือ"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              shipper_payment_type === 1
                ? helper.addCommaWith2digit(creditInfo?.credit_balance)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนการเรียกรถทั้งหมด"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              helper.addComma(creditInfo?.all_work) === "-"
                ? 0
                : helper.addComma(creditInfo?.all_work)
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนงานที่ลบประกาศ"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              helper.addComma(creditInfo?.delete_work_announcement) === "-"
                ? 0
                : helper.addComma(creditInfo?.delete_work_announcement)
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนงานที่หมดอายุ"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              helper.addComma(creditInfo?.work_expire) === "-"
                ? 0
                : helper.addComma(creditInfo?.work_expire)
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนงานที่กำลังดำเนินการ"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              helper.addComma(creditInfo?.work_in_progress) === "-"
                ? 0
                : helper.addComma(creditInfo?.work_in_progress)
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนงานที่ยกเลิก"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              helper.addComma(creditInfo?.work_cancel) === "-"
                ? 0
                : helper.addComma(creditInfo?.work_cancel)
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนงานที่เสร็จสิ้น"}
            style={{ width: "100%" }}
            readOnly={true}
            value={
              helper.addComma(creditInfo?.work_success) === "-"
                ? 0
                : helper.addComma(creditInfo?.work_success)
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label={"จำนวนเงินค้างชำระรวม"}
            style={{ width: "100%" }}
            readOnly={true}
            value={helper.addCommaWith2digit(creditInfo?.all_work_unpaid)}
          />
        </div>
        <Controller
          name="is_public"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name={"โครงการสาธารณะ*"}
                nameDescription="(ค่าเริ่มต้น : ใช่)"
                options={[
                  { value: true, name: "ใช่" },
                  { value: false, name: "ไม่ใช่" },
                ]}
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: "",
              desc: "",
            });
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: "",
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <SubMenu
          menus={MenuProject}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
      </Container>
      <form
        className={styles.containerDetailProject}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderCard1()}
        {renderCard2()}
        {renderCard3()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: "white", fontSize: "70px" }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default DetailProject;
