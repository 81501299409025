import { Box, Container, Grid, Paper, TextField } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import { useEffect, useState } from "react";
import "./Dashboard.css";
import AreaChart from "components/chart/AreaChart";
import StackedAreaChart from "components/chart/StackedAreaChart";
import PieChart from "components/chart/PieChart";
import StackedBarChart from "components/chart/StackedBarChart";
import ProgressChart from "components/chart/ProgressChart";

import { subMenuDasdboard } from "utils/submenuoptions";
import FieldSelect from "components/field/FieldSelect";
import moment from "moment";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import SearchButton from "components/button/SearchButton";
import ResetButton from "components/button/ResetButton";
import Loading from "components/loading/Loading";
import { getDashbaordIcon, getDashbaordIncome } from "utils/api/dashbaord";
import { getAllProject, getAllShipper } from "utils/api/masterData/service";
import FieldAutoComplete from "components/field/FieldAutoComplete";
import * as currency from "currency.js";

// const data = [
//   {
//     name: "01/2023",
//     value: 4000,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
//   {
//     name: "02/2023",
//     value: 3000,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
//   {
//     name: "03/2023",
//     value: 2000,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
//   {
//     name: "04/2023",
//     value: 2780,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
//   {
//     name: "05/2023",
//     value: 1890,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
//   {
//     name: "06/2023",
//     value: 2390,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
//   {
//     name: "07/2023",
//     value: 3490,
//     data: {
//       ปริมาณงานที่จับคู่แล้ว: "140000",
//       เป้าหมาย: "200,000",
//       ส่วนต่างจากเป้าหมาย: "-30%",
//       ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
//       ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
//     },
//   },
// ];

// const data2 = [
//   {
//     name: "01/2023",
//     value: [100, 200, 300],
//     data: {
//       บุคคลทั่วไป: "100",
//       นิติบุคคล: "200",
//       รวม: "300",
//     },
//   },
//   {
//     name: "02/2023",
//     value: [200, 400, 600],
//     data: {
//       บุคคลทั่วไป: "200",
//       นิติบุคคล: "400",
//       รวม: "600",
//     },
//   },
//   {
//     name: "03/2023",
//     value: [300, 100, 400],
//     data: {
//       บุคคลทั่วไป: "300",
//       นิติบุคคล: "100",
//       รวม: "400",
//     },
//   },
// ];

// const data3 = [
//   {
//     name: "01/2023",
//     value: [100, 200, 300],
//     data: {
//       บริการเหมาคัน: "100",
//       บริการฝากส่ง: "200",
//       รวม: "300",
//     },
//   },
//   {
//     name: "02/2023",
//     value: [200, 400, 600],
//     data: {
//       บริการเหมาคัน: "200",
//       บริการฝากส่ง: "400",
//       รวม: "600",
//     },
//   },
//   {
//     name: "03/2023",
//     value: [300, 100, 400],
//     data: {
//       บริการเหมาคัน: "300",
//       บริการฝากส่ง: "100",
//       รวม: "400",
//     },
//   },
// ];

// const data4 = [
//   { name: "บุคคลทั่วไป", value: 200, percent: "20%", color: "FFC675" },
//   { name: "นิติบุคคล", value: 800, percent: "80%", color: "8C8DFF" },
// ];

// const data5 = [
//   {
//     name: "01/2023",
//     value: {
//       เฟอร์นิเจอร์: 100,
//       "เครื่องใช้ไฟฟ้า เครื่องจักร": 90,
//       เครื่องใช้ครัวเรือน: 80,
//       "แก้ว กระจก เซรามิก": 70,
//       "อาหาร เครื่่องดื่ม สำเร็จรูป": 60,
//       โลหะ: 50,
//       ยาง: 40,
//       วัสดุก่อสร้าง: 30,
//       สินค้าเกษตร: 20,
//       สิ่งทอ: 10,
//       อื่นๆ: 5,
//     },
//   },
//   {
//     name: "02/2023",
//     value: {
//       เฟอร์นิเจอร์: 100,
//       "เครื่องใช้ไฟฟ้า เครื่องจักร": 90,
//       เครื่องใช้ครัวเรือน: 80,
//       "แก้ว กระจก เซรามิก": 70,
//       "อาหาร เครื่่องดื่ม สำเร็จรูป": 60,
//       โลหะ: 50,
//       ยาง: 40,
//       วัสดุก่อสร้าง: 30,
//       สินค้าเกษตร: 20,
//       สิ่งทอ: 10,
//       อื่นๆ: 5,
//     },
//   },
//   {
//     name: "03/2023",
//     value: {
//       เฟอร์นิเจอร์: 100,
//       "เครื่องใช้ไฟฟ้า เครื่องจักร": 90,
//       เครื่องใช้ครัวเรือน: 80,
//       "แก้ว กระจก เซรามิก": 70,
//       "อาหาร เครื่่องดื่ม สำเร็จรูป": 60,
//       โลหะ: 50,
//       ยาง: 40,
//       วัสดุก่อสร้าง: 30,
//       สินค้าเกษตร: 20,
//       สิ่งทอ: 10,
//       อื่นๆ: 5,
//     },
//   },
// ];

// const data6 = [
//   { name: "เฟอร์นิเจอร์", percent: 100, value: 4500 },
//   { name: "เครื่องใช้ไฟฟ้า เครื่องจักร", percent: 95, value: 3500 },
//   { name: "เครื่องใช้ครัวเรือน", percent: 90, value: 3000 },
//   { name: "แก้ว กระจก เซรามิก", percent: 85, value: 2500 },
//   { name: "อาหาร เครื่่องดื่ม สำเร็จรูป", percent: 80, value: 2000 },
//   { name: "เฟอร์นิเจอร์", percent: 80, value: 2000 },
//   { name: "เครื่องใช้ไฟฟ้า", percent: 80, value: 2000 },
//   { name: "เครื่องใช้ครัวเรือน", percent: 80, value: 2000 },
//   { name: "แก้ว", percent: 80, value: 2000 },
//   { name: "อาหาร", percent: 80, value: 2000 },
// ];

const DashbordIncome = () => {
  const [dashboard1, setDashboard1] = useState(null);
  const [dashboard2, setDashboard2] = useState(null);
  const [dashboard3, setDashboard3] = useState(null);
  const [dashboard4, setDashboard4] = useState(null);
  const [dashboard5, setDashboard5] = useState(null);
  const [dashboard6, setDashboard6] = useState(null);
  const [dashboard7, setDashboard7] = useState(null);
  const [dashboard8, setDashboard8] = useState(null);
  const [dashboard9, setDashboard9] = useState(null);
  const [dashboard10, setDashboard10] = useState(null);
  const [dashboard11, setDashboard11] = useState(null);
  const [dashboard12, setDashboard12] = useState(null);
  const [dashboard13, setDashboard13] = useState(null);
  const [dashboard14, setDashboard14] = useState(null);
  const [dashboard15, setDashboard15] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [groupBy, setGroupBy] = useState(4);
  const [filterBy, setFilterBy] = useState("projectID");
  const [filter, setFilter] = useState(null);

  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);

  const [acProject, setAcProject] = useState([]);
  const [acShipper, setAcShipper] = useState([]);

  const monthToNumber = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames.indexOf(month);
  };

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNumber < 10 ? "0" + weekNumber : weekNumber.toString();
  };

  const generateDates = (dateFrom, dateTo, groupBy) => {
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);
    const generatedDates = [];

    if (groupBy === 1) {
      for (
        let year = fromDate.getFullYear();
        year <= toDate.getFullYear();
        year++
      ) {
        generatedDates.push(year.toString());
      }
    } else if (groupBy === 2) {
      const startYear = fromDate.getFullYear();
      const endYear = toDate.getFullYear();
      const startMonth = fromDate.getMonth();
      const endMonth = toDate.getMonth();

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const date = new Date(year, month, 1);
          generatedDates.push(formatDate2(date));
        }
      }
    } else if (groupBy === 3) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        const weekNumber = getWeekNumber(currentDate);
        const year = currentDate.getFullYear();
        generatedDates.push(`w${weekNumber}/${year}`);
        currentDate.setDate(currentDate.getDate() + 7);
      }
    } else if (groupBy === 4) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        generatedDates.push(formatDate4(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return generatedDates;
  };

  const formatDate1 = (dateString) => {
    return moment(dateString).format("YYYY");
  };

  const formatDate2 = (dateString) => {
    return moment(dateString).format("MMM YYYY");
  };
  const formatDate3 = (dateString) => {
    return dateString;
  };

  const formatDate4 = (dateString) => {
    return moment(dateString).format("DD MMM YYYY");
  };

  useEffect(() => {
    setActiveSubMenu(1);
    setSubMenuBreadcrumbs([
      {
        label: "แผนภูมิและกราฟ",
        path: null,
      },
      {
        label: "แผนภูมิและกราฟรายได้",
        path: null,
      },
    ]);
    fetchDashboard();
    getAutoCompleteProject();
    getAutoCompleteShipper();
  }, []);

  async function getAutoCompleteProject() {
    try {
      const res = await getAllProject();
      if (res) {
        setAcProject(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAutoCompleteShipper() {
    try {
      const res = await getAllShipper();
      if (res) {
        setAcShipper(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDashboard() {
    setIsReady(false);
    const requestData = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
      groupBy: groupBy,
    };
    if (filterBy !== "" && filter !== null) {
      requestData[filterBy] = filter.value;
    }
    try {
      const response = await getDashbaordIncome(requestData);
      if (response.status === 200) {
        const res = response.data.data;
        const rawData = res.map((item) => {
          if (groupBy === 1) {
            item.groupdate = formatDate1(item.groupdate);
          } else if (groupBy === 2) {
            item.groupdate = formatDate2(item.groupdate);
          } else if (groupBy === 3) {
            item.groupdate = formatDate3(item.groupdate);
          } else if (groupBy === 4) {
            item.groupdate = formatDate4(item.groupdate);
          }
          return item;
        });
        const generatedDates = generateDates(dateFrom, dateTo, groupBy);
        generatedDates.forEach((generatedDate) => {
          if (!rawData.some((item) => item.groupdate === generatedDate)) {
            rawData.push({
              groupdate: generatedDate,
              income: "0.00",
              income_carrier: {},
              income_corporate: "0.00",
              income_created_by_admin: "0.00",
              income_created_by_user: "0.00",
              income_individual: "0.00",
              income_payment_type_credit: "0.00",
              income_payment_type_debit: "0.00",
              income_product_type: {},
              income_service: {},
              income_shipper: {},
              income_truck_type: {},
            });
          }
        });

        if (groupBy === 2) {
          rawData.sort((a, b) => {
            const [aMonth, aYear] = a.groupdate.split(" ");
            const [bMonth, bYear] = b.groupdate.split(" ");
            const aMonthNumber = monthToNumber(aMonth);
            const bMonthNumber = monthToNumber(bMonth);

            if (aYear === bYear) {
              return aMonthNumber - bMonthNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 3) {
          rawData.sort((a, b) => {
            const aWeekYear = a.groupdate.split(" ")[0];
            const bWeekYear = b.groupdate.split(" ")[0];
            const [aWeek, aYear] = aWeekYear.split("/");
            const [bWeek, bYear] = bWeekYear.split("/");
            const aWeekNumber = parseInt(aWeek.split("w")[1]);
            const bWeekNumber = parseInt(bWeek.split("w")[1]);
            if (aYear === bYear) {
              return aWeekNumber - bWeekNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 4) {
          rawData.sort((a, b) => {
            const dateA = new Date(a.groupdate);
            const dateB = new Date(b.groupdate);
            return dateA - dateB;
          });
        } else {
          rawData.sort((a, b) => a.groupdate.localeCompare(b.groupdate));
        }

        const data = rawData.map((value, index) => {
          const target = 10;
          const targetDiff = (100 / target) * value.income - 100;
          const prev = index === 0 ? "NaN" : rawData[index - 1].income;
          const prevDiff = (100 / prev) * value.income - 100;
          const targetDiffText =
            targetDiff >= 0
              ? `+${targetDiff.toFixed(2)}%`
              : `${targetDiff.toFixed(2)}%`;
          const prevDiffText =
            prevDiff >= 0
              ? `+${prevDiff.toFixed(2)}%`
              : `${prevDiff.toFixed(2)}%`;
          return {
            name: value.groupdate,
            value: value.income,
            data: {
              รายได้จากงานที่จับคู่แล้ว: value.income,
              เป้าหมาย: `${target}`,
              ส่วนต่างจากเป้าหมาย: targetDiffText,
              รายได้จากงานที่จับคู่แล้วก่อนหน้า: `${prev}`,
              ส่วนต่างจากรายได้จากงานที่จับคู่แล้วก่อนหน้า: prevDiffText,
            },
          };
        });

        const data2 = rawData.map((value, index) => {
          const sum = currency(value.income_created_by_user).add(
            value.income_created_by_admin
          );
          const sum_format = currency(sum, {
            symbol: "",
            precision: 2,
          }).value;
          return {
            name: value.groupdate,
            value: [
              value.income_created_by_user,
              value.income_created_by_admin,
              sum_format,
            ],
            data: {
              บุคคลทั่วไป: value.income_created_by_user,
              นิติบุคคล: value.income_created_by_admin,
              รวม: sum_format,
            },
          };
        });

        const arrService = rawData.map((value, index) => {
          return Object.keys(value.income_service);
        });
        const uniqueService = [...new Set(arrService.flat())];
        const zeroValue = {};
        uniqueService.forEach((service) => {
          zeroValue[service] = 0;
        });

        const data3 = rawData.map((value, index) => {
          const resValue = [];
          const resData = {};
          for (const key of uniqueService) {
            resValue.push(value.income_service[key] || 0);
            resData[key] = value.income_service[key] || 0;
          }
          return {
            name: value.groupdate,
            value: resValue,
            data: resData,
          };
        });

        const data4 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.income_created_by_user),
              prev[1] + parseFloat(curr.income_created_by_admin),
            ];
          },
          [0, 0]
        );

        const data5 = rawData.reduce(
          (prev, curr) => {
            for (const key in prev) {
              prev[key] += curr.income_service[key] || 0;
            }
            return prev;
          },
          { ...zeroValue }
        );

        function getRandomColor() {
          const letters = "0123456789ABCDEF";
          let color = "";
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        const newData5 = Object.entries(data5).map(([name, value]) => {
          const total = Object.values(data5).reduce(
            (acc, curr) => acc + curr,
            0
          );
          const percent = ((value / total) * 100).toFixed(2);
          const color = getRandomColor();

          return {
            name,
            value,
            percent: parseFloat(percent),
            color,
          };
        });

        const data6 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            value: [
              currency(value.income_individual).value,
              currency(value.income_corporate).value,
              currency(value.income_individual).add(value.income_corporate)
                .value,
            ],
            data: {
              บุคคลทั่วไป: currency(value.income_individual, {
                symbol: "",
                precision: 0,
              }).format(),
              นิติบุคคล: currency(value.income_corporate, {
                symbol: "",
                precision: 0,
              }).format(),
              รวม: currency(value.income_individual, {
                symbol: "",
                precision: 0,
              })
                .add(value.income_corporate)
                .format(),
            },
          };
        });

        const data7 = rawData.map((value, index) => {
          return {
            name: value.groupdate,
            value: [
              currency(value.income_payment_type_debit).value,
              currency(value.income_payment_type_credit).value,
              currency(value.income_payment_type_debit).add(
                value.income_payment_type_credit
              ).value,
            ],
            data: {
              บุคคลทั่วไป: currency(value.income_payment_type_debit, {
                symbol: "",
                precision: 0,
              }).format(),
              นิติบุคคล: currency(value.income_payment_type_credit, {
                symbol: "",
                precision: 0,
              }).format(),
              รวม: currency(value.income_payment_type_debit, {
                symbol: "",
                precision: 0,
              })
                .add(value.income_payment_type_credit)
                .format(),
            },
          };
        });

        const data8 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.income_individual),
              prev[1] + parseFloat(curr.income_corporate),
            ];
          },
          [0, 0]
        );

        const data9 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.income_payment_type_debit),
              prev[1] + parseFloat(curr.income_payment_type_credit),
            ];
          },
          [0, 0]
        );

        const data10 = rawData.map((value, index) => {
          const sortedItems = Object.entries(value.income_product_type)
            .sort((a, b) => b[1] - a[1])
            .map(([key, val]) => ({ [val.name]: val.value }));

          const top10Items = sortedItems.slice(0, 10);

          const otherTotal = sortedItems.slice(10).reduce((acc, curr) => {
            const value = Object.values(curr)[0];
            return acc + value;
          }, 0);

          if (otherTotal !== 0) {
            top10Items.push({ อื่นๆ: otherTotal });
          }

          const top10ItemsJSON = {};
          top10Items.forEach((obj) => {
            for (const key in obj) {
              top10ItemsJSON[key] = obj[key];
            }
          });

          return {
            name: value.groupdate,
            value: top10ItemsJSON,
          };
        });

        const data11 = rawData.map((value, index) => {
          const sortedItems = Object.entries(value.income_truck_type)
            .sort((a, b) => b[1] - a[1])
            .map(([key, val]) => ({ [val.name]: val.value }));

          const top10Items = sortedItems.slice(0, 10);

          const otherTotal = sortedItems.slice(10).reduce((acc, curr) => {
            const value = Object.values(curr)[0];
            return acc + value;
          }, 0);

          if (otherTotal !== 0) {
            top10Items.push({ อื่นๆ: otherTotal });
          }

          const top10ItemsJSON = {};
          top10Items.forEach((obj) => {
            for (const key in obj) {
              top10ItemsJSON[key] = obj[key];
            }
          });
          return {
            name: value.groupdate,
            value: top10ItemsJSON,
          };
        });

        const data12 = rawData.reduce((prev, curr) => {
          for (const key in curr.income_product_type) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.income_product_type[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.income_product_type[key].value || 0;
          }
          return prev;
        }, {});
        const newData12 = Object.entries(data12).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue12 = newData12.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0
        );
        newData12.sort((a, b) => b.value - a.value);
        const top10Items12 = newData12.slice(0, 10);

        const data13 = rawData.reduce((prev, curr) => {
          console.log({ curr });
          for (const key in curr.income_truck_type) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.income_truck_type[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.income_truck_type[key].value || 0;
          }
          return prev;
        }, {});
        const newData13 = Object.entries(data13).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue13 = newData13.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0
        );
        newData13.sort((a, b) => b.value - a.value);
        const top10Items13 = newData13.slice(0, 10);

        const data14 = rawData.reduce((prev, curr) => {
          for (const key in curr.income_shipper) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.income_shipper[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.income_shipper[key].value || 0;
          }
          return prev;
        }, {});
        const newData14 = Object.entries(data14).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue14 = newData14.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0
        );
        newData14.sort((a, b) => b.value - a.value);
        const top10Items14 = newData14.slice(0, 10);

        const data15 = rawData.reduce((prev, curr) => {
          for (const key in curr.income_carrier) {
            if (!prev[key]) {
              prev[key] = {
                name: curr.income_carrier[key].name,
                value: 0,
              };
            }
            prev[key].value += curr.income_carrier[key].value || 0;
          }
          return prev;
        }, {});
        const newData15 = Object.entries(data15).map(([id, value]) => ({
          id: id,
          name: value.name,
          value: value.value,
        }));
        const totalValue15 = newData15.reduce(
          (acc, item) => currency(acc).add(item.value).value,
          0
        );
        newData15.sort((a, b) => b.value - a.value);
        const top10Items15 = newData15.slice(0, 10);

        let findImg = {
          product_type: [],
          truck_type: [],
          shipper: [],
          carrier: [],
        };
        findImg.product_type = top10Items12.map((item) => item.id);
        findImg.truck_type = top10Items13.map((item) => item.id);
        findImg.shipper = top10Items14.map((item) => item.id);
        findImg.carrier = top10Items15.map((item) => item.id);
        const icon = await getDashbaordIcon(findImg);
        if (icon) {
          const { product_type, carrier, shipper, truck_type, url } =
            icon.data.data;

          newData12.forEach((item) => {
            const percent = (item.value / totalValue12) * 100;
            item["percent"] = parseFloat(percent.toFixed(2));
            const icon_part =
              product_type.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item["icon"] = null;
            } else {
              item["icon"] = `http://${url}${icon_part}`;
            }
          });
          newData13.forEach((item) => {
            const percent = (item.value / totalValue13) * 100;
            item["percent"] = parseFloat(percent.toFixed(2));
            const icon_part =
              truck_type.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item["icon"] = null;
            } else {
              item["icon"] = `http://${url}${icon_part}`;
            }
          });
          newData14.forEach((item) => {
            const percent = (item.value / totalValue14) * 100;
            item["percent"] = parseFloat(percent.toFixed(2));
            const icon_part =
              shipper.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item["icon"] = null;
            } else {
              item["icon"] = `http://${icon_part}`;
            }
          });
          newData15.forEach((item) => {
            const percent = (item.value / totalValue15) * 100;
            item["percent"] = parseFloat(percent.toFixed(2));
            const icon_part =
              carrier.find((val) => val.name === item.name)?.image ?? null;
            if (icon_part === null) {
              item["icon"] = null;
            } else {
              item["icon"] = `http://${icon_part}`;
            }
          });
        }

        setDashboard1(data);
        setDashboard2(data2);
        setDashboard3(data3);
        setDashboard4([
          {
            name: "ผู้ใช้งาน",
            value: data4[0],
            percent: (data4[0] / (data4[0] + data4[1])) * 100,
            color: "FFC675",
          },
          {
            name: "ผู้ดูแลระบบ",
            value: data4[1],
            percent: (data4[1] / (data4[0] + data4[1])) * 100,
            color: "8C8DFF",
          },
        ]);
        setDashboard5(newData5);
        setDashboard6(data6);
        setDashboard7(data7);
        setDashboard8([
          {
            name: "บุคคลทั่วไป",
            value: data8[0],
            percent: (data8[0] / (data8[0] + data8[1])) * 100,
            color: "FFC675",
          },
          {
            name: "นิติบุคคล",
            value: data8[1],
            percent: (data8[1] / (data8[0] + data8[1])) * 100,
            color: "8C8DFF",
          },
        ]);
        setDashboard9([
          {
            name: "บุคคลทั่วไป",
            value: data9[0],
            percent: (data9[0] / (data9[0] + data9[1])) * 100,
            color: "FFC675",
          },
          {
            name: "นิติบุคคล",
            value: data9[1],
            percent: (data9[1] / (data9[0] + data9[1])) * 100,
            color: "8C8DFF",
          },
        ]);
        setDashboard10(data10);
        setDashboard11(data11);
        setDashboard12(newData12);
        setDashboard13(newData13);
        setDashboard14(newData14);
        setDashboard15(newData15);
      }
      setIsReady(true);
    } catch (error) {
      setIsReady(true);
      console.error("Error fetching Dashboard:", error);
    }
  }

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  const handleGroupby = (val) => {
    setGroupBy(val);
  };

  const handleDate = (val) => {
    setDateFrom(val[0]);
    setDateTo(val[1]);
  };

  const handleFilterBy = (val) => {
    setFilterBy(val);
  };

  const handleFilter = (val) => {
    setFilter(val);
  };

  const handleSearch = () => {
    fetchDashboard();
  };

  const handleReset = () => {
    setDateFrom(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setDateTo(moment().format("YYYY-MM-DD"));
    setGroupBy(4);
    setFilterBy("");
    setFilter("");
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          height: "100px",
          width: "100%",
          padding: "26px 37px",
          borderRadius: "10px",
          background: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: 1, name: "ปี" },
                { value: 2, name: "เดือน" },
                { value: 3, name: "สัปดาห์" },
                { value: 4, name: "วัน" },
              ]}
              value={groupBy}
              onChange={handleGroupby}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: dateFrom,
                end_date: dateTo,
              }}
              onChange={handleDate}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`ค้นหาจาก`}
              options={[
                { value: "projectID", name: "ชื่อโครงการ" },
                { value: "profileID", name: "ชื่อโปรไฟล์ลูกค้า" },
              ]}
              value={filterBy}
              onChange={handleFilterBy}
            />
          </Grid>
          <Grid item xs={2}>
            {/* <FieldSearch
              className="w-100"
              value={filter}
              onChange={handleFilter}
            /> */}
            <FieldAutoComplete
              className="w-100"
              value={filter}
              onChange={handleFilter}
              options={filterBy === "projectID" ? acProject : acShipper}
            />
          </Grid>
          <Grid item xs={2}>
            <SearchButton onClick={handleSearch} />
            <ResetButton styles={{ marginLeft: "5px" }} onClick={handleReset} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderDasdboard = () => {
    return (
      <div className="div-chart">
        <Box
          className="box-inline box-white"
          sx={{
            height: "625px",
            minWidth: "1150px",
            padding: "18px 25px",
          }}
        >
          <AreaChart
            title="รายได้จากงานที่จับคู่แล้ว"
            data={dashboard1}
            color="2499EF"
          />
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "800px", height: "300px", padding: "18px 25px" }}
          >
            <StackedAreaChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทผู้ประกาศงาน"
              data={dashboard2}
              color={["FFC675", "FF316F", "2499EF"]}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "800px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <StackedAreaChart
              title="ปริมาณงานที่จับคู่แล้วรายได้จากงานที่จับคู่แล้วแยกตามประเภทบริการแยกตามประเภทบริการ"
              data={dashboard3}
              color={["FFC675", "27CE88", "2499EF"]}
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "325px", height: "300px", padding: "18px 25px" }}
          >
            <PieChart
              data={dashboard4}
              title="สัดส่วนรายได้จากงานที่จับคู่แล้ว"
              title2="แยกตามประเภทผู้ประกาศงาน"
              unit="รายได้"
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "325px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <PieChart
              data={dashboard5}
              title="สัดส่วนรายได้จากงานที่จับคู่แล้ว"
              title2="แยกตามประเภทบริการ"
              unit="รายได้"
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "800px", height: "300px", padding: "18px 25px" }}
          >
            <StackedAreaChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทโปรไฟล์"
              data={dashboard6}
              color={["FFC675", "8C8DFF", "2499EF"]}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "800px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <StackedAreaChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทการชำระเงิน"
              data={dashboard7}
              color={["FFC675", "8CA3BA", "2499EF"]}
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "325px", height: "300px", padding: "18px 25px" }}
          >
            <PieChart
              data={dashboard8}
              title="สัดส่วนรายได้จากงานที่จับคู่แล้ว"
              title2="แยกตามประเภทโปรไฟล์"
              unit="รายได้"
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "325px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <PieChart
              data={dashboard9}
              title="สัดส่วนปริมาณงานที่จับคู่แล้ว"
              title2="แยกตามประเภทบริการ"
              unit="รายได้"
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "800px", height: "300px", padding: "18px 25px" }}
          >
            <StackedBarChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทสินค้า"
              data={dashboard10}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "800px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <StackedBarChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทสินค้า"
              data={dashboard11}
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "500px", height: "300px", padding: "18px 25px" }}
          >
            <ProgressChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทสินค้า"
              unit="บาท"
              data={dashboard12}
              color="rgba(39, 206, 136)"
              precision={2}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "500px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <ProgressChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทรถ"
              unit="บาท"
              data={dashboard13}
              color="rgba(140, 141, 255)"
              precision={2}
            />
          </Box>
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "500px", height: "300px", padding: "18px 25px" }}
          >
            <ProgressChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามโปรไฟล์ลูกค้าสูงสุด 10 อันดับแรก"
              unit="บาท"
              data={dashboard14}
              color="rgba(255, 49, 111)"
              precision={2}
            />
          </Box>
          <Box
            className="box-white"
            px={{
              width: "500px",
              height: "300px",
              padding: "18px 25px",
              marginTop: "25px",
            }}
          >
            <ProgressChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามโปรไฟล์ผู้ขนส่งสูงสุด 10 อันดับแรก"
              unit="บาท"
              data={dashboard15}
              color="rgba(36, 153, 239)"
              precision={2}
            />
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <Box className="DashbordWork">
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
      </Container>
      {isReady === false ? <Loading /> : renderDasdboard()}
    </Box>
  );
};

export default DashbordIncome;
