import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import Loading from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import { Container, Typography, Avatar } from "@mui/material";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import SubMenu from "components/layout/SubMenu.js";
import styles from "./TableFavoritePlace.module.css";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import { Save } from "@mui/icons-material";
import { ActiveStatus } from "utils/enum";
import { getFavoritePlaceId } from "utils/api/profile/favoritePlace";
import FieldFindLocation from "components/field/FieldFindLocation";
import HelperMap from "utils/helperMap";
import { PlaceType } from "utils/enum";
import { editFavoritePlace } from "utils/api/profile/favoritePlace";
import ENV from "utils/environment";
import InputLongdoAutoComplete from "components/input/InputLongdoAutoComplete";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
const DetailFavoritePlace = () => {
  const { user_code, favorite_place_id } = useParams();
  const navigate = useNavigate();
  const { ref } = useRef();
  const [currentLocation, setCurrentLocation] = useState();
  const [currentPin, setCurrentPin] = useState();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: "",
    desc: "",
  });
  const subUserID = [
    {
      label: "รายละเอียดสถานที่ที่บันทึกไว้",
      active: true,
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "สถานที่ที่บันทึกไว้",
      path: "",
    },
    {
      label: `${dataDetail?.location_name}`,
      path: "",
    },
    {
      label: "รายละเอียดสถานที่ที่บันทึกไว้",
      path: "",
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  const location_info = watch("location");

  const handleMaxDigit = (e, field, max) => {
    const newValue = e.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      await getFavoritePlaceId(favorite_place_id)
        .then((response) => {
          const rest = response.data.data;
          setDataDetail(rest);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          if (error.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            // window?.location?.reload();
          }
        });
    });
  }, [favorite_place_id]);

  useEffect(() => {
    const newInitialFormValues = {};
    console.log("dataDetail", dataDetail);
    if (dataDetail) {
      newInitialFormValues.location = {
        lat: Number(dataDetail?.lat) ?? "",
        long: Number(dataDetail?.long) ?? "",
        search: dataDetail?.address ?? "",
      };
      newInitialFormValues.active = dataDetail?.active ?? "";
      newInitialFormValues.location_name = dataDetail?.location_name ?? "";
      newInitialFormValues.contact_name = dataDetail?.contact_name ?? "";
      newInitialFormValues.contact_phone = dataDetail?.contact_phone ?? "";
      newInitialFormValues.place_type = dataDetail?.place_type ?? "";
      newInitialFormValues.remark = dataDetail?.remark ?? "";

      console.log("newInitialFormValues", newInitialFormValues);
      setInitialFormValues(newInitialFormValues);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    initMap();
  }, []);

  const initMap = () => {
    console.log("initMap modal map");
    navigator.geolocation.getCurrentPosition(async function (position) {
      await map?.location(
        { lat: position.coords.latitude, lon: position.coords.longitude },
        true
      );

      const dot = await new longdo.Marker(
        { lat: position.coords.latitude, lon: position.coords.longitude },
        {
          title: "My Location",
          draggable: false,
          weight: longdo.OverlayWeight.Top,
        }
      );
      setCurrentPin(dot);
      await moveMap({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
      await map.Ui.Fullscreen.visible(true);
      await map.Ui.Zoombar.visible(true);
      // await map.Overlays.add(dot);
    });
  };

  useEffect(() => {
    console.log("currentPin", currentPin);
    console.log("currentLocation", currentLocation);
  }, [currentPin]);
  useEffect(() => {
    if (
      location_info &&
      location_info?.lat !== "" &&
      location_info?.long !== ""
    ) {
      markMap();
    }
  }, [location_info?.lat, location_info?.long, currentPin]);

  const markMap = async () => {
    console.log("Do Mark Map", location_info);
    if (map) {
      console.log("Has Map");
      if (currentPin) {
        await map.Overlays.clear();
      }
      const dot = await new longdo.Marker(
        { lat: location_info?.lat, lon: location_info?.long },
        {
          title: "My Location",
          draggable: false,
          clickable: false,
          weight: longdo.OverlayWeight.Top,
          icon: {
            html:
              '<div class="d-flex flex-row"><img src="' +
              require("../../../assets/image/work/send_pin.png") +
              '" style="height: 30px"/></div>',
          },
        }
      );
      await moveMap({ lat: location_info.lat, lon: location_info.long });
      await map.Overlays.add(dot);
      if (currentPin) await map.Overlays.add(currentPin);

      await map.zoom(13, true);
      await map.Ui.Fullscreen.visible(true);
      await map.Ui.Zoombar.visible(true);
      // await map.Overlays.add(currentPin);

      await map.Event.bind("overlayDrop", async function (overlay) {
        console.log(overlay.location());
        let data = await HelperMap.getGeocode({
          lat: overlay.location().lat,
          lng: overlay.location().lon,
        });
        let detail = await HelperMap.getDetailLocation(data, {
          lat: overlay.location().lat,
          lng: overlay.location().lon,
        });
        detail.search = detail.address;
        setValue("location", {
          ...location_info,
          ...detail,
        });
        await moveMap(overlay.location());
      });
    }
  };

  const moveMap = async (location) => {
    console.log("location", location);
    await map?.location(location, true);
  };

  const rendercard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Typography fontSize={"20px"} fontWeight={500} sx={{ p: "13px" }}>
          {dataDetail?.location_name || ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่สร้างรายการ{" "}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่แก้ไขข้อมูลล่าสุด{" "}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ""}
        </Typography>
        <Controller
          name="active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: "20px", width: "100%" }}>
              <FieldSelect
                name="สถานะใช้งาน"
                nameDescription="(ค่าเริ่มต้น : ใช้งาน)"
                options={ActiveStatus}
                value={value}
                onChange={onChange}
                style={{ width: "-webkit-fill-available" }}
              />
            </div>
          )}
        />
        <div style={{ width: "100%" }}>
          <Controller
            name="location"
            control={control}
            rules={{
              required: "กรุณากรอกชื่อสถานที่",
            }}
            render={({ field: { onChange, value } }) => (
              <div>
                {/* <FieldFindLocation
                    onChange={onChange}
                    value={value}
                    error={!!errors.location}
                  /> */}
                <InputLongdoAutoComplete
                  value={value}
                  label={"ค้นหาสถานที่"}
                  onChange={(val) => {
                    console.log("val", val);
                    onChange({
                      ...val,
                    });
                  }}
                  closeFav={true}
                  error={!!errors.location}
                />
                <div style={{ color: "red" }}>
                  {errors.location ? errors.location.message : ""}
                </div>
              </div>
            )}
          />
        </div>
        <div
          style={{
            position: "relative",
            height: "470px",
            width: "100%",
          }}
        >
          <LongdoMap
            id={"detail-favorite"}
            ref={ref}
            map_key={ENV.MAP_KEY[ENV.ENV].KEY_2}
          />
        </div>
      </div>
    );
  };

  const rendercard2 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          name="location_name"
          control={control}
          rules={{
            required: "กรุณากรอกชื่อสถานที่",
          }}
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: "23px", width: "100%" }}>
              <FieldInput
                label="ชื่อสถานที่*"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
                error={!!errors.location_name}
                helperText={
                  errors.location_name ? errors.location_name.message : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="contact_name"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="ผู้ติดต่อ"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="contact_phone"
          control={control}
          rules={{
            required: "กรุณากรอกเบอร์มือถือ",
            pattern: {
              value: /^(?:\+66|0)[689]\d{8}$/,
              message: "กรุณากรอกเบอร์มือถือให้ถูกต้อง",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="เบอร์มือถือ*"
                type="number"
                style={{ width: "-webkit-fill-available" }}
                value={value}
                onChange={(e) => handleMaxDigit(e, "contact_phone", 10)}
                error={!!errors.contact_phone}
                helperText={
                  errors.contact_phone ? errors.contact_phone.message : ""
                }
              />
            </div>
          )}
        />
        <Controller
          name="place_type"
          control={control}
          rules={{
            required: "กรุณากรอกประเภทสถานที่",
          }}
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="ประเภทสถานที่*"
                style={{ width: "100%" }}
                options={PlaceType}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          name="remark"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="หมายเหตุ"
                style={{ width: "100%" }}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    console.log("initialFormValues", initialFormValues);
    const changedData = {};
    Object.keys(data).forEach((fieldName) => {
      if (typeof data[fieldName] === "object") {
        const dataString = JSON.stringify(data[fieldName]);
        const initialString = JSON.stringify(initialFormValues[fieldName]);
        if (dataString !== initialString) {
          changedData["address"] = data[fieldName]["address"];
          changedData["district"] = data[fieldName]["district"];
          changedData["lat"] = data[fieldName]["lat"];
          changedData["long"] = data[fieldName]["long"];
          changedData["postal_code"] = data[fieldName]["postal_code"];
          changedData["province"] = data[fieldName]["province"];
          changedData["subdistrict"] = data[fieldName]["subdistrict"];
        }
      } else if (
        typeof data[fieldName] !== "object" &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        changedData[fieldName] = data[fieldName];
        if (data[fieldName] === "") {
          changedData[fieldName] = null;
        } else {
          changedData[fieldName] = data[fieldName];
        }
      }
    });

    console.log("changedData", changedData);
    if (favorite_place_id && changedData) {
      await editFavoritePlace(favorite_place_id, changedData)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal({
              open: true,
              title: "บันทึก",
              desc: "บันทึกข้อมูลสำเร็จ",
            });
            console.log("edit Success");
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window?.location?.reload();
            }
          }
        });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: "",
              desc: "",
            });
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: "",
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <SubMenu menus={subUserID} breadcrumbs={breadcrumbs} />
      </Container>
      <form
        className={styles.containerFavoritePlace}
        onSubmit={handleSubmit(onSubmit)}
      >
        {rendercard1()}
        {rendercard2()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: "white", fontSize: "70px" }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default DetailFavoritePlace;
