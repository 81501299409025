import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import { subMenuPostWork } from "utils/submenuoptions";
import SearchLocation from "components/work/RouteOptimiza/SearchLocation";
import Map from "../../components/work/RouteOptimiza/Map";
import LoadingBackground from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import axios from "axios";
import ENV from "utils/environment";
import API from "../../utils/api/work/work";
import styles from "./RouteOptimize.module.css";

const api = API.create();

const RouteOptimize = () => {
  const navigate = useNavigate();
  const { ref } = useRef();
  const [listPoint, setListPoint] = useState([
    {
      field_name: "A",
      type: "receipt_point",
      value: {},
      error: false,
    },
    {
      field_name: "B",
      type: "send_point",
      value: {},
      error: false,
    },
  ]);
  const [routeOptimize, setRouteOptimize] = useState({
    point: [],
    total_distance: null,
    total_time: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [routeMode, setRouteMode] = useState("t");

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`ประเภทรถ`}
        menus={subMenuPostWork.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `จัดเส้นทาง` ? true : false,
          };
        })}
        breadcrumbs={[
          {
            label: "ประกาศงาน",
            path: null,
          },
          {
            label: "จัดเส้นทาง",
            path: null,
          },
        ]}
      />
    );
  };

  const handleChangePoint = ({
    field_name = "",
    type = "receipt_point",
    value = {},
  }) => {
    let _listPoint = [...listPoint];
    const index = _listPoint.findIndex((item) => {
      return item.field_name === field_name;
    });

    if (index >= 0) {
      _listPoint[index] = {
        ..._listPoint[index],
        value: value,
        error:
          R.isNil(_listPoint[index].value) || R.isEmpty(_listPoint[index].value)
            ? true
            : false,
      };
    }

    setListPoint(_listPoint);
  };
  const handleAddPoint = () => {
    setListPoint([
      ...listPoint,
      {
        field_name: String.fromCharCode(65 + listPoint.length),
        value: {},
        type: "send_point",
        error: false,
      },
    ]);
  };
  const handleRouteOptimize = async () => {
    try {
      setIsLoading(true);
      const empty_value_index = [];
      listPoint.forEach((point, index) => {
        if (
          R.isEmpty(point.value) ||
          R.isNil(point.value.lat) ||
          R.isNil(point.value.long)
        ) {
          empty_value_index.push(index);
        }
      });

      if (!R.isEmpty(empty_value_index)) {
        let _listPoint = [...listPoint];
        empty_value_index.forEach((index) => {
          _listPoint[index] = { ..._listPoint[index], error: true };
        });
        setAlertModal({
          open: true,
          title: `เกิดข้อผิดพลาด`,
          description: "กรุณาเลือกสถานที่ให้ครบ",
        });
        setListPoint(_listPoint);
      } else {
        let request_data = {
          lon: [],
          lat: [],
          cost: false, // true : time_step and distance_step details will be included || false : Only the optimal path without additional information will be returned
          otsp: false, // true : Return to start/first point || false : Do not return to start/first point
          // mode: "Avoid traffic",
          mode: routeMode,
          key: ENV.MAP_KEY[ENV.ENV].KEY_3,
        };

        listPoint.forEach((point) => {
          request_data.lon.push(point.value.long);
          request_data.lat.push(point.value.lat);
        });

        const resp = await api.routeOptimiza(request_data);
        if (resp.code === 1000) {
          const route_optimize_point = [];
          resp.data.sequence.forEach((seq) => {
            route_optimize_point.push(listPoint[seq]);
          });
          setRouteOptimize({
            point: route_optimize_point,
            total_distance: resp.data.total_distance,
            total_time: resp.data.total_time,
          });
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log("error handleRouteOptimize: ", error);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.description,
      });
      setIsLoading(false);
    }
  };
  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };
  const handleRemoveField = (index) => {
    let list_point = [...listPoint];
    list_point.splice(index, 1);
    const new_list_point = list_point.map((point, index) => {
      return {
        ...point,
        field_name: String.fromCharCode(65 + index),
      };
    });

    setListPoint(new_list_point);
    setRouteOptimize({
      point: [],
      total_distance: null,
      total_time: null,
    });
  };
  const handleRouteModeChange = (value) => {
    setRouteMode(value);
  };

  return (
    <Container className={styles.main_container}>
      {isLoading === true ? <LoadingBackground /> : null}
      {renderSubMenu()}
      <div className={styles.route_optimize_container}>
        <SearchLocation
          listPoint={listPoint}
          routeMode={routeMode}
          handleChangePoint={handleChangePoint}
          handleAddPoint={handleAddPoint}
          handleRouteOptimize={handleRouteOptimize}
          handleRemoveField={handleRemoveField}
          handleRouteModeChange={handleRouteModeChange}
        />

        <Map routeOptimize={routeOptimize} />
      </div>
      {renderModal()}
    </Container>
  );
};

export default RouteOptimize;
