import React from "react";
import { Controller } from "react-hook-form";
import * as R from "ramda";
// import FieldFindLocation from "components/field/FieldFindLocation";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RemoveCircleOutline } from "@mui/icons-material";
// import { LongdoMap, map, longdo } from "../../map/LongdoMap";
import styles from "./SearchLocation.module.css";
import InputLongdoAutoComplete from "components/input/InputLongdoAutoComplete";
import Button from "components/button/Button";
import FieldSelect from "components/field/FieldSelect";

const SearchLocation = ({
  listPoint = [],
  routeMode = "t",
  handleChangePoint = () => {},
  handleAddPoint = () => {},
  handleRouteOptimize = () => {},
  handleRemoveField = () => {},
  handleRouteModeChange = () => {},
}) => {
  const renderFieldSearchLocation = () => {
    return (
      <div>
        {!R.isEmpty(listPoint)
          ? listPoint.map((point, index) => {
              return (
                <div className={styles.field_container}>
                  <section className={styles.input_longdo}>
                    <InputLongdoAutoComplete
                      value={point.value}
                      label={
                        point.type === "receipt_point"
                          ? "จุดรับสินค้า"
                          : "จุดส่งสินค้า"
                      }
                      onChange={(val) => {
                        handleChangePoint({
                          field_name: point.field_name,
                          type: point.type,
                          value: val,
                        });
                      }}
                      renderFrontIcon={() => {
                        return (
                          <div
                            className={styles.start_adornment_container}
                            style={{
                              color: point.error === true ? "red" : null,
                            }}
                          >
                            {point.field_name}
                          </div>
                        );
                      }}
                      // descriptionUnderField={
                      //   "สามารถค้นหาจากชื่อสถานที่ พิกัดละติจูด,ลองจิจูด และ Google Plus Code ได้"
                      // }
                      closeFav={true}
                      error={point.error}
                    />
                  </section>

                  <section className={styles.delete_button}>
                    {index >= 2 ? (
                      <RemoveCircleOutline
                        onClick={() => {
                          handleRemoveField(index);
                        }}
                        sx={{ color: "#121f43", cursor: "pointer" }}
                      />
                    ) : null}
                  </section>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const renderAddBtn = () => {
    return (
      <div
        className={styles.select_location_route_container}
        onClick={() => {
          handleAddPoint();
        }}
      >
        <div style={{ paddingTop: 5 }}>
          <AddCircleIcon />
        </div>
        <div>
          <p>เพิ่มจุดส่งสินค้า</p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.search_location_container}>
      {renderFieldSearchLocation()}
      {renderAddBtn()}

      <FieldSelect
        name={"เงื่อนไขการจัดเส้นทาง"}
        value={routeMode}
        options={[
          {
            value: "t",
            name: "เส้นทางที่เดินทางเร็วที่สุด (คำนวณจากการจราจรปัจจุบัน)",
          },
          {
            value: "c",
            name: "เส้นทางที่เดินทางเร็วที่สุด (คำนวณจากสติการจราจร)",
          },
          {
            value: "e",
            name: "เส้นทางที่เดินทางสั้นที่สุด (คำนวณจากการจราจรปัจจุบัน)",
          },
          {
            value: "d",
            name: "เส้นทางที่เดินทางสั้นที่สุด (คำนวณจากสติการจราจร)",
          },
        ]}
        onChange={(value) => {
          handleRouteModeChange(value);
        }}
      />

      <Button
        disabled={listPoint.find((point) => point.error === true)}
        onClick={() => {
          handleRouteOptimize();
        }}
      >{`จัดเส้นทาง`}</Button>
    </div>
  );
};

export default SearchLocation;
