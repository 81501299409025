import instance from "../../axiosInstance";

const getAllProject = async (param) => {
  try {
    return instance.get(`/api/project`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const getProjectByProjectCode = async (project_code = null) => {
  try {
    return await instance.get(
      `api/project/get-by-project-code/${project_code}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const addProject = async (profile_id, body) => {
  try {
    return instance.post(`/api/project/${profile_id}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getProjectById = async (project_id) => {
  try {
    return instance.get(`/api/project/${project_id}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editProject = async (project_id, body) => {
  try {
    return instance.patch(`/api/project/${project_id}`, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllCarrierInProject = async (project_id, param) => {
  try {
    return instance.get(`/api/project/all-carrier-in-project/${project_id}`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const addCarrierInProject = async (project_id, body) => {
  try {
    return instance.post(
      `/api/project/carrier-in-project/${project_id}`,
      body,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

const getCarrierInProjectById = async (carrier_in_project_id) => {
  try {
    return instance.get(
      `/api/project/carrier-in-project/${carrier_in_project_id}`
    );
  } catch (error) {
    console.log(error);
  }
};

const editCarrierInProject = async (carrier_in_project_id, data) => {
  try {
    return instance.patch(
      `/api/project/carrier-in-project/${carrier_in_project_id}`,
      data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const getAllProjectAdmin = async (project_id, param) => {
  try {
    return instance.get(`/api/project/all-project-admin/${project_id}`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const findAdminByRole = async (role) => {
  try {
    return instance.get(`/api/find-admin-in-role`, {
      params: role,
    });
  } catch (error) {
    console.log(error);
  }
};

const addProjectAdmin = async (project_id, body) => {
  try {
    return instance.post(`/api/project/project-admin/${project_id}`, body, {
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getProjectAdminId = async (project_admin_id) => {
  try {
    return instance.get(`/api/project/project-admin/${project_admin_id}`);
  } catch (error) {
    console.log(error);
  }
};

const editProjectAdmin = async (project_admin_id, data) => {
  try {
    return instance.patch(
      `/api/project/project-admin/${project_admin_id}`,
      data,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

const calculateCreditProject = async (project_id, param) => {
  try {
    return instance.get(`/api/project/calculate-credit-project/${project_id}`, {
      params: param,
    });
  } catch (error) {
    console.log(error);
  }
};

export {
  getAllProject,
  addProject,
  getProjectById,
  editProject,
  getAllCarrierInProject,
  addCarrierInProject,
  getCarrierInProjectById,
  editCarrierInProject,
  getAllProjectAdmin,
  findAdminByRole,
  addProjectAdmin,
  getProjectAdminId,
  editProjectAdmin,
  calculateCreditProject,
};
