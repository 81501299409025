import helper from "utils/helper";
import instance from "../../axiosInstance";

const Province = async (params = {}) => {
  try {
    return await instance.get(`api/master/province`, {
      params: params,
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const District = async (params = {}) => {
  try {
    let param = "?";
    param += helper.getParam(params);
    return await instance.get(`api/master/district` + param, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const SubDistrict = async (params = {}) => {
  try {
    return await instance.get(`api/master/subdistrict`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const DistrictID = async (id) => {
  try {
    return await instance.get(`api/master/district/${id}`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const SubDistrictID = async (id) => {
  try {
    return await instance.get(`api/master/subdistrict/${id}`, {
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
const masterProvice = {
  Province,
  District,
  SubDistrict,
  DistrictID,
  SubDistrictID,
};

export default masterProvice;
