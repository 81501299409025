import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { UploadFile } from "@mui/icons-material";
import FormModal from "components/modal/FormModal";
import FieldInput from "components/field/FieldInput";
import AlertModal from "components/modal/AlertModal";
import FieldUpload from "components/field/FieldUpload";
import TemplateForm from "components/templateForm/TemplateForm";
import Button from "components/button/Button";
import helper from "utils/helper";
import { ProfileType } from "utils/enum";
import {
  getAllCountry,
  getAllProvince,
  getAllZone,
  getDistrict,
  getSubDistrict,
} from "utils/api/masterData/gis";
import { createProfileJoinCarrier } from "utils/api/profile/profileJoinCarrier";
import { findCompanyName } from "utils/api/profile/profile";

const CreateProfileJoinCarrierModal = ({
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const { user_id, profile_id } = useParams();
  const form_confim_btn = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const handleCreateProfileJoinCarrier = async () => {
    setIsLoading(true);
    try {
      const body = {
        owner_id: Number(profile_id),
        profile_id: getValues().co_runner_profile_id,
        active: true,
      };

      const create_resp = await createProfileJoinCarrier(body);
      if (create_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log("error,", error);

      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setOpen(false);
      setIsLoading(false);
    }
  };

  const handleGetProfileName = async () => {
    setIsLoading(true);
    try {
      const user_code = getValues().user_code ? getValues().user_code : null;
      if (!R.isNil(user_code) && !R.isEmpty(user_code)) {
        const resp = await findCompanyName(user_code);

        if (resp.data.code === 1000) {
          const data = resp.data.data;
          if (data.id !== Number(profile_id)) {
            setValue(`company_name`, data.company_name);
            setValue(`co_runner_profile_id`, data.id);
            clearErrors(`user_code`);
          } else {
            setError(`user_code`, {
              type: "error",
              message: `ไม่สามารถใช้รหัสผู้ใช้งานหลักนี้ได้`,
            });
            setValue(`company_name`, null);
            setValue(`co_runner_profile_id`, null);
          }
        }
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.description
      ) {
        setError(`user_code`, {
          type: "error",
          message: error.response.data.description,
        });
      }
      setValue(`company_name`, null);
    }
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  const renderForm = () => {
    const list_form = [
      {
        name: "user_code",
        field_type: "text",
        field_label: "รหัสผู้ใช้งานหลัก*",
        rules: {
          required: "กรุณาใส่รหัสผู้ใช้งานหลัก",
        },
        error: errors[`user_code`] ? errors[`user_code`] : false,
        errorText: errors[`user_code`] ? errors[`user_code`].message : null,
      },
      {
        name: "company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
    ];

    return (
      <div>
        {list_form.map((menu, index) => {
          return (
            <>
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
              />

              {menu.name === "user_code" && (
                <div
                  style={{
                    marginBottom: `23px`,
                  }}
                >
                  <Button
                    type={`button`}
                    onClick={() => {
                      handleGetProfileName();
                    }}
                  >{`ค้นหา`}</Button>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <FormModal
        title={`เพิ่มผู้ขนส่งร่วมวิ่ง`}
        open={open}
        confirmTextBtn={"บันทึก"}
        cancelTextBtn={"ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          reset();
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handleCreateProfileJoinCarrier();
          })}
        >
          {renderForm()}

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateProfileJoinCarrierModal;
