// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableCustom_rowCheck__--DWZ td:first-child,
.TableCustom_rowCheck__--DWZ th:first-child {
  border-radius: 10px 0 0 10px;
}
.TableCustom_rowChecked__n\\+K7n td:first-child,
.TableCustom_rowChecked__n\\+K7n th:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 2px solid var(--mui-palette-secondary-main);
  border-top: 2px solid var(--mui-palette-secondary-main);
  border-bottom: 2px solid var(--mui-palette-secondary-main);
}
.TableCustom_rowChecked__n\\+K7n td,
.TableCustom_rowChecked__n\\+K7n th {
  border-top: 2px solid var(--mui-palette-secondary-main);
  border-bottom: 2px solid var(--mui-palette-secondary-main) !important;
}

.TableCustom_rowCheck__--DWZ td:last-child,
.TableCustom_rowCheck__--DWZ th:last-child {
  border-radius: 0 10px 10px 0;
}

.TableCustom_rowChecked__n\\+K7n td:last-child,
.TableCustom_rowChecked__n\\+K7n th:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 2px solid var(--mui-palette-secondary-main);
  border-top: 2px solid var(--mui-palette-secondary-main);
  border-bottom: 2px solid var(--mui-palette-secondary-main);
}
.TableCustom_tableCustom__8gENW.TableCustom_MuiTable-root__VJKeC {
  border-collapse: separate;
  border-spacing: 0 20px;
}

.TableCustom_tableCustom__8gENW.TableCustom_MuiTable-root__VJKeC tr {
  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/table/TableCustom.module.css"],"names":[],"mappings":"AAAA;;EAEE,4BAA4B;AAC9B;AACA;;EAEE,4BAA4B;EAC5B,wDAAwD;EACxD,uDAAuD;EACvD,0DAA0D;AAC5D;AACA;;EAEE,uDAAuD;EACvD,qEAAqE;AACvE;;AAEA;;EAEE,4BAA4B;AAC9B;;AAEA;;EAEE,4BAA4B;EAC5B,yDAAyD;EACzD,uDAAuD;EACvD,0DAA0D;AAC5D;AACA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;AACrB","sourcesContent":[".rowCheck td:first-child,\n.rowCheck th:first-child {\n  border-radius: 10px 0 0 10px;\n}\n.rowChecked td:first-child,\n.rowChecked th:first-child {\n  border-radius: 10px 0 0 10px;\n  border-left: 2px solid var(--mui-palette-secondary-main);\n  border-top: 2px solid var(--mui-palette-secondary-main);\n  border-bottom: 2px solid var(--mui-palette-secondary-main);\n}\n.rowChecked td,\n.rowChecked th {\n  border-top: 2px solid var(--mui-palette-secondary-main);\n  border-bottom: 2px solid var(--mui-palette-secondary-main) !important;\n}\n\n.rowCheck td:last-child,\n.rowCheck th:last-child {\n  border-radius: 0 10px 10px 0;\n}\n\n.rowChecked td:last-child,\n.rowChecked th:last-child {\n  border-radius: 0 10px 10px 0;\n  border-right: 2px solid var(--mui-palette-secondary-main);\n  border-top: 2px solid var(--mui-palette-secondary-main);\n  border-bottom: 2px solid var(--mui-palette-secondary-main);\n}\n.tableCustom.MuiTable-root {\n  border-collapse: separate;\n  border-spacing: 0 20px;\n}\n\n.tableCustom.MuiTable-root tr {\n  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowCheck": `TableCustom_rowCheck__--DWZ`,
	"rowChecked": `TableCustom_rowChecked__n+K7n`,
	"tableCustom": `TableCustom_tableCustom__8gENW`,
	"MuiTable-root": `TableCustom_MuiTable-root__VJKeC`
};
export default ___CSS_LOADER_EXPORT___;
