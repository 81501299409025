import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import FieldIconLeftRight from "components/field/FieldIconLeftRight";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Search } from "@mui/icons-material/";
import { FormControl, InputLabel, Input } from "@mui/material";
import HelperMap from "utils/helperMap";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
export let search;
// export let suggest;
export default function FieldFindLocation(props) {
  const { value, label, error, onChange = () => {} } = props;
  const [placeList, setPlaceList] = useState([]);
  const [location, setLocation] = useState({
    lat: 13.7908313,
    lon: 100.5422222,
  });
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    console.log("value: ", value);
  }, [value]);

  useEffect(() => {
    if (map && longdo) {
      initLongdoMap();
    }
  }, [map, longdo]);

  const initLongdoMap = () => {
    search = document.getElementById("location-search");
    search.onkeyup = function (event) {
      if ((event || window.event).keyCode != 13) return;
      doSearch();
    };

    search.oninput = function () {
      if (search.value.length < 3) {
        return;
      }

      map.Search.suggest(search.value);
    };

    map.Event.bind("suggest", async function (result) {
      if (result.meta.keyword != search.value) return;

      if (search?.value?.length > 0) {
        let isLatLong = search?.value?.split(",");
        if (isLatLong && isLatLong.length === 2 && isLatLong[1] !== "") {
          if (/.[0-9]/.test(isLatLong[1])) {
            console.log("do get from lat long");
            setPlaceList([]);
            getPlaceLocation({
              lat: isLatLong[0],
              lon: isLatLong[1],
            });
          } else {
            const list = await HelperMap.getSuggest(search.value);
            console.log("data list suggest", list);
            setPlaceList(list);
          }
        } else {
          const list = await HelperMap.getSuggest(search.value);
          console.log("data list suggest", list);
          setPlaceList(list);
        }
      }
    });
  };
  const getPlaceLocation = async (loc) => {
    let place = await HelperMap.getPlaceFormLatLong(loc);
    console.log("place", place);
    if (
      place.province &&
      place.province !== "" &&
      place.district &&
      place.district !== "" &&
      place.subdistrict &&
      place.subdistrict !== "" &&
      place.postcode &&
      place.postcode !== ""
    ) {
      let list = [
        {
          ...place,
          lat: loc.lat,
          long: loc.lon,
          w:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
          address:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
        },
      ];
      console.log("list", list);
      setPlaceList(list);
    }
    // return
  };
  function doSearch() {
    // map.Search.language("th");
    map.Search.search(search.value);
    // suggest.style.display = "none";
  }
  const doSuggest = (value) => {
    search.value = value;
    console.log("value", value);
    // doSearch();
  };

  return (
    <div style={{ paddingBottom: "23px", width: "100%", position: "relative" }}>
      <div id={"result"}></div>
      <FormControl variant="standard" fullWidth margin="none">
        <TextField
          placeholder=""
          value={value?.search ?? ""}
          error={error}
          onChange={(val) => {
            onChange({ ...value, search: val.target.value });
          }}
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 150);
          }}
          id={"location-search"}
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  sx={{
                    color: error ? "error.main" : "grey.main",
                    fontSize: "25px",
                  }}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            marginTop: "16px",
            "& .MuiInputBase-root": {
              padding: "0 17px",
            },
          }}
        />
        <div style={{ color: "red", fontSize: "12px" }}>
          {props.errorText || ""}
        </div>
      </FormControl>
      {placeList.length > 0 && isFocused ? (
        <div
          style={{
            position: "absolute",
            zIndex: 10000,
            backgroundColor: "white",
            width: "100%",
          }}
        >
          {placeList &&
            placeList.map((ele, index) => {
              return (
                <div
                  key={"google-lat-long" + index}
                  style={{ fontSize: 12, fontWeight: 600, padding: 5 }}
                  onClick={async () => {
                    let data = await HelperMap.getPlace(ele.w);
                    onChange({
                      ...value,
                      ...data,
                      long: data.lon,
                      search: data.name,
                    });
                  }}
                >
                  {ele.w}
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
}
