import React, { useEffect, useState } from "react";
import SubMenu from "components/layout/SubMenu.js";
import * as R from "ramda";
import { useNavigate, useParams } from "react-router-dom";
import { subMenuMasterData } from "utils/submenuoptions";
import "./template.css";
import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import { Search } from "@mui/icons-material";
import helper from "utils/helper";
import TableCustomThirdParty from "components/table/ThirdPartyTable/TableCustomThirdParty";
import { Check, Clear } from "@mui/icons-material";
import FloatingButton from "components/button/FloatingButton";
import ModalWork from "components/work/modalWork";
import Line from "components/layout/line";
import { useForm, Controller } from "react-hook-form";
import InputNormal from "components/input/inputNormal";
import API from "utils/api/work/work";
import Swal from "sweetalert2";
import TableActiveIcon from "components/tableActiveIcon";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import ImportTemplate from "./importTemplate/ImportTemplate";
import { downloadTemplateWork } from "utils/api/templateWork/templateWork";
import AlertModal from "components/modal/AlertModal";
import SelectRowValue from "components/button/SelectRowValue";
const api = API.create();

export default function Template() {
  const navigate = useNavigate();
  const columns = [
    {
      id: "created_at",
      label: <label style={{ fontSize: 20 }}>วันที่สร้างรายการ</label>,
      align: "center",
      //   width: "13%",
      format: (value) => (
        <label style={{ fontSize: 20 }}>
          {helper.ToConvertDateTimeForTable(value.created_at)}
        </label>
      ),
    },
    {
      id: "updated_at",
      label: <label style={{ fontSize: 20 }}>วันที่แก้ไขข้อมูลล่าสุด</label>,
      align: "center",
      //   width: "13%",
      format: (value) => (
        <label style={{ fontSize: 20 }}>
          {helper.ToConvertDateTimeForTable(value.updated_at)}
        </label>
      ),
    },
    {
      id: "name",
      label: (
        <label style={{ fontSize: 20 }}>
          อัตราน้ำมันเริ่มต้น-สิ้นสุด สำหรับลูกค้า
        </label>
      ),
      align: "center",
      //   width: "13%",
      format: (value) => (
        <label style={{ fontSize: 20 }}>
          {value.fuel_rate_start_shipper + " - " + value.fuel_rate_end_shipper}
        </label>
      ),
    },
    {
      id: "usage",
      label: (
        <label style={{ fontSize: 20 }}>
          อัตราน้ำมันเริ่มต้น-สิ้นสุด สำหรับผู้ขนส่ง
        </label>
      ),

      align: "center",
      //   width: "13%",
      format: (value) => (
        <label style={{ fontSize: 20 }}>
          {value.fuel_rate_start_carrier + " - " + value.fuel_rate_end_carrier}
        </label>
      ),
    },
    {
      id: "",
      label: <label style={{ fontSize: 20 }}>ดาวน์โหลด</label>,
      align: "center",
      format: (value) => {
        return (
          <label
            style={{
              fontSize: 20,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={async () => {
              await handleDownloadTemplateFuelRateQuotation(value.id);
            }}
          >
            {"ดาวน์โหลด"}
          </label>
        );
      },
    },
    {
      id: "project_name",
      label: <label style={{ fontSize: 20 }}>สถานะใช้งาน</label>,

      align: "center",
      //   width: "13%",
      format: (value) => <TableActiveIcon active_status={value.active} />,
    },
  ];
  const { user_id, project_id, user_code, project_code, profile_id } =
    useParams();
  const MenuProject = [
    {
      label: "รายละเอียดโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}`,
      //   header: "ข้อมูลทั่วไป",
    },
    {
      label: "แม่แบบงาน",
      path: ``,
      active: true,
    },
    {
      label: "ควาามคืบหน้าโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/project-progression`,
    },
    {
      label: "ควาามคืบหน้าในการหาผู้ขนส่ง",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/carrier-progression`,
    },
    {
      label: "ผู้ขนส่งในโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/carrier-in-project`,
    },
    {
      label: "ผู้รับผิดชอบโครงการ",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/detail/${project_id}/${project_code}/project-admin`,
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${project_code}`,
      path: "",
    },
    {
      label: "แม่แบบงาน",
      path: "",
    },
  ];
  const [filter, setFilter] = useState({
    order_by: "created_at",
    order_by_value: 1,
    profile_id: profile_id,
    project_id: project_id,
  });
  const [filterPerPage, setFilterPerPage] = useState([
    { value: "5", name: "5" },
  ]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 5,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm({});
  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  const renderSubMenu = () => {
    return <SubMenu menus={MenuProject} breadcrumbs={breadcrumbs} />;
  };
  const getData = async (page = pagination) => {
    let fil = { ...filter, ...page };
    console.log(fil);
    await api
      .getTemplate(fil)
      .then((response) => {
        console.log(response);
        let data_same = [];
        // response.data.results.forEach((element) => {
        //   if (data_same.length > 0) {
        //     if (
        //       data_same
        //         .map((e) => e.project_fuel_rate_id)
        //         .includes(element.project_fuel_rate_id)
        //     ) {
        //     } else {
        //       data_same.push(element);
        //     }
        //   } else {
        //     data_same.push(element);
        //   }
        // });
        console.log("data_same", data_same);
        setData(response.data.results);
        let PerPage = [{ value: "5", name: "5" }];
        if (response.data.total > 5) {
          PerPage.push({ value: "10", name: "10" });
        }
        if (response.data.total > 10) {
          PerPage.push({ value: "15", name: "15" });
        }
        if (response.data.total > 15) {
          PerPage.push({ value: "20", name: "20" });
        }
        if (response.data.total > 20) {
          PerPage.push({ value: "50", name: "50" });
        }
        if (response.data.total > 50) {
          PerPage.push({ value: "100", name: "100" });
        }
        if (response.data.total > 100) {
          PerPage.push({ value: "250", name: "250" });
        }
        if (response.data.total > 250) {
          PerPage.push({ value: "500", name: "500" });
        }
        if (response.data.total > 500) {
          PerPage.push({ value: "1000", name: "1000" });
        }
        setFilterPerPage(PerPage);
        setPagination({
          page: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        setRefresh(false);
      })
      .catch((error) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };
  const add = async (detail) => {
    console.log(detail);
    await api
      .createKey(detail)
      .then((response) => {
        // console.log(response);
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "เพิ่ม Key เสร็จสิ้น",
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {
            // window.location.href = "/work";
            reset();
            setCreateModal(false);
            setRefresh(true);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  const edit = async (detail) => {
    console.log(detail);
    await api
      .editKey(detail, editModal)
      .then((response) => {
        // console.log(response);
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "แก้ไข Key เสร็จสิ้น",
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {
            // window.location.href = "/work";
            reset();
            setEditModal(null);
            setRefresh(true);
          },
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  async function handleDownloadTemplateFuelRateQuotation(
    project_fuel_rate_id = null
  ) {
    try {
      if (!R.isNil(project_fuel_rate_id) && !R.isEmpty(project_fuel_rate_id)) {
        const download_response = await downloadTemplateWork(
          project_fuel_rate_id
        );

        helper.downloadFile({
          api_response: download_response,
        });
      }
    } catch (error) {
      console.log("error handleDownloadTemplateFuelRateQuotation:", error);
      setAlertModal({
        open: true,
        title: "พบปัญหาขณะดาวน์โหลดแม่แบบงาน",
        description: error.message,
      });
    }
  }

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <div className="third-party-body">
      {renderSubMenu()}
      {renderModal()}

      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "15px 20px 10px 20px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid
          container
          rowSpacing={2}
          justifyContent="space-between"
          // width={"100%"}
        >
          <Grid item xs={12} sm={6} md={6} lg={3} className="gridItem">
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "active", name: "สถานะใช้งาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={filter.order_by}
              value={filter.order_by}
              onChange={(val) => {
                setFilter({ ...filter, order_by: val });
              }}
              closePaddingBottom={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={2.5} className="gridItem">
              <FieldSelect
                name={"ค้นหาจาก"}
                options={[
                  { value: "name", name: "ชื่อบริการ" },
                  { value: "usage", name: "การใช้งาน" },
                  { value: "param", name: "ชื่อตัวแปร" },
                  { value: "key", name: "Key ที่ใช้งาน" },
                ]}
                style={{
                  width: "-webkit-fill-available",
                }}
                defaultValue={filter.search_type}
                value={filter.search_type}
                onChange={(val) => {
                  setFilter({ ...filter, search_type: val });
                }}
                closePaddingBottom={true}
              />
            </Grid> */}
          <Grid item xs={12} sm={6} md={6} lg={3} className="gridItem">
            <FieldDateRangePicker
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter.datetime_from ?? "",
                end_date: filter.datetime_to ?? "",
              }}
              style={{ zIndex: 101, marginTop: 1 }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  datetime_from: new Date(val[0]).toISOString(),
                  datetime_to: new Date(val[1]).toISOString(),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} className="gridItem">
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_value}
              onChange={(val) => {
                setFilter({ ...filter, search_value: val.target.value });
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
            className="gridItem"
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                getData({ ...pagination, page: 1 });
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 30 }} />
            </Avatar>

            <div>
              <SelectRowValue
                onSelect={(val) => {
                  setPagination({ ...pagination, per_page: val });
                  getData({ ...pagination, per_page: val });
                }}
              />
              {/* <FieldSelect
                name={"จำนวนแถว"}
                options={filterPerPage}
                style={{
                  // width: "-webkit-fill-available",
                  width: 80,
                }}
                defaultValue={"5"}
                value={pagination.per_page}
                onChange={(val) => {
                  setPagination({ ...pagination, per_page: val });
                  getData({ ...pagination, per_page: val });
                }}
                closePaddingBottom={true}
              /> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <TableCustomThirdParty
        columns={columns}
        dataSource={data}
        pagination={pagination}
        setPagination={(val) => {
          setPagination(val);
          getData(val);
        }}
        onClick={(val) => {
          console.log(val);

          window.location.href = `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/template/${val.id}`;
        }}
        refresh={refresh}
      />
      <FloatingButton
        button_type="add"
        onClick={() => {
          setCreateModal(true);
        }}
      />
      <ModalWork
        open={createModal}
        close={() => {
          setCreateModal(false);
        }}
        enableClose
        maxWidth
        minHeight
      >
        <ImportTemplate
          refresh={() => {
            setCreateModal(false);
            setRefresh(true);
          }}
        />
      </ModalWork>
    </div>
  );
}
