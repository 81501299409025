import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./modalSelectMap.css";
// const google = window.google;
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 400,
  flexDirection: "column",
  overflow: "scroll",
  maxHeight: "90vmin",
  //   height: 200,
  bgcolor: "background.paper",
  borderRadius: 5,
  color: "#8ca3ba",
  boxShadow: 24,
  // pt: 3,
  // px: 3,
  // pb: 3,
};
const ModalWork = (props) => {
  const {
    value,
    close,
    open,
    onChange,
    confirm,
    minHeight,
    width,
    maxWidth = false,
  } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  console.log("width", width);
  return (
    <div className="modal-work">
      <Modal open={open}>
        <Box
          sx={{
            ...style,
            minHeight: minHeight ? "80vh" : "",
            minWidth: maxWidth
              ? window.innerWidth * 0.9
              : width ?? isMobile
              ? 350
              : 400,
            maxWidth: maxWidth
              ? window.innerWidth * 0.9
              : width ?? isMobile
              ? 350
              : 400,
          }}
        >
          {props.enableClose ? (
            <div
              style={{
                float: "right",
                cursor: "pointer",
                textAlign: "end",
                padding: "10px 10px 0px 0px",
                marginBottom: -5,
              }}
              onClick={() => {
                close();
              }}
            >
              <CloseIcon sx={{ color: "#8ca3ba" }} />
            </div>
          ) : null}
          <div>{props.children}</div>
          {props.showBottom ? (
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                padding: 20,
                borderRadius: 20,
                textAlign: "center",
              }}
            >
              {props.bottombar}
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalWork;
