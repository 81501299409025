import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FilledInput, IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import "./modalSelectMap.css";
import helper from "utils/helper";
import HelperMap from "utils/helperMap";
import FieldIconLeftRight from "components/field/FieldIconLeftRight";
import Line from "components/layout/line";
import InputNormal from "components/input/inputNormal";
import InputDisable from "components/input/inputDisable";
import InputSelect from "components/input/inputSelect";
import InputImageShow from "components/input/InputImageShow";
import ProductList from "components/input/productList";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Swal from "sweetalert2";
import { getAllStlStandardSize } from "utils/api/masterData/stlStandardSize";
// const google = window.google;

const isMobile = window.innerWidth <= 768 ? true : false;
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: isMobile ? 350 : 400,
  maxWidth: isMobile ? 350 : 400,
  flexDirection: "column",
  height: "80vh",
  // maxHeight: "90vmin",
  //   height: 200,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  // pt: 3,
  // px: 3,
  // pb: 3,
};
const ModalSelectProduct = (props) => {
  const {
    value,
    close,
    open,
    onChange,
    confirm,
    productType,
    workType,
    is_detail = false,
  } = props;
  const [showProduct, setShowProduct] = useState({ index: null, show: false });
  const [checkData, setCheckData] = useState(false);
  const [size, setSize] = useState(null);
  const [stlSize, setStlSize] = useState([]);
  // const [product, setProduct] = useState()
  // console.log("product", value);
  useEffect(() => {
    if (workType === "stl") {
      stlSizeCal();
    }
  }, [value, workType]);
  useEffect(() => {
    // console.log("workType", workType);
    if (workType === "stl") {
      getStl();
    }
  }, [workType]);
  const getStl = async () => {
    let stl = await getAllStlStandardSize();
    console.log("stl", stl);
    setStlSize(stl.data.data.results);
  };
  const stlSizeCal = () => {
    console.log("stlSize", stlSize);
    let weight = 0;
    let height = 0;
    let length = 0;
    let width = 0;
    let volume = 0;
    value?.forEach((ele) => {
      weight +=
        parseInt(ele?.product_weight !== "" ? ele?.product_weight : 0) *
        parseInt(ele?.product_amount !== "" ? ele?.product_amount : 1);
      volume += parseFloat(
        ele?.product_volume !== "" ? ele?.product_volume : 0
      );
    });
    let size;

    stlSize.forEach((e) => {
      if (
        volume <= parseFloat(e.volume) &&
        weight <= parseFloat(e.weight) &&
        !size
      ) {
        size = e;
      }
    });
    setSize(size);
    console.log("size", size);
  };
  useEffect(() => {
    console.log("productType", productType);
  }, [productType]);
  useEffect(() => {
    if (size) {
      let tmp = value;
      // tmp[index].product_weight = val.target.value;

      tmp?.forEach((ele, index) => {
        tmp[index].product_size = size?.id ?? "";
      });
      onChange(tmp);
    }
  }, [size]);
  const getWeight = () => {
    let weight = 0;
    value?.forEach((ele) => {
      weight +=
        parseInt(
          ele?.product_weight && ele?.product_weight !== ""
            ? ele?.product_weight
            : 0
        ) *
        parseInt(
          ele?.product_amount && ele?.product_amount !== ""
            ? ele?.product_amount
            : 1
        );
    });

    return weight === 0 || isNaN(weight) ? "-" : weight;
  };
  const getVolume = () => {
    let volume = 0;
    value?.forEach((ele) => {
      volume += parseFloat(
        ele?.product_volume && ele?.product_volume !== ""
          ? ele?.product_volume
          : 0
      );
    });

    return volume === 0 || isNaN(volume) ? "-" : volume.toFixed(2);
  };
  const confirmCheck = () => {
    let missing = false;
    value.forEach((ele) => {
      console.log(ele);
      if (ele.product_id === "") {
        missing = true;
      }
      if (ele.product_name === "") {
        missing = true;
      }
      if (ele.product_length === "" && workType === "stl") {
        missing = true;
      }
      if (ele.product_width === "" && workType === "stl") {
        missing = true;
      }
      if (ele.product_height === "" && workType === "stl") {
        missing = true;
      }
      if (ele.product_amount === "" && workType === "stl") {
        missing = true;
      }
      if (ele.product_weight === "") {
        missing = true;
      }
      if (!ele.product_image || ele.product_image?.length < 0) {
        missing = true;
      }
    });
    if (missing) {
      setCheckData(true);
    }
    return missing;
  };
  return (
    <div>
      <Modal open={open} className="modal-select-truck">
        <Box sx={{ ...style }}>
          <div
            style={{
              float: "right",
              cursor: "pointer",
              textAlign: "end",
              padding: 10,
            }}
            onClick={() => {
              setCheckData(false);
              setSize();
              close();
            }}
          >
            <CloseIcon sx={{ color: "#8ca3ba" }} />
          </div>
          <div
            style={{
              flex: 1,
              overflow: "scroll",
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 20,
            }}
          >
            {value?.map((ele, index) => {
              return (
                <div key={index + "product"} style={{ marginBottom: 30 }}>
                  <div
                    style={{
                      padding: "0px 0px 5px 10px",
                      opacity: 0.8,
                      display: "flex",
                    }}
                  >
                    <div style={{ flex: 1 }}>ประเภทสินค้าที่ {index + 1}*</div>
                    {index !== 0 && !is_detail ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let tmp = [...value];
                          tmp.splice(index, 1);
                          onChange(tmp);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </div>
                    ) : null}
                  </div>
                  <Line width={3} />
                  {showProduct.index === index && showProduct.show ? (
                    <ProductList
                      value={ele}
                      onChange={(val) => {
                        setShowProduct({ index: null, show: false });
                        let tmp = [...value];
                        tmp[index].image = val.image;
                        tmp[index].name = val.name;
                        tmp[index].product_id = val.id;
                        tmp[index].example = val.example;

                        console.log("tmp", tmp);
                        onChange(tmp);
                      }}
                      productType={productType}
                    />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                          padding: 10,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!is_detail)
                            setShowProduct({ index: index, show: true });
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flex: 1,
                            // padding: "5px 20px 5px 10px",
                          }}
                        >
                          <img
                            src={
                              ele && ele.image
                                ? helper.addhtpps(ele.image)
                                : require("../../assets/image/work/product.png")
                            }
                            height={40}
                            alt={"product"}
                          />
                        </div>
                        <div style={{ flex: 1, textAlignLast: "start" }}>
                          <div style={{ fontWeight: 500 }}>
                            {ele?.name ?? "ประเภทสินค้า*"}
                          </div>
                          <div style={{ fontSize: 13, opacity: 0.3 }}>
                            {ele?.example ?? "ระบุประเภทสินค้า"}
                          </div>
                        </div>
                      </div>
                      {checkData && ele.product_id === "" ? (
                        <div
                          style={{
                            color: "#ff316f",
                            position: "absolute",
                            fontSize: 12,
                          }}
                        >
                          กรุณากรอกข้อมูล
                        </div>
                      ) : null}
                      <Line width={3} opacity={1} />
                    </div>
                  )}
                  <div style={{ position: "relative" }}>
                    <InputNormal
                      value={ele.product_name}
                      onChange={(val) => {
                        let tmp = [...value];
                        tmp[index].product_name = val.target.value;
                        onChange(tmp);
                      }}
                      label={"ชื่อสินค้า*"}
                      error={checkData && ele.product_name === ""}
                      disable={is_detail}
                    />
                    {checkData && ele.product_name === "" ? (
                      <div
                        style={{
                          color: "#ff316f",
                          position: "absolute",
                          fontSize: 12,
                        }}
                      >
                        กรุณากรอกข้อมูล
                      </div>
                    ) : null}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div style={{ flex: 1, position: "relative" }}>
                      <div style={{ width: "90%" }}>
                        <InputNormal
                          type="number"
                          value={ele.product_width}
                          onChange={(val) => {
                            let tmp = [...value];
                            tmp[index].product_width = val.target.value;
                            if (
                              tmp[index].product_height !== "" &&
                              tmp[index].product_length !== "" &&
                              tmp[index].product_width !== ""
                            ) {
                              tmp[index].product_volume = (
                                parseFloat(tmp[index].product_height) *
                                parseFloat(tmp[index].product_length) *
                                parseFloat(tmp[index].product_width)
                              ).toFixed(2);
                            }
                            onChange(tmp);
                          }}
                          error={
                            checkData &&
                            ele.product_width === "" &&
                            workType === "stl"
                          }
                          label={"กว้าง*"}
                          subFirst={"(เมตร)"}
                          disable={is_detail}
                        />
                      </div>
                      {checkData &&
                      ele.product_width === "" &&
                      workType === "stl" ? (
                        <div
                          style={{
                            color: "#ff316f",
                            position: "absolute",
                            fontSize: 12,
                            bottom: -2,
                          }}
                        >
                          กรุณากรอกข้อมูล
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "-webkit-center",
                        position: "relative",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <InputNormal
                          type="number"
                          value={ele.product_length}
                          onChange={(val) => {
                            let tmp = [...value];
                            tmp[index].product_length = val.target.value;
                            if (
                              tmp[index].product_height !== "" &&
                              tmp[index].product_length !== "" &&
                              tmp[index].product_width !== ""
                            ) {
                              tmp[index].product_volume = (
                                parseFloat(tmp[index].product_height) *
                                parseFloat(tmp[index].product_length) *
                                parseFloat(tmp[index].product_width)
                              ).toFixed(2);
                            }
                            onChange(tmp);
                          }}
                          label={"ยาว*"}
                          error={
                            checkData &&
                            ele.product_length === "" &&
                            workType === "stl"
                          }
                          subFirst={"(เมตร)"}
                          disable={is_detail}
                        />
                      </div>
                      {checkData &&
                      ele.product_length === "" &&
                      workType === "stl" ? (
                        <div
                          style={{
                            color: "#ff316f",
                            position: "absolute",
                            fontSize: 12,
                            bottom: -2,
                          }}
                        >
                          กรุณากรอกข้อมูล
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "-webkit-right",
                        position: "relative",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <InputNormal
                          type="number"
                          value={ele.product_height}
                          onChange={(val) => {
                            let tmp = [...value];
                            tmp[index].product_height = val.target.value;
                            if (
                              tmp[index].product_height !== "" &&
                              tmp[index].product_length !== "" &&
                              tmp[index].product_width !== ""
                            ) {
                              tmp[index].product_volume = (
                                parseFloat(tmp[index].product_height) *
                                parseFloat(tmp[index].product_length) *
                                parseFloat(tmp[index].product_width)
                              ).toFixed(2);
                            }
                            onChange(tmp);
                          }}
                          label={"สูง*"}
                          subFirst={"(เมตร)"}
                          disable={is_detail}
                          error={
                            checkData &&
                            ele.product_height === "" &&
                            workType === "stl"
                          }
                        />
                      </div>
                      {checkData &&
                      ele.product_height === "" &&
                      workType === "stl" ? (
                        <div
                          style={{
                            color: "#ff316f",
                            position: "absolute",
                            fontSize: 12,
                            bottom: -2,
                          }}
                        >
                          กรุณากรอกข้อมูล
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      position: "relative",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <div style={{ width: "95%" }}>
                        <InputDisable
                          type="number"
                          value={ele.product_volume}
                          error={
                            checkData &&
                            ele.product_volume === "" &&
                            workType === "stl"
                          }
                          label={"ปริมาตรสินค้า/หน่วย*"}
                          subFirst={"(ลูกบาตรเมตร)"}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: "-webkit-right",
                        position: "relative",
                      }}
                    >
                      <div style={{ width: "95%" }}>
                        <InputNormal
                          type="number"
                          value={ele.product_weight}
                          onChange={(val) => {
                            let tmp = [...value];
                            tmp[index].product_weight = val.target.value;
                            onChange(tmp);
                          }}
                          label={"น้ำหนักสินค้า/หน่วย*"}
                          error={checkData && ele.product_weight === ""}
                          subFirst={"(กิโลกรัม)"}
                          disable={is_detail}
                        />
                      </div>
                      {checkData && ele.product_weight === "" ? (
                        <div
                          style={{
                            color: "#ff316f",
                            position: "absolute",
                            fontSize: 12,
                            bottom: -2,
                          }}
                        >
                          กรุณากรอกข้อมูล
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ flex: 1, position: "relative" }}>
                      <div style={{ width: "95%" }}>
                        <InputNormal
                          type="number"
                          value={ele.product_amount}
                          onChange={(val) => {
                            let tmp = [...value];
                            tmp[index].product_amount = val.target.value;
                            onChange(tmp);
                          }}
                          label={"จำนวนสินค้า*"}
                          error={
                            checkData &&
                            ele.product_amount === "" &&
                            workType === "stl"
                          }
                          // subFirst={"(กิโลกรัม)"}
                          disable={is_detail}
                        />
                      </div>
                      {checkData &&
                      ele.product_height === "" &&
                      workType === "stl" ? (
                        <div
                          style={{
                            color: "#ff316f",
                            position: "absolute",
                            fontSize: 12,
                            bottom: -2,
                          }}
                        >
                          กรุณากรอกข้อมูล
                        </div>
                      ) : null}
                    </div>
                    <div style={{ flex: 1, textAlign: "-webkit-right" }}>
                      <div style={{ width: "95%" }}>
                        <InputSelect
                          value={ele.product_count_type}
                          onChange={(val) => {
                            // console.log("select", val);
                            let tmp = [...value];
                            tmp[index].product_count_type = val.target.value;
                            onChange(tmp);
                          }}
                          options={[
                            { value: 1, item: "ชิ้น" },
                            { value: 2, item: "พาเลท" },
                          ]}
                          label={"ประเภทการนับสินค้า*"}
                          subFirst={"(ค่าเริ่มต้น ชิ้น)"}
                          disable={is_detail}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ position: "relative", marginTop: 10 }}>
                    <InputImageShow
                      value={ele.product_image ?? undefined}
                      onChange={(val) => {
                        // console.log(val);
                        let tmp = [...value];
                        tmp[index].product_image = val;
                        // tmp[index].product_image_
                        // console.log("tmp", tmp);
                        onChange(tmp);
                        // console.log(value.product_image);
                      }}
                      id={index}
                      disable={is_detail}
                    />
                    {checkData &&
                    (!ele.product_image || ele.product_image?.length < 0) ? (
                      <div
                        style={{
                          color: "#ff316f",
                          position: "absolute",
                          fontSize: 12,
                          // bottom: -2,
                        }}
                      >
                        กรุณากรอกข้อมูล
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
            {is_detail ? null : (
              <div
                style={{
                  color: "#8ca3ba",
                  borderRadius: 10,
                  borderStyle: "dashed",
                  borderWidth: 1,
                  padding: 10,
                  textAlign: "center",

                  cursor: "pointer",
                  display: "flex",
                }}
                onClick={() => {
                  let tmp = [...value];
                  tmp.push({
                    name: "",
                    product_id: "",
                    product_name: "",
                    product_size: "",
                    product_width: "",
                    product_length: "",
                    product_height: "",
                    product_volume: "",
                    product_weight: "",
                    product_count_type: "",
                    product_amount: "",
                    product_image: [null, null, null, null, null],
                    // product_image_per_route: [],
                  });
                  onChange(tmp);
                }}
              >
                <div
                  style={{
                    flex: 1,
                    paddingLeft: 10,
                    alignSelf: "center",
                    placeContent: "center",
                    display: "flex",
                  }}
                >
                  <AddCircleIcon />
                  <div>เพิ่มประเภทสินค้า</div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: 20,
              borderRadius: 20,
              textAlign: "center",
            }}
          >
            {is_detail ? (
              <div>
                {workType === "stl"
                  ? `Size ${
                      value[0]?.product_size === 1
                        ? "S "
                        : value[0]?.product_size === 2
                        ? "M "
                        : "L "
                    }` ?? `Size -`
                  : ""}
                น้ำหนักรวม {getWeight()} ก.ก. ปริมาตรรวม {getVolume()} ลบ.ม.
              </div>
            ) : (
              <div>
                {workType === "stl" ? size?.name ?? `Size -` : ""} น้ำหนักรวม{" "}
                {getWeight()} ก.ก. ปริมาตรรวม {getVolume()} ลบ.ม.
              </div>
            )}
            {is_detail ? (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      confirm();
                    }}
                  >
                    ปิด
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      setCheckData(false);
                      setSize();
                      close();
                    }}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      if (!confirmCheck()) {
                        if (workType === "stl" && !size) {
                          Swal.fire({
                            title: "ขออภัย",
                            text: "ขนาดสินค้าของท่านใหญ่กว่าขนาดที่รับฝากส่ง ท่านสามารถเปลี่ยนไปใช้บริการแบบเหมาคัน",
                            confirmButtonText: "ตกลง",
                            icon: "warning",
                          });
                        } else {
                          confirm();
                        }
                      }
                    }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSelectProduct;
