// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateTruckTypeModal_field__-5ZSf {
  margin: 3px 0px;
  width: 100%;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/masterData/truckType/CreateTruckTypeModal/CreateTruckTypeModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".field {\n  margin: 3px 0px;\n  width: 100%;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `CreateTruckTypeModal_field__-5ZSf`
};
export default ___CSS_LOADER_EXPORT___;
