import React, { useEffect, useState } from "react";
import SubMenu from "components/layout/SubMenu.js";
import * as R from "ramda";
import { useNavigate, useParams } from "react-router-dom";
import { subMenuMasterData } from "utils/submenuoptions";
import "./template.css";
import {
  Avatar,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import { Search } from "@mui/icons-material";
import helper from "utils/helper";
import TableCustomThirdParty from "components/table/ThirdPartyTable/TableCustomThirdParty";
import { Check, Clear } from "@mui/icons-material";
import FloatingButton from "components/button/FloatingButton";
import ModalWork from "components/work/modalWork";
import Line from "components/layout/line";
import { useForm, Controller } from "react-hook-form";
import InputNormal from "components/input/inputNormal";
import API from "utils/api/work/work";
import Swal from "sweetalert2";
import TableActiveIcon from "components/tableActiveIcon";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import ImportTemplate from "./importTemplate/ImportTemplate";
import { downloadTemplateWork } from "utils/api/templateWork/templateWork";
import AlertModal from "components/modal/AlertModal";
import InputSelect from "components/input/inputSelect";
import InputDisable from "components/input/inputDisable";
import InputUpload from "components/input/inputUpload";
const api = API.create();

export default function TemplateDetail(props) {
  const navigate = useNavigate();

  const {
    user_id,
    project_id,
    user_code,
    project_code,
    profile_id,
    fuel_rate_id,
  } = useParams();
  const MenuProject = [
    {
      label: "รายละเอียดแม่แบบงาน",
      path: ``,
      active: true,
    },
  ];
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [filter, setFilter] = useState({
    order_by: "created_at",
    order_by_value: 1,
    profile_id: profile_id,
    project_id: project_id,
  });
  const [filterPerPage, setFilterPerPage] = useState([
    { value: "5", name: "5" },
  ]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 5,
    page: 1,
  });
  const [data, setData] = useState();
  const [refresh, setRefresh] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm({});
  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  const renderSubMenu = () => {
    return <SubMenu menus={MenuProject} breadcrumbs={breadcrumbs} />;
  };
  const getData = async () => {
    await api
      .getTemplateById({ project_fuel_rate_id: fuel_rate_id })
      .then((response) => {
        console.log("response", response);
        let d = response.data.results;
        setData(response.data.results);
        setValue("active", response.data.results.active ? "1" : "0");
        setValue("fuel_rate_start_shipper", d.fuel_rate_start_shipper);
        setValue("fuel_rate_end_shipper", d.fuel_rate_end_shipper);
        setValue("fuel_rate_start_carrier", d.fuel_rate_start_carrier);
        setValue("fuel_rate_end_carrier", d.fuel_rate_end_carrier);
        setValue("template_work_file", helper.addhtpps(d.template_work_file));

        setBreadcrumbs([
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: "/user",
          },
          {
            label: `${user_code}`,
            path: "",
          },
          {
            label: "ข้อมูลโครงการ",
            path: "",
          },
          {
            label: `${project_code}`,
            path: "",
          },
          {
            label: "แม่แบบงาน",
            path: "",
          },
          {
            label:
              response.data.results.fuel_rate_start_shipper +
              " - " +
              response.data.results.fuel_rate_end_shipper,
            path: "",
          },
          {
            label: "รายละเอียดแม่แบบงาน",
            path: "",
          },
        ]);
      })
      .catch((error) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };
  const editTemplate = async (detail) => {
    let formData = new FormData();
    formData.append("active", detail.active === "1");
    formData.append("project_fuel_rate_id", data.id);
    await api
      .editTemplate(formData)
      .then((response) => {
        Swal.fire({
          // position: 'top-end',
          icon: "success",
          title: "บันทึก Template เสร็จสิ้น",
          // text: "เลขที่งาน " + response.data.results,
          showConfirmButton: false,
          timer: 1500,
          willClose: () => {},
        });
      })
      .catch((e) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + e.response.data.code,
          text:
            e.response.data.description?.message ?? e.response.data.description,
          icon: "error",
          showConfirmButton: false,
        });
        console.log(e);
      });
  };
  return (
    <div className="third-party-body">
      {renderSubMenu()}
      {renderModal()}
      <div className="post-work-body-flex">
        <div className="post-work-box">
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: 20, fontWeight: 500 }}>
              {data
                ? data.fuel_rate_start_shipper +
                  " - " +
                  data.fuel_rate_end_shipper
                : "-"}
            </div>
            <div style={{ fontStyle: "italic", fontSize: 14, opacity: 0.4 }}>
              {"วันที่สร้างรายการ " +
                helper.momentDateTime(data?.created_at, "short", false)}
            </div>
            <div style={{ fontStyle: "italic", fontSize: 14, opacity: 0.4 }}>
              {"วันที่แก้ไขข้อมูลล่าสุด " +
                helper.momentDateTime(data?.updated_at, "short", false)}
            </div>
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 16, marginTop: 10 }}>การใช้งาน</div>
              <Line width={2} />
            </div>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  value={value}
                  label={"สถานะการใช้งาน"}
                  options={[
                    { value: "1", item: "ใช้งาน" },
                    { value: "0", item: "ไม่ใช้งาน" },
                  ]}
                  error={errors.active}
                  onChange={onChange}
                  subSec={"(ค่าเริ่มต้น ใช้งาน)"}
                />
              )}
              name="active"
              defaultValue=""
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <div style={{ textAlign: "center" }}>สำหรับลูกค้า</div>
            <Line width={2} />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันเริ่มต้น*"
                  onChange={onChange}
                  value={value}
                  error={errors.fuel_rate_start_shipper}
                  type="number"
                  disable
                />
              )}
              name="fuel_rate_start_shipper"
              defaultValue=""
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันสิ้นสุด*"
                  onChange={onChange}
                  value={value}
                  error={errors.fuel_rate_end_shipper}
                  type="number"
                  disable
                />
              )}
              name="fuel_rate_end_shipper"
              defaultValue=""
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputUpload
                  id={"import-work" + Math.random(10)}
                  value={value}
                  label={"ไฟล์แม่แบบงาน*"}
                  onChange={onChange}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  error={errors.template_work_file}
                  download={(val) => {
                    helper.downloadAs(
                      val,
                      "ไฟล์แม่แบบงานโปรเจ็ค " +
                        data.project.project_code +
                        " " +
                        data.fuel_rate_start_shipper +
                        " - " +
                        data.fuel_rate_end_shipper +
                        ".xlsx"
                    );
                  }}
                  readOnly
                />
              )}
              name="template_work_file"
              defaultValue=""
            />
            <div style={{ textAlign: "center", marginTop: 20 }}>
              สำหรับผู้ขนส่ง
            </div>
            <Line width={2} />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันเริ่มต้น*"
                  onChange={onChange}
                  value={value}
                  error={errors.fuel_rate_start_carrier}
                  type="number"
                  disable
                />
              )}
              name="fuel_rate_start_carrier"
              defaultValue=""
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="อัตราน้ำมันสิ้นสุด*"
                  onChange={onChange}
                  value={value}
                  error={errors.fuel_rate_end_carrier}
                  type="number"
                  disable
                />
              )}
              name="fuel_rate_end_carrier"
              defaultValue=""
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              color="secondary"
              onClick={handleSubmit(editTemplate)}
              // disabled={!file || uploading}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
