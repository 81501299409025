import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Pagination from "components/pagination/Pagination";
import helper from "utils/helper";
import "./AntdTable.css";

const DragTable = ({
  columns = [],
  dataSource = [],
  loading = false,
  pagination = {
    page: 1,
    per_page: 10,
    total: 100,
  },
  setPagination = () => {},
  onDragChange = () => {},
}) => {
  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);

  useEffect(() => {
    const _dataSource = dataSource.map((data, index) => {
      if (!R.isNil(data.id)) {
        return { key: data.id, ...data };
      }

      return { key: index, ...data };
    });

    setTableData(_dataSource);
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    const _columns = [
      {
        key: "sort",
        title: "ลำดับ",
        align: "center",
      },
      ...columns,
    ];

    setTableColumn(_columns);
    // eslint-disable-next-line
  }, [columns]);

  const tableLoading = {
    spinning: loading,
    indicator: <CircularProgress />,
  };

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "grab", fontSize: 20 }}
                  {...listeners}
                />
              ),
            });
          }

          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setTableData((previous) => {
        const active_index = previous.findIndex((i) => i.key === active.id);
        const over_index = previous.findIndex((i) => i.key === over?.id);
        const new_array_data = arrayMove(previous, active_index, over_index);
        onDragChange(new_array_data);

        return new_array_data;
      });
    }
  };

  return (
    <div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={tableData.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            // rowKey="key"
            columns={tableColumn}
            dataSource={tableData}
            pagination={false}
            rowClassName={() => "antdRow"}
            loading={tableLoading}
            // scroll={{ y: 450 }}
          />
        </SortableContext>
      </DndContext>

      {!helper.IsEmptyFunction(setPagination) && (
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
        />
      )}
    </div>
  );
};

export default DragTable;
