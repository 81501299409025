import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import styles from "./admin.module.css";
import { UploadFile } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

//component in project
import AlertModal from "components/modal/AlertModal";
import FieldUpload from "components/field/FieldUpload";
import FieldSelect from "components/field/FieldSelect";
import FieldPassword from "components/field/FieldPassword";
import ButtonCustom from "components/ButtonCustom";
import Loading from "components/loading/Loading";
import helper from "utils/helper";
import { getMenuPermission, createAdmin } from "utils/api/admin/admin";
import FieldInput from "components/field/FieldInput";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ModalCreateAdmin = (props) => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState(null);
  const [alertModal, setAlertModal] = useState({
    open: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [menuPermission, setMenuPermission] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      image: "",
      username: "",
      admin_role_type: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      adminPermission: "",
      signature_image: "",
      active: true,
    },
  });

  useEffect(() => {
    getMenuPermissionMS();
  }, []);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const getMenuPermissionMS = async () => {
    const param = {
      sort_field: "id",
      sort_order: 1,
      page: 1,
      per_page: 15,
    };
    // const convertParam = helper.ToConvertParams(param);
    const response = await getMenuPermission(param);
    console.log("responseresponse", response);
    let results = response.data.data.results;
    setItems(
      ...items,
      results?.map((res) => ({
        menuPermissionId: res.id,
        name: res.name,
        visit: false,
        create: false,
        deleteP: false,
        edit: false,
        print: false,
        exportP: false,
        all: false,
      }))
    );
  };

  useEffect(() => {
    console.log(items);
  }, [items]);

  const handleForm = (key, val) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleMaxDigit = (event, field, max) => {
    const newValue = event.target.value;
    // Limit input to a maximum of 10 characters
    if (newValue.length <= max) {
      setValue(field, newValue);
    }
    if (newValue.length > 0) {
      clearErrors(field);
    }
  };

  const handleCheckboxClick = (itemId, property) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.menuPermissionId === itemId) {
          const updatedItem = {
            ...item,
            [property]: !item[property],
          };

          if (property === "all") {
            const allChecked = updatedItem.all;
            updatedItem.visit = allChecked;
            updatedItem.create = allChecked;
            updatedItem.deleteP = allChecked;
            updatedItem.edit = allChecked;
            updatedItem.print = allChecked;
            updatedItem.exportP = allChecked;
          } else {
            updatedItem.all =
              updatedItem.visit &&
              updatedItem.create &&
              updatedItem.deleteP &&
              updatedItem.edit &&
              updatedItem.print &&
              updatedItem.exportP;
          }

          return updatedItem;
        } else {
          return item;
        }
      })
    );
  };

  useEffect(() => {
    // Modify the items array
    const modifiedItems = items?.map(
      ({ deleteP, exportP, name, all, ...item }) => ({
        delete: deleteP,
        export: exportP,
        ...item,
      })
    );

    // Filter out items where all properties are false
    const filteredItems = modifiedItems.filter((item) => {
      return Object.keys(item).some(
        (key) => key !== "menuPermissionId" && item[key]
      );
    });

    if (filteredItems) {
      setValue("adminPermission", filteredItems);
      clearErrors("adminPermission");
    }
  }, [items]);

  const onSubmit = async (data) => {
    setLoading(true);
    if (data?.adminPermission.length === 0) {
      setError("adminPermission", { message: "กรุณาเลือกสิทธิ์การใช้งาน" });
    }
    console.log("dataaaaaaa", data);
    const GForm = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "adminPermission") {
        GForm.append(key, JSON.stringify(data[key]));
      } else {
        GForm.append(key, data[key]);
      }
    });
    GForm.delete("confirmPassword");
    console.log("GForm", GForm.adminPermission);

    await createAdmin(GForm)
      .then((response) => {
        console.log("response", response);
        if (response.data.code === 1000) {
          props.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
          if (error.response.data.description === "Username ถูกใช้แล้ว") {
            setError("username", {
              type: 400,
              message: error.response.data.description,
            });
          }
        }
      });
  };
  return (
    <div>
      {loading && <Loading />}
      {/* <AlertModal
        open={alertModal}
        title="ข้อมูลไม่ถูกต้อง"
        description={alertModal.message}
        onClose={() => {
          setAlertModal({ open: false, message: "" });
        }}
      /> */}
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modalStyle}>
          <form
            style={{ display: "flex", width: "100%" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box className={styles.leftBox}>
              <Controller
                name="image"
                defaultValue=""
                control={control}
                rules={{
                  required: "กรุณาอัพโหลดรูปภาพ",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldUpload
                    name={"รูปโปรไฟล์"}
                    icon={<UploadFile />}
                    onChange={onChange}
                    accept="image/jpeg,image/png"
                    style={{
                      width: "350px",
                      paddingBottom: errors.image ? 0 : "25px",
                    }}
                    value={value}
                    error={!!errors.image} // Convert error object to boolean
                    textError={errors.image ? errors.image.message : false}
                  />
                )}
              />
              <Controller
                name="username"
                control={control}
                rules={{
                  required: "กรุณากรอกชื่อผู้ใช้งาน",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldInput
                    id="username"
                    label="ชื่อผู้ใช้งาน*"
                    variant="standard"
                    onChange={onChange}
                    value={value}
                    error={!!errors.username} // Convert error object to boolean
                    helperText={errors.username ? errors.username.message : ""}
                    style={{ width: "-webkit-fill-available" }}
                  />
                )}
              />
              <Controller
                name="admin_role_type"
                control={control}
                rules={{
                  required: "กรุณาเลือกบทบาทผู้ดูแลระบบ",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldSelect
                    name={"บทบาทผู้ดูแลระบบ*"}
                    options={[
                      { value: 0, name: "SuperAdmin" },
                      { value: 1, name: "ฝ่ายขาย" },
                      { value: 2, name: "ฝ่ายบริการลูกค้า" },
                      { value: 3, name: "ฝ่ายปฏิบัติการ" },
                      { value: 4, name: "ฝ่ายการเงินและบัญชี" },
                      { value: 5, name: "ฝ่ายเทคโนโลยีสารสนเทศ" },
                    ]}
                    value={value}
                    onChange={onChange}
                    style={{ width: "-webkit-fill-available" }}
                    error={!!errors.admin_role_type} // Convert error object to boolean
                    errorText={
                      errors.admin_role_type
                        ? errors.admin_role_type.message
                        : ""
                    }
                  />
                )}
              />
              <Controller
                name="firstname"
                control={control}
                rules={{
                  required: "กรุณากรอกชื่อ",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldInput
                    id="firstname"
                    label="ชื่อ*"
                    onChange={onChange}
                    value={value}
                    error={!!errors.firstname}
                    helperText={
                      errors.firstname ? errors.firstname.message : ""
                    }
                    style={{ width: "-webkit-fill-available" }}
                  />
                )}
              />
              <Controller
                name="lastname"
                control={control}
                rules={{
                  required: "กรุณากรอกนามสกุล",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldInput
                    id="lastname"
                    label="นามสกุล*"
                    value={value}
                    onChange={onChange}
                    error={!!errors.lastname} // Convert error object to boolean
                    helperText={errors.lastname ? errors.lastname.message : ""}
                    style={{ width: "-webkit-fill-available" }}
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "กรุณากรอกเบอร์มือถือ",
                  pattern: {
                    value: /^(?:\+66|0)[689]\d{8}$/,
                    message: "กรุณากรอกเบอร์มือถือ",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldInput
                    id="phone"
                    label="เบอร์มือถือ*"
                    value={value}
                    onChange={(e) => {
                      handleMaxDigit(e, "phone", 10);
                    }}
                    error={!!errors.phone} // Convert error object to boolean
                    helperText={errors.phone ? errors.phone.message : false}
                    style={{ width: "-webkit-fill-available" }}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "กรุณากรอกอีเมล",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "กรุณากรอกอีเมลให้ถูกต้อง",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldInput
                    id="email"
                    label="อีเมล*"
                    value={value}
                    onChange={onChange}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    style={{ width: "-webkit-fill-available" }}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  minLength: {
                    value: 6,
                    message:
                      "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
                    message:
                      "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldPassword
                    id="password"
                    name="รหัสผ่าน"
                    value={value}
                    onChange={onChange}
                    error={!!errors.password}
                    textError={errors.password ? errors.password.message : ""}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  minLength: {
                    value: 6,
                    message:
                      "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                  },
                  pattern: {
                    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$%^&+=!_\-]+$/,
                    message:
                      "รหัสผ่านต้องประกอบด้วยภาษาอังกฤษและตัวเลขอย่างน้อย 6 ตัว",
                  },
                  validate: (value) =>
                    value === getValues().password || "รหัสผ่านไม่ตรงกัน",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldPassword
                    name="ยืนยันรหัสผ่าน"
                    value={value}
                    onChange={onChange}
                    error={!!errors.confirmPassword}
                    textError={
                      errors.confirmPassword
                        ? errors.confirmPassword.message
                        : ""
                    }
                  />
                )}
              />

              <Controller
                name="signature_image"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FieldUpload
                    name={"ลายเซ็น"}
                    accept="image/jpeg,image/png"
                    icon={<UploadFile />}
                    value={value}
                    onChange={onChange}
                    style={{
                      width: "-webkit-fill-available",
                    }}
                  />
                )}
              />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ButtonCustom
                  type={"cancel"}
                  onClick={props?.handleClose}
                  name="ยกเลิก"
                />
                <ButtonCustom type="submit" name="บันทึก" />
              </div>
            </Box>
            <Box className={styles.rightBox}>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {errors?.adminPermission ? (
                  <Typography color={"error.main"} fontSize={"12px"}>
                    {errors?.adminPermission.message}
                  </Typography>
                ) : (
                  ""
                )}
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none",
                      },
                    }}
                    aria-label="simple table"
                  >
                    <TableHead sx={{ bgcolor: "primary.main", height: "50px" }}>
                      <TableRow>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          สิทธิ์การใช้งาน
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          ทั้งหมด
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          เข้าชม
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          เพิ่ม
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          ลบ
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          แก้ไข
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          พิมพ์
                        </TableCell>
                        <TableCell
                          className={styles.headPermission}
                          align="center"
                        >
                          ส่งออก
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items &&
                        items?.map((item) => (
                          <TableRow key={item.menuPermissionId}>
                            <TableCell
                              className={styles.listPermission}
                              component="th"
                              scope="row"
                              sx={{ padding: 0 }}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                {...label}
                                name="all"
                                checked={item.all}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "all"
                                  )
                                }
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                {...label}
                                name="visit"
                                checked={item.visit}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "visit"
                                  )
                                }
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                name="create"
                                checked={item.create}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "create"
                                  )
                                }
                                {...label}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                name="delete"
                                checked={item.deleteP}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "deleteP"
                                  )
                                }
                                {...label}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                name="edit"
                                checked={item.edit}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "edit"
                                  )
                                }
                                {...label}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                name="print"
                                checked={item.print}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "print"
                                  )
                                }
                                {...label}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }} align="right">
                              <Checkbox
                                name="export"
                                checked={item.exportP}
                                onClick={() =>
                                  handleCheckboxClick(
                                    item.menuPermissionId,
                                    "exportP"
                                  )
                                }
                                {...label}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 28 },
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Box>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default ModalCreateAdmin;
