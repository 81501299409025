import instance from "utils/axiosInstance";
import helper from "utils/helper";
export const sendNoti = async (params) => {
  try {
    let param = "?";
    // param += helper.getParam(work_id);
    param += "&" + helper.getParam(params);
    return instance.post(`/api/utils/send-notification` + param);
  } catch (error) {
    console.log("error", error);
  }
};
