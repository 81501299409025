import React, { useState } from "react";
import { Transition } from "react-transition-group";

const SlideTransition = ({
  show = true,
  direction = `left`,
  duration = 400,
  children,
}) => {
  // const [inProp, setInProp] = useState(true); // State to control the transition

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateY(-100%)", // Initial position off-screen
  };

  let transitionStyles = {
    entering: { transform: "translateX(-100%)" },
    entered: { transform: "translateX(-100%)" },
    exiting: { transform: "translateX(0%)" },
    exited: { transform: "translateX(0%)" },
  };
  if (direction === `right`) {
    transitionStyles = {
      entering: { transform: "translateX(0%)" }, // Slide in from the right
      entered: { transform: "translateX(0%)" },
      exiting: { transform: "translateX(100%)" }, // Slide out to the right
      exited: { transform: "translateX(100%)" },
    };
  }

  return (
    <Transition in={show} timeout={duration}>
      {(state) => {
        return (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className={`fade-${state}`}
          >
            {children}
          </div>
        );
      }}
    </Transition>
  );

  //   return (
  //     <div>
  //       <button style={{ marginBottom: 100 }} onClick={() => setInProp(!inProp)}>
  //         Toggle Transition
  //       </button>
  //       <Transition in={inProp} timeout={duration}>
  //         {(state) => {
  //           return (
  //             <div
  //               style={{
  //                 ...defaultStyle,
  //                 ...transitionStyles[state],
  //               }}
  //               className={`fade-${state}`}
  //             >
  //               {children}
  //             </div>
  //           );
  //         }}
  //       </Transition>
  //     </div>
  //   );
};

export default SlideTransition;
