import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loading from "components/loading/LoadingBackground";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import {
  Drawer,
  Typography,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { Search, FilterAlt } from "@mui/icons-material";
import helper from "utils/helper";
import { useCookies } from "react-cookie";

import FieldSelect from "components/field/FieldSelect";
import TableActiveIcon from "components/tableActiveIcon";
import ButtonCustom from "components/ButtonCustom";
import SubMenu from "components/layout/SubMenu.js";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import styles from "./TableSubUserInProfile.module.css";
import TableCustom from "components/table/TableCustom";
import { getAllSubUserInProfile } from "utils/api/profile/profile";
import UserDetailSubMenu from "components/layout/SubMenu.js/UserDetailSubMenu";
import SelectRowValue from "components/button/SelectRowValue";
const TableSubUserInProfile = () => {
  const { user_id, profile_id, user_code } = useParams();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [mainInfo, setMainInfo] = useState({});
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [filter, setFilter] = useState({
    sort_by: "updated_at",
    search_by: "user_name",
    search_val: "",
    start_date: dateFrom,
    end_date: dateTo,
    page: 1,
    per_page: 5,
    total: 0,
  });

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลผู้ใช้รองในโปรไฟล์",
      path: `/user/profile/sub-user/${profile_id}`,
    },
    // {
    //   label: `${dataDetail?.user_code}`,
    //   path: "",
    // },
  ];

  const columns = [
    {
      id: "created_at",
      label: "วันที่สร้างรายการ",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.created_at),
    },
    {
      id: "updated_at",
      label: "วันที่แก้ไขข้อมูลล่าสุด",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.updated_at),
    },
    {
      id: "user_code",
      label: "รหัสผู้ใช้งาน",
      align: "center",
      format: (value) => value.user_code,
    },
    {
      label: "ชื่อผู้ใช้งาน",
      align: "left",
      format: (value) => `${value.firstname} ${value.lastname}`,
    },
    {
      id: "role",
      label: "บทบาทที่ได้รับในโปรไฟล์นี้",
      align: "center",
      format: (value) =>
        value.roles === "shipper"
          ? "ลูกค้า"
          : value.roles === "carrier"
          ? "ผู้ขนส่ง"
          : value.roles === "driver"
          ? "พนักงานขับรถ"
          : "",
    },
    // {
    //   id: "is_main",
    //   label: "บทบาทที่ใช้งาน",
    //   align: "center",
    //   format: (value) => (
    //     <div>
    //       {value.user_profile.user_profile_shipper.is_shipper === true
    //         ? "SH  "
    //         : ""}
    //       {value.user_profile.user_profile_carrier.is_carrier === true
    //         ? "CR  "
    //         : ""}
    //       {value.user_profile.user_profile_driver.is_driver === true
    //         ? "DR  "
    //         : ""}
    //     </div>
    //   ),
    // },
    {
      id: "active",
      label: "สถานะใช้งาน",
      align: "center",
      width: "10%",
      format: (value) => {
        return <TableActiveIcon active_status={value.active} />;
      },
    },
  ];

  const filterGeneral = [
    {
      name: "ประเภทโปรไฟล์",
      value: "profile_type",
      options: [
        { value: 0, name: "บุคคลธรรมดา" },
        { value: 1, name: "นิติบุคคล" },
      ],
    },
    {
      name: "บทบาทผู้ใช้งาน",
      value: "role",
      options: [
        { value: "shipper", name: "ลูกค้า" },
        { value: "carrier", name: "ผู้ขนส่ง" },
        { value: "driver", name: "พนักงานขับรถ" },
      ],
    },
    {
      name: "สถานะใช้งาน",
      value: "active",
      options: [
        { value: true, name: "ใช้งาน" },
        { value: false, name: "ระงับ" },
      ],
    },
  ];

  useEffect(() => {
    getAllSubUser(filter);
  }, [filter.page, filter.per_page]);

  const getAllSubUser = async (_filter) => {
    setLoading(true);
    if (profile_id) {
      await getAllSubUserInProfile(profile_id, _filter)
        .then((response) => {
          if (response?.data.code === 1000) {
            const rest = response?.data.data;
            const main_info = [];
            const sub_info = [];
            rest.forEach((val) => {
              if (val.user.is_main === true) {
                main_info.push(val);
              } else {
                if (val.user_profile_shipper.is_shipper === true) {
                  val.user.roles = "shipper";
                } else if (val.user_profile_carrier.is_carrier === true) {
                  val.user.roles = "carrier";
                } else if (val.user_profile_driver.is_driver === true) {
                  val.user.roles = "driver";
                }
                sub_info.push(val);
              }

              delete val.user_profile_shipper;
              delete val.user_profile_carrier;
              delete val.user_profile_driver;
            });
            const customData = sub_info?.map((item) => item.user);
            setDataDetail(customData);
            setFilter({ ...filter, total: response.data.total });
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          if (error?.response.data.code === 4106) {
            setErrorAlert({
              open: true,
              desc: error?.response.data.description,
            });
          } else if (error?.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        });
    }
  };

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "company_name", name: "ชื่อบริษัท" },
                { value: "user_name", name: "ชื่อผู้ใช้งาน" },
                { value: "phone", name: "เบอร์" },
                { value: "user_code", name: "รหัสผู้ใช้งาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "13px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "20px",
              gap: "20px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterSub = { ...filter, page: 1 };
                getAllSubUser(filterSub);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
            />
            {/* <FieldSelect
              name={"จำนวนแถว"}
              options={[
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 20, name: "20" },
                { value: 50, name: "50" },
                { value: 100, name: "100" },
              ]}
              defaultValue={5}
              value={filter.per_page}
              onChange={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
              closePaddingBottom={true}
            /> */}
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <Drawer
        anchor="right"
        open={advanceFilter}
        onClose={() => {
          setAdvanceFilter(false);
          const filterSub = { ...filter, page: 1 };
          getAllSubUser(filterSub);
        }}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
            />
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
            <div className="general">
              <Typography
                fontSize={"20px"}
                fontWeight={500}
                sx={{ padding: "20px 0" }}
              >
                ทั่วไป
              </Typography>
              {filterGeneral &&
                filterGeneral.map((value) => {
                  return (
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filter[value?.value]}
                      defaultValue={[]}
                      onChange={(val) => {
                        setFilter({ ...filter, [value?.value]: val });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      multiSelect={true}
                      showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  );
                })}
            </div>
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() =>
                  setFilter({
                    sort_by: "updated_at",
                    search_by: "user_name",
                    search_val: "",
                    start_date: dateFrom,
                    end_date: dateTo,
                    page: 1,
                    per_page: 5,
                    total: 0,
                    profile_type: [],
                    role: [],
                    active: [],
                  })
                }
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Container>
        <UserDetailSubMenu
          label={`ข้อมูลผู้ใช้รองในโปรไฟล์`}
          pathbackward={-1}
          profile_id={profile_id}
          user_id={user_id}
          user_code={user_code}
          breadcrumbs={breadcrumbs}
        />
        {/* <SubMenu menus={MenuUser} breadcrumbs={breadcrumbs} /> */}
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={dataDetail}
            pagination={filter}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
            onSelectedRow={(val) => console.log(val)}
            href={`/user/${user_id}/${user_code}/profile/${profile_id}/sub-user/detail/`}
            // checkBox={true}
          />
        </div>
      </Container>
    </React.Fragment>
  );
};

export default TableSubUserInProfile;
