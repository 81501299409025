import React, { useEffect, useState } from "react";
import helper from "utils/helper";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalWork from "./modalWork";
import Line from "components/layout/line";
import API from "../../utils/api/work/work";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldInput from "components/field/FieldInput";
import FieldUpload from "components/field/FieldUpload";
import ButtonCustom from "components/ButtonCustom";
import instance from "utils/axiosInstance";
import { getAdminById } from "utils/api/admin/admin";
import { getUserById } from "utils/api/user/user";
import { Button } from "@mui/material";
const api = API.create();
const PaymentStatus = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showModal, setShowModal] = useState(false);
  const [statusDetail, setStatusDetail] = useState(null);
  const [createableName, setCreateableName] = useState("");
  console.log("createableName", createableName);

  const showStatus = async (status_id, status, type) => {
    console.log("showStatus", status_id, status);
    const doc_code =
      status_id === 3
        ? status?.workPaymentCarrierDetail
          ? status?.workPaymentCarrierDetail.invoice_code
          : status?.workPaymentShipperDetail.invoice_code
        : status_id === 5
        ? status?.workPaymentCarrierDetail
          ? status?.workPaymentCarrierDetail.bill_code
          : status?.workPaymentShipperDetail.bill_code
        : status_id === 7
        ? status?.workPaymentCarrierDetail
          ? status?.workPaymentCarrierDetail.pay_in_code
          : status?.workPaymentShipperDetail.receipt_code
        : "";
    setShowModal(true);
    getCreateableName(status?.createable_by_id, status?.createable_by_type);
    if (status_id === 3 || status_id === 5 || status_id === 7) {
      await api
        .getDetailPayment(status_id, doc_code, type)
        .then(async (res) => {
          console.log(res);
          if (
            res.data.data.result.status === "แจ้งหนี้แล้ว" ||
            res.data.data.result.status === "วางบิลแล้ว"
          ) {
            if (res?.data.data.result.file) {
              const response = await instance.get(res?.data.data.result.file, {
                responseType: "blob",
              });
              if (response) {
                console.log("have response");
                const href = URL.createObjectURL(response.data);
                setStatusDetail({
                  ...res.data.data.result,
                  file: href,
                  type: type,
                  status_id: status_id,
                });
                // window.open(href, "_blank");
              }
            }
          } else {
            setStatusDetail({
              ...res.data.data.result,
              type: type,
              status_id: status_id,
            });
          }
        })
        .catch((error) => console.log(error));
    } else {
      setStatusDetail({
        ...status,
        type: type,
        status_id: status_id,
        status: status?.paymentShipperStatus
          ? status?.paymentShipperStatus?.name
          : status?.paymentCarrierStatus?.name,
      });
    }
  };

  const getCreateableName = async (id, type) => {
    console.log("getCreateableName", id, type);
    if (type === 1) {
      await getAdminById(id)
        .then((res) => {
          if (res.data.code === 1000) {
            setCreateableName(
              res.data.data.firstname + " " + res.data.data.lastname
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    console.log("statusDetail", statusDetail);
  }, [statusDetail]);

  const detailCarrier = () => {
    return (
      <div
        style={{
          paddingTop: 20,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FieldDatePicker
          label={
            statusDetail?.status_id === 3
              ? "วันเวลาที่ต้องการตั้งเบิก*"
              : statusDetail?.status_id === 5
              ? "วันเวลาที่ต้องเตรียมแจ้งโอน*"
              : statusDetail?.status_id === 7
              ? "วันเวลาที่ต้องการแจ้งโอน*"
              : ""
          }
          subLabel={"(ค่าเริ่มต้น:วันเวลาปัจจุบัน)"}
          value={statusDetail?.actual_date}
          showTime={true}
          readOnly={true}
          style={{ width: "100%" }}
        />
        <FieldInput
          label="รวมจำนวนงาน"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.count_work}
        />
        <FieldInput
          label="รวมราคาผู้ขนส่งก่อนหักภาษี"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.carrier_freight_before_tax}
        />
        <FieldInput
          label="รวมภาษีหัก ณ ที่จ่าย"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.carrier_freight_tax}
        />
        <FieldInput
          label="รวมค่าประกันภัยสินค้ารายเที่ยว"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.carrier_insurance_per_trip}
        />
        <FieldInput
          label="รวมค่าปรับ"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.carrier_penalty}
        />
        <FieldInput
          label="รวมราคาผู้ขนส่งสุทธิ"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.carrier_freight_net}
        />
        {statusDetail?.status_id === 7 && (
          <>
            <FieldInput
              label="จำนวนเงินที่ชำระจริง*"
              subLabel="(ผลรวมจากการเลือก)"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.amount_of_transfer_actual}
            />
            <FieldInput
              label="ธนาคารที่ชำระ*"
              subLabel="(ผลรวมจากการเลือก)"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.bank_name}
            />
          </>
        )}
        <FieldUpload
          name={
            statusDetail?.status_id === 3
              ? "ใบแจ้งหนี้จากผู้ขนส่ง"
              : statusDetail?.status_id === 5
              ? "ใบวางบิลจากผู้ขนส่ง"
              : statusDetail?.status_id === 7
              ? "pay-in*"
              : ""
          }
          value={statusDetail?.file}
          readOnly={true}
        />

        <FieldInput
          label="ผู้ดูแลระบบที่ทำรายการ"
          style={{ width: "100%" }}
          readOnly={true}
          value={createableName}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "white",
            paddingBottom: 20,
          }}
        >
          <ButtonCustom
            type="cancel"
            name={"ปิด"}
            onClick={() => setShowModal(false)}
          />
          <ButtonCustom
            type={statusDetail?.file === "" ? "disabled" : "submit"}
            name={"ดูเอกสาร"}
            onClick={() =>
              window.open(`http://${statusDetail?.file}`, "_blank")
            }
            style={{ width: "160px" }}
            disabled={statusDetail?.file === "" ? true : false}
          />
        </div>
      </div>
    );
  };

  const detailShipper = () => {
    return (
      <div
        style={{
          paddingTop: 20,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FieldDatePicker
          label={
            statusDetail?.status_id === 3
              ? "วันเวลาที่ต้องการแจ้งหนี้*"
              : statusDetail?.status_id === 5
              ? "วันเวลาที่ต้องการวางบิล*"
              : "วันเวลาที่ต้องการชำระเงิน*"
          }
          subLabel={"(ค่าเริ่มต้น:วันเวลาปัจจุบัน)"}
          value={statusDetail?.actual_date}
          showTime={true}
          readOnly={true}
          style={{ width: "100%" }}
        />
        <FieldInput
          label={
            statusDetail?.status_id === 3
              ? "รวมจำนวนงาน"
              : statusDetail?.status_id === 5
              ? "รวมจำนวนใบแจ้งหนี้"
              : "รวมจำนวนใบแจ้งวางบิล"
          }
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.count_work}
        />
        <FieldInput
          label="รวมราคาลูกค้าก่อนหักภาษี"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.shipper_freight_before_tax}
        />
        <FieldInput
          label="รวมภาษีหัก ณ ที่จ่าย"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.shipper_freight_tax}
        />
        <FieldInput
          label="รวมราคาลูกค้าสุทธิ"
          subLabel="(ผลรวมจากการเลือก)"
          style={{ width: "100%" }}
          readOnly={true}
          value={statusDetail?.shipper_freight_net}
        />
        {statusDetail?.status_id === 7 ? (
          <>
            <FieldInput
              label="จำนวนเงินที่ชำระจริง*"
              subLabel="(ค่าเริ่มต้น: รวมราคาลูกค้าสุทธิ)"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.amount_of_transfer_actual}
            />
            <FieldInput
              label="ธนาคารที่ชำระ*"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.bank_name}
            />
            <FieldInput
              label="ref*"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.ref ?? ""}
            />
          </>
        ) : (
          <>
            <FieldDatePicker
              label={"วันที่กำหนดชำระเงิน*"}
              subLabel={
                "(ค่าเริ่มต้น:วันเวลาปัจจุบัน)(ลูกค้าเงินสด load_time_end +1 วัน)"
              }
              value={statusDetail?.payment_due_date}
              showTime={true}
              readOnly={true}
              style={{ width: "100%" }}
            />
            <FieldInput
              label="จำนวนวันเครดิตเทอม"
              subLabel="(ค่าเริ่มต้น : ดึงข้อมูลจากโครงการ)(ลูกค้าเครดิตไม่ต้องระบุ)"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.credit_term ?? ""}
            />
            <FieldInput
              label="รอบเวลาที่นับการใช้งาน"
              subLabel="(ค่าเริ่มต้น : ดึงข้อมูลจากโครงการ)(ลูกค้าเครดิตไม่ต้องระบุ)"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.credit_length_date ?? ""}
            />
            <FieldInput
              label="หมายเหตุ"
              style={{ width: "100%" }}
              readOnly={true}
              value={statusDetail?.credit_remark ?? ""}
            />
          </>
        )}
        <FieldUpload
          name={
            statusDetail?.status_id === 3
              ? "ใบแจ้งหนี้"
              : statusDetail?.status_id === 5
              ? "ใบวางบิล"
              : "สลิปโอนเงิน"
          }
          value={statusDetail?.file}
          readOnly={true}
        />
        {statusDetail?.status_id === 7 && (
          <FieldUpload
            name="ใบเสร็จรับเงิน"
            //  value={statusDetail?.file}
            readOnly={true}
          />
        )}

        <FieldInput
          label="ผู้ดูแลระบบที่ทำรายการ"
          style={{ width: "100%" }}
          readOnly={true}
          value={createableName}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "white",
            paddingBottom: 20,
          }}
        >
          <ButtonCustom
            type="cancel"
            name={"ปิด"}
            onClick={() => setShowModal(false)}
          />
          <ButtonCustom
            type={statusDetail?.file === "" ? "disabled" : "submit"}
            name={"ดูเอกสาร"}
            disabled={statusDetail?.file === "" ? true : false}
            style={{ width: "160px" }}
            onClick={() => window.open(statusDetail?.file, "_blank")}
          />
        </div>
      </div>
    );
  };

  const OtherStatus = () => {
    return (
      <div
        style={{
          paddingTop: 20,
        }}
      >
        <div>
          <FieldInput
            label="ผู้ดูแลระบบที่ทำรายการ"
            style={{ width: "100%" }}
            readOnly={true}
            value={createableName}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <div
        style={{
          textAlign: "center",
          padding: 13,
          fontSize: 16,
          fontWeight: 500,
          opacity: 0.6,
        }}
      >
        {props?.type === "carrier"
          ? "สถานะการชำระเงินผู้ขนส่ง"
          : "สถานะการชำระเงินลูกค้า"}
      </div>
      <Line />
      <div style={{ paddingTop: 10, overflow: "scroll" }}>
        {props?.data?.map((ele, index) => {
          return (
            <div key={"status" + index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0px 10px 10px 10px",
                    }}
                  >
                    <TripOriginIcon
                      sx={{ fontSize: 16, opacity: index === 0 ? 1 : 0.5 }}
                    />
                    <MoreVertIcon sx={{ opacity: index === 0 ? 1 : 0.5 }} />
                  </div>
                  <div
                    style={{ paddingLeft: 15, opacity: index === 0 ? 1 : 0.5 }}
                  >
                    <div
                      color={index === 0 ? "#121F43" : "#8CA3BA"}
                      fontSize={16}
                    >
                      {props?.type === "carrier"
                        ? ele.paymentCarrierStatus.name
                        : ele.paymentShipperStatus.name}
                    </div>
                    <div
                      color={index === 0 ? "#121F43" : "#8CA3BA"}
                      fontSize={11}
                    >
                      {helper.momentDateTime(ele.updated_at, "short", false)}
                    </div>
                  </div>
                </div>
                {props?.type === "carrier" ? (
                  <div
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showStatus(
                          ele?.payment_carrier_status_id,
                          ele,
                          "carrier"
                        );
                      }}
                    >
                      <ArrowForwardIosIcon sx={{ opacity: 0.3 }} />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showStatus(
                          ele?.payment_shipper_status_id,
                          ele,
                          "shipper"
                        );
                      }}
                    >
                      <ArrowForwardIosIcon sx={{ opacity: 0.3 }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <ModalWork
        open={showModal}
        close={() => {
          setShowModal(false);
          setStatusDetail(null);
        }}
        enableClose
        minHeight
      >
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>{statusDetail?.status}</div>
          <Line />
          <div style={{ overflow: "auto" }}>
            {statusDetail?.type === "carrier" &&
            (statusDetail?.status_id === 3 ||
              statusDetail?.status_id === 5 ||
              statusDetail?.status_id === 7)
              ? detailCarrier()
              : statusDetail?.type === "shipper" &&
                (statusDetail?.status_id === 3 ||
                  statusDetail?.status_id === 5 ||
                  statusDetail?.status_id === 7)
              ? detailShipper()
              : OtherStatus()}
          </div>
          {statusDetail?.status_id !== 3 &&
            statusDetail?.status_id !== 5 &&
            statusDetail?.status_id !== 7 && (
              <div
                style={{
                  position: isMobile ? "" : "absolute",
                  bottom: 20,
                  left: 20,
                  right: 20,
                  overflow: "auto",
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  ปิด
                </Button>
              </div>
            )}
        </div>
      </ModalWork>
    </div>
  );
};

export default PaymentStatus;
