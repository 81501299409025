import instance from "../../axiosInstance";

export const getAllShipperFavoriteGroup = async (params = {}) => {
  try {
    return await instance.get(
      `api/profile/favorite-group/shipper-favorite-group`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllShipperFavoriteGroupByProfileId = async (
  profile_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `api/profile/favorite-group/shipper-favorite-group/${profile_id}`,
      { params: params }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getShipperFavoriteGroup = async (id = null) => {
  try {
    return await instance.get(
      `api/profile/favorite-group/shipper-favorite-group/detail/${id}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createShipperFavoriteGroup = async (body = {}) => {
  try {
    return await instance.post(
      `api/profile/favorite-group/shipper-favorite-group/`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateShipperFavoriteGroup = async (id = null, body = {}) => {
  try {
    return await instance.patch(
      `api/profile/favorite-group/shipper-favorite-group/${id}`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
