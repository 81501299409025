import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import FieldIconLeftRight from "components/field/FieldIconLeftRight";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { FormControl, InputLabel, Input } from "@mui/material";
import "./input.css";
import axios from "axios";
import ENV from "utils/environment";
import HelperMap from "utils/helperMap";
import Swal from "sweetalert2";
export default function InputGoogleComplete(props) {
  const { value, label, error, onChange = () => {} } = props;
  const [placeList, setPlaceList] = useState([]);
  //   13.7908313,100.5422222
  const [location, setLocation] = useState({
    lat: 13.7908313,
    lon: 100.5422222,
  });
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    console.log("value: ", value);
  }, [value]);
  useEffect(() => {
    setTimeout(async () => {
      let pac = document.getElementsByClassName("pac-container");
      // console.log("pac-container", pac[0].children.length);
      if (pac[0]?.children?.length === 0 && value?.address) {
        console.log(value.address);
        console.log("here 0");
        let isLatLong = value.address.split(",");
        if (isLatLong && isLatLong.length === 2 && isLatLong[1] !== "") {
          // setGoogleGeoCoder(googleGeoCoder);
          //   console.log("googleGeoCoder", googleGeoCoder);
          if (/.[0-9]/.test(isLatLong[1])) {
            // let getDetail = await HelperMap.getAddressFromLocation({
            //   lat: isLatLong[0],
            //   lon: isLatLong[1],
            // });
            let getDetail = await HelperMap.getGeocode({
              lat: isLatLong[0],
              lng: isLatLong[1],
            });
            setPlaceList([getDetail]);
            // console.log("getDetail", getDetail);
          } else {
            // setPlaceList([]);
          }
        } else {
          //   setPlaceList([]);
        }
      } else {
        setPlaceList([]);
      }
    }, 100);
  }, [value]);
  useEffect(() => {
    initGoogleMap();
  }, []);
  const initGoogleMap = () => {
    HelperMap.getGoogleMaps().then(async () => {
      const map = new window.google.maps.Map(document.getElementById("gmap"), {
        center: {
          lat: location.lat,
          lng: location.lon,
        },
        zoom: 13,
        mapTypeId: "roadmap",
      });
      // const geocoder = new google.maps.Geocoder();

      const input = document.getElementById("location-search");
      const searchBox = new window.google.maps.places.SearchBox(input);

      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
      // Bias the SearchBox results towards current map's viewport.

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });

      searchBox.addListener("places_changed", async function () {
        let places = searchBox.getPlaces();

        console.log("places", places);
        if (places.length == 0) {
          return;
        }

        let bounds = new window.google.maps.LatLngBounds();
        if (!places[0].geometry) {
          console.log("Returned place contains no geometry");
          return;
        }

        if (places[0].geometry.viewport) {
          bounds.union(places[0].geometry.viewport);

          let address = places[0].formatted_address;

          let lat = places[0].geometry.location.lat();
          let lng = places[0].geometry.location.lng();

          // await setSearchAddr(address);
          // await setLocation({ lat: lat, lon: lng });
          //   let place = await HelperMap.getAddressFromLocation({
          //     lat: lat,
          //     lon: lng,
          //   });
          //   console.log("place", place);
          // let tmp = [...allData];
          //   let detail = await HelperMap.getDetailLocation(place, data);
          // tmp[index] = detail;
          // setShowAddr(detail);
          // setData(tmp[index]);
          // // let location_detail = await reverseGeocoding(locationDetail, {
          // //   lat: lat,
          // //   lon: lng,
          // // });
          let place = await HelperMap.getGeocode({ lat: lat, lng: lng });
          console.log("place", place);

          let country = place.address_components.filter((data) => {
            // country
            if (data.types.indexOf("country") >= 0) {
              return data;
            }
          });
          if (
            country.length > 0 &&
            country[0].short_name.toLowerCase() !== "th"
          ) {
            Swal.fire({
              html: `ท่านเลือกพิกัดอยู่นอกประเทศไทย`,
              type: "info",
            });
          } else {
            let data = await place_detail(place, { lat: lat, lng: lng });
            onChange({ ...value, ...data, search: data.address });
          }

          //   console.log('detail',detail)
          //
          // // await setLocationDetail(location_detail);
          // moveMap({ lat: lat, lon: lng });

          // tmp[index].marker.move({ lat: lat, lon: lng }, true);
        } else {
          bounds.extend(places[0].geometry.location);
        }
        map.fitBounds(bounds);
      });
    });
  };
  const place_detail = async (detail, latlng = null) => {
    // console.log();
    return await HelperMap.getDetailLocation(detail, latlng);
  };

  return (
    <div className={"input-google-complete"}>
      <div id={"gmap"}></div>
      <FormControl variant="standard" fullWidth margin="none">
        <TextField
          label={label}
          value={value?.search ?? ""}
          error={error}
          onChange={async (val) => {
            onChange({ ...value, search: val.target.value });
            //   await HelperMap.getAddressFromLocation({
            //     lat: 41.40338,
            //     lon: 2.17403,
            //   });
            //   await getPlace(val.target.value);
          }}
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 150);
          }}
          id={"location-search"}
          variant="standard"
        />
        <div style={{ position: "absolute", top: 25 }}>
          <SearchOutlinedIcon sx={{ fontSize: 30, color: "#8ca3ba" }} />
        </div>
        <div
          style={{
            position: "absolute",
            top: 25,
            right: 0,
            backgroundColor: "white",
          }}
        >
          <IconButton
          // onClick={handleClickShowPassword}
          // onMouseDown={handleMouseDownPassword}
          >
            <BookmarkBorderOutlinedIcon sx={{ color: "#8ca3ba" }} />
          </IconButton>
        </div>
        <div
          style={{
            fontSize: 10,
            marginLeft: 20,
            position: "absolute",
            color: "#8ca3ba",
            bottom: -16,
            left: 20,
          }}
        >
          <div>
            สามารถค้นหาจากชื่อสถานที่ พิกัดละติจูด,ลองจิจูด และ Google Plus Code
          </div>
        </div>
      </FormControl>
      {placeList.length > 0 && isFocused ? (
        <div className="place-list pac-container">
          {placeList.map((ele, index) => {
            return (
              <div
                key={"google-lat-long" + index}
                className="pac-item"
                style={{ fontSize: 12, fontWeight: 600 }}
                onClick={async () => {
                  //   console.log("click", ele);
                  let data = await place_detail(ele);
                  onChange({ ...value, ...data, search: data.address });
                }}
              >
                {ele.formatted_address}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
