import instance from "../../axiosInstance";
import helper from "utils/helper";

export const downloadTemplateFuelRateQuotation = async (
  project_fuel_rate_id = null
) => {
  try {
    return await instance.get(
      `/api/template-work/template-quotation-excel/download-template-fuel-rate-quotation/${project_fuel_rate_id}`,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createTemplateCarrierFreight = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/template-work/template-quotation-excel/create-template-carrier-freight`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const checkErrorFieldsInTemplateCarrierFrieghtFile = async (
  body = {},
  params = {}
) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/template-work/template-quotation-excel/check-error-field-template-carrier-freight-file`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getTemplateCarrierFreightExcel = async (
  project_fuel_rate_id = null
) => {
  try {
    return await instance.get(
      `/api/template-work/template-quotation-excel/get-template-carrier-freight-excel/${project_fuel_rate_id}`,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateFuelRateQuotation = async (
  project_fuel_rate_id = null,
  body = {}
) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);
    return await instance.patch(
      `/api/template-work/template-quotation-excel/update-fuel-rate-quotation/${project_fuel_rate_id}`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const exportCarrierFreightExcel = async (
  project_fuel_rate_id = null
) => {
  try {
    return await instance.get(
      `/api/template-work/template-quotation-excel/export-template-carrier-freight-excel/${project_fuel_rate_id}`,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
