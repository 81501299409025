import instance from "../../axiosInstance";
import helper from "utils/helper";

export const getAllTruck = async (params = {}) => {
  try {
    return await instance.get(`api/truck`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllTruckInProfile = async (profile_id = null, params = {}) => {
  try {
    return await instance.get(`api/truck/profile-truck/${profile_id}`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getTruck = async (id = null) => {
  try {
    return await instance.get(`api/truck/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createTruck = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(`api/truck/`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateTruck = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.patch(`api/truck/${id}`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const changeCarrier = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);
    console.log("form_data", form_data);
    return await instance.patch(`api/truck/change-carrier/${id}`, body, {
      headers: {
        // "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllTruckCheckProject = async (truck_id = null, params = {}) => {
  try {
    return await instance.get(`api/truck/truck-check-project/${truck_id}`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getTruckCheckProject = async (id = null) => {
  try {
    return await instance.get(`api/truck/truck-check-project/detail/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createTruckCheckProject = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(`api/truck/truck-check-project/`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateTruckCheckProject = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.patch(
      `api/truck/truck-check-project/${id}`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const uploadExcelTruck = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(`/api/truck/excel-insert`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const approveTruck = async (id = null, body = {}) => {
  try {
    return await instance.patch(`/api/truck/approve-truck/${id}`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
