// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableActiveIcon_tableActiveIconContainer__kg9Gy {
  display: flex;
  justify-content: center;
}

.tableActiveIcon_tableActiveIconContainer__kg9Gy .tableActiveIcon_iconWrapper__7VmgU {
  padding: 2px;
  width: max-content;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.tableActiveIcon_tableActiveIconContainer__kg9Gy .tableActiveIcon_activeColor__FNDNJ {
  background-color: #28ce88;
}

.tableActiveIcon_tableActiveIconContainer__kg9Gy .tableActiveIcon_inActiveColor__3wyNZ {
  background-color: lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/components/tableActiveIcon/tableActiveIcon.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".tableActiveIconContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.tableActiveIconContainer .iconWrapper {\n  padding: 2px;\n  width: max-content;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n}\n\n.tableActiveIconContainer .activeColor {\n  background-color: #28ce88;\n}\n\n.tableActiveIconContainer .inActiveColor {\n  background-color: lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableActiveIconContainer": `tableActiveIcon_tableActiveIconContainer__kg9Gy`,
	"iconWrapper": `tableActiveIcon_iconWrapper__7VmgU`,
	"activeColor": `tableActiveIcon_activeColor__FNDNJ`,
	"inActiveColor": `tableActiveIcon_inActiveColor__3wyNZ`
};
export default ___CSS_LOADER_EXPORT___;
