import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Container } from "@mui/material";
import DragTable from "components/table/DragTable";
import SubMenu from "components/layout/SubMenu.js";
import {
  getTruckType,
  updateOrderTruckType,
} from "../../../../utils/api/masterData/truckType";
import TableActiveIcon from "components/tableActiveIcon";
import helper from "utils/helper";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";

const columns = [
  {
    key: "created_at",
    title: "วันที่สร้างรายการ",
    align: "center",
    render: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    key: "updated_at",
    title: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    render: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    key: "truck_code",
    title: "รหัสประเภทรถ",
    align: "center",
    render: (value) => {
      return value.truck_code;
    },
  },
  {
    key: "truck_type",
    title: "ประเภทรถ",
    align: "center",
    render: (value) => {
      return value.name;
    },
  },
  {
    key: "active",
    title: "สถานะใช้งาน",
    align: "center",
    render: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const OrderTruckType = () => {
  const [truckType, setTruckType] = useState([]);
  const [listNewTruckTypeId, setListNewTruckTypeId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const getTruckTypeData = async () => {
    setIsLoading(true);

    try {
      const params = {
        sort_field: ["order_number"],
        sort_order: [1],
        page: null,
        per_page: null,
      };
      const resp = await getTruckType(params);
      setIsLoading(false);

      return {
        data: resp.data.data.results,
        total: resp.data.data.total,
      };
    } catch (error) {
      console.log("error getTruckType: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data, total } = await getTruckTypeData();

      setTruckType(data);
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดประเภทรถ`}
        pathbackward={`/master-data/truck-type`}
        menus={[
          {
            label: "จัดการลำดับประเภทรถ",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "ประเภทรถ",
            path: null,
          },
          {
            label: "จัดการลำดับประเภทรถ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderTable = () => {
    return (
      <DragTable
        columns={columns}
        dataSource={truckType}
        loading={isLoading}
        onDragChange={(val) => {
          setListNewTruckTypeId(val.map((data) => data.id));
        }}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  const handleUpdateOrderTruckType = async () => {
    try {
      if (!R.isEmpty(listNewTruckTypeId)) {
        setIsLoading(true);
        const resp = await updateOrderTruckType({
          list_truck_id: listNewTruckTypeId,
        });

        if (resp.data.code === 1000) {
          setListNewTruckTypeId([]);
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {renderSubMenu()}
      {renderTable()}
      {renderModal()}

      <FloatingButton
        button_type="save"
        onClick={() => {
          handleUpdateOrderTruckType();
        }}
      />
    </Container>
  );
};

export default OrderTruckType;
