import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// project import
import Typography from "./typography";
import componentsOverride from "./overrides";
import Palette from "./palette";
import responsive from "utils/responsive";

export default function ThemeCustomization({ children }) {
  const theme = Palette("light", "default");

  const themeTypography = Typography(`'Kanit', sans-serif`);
  // const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = useMemo(() => {
    return {
      // breakpoints: {
      //   values: {
      //     xs: 0,
      //     sm: 768,
      //     md: 1024,
      //     lg: 1266,
      //     xl: 1536,
      //   },
      // },
      breakpoints: {
        values: {
          xs: responsive.xs,
          sm: responsive.sm,
          md: responsive.md,
          lg: responsive.lg,
          xl: responsive.xl,
        },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      typography: themeTypography,
    };
  }, [theme, themeTypography]);

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
