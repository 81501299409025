import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import {
  getTruckTypeById,
  updateTruckType,
} from "../../../../utils/api/masterData/truckType";
import { getAllListTruckDrivingLicense } from "utils/api/masterData/register";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import SubMenu from "components/layout/SubMenu.js";
import AlertModal from "components/modal/AlertModal";
import helper from "utils/helper";
import styles from "./TruckTypeInfo.module.css";
import Button from "components/button/Button";
import {
  downloadExcelTruckFreight,
  uploadTruckFreightFromExcel,
} from "utils/api/masterData/truckFreight";

const TruckTypeInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [listTruckDrivingLicense, setListTruckDrivingLicense] = useState([]);

  const {
    // register,
    handleSubmit,
    control,
    // watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      active: true,
      add_drop_point_fee: 1,
      allowance: 1,
      average_speed_per_hour: 1,
      consumption_rate: 1,
      document_return_fee: 1,
      has_tail: true,
      height: 1,
      image: "",
      labor_fee: 1,
      left_over_fee: 1,
      length: 1,
      maintenance: 1,
      max_labor_load: 1,
      max_labor_unload: 1,
      min_cover_insurance_head: 1,
      min_cover_insurance_tail: 1,
      min_labor_load: 1,
      min_labor_unload: 1,
      name: "",
      order_number: 1,
      premium_per_trip_head: 1,
      premium_per_trip_tail: 1,
      saas: 1,
      truck_code: "",
      truck_freight: {
        freight_minimum: 1920.21,
        truck_freight_km: [],
      },
      volume: 1,
      weight: 1,
      width: 1,
    },
  });

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {
        const { data: truck_info_data } = await getTruckTypeInfo();

        if (!R.isEmpty(truck_info_data)) {
          Object.keys(truck_info_data).forEach((key) => {
            if (typeof truck_info_data[key] === "boolean") {
              const bool_val = truck_info_data[key] === true ? `true` : `false`;
              setValue(key, bool_val);
            } else if (Array.isArray(truck_info_data[key])) {
              const _value = truck_info_data[key].map(
                (item) => item.driving_license_id
              );
              setValue(key, _value);
            } else {
              setValue(key, truck_info_data[key]);
            }
          });
        }

        const listTruckDrivingLicense_resp =
          await getAllListTruckDrivingLicense();
        if (listTruckDrivingLicense_resp.data.code == 1000) {
          setListTruckDrivingLicense(
            listTruckDrivingLicense_resp.data.data.results
          );
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error getData in useEffect: ", error);
        navigate("/master-data/truck-type");
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const updateVolumeField = () => {
    const width = getValues("width") ? getValues("width") : null;
    const height = getValues("height") ? getValues("height") : null;
    const length = getValues("length") ? getValues("length") : null;

    if (width && height && length) {
      const volume = parseFloat(width * height * length).toFixed(2);
      setValue("volume", volume);
      clearErrors("volume");
    } else {
      setValue("volume", null);
      setError("volume", "");
    }
  };

  const getTruckTypeInfo = async () => {
    try {
      const resp = await getTruckTypeById(id);

      return {
        data: resp.data.data.results,
        total: resp.data.data.total,
      };
    } catch (error) {
      console.log("error getTruckTypeInfo: ", error);
    }
  };

  const handleUploadFileTruckFreight = async ({ file = null }) => {
    try {
      const is_excel_file = helper.isCsvOrXlsxfile({ file: file });
      if (is_excel_file === true) {
        const upload_response = await uploadTruckFreightFromExcel({
          truck_type_id: id,
          truck_freight_file: file,
        });
        if (upload_response.data.code === 1000) {
          // setAlertModal({
          //   open: true,
          //   title: `อัพโหลดสำเร็จ`,
          //   description: `อัพโหลดข้อมูลสำเร็จ`,
          // });
        }
      } else {
        setAlertModal({
          open: true,
          title: `พบปัญหาขณะอัพโหลดไฟล์`,
          description: `กรุณาใส่ไฟล์ .csv | .xlsx | .xls`,
        });
      }
    } catch (error) {
      // setAlertModal({
      //   open: true,
      //   title: `พบปัญหาขณะอัพโหลดไฟล์`,
      //   description: error.response.data.description,
      // });
      throw { error };
    }
  };

  const updateTruckTypeData = async () => {
    setIsLoading(true);

    try {
      let _truckInfo = getValues();
      if (typeof _truckInfo.image === `string`) {
        _truckInfo = R.omit(["image"], _truckInfo);
      }

      // const { km_start, km_end, baht_per_km } =
      //   _truckInfo.truck_freight.truck_freight_km.reduce(
      //     (accumulator, currentValue) => {
      //       accumulator.km_start.push(currentValue.km_start);
      //       accumulator.km_end.push(currentValue.km_end);
      //       accumulator.baht_per_km.push(currentValue.baht_per_km);

      //       return accumulator;
      //     },
      //     {
      //       km_start: [],
      //       km_end: [],
      //       baht_per_km: [],

      //     }
      //   );

      _truckInfo = {
        ..._truckInfo,
        // truck_freight_km_start: km_start,
        // truck_freight_km_end: km_end,
        // truck_freight_baht_per_km: baht_per_km,
        active: _truckInfo.active === `true` ? true : false,
        has_tail: _truckInfo.has_tail === `true` ? true : false,
        // freight_minimum: _truckInfo.truck_freight.freight_minimum,
        // truck_freight_km: _truckInfo.truck_freight.truck_freight_km,
      };
      _truckInfo = R.omit(["truck_freight"], _truckInfo);
      _truckInfo = R.omit(["truck_freight_file"], _truckInfo);

      updateTruckType(id, _truckInfo).then(async (update_resp) => {
        if (update_resp.data.code === 1000) {
          let truck_freight_file = getValues().truck_freight_file;
          if (!R.isNil(truck_freight_file)) {
            await handleUploadFileTruckFreight({
              file: truck_freight_file,
            });
          }

          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
      });
      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะใช้งาน";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "name",
        field_type: "text",
        field_label: "ประเภทรถ*",
        rules: {
          required: "กรุณาเลือกประเภทรถ",
        },
      },
      {
        name: "image",
        nameDescription: "ขนาด 175 x 50 พิกเซล หรือ อัตราส่วน 2:7 เท่านั้น",
        field_type: "upload",
        field_label: "รูปภาพรถ*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              const { width: image_width, height: image_height } =
                await helper.convertFileSizeToPixel({ file: file });

              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
              }
              if (helper.isFileOverSize({ file: file, size: 10 })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
              } else if (image_width !== 175 && image_height !== 50) {
                return "กรุณาใส่รูปภาพขนาด 175 x 50 พิกเซล";
              }
            }
            return true;
          },
        },
      },
      {
        name: "has_tail",
        field_type: "select",
        field_label: "มีหาง*",
        onChange: (e) => {
          setValue("min_cover_insurance_tail", null);
          clearErrors("min_cover_insurance_tail");
          setValue("premium_per_trip_tail", null);
          clearErrors("premium_per_trip_tail");
        },
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะมีหาง";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
      },
      {
        name: "width",
        field_type: "text",
        field_label: "ความกว้างของกระบะบรรทุก (เมตร)*",
        onBlur: (e) => {
          updateVolumeField();
          setValue(`width`, parseFloat(getValues().width).toFixed(2));
        },
        rules: {
          required: "กรุณาใส่ความกว้างของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "length",
        field_type: "text",
        field_label: "ความยาวของกระบะบรรทุก (เมตร)*",
        onBlur: () => {
          updateVolumeField();
          setValue(`length`, parseFloat(getValues().length).toFixed(2));
        },
        rules: {
          required: "กรุณาใส่ความยาวของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "height",
        field_type: "text",
        field_label: "ความสูงของกระบะบรรทุก (เมตร)*",
        onBlur: () => {
          updateVolumeField();
          setValue(`height`, parseFloat(getValues().height).toFixed(2));
        },
        rules: {
          required: "กรุณาใส่ความสูงของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "volume",
        variant: "filled",
        disabled: true,
        field_type: "text",
        field_label: "ปริมาตรของกระบะบรรทุก (เมตร)*",
        rules: {
          required: "กรุณาใส่ปริมาตรของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "weight",
        field_type: "text",
        field_label: "นํ้าหนักบรรทุก (กิโลกรัม)*",
        rules: {
          required: "กรุณาใส่นํ้าหนักบรรทุก (กิโลกรัม)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{getValues().truck_code}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn2 = () => {
    const col2_menu = [
      {
        name: "consumption_rate",
        field_label: "อัตราการสิ้นเปลืองเชื้อเพลิง (กิโลเมตร/ลิตร)*",
        rules: {
          required: "กรุณาใส่อัตราการสิ้นเปลืองเชื้อเพลิง (กิโลเมตร/ลิตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "average_speed_per_hour",
        field_label: "ความเร็วเฉลี่ย (กิโลเมตร/ชั่วโมง)*",
        rules: {
          required: "กรุณาใส่ความเร็วเฉลี่ย (กิโลเมตร/ชั่วโมง)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_cover_insurance_head",
        field_label: "วงคุ้มครองขั้นต่ำ (ส่วนหัว)*",
        rules: {
          required: "กรุณาใส่วงคุ้มครองขั้นต่ำ (ส่วนหัว)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_cover_insurance_tail",
        disabled: getValues().has_tail === "false" ? true : false,
        field_label: "วงคุ้มครองขั้นต่ำ (ส่วนหาง)*",
        rules: {
          required:
            getValues().has_tail === "true"
              ? "กรุณาใส่วงคุ้มครองขั้นต่ำ (ส่วนหาง)"
              : null,
          pattern:
            getValues().has_tail === "true"
              ? {
                  value: helper.RegexValidate(`number`),
                  message: "ระบุเป็นตัวเลขได้เท่านั้น",
                }
              : null,
        },
      },
      {
        name: "premium_per_trip_head",
        field_label: "เบี้ยประกันภัยรายเที่ยว (ส่วนหัว)*",
        rules: {
          required: "กรุณาใส่เบี้ยประกันภัยรายเที่ยว (ส่วนหัว)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "premium_per_trip_tail",
        disabled: getValues().has_tail === "false" ? true : false,
        field_label: "เบี้ยประกันภัยรายเที่ยว (ส่วนหาง)*",
        rules: {
          required:
            getValues().has_tail === "true"
              ? "กรุณาใส่เบี้ยประกันภัยรายเที่ยว (ส่วนหาง)"
              : null,
          pattern:
            getValues().has_tail === "true"
              ? {
                  value: helper.RegexValidate(`number`),
                  message: "ระบุเป็นตัวเลขได้เท่านั้น",
                }
              : null,
        },
      },
      {
        name: "maintenance",
        field_label: "ค่าซ่อมบำรุง (บาท/กิโลเมตร)*",
        rules: {
          required: "กรุณาใส่ค่าซ่อมบำรุง (บาท/กิโลเมตร)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "allowance",
        field_label: "ค่าเบี้ยเลี้ยง (บาท/เที่ยว)*",
        rules: {
          required: "กรุณาใส่ค่าเบี้ยเลี้ยง (บาท/เที่ยว)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "saas",
        field_label: "ค่าดำเนินการ (%)*",
        rules: {
          required: "กรุณาใส่ค่าดำเนินการ (%)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_labor_load",
        field_label: "จำนวนคนงานยกสินค้าขึ้นต่ำสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าขึ้นต่ำสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
    ];

    return (
      <div className={styles.field_container}>
        {col2_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn3 = () => {
    const col3_menu = [
      {
        name: "max_labor_load",
        field_label: "จำนวนคนงานยกสินค้าขึ้นสูงสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าขึ้นสูงสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "min_labor_unload",
        field_label: "จำนวนคนงานยกสินค้าลงต่ำสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าลงต่ำสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "max_labor_unload",
        field_label: "จำนวนคนงานยกสินค้าลงสูงสุด*",
        rules: {
          required: "กรุณาใส่จำนวนคนงานยกสินค้าลงสูงสุด",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "labor_fee",
        field_label: "ค่าคนงานยกสินค้า*",
        rules: {
          required: "กรุณาใส่ค่าคนงานยกสินค้า",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "left_over_fee",
        field_label: "ค่าตกค้าง*",
        rules: {
          required: "กรุณาใส่ค่าตกค้าง",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "add_drop_point_fee",
        field_label: "ค่าเพิ่มจุดส่งสินค้า (บาท/จุด)*",
        rules: {
          required: "กรุณาใส่ค่าเพิ่มจุดส่งสินค้า (บาท/จุด)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "document_return_fee",
        field_label: "ค่าเอกสารนำกลับ (บาท/งาน)*",
        rules: {
          required: "กรุณาใส่ค่าเอกสารนำกลับ (บาท/งาน)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      // {
      //   name: "truck_freight.freight_minimum",
      //   field_label: "ราคาเริ่มต้น (บาท)*",
      //   rules: {
      //     required: "กรุณาใส่ราคาเริ่มต้น (บาท)",
      //     pattern: {
      //       value: helper.RegexValidate(`number`),
      //       message: "ระบุเป็นตัวเลขได้เท่านั้น",
      //     },
      //   },
      //   error:
      //     errors.truck_freight && errors.truck_freight.freight_minimum
      //       ? !!errors.truck_freight.freight_minimum
      //       : false,
      //   helperText:
      //     errors.truck_freight && errors.truck_freight.freight_minimum
      //       ? errors.truck_freight.freight_minimum.message
      //       : false,
      // },
      {
        name: "truck_driving_license",
        nameDescription: "ระบบจะดึงจากฐานข้อมูลสามารถเลือกได้หลายรายการ",
        field_type: "select",
        field_label: "ประเภทใบขับขี่ที่สามารถขับได้*",
        multiSelect: true,
        rules: {
          required: "กรุณาเลือกประเภทใบขับขี่",
        },
        options: listTruckDrivingLicense.map((item) => {
          return {
            value: item.id,
            name: item.name,
          };
        }),
      },
    ];

    // const col3_truck_freight_km = [
    //   {
    //     km_start: 1,
    //     km_end: 100,
    //     baht_per_km: null,
    //   },
    //   {
    //     km_start: 101,
    //     km_end: 200,
    //     baht_per_km: null,
    //   },
    // ].map((item, index) => {
    //   return {
    //     name: `truck_freight.truck_freight_km[${index}].baht_per_km`,
    //     field_label: `กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)*`,
    //     onBlur: (e) => {
    //       setValue(`truck_freight.truck_freight_km[${index}]`, {
    //         km_start: item.km_start,
    //         km_end: item.km_end,
    //         baht_per_km:
    //           !R.isNil(e.target.value) && !R.isEmpty(e.target.value)
    //             ? parseFloat(e.target.value).toFixed(2)
    //             : parseFloat(0).toFixed(2),
    //       });
    //     },
    //     rules: {
    //       required: `กรุณาใส่กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)`,
    //       pattern: {
    //         value: helper.RegexValidate("number"),
    //         message: "ระบุเป็นตัวเลขได้เท่านั้น",
    //       },
    //     },
    //     error:
    //       errors.truck_freight &&
    //       errors.truck_freight.truck_freight_km &&
    //       errors.truck_freight.truck_freight_km[index] &&
    //       errors.truck_freight.truck_freight_km[index].baht_per_km
    //         ? true
    //         : false,
    //     helperText:
    //       errors.truck_freight &&
    //       errors.truck_freight.truck_freight_km &&
    //       errors.truck_freight.truck_freight_km[index] &&
    //       errors.truck_freight.truck_freight_km[index].baht_per_km
    //         ? errors.truck_freight.truck_freight_km[index].baht_per_km.message
    //         : null,
    //   };
    // });

    return (
      <div className={styles.field_container}>
        {col3_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}

        {/* {col3_truck_freight_km.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })} */}
      </div>
    );
  };

  const renderColumn4 = () => {
    return (
      <div className={styles.field_container}>
        <Button
          type={`button`}
          onClick={async () => {
            const download_response = await downloadExcelTruckFreight(id);
            helper.downloadFile({
              api_response: download_response,
            });
          }}
          style={{
            margin: `0px 0px 10px`,
          }}
        >{`ดาวน์โหลดแม่แบบค่าขนส่ง`}</Button>

        <TemplateForm
          form_info={{
            name: "truck_freight_file",
            field_type: "upload",
            field_label: "ไฟล์ค่าขนส่ง",
            rules: {
              validate: async (file) => {
                if (!R.isNil(file)) {
                  if (typeof file !== "string") {
                    if (!helper.isCsvOrXlsxfile({ file: file })) {
                      return "อัพโหลดไฟล์นามสกุล .csv | .xlsx | .xls เท่านั้น";
                    }
                  }
                }
                return true;
              },
            },
          }}
          control={control}
          errors={errors}
          trigger={trigger}
        />
      </div>
    );
  };

  // const renderColumn4 = () => {
  //   const col4_truck_freight_km = [
  //     {
  //       km_start: 201,
  //       km_end: 300,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 301,
  //       km_end: 400,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 401,
  //       km_end: 500,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 501,
  //       km_end: 600,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 601,
  //       km_end: 700,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 701,
  //       km_end: 800,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 801,
  //       km_end: 900,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 901,
  //       km_end: 1000,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1001,
  //       km_end: 1100,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1101,
  //       km_end: 1200,
  //       baht_per_km: null,
  //     },
  //   ].map((item, index) => {
  //     index = index + 2;
  //     return {
  //       name: `truck_freight.truck_freight_km[${index}].baht_per_km`,
  //       field_label: `กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)*`,
  //       onChange: (e) => {
  //         setValue(`truck_freight.truck_freight_km[${index}]`, {
  //           km_start: item.km_start,
  //           km_end: item.km_end,
  //           baht_per_km: e.target.value,
  //         });
  //       },
  //       onBlur: (e) => {
  //         setValue(`truck_freight.truck_freight_km[${index}]`, {
  //           km_start: item.km_start,
  //           km_end: item.km_end,
  //           baht_per_km:
  //             !R.isNil(e.target.value) && !R.isEmpty(e.target.value)
  //               ? parseFloat(e.target.value).toFixed(2)
  //               : parseFloat(0).toFixed(2),
  //         });
  //       },
  //       rules: {
  //         required: `กรุณาใส่กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)`,
  //         pattern: {
  //           value: helper.RegexValidate("number"),
  //           message: "ระบุเป็นตัวเลขได้เท่านั้น",
  //         },
  //       },
  //       error:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? true
  //           : false,
  //       helperText:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? errors.truck_freight.truck_freight_km[index].baht_per_km.message
  //           : null,
  //     };
  //   });

  //   return (
  //     <div className={styles.field_container}>
  //       {col4_truck_freight_km.map((menu, index) => {
  //         return (
  //           <TemplateForm
  //             form_info={menu}
  //             index={index}
  //             control={control}
  //             errors={errors}
  //           />
  //         );
  //       })}
  //     </div>
  //   );
  // };

  // const renderColumn5 = () => {
  //   const col5_truck_freight_km = [
  //     {
  //       km_start: 1201,
  //       km_end: 1300,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1301,
  //       km_end: 1400,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1401,
  //       km_end: 1500,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1501,
  //       km_end: 1600,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1601,
  //       km_end: 1700,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1701,
  //       km_end: 1800,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1801,
  //       km_end: 1900,
  //       baht_per_km: null,
  //     },
  //     {
  //       km_start: 1901,
  //       km_end: 2000,
  //       baht_per_km: null,
  //     },
  //   ].map((item, index) => {
  //     index = index + 12;
  //     return {
  //       name: `truck_freight.truck_freight_km[${index}].baht_per_km`,
  //       field_label: `กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)*`,
  //       onChange: (e) => {
  //         setValue(`truck_freight.truck_freight_km[${index}]`, {
  //           km_start: item.km_start,
  //           km_end: item.km_end,
  //           baht_per_km: e.target.value,
  //         });
  //       },
  //       onBlur: (e) => {
  //         setValue(`truck_freight.truck_freight_km[${index}]`, {
  //           km_start: item.km_start,
  //           km_end: item.km_end,
  //           baht_per_km:
  //             !R.isNil(e.target.value) && !R.isEmpty(e.target.value)
  //               ? parseFloat(e.target.value).toFixed(2)
  //               : parseFloat(0).toFixed(2),
  //         });
  //       },
  //       rules: {
  //         required: `กรุณาใส่กิโลเมตรที่ ${item.km_start} - ${item.km_end} (บาท/กิโลเมตร)`,
  //         pattern: {
  //           value: helper.RegexValidate("number"),
  //           message: "ระบุเป็นตัวเลขได้เท่านั้น",
  //         },
  //       },
  //       error:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? true
  //           : false,
  //       helperText:
  //         errors.truck_freight &&
  //         errors.truck_freight.truck_freight_km &&
  //         errors.truck_freight.truck_freight_km[index] &&
  //         errors.truck_freight.truck_freight_km[index].baht_per_km
  //           ? errors.truck_freight.truck_freight_km[index].baht_per_km.message
  //           : null,
  //     };
  //   });

  //   const col5_menu = [
  //     {
  //       name: "truck_driving_license",
  //       nameDescription: "ระบบจะดึงจากฐานข้อมูลสามารถเลือกได้หลายรายการ",
  //       field_type: "select",
  //       field_label: "ประเภทใบขับขี่ที่สามารถขับได้*",
  //       multiSelect: true,
  //       rules: {
  //         required: "กรุณาเลือกประเภทใบขับขี่",
  //       },
  //       options: listTruckDrivingLicense.map((item) => {
  //         return {
  //           value: item.id,
  //           name: item.name,
  //         };
  //       }),
  //     },
  //   ];

  //   return (
  //     <div className={styles.field_container}>
  //       {col5_truck_freight_km.map((menu, index) => {
  //         return (
  //           <TemplateForm
  //             form_info={menu}
  //             index={index}
  //             control={control}
  //             errors={errors}
  //           />
  //         );
  //       })}

  //       {col5_menu.map((menu, index) => {
  //         return (
  //           <TemplateForm
  //             form_info={menu}
  //             index={index}
  //             control={control}
  //             errors={errors}
  //           />
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดประเภทรถ`}
        pathbackward={`/master-data/truck-type`}
        menus={[
          {
            label: "รายละเอียดประเภทรถ",
            path: `#`,
            active: true,
          },
          {
            label: "จัดการลำดับประเภทรถ",
            path: `/master-data/truck-type/order-list`,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "ประเภทรถ",
            path: null,
          },
          {
            label: getValues().name,
            path: null,
          },
          {
            label: "รายละเอียดประเภทรถ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          updateTruckTypeData();
        })}
      >
        {renderColumn1()}
        {renderColumn2()}
        {renderColumn3()}
        {renderColumn4()}
        {/* {renderColumn5()} */}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default TruckTypeInfo;
