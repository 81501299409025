import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { UploadFile } from "@mui/icons-material";
import FormModal from "components/modal/FormModal";
import FieldInput from "components/field/FieldInput";
import AlertModal from "components/modal/AlertModal";
import api from "../../../../utils/api/masterData/productType";
import FieldUpload from "components/field/FieldUpload";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";

const CreateProductTypeModal = ({
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const form_confim_btn = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: {},
  });

  const renderForm = () => {
    const form_menu = [
      {
        name: "code",
        field_type: "text",
        field_label: "รหัสสินค้า*",
        rules: {
          required: "กรุณาใส่ประเภทสินค้า",
        },
      },
      {
        name: "name",
        field_type: "text",
        field_label: "ประเภทสินค้า*",
        rules: {
          required: "กรุณาใส่ประเภทสินค้า",
        },
      },
      {
        name: "image",
        nameDescription: "ขนาด 175 x 50 พิกเซล หรือ อัตราส่วน 2:7 เท่านั้น",
        field_type: "upload",
        field_label: "รูปภาพสินค้า*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              const { width: image_width, height: image_height } =
                await helper.convertFileSizeToPixel({ file: file });

              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
              }
              if (helper.isFileOverSize({ file: file, size: 10 })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
              } else if (image_width !== 175 && image_height !== 50) {
                return "กรุณาใส่รูปภาพขนาด 175 x 50 พิกเซล";
              }
            }
            return true;
          },
        },
      },
      {
        name: "example",
        field_type: "text",
        field_label: "ตัวอย่างชนิดสินค้า*",
        rules: {
          required: "กรุณาใส่ตัวอย่างชนิดสินค้า",
        },
      },
      // {
      //   name: "example",
      //   nameDescription: "ขนาด 175 x 50 พิกเซล หรือ อัตราส่วน 2:7 เท่านั้น",
      //   field_type: "upload",
      //   field_label: "รูปภาพตัวอย่างสินค้า*",
      //   rules: {
      //     validate: async (file) => {
      //       if (typeof file !== "string") {
      //         const { width: image_width, height: image_height } =
      //           await helper.convertFileSizeToPixel({ file: file });

      //         if (!helper.isJpgFile({ file: file })) {
      //           return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
      //         }
      //         if (helper.isFileOverSize({ file: file, size: 10 })) {
      //           return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
      //         } else if (image_width !== 175 && image_height !== 50) {
      //           return "กรุณาใส่รูปภาพขนาด 175 x 50 พิกเซล";
      //         }
      //       }
      //       return true;
      //     },
      //   },
      // },
    ];

    return (
      <div>
        {form_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
              trigger={trigger}
            />
          );
        })}
      </div>
    );
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  const handlecreateProductType = async () => {
    setIsLoading(true);

    try {
      let data = getValues();

      data = { ...data, order_number: 1, active: true };
      const create_resp = await api.createProductType(data);

      if (create_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setOpen(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setOpen(false);
    }
  };

  return (
    <>
      <FormModal
        title={`เพิ่มประเภทสินค้า`}
        open={open}
        confirmTextBtn={"บันทึก"}
        cancelTextBtn={"ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          reset();
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handlecreateProductType();
          })}
        >
          {renderForm()}

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateProductTypeModal;
