import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import AlertModal from "components/modal/AlertModal";
import {
  getProfileReceiptName,
  updateProfileReceiptName,
} from "utils/api/profile/profileReceiptName";
import { getUserById } from "utils/api/user/user";
import {
  FreightType,
  PaymentType,
  ProfileType,
  ProjectFrequencyType,
  ReminderDate,
  WorkType,
} from "utils/enum";
import { getParticipateProject } from "utils/api/project/profileCarrierInProject";
import { getAllService } from "utils/api/masterData/service";
import { calculateCreditProject } from "utils/api/project/project";
import helper from "utils/helper";
import styles from "./ParticipateProjectInfo.module.css";

const ParticipateProjectInfo = () => {
  const { user_id, profile_id, participate_project_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState(``);
  const [userInfo, setUserInfo] = useState({});
  const [allService, setAllService] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetParticipateProject();
    handleGetUserInfo();
    handleGetAllService();

    // eslint-disable-next-line
  }, []);

  const handleGetParticipateProject = async () => {
    setIsLoading(true);
    try {
      const resp = await getParticipateProject(participate_project_id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else if (
            key === `project_frequency_type` ||
            key === `work_type` ||
            key === `freight_type` ||
            key === `shipper_payment_type`
          ) {
            setValue(key, {
              ...data[key],
              id: data[key].id.toString(),
            });
          } else if (key === `project_reminder_shipper`) {
            if (!R.isNil(data[key]) && !R.isEmpty(data[key])) {
              let _reminder_date = [];
              data[key].forEach((item, index) => {
                _reminder_date = [..._reminder_date, item.reminder_date];
              });
              setValue(key, _reminder_date);
            }
          } else if (key === `profileCarrierInProject`) {
            if (!R.isNil(data[key]) && !R.isEmpty(data[key])) {
              setValue(key, data[key][0]);
            }
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(data.project_code);
      handleCalculateCreditProject();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getProfileJoinCarrier: ", error);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
      // navigate("/truck/truck-check-project");
    }
  };

  const handleCalculateCreditProject = async () => {
    try {
      const params = {
        project_payment_type: `carrier`,
        carrier_in_project_id: getValues().profileCarrierInProject.id,
      };
      const resp = await calculateCreditProject(getValues().project_id, params);
      if (resp.data.code === 1000) {
        const data = resp.data.data;
        Object.keys(data).forEach((key) => {
          setValue(key, data[key]);
        });
      }
      // setUserInfo(resp.data.data);
    } catch (error) {
      console.log("error handleCalculateCreditProject: ", error);
    }
  };

  const handleGetUserInfo = async () => {
    try {
      const resp = await getUserById(user_id);
      setUserInfo(resp.data.data);
    } catch (error) {
      console.log("error handleGetUserInfo: ", error);
    }
  };

  const handleGetAllService = async () => {
    try {
      const resp = await getAllService();
      setAllService(resp.data.data.results);
    } catch (error) {
      console.log("error getAllService: ", error);
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดโครงการที่เข้าร่วม`}
        pathbackward={location.pathname.split("/detail")[0]}
        menus={[
          {
            label: "รายละเอียดโครงการที่เข้าร่วม",
            path: `#`,
            active: true,
          },
          {
            label: "ใบเสนอราคาตามอัตรานํ้ามัน",
            path: `/user/${user_id}/profile/${profile_id}/participate-project/${participate_project_id}/project/${getValues(
              "project_id"
            )}/fuel-rate-quotation`,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label: userInfo ? userInfo.user_code : ``,
            path: null,
          },
          {
            label: "ข้อมูลโครงการที่เข้าร่วม",
            path: null,
          },
          {
            label: getValues().project_code ? getValues().project_code : ``,
            path: null,
          },
          {
            label: "รายละเอียดโครงการที่เข้าร่วม",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  const renderColumn1 = () => {
    const form1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน",
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
        disabled: true,
      },
      {
        name: "is_default",
        field_type: "select",
        field_label: "โครงการเริ่มต้น",
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        disabled: true,
      },
      {
        name: "project_code",
        field_type: "text",
        field_label: "ชื่อโครงการ",
        disabled: true,
      },
      {
        name: "profile.company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์ลูกค้า",
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
        disabled: true,
      },
      {
        name: "service.id",
        field_type: "select",
        field_label: "ประเภทบริการ",
        options: allService.map((service) => {
          return { value: service.id, name: service.name };
        }),
        disabled: true,
      },
      {
        name: "project_frequency_type.id",
        field_type: "select",
        field_label: "ความถี่งาน",
        options: ProjectFrequencyType.map((frequency_type) => {
          return {
            value: frequency_type.value.toString(),
            name: frequency_type.name,
          };
        }),
        disabled: true,
      },
      {
        name: "project_frequency_type.id",
        field_type: "select",
        field_label: "ลักษณะงาน",
        options: WorkType.map((work_type) => {
          return {
            value: work_type.value.toString(),
            name: work_type.name,
          };
        }),
        disabled: true,
      },
      {
        name: "has_truck_check",
        field_type: "select",
        field_label: "มีการตรวจสภาพรถ",
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>

          <h4>{`ข้อมูลโครงการ`}</h4>
        </div>

        {form1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn2 = () => {
    const form2_menu = [
      {
        name: "has_driver_training",
        field_type: "select",
        field_label: "มีการอบรมพนักงานขับรถ",
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        disabled: true,
      },
      {
        name: "has_driver_health",
        field_type: "select",
        field_label: "มีการตรวจสุขภาพพนักงานขับรถ",
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        disabled: true,
      },
      {
        name: "freight_type.id",
        field_type: "select",
        field_label: "ประเภทการคิดค่าขนส่ง",
        options: FreightType.map((freight_type) => {
          return {
            value: freight_type.value.toString(),
            name: freight_type.name,
          };
        }),
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลโครงการ`}</h4>
        </div>

        {form2_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn3 = () => {
    const form3_menu = [
      {
        name: "shipper_payment_type.id",
        field_type: "select",
        field_label: "ประเภทการรับเงินของผู้ขนส่ง",
        options: PaymentType.map((payment_type) => {
          return {
            value: payment_type.value.toString(),
            name: payment_type.name,
          };
        }),
        disabled: true,
      },
      {
        name: "profileCarrierInProject.credit_term",
        field_type: "text",
        field_label: "จำนวนวันเครดิตเทอม",
        disabled: true,
      },
      {
        name: "profileCarrierInProject.credit_length_date",
        field_type: "text",
        field_label: "รอบเวลาที่นับการใช้งาน",
        disabled: true,
      },
      {
        name: "profileCarrierInProject.credit_bill_date",
        field_type: "text",
        field_label: "วันที่วางรอบบิลของทุกเดือน",
        disabled: true,
      },
      {
        name: "profileCarrierInProject.credit_remark",
        field_type: "text",
        field_label: "หมายเหตุเครดิต",
        disabled: true,
      },
      {
        name: "project_reminder_shipper",
        field_type: "select",
        multiSelect: true,
        field_label: "วันที่ต้องการแจ้งเตือนวางบิล",
        options: ReminderDate,
        disabled: true,
      },
      {
        name: "profileCarrierInProject.contract_file",
        field_type: "upload",
        field_label: "ไฟล์สัญญา",
        disabled: true,
      },
      {
        name: "profileCarrierInProject.quotation_file",
        field_type: "upload",
        field_label: "ไฟล์ใบเสนอราคา",
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลผู้ขนส่งในโครงการ`}</h4>
        </div>

        {form3_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn4 = () => {
    const form4_menu = [
      {
        name: "profileCarrierInProject.credit_limit",
        field_type: "text",
        field_label: "วงเงินเครดิตที่ให้วีมูฟฯ",
        disabled: true,
      },
      {
        name: "credit_used",
        field_type: "text",
        field_label: "วงเงินเครดิตที่ใช้ไป",
        disabled: true,
      },
      {
        name: "credit_balance",
        field_type: "text",
        field_label: "วงเงินเครดิตคงเหลือ",
        disabled: true,
      },
      {
        name: "all_work",
        field_type: "text",
        field_label: "จำนวนการรับงานทั้งหมด",
        disabled: true,
      },
      {
        name: "work_in_progress",
        field_type: "text",
        field_label: "จำนวนงานที่กำลังดำเนินการ",
        disabled: true,
      },
      {
        name: "work_cancel",
        field_type: "text",
        field_label: "จำนวนงานที่ยกเลิก",
        disabled: true,
      },
      {
        name: "work_success",
        field_type: "text",
        field_label: "จำนวนงานที่เสร็จสิ้น",
        disabled: true,
      },
      {
        name: "all_work_unpaid",
        field_type: "text",
        field_label: "จำนวนเงินค้างชำระรวม",
        disabled: true,
      },
    ];
    // รอทำตาม สุดสวย แห่งทีม Tech
    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลผู้ขนส่งในโครงการ`}</h4>
        </div>

        {form4_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          // handleUpdateProfileJoinCarrier();
        })}
      >
        {renderColumn1()}
        {renderColumn2()}
        {renderColumn3()}
        {renderColumn4()}

        {/* <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        /> */}

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default ParticipateProjectInfo;
