// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Map_route_optimiza_map_container__QtqPA {
  width: 100%;
  background-color: white;
  height: max-content;
  border-radius: 20px;
}

.Map_list_route_optimiza_container__ba6Zl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 20px 40px;
  margin: 5px 0px;
}

.Map_list_route_optimiza_container__ba6Zl .Map_text_container__XLVy9 {
  text-align: center;
}

.Map_list_route_optimiza_container__ba6Zl .Map_route_container__SGHNL {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.Map_list_route_optimiza_container__ba6Zl .Map_wrapper__JLx7x {
  display: flex;
  align-items: center;
}

.Map_point_wrapper__w9tWc {
  background-color: #121f43;
  color: white;
  font-size: 20px;
  font-weight: 500px;
  border-radius: 50%;
  padding: 0px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/work/RouteOptimiza/Map.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".route_optimiza_map_container {\n  width: 100%;\n  background-color: white;\n  height: max-content;\n  border-radius: 20px;\n}\n\n.list_route_optimiza_container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: row wrap;\n  gap: 20px 40px;\n  margin: 5px 0px;\n}\n\n.list_route_optimiza_container .text_container {\n  text-align: center;\n}\n\n.list_route_optimiza_container .route_container {\n  display: flex;\n  justify-content: center;\n  flex-flow: row wrap;\n}\n\n.list_route_optimiza_container .wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.point_wrapper {\n  background-color: #121f43;\n  color: white;\n  font-size: 20px;\n  font-weight: 500px;\n  border-radius: 50%;\n  padding: 0px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"route_optimiza_map_container": `Map_route_optimiza_map_container__QtqPA`,
	"list_route_optimiza_container": `Map_list_route_optimiza_container__ba6Zl`,
	"text_container": `Map_text_container__XLVy9`,
	"route_container": `Map_route_container__SGHNL`,
	"wrapper": `Map_wrapper__JLx7x`,
	"point_wrapper": `Map_point_wrapper__w9tWc`
};
export default ___CSS_LOADER_EXPORT___;
