import React, { useEffect, useState } from "react";
import styles from "../../TableTrade.module.css";
import { Modal, Typography } from "@mui/material";
import FieldDatePicker from "components/field/FieldDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import ButtonCustom from "components/ButtonCustom";
import {
  createTradeProject,
  editTradeProject,
  getProjectTradeById,
  paidTransport,
} from "utils/api/trade/trade";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import { getAllProductTradeType } from "utils/api/masterData/tradeMaster";
const ModalProjectCustomer = (props) => {
  const [allProduct, setAllProduct] = useState([]);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (props.type === "edit") {
      getProject(props?.idProject);
    }
  }, [props]);
  useEffect(() => {
    getProduct();
  }, []);
  const getProduct = async () => {
    await getAllProductTradeType({ type: "list_select" })
      .then((res) => {
        const results = res?.data?.results;
        setAllProduct(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProject = async (id) => {
    try {
      await getProjectTradeById(id).then((res) => {
        const results = res.data.results;
        console.log("result project id", results);
        setValue("project_name", results?.project_name ?? null);
        setValue(
          "product_trade_type_id",
          results?.product_trade_type_id ?? null
        );
        setValue("credit_term", results?.credit_term ?? null);
        setValue("active", results?.active ?? null);
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    if (props?.type === "create") {
      if (props?.idCustomer) {
        await createTradeProject({
          ...data,
          trade_customer_id: props?.idCustomer,
        })
          .then((res) => {
            if (res.data.code === 1000) {
              props?.handleClose();
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (props?.type === "edit") {
      if (props?.idProject) {
        await editTradeProject(props?.idProject, data)
          .then((res) => {
            if (res.data.code === 1000) {
              props?.handleClose();
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={() => {
          props?.handleClose();
          reset();
        }}
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={() => {
                props?.handleClose();
                reset();
              }}
            />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props.type === "create"
                ? "เพิ่มรายชื่อโครงการซื้อขาย"
                : "แก้ไขรายชื่อโครงการซื้อขาย"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="project_name"
                rules={{
                  required:
                    props?.type === "create" ? "กรุณาใส่ชื่อโครงการ" : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldInput
                      label="ชื่อโครงการ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.project_name}
                      helperText={
                        errors.project_name ? errors.project_name.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="product_trade_type_id"
                rules={{
                  required:
                    props?.type === "create"
                      ? "กรุณาเลือกประเภทสินค้าซื้อขาย"
                      : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ประเภทสินค้าซื้อขาย"
                      options={allProduct}
                      onChange={onChange}
                      value={value}
                      error={!!errors.product_trade_type_id}
                      errorText={
                        errors.product_trade_type_id
                          ? errors.product_trade_type_id.message
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="credit_term"
                rules={{
                  required:
                    props?.type === "create"
                      ? "กรุณากรอกระยะเวลาเครดิต"
                      : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ระยะเวลาเครดิต"
                      onChange={onChange}
                      value={value}
                      type="number"
                      error={!!errors.credit_term}
                      helperText={
                        errors.credit_term ? errors.credit_term.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              {props?.type === "edit" && (
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="สถานะใช้งาน"
                        options={[
                          { value: true, name: "ใช้งาน" },
                          { value: false, name: "ระงับ" },
                        ]}
                        onChange={onChange}
                        value={value}
                        error={!!errors.active}
                        errorText={errors.active ? errors.active.message : ""}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={() => {
                    props.handleClose();
                    reset();
                  }}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalProjectCustomer;
