import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Avatar,
  Grid,
  Box,
  TextField,
  InputAdornment,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Drawer,
  Typography,
  useTheme,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import styles from "./TableAdmin.module.css";

import {
  Add,
  AddCircle,
  Search,
  RotateRight,
  FilterAlt,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

//component in project
import AlertModal from "components/modal/AlertModal";
import ModalCreateAdmin from "./ModalCreateAdmin";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/LoadingBackground";
// import Table from "components/table/MainTable";
import TableActiveIcon from "components/tableActiveIcon";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import Logo from "assets/image/WemoveLogo.svg";
import ButtonCustom from "components/ButtonCustom";
import helper from "utils/helper";
import FieldUpload from "components/field/FieldUpload";
import { getAllAdmin as getAllAdminAPI } from "utils/api/admin/admin";
import { subMenuAdmin } from "utils/submenuoptions";
import PaginationComponent from "components/pagination/Pagination";
import TableCustom from "components/table/TableCustom";
import moment from "moment";
import { useCookies } from "react-cookie";
import SelectRowValue from "components/button/SelectRowValue";

const TableAdmin = () => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [data, setData] = useState([]);
  const [openModalCreateAdmin, setOpenModalCreateAdmin] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [loading, setLoading] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));

  const [filterAdmin, setFilterAdmin] = useState({
    sort_by: "updated_at",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });

  const breadcrumbs = [
    {
      label: "จัดการผู้ดูแลระบบ",
      path: null,
    },
  ];

  const columns = [
    {
      id: "created_at",
      label: "วันที่สร้างรายการ",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.created_at),
    },
    {
      id: "updated_at",
      label: "วันที่แก้ไขข้อมูลล่าสุด",
      align: "center",
      format: (value) => helper.ToConvertDateTimeForTable(value.updated_at),
    },
    {
      //   id: "firstname",
      label: "ชื่อผู้ดูแลระบบ",
      align: "left",
      format: (value) => `${value.firstname} ${value.lastname}`,
    },
    {
      id: "username",
      label: "ชื่อผู้ใช้งานผู้ดูแลระบบ",
      align: "left",
    },
    {
      id: "admin_role_type",
      label: "บทบาทผู้ดูแลระบบ",
      align: "center",
      format: (value) => `${value.admin_role_type.name}`,
    },
    {
      id: "active",
      label: "สถานะใช้งาน",
      align: "center",
      format: (value) => {
        return <TableActiveIcon active_status={value.active} />;
      },
    },
  ];

  useEffect(() => {
    getAllAdmin(filterAdmin);
  }, [filterAdmin.page, filterAdmin.per_page]);

  const handleRowContextMenu = (event, id) => {
    event.preventDefault();
    window.open(`admin/${id}`, "_blank"); // Open link in new tab
  };

  const handleRowClick = (event, id) => {
    if (event.button === 0) {
      // Left-click: Open link in new tab
      navigate(`/admin/${id}`);
    }
  };

  const getAllAdmin = async (filter) => {
    setLoading(true);
    await getAllAdminAPI(filter)
      .then((response) => {
        console.log("response all admin", response);
        if (response.data.code === 1000) {
          setLoading(false);
          setAdvanceFilter(false);
          setData(response.data.data);
          setFilterAdmin({ ...filterAdmin, total: response.data.total });
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log("error", error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filterAdmin.sort_by}
              onChange={(val) =>
                setFilterAdmin({ ...filterAdmin, sort_by: val })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterAdmin.start_date ?? dateFrom,
                end_date: filterAdmin.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterAdmin({
                  ...filterAdmin,
                  start_date: new Date(val[0]).toISOString(),
                  end_date: new Date(val[1]).toISOString(),
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[{ value: "name", name: "ชื่อผู้ดูแลระบบ" }]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"name"}
              value={filterAdmin.search_by}
              onChange={(val) =>
                setFilterAdmin({ ...filterAdmin, search_by: val })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterAdmin.search_val ? filterAdmin.search_val : ""}
              onChange={(val) =>
                setFilterAdmin({ ...filterAdmin, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filter = { ...filterAdmin, page: 1 };
                getAllAdmin(filter);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilterAdmin({ ...filterAdmin, per_page: val });
              }}
            />
            {/* <FieldSelect
              name={"จำนวนแถว"}
              options={[
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 20, name: "20" },
                { value: 50, name: "50" },
                { value: 100, name: "100" },
              ]}
              defaultValue={5}
              value={filterAdmin.per_page}
              onChange={(val) => {
                setFilterAdmin({ ...filterAdmin, per_page: val });
              }}
              closePaddingBottom={true}
            /> */}
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate("/");
        }}
      />
      <Drawer
        anchor="right"
        open={advanceFilter}
        onClose={() => setAdvanceFilter(false)}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filterAdmin.sort_by}
              onChange={(val) =>
                setFilterAdmin({ ...filterAdmin, sort_by: val })
              }
            />
            <FieldDateRangePicker
              className={styles.dateSelect}
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterAdmin.start_date ?? dateFrom,
                end_date: filterAdmin.end_date ?? dateTo,
              }}
              style={{ zIndex: 101 }}
              onChange={(val) =>
                setFilterAdmin({
                  ...filterAdmin,
                  start_date: new Date(val[0]).toISOString(),
                  end_date: new Date(val[1]).toISOString(),
                })
              }
            />
            <FieldSelect
              name={"บทบาทผู้ดูแล"}
              options={[
                { value: "", name: "ทั้งหมด" },
                { value: 0, name: "SuperAdmin" },
                { value: 1, name: "ฝ่ายขาย" },
                { value: 2, name: "ฝ่ายบริการลูกค้า" },
                { value: 3, name: "ฝ่ายปฏิบัติการ" },
                { value: 4, name: "ฝ่ายการเงินและบัญชี" },
                { value: 5, name: "ฝ่ายเทคโนโลยีสารสนเทศ" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filterAdmin.role}
              onChange={(val) => setFilterAdmin({ ...filterAdmin, role: val })}
            />
            <FieldSelect
              name={"สถานะผู้ดูแล"}
              options={[
                { value: "", name: "ทั้งหมด" },
                { value: true, name: "ใช้งาน" },
                { value: false, name: "ไม่ใช้งาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filterAdmin.active}
              onChange={(val) =>
                setFilterAdmin({ ...filterAdmin, active: val })
              }
            />
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() => getAllAdmin(filterAdmin)}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Container>
        <SubMenu
          menus={subMenuAdmin.map((menu) => {
            return {
              label: menu.label,
              path: menu.path,
              active: menu.label === `จัดการผู้ดูแลระบบ` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
        />
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={data}
            pagination={filterAdmin}
            setPagination={(val) => {
              setFilterAdmin({
                ...filterAdmin,
                ...val,
              });
            }}
            href={`/admin/`}
            loading={loading}
            // checkBox={true}
          />
        </div>

        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() => setOpenModalCreateAdmin(true)}
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>
      </Container>

      {openModalCreateAdmin ? (
        <ModalCreateAdmin
          open={openModalCreateAdmin}
          handleClose={() => setOpenModalCreateAdmin(false)}
          alertSuccess={() => {
            setSuccessAlert(true);
          }}
          alertError={(e) => {
            setErrorAlert(e);
          }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default TableAdmin;
