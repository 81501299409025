import helper from "utils/helper";
import instance from "../../axiosInstance";

export const getAllServiceArea = async (params = {}) => {
  try {
    return await instance.get(`api/master/service-area`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getServiceArea = async (id = null) => {
  try {
    return await instance.get(`api/master/service-area/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateServiceArea = async (id = null, body = {}) => {
  try {
    return await instance.patch(`api/master/service-area/${id}`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const downloadExcelServiceArea = async (params = {}) => {
  try {
    return await instance.get(`/api/master/service-area/download-excel`, {
      params: params,
      responseType: "arraybuffer",
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const uploadServiceAreaFromExcel = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/master/service-area/upload-service-area-from-excel`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
