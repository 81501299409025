import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import { Space, Tag } from "antd";
import TableActiveIcon from "components/tableActiveIcon";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FloatingButton from "components/button/FloatingButton";
import TableCustom from "components/table/TableCustom";
import SubMenu from "components/layout/SubMenu.js";
import FilterTab from "components/filterTab/FilterTab";
import { subMenuTruck } from "utils/submenuoptions";
import { getAllTruckCheckProject, getTruck } from "utils/api/truck/truck";
import CreateTruckCehckProjectModal from "./CreateTruckCehckProjectModal";
import helper from "../../utils/helper";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "project.project_code",
    label: "รหัสโครงการ",
    align: "center",
    format: (value) => {
      return value.project.project_code;
    },
  },
  {
    id: "project.name",
    label: "ชื่อโครงการ",
    align: "center",
    format: (value) => {
      return value.project.name;
    },
  },
  {
    id: "profile.company_name",
    label: "ชื่อโปรไฟล์",
    align: "center",
    format: (value) => {
      return value.profile.company_name;
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const TruckCheckProject = () => {
  const { user_id, profile_id, truck_id } = useParams();
  const location = useLocation();

  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `project_name`,
    search_val: ``,
    active: "all",
  };
  const [truckCheckProject, setTruckCheckProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [truckInfo, setTruckInfo] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});

  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });
    handleGetTruckInfo();

    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
  }) => {
    const params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      [filter.search_field]: filter.search_val,
      active:
        filter.active !== `all`
          ? filter.active === `true`
            ? true
            : false
          : null,
      page: page,
      per_page: per_page,
    };

    handleGetAllTruckCheckProject({
      page: page,
      per_page: per_page,
      new_params: params,
    });
    setParams(params);
  };

  const handleGetAllTruckCheckProject = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }
      const resp = await getAllTruckCheckProject(truck_id, new_params);
      setTruckCheckProject(resp.data.data.results);
      setPagination({
        page: page,
        per_page: per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllTruckCheckProject: ", error);
      setIsLoading(false);
    }
  };

  const handleGetTruckInfo = async () => {
    try {
      const resp = await getTruck(truck_id);
      setTruckInfo(resp.data.data.results);
    } catch (error) {
      console.log("error getTruckInfo: ", error);
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else if (field_type === `truck_accessories`) {
      const last_val = val[val.length - 1];
      if (last_val === -1) {
        val = [-1];
      } else {
        val = val.filter((item) => item !== -1);
      }
      setFilter({ ...filter, [field_type]: val });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    const user_code =
      truckInfo && truckInfo.profile && truckInfo.profile.user_info
        ? truckInfo.profile.user_info.user_code
        : ``;
    const _full_license_plate_head =
      truckInfo &&
      truckInfo.license_plate_head &&
      truckInfo.license_plate_head.full_license_plate
        ? truckInfo.license_plate_head.full_license_plate
        : ``;
    const _full_license_plate_tail =
      truckInfo &&
      truckInfo.license_plate_tail &&
      truckInfo.license_plate_tail.full_license_plate
        ? ` - ${truckInfo.license_plate_tail.full_license_plate}`
        : ``;
    // const prev_path = location.state?.prevPath;

    return (
      <SubMenu
        label={`การตรวจสภาพรถ`}
        // pathbackward={prev_path ? prev_path : -1}
        pathbackward={
          R.isNil(user_id) && R.isNil(profile_id)
            ? `/truck`
            : `/user/${user_id}/profile/${profile_id}/truck-in-profile`
        }
        menus={[
          {
            label: "การตรวจสภาพรถ",
            path: `#`,
            active: true,
          },
        ]}
        // stateNavigation={{
        //   prevPath: prev_path,
        // }}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label: user_code,
            path: null,
          },
          {
            label: "ข้อมูลรถในโปรไฟล์",
            path: null,
          },
          {
            label: `${_full_license_plate_head} ${_full_license_plate_tail}`,
            path: null,
          },
          {
            label: "การตรวจสภาพรถ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchFieldOption={{
          value: filter.search_field,
          options: [{ value: "project_name", name: "ชื่อโครงการ" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchValueOption={{
          onPressEnter: (field_type, val) => {
            handleGetDataByNewParams({ page: 1 });
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchButtonOption={{
          // onChange: (field_type, val) => {
          //   onChangeFilter(field_type, val);
          // },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetAllTruckCheckProject({
              page: 1,
              per_page: value,
            });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    return (
      <div>
        <FieldSelect
          name="กรองและจัดเรียงตาม"
          value={"updated_at"}
          options={[
            {
              value: "updated_at",
              name: "วันที่แก้ไขข้อมูลล่าสุด",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("updated_at", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldDateRangePicker
          name={`วันเริ่มต้น - วันสิ้นสุด`}
          popupClassName="FieldDateRangePicker-drawer-popup"
          value={{
            start_date: filter.start_date,
            end_date: filter.end_date,
          }}
          onChange={(val) => {
            onChangeFilter(`date_duration`, val);
          }}
          style={{ width: `100%`, marginBottom: 26 }}
        />

        <FieldSelect
          name="สถานะใช้งาน"
          value={filter.active}
          options={[
            {
              value: "all",
              name: "ทั้งหมด",
            },
            {
              value: "true",
              name: "ใช้งาน",
            },
            {
              value: "false",
              name: "ไม่ใช้งาน",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("active", val);
          }}
          style={{ width: `100%` }}
        />
      </div>
    );
  };

  const renderTable = () => {
    console.log("user_iduser_id", user_id);
    return (
      <TableCustom
        columns={columns}
        dataSource={truckCheckProject}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          handleGetAllTruckCheckProject({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        // href={`/truck/${truck_id}/truck-check-project/detail/`}
        href={
          R.isNil(user_id) && R.isNil(profile_id)
            ? `/truck/${truck_id}/truck-check-project/detail/`
            : `/user/${user_id}/profile/${profile_id}/truck-in-profile/${truck_id}/truck-check-project/detail/`
        }
      />
    );
  };

  return (
    <>
      <Container>
        {renderSubMenu()}
        {renderFilterTab()}
        {renderTable()}

        <FloatingButton
          button_type="add"
          onClick={() => {
            setOpenCreateModal(true);
          }}
        />
      </Container>

      <CreateTruckCehckProjectModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        updateTableData={async () => {
          handleGetAllTruckCheckProject({ page: 1 });
        }}
      />
    </>
  );
};

export default TruckCheckProject;
