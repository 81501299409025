import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import {
  getDriverTrainingProject,
  updateDriverTrainingProject,
} from "utils/api/user/user";
import SubMenu from "components/layout/SubMenu.js";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import helper from "utils/helper";
import styles from "./DriverTrainingProjectInfo.module.css";

const DriverTrainingProjectInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState(``);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetDriverTrainingProject();

    // eslint-disable-next-line
  }, []);

  const handleGetDriverTrainingProject = async () => {
    setIsLoading(true);
    try {
      const resp = await getDriverTrainingProject(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(data.project.project_code);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getDriverTrainingProject: ", error);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
      // navigate("/truck/truck-check-project");
    }
  };

  const handleUpdateDriverTrainingProject = async () => {
    setIsLoading(true);
    try {
      let data = {
        user_id: getValues().user.id,
        project_id: getValues().project.id,
        file: getValues().file,
        active: getValues().active === `true` ? true : false,
      };
      if (typeof data.file === `string`) {
        data = R.omit(["file"], data);
      }

      const update_resp = await updateDriverTrainingProject(id, data);
      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message
          ? error.response.data.description.message
          : error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderColumn1 = () => {
    const form1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          required: "กรุณาใส่สถานะใช้งาน",
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "project.name",
        field_type: "text",
        field_label: "ชื่อโครงการ",
        disabled: true,
      },
      {
        name: "project.profile.company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
      {
        name: "file",
        field_type: "upload",
        field_label: "หลักฐานการอบรมพนักงานขับรถ*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              if (
                !helper.isImageFile({ file: file }) &&
                !helper.isPdfFile({ file: file })
              ) {
                return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
              }
            }
            return true;
          },
        },
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {form1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดการอบรมพนักงานขับรถ`}
        // pathbackward={-1}
        pathbackward={location.pathname.split("/detail")[0]}
        menus={[
          {
            label: "รายละเอียดการอบรมพนักงานขับรถ",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label:
              getValues().user && getValues().user.user_code
                ? getValues().user.user_code
                : ``,
            path: null,
          },
          {
            label: "การอบรมพนักงานขับรถ",
            path: null,
          },
          {
            label:
              getValues().project && getValues().project.project_code
                ? getValues().project.project_code
                : ``,
            path: null,
          },
          {
            label: "รายละเอียดการอบรมพนักงานขับรถ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateDriverTrainingProject();
        })}
      >
        {renderColumn1()}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default DriverTrainingProjectInfo;
