import React from "react";
// import { Pagination as AntdPagination } from "antd";
import { Pagination as MuiPagination } from "@mui/material";
import "./styles.css";

const Pagination = ({
  pagination = {
    page: 1,
    per_page: 10,
    total: 100,
  },
  setPagination = () => {},
  loading = false,
}) => {
  // const onChange = (e: React.ChangeEvent<unknown>, page: number) => {
  //   setPagination({ ...pagination, page: page });
  // };
  const onChange = (e, page) => {
    setPagination({ ...pagination, page: page });
  };

  return (
    <div
      style={{
        display: `flex`,
        justifyContent: `center`,
        marginTop: 20,
      }}
    >
      <MuiPagination
        color="secondary"
        showFirstButton
        showLastButton
        count={Math.ceil(pagination.total / pagination.per_page)}
        page={pagination.page}
        onChange={onChange}
        disabled={loading === true ? true : false}
      />
    </div>
  );
};

export default Pagination;
