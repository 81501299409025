import { ArrowForwardIosOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectTruck from "./modalSelectTruck";
import ModalSelectProduct from "./modalSelectProduct";
import Swal from "sweetalert2";
import ModalWork from "./modalWork";
import Line from "components/layout/line";
import { Button, TextField } from "@mui/material";

const SelectRemark = (props) => {
  const [show, setShow] = useState(false);
  const { value, onChange = () => {}, is_detail = false } = props;

  const clickShow = () => {
    setShow(true);
  };
  const clickClose = () => {
    setShow(false);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          padding: "10px 0px 10px 0px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (!is_detail) clickShow();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("../../assets/image/work/remark.png")
            }
            height={40}
            alt={"bill"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div style={{ fontWeight: 500, opacity: value ? 1 : 0.3 }}>
            หมายเหตุ
          </div>
          <div style={{ fontSize: 13, opacity: value ? 1 : 0.3 }}>
            {value ? value : "เพื่ออธิบายให้คนขับเข้าใจมากยิ่งขึ้น (ไม่บังคับ)"}
          </div>
        </div>
        {is_detail ? null : (
          <div style={{ alignSelf: "center" }}>
            <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
          </div>
        )}
      </div>
      <ModalWork open={show} enableClose close={() => setShow(false)}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>หมายเหตุ</div>
          <Line />
          <TextField
            fullWidth
            id="standard-multiline-static"
            label="หมายเหตุ"
            value={value}
            defaultValue={value}
            multiline
            onChange={(val) => {
              onChange(val.target.value);
            }}
            rows={4}
            // defaultValue="Default Value"
            variant="standard"
          />
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
                onClick={() => {
                  setShow(false);
                  onChange("");
                }}
              >
                ย้อนกลับ
              </Button>
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  setShow(false);
                }}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
    </div>
  );
};
export default SelectRemark;
