import { Modal, Skeleton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./TableTrade.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import {
  getAllJobber,
  createTransport,
  editOrderTransport,
  getProjectTradeById,
} from "utils/api/trade/trade";
import { getTripStatus } from "utils/api/masterData/service";
import helper from "utils/helper";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Line from "components/layout/line";
import API from "utils/api/work/work";
import FieldAutoComplete from "components/field/FieldAutoComplete/index2";
let api = API.create();
const ModalTransport = (props) => {
  const defaultValue = props?.editData;
  console.log("defaultValue", defaultValue);
  const [listProject, setListProject] = useState([]);
  // const [listTradePayment, setListTradePayment] = useState([]);
  const [trackingDetail, setTrackingDetail] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [listJobber, setListJobber] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (props?.type === "edit") {
      setValue("order_code", defaultValue?.order_code);
      setValue("trade_destination", defaultValue?.trade_destination);
      setValue("destination_name", defaultValue?.destination_name);
      setValue("amount_per_trip", defaultValue?.amount_per_trip);
      setValue("sell_per_trip", defaultValue?.sell_per_trip);
      setValue("buy_per_trip", defaultValue?.buy_per_trip);
      setValue("profit_bath", defaultValue?.profit_bath);
      setValue("profit_percent", defaultValue?.profit_percent);
      setValue(
        "carrier_freight_before_tax",
        defaultValue?.carrier_freight_before_tax
      );
      setValue("cost_per_trip", defaultValue?.cost_per_trip);
    } else if (props?.type === "create") {
      setValue("order_code", props?.orderCode);
    }
  }, [props]);

  const sell_per_trip = watch("sell_per_trip");
  const buy_per_trip = watch("buy_per_trip");
  const profit_bath = watch("profit_bath");
  const carrier_freight_before_tax = watch("carrier_freight_before_tax");

  useEffect(() => {
    getTracking();
  }, [defaultValue]);

  useEffect(() => {
    if (sell_per_trip && buy_per_trip) {
      const cost =
        parseFloat(buy_per_trip || 0) +
        parseFloat(carrier_freight_before_tax || 0);
      const profit = parseFloat(sell_per_trip || 0) - parseFloat(cost || 0);
      setValue("cost_per_trip", cost);
      setValue("profit_bath", profit);
    }
  }, [sell_per_trip, buy_per_trip, carrier_freight_before_tax]);

  useEffect(() => {
    if (profit_bath !== undefined && buy_per_trip !== undefined) {
      console.log("profit_bath: ", profit_bath, "buy_per_trip: ", buy_per_trip);
      // const cost =
      //   parseFloat(buy_per_trip || 0) +
      //   parseFloat(carrier_freight_before_tax || 0);
      const percent =
        (parseFloat(profit_bath) / parseFloat(sell_per_trip)) * 100;
      setValue("profit_percent", percent.toFixed(2));
    }
  }, [profit_bath, buy_per_trip, sell_per_trip, carrier_freight_before_tax]);

  useEffect(() => {
    getJobber();
  }, []);

  const getJobber = async () => {
    await getAllJobber({ type: "list_select" })
      .then((res) => {
        if (res.data.code === 1000) {
          const results = res.data.results;
          setListJobber(
            results?.map((res) => ({ value: res.id, label: res.jobber_name }))
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const closeModal = () => {
    props?.handleClose();
    reset();
  };

  const getTracking = async () => {
    console.log(
      "getTracking",
      defaultValue?.tracking_code,
      defaultValue?.logistics_provider_type
    );
    if (
      defaultValue?.logistics_provider_type !== undefined &&
      defaultValue?.tracking_code !== ""
    ) {
      setLoadingDetail(true);
      await api
        .getTrackingFromCode({
          logistics_provider_type: defaultValue?.logistics_provider_type,
          tracking_code: defaultValue?.tracking_code,
        })
        .then((response) => {
          console.log("response", response);
          if (response.data?.results && response.data?.results.length > 0) {
            setTrackingDetail(response.data?.results.reverse());
          }
          setLoadingDetail(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingDetail(false);
        });
    }
  };

  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    if (props?.type === "edit") {
      console.log("Edit");
      await editOrderTransport(defaultValue?.id, data)
        .then((res) => {
          if (res.data.code === 1000) {
            closeModal();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (props?.orderId) {
      console.log("Add");
      await createTransport({ ...data, trade_order_id: props.orderId })
        .then((res) => {
          if (res.data.code === 1000) {
            closeModal();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Modal open={props?.open} onClose={closeModal} sx={{ zIndex: 1000 }}>
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon sx={{ color: "grey.main" }} onClick={closeModal} />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props?.type === "edit"
                ? "แก้ไขรายการจัดส่ง"
                : "เพิ่มรายการจัดส่ง"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="order_code"
                rules={{
                  required:
                    props?.type === "edit"
                      ? false
                      : "กรุณากรอกเลขที่ใบสั่งซื้อ",
                }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldInput
                      label="เลขที่ใบสั่งซื้อ"
                      onChange={onChange}
                      value={value}
                      error={!!errors.order_code}
                      helperText={
                        errors.order_code ? errors.order_code.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="destination_name"
                rules={{
                  required:
                    props?.type === "edit" ? false : "กรุณากรอกชื่อจุดส่ง",
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ชื่อจุดส่ง"
                      onChange={onChange}
                      value={value}
                      error={!!errors.destination_name}
                      helperText={
                        errors.destination_name
                          ? errors.destination_name.message
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="trade_destination"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="พิกัดจุดส่ง"
                      onChange={onChange}
                      value={value}
                      error={!!errors.trade_destination}
                      helperText={
                        errors.trade_destination
                          ? errors.trade_destination.message
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="amount_per_trip"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="จำนวนสินค้าที่ซื้อขายจริง"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.amount_per_trip}
                      helperText={
                        errors.amount_per_trip
                          ? errors.amount_per_trip.message
                          : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="buy_per_trip"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคาสินค้าที่ซื้อ (บาท)"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.buy_per_trip}
                      errorText={
                        errors.buy_per_trip ? errors.buy_per_trip.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="sell_per_trip"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคาสินค้าที่ขาย (บาท)"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.sell_per_trip}
                      errorText={
                        errors.sell_per_trip ? errors.sell_per_trip.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="carrier_freight_before_tax"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ราคาขนส่ง (บาท)"
                      onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.carrier_freight_before_tax}
                      errorText={
                        errors.carrier_freight_before_tax
                          ? errors.carrier_freight_before_tax.message
                          : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={defaultValue?.carrier_payment_status >= 3}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="cost_per_trip"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ต้นทุนรวม (บาท)"
                      // onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.cost_per_trip}
                      errorText={
                        errors.cost_per_trip ? errors.cost_per_trip.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="profit_bath"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="กำไร (บาท)"
                      //   onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      error={!!errors.profit_bath}
                      errorText={
                        errors.profit_bath ? errors.profit_bath.message : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="profit_percent"
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="กำไร %"
                      //   onChange={onChange}
                      value={value}
                      type="number"
                      alignRight={true}
                      endAdornment="%"
                      error={!!errors.profit_percent}
                      errorText={
                        errors.profit_percent
                          ? errors.profit_percent.message
                          : ""
                      }
                      style={{ width: "100%" }}
                      readOnly={true}
                    />
                  </div>
                )}
              />
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={closeModal}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                  disabled={
                    defaultValue?.id
                      ? defaultValue?.buyer_payment_status !== 1 ||
                        defaultValue?.seller_payment_status !== 1 ||
                        defaultValue?.carrier_payment_status > 2
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalTransport;
