import React, { useEffect, useRef, useState } from "react";
import { FormControl, Input, InputLabel, TextField } from "@mui/material";
import "./inputDisable.css";
const InputDisable = ({
  onChange,
  classNames,
  styles,
  label,
  error,
  value,
  type = "text",
  subFirst,
  subSec,
  opacity,
}) => {
  return (
    <div className={"inputDisable inputNormal"}>
      <FormControl variant="standard" autoFocus fullWidth margin="none">
        <InputLabel
          htmlFor="standard-adornment-shipper_id"
          color={error ? "error" : "primary"}
        >
          {opacity ? <div style={{ color: "#121f43" }}>{label}</div> : label}
        </InputLabel>
        <Input
          className={opacity ? "opacityColor" : ""}
          type={type}
          error={error}
          label={label}
          value={value}
          sx={{
            backgroundColor: "rgb(140,163,186,0.2)",
          }}
          onChange={onChange}
          //   editabled={false}
          disabled
        />
        <div className="inputNormalSub">
          <div>{subFirst}</div>
          <div>{subSec}</div>
        </div>
      </FormControl>
    </div>
  );
};

export default InputDisable;
