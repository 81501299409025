// SwipeComponent.js
import React, { useState } from "react";
import "./workPublicDetail.css";

const SwipeComponent = ({
  onSwipe = () => {},
  fullSwiped = false,
  minimized40 = false,
  headerContent = null,
  children,
}) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
    onSwipe();
  };

  return (
    <div
      className={`swipe-container ${
        !isMinimized
          ? fullSwiped
            ? "fullSwiped"
            : "swiped"
          : minimized40
          ? "minimized40"
          : "minimized"
      }`}
    >
      <div onClick={handleToggle}>
        <div
          style={{
            width: 50,
            height: 5,
            backgroundColor: "#8ca3ba",
            position: "absolute",
            top: 2.5,
            transform: "translate(-50%, -50%)",
            borderRadius: "0px 0px 10px 10px",
            left: "50%",
          }}
        ></div>
        <div>{headerContent}</div>
      </div>
      <div
        style={{
          overflow: "scroll",
          width: "100%",
          paddingTop: 20,
          paddingLeft: 40,
          paddingRight: 40,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SwipeComponent;
