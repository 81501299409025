import React, { useEffect } from "react";
import styles from "./ProductTradeType.module.css";
import { Modal, Typography } from "@mui/material";
import FieldDatePicker from "components/field/FieldDatePicker";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import ButtonCustom from "components/ButtonCustom";
import {
  createTradeProject,
  editTradeProject,
  getProjectTradeById,
  paidTransport,
} from "utils/api/trade/trade";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import {
  createProductTradeType,
  editProductTradeType,
} from "utils/api/masterData/tradeMaster";
const ModalProductTradeType = (props) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (props.type === "edit") {
      setValue("name", props?.data?.name ?? null);
      setValue("active", props?.data?.active ?? null);
    }
  }, [props]);
  const onSubmit = async (data) => {
    console.log("onSubmit", data);
    if (props?.type === "create") {
      await createProductTradeType(data)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (props?.type === "edit") {
      if (props?.data) {
        await editProductTradeType(props?.data?.id, data)
          .then((res) => {
            if (res.data.code === 1000) {
              props?.handleClose();
              reset();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={() => {
          props?.handleClose();
          reset();
        }}
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={() => {
                props?.handleClose();
                reset();
              }}
            />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props.type === "create"
                ? "เพิ่มข้อมูลประเภทสินค้าซื้อขาย"
                : "แก้ไขข้อมูลประเภทสินค้าซื้อขาย"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="name"
                rules={{
                  required:
                    props?.type === "create"
                      ? "กรุณากรอกชื่อประเภทสินค้าซื้อขาย"
                      : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldInput
                      label="ชื่อประเภทสินค้าซื้อขาย"
                      onChange={onChange}
                      value={value}
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              {props?.type === "edit" && (
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="สถานะใช้งาน"
                        options={[
                          { value: true, name: "ใช้งาน" },
                          { value: false, name: "ระงับ" },
                        ]}
                        onChange={onChange}
                        value={value}
                        error={!!errors.active}
                        errorText={errors.active ? errors.active.message : ""}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={() => {
                    props.handleClose();
                    reset();
                  }}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="บันทึก"
                  style={{ padding: 0 }}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalProductTradeType;
