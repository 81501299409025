import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { UploadFile } from "@mui/icons-material";
import FormModal from "components/modal/FormModal";
import FieldInput from "components/field/FieldInput";
import AlertModal from "components/modal/AlertModal";
import FieldUpload from "components/field/FieldUpload";
import TemplateForm from "components/templateForm/TemplateForm";
import Button from "components/button/Button";
import { getProjectByProjectCode } from "utils/api/project/project";
import { createDriverHealthProject } from "utils/api/user/user";
import helper from "utils/helper";

const CreateDriverHealthProjectModal = ({
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const { user_id } = useParams();
  const form_confim_btn = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const renderForm = () => {
    const form_menu = [
      {
        name: "project_code",
        field_type: "text",
        field_label: "รหัสโครงการ*",
        onBlur: () => {
          setValue(`project_id`, null);
          setValue(`project_name`, null);
          setValue(`company_name`, null);
        },
        rules: {
          required: "กรุณาใส่รหัสโครงการ",
        },
        error: errors[`project_code`] ? errors[`project_code`] : false,
        errorText: errors[`project_code`]
          ? errors[`project_code`].message
          : null,
      },
      {
        name: "project_name",
        field_type: "text",
        field_label: "ชื่อโครงการ",
        disabled: true,
      },
      {
        name: "company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
      {
        name: "file",
        field_type: "upload",
        field_label: "หลักฐานการอบรม*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              if (
                !helper.isImageFile({ file: file }) &&
                !helper.isPdfFile({ file: file })
              ) {
                return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
              }
            }
            return true;
          },
        },
      },
    ];

    return (
      <div>
        {form_menu.map((menu, index) => {
          return (
            <>
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
              />

              {menu.name === "project_code" && (
                <div
                  style={{
                    marginBottom: `23px`,
                  }}
                >
                  <Button
                    type={`button`}
                    onClick={() => {
                      handleGetProjectByProjectCode();
                    }}
                  >{`ค้นหา`}</Button>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const handleCreateDriverHealthProject = async () => {
    setIsLoading(true);

    try {
      let data = getValues();
      if (R.isNil(data.project_id)) {
        throw `กรุณาค้นหารหัสโครงการใหม่!`;
      }

      data = {
        user_id: user_id,
        project_id: data.project_id,
        file: data.file,
        active: true,
      };

      const create_resp = await createDriverHealthProject(data);
      if (create_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setOpen(false);
    } catch (error) {
      if (typeof error === `string`) {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error,
        });
      } else {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error.response.data.description,
        });
      }
      setOpen(false);
    }
  };

  const handleGetProjectByProjectCode = async () => {
    setIsLoading(true);
    try {
      const project_code = getValues().project_code
        ? getValues().project_code
        : null;
      if (!R.isNil(project_code) && !R.isEmpty(project_code)) {
        const resp = await getProjectByProjectCode(project_code);
        if (resp.data.code === 1000) {
          const data = resp.data.data.results;
          setValue(`project_id`, data.id);
          setValue(`project_name`, data.name);
          setValue(`company_name`, data.profile.company_name);

          clearErrors(`project_code`);
        }
      } else {
        throw `กรุณาใส่รหัสโครงการ`;
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.description
      ) {
        // setAlertModal({
        //   open: true,
        //   title: `บันทึกไม่สำเร็จ`,
        //   description: error.response.data.description,
        // });
        setError(`project_code`, {
          type: "error",
          message: error.response.data.description,
        });
      } else {
        // setAlertModal({
        //   open: true,
        //   title: `ค้นหาไม่สำเร็จ`,
        //   description: error,
        // });
      }

      setValue(`project_id`, null);
      setValue(`project_name`, null);
      setValue(`company_name`, null);
      // setOpen(false);
    }
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  return (
    <>
      <FormModal
        title={`เพิ่มการตรวจสุขภาพพนักงานขับรถ`}
        open={open}
        confirmTextBtn={"บันทึก"}
        cancelTextBtn={"ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          reset();
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handleCreateDriverHealthProject();
          })}
        >
          {renderForm()}

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateDriverHealthProjectModal;
