import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getFuelRateFormPTTByProvince } from "utils/api/masterData/fuelRate";

const CheckOilPrice = () => {
  function createData(province, district, price, date) {
    return { province, district, price, date };
  }
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("rowsrows", rows);
  }, [rows]);

  const getData = async () => {
    const params = [
      {
        province: "Phra Nakhon Si Ayutthaya",
        province_th: "พระนครศรีอยุธยา",
        district: "บางไทร",
      },
      {
        province: "Lampang",
        province_th: "ลําปาง",
        district: "แม่เมาะ",
      },
      {
        province: "Nakhon Ratchasima",
        province_th: "นครราชสีมา",
        district: "สีคิ้ว",
      },
      {
        province: "Chon Buri",
        province_th: "ชลบุรี",
        district: "บ้านบึง",
      },
      {
        province: "Rayong",
        province_th: "ระยอง",
        district: "เมืองระยอง",
      },
      {
        province: "Chachoengsao",
        province_th: "ฉะเชิงเทรา",
        district: "เมืองฉะเชิงเทรา",
      },
    ];

    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      const res = await getFuelRateFormPTTByProvince(param);
      console.log("getFuelRateFormPTTByProvince", res.data.data.results);
      const data = res.data.data.results;
      const dieselB7 = data.filter((e) => e.product === "ดีเซล");
      setRows((prevRows) => [
        ...prevRows,
        createData(
          param.province_th,
          dieselB7[0].location,
          dieselB7[0].price,
          dieselB7[0].price_date
        ),
      ]);
    }
  };

  return (
    <React.Fragment>
      <div style={{ padding: 97 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={require("assets/image/logo_pttor.png")}
            height={"40px"}
            alt="pttor logo"
            resizeMode="contain"
          />
          <h1 style={{ textAlign: "center" }}>ราคาน้ำมัน </h1>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "#121f43" }}>
              <TableRow>
                <TableCell align="center" style={{ color: "white" }}>
                  จังหวัด
                </TableCell>
                <TableCell align="center" style={{ color: "white" }}>
                  อำเภอ
                </TableCell>
                <TableCell align="center" style={{ color: "white" }}>
                  ดีเซล
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.province}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.province}</TableCell>
                  <TableCell align="center">{row.district}</TableCell>
                  <TableCell align="center">
                    <div style={{ fontWeight: 500, lineHeight: 1 }}>
                      {parseFloat(row.price).toFixed(2)}
                    </div>
                    <div style={{ fontSize: 12 }}>{row.date}</div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </React.Fragment>
  );
};

export default CheckOilPrice;
