import instance from "../../axiosInstance";

export const getAllCountry = async (params = {}) => {
  try {
    return await instance.get(`api/master/country`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllZone = async (params = {}) => {
  try {
    return await instance.get(`api/master/zone`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllProvince = async (params = {}) => {
  try {
    return await instance.get(`api/master/province`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getProvince = async (zone_id = null) => {
  try {
    return await instance.get(`api/master/province/${zone_id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllDistrict = async (params = {}) => {
  try {
    return await instance.get(`api/master/district`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getDistrict = async (province_id = null) => {
  try {
    return await instance.get(`api/master/district/${province_id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllSubDistrict = async (params = {}) => {
  try {
    return await instance.get(`api/master/subdistrict`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getSubDistrict = async (district_id = null) => {
  try {
    return await instance.get(`api/master/subdistrict/${district_id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
