import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const CheckApp = () => {
  const { wid } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const appUrl = `wemoveapp://work/${wid}`;
    window.location.href = appUrl;
    setTimeout(() => {
      redirectToStore();
    }, 2000);
  }, []);

  const redirectToStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      isMobile
        ? (window.location.href = "http://onelink.to/3dpe9h")
        : (window.location.href =
            "https://apps.apple.com/th/app/wemovedrive/id1602456992");
    } else if (/android/i.test(userAgent)) {
      isMobile
        ? (window.location.href = "http://onelink.to/3dpe9h")
        : (window.location.href =
            "https://play.google.com/store/apps/details?id=co.th.wemove.app&pli=1");
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=co.th.wemove.app&pli=1";
    }
  };
  return <div></div>;
};

export default CheckApp;
