import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/loading/LoadingBackground";
import { Modal, Typography } from "@mui/material";
import styles from "../TableAllProjectInProfile.module.css";
import CloseIcon from "@mui/icons-material/Close";
import ButtonCustom from "components/ButtonCustom";
import { useForm, Controller } from "react-hook-form";
import { findCompanyName as findCompanyNameAPI } from "utils/api/profile/profile";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import FieldUpload from "components/field/FieldUpload";
import { PaymentType, ReminderDate } from "utils/enum";
import { addCarrierInProject } from "utils/api/project/project";
import { useCookies } from "react-cookie";

const ModalAddCarrierInProject = (props) => {
  const { project_id } = useParams();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [userCode, setUserCode] = useState("");

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();
  const carrier_payment_type = watch("carrier_payment_type");

  const SearchCompany = async (user_code) => {
    setLoading(true);
    await findCompanyNameAPI(user_code)
      .then((response) => {
        console.log("usercode", response.data.data);
        if (response.data.code === 1000) {
          setLoading(false);
          clearErrors("profile");
          setValue("profile", response.data.data);
        }
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data) {
          setValue("profile", "");
          setError("profile", {
            type: 400,
            message: error.response.data.description,
          });
        } else {
          clearErrors("profile");
        }
      });
  };

  const handlePrevious = () => {
    if (step == 1) {
      props?.handleClose();
    } else if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = async (data) => {
    console.log("data handleNext", data);
    if (step < 2) {
      setStep(step + 1);
    }
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setLoading(true);
    const GForm = new FormData();
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        GForm.append(key, JSON.stringify(data[key]));
      } else if (key === "profile" && data["profile"] !== undefined) {
        GForm.append("profile_id", data[key].id);
      } else if (data[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, data[key]);
      }
    });

    GForm.delete("profile");
    for (const [key, value] of GForm.entries()) {
      console.log(`${key}: ${value}`);
    }
    await addCarrierInProject(project_id, GForm)
      .then((response) => {
        console.log("response", response);
        if (response.data.code === 1000) {
          props.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  const renderFormPage1 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
            เพิ่มผู้ขนส่งในโครงการ
          </Typography>
        </div>
        <div style={{ width: "100%", paddingTop: "20px" }}>
          <FieldInput
            label="รหัสผู้ใช้งานหลัก*"
            subLabel={`(บังคับถ้าเพิ่มผู้ใช้รอง)`}
            style={{ width: "-webkit-fill-available" }}
            value={userCode}
            onChange={(e) => setUserCode(e.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <ButtonCustom
            name="ค้นหา"
            onClick={() => SearchCompany(userCode)}
            fullWidth={true}
          />
        </div>
        <Controller
          name="profile"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <FieldInput
                label="ชื่อโปรไฟล์"
                style={{ width: "-webkit-fill-available" }}
                onChange={onChange}
                readOnly={true}
                value={value?.company_name ? value?.company_name : ""}
                error={!!errors.profile}
                helperText={errors.profile ? "ไม่พบโปรไฟล์" : ""}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="carrier_payment_type"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldSelect
                name="ประเภทการรับเงินของผู้ขนส่ง*"
                nameDescription="(ค่าเริ่มต้น: เงินสด)"
                descriptionUnderField="กรณีเงินสดจะไม่สามารถระบุข้อมูลในช่องที่บังคับเฉพาะเครดิตได้"
                options={PaymentType}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_term"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldInput
                label="จำนวนวันเครดิตเทอม*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                type="number"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_length_date"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldInput
                label="รอบเวลาที่นับการใช้งาน*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_bill_date"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldInput
                label="วันที่วางบิลของทุกเดือน*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_remark"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldInput
                label="หมายเหตุเครดิต"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const renderFormPage2 = () => {
    return (
      <div>
        <div className={styles.titleCard}>
          <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
            เพิ่มผู้ขนส่งในโครงการ
          </Typography>
        </div>
        <Controller
          control={control}
          name="reminder_date"
          render={({ field: { value, onChange } }) => (
            <div style={{ paddingTop: "20px" }}>
              <FieldSelect
                name="วันที่ต้องการเตือนวางบิล*"
                nameDescription="(วันที่ต้องการเตือนว่างบิล)"
                options={ReminderDate}
                defaultValue={[]}
                multiSelect={true}
                value={value}
                onChange={onChange}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="contract_file"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldUpload
                name="ไฟล์สัญญา"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="quotation_file"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldUpload
                name="ไฟล์ใบเสนอราคา"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_limit"
          render={({ field: { value, onChange } }) => (
            <div>
              <FieldInput
                label="วงเงินเครดิตที่ให้วีมูฟฯ*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
      </div>
    );
  };
  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form>
              {step === 1 && renderFormPage1()}
              {step === 2 && renderFormPage2()}
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={handlePrevious}
                  type={"cancel"}
                />
              </div>
              {step < 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name="ถัดไป"
                    onClick={handleSubmit(handleNext)}
                    fullWidth={true}
                  />
                </div>
              )}
              {step === 2 && (
                <div style={{ flex: 0.5 }}>
                  <ButtonCustom
                    name={"บันทึก"}
                    // type="submit"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddCarrierInProject;
