import { useState } from "react";
import {
  Modal,
  Typography,
  Box,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import FieldSelect from "components/field/FieldSelect";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import * as R from "ramda";
import { uploadUser } from "utils/api/user/user";
import { notification } from "antd";

const ModalUploadFile = ({ open = false, setOpen = () => {} }) => {
  const [is_main, setIs_main] = useState(true);
  const [user_excel, setUser_excel] = useState("");
  const [error, setError] = useState(false);

  const renderXBtn = () => (
    <IconButton
      onClick={() => {
        onCloseModal();
      }}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M8.73655 7.5L14.9548 0.30138C15.059 0.181749 14.9714 0 14.8103 0H12.92C12.8086 0 12.702 0.0483128 12.6286 0.131135L7.5 6.06902L2.37142 0.131135C2.30035 0.0483128 2.19375 0 2.08005 0H0.189699C0.0286162 0 -0.0590318 0.181749 0.0451981 0.30138L6.26346 7.5L0.0451981 14.6986C0.0218496 14.7253 0.00687072 14.7579 0.00203912 14.7926C-0.00279249 14.8273 0.00272616 14.8626 0.0179404 14.8943C0.0331547 14.926 0.0574256 14.9529 0.0878709 14.9716C0.118316 14.9903 0.153657 15.0002 0.189699 15H2.08005C2.19139 15 2.29798 14.9517 2.37142 14.8689L7.5 8.93098L12.6286 14.8689C12.6996 14.9517 12.8062 15 12.92 15H14.8103C14.9714 15 15.059 14.8182 14.9548 14.6986L8.73655 7.5Z"
          fill="#8CA3BA"
        />
      </svg>
    </IconButton>
  );

  const onCloseModal = () => {
    setIs_main(true);
    setUser_excel("");
    setError(false);
    setOpen(false);
  };

  const onSubmit = async () => {
    let error = false;
    if (R.isEmpty(user_excel)) {
      error = true;
    } else {
      const fileName = user_excel.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension !== "xls" && fileExtension !== "xlsx") {
        error = true;
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "กรุณาอัพโหลดไฟล์ Excel เท่านั้น"
        );
      }
    }

    if (error) {
      setError(true);
    } else {
      setError(false);
      const formData = new FormData();
      formData.append("is_main", is_main);
      formData.append("user_excel", user_excel);
      try {
        const res = await uploadUser(formData);
        if (res) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            "การอัพโหลดไฟล์สำเร็จ"
          );
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          error.response.data.description
        );
      }
    }
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  return (
    <>
      {contextHolder}
      <Modal open={open}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "20px",
            background: "#FFF",
            boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
            padding: "25px",
            height: "600px",
            minWidth: "400px",
          }}
        >
          {renderXBtn()}
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#8CA3BA",
                textAlign: "center",
                fontFamily: "Kanit",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              }}
            >
              อัปโหลดผู้ใช้งาน
            </Typography>
            <hr />
          </Box>
          <div
            style={{
              overflowY: "auto",
              height: "72vh",
              padding: "10px 0px",
            }}
          >
            <Grid>
              <FieldSelect
                name={"ประเภทผู้ใช้งาน"}
                options={[
                  { value: true, name: "ผู้ใช้งานหลัก" },
                  { value: false, name: "ผู้ใช้งานรอง" },
                ]}
                value={is_main}
                onChange={(val) => setIs_main(val)}
              />
            </Grid>
            <Grid>
              <FieldUpload
                name={"ไฟล์อัพโหลด*"}
                icon={<UploadFile />}
                value={user_excel}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(val) => setUser_excel(val)}
                error={error}
                textError={error ? "จำเป็นต้องระบุ และเป็นไฟล์ EXCEL" : ""}
              />
            </Grid>
            <Grid className={`text-center`}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px solid #8CA3BA",
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                อัพโหลด
              </Button>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalUploadFile;
