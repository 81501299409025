import instance from "../../axiosInstance";

export const downloadTemplateWork = async (project_fuel_rate_id = null) => {
  try {
    return await instance.get(
      `/api/template-work/download-template-work/${project_fuel_rate_id}`,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
