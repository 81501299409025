import React, { useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  useTheme,
  FormHelperText,
} from "@mui/material";
import { formLabelClasses } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
const FieldUpload = ({
  name = ``,
  nameDescription = ``,
  icon = ``,
  onChange = () => {},
  className = ``,
  style = {},
  error = false,
  textError = ``,
  value = ``,
  url = ``,
  readOnly = false,
  closePaddingBottom = false,
  descriptionUnderField = false,
  accept = ``,
}) => {
  const theme = useTheme();
  const inputFile = useRef(null);
  const [linkFile, setLinkFile] = useState(null);
  const [isLabelShrunk, setIsLabelShrunk] = useState(false);
  const navigate = useNavigate();

  const onClick = (event) => {
    inputFile.current.click();
    // Prevent event propagation to the input field
    event.stopPropagation();
  };

  return (
    <div
      style={{
        // pointerEvents: readOnly ? "none" : "",
        paddingBottom:
          error || closePaddingBottom || descriptionUnderField ? 0 : "23px",
        display: "flex",
        flexDirection: "column",
        // marginBottom: 23,
      }}
    >
      <input
        style={{ display: "none" }}
        ref={inputFile}
        accept={accept}
        onChange={(e) => {
          onChange(e.target.files[0]);
          setLinkFile(
            e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : ""
          );
        }}
        type="file"
        // disabled={value ? true : false}
      />
      <FormControl
        className={className}
        variant="standard"
        sx={{
          ...style,
          pointerEvents: readOnly && !value ? "none" : "",
          bgcolor: readOnly ? "rgba(140, 163, 186, 0.20)" : "white",
        }}
      >
        <InputLabel
          htmlFor="standard-adornment-password"
          sx={{
            color: error ? "error.main" : "grey.main",
            fontWeight: 500,
            lineHeight: nameDescription ? 1 : "",
            "& p": {
              fontSize: "10px",
            },
            [`&.${formLabelClasses.focused}`]: {
              marginTop: 0,
              "& p": {
                fontSize: "10px",
              },
            },
            [`&.${formLabelClasses.filled}`]: {
              marginTop: `0 !important`,
              "& p": {
                fontSize: "10px",
              },
            },
            marginTop: nameDescription ? "-10px" : "",
            padding: "0 14px",
          }}
        >
          {name}
          <br />
          {nameDescription && (
            <p style={{ margin: "5px 0" }}>{nameDescription}</p>
          )}
        </InputLabel>
        <Input
          // id="standard-adornment-password"
          type="text"
          sx={{
            // width: "-webkit-fill-available",
            "& .MuiInput-input": {
              padding: value ? "4px 14px 5px 14px" : "",
            },
          }}
          value={value ? "คลิกเพื่อเปิด" : ""}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                sx={{ color: error ? "error.main" : "grey.main" }}
                onClick={readOnly ? undefined : onClick}
              >
                {icon ? icon : <UploadFile />}
              </IconButton>
            </InputAdornment>
          }
          onClick={(event) => {
            if (value?.toString().includes("blob:")) {
              window.open(value);
            } else {
              value && value instanceof File
                ? (() => {
                    const fileURL = URL.createObjectURL(value);
                    window.open(fileURL, "_blank");
                  })()
                : value || (readOnly && value)
                ? window.open(`http://${value}`, "_blank")
                : onClick(event);
            }

            // value && value instanceof File
            //   ? (() => {
            //       const fileURL = URL.createObjectURL(value);
            //       window.open(fileURL, "_blank");
            //     })()
            //   : value || (readOnly && value)
            //   ? window.open(`http://${value}`, "_blank")
            //   : onClick(event);
          }}
          readOnly
          error={error}
        />
        {/* {error && textError ? (
          <p
            style={{
              fontSize: "12px",
              fontFamily: "Kanit",
              lineHeight: 1.66,
              color: theme.palette.error.main,
              marginTop: "3px",
              marginBottom: 0,
              padding: "0 14px",
            }}
          >
            {textError}
          </p>
        ) : (
          ""
        )} */}
      </FormControl>
      {textError ? (
        <FormHelperText
          sx={{
            color: "error.main",
            padding: "0 14px",
          }}
        >
          {textError}
        </FormHelperText>
      ) : descriptionUnderField ? (
        <FormHelperText
          sx={{
            color: value ? "primary.main" : "grey.main",
            fontSize: "11px",
            padding: "0 14px",
          }}
        >
          {descriptionUnderField}
        </FormHelperText>
      ) : (
        ""
      )}
    </div>
  );
};

export default FieldUpload;
