import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import { getWemoveVersion } from "utils/api/masterData/appVersion";
import helper from "utils/helper";
import styles from "./WemoveVersionInfo.module.css";

const WemoveVersionInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetWemoveVersion();

    // eslint-disable-next-line
  }, []);

  const handleGetWemoveVersion = async () => {
    setIsLoading(true);
    try {
      const resp = await getWemoveVersion(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error handleGetWemoveVersion: ", error);
      navigate("/master-data/app-version/wemove-version");
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`อัพเดทเวอร์ชัน WeMove`}
        pathbackward={`/master-data/app-version/wemove-version`}
        menus={[
          {
            label: "อัพเดทเวอร์ชัน WeMove",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "อัพเดทเวอร์ชัน WeMove",
            path: null,
          },
          {
            label: getValues().version_code,
            path: null,
          },
        ]}
      />
    );
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        disabled: true,
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "detail",
        field_type: "text",
        field_label: "คำอธิบาย",
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{getValues().version_code}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          // updateTruckType();
        })}
      >
        {renderColumn1()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default WemoveVersionInfo;
