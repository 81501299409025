import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import {
  getRouteProvince,
  updateRouteProvince,
} from "utils/api/masterData/routeProvince";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./RouteProvinceInfo.module.css";

const RouteProvinceInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetRouteProvince();

    // eslint-disable-next-line
  }, []);

  const handleGetRouteProvince = async () => {
    setIsLoading(true);

    try {
      const resp = await getRouteProvince(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(getValues().load_province_name);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getRouteProvince: ", error);
      navigate("/master-data/route-province");
    }
  };

  const handleUpdateRouteProvince = async () => {
    setIsLoading(true);

    try {
      let _values = getValues();

      _values = {
        ..._values,
        active: _values.active === `true` ? true : false,
        factor: parseFloat(parseFloat(_values.factor).toFixed(2)),
      };

      const update_resp = await updateRouteProvince(id, _values);
      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดเส้นทางจังหวัดที่ให้บริการ (FTL-Factor)`}
        pathbackward={`/master-data/route-province`}
        menus={[
          {
            label: "เส้นทางจังหวัดที่ให้บริการ (FTL-Factor)",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "เส้นทางจังหวัดที่ให้บริการ (FTL-Factor)",
            path: null,
          },
          {
            label: `${getValues().load_province_name} - ${
              getValues().unload_province_name
            }`,
            path: null,
          },
          {
            label: "รายละเอียดเส้นทางจังหวัดที่ให้บริการ (FTL-Factor)",
            path: null,
          },
        ]}
      />
    );
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะใช้งาน";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "load_province_name",
        field_type: "text",
        field_label: "จังหวัดจุดรับสินค้า",
        disabled: true,
      },
      {
        name: "unload_province_name",
        field_type: "text",
        field_label: "จังหวัดจุดส่งสินค้า",
        disabled: true,
      },
      {
        name: "factor",
        field_type: "text",
        field_label: "Factor*",
        onChange: () => {
          //   updateVolumeField();
        },
        rules: {
          required: "กรุณาใส่ Factor",
          validate: {
            isNumber: (value) =>
              helper.RegexValidate("number").test(value) ||
              "ระบุเป็นตัวเลขได้เท่านั้น",
            isTwoDecimal: (value) =>
              helper.RegexValidate("float_2_decimal").test(value) ||
              "ทศนิยมใส่ได้ 2 ตำแหน่งเท่านั้น",
          },
        },
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateRouteProvince();
        })}
      >
        {renderColumn1()}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default RouteProvinceInfo;
