import React, { useState, useEffect } from "react";
import {
  useParams,
  // useLocation,
  useNavigate,
} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import {
  getTruckCheckProject,
  updateTruckCheckProject,
} from "../../../utils/api/truck/truck";
import SubMenu from "components/layout/SubMenu.js";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import helper from "utils/helper";
import styles from "./TruckCheckProjectInfo.module.css";

const TruckCheckProjectInfo = () => {
  const { user_id, profile_id, truck_id, id } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState(``);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetTruckCheckProject();

    // eslint-disable-next-line
  }, []);

  const handleGetTruckCheckProject = async () => {
    setIsLoading(true);
    try {
      const resp = await getTruckCheckProject(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(data.project.project_code);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getTruckCheckProject: ", error);
      // navigate("/truck/truck-check-project");
    }
  };

  const handleUpdateTruckCheckProject = async () => {
    setIsLoading(true);
    try {
      let data = {
        truck_id: getValues().truck.id,
        project_id: getValues().project.id,
        file: getValues().file,
        active: getValues().active === `true` ? true : false,
      };
      if (typeof data.file === `string`) {
        data = R.omit(["file"], data);
      }

      const update_resp = await updateTruckCheckProject(id, data);
      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message
          ? error.response.data.description.message
          : error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderColumn1 = () => {
    const form1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          required: "กรุณาใส่สถานะใช้งาน",
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "project.name",
        field_type: "text",
        field_label: "ชื่อโครงการ",
        disabled: true,
      },
      {
        name: "project.profile.company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
      {
        name: "file",
        field_type: "upload",
        field_label: "หลักฐานการตรวจสภาพรถ*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              if (
                !helper.isImageFile({ file: file }) &&
                !helper.isPdfFile({ file: file })
              ) {
                return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
              }
            }
            return true;
          },
        },
        // error:
        //   errors[`truck_image_left`] && errors[`truck_image_inside`][`image`]
        //     ? true
        //     : false,
        // helperText:
        //   errors[`truck_image_left`] && errors[`truck_image_inside`][`image`]
        //     ? errors[`truck_image_left`][`image`][`message`]
        //     : false,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {form1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    const _full_license_plate_head =
      getValues().truck && getValues().truck.license_plate_head
        ? getValues().truck.license_plate_head
        : ``;
    const _full_license_plate_tail =
      getValues().truck && getValues().truck.license_plate_tail
        ? ` - ${getValues().truck.license_plate_tail}`
        : ``;
    // const prev_path = location.state?.prevPath;
    console.log("user_id", user_id);

    return (
      <>
        <SubMenu
          label={`รายละเอียดการตรวจสภาพรถ`}
          // pathbackward={`/truck/${truck_id}/truck-check-project`}
          pathbackward={
            R.isNil(user_id) && R.isNil(profile_id)
              ? `/truck/${truck_id}/truck-check-project`
              : `/user/${user_id}/profile/${profile_id}/truck-in-profile/${truck_id}/truck-check-project`
          }
          menus={[
            {
              label: "รายละเอียดการตรวจสภาพรถ",
              path: `#`,
              active: true,
            },
          ]}
          breadcrumbs={[
            {
              label: "ข้อมูลผู้ใช้งาน",
              path: null,
            },
            {
              label:
                getValues().project &&
                getValues().project.profile &&
                getValues().project.profile.user_info
                  ? getValues().project.profile.user_info.user_code
                  : ``,
              path: null,
            },
            {
              label: "ข้อมูลรถในโปรไฟล์",
              path: null,
            },
            {
              label: `${_full_license_plate_head} ${_full_license_plate_tail}`,
              path: null,
            },
            {
              label: "การตรวจสภาพรถ",
              path: null,
            },
            {
              label:
                getValues().project && getValues().project.project_code
                  ? getValues().project.project_code
                  : ``,
              path: null,
            },
            {
              label: "รายละเอียดการตรวจสภาพรถ",
              path: null,
            },
          ]}
        />
      </>
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateTruckCheckProject();
        })}
      >
        {renderColumn1()}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default TruckCheckProjectInfo;
