import { merge } from "lodash";

//project import
import ListItemIcon from "./ListItemIcon";
import ListItemButton from "./ListItemButton";
import ListItem from "./ListItem";
import Toolbar from "./Toolbar";
import TextField from "./TextField";
import Checkbox from "./Checkbox";

export default function ComponentsOverride(theme) {
  return merge(
    ListItemIcon(),
    ListItemButton(),
    ListItem(),
    Toolbar(),
    TextField(theme),
    Checkbox(theme)
  );
}
