import React, { useEffect, useState } from "react";
import { LongdoMap, map, longdo } from "./LongdoMapSec";
import "../../pages/work/PostWork.css";
import HelperMap from "utils/helperMap";
import helper from "utils/helper";
import ENV from "utils/environment";
export default function MapWithCall(props) {
  // console.log("MapWithCall", props);
  const { call, marker, driver, map_key } = props;
  const initMap = () => {};
  const [driverMarker, setDriverMarker] = useState();
  useEffect(() => {
    if (call && call.length > 0) {
      route();
    }
  }, [call]);

  useEffect(() => {
    if (marker && marker.length > 0) {
      setMarker();
    }
  }, [marker]);
  useEffect(() => {
    if (driver) {
      setDriver();
    }
  }, [driver]);
  const setMarker = async () => {
    for (const { element, index } of marker.map((element, index) => ({
      element,
      index,
    }))) {
      console.log("marker", element);
      await map.Overlays.add(element);
    }
  };
  const setDriver = async () => {
    if (driverMarker) {
      driverMarker.move({ lon: driver.long, lat: driver.lat }, true);
    } else {
      let marker = await HelperMap.renderMaker({
        ...driver,
        type: "driver",
      });
      await map.Overlays.add(marker);
      // console.log("marker", marker);
      setDriverMarker(marker);
    }
  };
  const route = async () => {
    await map.Overlays.clear();
    await map.Route.clear();
    // console.log("here");
    let tmpBound = {
      minLon: null,
      minLat: null,
      maxLon: null,
      maxLat: null,
    };
    let bound;
    // await map.Route.add(call[0]);
    for (const { element, index } of call.map((element, index) => ({
      element,
      index,
    }))) {
      await map.Route.add(element);
      await map.Overlays.add(element);
      bound = await HelperMap.getBound(
        {
          lat: element.location().lat,
          long: element.location().lon,
        },
        tmpBound
      );
    }
    // console.log("bound", bound);
    if (call.length > 1) {
      await map.bound(bound);
      await map.zoom(parseInt(map.zoom()) - 1, true);
    } else {
      await map.location(call[0].location(), true);
    }
    await map.Route.line("road", { lineColor: "#121f43" });
    // await map.bound(tmpBound);
    await map.Route.mode(longdo.RouteMode.Cost);
    await map.Route.enableRoute(longdo.RouteType.Tollway, false);
    await map.Route.label(longdo.RouteLabel.Hide, true);
    await map.Route.search();
  };
  return (
    // <div>
    <LongdoMap
      id={"post-work-main"}
      callback={initMap()}
      map_key={ENV.MAP_KEY[ENV.ENV].KEY_2}
    />
    // </div>
  );
}
