import instance from "../../axiosInstance";
import helper from "utils/helper";

export const getAllPrivacyPolicy = async (params = {}) => {
  try {
    return await instance.get(`api/master/privacy-policy`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getPrivacyPolicy = async (id = null) => {
  try {
    return await instance.get(`api/master/privacy-policy/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createPrivacyPolicy = async (body = {}) => {
  const form_data = helper.ConvertJsonToFormData(body);

  try {
    return await instance.post(`api/master/privacy-policy/`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
