import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import { getFuelRate } from "../../../../utils/api/masterData/fuelRate";
import SubMenu from "components/layout/SubMenu.js";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import helper from "utils/helper";
import styles from "./FuelRateInfo.module.css";

const FuelRateInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    getfuelRateData();

    // eslint-disable-next-line
  }, []);

  const getfuelRateData = async () => {
    setIsLoading(true);
    try {
      const resp = await getFuelRate(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getfuelRate: ", error);
      navigate("/master-data/fuel-rate");
    }
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        disabled: true,
        // rules: {
        //   validate: (value) => {
        //     if (R.isEmpty(value)) {
        //       return "กรุณาเลือกสถานะใช้งาน";
        //     }
        //     return true;
        //   },
        // },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "end_date",
        field_type: "datePicker",
        field_label: "วันเวลาสิ้นสุดการใช้งาน*",
        subLabel: "(ระบบจะไม่แสดงจนกว่าจะมีรายการล่าสุดเพิ่มเข้ามา)",
        helperText:
          "วันเวลาที่ Inactive จะเท่ากับวันเวลาที่เริ่มใช้งานของรายการถัดไป",
        disabled: true,
        showTime: false,
      },
      {
        name: "start_date",
        field_type: "datePicker",
        field_label: "วันเวลาเริ่มต้นการใช้งาน*",
        subLabel: "(ค่าเริ่มต้น: 00.00 น. ของวันถัดไป)",
        disabled: true,
        showTime: false,
      },
      {
        name: "rate",
        field_type: "text",
        field_label: "อัตรานํ้ามัน*",
        disabled: true,
        // rules: {
        //   required: "กรุณาใส่อัตรานํ้ามัน",
        //   pattern: {
        //     value: helper.RegexValidate(`number`),
        //     message: "ระบุเป็นตัวเลขได้เท่านั้น",
        //   },
        // },
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{Number(getValues().rate).toFixed(2)}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดอัตรานํ้ามัน`}
        pathbackward={`/master-data/fuel-rate`}
        menus={[
          {
            label: "รายละเอียดอัตรานํ้ามัน",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "อัตรานํ้ามัน",
            path: null,
          },
          {
            label: getValues().rate,
            path: null,
          },
          {
            label: "รายละเอียดอัตรานํ้ามัน",
            path: null,
          },
        ]}
      />
    );
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          // updateTruckType();
        })}
      >
        {renderColumn1()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default FuelRateInfo;
