import instance from "../axiosInstance";

export const getDashbaordWork = async (params = "") => {
  return await instance.get(`api/dashboard/work`, {
    params: params,
  });
};

export const getDashbaordIncome = async (params = "") => {
  return await instance.get(`api/dashboard/income`, {
    params: params,
  });
};

export const getDashbaordProfile = async (params = "") => {
  return await instance.get(`api/dashboard/profile`, {
    params: params,
  });
};

export const getDashbaordTruck = async (params = "") => {
  return await instance.get(`api/dashboard/truck`, {
    params: params,
  });
};

export const getDashbaordKPI = async (params = "") => {
  return await instance.get(`api/dashboard/kpi`, {
    params: params,
  });
};

export const getDashbaordIcon = async (data) => {
  return await instance.post(`api/dashboard/icon`, data);
};
