import SubMenu from "components/layout/SubMenu.js";
import SubMenuCustom from "components/layout/SubMenu.js/SubMenuCustom";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import "./PostWork.css";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
  Grid,
  InputAdornment,
  Drawer,
} from "@mui/material";
import CloseDrawer from "../../assets/icons/closeDrawer.svg";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import InputDisable from "components/input/inputDisable";
import ButtonSwitch from "components/input/buttonSwitch";
import SelectLocation from "components/work/selectLocation";
import Line from "components/layout/line";
import Loading from "components/loading/LoadingBackground";

import SelectTruck from "components/work/selectTruck";
import SelectProduct from "components/work/selectProduct";
import SelectService from "components/work/selectService";
import SelectDateTime from "components/work/selectDateTime";
import SelectBilling from "components/work/selectBilling";
import SelectRemark from "components/work/selectRemark";
import SelectPostName from "components/work/selectPostName";
import SelectFavCarrier from "components/work/selectFavCarrier";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InputNormal from "components/input/inputNormal";
import Swal from "sweetalert2";
import InputSelect from "components/input/inputSelect";
import HelperMap from "utils/helperMap";
import { getTruckType } from "utils/api/masterData/truckType";
import masterProduct from "utils/api/masterData/productType";
import masterProvice from "utils/api/masterData/provice";
import ModalWork from "components/work/modalWork";
import helper from "utils/helper";
import InputUpload from "components/input/inputUpload";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import io from "socket.io-client";
import { styled } from "@mui/material/styles";
import TableCustom from "components/table/TableCustom";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import {
  FilterAlt,
  RotateRight,
  Search,
  FileDownload,
  FileUpload,
} from "@mui/icons-material";
import moment from "moment";
import ButtonCustom from "components/ButtonCustom";
import API from "../../utils/api/work/work";
import ModalUpdateStatus from "components/work/modalUpdateStatus";
import TableCustomWork from "components/work/TableCustomWork";
import { getAllZone } from "utils/api/masterData/gis";
import CustomModal from "components/modal/CustomModal";
import FieldUpload from "components/field/FieldUpload";
import SelectRowValue from "components/button/SelectRowValue";
const api = API.create();
const menus = [
  {
    label: "ข้อมูลงาน",
    path: "/work",
    active: true,
  },
  {
    label: "ติดตามงานทั้งระบบ",
    path: "/work-tracking",
  },
];

const breadcrumbs = [
  {
    label: "ข้อมูลงาน",
    path: null,
  },
];

const columns = [
  {
    id: "createdAt",
    label: <label style={{ fontSize: 12 }}>วันที่สร้างรายการ</label>,
    align: "center",
    //   width: "13%",
    format: (value) => (
      <div>
        <label style={{ fontSize: 12 }}>
          {helper.ToConvertDateTimeForTable(value.createdAt)}
        </label>
        <br />
        <label style={{ fontSize: 12, color: "#27CE88" }}>
          {value.work_status_id !== 1 &&
            value?.accept_work_date &&
            helper.ToConvertDateTimeForTable(value?.accept_work_date)}
        </label>
      </div>
    ),
  },
  {
    id: "work_code",
    label: <label style={{ fontSize: 12 }}>เลขที่งาน</label>,
    align: "center",
    //   width: "13%",
    format: (value) => (
      <label style={{ fontSize: 12 }}>{value.work_code}</label>
    ),
  },
  {
    id: "work_name",
    label: <label style={{ fontSize: 12 }}>ชื่อประกาศ</label>,
    align: "center",
    //   width: "13%",
    format: (value) => (
      <label className="ellipsis-work-name" style={{ fontSize: 12 }}>
        {value.work_name}
      </label>
    ),
  },
  {
    id: "company_name",
    label: <label style={{ fontSize: 12 }}>ชื่อโปรไฟล์ลูกค้า</label>,

    align: "center",
    //   width: "13%",
    format: (value) => (
      <label className="ellipsis-work-name" style={{ fontSize: 12 }}>
        {value.shipper.company_name}
      </label>
    ),
  },
  {
    id: "project_name",
    label: <label style={{ fontSize: 12 }}>ชื่อโครงการ</label>,

    align: "center",
    //   width: "13%",
    format: (value) => (
      <label style={{ fontSize: 12 }}>{value.project.name}</label>
    ),
  },
  {
    id: "load_place",
    label: <label style={{ fontSize: 12 }}>จุดรับสินค้า</label>,

    align: "center",
    //   width: "13%",
    format: (value) => {
      let text = "";
      let unload_place = value.workLocations.filter(
        (e) => e.location_type === 0
      );
      let route = 0;
      unload_place.forEach((e, i) => {
        if (i === 0) {
          text += e.province;
        } else {
          route++;
        }
      });
      if (route > 0) {
        text += "และอีก " + route + " จุด";
      }
      return <label style={{ fontSize: 12 }}>{text}</label>;
    },
  },
  {
    id: "unload_place",
    label: <label style={{ fontSize: 12 }}>จุดส่งสินค้า</label>,

    align: "center",
    //   width: "13%",
    format: (value) => {
      let text = "";
      let unload_place = value.workLocations.filter(
        (e) => e.location_type === 1
      );
      let route = 0;
      unload_place.forEach((e, i) => {
        if (i === 0) {
          text += e.province;
        } else {
          route++;
        }
      });
      if (route > 0) {
        text += "และอีก " + route + " จุด";
      }
      return <label style={{ fontSize: 12 }}>{text}</label>;
    },
  },
  {
    id: "product_type",
    label: <label style={{ fontSize: 12 }}>ประเภทสินค้า</label>,
    align: "center",
    //   width: "13%",
    format: (value) => {
      let text = "";
      let unload_place = value.workLocations.filter(
        (e) => e.location_type === 0
      );
      let prod = 0;
      unload_place.forEach((e, i) => {
        e.workLocationProducts.forEach((ele, index) => {
          if (i === 0) {
            text += ele.product.name;
          } else {
            prod++;
          }
        });
      });
      if (prod > 0) {
        text += "และอีก " + prod + " ประเภท";
      }
      return <label style={{ fontSize: 12 }}>{text}</label>;
    },
  },
  {
    id: "truck_type",
    label: <label style={{ fontSize: 12 }}>ประเภทรถ</label>,

    align: "center",
    //   width: "13%",
    format: (value) => (
      <label style={{ fontSize: 12 }}>{value.truck_type.name}</label>
    ),
  },

  {
    id: "service",
    label: <label style={{ fontSize: 12 }}>บริการเสริม</label>,

    align: "center",
    //   width: "13%",
    format: (value) => {
      let text = "";
      if (value.has_document_return) {
        text += "มีเอกสารนำกลับ ";
      }
      if (value.has_labor) {
        if (value.labor_load_amount && value.labor_load_amount > 0) {
          text += "คนงานยกสินค้าขึ้น " + value.labor_load_amount + " คน ";
        }
        if (value.labor_unload_amount && value.labor_unload_amount > 0) {
          text += "คนงานยกสินค้าลง " + value.labor_unload_amount + " คน ";
        }
      }
      if (text === "") {
        text = "ไม่มีบริการเสริม";
      }
      return <label style={{ fontSize: 12 }}>{text}</label>;
    },
  },
  {
    id: "work_status",
    label: <label style={{ fontSize: 12 }}>สถานะงาน</label>,

    align: "center",
    //   width: "13%",
    format: (value) => (
      <label
        style={{
          fontSize: 12,
          color:
            value?.work_status.id === 1
              ? "#7E57C2"
              : value?.work_status.id >= 2 && value?.work_status.id <= 10
              ? "#42A5F5"
              : value?.work_status.id >= 11 && value?.work_status.id <= 14
              ? "#FFC675"
              : value?.work_status.id === 15
              ? "#27CE88"
              : value?.work_status.id >= 17 && value?.work_status.id <= 19
              ? "#8CA3BA"
              : value?.work_status.id >= 20 && value?.work_status.id <= 21
              ? "#FF3D00"
              : "",
        }}
      >
        {value.work_status.name}
      </label>
    ),
  },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 40,
  borderRadius: 10,
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "white",
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#121f43" : "#308fe8",
  },
}));

export default function AllWork() {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 5,
    page: 1,
  });
  const [showModal, setShowModal] = useState(false);
  const [workData, setWorkData] = useState([]);
  const [carrierId, setCarrierId] = useState();
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [fileDocumentNumber, setFileDocumentNumber] = useState(null);

  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [truckType, setTruckType] = useState();
  const [productType, setProductType] = useState();
  const [province, setProvice] = useState([]);
  const [region, setRegion] = useState([]);
  const [workStatus, setWorkStatus] = useState([]);
  const [filterPerPage, setFilterPerPage] = useState([
    { value: "5", name: "5" },
  ]);
  const [filter, setFilter] = useState({
    order_by: "created_at",
    order_by_value: 1,
    datetime_from: "",
    datetime_to: "",
    profile_type: null,
    search_value: "",
    search_type: "company_name",
    load_place: null,
    unload_place: null,
    load_region: null,
    unload_region: null,
    product_type: null,
    truck_type: null,
    addon_type: null,
    status_type: null,
    createable_by_type: null,
  });

  useEffect(() => {
    if (!truckType) {
      getTruck();
    }
    if (!productType) {
      getProduct();
    }
    getRegion();
    getProvince();
    getStatus();
  }, []);
  useEffect(() => {
    getWork(pagination);
  }, []);
  useEffect(() => {
    console.log(filter);
  }, [filter]);

  const getStatus = async () => {
    api
      .getWorkStatus()
      .then((response) => {
        console.log(response);
        setWorkStatus(
          response.data.results.map((ele) => {
            return { ...ele, name: ele.name, value: ele.id };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getTruck = async () => {
    let truck = await getTruckType({
      sort_field: "order_number",
      sort_order: 1,
    });
    // console.log(truck);
    let mapPv = truck.data.data.results.map((ele) => {
      return { ...ele, name: ele.name, value: ele.id };
    });
    setTruckType(mapPv);
  };
  const getRegion = async () => {
    let rg = await getAllZone();
    let mapRg = rg.data.data.results.map((ele) => {
      return { ...ele, item: ele.name, value: ele.id };
    });
    console.log("mapRg", mapRg);
    setRegion(mapRg);
  };
  const getProvince = async () => {
    let pv = await masterProvice.Province();
    let mapPv = pv.data.data.results.map((ele) => {
      return { ...ele, item: ele.name, value: ele.name };
    });
    // console.log(mapPv);
    setProvice(mapPv);
  };
  const getProduct = async () => {
    let product = await masterProduct.ProductType();
    // console.log(product);
    let mapPv = product.data.data.results.map((ele) => {
      return { ...ele, name: ele.name, value: ele.id };
    });
    setProductType(mapPv);
  };

  const downloadTemplate = async () => {
    const download_response = await api.downloadTemplateDocumentNumber();

    helper.downloadFile({
      api_response: download_response,
    });
  };

  const handleUploadFile = async (data) => {
    setLoading(true);
    const GForm = new FormData();
    GForm.append("file", data);
    await api
      .uploadDocumentNumber(GForm)
      .then((response) => {
        if (response.data.code === 1000) {
          Swal.fire({
            title: "อัพโหลดสําเร็จ",
            icon: "success",
          });
          setLoading(false);
          setShowModalUpload(false);
          setRefresh(true);
          setFileDocumentNumber(null);
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: error.response?.data?.description ?? "กรุณาลองใหม่ภายหลัง",
          icon: "error",
        });
        setLoading(false);
        setShowModalUpload(false);
        setFileDocumentNumber(null);
        setRefresh(true);
        setLoading(false);
      });
  };
  const getWork = (filterPage = pagination) => {
    setLoading(true);
    let body = filter;
    body = { ...body, ...filterPage };
    Object.keys(body).forEach((key) => {
      if (body[key] === undefined) {
        delete body[key];
      } else if (!body[key]) {
        delete body[key];
      }
    });
    // console.log(body);
    api
      .getWork(body)
      .then((response) => {
        setLoading(false);
        console.log(response.data.results);
        setData(response.data.results);
        let PerPage = [{ value: "5", name: "5" }];
        if (response.data.total > 5) {
          PerPage.push({ value: "10", name: "10" });
        }
        if (response.data.total > 10) {
          PerPage.push({ value: "15", name: "15" });
        }
        if (response.data.total > 15) {
          PerPage.push({ value: "20", name: "20" });
        }
        if (response.data.total > 20) {
          PerPage.push({ value: "50", name: "50" });
        }
        if (response.data.total > 50) {
          PerPage.push({ value: "100", name: "100" });
        }
        if (response.data.total > 100) {
          PerPage.push({ value: "250", name: "250" });
        }
        if (response.data.total > 250) {
          PerPage.push({ value: "500", name: "500" });
        }
        if (response.data.total > 500) {
          PerPage.push({ value: "1000", name: "1000" });
        }
        setFilterPerPage(PerPage);
        setPagination({
          page: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        setLoading(false);

        setRefresh(false);
      })
      .catch((e) => {
        setLoading(false);

        console.log(e);
      });
  };
  useEffect(() => {
    if (refresh) {
      getWork();
    }
  }, [refresh]);
  return (
    <div className="post-work-page">
      {loading && <Loading />}
      <div className="post-work-body">
        <div className="post-work-body-flex">
          <div style={{ width: 400 }}>
            <SubMenu menus={menus} />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <SubMenu breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div
          style={{
            borderRadius: "10px",
            backgroundColor: "white",
            padding: "15px 20px 10px 20px",
            boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
            zIndex: 2,
            flexGrow: 1,
          }}
        >
          <Grid container rowSpacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={2.2} className="gridItem">
              <FieldSelect
                name={"กรองและจัดเรียงตาม"}
                options={[
                  { value: "created_at", name: "วันที่สร้างรายการ" },
                  { value: "accept_work_date", name: "วันที่จับคู่งาน" },
                ]}
                style={{
                  width: "-webkit-fill-available",
                }}
                defaultValue={filter.order_by}
                value={filter.order_by}
                onChange={(val) => {
                  setFilter({ ...filter, order_by: val });
                  // getWork(pagination);
                }}
                closePaddingBottom={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.4} className="gridItem">
              <FieldDateRangePicker
                className="dateSelect"
                name={`วันเริ่มต้น - วันสิ้นสุด`}
                value={{
                  start_date: filter.datetime_from ?? "",
                  end_date: filter.datetime_to ?? "",
                }}
                style={{ width: "-webkit-fill-available" }}
                onChange={(val) => {
                  setFilter({
                    ...filter,
                    datetime_from: val[0],
                    datetime_to: val[1],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.4} className="gridItem">
              <FieldSelect
                name={"ค้นหาจาก"}
                options={[
                  { value: "company_name", name: "ชื่อโปรไฟล์ผู้ประกาศงาน" },
                  {
                    value: "company_name_carrier",
                    name: "ชื่อโปรไฟล์ผู้รับงาน",
                  },
                  {
                    value: "project_name",
                    name: "ชื่อโครงการ",
                  },
                  { value: "firstname", name: "ชื่อผู้ประกาศหรือรับงาน" },
                  { value: "work_code", name: "เลขที่งาน" },
                  { value: "work_name", name: "ชื่อประกาศ" },
                  {
                    value: "ref_customer_code_1",
                    name: "CS เลขที่เอกสารส่งมอบสินค้า",
                  },
                  {
                    value: "ref_customer_code_2",
                    name: "CS วันที่ในเอกสาร (เอกสารนำกลับ)",
                  },
                  {
                    value: "ref_customer_code_3",
                    name: "CS น้ำหนัก / จำนวนสินค้า",
                  },
                  { value: "ref_customer_code_4", name: "CS เลขที่ Shipment" },
                  {
                    value: "ref_customer_code_5",
                    name: "CS ชื่อร้านค้าที่ส่งสินค้า",
                  },
                  {
                    value: "ref_customer_code_6",
                    name: "OP วันที่ในเอกสาร PO / PR",
                  },
                  {
                    value: "ref_customer_code_7",
                    name: "OP เลขที่ในเอกสาร PO / PR",
                  },
                  { value: "ref_customer_code_8", name: "OP เรทราคาน้ำมัน" },
                  {
                    value: "ref_customer_code_9",
                    name: "OP น้ำหนักที่ลูกค้าแจ้ง",
                  },
                  { value: "ref_customer_code_10", name: "OP อื่น ๆ" },
                ]}
                style={{
                  width: "-webkit-fill-available",
                }}
                defaultValue={filter.search_type}
                value={filter.search_type}
                onChange={(val) => {
                  setFilter({ ...filter, search_type: val });
                }}
                closePaddingBottom={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2.4} className="gridItem">
              <TextField
                id="search_val"
                variant="standard"
                style={{
                  paddingTop: "16px",
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={filter.search_value}
                onChange={(val) => {
                  setFilter({ ...filter, search_value: val.target.value });
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
              justifyContent="center"
              xs={12}
              sm={6}
              md={6}
              lg={2.6}
              className="gridItem"
            >
              <Avatar
                variant="rounded"
                sx={{
                  width: "40px",
                  height: "40px",
                  // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                  bgcolor: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  getWork({ ...pagination, page: 1 });
                }}
              >
                <Search sx={{ color: "secondary.main", fontSize: 30 }} />
              </Avatar>
              <Avatar
                variant="rounded"
                sx={{
                  width: "40px",
                  height: "40px",
                  // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                  bgcolor: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (workData.length > 0) setShowModal(true);
                  // Reset Filter
                  // setFilter({
                  //   order_by: "created_at",
                  //   order_by_value: 1,
                  //   datetime_from: null,
                  //   datetime_to: null,
                  //   profile_type: null,
                  //   search_value: "",
                  //   search_type: "firstname",
                  //   load_place: null,
                  //   unload_place: null,
                  //   product_type: null,
                  //   truck_type: null,
                  //   addon_type: null,
                  //   status_type: null,
                  // });
                  // setPagination({
                  //   total: 0,
                  //   per_page: 5,
                  //   page: 1,
                  // });
                  // getWork({
                  //   total: 0,
                  //   per_page: 5,
                  //   page: 1,
                  // });
                }}
              >
                <RotateRight sx={{ color: "secondary.main", fontSize: 30 }} />
              </Avatar>
              <Avatar
                variant="rounded"
                sx={{
                  width: 40,
                  height: 40,
                  // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,

                  filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                  bgcolor: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  //Download template
                  downloadTemplate();
                }}
              >
                <FileDownload sx={{ color: "secondary.main", fontSize: 30 }} />
              </Avatar>
              <Avatar
                variant="rounded"
                sx={{
                  width: 40,
                  height: 40,
                  // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                  bgcolor: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  //Upload template
                  setShowModalUpload(true);
                }}
              >
                <FileUpload sx={{ color: "secondary.main", fontSize: 30 }} />
              </Avatar>
              <div>
                <SelectRowValue
                  onSelect={(val) => {
                    setPagination({ ...pagination, per_page: val });
                    getWork({ ...pagination, per_page: val });
                  }}
                  width={40}
                  height={40}
                />
                {/* <FieldSelect
                  name={"จำนวนแถว"}
                  options={filterPerPage}
                  style={{
                    // width: "-webkit-fill-available",
                    width: 80,
                  }}
                  defaultValue={"5"}
                  value={pagination.per_page}
                  onChange={(val) => {
                    setPagination({ ...pagination, per_page: val });
                    getWork({ ...pagination, per_page: val });
                  }}
                  closePaddingBottom={true}
                /> */}
              </div>
              <Avatar
                variant="rounded"
                sx={{
                  width: 40,
                  height: 40,
                  // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                  filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                  bgcolor: "white",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAdvanceFilter(true);
                }}
              >
                <FilterAlt sx={{ color: "secondary.main", fontSize: 30 }} />
              </Avatar>
            </Grid>
          </Grid>
        </div>
        <div className="work-body-flex all-work">
          <div style={{ flex: 1 }}>
            <TableCustomWork
              columns={columns}
              dataSource={data}
              pagination={pagination}
              setPagination={(val) => {
                setPagination(val);
                getWork(val);
              }}
              //   onSelectedRow={(val) => console.log(val)}
              href={`/work-details/`}
              onSelectedRow={(val) => {
                setWorkData(
                  val.map((e) => {
                    return {
                      work_id: e.id,
                      carrier_id: e.carrier_id,
                      truck_type_id: e.truck_type_id,
                      work_location: e.workLocations,
                    };
                  })
                );
                // if (val.length > 0 && val[0].carrier_id)
                //   setCarrierId(val[0].carrier_id);
                // else setCarrierId();
              }}
              checkBoxValidation={[
                "work_status_id",
                "shipper_id",
                "truck_type_id",
                "has_document_return",
              ]}
              checkBox={true}
              refresh={refresh}
            />
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={advanceFilter}
        onClose={() => setAdvanceFilter(false)}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)", placeSelf: "start" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "accept_work_date", name: "วันที่จับคู่งาน" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"created_at"}
              value={filter.order_by}
              onChange={(val) => {
                setFilter({ ...filter, order_by: val });
              }}
              closePaddingBottom={true}
            />
            <FieldDateRangePicker
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter.datetime_from ?? "",
                end_date: filter.datetime_to ?? "",
              }}
              style={{ zIndex: 101, marginTop: 5 }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  datetime_from: new Date(val[0]).toISOString(),
                  datetime_to: new Date(val[1]).toISOString(),
                })
              }
            />
            <FieldSelect
              name={"ประเภทโปรไฟล์ลูกค้า"}
              options={[
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.profile_type}
              value={filter.profile_type}
              onChange={(val) => {
                setFilter({ ...filter, profile_type: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"ประเภทโปรไฟล์ผู้ขนส่ง"}
              options={[
                { value: "0", name: "บุคคลธรรมดา" },
                { value: "1", name: "นิติบุคคล" },
              ]}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.profile_carrier_type}
              value={filter.profile_carrier_type}
              onChange={(val) => {
                setFilter({ ...filter, profile_carrier_type: val });
              }}
              closePaddingBottom={true}
            />
            {/* <FieldSelect
              name={"ภูมิภาครับสินค้า"}
              options={region}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.load_region}
              value={filter.load_region}
              onChange={(val) => {
                setFilter({ ...filter, load_region: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"ภูมิภาคส่งสินค้า"}
              options={region}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.unload_region}
              value={filter.unload_region}
              onChange={(val) => {
                setFilter({ ...filter, unload_region: val });
              }}
              closePaddingBottom={true}
            /> */}
            <InputSelect
              defaultValue={filter.load_region}
              value={filter.load_region}
              onChange={(val) => {
                setFilter({ ...filter, load_region: val.target.value });
              }}
              options={region}
              label={"ภูมิภาครับสินค้า*"}
              disable={filter.load_place}
            />
            <InputSelect
              defaultValue={filter.unload_region}
              value={filter.unload_region}
              onChange={(val) => {
                setFilter({ ...filter, unload_region: val.target.value });
              }}
              options={region}
              label={"ภูมิภาคส่งสินค้า*"}
              disable={filter.unload_place}
            />
            <InputSelect
              defaultValue={filter.load_place}
              value={filter.load_place}
              onChange={(val) => {
                setFilter({ ...filter, load_place: val.target.value });
              }}
              options={province}
              label={"พื้นที่รับสินค้า*"}
              disable={filter.load_region}
            />
            <InputSelect
              defaultValue={filter.unload_place}
              value={filter.unload_place}
              onChange={(val) => {
                setFilter({ ...filter, unload_place: val.target.value });
              }}
              options={province}
              label={"พื้นที่ส่งสินค้า*"}
              disable={filter.unload_region}
            />
            {/* <FieldSelect
              name={"พื้นที่รับสินค้า"}
              options={province}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.load_place}
              value={filter.load_place}
              onChange={(val) => {
                setFilter({ ...filter, load_place: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"พื้นที่ส่งสินค้า"}
              options={province}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.unload_place}
              value={filter.unload_place}
              onChange={(val) => {
                setFilter({ ...filter, unload_place: val });
              }}
              closePaddingBottom={true}
            /> */}
            <FieldSelect
              name={"ประเภทสินค้า"}
              options={productType}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.product_type}
              value={filter.product_type}
              onChange={(val) => {
                setFilter({ ...filter, product_type: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"ประเภทรถ"}
              options={truckType}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.truck_type}
              value={filter.truck_type}
              onChange={(val) => {
                setFilter({ ...filter, truck_type: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"บริการเสริม"}
              options={[
                {
                  value: "has_document_return",
                  name: "มีเอกสารนำกลับ",
                },
                {
                  value: "has_labor",
                  name: "มีพนักงานขึ้นลง",
                },
              ]}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.addon_type}
              value={filter.addon_type}
              onChange={(val) => {
                setFilter({ ...filter, addon_type: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"สถานะงาน"}
              options={workStatus}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.status_type}
              value={filter.status_type}
              onChange={(val) => {
                setFilter({ ...filter, status_type: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"สถานะผิดปกติ (ต้องเพิ่ม)"}
              options={[]}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.abnormal_type}
              value={filter.abnormal_type}
              onChange={(val) => {
                setFilter({ ...filter, abnormal_type: val });
              }}
              closePaddingBottom={true}
            />
            <FieldSelect
              name={"ประเภทผู้ประกาศงาน"}
              options={[
                { value: "0", name: "ผู้ใช้งาน" },
                { value: "1", name: "ผู้ดูแลระบบ" },
              ]}
              style={{
                width: "-webkit-fill-available",
                marginTop: "5px",
              }}
              defaultValue={filter.createable_by_type}
              value={filter.createable_by_type}
              onChange={(val) => {
                setFilter({ ...filter, createable_by_type: val });
              }}
              closePaddingBottom={true}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              color="secondary"
              onClick={() => {
                getWork({ ...pagination, page: 1 });
                setAdvanceFilter(false);
              }}
            >
              ค้นหา
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="outline"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{
                borderStyle: "solid",
                borderWidth: 1,
                color: "#8ca3ba",
                borderColor: "#8ca3ba",
                height: 50,
              }}
              color="secondary"
              onClick={() => {
                setFilter({
                  order_by: "created_at",
                  order_by_value: 1,
                  datetime_from: null,
                  datetime_to: null,
                  profile_type: null,
                  search_value: "",
                  search_type: "company_name",
                  load_place: null,
                  unload_place: null,
                  product_type: null,
                  truck_type: null,
                  addon_type: null,
                  status_type: null,
                });
                setPagination({
                  total: 0,
                  per_page: 5,
                  page: 1,
                });
                getWork({
                  total: 0,
                  per_page: 5,
                  page: 1,
                });
                setAdvanceFilter(false);
              }}
            >
              ล้างการกรอง
            </Button>
          </div>
        </div>
      </Drawer>
      <ModalUpdateStatus
        open={showModal}
        workData={workData}
        close={() => {
          setShowModal(false);
        }}
        success={() => {
          setShowModal(false);
          setRefresh(true);
        }}
      />
      <CustomModal
        open={showModalUpload}
        title={"อัพโหลดไฟล์"}
        onCancel={() => {
          setShowModalUpload(false);
        }}
        onConfirm={async () => {
          handleUploadFile(fileDocumentNumber);
        }}
      >
        <FieldUpload
          name="ไฟล์อัพเดตเอกสาร"
          onChange={(val) => setFileDocumentNumber(val)}
          value={fileDocumentNumber}
        />
      </CustomModal>
    </div>
  );
}
