import { Box, Container, FormControl, Grid, Typography } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import AlertModal from "components/modal/AlertModal";
import * as moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../TableAllProjectInProfile.module.css";
import { useEffect, useState } from "react";
import FieldSelect from "components/field/FieldSelect";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import FieldInput from "components/field/FieldInput";
import FloatingButton from "components/button/FloatingButton";
import { ProjectCarrierProgression, ProjectProgression } from "utils/enum";
import { notification } from "antd";
import {
  getProjectCarrierProgressionDetail,
  updateProjectCarrierProgression,
} from "utils/api/project/project-carrier-progression";

const ProjectCarrierProgressDetail = () => {
  const navigate = useNavigate();
  const {
    project_id,
    user_id,
    user_code,
    profile_id,
    project_code,
    progression_id,
  } = useParams();

  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [formUpdate, setFormUpdate] = useState({
    progressionType: "",
    active: "",
    file: "",
    remark: "",
  });
  const [error, setError] = useState({
    progressionType: false,
    active: false,
    file: false,
  });

  const subMenu_pathbackward = `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/carrier-progression`;

  const subMenu_menus = [
    {
      label: "รายละเอียดความคืบหน้าในการหาผู้ขนส่ง",
      path: `/user/${user_id}/${user_code}/profile/${profile_id}/project/${project_id}/${project_code}/carrier-progression/${progression_id}`,
      active: true,
    },
  ];

  const subMenu_breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${project_code}`,
      path: "",
    },
    {
      label: "ความคืบหน้าในการหาผู้ขนส่ง",
      path: "",
    },
    {
      label: "ติดต่อลูกค้า",
      path: "",
    },
    {
      label: "รายละเอียดความคืบหน้าในการหาผู้ขนส่ง",
      path: "",
    },
  ];

  useEffect(() => {
    getDetail(progression_id);
  }, []);

  const getDetail = async (progression_id) => {
    try {
      const response = await getProjectCarrierProgressionDetail(
        project_id,
        progression_id
      );
      const data = response.data.data.result;
      const file = response.data.data.file;
      setFormUpdate({
        progressionType: data.progression_type,
        active: data.active ? 1 : 0,
        file: file,
        remark: data.remark,
      });
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        error.response.data.description
      );
    }
  };

  // const ProgressionOptions = [
  //   { value: "ALL", name: "ทั้งหมด" },
  //   ...ProjectProgression,
  // ];

  const handleOnChangeFormUpdate = (e, key) => {
    console.log({ key, e });
    if (key === "date") {
      setFormUpdate({ ...formUpdate, dateFrom: e[0], dateTo: e[1] });
    } else {
      let value = e;
      if (key === "remark") {
        value = e.target.value;
      }
      setFormUpdate({ ...formUpdate, [key]: value });
    }
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  const renderCard = () => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          width: "400px",
          minWidth: "400px",
          padding: "10px 25px 10px 25px",
          minHeight: "80.5vmin",
          maxHeight: "100vmin",
          overflow: "auto",
        }}
      >
        <Typography
          sx={{ fontSize: "20px", marginTop: "36px" }}
          className="text-center"
        >
          ติดต่อลูกค้า
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            marginTop: "7px",
            color: "#8CA3BA",
          }}
          className="text-center"
        >
          วันที่สร้างรายการ 2022-05-22 12.00
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#8CA3BA",
          }}
          className="text-center"
        >
          วันที่แก้ไขข้อมูลล่าสุด 2022-05-22 12.00
        </Typography>
        <Grid sx={{ marginTop: "13px" }}>
          <form>
            <FormControl fullWidth>
              <FieldSelect
                className="w-100"
                name={`สถานะงาน*`}
                nameDescription={`ค่าเริ่มต้นใช้งาน`}
                options={[
                  { value: 1, name: "ใช้งาน" },
                  { value: 0, name: "ระงับใช้งาน" },
                ]}
                value={formUpdate.active}
                error={error.active}
                helperText={error.active ? "จำเป็นต้องระบุ" : ""}
                onChange={(e) => handleOnChangeFormUpdate(e, "active")}
              />
            </FormControl>
            <FormControl fullWidth>
              <FieldSelect
                className="w-100"
                name={`สถานะความถืบหน้าโครงการ`}
                options={ProjectCarrierProgression}
                value={formUpdate.progressionType}
                error={error.progressionType}
                helperText={error.actiprogressionTypeve ? "จำเป็นต้องระบุ" : ""}
                onChange={(e) => handleOnChangeFormUpdate(e, "progressionType")}
              />
            </FormControl>
            <FormControl fullWidth>
              <FieldUpload
                name={"ไฟล์ความถืบหน้าโครงการ*"}
                icon={<UploadFile />}
                value={formUpdate.file}
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf"
                onChange={(e) => handleOnChangeFormUpdate(e, "file")}
                error={error.file}
                helperText={
                  error.file
                    ? "อัพโหลดไฟล์นามสกุลไฟล์ XLSX และ PDF เท่านั้น และขนาดไม่เกิน 10 MB"
                    : ""
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <FieldInput
                className="w-100"
                label="หมายเหตุ"
                value={formUpdate.remark}
                onChange={(e) => handleOnChangeFormUpdate(e, "remark")}
              />
            </FormControl>
          </form>
        </Grid>
      </Box>
    );
  };

  const handleFloatingButton = async () => {
    const updateData = {
      active: formUpdate.active,
      progressionType: formUpdate.progressionType,
      file: formUpdate.file,
      remark: formUpdate.remark,
    };
    const newError = {};
    Object.entries(updateData).forEach(([key, value]) => {
      if (["progressionType", "file", "active"].includes(key)) {
        if (
          value === undefined ||
          value === null ||
          value === "" ||
          value === "0.00"
        ) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
      }
    });
    setError(newError);
    if (typeof updateData.file === "string") {
      delete updateData.file;
    }
    if (Object.values(newError).every((value) => !value)) {
      try {
        const response = await updateProjectCarrierProgression(
          project_id,
          progression_id,
          updateData
        );
        if (response) {
          openNotificationWithIcon(
            "success",
            "Notification Success",
            response.data.description
          );
        }
      } catch (error) {
        console.log(error);
        openNotificationWithIcon(
          "error",
          "Notification Error",
          "เกิดข้อผิดพลาดบางอย่าง"
        );
      }
    } else {
      openNotificationWithIcon(
        "error",
        "Notification Error",
        "กรุณากรอกข้อมูลให้ครบถ้วน"
      );
    }
  };

  return (
    <div className="project-progression-detail">
      <Container>
        {contextHolder}
        {errorAlert.open === true && (
          <AlertModal
            open={errorAlert.open}
            title="เกิดข้อผิดพลาด"
            description={errorAlert.desc}
            onClose={() => {
              setErrorAlert({
                open: false,
                desc: "",
              });
              navigate(-1);
            }}
          />
        )}
        <SubMenu
          pathbackward={subMenu_pathbackward}
          menus={subMenu_menus}
          breadcrumbs={subMenu_breadcrumbs}
        />
        {renderCard()}
        <FloatingButton
          button_type="save"
          onClick={() => {
            handleFloatingButton();
          }}
        />
      </Container>
    </div>
  );
};

export default ProjectCarrierProgressDetail;
