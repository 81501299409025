import React from "react";
import { Button } from "antd";
import DownloadIcon from "@mui/icons-material/Download";

const DownloadButton = ({ onClick, classNames, styles }) => {
  return (
    <Button
      className={classNames}
      variant="text"
      onClick={onClick}
      style={{
        // width: "50px",
        // height: "50px",
        width: "55px",
        height: "57px",
        flexShrink: "0",
        filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
        padding: "8px",
        border: 0,
        ...styles,
      }}
    >
      <DownloadIcon sx={{ fontSize: "40px", color: "#121F43" }} />
    </Button>
  );
};

export default DownloadButton;
