import instance from "../../axiosInstance";

export const getAllParticipateRunnerProject = async (
  profile_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `/api/profile/profile-join-carrier/participate-runner-project/${profile_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllCoRunnerProject = async (profile_id = null, params = {}) => {
  try {
    return await instance.get(
      `/api/profile/profile-join-carrier/co-runner-project/${profile_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getProfileJoinCarrier = async (id = null) => {
  try {
    return await instance.get(`/api/profile/profile-join-carrier/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createProfileJoinCarrier = async (body = {}) => {
  try {
    return await instance.post(`/api/profile/profile-join-carrier/`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateProfileJoinCarrier = async (id = null, body = {}) => {
  try {
    return await instance.patch(
      `/api/profile/profile-join-carrier/${id}`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
