import React from "react";
import { TextField, FormControl, InputLabel, Input } from "@mui/material";
import "../input/input.css";
const FieldIconLeftRight = ({
  value = ``,
  onChange = () => {},
  label = "",
  subLabel = "",
  error = false,
  helperText = "",
  readOnly = false,
  style = {},
  iconLeft,
  iconRight,
  type = "text",
  id = null,
}) => {
  return (
    <div className="field-icon-left-right">
      <FormControl variant="standard" fullWidth margin="none">
        <InputLabel color={error ? "error" : "primary"}>{label}</InputLabel>
        <Input
          type={type}
          label={label}
          value={value}
          error={error}
          onChange={onChange}
          id={id}
          endAdornment={iconRight}
        />
        <div style={{ position: "absolute", top: 25 }}>{iconLeft}</div>
        <div
          style={{
            fontSize: 10,
            marginLeft: 20,
            position: "absolute",
            color: "#8ca3ba",
            bottom: -16,
            left: 20,
          }}
        >
          <div>{subLabel}</div>
        </div>
      </FormControl>
    </div>
  );
};

export default FieldIconLeftRight;
