import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FilledInput, IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import "./modalSelectMap.css";
import helper from "utils/helper";
import HelperMap from "utils/helperMap";
import FieldIconLeftRight from "components/field/FieldIconLeftRight";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import Line from "components/layout/line";
import InputGoogleComplete from "components/input/inputGoogleComplete";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import InputNormal from "components/input/inputNormal";
import InputSelect from "components/input/inputSelect";
import Master from "utils/master";
import FieldUpload from "components/field/FieldUpload";
import InputUpload from "components/input/inputUpload";
import ModalSelectProduct from "./modalSelectProduct";
import { getAllFavoritePlace } from "utils/api/profile/favoritePlace";
import CustomModal from "components/modal/CustomModal";
import ModalWork from "./modalWork";
import { Search } from "@mui/icons-material";
import Pagination from "components/pagination/Pagination";
import Swal from "sweetalert2";
import InputLongdoAutoComplete from "components/input/InputLongdoAutoComplete";
import ENV from "utils/environment";
const isMobile = window.innerWidth <= 768 ? true : false;
// const google = window.google;
const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? 350 : 400,
  height: "80vh",
  // maxHeight: "90vmin",
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  pt: 3,
  px: 3,
  pb: 3,
};
const ModalSelectMap = (props) => {
  const {
    value,
    close,
    show,
    onChange = () => {},
    confirm,
    profile_id,
    workType,
    is_detail = false,
    closeFav = false,
    closeCurrent = false,
  } = props;
  const [currentLocation, setCurrentLocation] = useState();
  const [currentPin, setCurrentPin] = useState();
  const [showLocDetail, setShowLocDetail] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showFavPlace, setShowFavPlace] = useState(false);
  const [favPlaceList, setFavPlaceList] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    per_page: 5,
    search_by: "location_name",
    search_val: "",
    active: true,
  });
  const [total, setTotal] = useState(0);

  const { ref } = useRef();
  console.log("profile_id", profile_id);
  const initMap = () => {
    console.log("initMap modal map");
    if (show) {
      console.log(navigator.geolocation);
      navigator.permissions.query({ name: "geolocation" }).then((response) => {
        console.log("response", response);
      });
      navigator.geolocation.getCurrentPosition(async function (position) {
        console.log("get location");
        await map.location(
          { lat: position.coords.latitude, lon: position.coords.longitude },
          true
        );

        // setValue("current_location", {
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // });
        // let data = await HelperMap.getGeocode({
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // });
        // setValue("current_data", );
        // onChange({
        //   ...value,
        //   current_location: HelperMap.getDetailLocation(data),
        // });

        console.log(
          "place",
          await HelperMap.getPlaceFormLatLong({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          })
        );
        let place = await HelperMap.getPlaceFormLatLong({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        setCurrentLocation({
          ...place,
          lat: position.coords.latitude,
          long: position.coords.longitude,
          w:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
          address:
            (place.road ?? "") +
            " " +
            place.subdistrict +
            " " +
            place.district +
            " " +
            place.province +
            " " +
            place.postcode,
        });
        // setCurrentLocation(
        //   await HelperMap.getDetailLocation(data, {
        //     lat: position.coords.latitude,
        //     lng: position.coords.longitude,
        //   })
        // );
        // setLocation({
        //   lat: position.coords.latitude,
        //   lon: position.coords.longitude,
        // });
        // setMyLocation({
        //   lat: position.coords.latitude,
        //   lon: position.coords.longitude,
        // });
        const dot = await new longdo.Marker(
          { lat: position.coords.latitude, lon: position.coords.longitude },
          {
            title: "My Location",
            draggable: false,
            weight: longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class="d-flex flex-row"><img src="' +
                require("../../assets/image/work/redDot.gif") +
                '" style="width: 30px;height: 30px"/><span class="font-weight-bold margin-top"></span></div>',
            },
          }
        );
        setCurrentPin(dot);
        await moveMap({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
        await map.Overlays.add(dot);
        // myLocationMarker = dot;
        // initGoogleMap();
      });
    }
  };
  // useEffect(() => {
  //   if (watchCurrent && !watchCurrentData) {
  //     setTimeout(async () => {

  //     }, 100);
  //   }
  // }, [watchCurrent, watchCurrentData]);

  useEffect(() => {
    if (show && value && value.lat !== "" && value.long !== "") {
      markMap();
    }
  }, [value, currentPin]);
  useEffect(() => {
    if (profile_id) {
      getFavPlace();
    }
  }, [profile_id, filter]);
  // useEffect(() => {
  //   getFavPlace();
  // }, [filter]);
  const getFavPlace = async () => {
    let fav_place = await getAllFavoritePlace(profile_id, filter);
    console.log(fav_place);
    setFavPlaceList(fav_place.data.data);
    setTotal(fav_place.data.total);
  };
  const markMap = async () => {
    if (map) {
      if (currentPin) {
        await map.Overlays.clear();
      }

      const dot = await new longdo.Marker(
        { lat: value.lat, lon: value.long },
        {
          title: "My Location",
          draggable: true,
          clickable: false,
          weight: longdo.OverlayWeight.Top,
          icon: {
            html:
              '<div class=""><img src="' +
              (value.type === 0
                ? require("../../assets/image/work/pickup_pin.png")
                : require("../../assets/image/work/send_pin.png")) +
              '" style="height: 30px"/></div>',
            // url:
            //   value.type === 0
            //     ? require("../../assets/image/work/pickup_pin.png")
            //     : require("../../assets/image/work/send_pin.png"),
            // offset: { x: 12, y: 45 },
          },
        }
      );
      await moveMap({ lat: value.lat, lon: value.long });
      await map.Overlays.add(dot);
      if (currentPin) await map.Overlays.add(currentPin);

      await map.Event.bind("overlayDrop", async function (overlay) {
        console.log(overlay.location());
        // let data = await HelperMap.getGeocode({
        //   lat: overlay.location().lat,
        //   lng: overlay.location().lon,
        // });
        // let country = data.address_components.filter((data) => {
        //   // country
        //   if (data.types.indexOf("country") >= 0) {
        //     return data;
        //   }
        // });
        let place = await HelperMap.getPlaceFormLatLong({
          lat: overlay.location().lat,
          lon: overlay.location().lon,
        });
        console.log(place);
        if (
          place?.country !== "ประเทศไทย"
          // country[0].short_name.toLowerCase() !== "th"
        ) {
          Swal.fire({
            html: `ท่านเลือกพิกัดอยู่นอกประเทศไทย`,
            type: "info",
          });
        } else {
          onChange({
            ...value,
            ...place,
            lat: overlay.location().lat,
            long: overlay.location().lon,
            w:
              (place.road ?? "") +
              " " +
              place.subdistrict +
              " " +
              place.district +
              " " +
              place.province +
              " " +
              place.postcode,
            search:
              (place.road ?? "") +
              " " +
              place.subdistrict +
              " " +
              place.district +
              " " +
              place.province +
              " " +
              place.postcode,
            address:
              (place.road ?? "") +
              " " +
              place.subdistrict +
              " " +
              place.district +
              " " +
              place.province +
              " " +
              place.postcode,
            contact_name: "",
            contact_phone: "",
            place_name: "",
            place_type_id: 0,
            remark: "",
            document: "",
            document_file: [],
          });
          // let detail = await HelperMap.getDetailLocation(data, {
          //   lat: overlay.location().lat,
          //   lng: overlay.location().lon,
          // });
          // detail.search = detail.address;
          // onChange({
          //   ...value,
          //   ...detail,
          //   contact_name: "",
          //   contact_phone: "",
          //   place_name: "",
          //   place_type_id: 0,
          //   remark: "",
          //   document: "",
          //   document_file: [],
          // });
          await moveMap(overlay.location());
        }

        // if (_this.state.location_index != -1) {
        //   _this.props.sending[_this.state.location_index] =
        //     await _this.reverseGeocoding(
        //       _this.props.sending[_this.state.location_index],
        //       overlay.location()
        //     );
        //   await _this.routingMarker();
        // }
        // console.log(await _this.reverseGeocoding(_this.props.sending[_this.state.location_index],overlay.location()))
        // console.log(_this.state.location_index)
      });
    }
  };
  const moveMap = async (location) => {
    map.location(location, true);
  };
  useEffect(() => {
    if (show) {
      // console.log(value);
      initMap();
    } else {
      let ele = document.getElementById("post-work-botbar");
      if (ele) ele.style.height = null;
      setShowLocDetail(false);
    }
  }, [show]);
  return (
    <div>
      <Modal
        open={show}
        //   onClose={() => {
        //     close();
        //   }}
        className="modal-select-map"
      >
        <Box sx={{ ...style }}>
          <div
            style={{
              // cursor: "pointer",
              float: "right",
              textAlign: "end",
            }}
          >
            <IconButton
              onClick={() => {
                close();
              }}
              // onMouseDown={handleMouseDownPassword}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ marginBottom: 10 }}>
            <InputLongdoAutoComplete
              value={value}
              label={value?.type === 0 ? "จุดรับสินค้า" : "จุดส่งสินค้า"}
              // error={errors.address}
              onChange={(val) => {
                onChange({
                  ...val,
                  contact_name: "",
                  contact_phone: "",
                  place_name: "",
                  place_type_id: 0,
                  remark: "",
                  document: "",
                  document_file: [],
                });
              }}
              closeFav={closeFav}
            />
            {/* <InputGoogleComplete
              value={value}
              label={value?.type === 0 ? "จุดรับสินค้า" : "จุดส่งสินค้า"}
              // error={errors.address}
              onChange={(val) => {
                onChange({
                  ...val,
                  contact_name: "",
                  contact_phone: "",
                  place_name: "",
                  place_type_id: 0,
                  remark: "",
                  document: "",
                  document_file: [],
                });
              }}
            /> */}
          </div>
          {closeCurrent ? (
            ""
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="outline"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{
                borderStyle: "solid",
                borderWidth: 1,
                color: "#8ca3ba",
                borderColor: "#8ca3ba",
                height: 50,
              }}
              color="secondary"
              onClick={() => {
                onChange({
                  ...value,
                  ...currentLocation,
                  search: currentLocation?.address,
                });
              }}
            >
              ใช้ตำแหน่งปัจจุบัน
            </Button>
            // <FieldIconLeftRight
            //   value={currentLocation?.address ?? ""}
            //   label="ตำแหน่งปัจจุบัน"
            //   iconLeft={
            //     // <IconButton>
            //     <GpsFixedOutlinedIcon
            //       sx={{ fontSize: 30, color: "#8ca3ba", cursor: "pointer" }}
            //       onClick={() => {
            //         onChange({ ...value, ...currentLocation, search: "" });
            //       }}
            //     />
            //     // </IconButton>
            //   }
            //   subLabel="ระบบจะค้นหาจากตำแหน่งปัจจุบันของคุณโปรดเปิด GPS"
            //   // onChange={(val) => {}}
            // />
          )}
          <div
            style={{
              flex: 1,
              position: "relative",
              marginTop: 20,
              // marginBottom: 20,
            }}
          >
            {closeFav ? (
              ""
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 1,
                  display: "flex",
                  backgroundColor: "white",
                  top: -1,
                  padding: "10px 0px 10px 10px",
                  borderRadius: "0px 0px 20px 20px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                  cursor: "pointer",
                }}
                onClick={() => setShowFavPlace(true)}
              >
                {/* <div> */}
                <BookmarkOutlinedIcon sx={{ color: "#8ca3ba", fontSize: 30 }} />
                {/* </div> */}
                <div style={{ color: "#8ca3ba", alignSelf: "center" }}>
                  สถานที่บันทึกไว้
                </div>
              </div>
            )}
            <div style={{ paddingTop: closeCurrent ? 0 : 40, height: "100%" }}>
              {show ? (
                <LongdoMap
                  id={"post-work-modal-map"}
                  ref={ref}
                  style={{ borderRadius: 20 }}
                  map_key={ENV.MAP_KEY[ENV.ENV].KEY_1}
                  // callback={}
                />
              ) : null}
            </div>

            <div
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 1,
                // display: "flex",
                // height: "60vmin",
                backgroundColor: "white",
                bottom: -1,
                padding: "10px 0px 10px 10px",
                borderRadius: "20px 20px 0px 0px",
                cursor: "pointer",
                overflow: "scroll",
                // textAlign: "center",
                justifyContent: "center",
                boxShadow: "rgba(115, 115, 115, 0.1) 0px -1px 10px",
              }}
              id="post-work-botbar"
            >
              {closeFav ? (
                ""
              ) : (
                <>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let ele = document.getElementById("post-work-modal-map");
                      // console.log(ele.offsetHeight);
                      if (
                        document.getElementById("post-work-botbar")
                          .offsetHeight === ele.offsetHeight
                      ) {
                        document.getElementById(
                          "post-work-botbar"
                        ).style.height = null;
                        setShowLocDetail(false);
                      } else {
                        document.getElementById(
                          "post-work-botbar"
                        ).style.height = ele.offsetHeight + "px";
                        setShowLocDetail(true);
                      }
                    }}
                  >
                    <div
                      style={{
                        width: 50,
                        height: 5,
                        backgroundColor: "#8ca3ba",
                        position: "absolute",
                        top: 2.5,
                        transform: "translate(-50%, -50%)",
                        borderRadius: "0px 0px 10px 10px",
                        left: "50%",
                      }}
                    ></div>
                    <div style={{ color: "#8ca3ba", textAlign: "center" }}>
                      ข้อมูลติดต่อ
                      {value?.type === 0 ? "จุดรับสินค้า" : "จุดส่งสินค้า"}
                    </div>
                  </div>
                  {showLocDetail ? (
                    <div>
                      <Line />
                      <InputNormal
                        value={value?.contact_name}
                        label={"ชื่อผู้ติดต่อ*"}
                        onChange={(val) => {
                          onChange({
                            ...value,
                            contact_name: val.target.value,
                          });
                        }}
                        subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                      />
                      <InputNormal
                        value={value?.contact_phone}
                        extraType={"phone"}
                        maxLength={10}
                        label={"เบอร์โทรผู้ติดต่อ*"}
                        onChange={(val) => {
                          onChange({
                            ...value,
                            contact_phone: val.target.value,
                          });
                        }}
                        subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                      />
                      <InputNormal
                        value={value?.place_name}
                        // extraType={"phone"}
                        // maxLength={10}
                        label={"ชื่อสถานที่*"}
                        onChange={(val) => {
                          onChange({ ...value, place_name: val.target.value });
                        }}
                        // subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                      />
                      <InputSelect
                        value={value?.place_type_id}
                        label={"ประเภทสถานที่*"}
                        subFirst={"(ค่าเริ่มต้น: ที่พักอาศัย)"}
                        options={Master.place_type}
                        onChange={(val) => {
                          onChange({
                            ...value,
                            place_type_id: val.target.value,
                          });
                        }}
                      />
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 10, flex: 1 }}>
                          <InputNormal
                            value={value?.document}
                            label={"เอกสารอื่นๆ*"}
                            onChange={(val) => {
                              onChange({
                                ...value,
                                document: val.target.value,
                              });
                            }}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <InputUpload
                            id={"document_file" + Math.random(10)}
                            value={value?.document_file}
                            label={"เอกสารอื่นๆ*"}
                            onChange={(val) => {
                              console.log(val);
                              onChange({
                                ...value,
                                document_file: val,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <InputNormal
                        value={value?.remark}
                        label={"หมายเหตุ*"}
                        onChange={(val) => {
                          onChange({ ...value, remark: val.target.value });
                        }}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        style={{ borderRadius: "10px", marginTop: 20 }}
                        sx={{ height: 50 }}
                        color="secondary"
                        onClick={() => {
                          setShowProduct(true);
                        }}
                      >
                        ข้อมูลรับสินค้า ณ สถานที่ที่นี้
                      </Button>
                    </div>
                  ) : null}
                </>
              )}
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {}}
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      confirm();
                    }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <TextField
              // id="outlined-basic"
              id="location-search"
              label={"สถานที่"}
              variant="outlined"
              size="small"
              value={watchAddress}
              onChange={(val) => {
                setValue("address", val.target.value);
              }}
            />
          </div> */}
        </Box>
      </Modal>
      {show ? (
        <ModalSelectProduct
          open={showProduct}
          value={value?.product}
          onChange={(val) => {
            onChange({ ...value, product: val });
          }}
          close={() => {
            setShowProduct(false);
          }}
          confirm={() => {
            setShowProduct(false);
          }}
          workType={workType}
        />
      ) : null}

      {show ? (
        <ModalWork
          open={showFavPlace}
          // value={value?.product}
          onChange={(val) => {
            console.log("val", val);
            // onChange({ ...value, product: val });
          }}
          close={() => {
            setShowFavPlace(false);
          }}
          confirm={() => {
            setShowFavPlace(false);
          }}
          enableClose
          minHeight
        >
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>สถานที่บันทึกไว้</div>
            <Line />
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_value}
              onChange={(val) => {
                setFilter({ ...filter, search_val: val.target.value });
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={() => {
                getFavPlace();
              }}
            >
              ค้นหา
            </Button>
            <div>
              {favPlaceList.map((ele, index) => {
                return (
                  <div
                    key={"fav" + index}
                    style={{
                      borderRadius: 10,
                      borderStyle: "solid",
                      borderWidth: 1,
                      padding: 10,
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      console.log(ele);
                      onChange({
                        ...value,
                        name: ele.location_name,
                        search: ele.address,
                        lat: ele.lat,
                        long: ele.long,
                        address: ele.address,
                        district: ele.district,
                        subdistrict: ele.subdistrict,
                        province: ele.province,
                        postal_code: ele.postal_code,
                        contact_name: ele.contact_name,
                        contact_phone: ele.contact_phone,
                        place_name: ele.location_name,
                        place_type_id: ele.place_type,
                        remark: ele.remark,
                      });
                      setShowFavPlace(false);
                    }}
                  >
                    {ele.location_name}
                  </div>
                );
              })}
              <Pagination
                pagination={{
                  page: filter.page,
                  per_page: filter.per_page,
                  total: total,
                }}
                setPagination={(val) => {
                  console.log(val);
                  setFilter({
                    ...filter,
                    per_page: val.per_page,
                    page: val.page,
                  });
                  // getFavPlace({
                  //   ...filter,
                  //   per_page: val.per_page,
                  //   page: val.page,
                  // })
                }}
                // loading={loading}
              />
            </div>
          </div>
        </ModalWork>
      ) : null}
    </div>
  );
};

export default ModalSelectMap;
