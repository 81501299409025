import instance from "../../axiosInstance";
import helper from "utils/helper";

const getAllWorkRegular = async (param) => {
  try {
    return instance.get(`/api/work/regular/get-all-work-regular`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};
const createWorkRegular = async (data) => {
  try {
    return instance.post(`/api/work/regular/create-work-regular`, data, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getWorkRegularById = async (id) => {
  try {
    return instance.get(`/api/work/regular/get-work-regular-by-id/${id}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editWorkRegular = async (id, data) => {
  try {
    return instance.patch(`/api/work/regular/edit-work-regular/${id}`, data, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getAllWorkRegular,
  createWorkRegular,
  getWorkRegularById,
  editWorkRegular,
};
