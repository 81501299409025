import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm, Controller } from "react-hook-form";
import Loading from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import { Container, Avatar, Typography } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import { Save } from "@mui/icons-material";
import styles from "../TableAllProjectInProfile.module.css";
import {
  editCarrierInProject,
  editProjectAdmin,
  getCarrierInProjectById,
} from "utils/api/project/project";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import { ActiveStatus, AdminRole, PaymentType, ReminderDate } from "utils/enum";
import FieldUpload from "components/field/FieldUpload";
import { getProjectAdminId } from "utils/api/project/project";

const DetailProjectAdmin = () => {
  const { user_code, project_code, project_admin_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [serviceMaster, setServiceMaster] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: "",
    desc: "",
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  useEffect(() => {
    getDetailProjectAdmin(project_admin_id);
  }, [project_admin_id]);

  useEffect(() => {
    console.log("dataDetail", dataDetail);
  }, [dataDetail]);

  const MenuProject = [
    {
      label: "รายละเอียดผู้รับผิดชอบในโครงการ",
      path: "/",
      active: true,
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${project_code}`,
      path: "",
    },
    {
      label: "ผู้รับผิดชอบโครงการ",
      path: "",
    },
    {
      label: `${dataDetail?.admin.username}`,
      path: "",
    },
    {
      label: "รายละเอียดผู้รับผิดชอบในโครงการ",
      path: "",
    },
  ];

  useEffect(() => {
    console.log("dataDetail", dataDetail);
    if (dataDetail) {
      const newInitialFormValues = {};
      newInitialFormValues.active = String(dataDetail?.active) ?? "";
      newInitialFormValues.admin_id = dataDetail?.admin_id ?? "";
      newInitialFormValues.admin_role_type =
        dataDetail?.admin.admin_role_type ?? "";

      setInitialFormValues(newInitialFormValues);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail, setValue]);

  const getDetailProjectAdmin = async (project_admin_id) => {
    setLoading(true);
    await getProjectAdminId(project_admin_id)
      .then((response) => {
        if (response.data.code === 1000) {
          const rest = response.data.data;
          setDataDetail(rest);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const renderCard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Typography fontSize={"20px"} fontWeight={500} sx={{ p: "13px" }}>
          {dataDetail?.admin.username || ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่สร้างรายการ{" "}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่แก้ไขข้อมูลล่าสุด{" "}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ""}
        </Typography>
        <Controller
          control={control}
          name="active"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="สถานะการใช้งาน*"
                nameDescription="(ค่าเริ่มต้น:ใช้งาน)"
                options={ActiveStatus}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="admin_name"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="ชื่อผู้ดูแลระบบ*"
                options={[
                  {
                    value: 1,
                    name: `${dataDetail?.admin.firstname}  ${dataDetail?.admin.lastname}`,
                  },
                ]}
                value={1}
                readOnly={true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="admin_role_type"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="บทบาทผู้ดูแลระบบ*"
                options={AdminRole}
                value={value}
                onChange={onChange}
                readOnly={true}
              />
            </div>
          )}
        />
        <ButtonCustom
          name="ดูข้อมูลผู้ดูแลระบบ"
          fullWidth={true}
          onClick={() => {
            navigate(`/admin/${dataDetail?.admin_id}`);
          }}
        />
      </div>
    );
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    console.log("initialFormValues", initialFormValues);
    setLoading(true);
    const changedData = {};
    //check array

    Object.keys(data).forEach((fieldName) => {
      if (
        Array.isArray(data[fieldName]) &&
        JSON.stringify(data[fieldName]) !==
          JSON.stringify(initialFormValues[fieldName])
      ) {
        changedData[fieldName] = data[fieldName];
      } else if (
        !Array.isArray(data[fieldName]) &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        changedData[fieldName] = data[fieldName];
        if (data[fieldName] === "") {
          changedData[fieldName] = null;
        } else {
          changedData[fieldName] = data[fieldName];
        }
      }
    });

    console.log("changedData", changedData);

    if (project_admin_id) {
      await editProjectAdmin(project_admin_id, changedData)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal({
              open: true,
              title: "บันทึก",
              desc: "บันทึกข้อมูลสำเร็จ",
            });
            console.log("edit Success");
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window?.location?.reload();
            }
          }
        });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: "",
              desc: "",
            });
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: "",
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <SubMenu
          menus={MenuProject}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
      </Container>
      <form
        className={styles.containerDetailProject}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderCard1()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: "white", fontSize: "70px" }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default DetailProjectAdmin;
