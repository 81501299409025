// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .form-updatestatus .MuiFormControl-root {
  margin-bottom: 5px;
}

.input-standard .MuiInputBase-input {
  margin: 0 10px;
}

.input-standard .MuiInputLabel-root {
  margin: 0 10px;
}

.input-standard .MuiFormHelperText-root {
  margin: 0 10px;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/trade/finance/finance.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;GAcG","sourcesContent":["/* .form-updatestatus .MuiFormControl-root {\n  margin-bottom: 5px;\n}\n\n.input-standard .MuiInputBase-input {\n  margin: 0 10px;\n}\n\n.input-standard .MuiInputLabel-root {\n  margin: 0 10px;\n}\n\n.input-standard .MuiFormHelperText-root {\n  margin: 0 10px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
