import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import {
  getRouteStlStandardSize,
  updateRouteStlStandardSize,
} from "utils/api/masterData/routeStlStandardSize";
import { getAllStlStandardSize } from "../../../../utils/api/masterData/stlStandardSize";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./RouteStlStandardSizeInfo.module.css";

const RouteStlStandardSizeInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState("");
  const [listStlStandardSize, setListStlStandardSize] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetRouteStlStandardSize();

    // eslint-disable-next-line
  }, []);

  const handleGetRouteStlStandardSize = async () => {
    setIsLoading(true);
    try {
      const service_params = {
        sort_field: ["created_at"],
        sort_order: [1],
      };
      const stl_standard_size_resp = await getAllStlStandardSize(
        service_params
      );

      const stl_standard_size_data = stl_standard_size_resp.data.data.results;
      setListStlStandardSize(stl_standard_size_data);

      const resp = await getRouteStlStandardSize(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else if (key === `size_info`) {
            let list_size_info = {};
            stl_standard_size_data.forEach((standard_size) => {
              const find_data = data[key].find((item) => {
                return item.id === standard_size.id;
              });

              if (!R.isNil(find_data)) {
                list_size_info = {
                  ...list_size_info,
                  [standard_size.id]: find_data,
                };
              } else {
                list_size_info = {
                  ...list_size_info,
                  [standard_size.id]: {
                    id: -1,
                    freight: 0,
                    size_name: standard_size.name,
                  },
                };
              }
            });
            setValue(key, list_size_info);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(getValues().load_zone_name);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getRouteStlStandardSize: ", error);
      navigate("/master-data/route-stl-standard-size");
    }
  };

  const handleUpdateRouteStlStandardSize = async () => {
    setIsLoading(true);
    try {
      let data = getValues();
      const stl_standard_size_info = Object.values(data.size_info).map(
        (item) => {
          return {
            id: item.id,
            freight: parseFloat(item.freight),
          };
        }
      );

      const obj = {
        active: data.active === `true` ? true : false,
        stl_standard_size_info: stl_standard_size_info,
      };

      const update_resp = await updateRouteStlStandardSize(data.id, obj);
      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดเส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)`}
        pathbackward={`/master-data/route-stl-standard-size`}
        menus={[
          {
            label: "เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "เส้นทางภูมิภาคที่ให้บริการ (STL-Size&Freight)",
            path: null,
          },
          {
            label: `${getValues().load_zone_name} - ${
              getValues().unload_zone_name
            }`,
            path: null,
          },
          {
            label: "รายละเอียด",
            path: null,
          },
        ]}
      />
    );
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะใช้งาน";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "load_zone_name",
        field_type: "text",
        field_label: "ภูมิภาคจุดรับสินค้า",
        disabled: true,
      },
      {
        name: "unload_zone_name",
        field_type: "text",
        field_label: "ภูมิภาคจุดส่งสินค้า",
        disabled: true,
      },
    ];

    const stl_standard_size_form1 = listStlStandardSize.map((item) => {
      return {
        name: `size_info[${item.id}].freight`,
        field_type: "text",
        field_label: `ค่าใช้จ่ายเพิ่มเติม ${item.name}*`,
        // nameDescription: `(ค่าเริ่มต้ม 0)`,
        rules: {
          required: `กรุณาใส่ค่าใช้จ่ายเพิ่มเติม ${item.name}`,
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
        error:
          errors && errors["size_info"] && errors["size_info"][`${item.id}`]
            ? true
            : false,
        helperText:
          errors && errors["size_info"] && errors["size_info"][`${item.id}`]
            ? errors["size_info"][`${item.id}`].freight.message
            : null,
      };
    });

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}

        {stl_standard_size_form1.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateRouteStlStandardSize();
        })}
      >
        {renderColumn1()}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default RouteStlStandardSizeInfo;
