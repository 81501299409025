import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loading from "components/loading/LoadingBackground";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import {
  Drawer,
  Typography,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { Search, FilterAlt, Add } from "@mui/icons-material";
import helper from "utils/helper";
import { useCookies } from "react-cookie";

import FieldSelect from "components/field/FieldSelect";
import TableActiveIcon from "components/tableActiveIcon";
import ButtonCustom from "components/ButtonCustom";
import SubMenu from "components/layout/SubMenu.js";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import styles from "./WorkRegular.module.css";
import TableCustom from "components/table/TableCustom";
import AlertModal from "components/modal/AlertModal";
import { getAllWorkRegular } from "utils/api/workRegular/workRegular";
import ModalCreateWorkRegular from "./ModalCreateWorkRegular";
import SelectRowValue from "components/button/SelectRowValue";

const TableWorkRegular = () => {
  const { profile_id } = useParams();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [openModalCreateWork, setOpenModalCreateWork] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [mainInfo, setMainInfo] = useState({});
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [filter, setFilter] = useState({
    sort_by: "updated_at",
    search_by: "work_name",
    search_val: "",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
  });

  useEffect(() => {
    console.log("dataDetail", dataDetail);
  }, [dataDetail]);

  const menus = [
    {
      label: "ประกาศงานรายการเดียว",
      path: "/post-work",
    },
    {
      label: "ประกาศงานแบบใช้ไฟล์นำเข้า",
      path: "/import-work",
    },
    {
      label: "จัดเส้นทาง",
      path: "/route-optimize",
    },
    {
      label: "จัดการงานประจำ",
      path: "/work-regular",
      active: true,
    },
  ];

  const breadcrumbs = [
    {
      label: "ประกาศงาน",
      path: "",
    },
    {
      label: "จัดการงานประจำ",
      path: "",
    },
  ];

  const columns = [
    {
      id: "updated_at",
      label: <label style={{ fontSize: 12 }}>วันที่แก้ไขข้อมูลล่าสุด</label>,
      align: "center",
      format: (value) => (
        <label style={{ fontSize: 12 }}>
          {helper.ToConvertDateTimeForTable(value?.updated_at)}
        </label>
      ),
    },
    {
      id: "work_regular_code",
      label: <label style={{ fontSize: 12 }}>เลขที่งานประจำ</label>,
      align: "center",
      format: (value) => (
        <label style={{ fontSize: 12 }}>{value?.work_regular_code}</label>
      ),
    },
    {
      id: "work_regular_name",
      label: <label style={{ fontSize: 12 }}>ชื่อประกาศ</label>,
      align: "left",
      format: (value) => (
        <label style={{ fontSize: 12 }}>{value?.work_regular_name}</label>
      ),
    },
    {
      id: "profile",
      label: <label style={{ fontSize: 12 }}>ชื่อโปรไฟล์ลูกค้า</label>,
      align: "left",
      format: (value) => (
        <label style={{ fontSize: 12 }}>
          {value?.profile_info?.company_name}
        </label>
      ),
    },
    {
      id: "zone",
      label: <label style={{ fontSize: 12 }}>ภูมิภาค</label>,
      align: "center",
      format: (value) => {
        let text = "";
        let route = 0;
        value?.workRegularZone.forEach((e, i) => {
          if (i === 0) {
            text += e?.zone_info?.name;
          } else {
            route++;
          }
        });
        if (route > 0) {
          text += "และอีก " + route + " จุด";
        }

        return <label style={{ fontSize: 12 }}>{text}</label>;
      },
    },
    {
      id: "origin",
      label: <label style={{ fontSize: 12 }}>จุดรับ</label>,
      align: "center",
      format: (value) => {
        let text = "";
        let route = 0;
        value?.workRegularOrigin.forEach((e, i) => {
          if (i === 0) {
            text += e?.origin_province?.name;
          } else {
            route++;
          }
        });
        if (route > 0) {
          text += "และอีก " + route + " จุด";
        }

        return <label style={{ fontSize: 12 }}>{text}</label>;
      },
    },
    {
      id: "destination",
      label: <label style={{ fontSize: 12 }}>จุดส่ง</label>,
      align: "center",
      format: (value) => {
        let text = "";
        let route = 0;
        value?.workRegularDestination.forEach((e, i) => {
          if (i === 0) {
            text += e?.destination_province?.name;
          } else {
            route++;
          }
        });
        if (route > 0) {
          text += "และอีก " + route + " จุด";
        }

        return <label style={{ fontSize: 12 }}>{text}</label>;
      },
    },

    {
      id: "product",
      label: <label style={{ fontSize: 12 }}>ประเภทสินค้า</label>,
      align: "left",
      format: (value) => (
        <label style={{ fontSize: 12 }}>{value?.product_type?.name}</label>
      ),
    },
    {
      id: "truck_type",
      label: <label style={{ fontSize: 12 }}>ประเภทรถ</label>,
      align: "left",
      format: (value) => (
        <label style={{ fontSize: 12 }}>{value?.truck_type?.name}</label>
      ),
    },

    {
      id: "service",
      label: <label style={{ fontSize: 12 }}>บริการเสริม</label>,

      align: "center",
      //   width: "13%",
      format: (value) => {
        let text = "";
        if (value.has_document_return) {
          text += "มีเอกสารนำกลับ ";
        }
        if (value.has_labor) {
          if (value.labor_load_amount && value.labor_load_amount > 0) {
            text += "คนงานยกสินค้าขึ้น " + value.labor_load_amount + " คน\n ";
          }
          if (value.labor_unload_amount && value.labor_unload_amount > 0) {
            text += "คนงานยกสินค้าลง " + value.labor_unload_amount + " คน\n ";
          }
        }
        if (text === "") {
          text = "ไม่มีบริการเสริม";
        }
        return <label style={{ fontSize: 12 }}>{text}</label>;
      },
    },
    {
      id: "active",
      label: <label style={{ fontSize: 12 }}>สถานะใช้งาน</label>,
      align: "center",
      width: "10%",
      format: (value) => {
        return <TableActiveIcon active_status={value?.active} />;
      },
    },
  ];

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filter.sort_by}
              onChange={(val) => setFilter({ ...filter, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filter?.start_date ?? dateFrom,
                end_date: filter?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilter({
                  ...filter,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[{ value: "work_name", name: "ชื่อประกาศ" }]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"work_name"}
              value={filter.search_by}
              onChange={(val) => setFilter({ ...filter, search_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.search_val ? filter.search_val : ""}
              onChange={(val) =>
                setFilter({ ...filter, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "20px",
              gap: "20px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filterWorkRegular = { ...filter, page: 1 };
                getDataAllWorkRegular(filterWorkRegular);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
            />
            {/* <FieldSelect
              name={"จำนวนแถว"}
              options={[
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 20, name: "20" },
                { value: 50, name: "50" },
                { value: 100, name: "100" },
              ]}
              defaultValue={5}
              value={filter.per_page}
              onChange={(val) => {
                setFilter({ ...filter, per_page: val });
              }}
              closePaddingBottom={true}
            /> */}
            {/* <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar> */}
          </Grid>
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    getDataAllWorkRegular(filter);
  }, [filter.per_page, filter.page]);

  const getDataAllWorkRegular = async (_filter) => {
    setLoading(true);
    await getAllWorkRegular(_filter)
      .then((response) => {
        if (response.data.code === 1000) {
          const rest = response?.data.results.data;
          setDataDetail(rest);
          setLoading(false);
          setFilter({ ...filter, total: response?.data.results.total });
        } else if (response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: response.data.description,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          window.location.reload();
        }}
      />
      {errorAlert.open ? (
        <AlertModal
          open={errorAlert.open}
          title={"เกิดข้อผิดพลาด"}
          description={errorAlert.desc}
          onClose={() => {
            if (errorAlert.desc === "ไม่มีสิทธ์ในการเข้าถึง") {
              navigate("/");
            }

            setErrorAlert({
              open: false,
              description: ``,
            });
          }}
        />
      ) : null}
      {openModalCreateWork ? (
        <ModalCreateWorkRegular
          open={openModalCreateWork}
          handleClose={() => setOpenModalCreateWork(false)}
          alertSuccess={() => {
            setSuccessAlert(true);
          }}
          alertError={(e) => {
            setErrorAlert(e);
          }}
        />
      ) : (
        ""
      )}
      <Container>
        <SubMenu menus={menus} breadcrumbs={breadcrumbs} pathbackward={-1} />
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={dataDetail}
            pagination={filter}
            setPagination={(val) => {
              setFilter({
                ...filter,
                ...val,
              });
            }}
            onSelectedRow={(val) => console.log(val)}
            href={`/work-regular/detail/`}
          />
          <Avatar
            sx={{
              position: "fixed",
              bottom: "35px",
              right: "97px",
              width: 100,
              height: 100,
              bgcolor: "secondary.main",
            }}
            onClick={() => setOpenModalCreateWork(true)}
          >
            <Add sx={{ color: "white", fontSize: "90px" }} />
          </Avatar>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default TableWorkRegular;
