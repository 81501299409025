import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Box, Container } from "@mui/material";
import * as R from "ramda";
import {
  approveTruck,
  changeCarrier,
  getTruck,
  updateTruck,
} from "../../../utils/api/truck/truck";
import { getTruckType } from "utils/api/masterData/truckType";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import { getAllAccessory } from "utils/api/masterData/accessory";
import { getAllDriverInProfile } from "utils/api/profile/profile";
import { getAllProvince } from "utils/api/masterData/gis";
import SelectTruckInProfile from "../SelectTruckInProfile";
import CustomModal from "components/modal/CustomModal";
import Button from "components/button/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import {
  ApproveStatus,
  truck_document_approve_status,
} from "../../../utils/enum";
import helper from "utils/helper";
import styles from "./TruckInfo.module.css";
import { getTruckBrand } from "utils/api/masterData/truckBrand";
import { sendNoti } from "utils/api/noti/noti";
import InputNormal from "components/input/inputNormal";
import API from "../../../utils/api/work/work";
import Swal from "sweetalert2";
import InputDisable from "components/input/inputDisable";
import ButtonCustom from "components/ButtonCustom";
const api = API.create();
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 400,
  flexDirection: "column",
  overflow: "scroll",
  maxHeight: "90vmin",
  //   height: 200,
  bgcolor: "background.paper",
  borderRadius: 5,
  color: "#121f43",
  boxShadow: 24,
  // pt: 3,
  // px: 3,
  // pb: 3,
};
const TruckInfo = () => {
  const { user_id, profile_id, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState("");
  const [allTruckType, setAllTruckType] = useState([]);
  const [allTruckBrand, setAllTruckBrand] = useState([]);
  const [allAccessory, setAllAccessory] = useState([]);
  const [allDriverInProfile, setAllDriverInProfile] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [isChangeApprovedStatus, setIsChangeApprovedStatus] = useState(false);
  const [selectTruckModal, setSelectTruckModal] = useState({
    open: false,
    select_type: `head`,
    data: {},
  });
  const [exceptionTruckIdInTruckModal, setExceptionTruckIdInTruckModal] =
    useState({
      truck_head: null,
      truck_tail: null,
    });
  const [profileId, setProfileId] = useState(null);
  const [visibleChangeCarrier, setVisibleChangeCarrier] = useState(false);
  const [oldTruckData, setOldTruckData] = useState({});
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const watchCarrier = watch("carrier_code");
  const watchCompanyName = watch("company_name");
  const [disabledField, setDisabledField] = useState({
    is_owner_truck_head: false,
    is_owner_truck_tail: false,
    reason_for_edit: false,
    truck_head: { selected_exists_truck: false },
    truck_tail: {
      selected_exists_truck: false,
      front_license_plate: false,
      center_license_plate: false,
      back_license_plate: false,
      registration_date_tail: false,
      registration_file_tail: false,
      is_owner_truck_tail: false,
      consent_lend_truck_file_tail: false,
      has_insurance: false,
      cover_insurance: false,
      expired_insurance_date: false,
      accept_trip_insurance: false,
      insurance_file: false,
      truck_tax_file: false,
      expired_truck_tax_date: false,
      has_tax: false,
    },
  });

  useEffect(() => {
    handleGetTruck();
    handleGetTruckType();
    handleGetTruckBrand();
    handleGetAccessory();
    handleGetProvince();
    clearErrors();
    setExceptionTruckIdInTruckModal({ truck_head: null, truck_tail: null });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!R.isEmpty(allTruckType)) {
      handleDisabledField();
    }

    // eslint-disable-next-line
  }, [getValues().truck_type, allTruckType]);

  const handleGetTruck = async () => {
    setIsLoading(true);
    try {
      const resp = await getTruck(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        setOldTruckData(data);
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else if (key === `truck_accessories`) {
            if (!R.isNil(data[key]) && !R.isEmpty(data[key])) {
              const list_accessories_id = data[key].map((accessory) => {
                return accessory.accessory_id;
              });
              setValue(key, list_accessories_id);
            } else {
              setValue(key, []);
            }
          } else if (
            key === `truck_insurance_head` ||
            key === `truck_insurance_tail`
          ) {
            if (!R.isNil(data[key])) {
              let _truck_insurance = data[key];

              _truck_insurance.accept_trip_insurance = !R.isNil(
                _truck_insurance.accept_trip_insurance
              )
                ? _truck_insurance.accept_trip_insurance === true
                  ? `true`
                  : `false`
                : null;
              _truck_insurance.has_insurance = !R.isNil(
                _truck_insurance.has_insurance
              )
                ? _truck_insurance.has_insurance === true
                  ? `true`
                  : `false`
                : null;

              setValue(key, _truck_insurance);
            }
          } else if (key === `truck_tax_head` || key === `truck_tax_tail`) {
            if (!R.isNil(data[key])) {
              let _truck_tax = data[key];

              _truck_tax.has_tax =
                _truck_tax.has_tax === true ? `true` : `false`;
              setValue(key, _truck_tax);
            }
          } else {
            setValue(key, data[key]);
          }
        });

        const _full_license_plate_head =
          getValues().license_plate_head &&
          getValues().license_plate_head.full_license_plate
            ? getValues().license_plate_head.full_license_plate
            : ``;
        const _full_license_plate_tail =
          getValues().license_plate_tail &&
          getValues().license_plate_tail.full_license_plate
            ? ` - ${getValues().license_plate_tail.full_license_plate}`
            : ``;
        setH2Text(`${_full_license_plate_head} ${_full_license_plate_tail}`);

        await handleGetAllDriverInProfile({ profile_id: data.profile.id });
        setProfileId(data.profile.id);
        handleDisabledField();
      }

      handleDisabledField();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getTruck: ", error);
      setAlertModal({
        open: true,
        title: `พบปัญหา`,
        description: error.response.data.description,
      });
      // navigate("/truck");
    }
  };

  const handleGetTruckBrand = async () => {
    try {
      const resp = await getTruckBrand({
        page: null,
        per_page: null,
        active: true,
        sort_field: ["id"],
        sort_order: [1],
      });
      const data = resp.data.data.results;
      setAllTruckBrand(data);
    } catch (error) {
      console.log("error getTruckBrand: ", error);
    }
  };

  const handleGetTruckType = async () => {
    try {
      const resp = await getTruckType({
        page: null,
        per_page: null,
        active: true,
        sort_field: ["order_number"],
        sort_order: [1],
      });
      const data = resp.data.data.results;
      setAllTruckType(data);
    } catch (error) {
      console.log("error getTruckType: ", error);
    }
  };

  const handleGetAccessory = async () => {
    try {
      const resp = await getAllAccessory({
        page: null,
        per_page: null,
        active: true,
        sort_field: ["id"],
        sort_order: [1],
      });
      const data = resp.data.data.results;
      setAllAccessory(data);
    } catch (error) {
      console.log("error getAllAccessory: ", error);
    }
  };

  const handleGetAllDriverInProfile = async ({ profile_id = null }) => {
    try {
      if (!R.isNil(profile_id)) {
        const resp = await getAllDriverInProfile(profile_id);
        const data = resp.data.data.results;
        setAllDriverInProfile(data);
      }
    } catch (error) {
      console.log("error getAllDriverInProfile: ", error);
    }
  };

  const handleGetProvince = async () => {
    try {
      const resp = await getAllProvince({
        // page: null,
        // per_page: null,
        // sort_field: ["order_number"],
        // sort_order: [1],
      });
      const data = resp.data.data.results;
      setAllProvince(data);
    } catch (error) {
      console.log("error getTruckType: ", error);
    }
  };

  const handleUpdateTruck = async () => {
    setIsLoading(true);
    try {
      let data = getValues();
      // if (!R.isNil(data.document_approve_status)) {
      //   const _document_approve_status = truck_document_approve_status.find(
      //     (approve_status) => {
      //       return approve_status.name === data.document_approve_status;
      //     }
      //   );
      //   data = {
      //     ...data,
      //     document_approve_status: !R.isNil(_document_approve_status)
      //       ? _document_approve_status.id
      //       : null,
      //   };
      // }

      const _data = {
        // profile_id: profile_id,
        profile_id: data.profile && data.profile.id ? data.profile.id : null,
        truck_type_id: data.truck_type.id,
        has_tail: data.has_tail === `true` ? true : false,
        width: data.width,
        length: data.length,
        height: data.height,
        weight: data.weight,

        truck_brand_head_id:
          data.truck_brand_head && data.truck_brand_head.id
            ? data.truck_brand_head.id
            : null,
        license_plate_head: `${data.license_plate_head.front_license_plate} ${data.license_plate_head.center_license_plate} ${data.license_plate_head.back_license_plate}`,
        registration_date_head: data.registration_date_head,
        registration_file_head: data.registration_file_head,
        is_owner_truck_head:
          data.is_owner_truck_head === `true` ||
          data.is_owner_truck_head === true
            ? true
            : false,
        consent_lend_truck_head:
          data.consent_lend_truck_head === `true` ? true : false,
        consent_lend_truck_file_head: data.consent_lend_truck_file_head,

        truck_brand_tail_id:
          data.truck_brand_tail && data.truck_brand_tail.id
            ? data.truck_brand_tail.id
            : null,
        license_plate_tail:
          disabledField.truck_tail.front_license_plate === true ||
          disabledField.truck_tail.center_license_plate === true ||
          disabledField.truck_tail.back_license_plate === true
            ? ``
            : `${
                data.license_plate_tail.front_license_plate
                  ? data.license_plate_tail.front_license_plate
                  : ""
              }${
                data.license_plate_tail.center_license_plate
                  ? ` ${data.license_plate_tail.center_license_plate}`
                  : ""
              }${
                data.license_plate_tail.back_license_plate
                  ? ` ${data.license_plate_tail.back_license_plate}`
                  : ""
              }`,
        registration_date_tail: data.registration_date_tail,
        registration_file_tail: data.registration_file_tail,
        is_owner_truck_tail: R.isNil(data.is_owner_truck_tail)
          ? null
          : data.is_owner_truck_tail === `true` ||
            data.is_owner_truck_tail === true
          ? true
          : false,
        consent_lend_truck_tail:
          data.consent_lend_truck_tail === `true` ? true : false,
        consent_lend_truck_file_tail: data.consent_lend_truck_file_tail,

        driver_id: data.driver && data.driver.id ? data.driver.id : null,
        // document_approve_status: data.document_approve_status,
        // reason_for_edit: R.isEmpty(data.reason_for_edit)
        //   ? "-"
        //   : data.reason_for_edit,
        // reason_for_edit: data.reason_for_edit,
        approved_at: data.approved_at,
        approved_by: data.approved_by,
        active: data.active === `true` ? true : false,
        truck_accessories: data.truck_accessories,

        truck_image_front: data.truck_image_front.image,
        truck_image_left: data.truck_image_left.image,
        truck_image_right: data.truck_image_right.image,
        truck_image_back: data.truck_image_back.image,
        truck_image_inside: data.truck_image_inside.image,

        truck_insurance_head_has_insurance: !R.isNil(
          data.truck_insurance_head.has_insurance
        )
          ? data.truck_insurance_head.has_insurance === `true`
            ? true
            : false
          : null,
        truck_insurance_head_cover_insurance: data.truck_insurance_head
          .cover_insurance
          ? data.truck_insurance_head.cover_insurance
          : null,
        truck_insurance_head_expired_insurance_date: data.truck_insurance_head
          .expired_insurance_date
          ? data.truck_insurance_head.expired_insurance_date
          : null,
        truck_insurance_head_accept_trip_insurance: !R.isNil(
          data.truck_insurance_head.accept_trip_insurance
        )
          ? data.truck_insurance_head.accept_trip_insurance === `true`
            ? true
            : false
          : null,
        truck_insurance_head_insurance_file: !R.isNil(
          data.truck_insurance_head.insurance_file
        )
          ? data.truck_insurance_head.insurance_file
          : null,

        truck_insurance_tail_has_insurance: !R.isNil(
          data.truck_insurance_tail.has_insurance
        )
          ? data.truck_insurance_tail.has_insurance === `true`
            ? true
            : false
          : null,
        truck_insurance_tail_cover_insurance: data.truck_insurance_tail
          .cover_insurance
          ? data.truck_insurance_tail.cover_insurance
          : null,
        truck_insurance_tail_expired_insurance_date: data.truck_insurance_tail
          .expired_insurance_date
          ? data.truck_insurance_tail.expired_insurance_date
          : null,
        truck_insurance_tail_accept_trip_insurance: !R.isNil(
          data.truck_insurance_tail.accept_trip_insurance
        )
          ? data.truck_insurance_tail.accept_trip_insurance === `true`
            ? true
            : false
          : null,
        truck_insurance_tail_insurance_file: !R.isNil(
          data.truck_insurance_tail.insurance_file
        )
          ? data.truck_insurance_tail.insurance_file
          : null,

        truck_tax_file_head: data.truck_tax_head.truck_tax_file,
        expired_truck_tax_date_head: data.truck_tax_head.expired_truck_tax_date,
        has_tax_head: data.truck_tax_head.has_tax === `true` ? true : false,

        truck_tax_file_tail: data.truck_tax_tail.truck_tax_file,
        expired_truck_tax_date_tail: data.truck_tax_tail.expired_truck_tax_date,
        has_tax_tail: data.truck_tax_tail.has_tax === `true` ? true : false,
      };

      let body = { ..._data };
      const image_key = [
        "registration_file_head",
        "consent_lend_truck_file_head",
        "registration_file_tail",
        "consent_lend_truck_file_tail",
        "truck_insurance_head_insurance_file",
        "truck_insurance_tail_insurance_file",
        "truck_image_front",
        "truck_image_left",
        "truck_image_right",
        "truck_image_back",
        "truck_image_inside",
        "truck_tax_file_head",
        "truck_tax_file_tail",
      ];
      Object.keys(_data).forEach((key) => {
        if (image_key.includes(key)) {
          if (typeof _data[key] === `string` || R.isNil(_data[key])) {
            body = R.omit([`${key}`], body);
          }
        }
      });
      await updateTruck(id, body).then(async (update_resp) => {
        if (update_resp.data.code === 1000) {
          // setH2Text(`${body.license_plate_head} ${body.license_plate_tail}`);
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
          // if (isChangeApprovedStatus) {
          //   const param_noti = {
          //     user_code: [getValues().profile.user_info.user_code],
          //     title: h2Text,
          //     message: `${getValues().document_approve_status} ${
          //       getValues().document_approve_status === "แก้ไขเพิ่มเติม"
          //         ? "เหตุผล" + getValues().reason_for_edit
          //         : ""
          //     }`,
          //     for_shipper: false,
          //     for_carrier: true,
          //     for_driver: true,
          //     important: false,
          //   };
          //   await sendNoti(param_noti)
          //     .then((res) => console.log("send_noti success"))
          //     .catch((error) => {
          //       console.log("error", error);
          //     });
          // }
          // await handleGetTruck();
        }
      });

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message
          ? error.response.data.description.message
          : error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const handleApproved = async () => {
    let data = getValues();
    let body = {};
    if (!R.isNil(data.document_approve_status)) {
      const _document_approve_status = truck_document_approve_status.find(
        (approve_status) => {
          return approve_status.name === data.document_approve_status;
        }
      );
      body = {
        ...body,
        document_approve_status: !R.isNil(_document_approve_status)
          ? _document_approve_status.id
          : null,
      };
    }
    if (!R.isNil(data.reason_for_edit)) {
      body = {
        ...body,
        reason_for_edit: !R.isNil(data.reason_for_edit)
          ? data.reason_for_edit
          : null,
      };
    }

    if (body.document_approve_status !== null) {
      await approveTruck(id, {
        document_approve_status: body.document_approve_status,
        reason_for_edit: body.reason_for_edit,
      }).then(async (approve_resp) => {
        if (approve_resp.data.code === 1000) {
          setH2Text(`${data.license_plate_head} ${data.license_plate_tail}`);
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
          if (isChangeApprovedStatus) {
            const param_noti = {
              user_code: [getValues().profile.user_info.user_code],
              title: h2Text,
              message: `${getValues().document_approve_status} ${
                getValues().document_approve_status === "แก้ไขเพิ่มเติม"
                  ? "เหตุผล" + getValues().reason_for_edit
                  : ""
              }`,
              for_shipper: false,
              for_carrier: true,
              for_driver: true,
              important: false,
            };
            await sendNoti(param_noti)
              .then((res) => console.log("send_noti success"))
              .catch((error) => {
                console.log("error", error);
              });
          }
          // await handleGetTruck();
        }
      });
    }
  };
  const handleFieldChange = (field_name = "truck_type", field_value = null) => {
    if (field_name === "truck_type") {
      const find_data = allTruckType.find((item) => item.id === field_value);
      if (!R.isNil(find_data)) {
        const has_tail = find_data.has_tail === true ? `true` : `false`;
        setValue(`has_tail`, has_tail);
        setValue(`width`, find_data.width.toFixed(2));
        setValue(`length`, find_data.length.toFixed(2));
        setValue(`height`, find_data.height.toFixed(2));
        setValue(`weight`, find_data.weight);
        clearErrors("has_tail");
        clearErrors("width");
        clearErrors("length");
        clearErrors("height");
        clearErrors("weight");

        if (find_data.min_cover_insurance_head === 0) {
          setValue("truck_insurance_head.accept_trip_insurance", null);
          setValue("truck_insurance_head.cover_insurance", null);
          setValue("truck_insurance_head.expired_insurance_date", null);
          setValue("truck_insurance_head.has_insurance", null);
        }
        if (find_data.min_cover_insurance_tail === 0) {
          setValue("truck_insurance_tail.accept_trip_insurance", null);
          setValue("truck_insurance_tail.cover_insurance", null);
          setValue("truck_insurance_tail.expired_insurance_date", null);
          setValue("truck_insurance_tail.has_insurance", null);
        }
      }

      handleDisabledField();
    } else if (field_name === "has_tail") {
      handleDisabledField();
    } else if (field_name === "is_owner_truck_head") {
      setValue("consent_lend_truck_file_head", null);
      clearErrors("consent_lend_truck_file_head");
      handleDisabledField();
    } else if (field_name === "truck_insurance_head.has_insurance") {
      handleDisabledField();
      if (field_value == "false") {
        setValue("truck_insurance_head.cover_insurance", null);
        setValue("truck_insurance_head.expired_insurance_date", null);
        setValue("truck_insurance_head.insurance_file", null);

        clearErrors("truck_insurance_head.cover_insurance");
        clearErrors("truck_insurance_head.expired_insurance_date");
        clearErrors("truck_insurance_head.insurance_file");
      } else {
        setValue("truck_insurance_head.accept_trip_insurance", null);

        clearErrors("truck_insurance_head.accept_trip_insurance");
      }
    } else if (field_name === "is_owner_truck_tail") {
      setValue("consent_lend_truck_file_tail", null);
      clearErrors("consent_lend_truck_file_tail");
      handleDisabledField();
    } else if (field_name === "truck_insurance_tail.has_insurance") {
      handleDisabledField();
      if (field_value == "false") {
        setValue("truck_insurance_tail.cover_insurance", null);
        setValue("truck_insurance_tail.expired_insurance_date", null);
        setValue("truck_insurance_tail.insurance_file", null);

        clearErrors("truck_insurance_tail.cover_insurance");
        clearErrors("truck_insurance_tail.expired_insurance_date");
        clearErrors("truck_insurance_tail.insurance_file");
      } else {
        setValue("truck_insurance_tail.accept_trip_insurance", null);

        clearErrors("truck_insurance_tail.accept_trip_insurance");
      }
    } else if (field_name === "document_approve_status") {
      handleDisabledField();
    } else if (field_name === "truck_brand_head") {
      setValue("truck_brand_head", { id: field_value });
    } else if (field_name === "truck_brand_tail") {
      setValue("truck_brand_tail", { id: field_value });
    }
  };

  const handleFieldBlur = (field_name = "width", field_value = null) => {
    if (field_name === "width") {
      const value = parseFloat(field_value).toFixed(2);
      if (!isNaN(value)) {
        setValue(`width`, value);
      } else {
        setValue(`width`, parseFloat(0).toFixed(2));
        clearErrors(`width`);
      }
    } else if (field_name === "length") {
      const value = parseFloat(field_value).toFixed(2);
      if (!isNaN(value)) {
        setValue(`length`, value);
      } else {
        setValue(`length`, parseFloat(0).toFixed(2));
        clearErrors(`length`);
      }
    } else if (field_name === "height") {
      const value = parseFloat(field_value).toFixed(2);
      if (!isNaN(value)) {
        setValue(`height`, value);
      } else {
        setValue(`height`, parseFloat(0).toFixed(2));
        clearErrors(`height`);
      }
    }
  };

  const handleDisabledField = () => {
    try {
      const data = getValues();
      let _disabled_field = disabledField;

      if (!R.isNil(data.has_tail)) {
        let disabled_status_changed = false;
        if (data.has_tail === `false`) {
          disabled_status_changed = true;

          const filed_name_from_input = [
            "license_plate_tail.front_license_plate",
            "license_plate_tail.center_license_plate",
            "license_plate_tail.back_license_plate",
            "registration_date_tail",
            "registration_file_tail",
            "is_owner_truck_tail",
            "consent_lend_truck_file_tail",
            "truck_insurance_tail.has_insurance",
            "truck_insurance_tail.cover_insurance",
            "truck_insurance_tail.expired_insurance_date",
            "truck_insurance_tail.accept_trip_insurance",
            "truck_insurance_tail.insurance_file",
            "truck_tax_tail.truck_tax_file",
            "truck_tax_tail.expired_truck_tax_date",
            "truck_tax_tail.has_tax",
            "truck_brand_tail.id",
          ];
          filed_name_from_input.forEach((field) => {
            setValue(field, null);
            clearErrors(field);
          });
        }

        const filed_name_truck_head = ["selected_exists_truck"];
        filed_name_truck_head.forEach((field) => {
          _disabled_field = {
            ..._disabled_field,
            truck_head: {
              ..._disabled_field.truck_head,
              [field]: disabled_status_changed,
            },
          };
        });

        const filed_name_truck_tail = [
          "selected_exists_truck",
          "front_license_plate",
          "center_license_plate",
          "back_license_plate",
          "registration_date_tail",
          "registration_file_tail",
          "is_owner_truck_tail",
          "consent_lend_truck_file_tail",
          "has_insurance",
          "cover_insurance",
          "expired_insurance_date",
          "accept_trip_insurance",
          "insurance_file",
          "truck_tax_file",
          "expired_truck_tax_date",
          "has_tax",
          "brand_name",
        ];
        filed_name_truck_tail.forEach((field) => {
          _disabled_field = {
            ..._disabled_field,
            truck_tail: {
              ..._disabled_field.truck_tail,
              [field]: disabled_status_changed,
            },
          };
        });
      }
      if (!R.isNil(data.is_owner_truck_head)) {
        _disabled_field = {
          ..._disabled_field,
          is_owner_truck_head:
            data.is_owner_truck_head === `true` ? true : false,
        };
      }
      if (!R.isNil(data.is_owner_truck_tail)) {
        _disabled_field = {
          ..._disabled_field,
          is_owner_truck_tail:
            data.is_owner_truck_tail === `true` ? true : false,
        };
      }
      if (!R.isNil(data.document_approve_status)) {
        if (data.document_approve_status === `แก้ไขเพิ่มเติม`) {
          _disabled_field = {
            ..._disabled_field,
            reason_for_edit: false,
          };
        } else {
          _disabled_field = {
            ..._disabled_field,
            reason_for_edit: true,
          };
          // setValue(`reason_for_edit`, "");
          setValue(`reason_for_edit`, null);
        }
      }
      if (!R.isNil(data.truck_type)) {
        const field_name = [
          "has_insurance",
          "cover_insurance",
          "expired_insurance_date",
          "accept_trip_insurance",
          "insurance_file",
        ];
        const truck_type_data = allTruckType.find(
          (item) => item.id === data.truck_type.id
        );

        if (truck_type_data.min_cover_insurance_head === 0) {
          field_name.forEach((field) => {
            _disabled_field = {
              ..._disabled_field,
              truck_head: {
                ..._disabled_field.truck_head,
                [field]: true,
              },
            };
          });
        } else {
          field_name.forEach((field) => {
            _disabled_field = {
              ..._disabled_field,
              truck_head: {
                ..._disabled_field.truck_head,
                [field]: false,
              },
            };
          });

          if (!R.isNil(data.truck_insurance_head)) {
            let disabled_status_changed =
              data.truck_insurance_head.has_insurance === "false" ||
              data.truck_insurance_head.has_insurance === false
                ? true
                : false;

            _disabled_field = {
              ..._disabled_field,
              truck_head: {
                ..._disabled_field.truck_head,
                expired_insurance_date: disabled_status_changed,
                cover_insurance: disabled_status_changed,
                insurance_file: disabled_status_changed,
              },
            };
          }
        }

        if (
          truck_type_data.min_cover_insurance_tail === 0 ||
          _disabled_field.truck_tail.has_insurance === true
        ) {
          field_name.forEach((field) => {
            _disabled_field = {
              ..._disabled_field,
              truck_tail: {
                ..._disabled_field.truck_tail,
                [field]: true,
              },
            };
          });
        } else {
          field_name.forEach((field) => {
            _disabled_field = {
              ..._disabled_field,
              truck_tail: {
                ..._disabled_field.truck_tail,
                [field]: false,
              },
            };
          });

          if (!R.isNil(data.truck_insurance_tail)) {
            let disabled_status_changed =
              data.truck_insurance_tail.has_insurance === "false" ||
              data.truck_insurance_tail.has_insurance === false
                ? true
                : false;

            _disabled_field = {
              ..._disabled_field,
              truck_tail: {
                ..._disabled_field.truck_tail,
                expired_insurance_date: disabled_status_changed,
                cover_insurance: disabled_status_changed,
                insurance_file: disabled_status_changed,
              },
            };
          }
        }
      }
      if (!R.isNil(data.truck_insurance_head)) {
        if (
          data.truck_insurance_head.has_insurance === "true" ||
          data.truck_insurance_head.has_insurance === true
        ) {
          _disabled_field = {
            ..._disabled_field,
            truck_head: {
              ..._disabled_field.truck_head,
              accept_trip_insurance: true,
            },
          };
        }
      }
      if (!R.isNil(data.truck_insurance_tail)) {
        if (
          data.truck_insurance_tail.has_insurance === "true" ||
          data.truck_insurance_tail.has_insurance === true
        ) {
          _disabled_field = {
            ..._disabled_field,
            truck_tail: {
              ..._disabled_field.truck_tail,
              accept_trip_insurance: true,
            },
          };
        }
      }

      setDisabledField(_disabled_field);
    } catch (error) {}
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะใช้งาน";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "profile.company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
      {
        name: "truck_type.id",
        field_type: "select",
        field_label: "ประเภทรถ*",
        // rules: {
        //   required: "กรุณาเลือกประเภทรถ",
        // },
        options: allTruckType.map((truck_type) => {
          return {
            value: truck_type.id,
            name: truck_type.name,
          };
        }),
        onChange: (value) => {
          handleFieldChange("truck_type", value);
        },
      },
      {
        name: "has_tail",
        field_type: "select",
        field_label: "มีหาง*",
        // rules: {
        //   validate: (value) => {
        //     if (R.isEmpty(value)) {
        //       return "กรุณาเลือกมีหาง";
        //     }
        //     return true;
        //   },
        // },
        disabled: true,
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        onChange: () => {
          handleFieldChange("has_tail");
        },
      },
      {
        name: "width",
        field_type: "text",
        field_label: "ความกว้างของกระบะบรรทุก (เมตร)*",
        onBlur: (e) => {
          handleFieldBlur("width", e.target.value);
        },
        rules: {
          // required: "กรุณาใส่ความกว้างของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`float_2_decimal`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "length",
        field_type: "text",
        field_label: "ความยาวของกระบะบรรทุก (เมตร)*",
        onBlur: (e) => {
          handleFieldBlur("length", e.target.value);
        },
        rules: {
          // required: "กรุณาใส่ความยาวของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`float_2_decimal`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "height",
        field_type: "text",
        field_label: "ความสูงของกระบะบรรทุก (เมตร)*",
        onBlur: (e) => {
          handleFieldBlur("height", e.target.value);
        },
        rules: {
          // required: "กรุณาใส่ความสูงของกระบะบรรทุก (เมตร)",
          pattern: {
            value: helper.RegexValidate(`float_2_decimal`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
      {
        name: "weight",
        field_type: "text",
        field_label: "นํ้าหนักบรรทุก (กิโลกรัม)*",
        rules: {
          // required: "กรุณาใส่นํ้าหนักบรรทุก (กิโลกรัม)",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>

          <h4>{`ข้อมูลรถ (ทั่วไป)`}</h4>
        </div>

        {col1_menu.map((menu, index) => {
          if (index === 3) {
          }
          return index === 3 ? (
            <div>
              <div style={{ marginBottom: 20 }}>
                <Button
                  type={`button`}
                  onClick={() => {
                    setVisibleChangeCarrier(true);
                  }}
                >{`ย้ายสังกัด`}</Button>
              </div>
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
              />
            </div>
          ) : (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn2 = () => {
    const col2_menu = [
      {
        name: "truck_accessories",
        field_type: "select",
        multiSelect: true,
        field_label: "อุปกรณ์เสริมติดรถ",
        options: !R.isEmpty(allAccessory)
          ? allAccessory.map((accessory) => {
              return {
                value: accessory.id,
                name: accessory.name,
              };
            })
          : [],
      },
      {
        name: "driver.id",
        field_type: "select",
        field_label: "พนักงานที่ขับรถคันนี้",
        disabled: R.isEmpty(allDriverInProfile) ? true : false,
        options: !R.isEmpty(allDriverInProfile)
          ? allDriverInProfile.map((item) => {
              return {
                value: item.driver_info.id,
                name: `${item.driver_info.firstname} ${item.driver_info.lastname}`,
              };
            })
          : [],
      },
      {
        name: "truck_image_front.image",
        field_type: "upload",
        field_label: "รูปภาพรถด้านหน้า*",
        rules: {
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (typeof file !== "string") {
              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น";
              }
            }
            return true;
          },
        },
        error:
          errors[`truck_image_front`] && errors[`truck_image_inside`][`image`]
            ? true
            : false,
        helperText:
          errors[`truck_image_front`] && errors[`truck_image_inside`][`image`]
            ? errors[`truck_image_front`][`image`][`message`]
            : false,
      },
      {
        name: "truck_image_left.image",
        field_type: "upload",
        field_label: "รูปภาพรถด้านซ้าย*",
        rules: {
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (typeof file !== "string") {
              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น";
              }
            }
            return true;
          },
        },
        error:
          errors[`truck_image_left`] && errors[`truck_image_inside`][`image`]
            ? true
            : false,
        helperText:
          errors[`truck_image_left`] && errors[`truck_image_inside`][`image`]
            ? errors[`truck_image_left`][`image`][`message`]
            : false,
      },
      {
        name: "truck_image_right.image",
        field_type: "upload",
        field_label: "รูปภาพรถด้านขวา*",
        rules: {
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (typeof file !== "string") {
              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น";
              }
            }
            return true;
          },
        },
        error:
          errors[`truck_image_right`] && errors[`truck_image_inside`][`image`]
            ? true
            : false,
        helperText:
          errors[`truck_image_right`] && errors[`truck_image_inside`][`image`]
            ? errors[`truck_image_right`][`image`][`message`]
            : false,
      },
      {
        name: "truck_image_back.image",
        field_type: "upload",
        field_label: "รูปภาพรถด้านหลัง*",
        rules: {
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (typeof file !== "string") {
              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น";
              }
            }
            return true;
          },
        },
        error:
          errors[`truck_image_back`] && errors[`truck_image_inside`][`image`]
            ? true
            : false,
        helperText:
          errors[`truck_image_back`] && errors[`truck_image_inside`][`image`]
            ? errors[`truck_image_back`][`image`][`message`]
            : false,
      },
      {
        name: "truck_image_inside.image",
        field_type: "upload",
        field_label: "รูปภาพด้านในกระบะบรรทุก*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              if (!file) {
                return true;
              }
              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น";
              }
            }
            return true;
          },
        },
        error:
          errors[`truck_image_inside`] && errors[`truck_image_inside`][`image`]
            ? true
            : false,
        helperText:
          errors[`truck_image_inside`] && errors[`truck_image_inside`][`image`]
            ? errors[`truck_image_inside`][`image`][`message`]
            : false,
      },
    ];

    return (
      <div className={styles.field_container}>
        {col2_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn3 = () => {
    const col3_menu = [
      {
        name: "truck_brand_head.id",
        field_type: "select",
        field_label: "ยี่ห้อรถ*",
        // rules: {
        //   required: "กรุณาเลือกยี่ห้อรถ",
        // },
        options: allTruckBrand.map((brand) => {
          return {
            value: brand.id,
            name: brand.truck_brand_name,
          };
        }),
        onChange: (value) => {
          handleFieldChange("truck_brand_head", value);
        },
        error:
          errors[`truck_brand_head`] && errors[`truck_brand_head`][`id`]
            ? true
            : false,
        helperText:
          errors[`truck_brand_head`] && errors[`truck_brand_head`][`id`]
            ? errors[`truck_brand_head`][`id`][`message`]
            : false,
      },
      {
        name: "license_plate_head.front_license_plate",
        field_type: "text",
        field_label: "ทะเบียนรถ (ตัวอักษรหรือตัวเลข 2 ตัวหน้า)*",
        rules: {
          // required: "กรุณาใส่ทะเบียนรถ (ตัวอักษรหรือตัวเลข 2 ตัวหน้า)",
          pattern: {
            value: helper.RegexValidate(`front_license_plate`),
            message: "กรุณาใส่ตัวอักษรภาษาไทย 2-3 ตัว",
          },
        },
        error:
          errors[`license_plate_head`] &&
          errors[`license_plate_head`][`front_license_plate`]
            ? true
            : false,
        helperText:
          errors[`license_plate_head`] &&
          errors[`license_plate_head`][`front_license_plate`]
            ? errors[`license_plate_head`][`front_license_plate`][`message`]
            : false,
      },
      {
        name: "license_plate_head.center_license_plate",
        field_type: "text",
        field_label: "ทะเบียนรถ (ตัวเลข 1-4 ตัว)*",
        rules: {
          // required: "กรุณาใส่ทะเบียนรถ (ตัวเลข 1-4 ตัว)",
          pattern: {
            value: helper.RegexValidate(`center_license_plate`),
            message: "กรุณาใส่ตัวเลข 1-4 ตัว",
          },
        },
        // rules: {
        //   required: "กรุณาใส่ทะเบียนรถ (ตัวเลข 4 ตัว)",
        // },
        error:
          errors[`license_plate_head`] &&
          errors[`license_plate_head`][`center_license_plate`]
            ? true
            : false,
        helperText:
          errors[`license_plate_head`] &&
          errors[`license_plate_head`][`center_license_plate`]
            ? errors[`license_plate_head`][`center_license_plate`][`message`]
            : false,
      },

      {
        name: "license_plate_head.back_license_plate",
        field_type: "select",
        field_label: "ทะเบียนรถ (จังหวัด)*",
        // rules: {
        //   required: "กรุณาเลือกทะเบียนรถ (จังหวัด)",
        // },
        options: allProvince.map((province) => {
          return {
            value: province.name,
            name: province.name,
          };
        }),
        error:
          errors[`license_plate_head`] &&
          errors[`license_plate_head`][`back_license_plate`]
            ? true
            : false,
        helperText:
          errors[`license_plate_head`] &&
          errors[`license_plate_head`][`back_license_plate`]
            ? errors[`license_plate_head`][`back_license_plate`][`message`]
            : false,
      },
      {
        name: "registration_date_head",
        field_type: "datePicker",
        field_label: "วันที่จดทะเบียน*",
        // rules: {
        //   required: "กรุณาใส่วันที่จดทะเบียน",
        // },
      },
      {
        name: "registration_file_head",
        field_type: "upload",
        field_label: "ไฟล์เอกสารจดทะเบียน*",
        rules: {
          // required: "กรุณาใส่ไฟล์เอกสารจดทะเบียน",
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (typeof file !== "string") {
              if (
                !helper.isImageFile({ file: file }) &&
                !helper.isPdfFile({ file: file })
              ) {
                return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
              }
            }
            return true;
          },
        },
      },
      {
        name: "is_owner_truck_head",
        field_type: "select",
        field_label: "สถานะการเป็นเจ้าของรถ*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะการเป็นเจ้าของรถ";
            }
            return true;
          },
        },
        onChange: (value) => {
          handleFieldChange("is_owner_truck_head", value);
        },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
      },
      {
        name: "consent_lend_truck_file_head",
        field_type: "upload",
        field_label: "เอกสารยินยอมให้ใช้รถ",
        nameDescription: "(บังคับกรณีที่ไม่ใช่เจ้าของรถ)",
        disabled: disabledField.is_owner_truck_head,
        rules: {
          // required:
          //   disabledField.is_owner_truck_head === false
          //     ? "กรุณาใส่เอกสารยินยอมให้ใช้รถ"
          //     : disabledField.truck_head.consent_lend_truck_file_tail === false
          //     ? "กรุณาใส่เอกสารยินยอมให้ใช้รถ"
          //     : null,
          validate: async (file) => {
            if (disabledField.is_owner_truck_head === false) {
              if (!file) {
                return true;
              }
              if (typeof file !== "string") {
                if (
                  !helper.isImageFile({ file: file }) &&
                  !helper.isPdfFile({ file: file })
                ) {
                  return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
                }
              }
              return true;
            } else {
              return true;
            }
          },
          // required:
          //   disabledField.truck_head.consent_lend_truck_file_tail === false
          //     ? disabledField.is_owner_truck_head === false
          //       ? "กรุณาใส่เอกสารยินยอมให้ใช้รถ"
          //       : null
          //     : null,
          // validate: async (file) => {
          //   if (
          //     disabledField.truck_head.consent_lend_truck_file_head === false
          //   ) {
          //     if (disabledField.is_owner_truck_head === false) {
          //       if (typeof file !== "string") {
          //         if (
          //           !helper.isImageFile({ file: file }) &&
          //           !helper.isPdfFile({ file: file })
          //         ) {
          //           return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
          //         }
          //       }
          //       return true;
          //     } else {
          //       return true;
          //     }
          //   }
          // },
        },
      },
      {
        name: "truck_insurance_head.has_insurance",
        field_type: "select",
        field_label: "สถานะการมีประกันภัยสินค้า*",
        disabled: disabledField.truck_head.has_insurance,
        // rules: {
        //   required:
        //     disabledField.truck_head.has_insurance === false
        //       ? "กรุณาเลือกสถานะการมีประกันภัยสินค้า"
        //       : null,
        // },
        options: [
          { value: "true", name: `มี` },
          { value: "false", name: `ไม่มี` },
        ],
        onChange: (value) => {
          handleFieldChange("truck_insurance_head.has_insurance", value);
        },
        error:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`has_insurance`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`has_insurance`]
            ? errors[`truck_insurance_head`][`has_insurance`][`message`]
            : false,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลรถ (ส่วนหัว)`}</h4>

          <Button
            type={`button`}
            disabled={disabledField.truck_head.selected_exists_truck}
            onClick={() => {
              setSelectTruckModal({
                open: true,
                select_type: `head`,
              });
            }}
          >{`เลือกส่วนหัวจากรถที่เพิ่มแล้ว`}</Button>
        </div>

        {col3_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn4 = () => {
    const col4_menu = [
      {
        name: "truck_insurance_head.cover_insurance",
        field_type: "text",
        field_label: "จำนวนทุนประกันภัยสินค้า*",
        disabled: disabledField.truck_head.cover_insurance,
        nameDescription:
          "(กรณีไม่มีประกันฯ ไม่ต้องระบุ และกรณีที่จำนวนทุนประกันฯ ในฐานข้อมูลมีค่าเท่ากับ 0 ไม่ต้องระบุ)",
        rules: {
          // required:
          //   disabledField.truck_head.cover_insurance === false
          //     ? "กรุณาใส่จำนวนทุนประกันภัยสินค้า"
          //     : null,
          // required: "กรุณาใส่จำนวนทุนประกันภัยสินค้า",
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
        error:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`cover_insurance`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`cover_insurance`]
            ? errors[`truck_insurance_head`][`cover_insurance`][`message`]
            : false,
      },
      {
        name: "truck_insurance_head.expired_insurance_date",
        field_type: "datePicker",
        field_label: "วันหมดอายุประกันภัยสินค้า*",
        disabled: disabledField.truck_head.expired_insurance_date,
        nameDescription:
          "(กรณีไม่มีประกันฯ ไม่ต้องระบุ และกรณีที่จำนวนทุนประกันฯ ในฐานข้อมูลมีค่าเท่ากับ 0 ไม่ต้องระบุ)",
        // rules: {
        //   required:
        //     disabledField.truck_head.expired_insurance_date === false
        //       ? "กรุณาใส่วันหมดอายุประกันภัยสินค้า"
        //       : null,
        //   // required: "กรุณาใส่วันหมดอายุประกันภัยสินค้า",
        // },
        error:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`expired_insurance_date`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`expired_insurance_date`]
            ? errors[`truck_insurance_head`][`expired_insurance_date`][
                `message`
              ]
            : false,
        disabledPreviousDay: true,
      },
      {
        name: "truck_insurance_head.insurance_file",
        field_type: "upload",
        field_label: "เอกสารกรมธรรม์",
        // nameDescription: "(บังคับกรณีที่ไม่ใช่เจ้าของรถ)",
        disabled: disabledField.truck_head.insurance_file,
        rules: {
          // required:
          //   disabledField.truck_head.insurance_file === false
          //     ? "กรุณาใส่ไฟล์กรมธรรม์"
          //     : null,
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (disabledField.truck_head.insurance_file === false) {
              if (disabledField.truck_head.insurance_file === false) {
                if (typeof file !== "string") {
                  if (
                    !helper.isImageFile({ file: file }) &&
                    !helper.isPdfFile({ file: file })
                  ) {
                    return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
                  }
                }
                return true;
              } else {
                return true;
              }
            }
          },
        },
        error:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`insurance_file`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`insurance_file`]
            ? errors[`truck_insurance_head`][`insurance_file`][`message`]
            : false,
      },
      {
        name: "truck_insurance_head.accept_trip_insurance",
        field_type: "select",
        field_label: "ยินยอมให้หักประกันภัยสินค้ารายเที่ยว*",
        disabled: disabledField.truck_head.accept_trip_insurance,
        nameDescription:
          "(บังคับกรณีไม่มีประกันฯ และกรณีที่จำนวนทุนประกันฯ ในฐานข้อมูลมีค่าเท่ากับ 0 ไม่ต้องระบุ)",
        // rules: {
        //   required:
        //     disabledField.truck_head.accept_trip_insurance === false
        //       ? "กรุณาเลือกการยินยอมให้หักประกันภัยสินค้ารายเที่ยว"
        //       : null,
        // },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        error:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`accept_trip_insurance`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_head`] &&
          errors[`truck_insurance_head`][`accept_trip_insurance`]
            ? errors[`truck_insurance_head`][`accept_trip_insurance`][`message`]
            : false,
      },
      {
        name: "truck_tax_head.truck_tax_file",
        field_type: "upload",
        field_label: "ไฟล์เอกสารภาษีรถ*",
        rules: {
          // required: "กรุณาใส่ไฟล์เอกสารภาษีรถ",
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (typeof file !== "string") {
              if (
                !helper.isImageFile({ file: file }) &&
                !helper.isPdfFile({ file: file })
              ) {
                return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
              }
            }
            return true;
          },
        },
        error:
          errors[`truck_tax_head`] && errors[`truck_tax_head`][`truck_tax_file`]
            ? true
            : false,
        helperText:
          errors[`truck_tax_head`] && errors[`truck_tax_head`][`truck_tax_file`]
            ? errors[`truck_tax_head`][`truck_tax_file`][`message`]
            : false,
      },
      {
        name: "truck_tax_head.expired_truck_tax_date",
        field_type: "datePicker",
        field_label: "วันที่หมดอายุภาษีรถ*",
        // rules: {
        //   required: "กรุณาใส่วันที่หมดอายุภาษีรถ",
        // },
        error:
          errors[`truck_tax_head`] &&
          errors[`truck_tax_head`][`expired_truck_tax_date`]
            ? true
            : false,
        helperText:
          errors[`truck_tax_head`] &&
          errors[`truck_tax_head`][`expired_truck_tax_date`]
            ? errors[`truck_tax_head`][`expired_truck_tax_date`][`message`]
            : false,
        disabledPreviousDay: true,
      },
      {
        name: "truck_tax_head.has_tax",
        field_type: "select",
        field_label: "สถานะการต่อภาษี*",
        // rules: {
        //   required: "กรุณาเลือกสถานะการต่อภาษี",
        //   // validate: (value) => {
        //   //   if (R.isEmpty(value)) {
        //   //     return "กรุณาเลือกสถานะการต่อภาษี";
        //   //   }
        //   //   return true;
        //   // },
        // },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        error:
          errors[`truck_tax_head`] && errors[`truck_tax_head`][`has_tax`]
            ? true
            : false,
        helperText:
          errors[`truck_tax_head`] && errors[`truck_tax_head`][`has_tax`]
            ? errors[`truck_tax_head`][`has_tax`][`message`]
            : false,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลรถ (ส่วนหัว)`}</h4>
        </div>

        {col4_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn5 = () => {
    const col5_menu = [
      {
        name: "truck_brand_tail.id",
        field_type: "select",
        field_label: "ยี่ห้อรถ*",
        disabled: disabledField.truck_tail.brand_name,
        // rules: {
        //   required:
        //     disabledField.truck_tail.brand_name === false
        //       ? "กรุณาเลือกยี่ห้อรถ"
        //       : null,
        // },
        options: allTruckBrand.map((brand) => {
          return {
            value: brand.id,
            name: brand.truck_brand_name,
          };
        }),
        onChange: (value) => {
          handleFieldChange("truck_brand_tail", value);
        },
        error:
          errors[`truck_brand_tail`] && errors[`truck_brand_tail`][`id`]
            ? true
            : false,
        helperText:
          errors[`truck_brand_tail`] && errors[`truck_brand_tail`][`id`]
            ? errors[`truck_brand_tail`][`id`][`message`]
            : false,
      },
      {
        name: "license_plate_tail.front_license_plate",
        field_type: "text",
        field_label: "ทะเบียนรถ (ตัวอักษรหรือตัวเลข 2 ตัวหน้า)*",
        disabled: disabledField.truck_tail.front_license_plate,
        rules: {
          // required:
          //   disabledField.truck_tail.front_license_plate === false
          //     ? "กรุณาใส่ทะเบียนรถ (ตัวอักษรหรือตัวเลข 2 ตัวหน้า)"
          //     : null,
          pattern:
            disabledField.truck_tail.front_license_plate === false
              ? {
                  value: helper.RegexValidate(`front_license_plate`),
                  message: "กรุณาใส่ตัวอักษรภาษาไทย 2-3 ตัว",
                }
              : null,
        },
        error:
          errors[`license_plate_tail`] &&
          errors[`license_plate_tail`][`front_license_plate`]
            ? true
            : false,
        helperText:
          errors[`license_plate_tail`] &&
          errors[`license_plate_tail`][`front_license_plate`]
            ? errors[`license_plate_tail`][`front_license_plate`][`message`]
            : false,
      },
      {
        name: "license_plate_tail.center_license_plate",
        field_type: "text",
        field_label: "ทะเบียนรถ (ตัวเลข 1-4 ตัว)*",
        disabled: disabledField.truck_tail.center_license_plate,
        rules: {
          // required:
          //   disabledField.truck_tail.center_license_plate === false
          //     ? "กรุณาใส่ทะเบียนรถ (ตัวเลข 1-4 ตัว)"
          //     : null,
          pattern:
            disabledField.truck_tail.center_license_plate === false
              ? {
                  value: helper.RegexValidate(`center_license_plate`),
                  message: "กรุณาใส่ตัวเลข 1-4 ตัว",
                }
              : null,
        },
        error:
          errors[`license_plate_tail`] &&
          errors[`license_plate_tail`][`center_license_plate`]
            ? true
            : false,
        helperText:
          errors[`license_plate_tail`] &&
          errors[`license_plate_tail`][`center_license_plate`]
            ? errors[`license_plate_tail`][`center_license_plate`][`message`]
            : false,
      },
      {
        name: "license_plate_tail.back_license_plate",
        field_type:
          disabledField.truck_tail.back_license_plate === false
            ? "select"
            : "text",
        field_label: "ทะเบียนรถ (จังหวัด)*",
        disabled: disabledField.truck_tail.back_license_plate,
        // rules: {
        //   required:
        //     disabledField.truck_tail.back_license_plate === false
        //       ? "กรุณาเลือกทะเบียนรถ (จังหวัด)"
        //       : null,
        // },
        options: allProvince.map((province) => {
          return {
            value: province.name,
            name: province.name,
          };
        }),
        error:
          errors[`license_plate_tail`] &&
          errors[`license_plate_tail`][`back_license_plate`]
            ? true
            : false,
        helperText:
          errors[`license_plate_tail`] &&
          errors[`license_plate_tail`][`back_license_plate`]
            ? errors[`license_plate_tail`][`back_license_plate`][`message`]
            : false,
      },
      {
        name: "registration_date_tail",
        field_type: "datePicker",
        field_label: "วันที่จดทะเบียน*",
        disabled: disabledField.truck_tail.registration_date_tail,
        // rules: {
        //   required:
        //     disabledField.truck_tail.registration_date_tail === false
        //       ? "กรุณาใส่วันที่จดทะเบียน"
        //       : null,
        // },
      },
      {
        name: "registration_file_tail",
        field_type: "upload",
        field_label: "ไฟล์เอกสารจดทะเบียน",
        disabled: disabledField.truck_tail.registration_file_tail,
        rules: {
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (disabledField.truck_tail.registration_file_tail === false) {
              if (R.isNil(file)) {
                return true;
              } else {
                if (typeof file !== "string") {
                  if (
                    !helper.isImageFile({ file: file }) &&
                    !helper.isPdfFile({ file: file })
                  ) {
                    return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
                  }
                }
                return true;
              }
            } else {
              return true;
            }
          },
        },
      },
      {
        name: "is_owner_truck_tail",
        field_type:
          disabledField.truck_tail.is_owner_truck_tail === false
            ? "select"
            : "text",
        field_label: "สถานะการเป็นเจ้าของรถ*",
        disabled: disabledField.truck_tail.is_owner_truck_tail,
        rules: {
          // required:
          //   disabledField.truck_tail.is_owner_truck_tail === false
          //     ? "กรุณาเลือกสถานะการเป็นเจ้าของรถ"
          //     : null,
          validate: (value) => {
            // if (disabledField.truck_tail.is_owner_truck_tail) {
            //   if (R.isEmpty(value)) {
            //     return "กรุณาเลือกสถานะการเป็นเจ้าของรถ";
            //   }
            //   return true;
            // } else {
            //   return true;
            // }
          },

          // validate: (value) => {
          //   if (R.isEmpty(value)) {
          //     return "กรุณาเลือกสถานะการเป็นเจ้าของรถ";
          //   }
          //   return true;
          // },
        },
        onChange: (value) => {
          handleFieldChange("is_owner_truck_tail", value);
        },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
      },
      {
        name: "consent_lend_truck_file_tail",
        field_type: "upload",
        field_label: "เอกสารยินยอมให้ใช้รถ",
        nameDescription: "(บังคับกรณีที่ไม่ใช่เจ้าของรถ)",
        disabled:
          disabledField.is_owner_truck_tail ||
          disabledField.truck_tail.consent_lend_truck_file_tail,
        rules: {
          // required:
          //   disabledField.truck_tail.consent_lend_truck_file_tail === false
          //     ? disabledField.is_owner_truck_tail === false
          //       ? "กรุณาใส่เอกสารยินยอมให้ใช้รถ"
          //       : null
          //     : null,
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (
              disabledField.truck_tail.consent_lend_truck_file_tail === false
            ) {
              if (disabledField.is_owner_truck_tail === false) {
                if (typeof file !== "string") {
                  if (
                    !helper.isImageFile({ file: file }) &&
                    !helper.isPdfFile({ file: file })
                  ) {
                    return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
                  }
                }
                return true;
              } else {
                return true;
              }
            }
          },
        },
      },
      {
        name: "truck_insurance_tail.has_insurance",
        field_type: "select",
        field_label: "สถานะการมีประกันภัยสินค้า*",
        disabled: disabledField.truck_tail.has_insurance,
        // rules: {
        //   required:
        //     disabledField.truck_tail.has_insurance === false
        //       ? "กรุณาเลือกสถานะการมีประกันภัยสินค้า"
        //       : null,
        //   // validate: (value) => {
        //   //   if (R.isEmpty(value)) {
        //   //     return "กรุณาเลือกสถานะการมีประกันภัยสินค้า";
        //   //   }
        //   //   return true;
        //   // },
        // },
        options: [
          { value: "true", name: `มี` },
          { value: "false", name: `ไม่มี` },
        ],
        onChange: (value) => {
          handleFieldChange("truck_insurance_tail.has_insurance", value);
        },
        error:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`has_insurance`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`has_insurance`]
            ? errors[`truck_insurance_tail`][`has_insurance`][`message`]
            : false,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลรถ (ส่วนหาง)`}</h4>

          <Button
            type={`button`}
            disabled={disabledField.truck_tail.selected_exists_truck}
            onClick={() => {
              setSelectTruckModal({
                open: true,
                select_type: `tail`,
              });
            }}
          >{`เลือกส่วนหางจากรถที่เพิ่มแล้ว`}</Button>
        </div>

        {col5_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn6 = () => {
    const col6_menu = [
      {
        name: "truck_insurance_tail.cover_insurance",
        field_type: "text",
        field_label: "จำนวนทุนประกันภัยสินค้า*",
        disabled: disabledField.truck_tail.cover_insurance,
        nameDescription:
          "(กรณีไม่มีประกันฯ ไม่ต้องระบุ และกรณีที่จำนวนทุนประกันฯ ในฐานข้อมูลมีค่าเท่ากับ 0 ไม่ต้องระบุ)",
        rules: {
          // required:
          //   disabledField.truck_tail.cover_insurance === false
          //     ? "กรุณาใส่จำนวนทุนประกันภัยสินค้า"
          //     : null,
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
        error:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`cover_insurance`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`cover_insurance`]
            ? errors[`truck_insurance_tail`][`cover_insurance`][`message`]
            : false,
      },
      {
        name: "truck_insurance_tail.expired_insurance_date",
        field_type: "datePicker",
        field_label: "วันหมดอายุประกันภัยสินค้า*",
        disabled: disabledField.truck_tail.expired_insurance_date,
        nameDescription:
          "(กรณีไม่มีประกันฯ ไม่ต้องระบุ และกรณีที่จำนวนทุนประกันฯ ในฐานข้อมูลมีค่าเท่ากับ 0 ไม่ต้องระบุ)",
        // rules: {
        //   required:
        //     disabledField.truck_tail.expired_insurance_date === false
        //       ? "กรุณาใส่วันหมดอายุประกันภัยสินค้า"
        //       : null,
        // },
        error:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`expired_insurance_date`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`expired_insurance_date`]
            ? errors[`truck_insurance_tail`][`expired_insurance_date`][
                `message`
              ]
            : false,
        disabledPreviousDay: true,
      },
      {
        name: "truck_insurance_tail.insurance_file",
        field_type: "upload",
        field_label: "เอกสารกรมธรรม์",
        // nameDescription: "(บังคับกรณีที่ไม่ใช่เจ้าของรถ)",
        disabled: disabledField.truck_tail.insurance_file,
        rules: {
          // required:
          //   disabledField.truck_tail.insurance_file === false
          //     ? "กรุณาใส่ไฟล์กรมธรรม์"
          //     : null,
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (disabledField.truck_tail.insurance_file === false) {
              if (disabledField.truck_tail.insurance_file === false) {
                if (typeof file !== "string") {
                  if (
                    !helper.isImageFile({ file: file }) &&
                    !helper.isPdfFile({ file: file })
                  ) {
                    return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
                  }
                }
                return true;
              } else {
                return true;
              }
            }
          },
        },
        error:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`insurance_file`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`insurance_file`]
            ? errors[`truck_insurance_tail`][`insurance_file`][`message`]
            : false,
      },
      {
        name: "truck_insurance_tail.accept_trip_insurance",
        field_type: "select",
        field_label: "ยินยอมให้หักประกันภัยสินค้ารายเที่ยว*",
        disabled: disabledField.truck_tail.accept_trip_insurance,
        nameDescription:
          "(บังคับกรณีไม่มีประกันฯ และกรณีที่จำนวนทุนประกันฯ ในฐานข้อมูลมีค่าเท่ากับ 0 ไม่ต้องระบุ)",
        // rules: {
        //   required:
        //     disabledField.truck_tail.accept_trip_insurance === false
        //       ? "กรุณาเลือกการยินยอมให้หักประกันภัยสินค้ารายเที่ยว"
        //       : null,
        // },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        error:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`accept_trip_insurance`]
            ? true
            : false,
        helperText:
          errors[`truck_insurance_tail`] &&
          errors[`truck_insurance_tail`][`accept_trip_insurance`]
            ? errors[`truck_insurance_tail`][`accept_trip_insurance`][`message`]
            : false,
      },
      {
        name: "truck_tax_tail.truck_tax_file",
        field_type: "upload",
        field_label: "ไฟล์เอกสารภาษีรถ*",
        disabled: disabledField.truck_tail.truck_tax_file,
        rules: {
          // required:
          //   disabledField.truck_tail.truck_tax_file === false
          //     ? "กรุณาใส่ไฟล์เอกสารภาษีรถ"
          //     : null,
          validate: async (file) => {
            if (!file) {
              return true;
            }
            if (disabledField.truck_tail.truck_tax_file === false) {
              if (typeof file !== "string") {
                if (
                  !helper.isImageFile({ file: file }) &&
                  !helper.isPdfFile({ file: file })
                ) {
                  return "อัพโหลดไฟล์นามสกุล PNG/JPG/PDF เท่านั้น";
                }
              }
              return true;
            } else {
              return true;
            }
          },
        },
        error:
          errors[`truck_tax_tail`] && errors[`truck_tax_tail`][`truck_tax_file`]
            ? true
            : false,
        helperText:
          errors[`truck_tax_tail`] && errors[`truck_tax_tail`][`truck_tax_file`]
            ? errors[`truck_tax_tail`][`truck_tax_file`][`message`]
            : false,
      },
      {
        name: "truck_tax_tail.expired_truck_tax_date",
        field_type: "datePicker",
        field_label: "วันที่หมดอายุภาษีรถ*",
        disabled: disabledField.truck_tail.expired_truck_tax_date,
        // rules: {
        //   required:
        //     disabledField.truck_tail.expired_truck_tax_date === false
        //       ? "กรุณาใส่วันที่หมดอายุภาษีรถ"
        //       : null,
        // },
        error:
          errors[`truck_tax_tail`] &&
          errors[`truck_tax_tail`][`expired_truck_tax_date`]
            ? true
            : false,
        helperText:
          errors[`truck_tax_tail`] &&
          errors[`truck_tax_tail`][`expired_truck_tax_date`]
            ? errors[`truck_tax_tail`][`expired_truck_tax_date`][`message`]
            : false,
        disabledPreviousDay: true,
      },
      {
        name: "truck_tax_tail.has_tax",
        field_type:
          disabledField.truck_tail.has_tax === false ? "select" : "text",
        field_label: "สถานะการต่อภาษี*",
        disabled: disabledField.truck_tail.has_tax,
        // rules: {
        //   required:
        //     disabledField.truck_tail.has_tax === false
        //       ? "กรุณาเลือกสถานะการต่อภาษี"
        //       : null,
        // },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
        error:
          errors[`truck_tax_tail`] && errors[`truck_tax_tail`][`has_tax`]
            ? true
            : false,
        helperText:
          errors[`truck_tax_tail`] && errors[`truck_tax_tail`][`has_tax`]
            ? errors[`truck_tax_tail`][`has_tax`][`message`]
            : false,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h4>{`ข้อมูลรถ (ส่วนหาง)`}</h4>
        </div>

        {col6_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderColumn7 = () => {
    const col7_menu = [
      {
        name: "document_approve_status",
        field_type: "select",
        value: getValues().document_approve_status,
        options: truck_document_approve_status.map((approve_status) => {
          return {
            value: approve_status.name,
            name: approve_status.name,
          };
        }),
        onChange: (value) => {
          handleFieldChange("document_approve_status");

          if (value === oldTruckData.document_approve_status) {
            setIsChangeApprovedStatus(false);
          } else {
            setIsChangeApprovedStatus(true);
          }
        },
      },
      {
        name: "reason_for_edit",
        field_type: "text",
        field_label: "เหตุผล",
        disabled: disabledField.reason_for_edit,
        descriptionUnderField:
          "เหตุผลสำหรับกรณีมีสถานะเอกสารเป็นแก้ไขเพิ่มเติม",
      },
      {
        name: "approved_at",
        field_type: "datePicker",
        field_label: "วันที่อนุมัติล่าสุด",
        showTime: true,
        disabled: true,
      },
      {
        name: "approved_admin_info.full_name",
        field_type: "text",
        field_label: "ผู้ดูแลระบบ (ผู้ตรวจสอบ)",
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        {col7_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
        <ButtonCustom
          name={`บันทึกสถานะการตรวจสอบเอกสาร`}
          onClick={handleApproved}
          fullWidth={true}
        />
      </div>
    );
  };

  const renderSubMenu = () => {
    const split_pathname = location.pathname
      ? location.pathname.split("/")
      : [];
    const _full_license_plate_head =
      getValues().license_plate_head &&
      getValues().license_plate_head.full_license_plate
        ? getValues().license_plate_head.full_license_plate
        : ``;
    const _full_license_plate_tail =
      getValues().license_plate_tail &&
      getValues().license_plate_tail.full_license_plate
        ? ` - ${getValues().license_plate_tail.full_license_plate}`
        : ``;

    return (
      <SubMenu
        label={`รายละเอียดข้อมูลรถ`}
        // pathbackward={`/user/${user_id}/truck-in-profile/${profile_id}`}
        // pathbackward={-1}
        pathbackward={
          !R.isNil(profile_id)
            ? location.pathname.split("/detail")[0]
            : `/truck`
        }
        // stateNavigation={{
        //   prevPath: R.isNil(
        //     split_pathname.find((item) => item === "truck-in-profile")
        //   )
        //     ? `/truck`
        //     : `/user/${user_id}/profile/${profile_id}/truck-in-profile`,
        // }}
        menus={[
          {
            label: "รายละเอียดข้อมูลรถ",
            path: `#`,
            active: true,
          },
          {
            label: "การตรวจสภาพรถ",
            // path: `/truck/${getValues().id}/truck-check-project`,
            path: R.isNil(
              split_pathname.find((item) => item === `truck-in-profile`)
            )
              ? `/truck/${getValues().id}/truck-check-project`
              : `/user/${user_id}/profile/${profile_id}/truck-in-profile/${
                  getValues().id
                }/truck-check-project`,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label:
              getValues().profile && getValues().profile.user_info
                ? getValues().profile.user_info.user_code
                : `null`,
            path: null,
          },
          {
            label: "ข้อมูลรถในโปรไฟล์",
            path: null,
          },
          {
            label: `${_full_license_plate_head} ${_full_license_plate_tail}`,
            path: null,
          },
          {
            label: "รายละเอียดข้อมูลรถ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
          handleGetTruck();
        }}
      />
    ) : null;
  };

  const renderSelectTruckModal = () => {
    return (
      <CustomModal
        open={selectTruckModal.open}
        onConfirm={async () => {
          if (!R.isEmpty(selectTruckModal.data)) {
            const selected_data = selectTruckModal.data;
            const resp = await getTruck(selected_data.id);
            if (resp.data.code === 1000) {
              const data = resp.data.data.results;
              setExceptionTruckIdInTruckModal({
                ...exceptionTruckIdInTruckModal,
                [`truck_${selectTruckModal.select_type}`]: data.id,
              });
              const field = [
                `license_plate`,
                `registration_date`,
                `is_owner_truck`,
                `registration_file`,
                `consent_lend_truck_file`,
                `truck_insurance`,
                `truck_tax`,
                `truck_brand`,
              ];

              field.forEach((_field) => {
                const key = `${_field}_${selectTruckModal.select_type}`;
                clearErrors(key);
                if (
                  key === `truck_insurance_head` ||
                  key === `truck_insurance_tail`
                ) {
                  if (!R.isNil(data[key])) {
                    let _truck_insurance = data[key];
                    let truck_insurance_data = {};
                    const disabled_field_key =
                      key === `truck_insurance_head`
                        ? `truck_head`
                        : `truck_tail`;

                    if (
                      disabledField[disabled_field_key].has_insurance === false
                    ) {
                      if (_truck_insurance.has_insurance === true) {
                        truck_insurance_data = {
                          ...truck_insurance_data,
                          has_insurance: `true`,
                          cover_insurance: _truck_insurance.cover_insurance,
                          expired_insurance_date:
                            _truck_insurance.expired_insurance_date,
                          accept_trip_insurance: null,
                          insurance_file: _truck_insurance.insurance_file,
                        };
                      } else {
                        truck_insurance_data = {
                          ...truck_insurance_data,
                          has_insurance: `false`,
                          cover_insurance: null,
                          expired_insurance_date: null,
                          accept_trip_insurance:
                            _truck_insurance.accept_trip_insurance === true
                              ? `true`
                              : `false`,

                          insurance_file: null,
                        };
                      }
                    } else {
                      truck_insurance_data = {
                        ...truck_insurance_data,
                        has_insurance: null,
                      };
                    }

                    setValue(key, truck_insurance_data);
                  } else {
                    setValue(key, {
                      id: null,
                      has_insurance: null,
                      cover_insurance: null,
                      expired_insurance_date: "",
                      accept_trip_insurance: null,
                    });
                  }
                } else if (
                  key === `is_owner_truck_head` ||
                  key === `is_owner_truck_tail`
                ) {
                  const _is_owner_truck = data[key] === true ? `true` : `false`;
                  setValue(key, _is_owner_truck);
                  // handleDisabledField();
                } else if (
                  key === `truck_tax_head` ||
                  key === `truck_tax_tail`
                ) {
                  if (!R.isNil(data[key])) {
                    let _truck_tax = data[key];

                    _truck_tax.has_tax =
                      _truck_tax.has_tax === true ? `true` : `false`;
                    setValue(key, _truck_tax);
                  } else {
                    setValue(key, {
                      truck_tax_file: "",
                      expired_truck_tax_date: "",
                      has_tax: "",
                    });
                  }
                } else {
                  setValue(key, data[key]);
                }
              });

              handleDisabledField();
            }
          }

          setSelectTruckModal({ ...selectTruckModal, open: false, data: {} });
        }}
        onCancel={() => {
          setSelectTruckModal({ ...selectTruckModal, open: false, data: {} });
        }}
        disabledComfirmBtn={
          R.isNil(selectTruckModal.data) || R.isEmpty(selectTruckModal.data)
        }
      >
        <SelectTruckInProfile
          profile_id={profileId}
          truck_info={getValues() ? getValues() : {}}
          exception_truck_id={[
            exceptionTruckIdInTruckModal.truck_head,
            exceptionTruckIdInTruckModal.truck_tail,
          ]}
          onSelect={(truck_data) => {
            setSelectTruckModal({ ...selectTruckModal, data: truck_data });
          }}
        />
      </CustomModal>
    );
  };
  const getUser = async () => {
    await api
      .getUser({ shipper_code: watchCarrier })
      .then(async (response) => {
        console.log("response,", response);
        setValue("carrier_id", response.data.data.id);
        setValue("company_name", response.data.data.company_name);
      })
      .catch((error) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text: "ไม่พบรหัสผู้ใช้งาน",
          icon: "error",
          showConfirmButton: false,
        });
      });
  };
  const changeCarrierFunc = async (value) => {
    console.log("value", value);
    setVisibleChangeCarrier(false);

    setIsLoading(true);
    try {
      const data = {
        carrier_id: value.carrier_id,
      };
      await changeCarrier(id, data).then(async (update_resp) => {
        if (update_resp.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
        reset();
        await handleGetTruck();
      });

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message
          ? error.response.data.description.message
          : error.response.data.description,
      });
      setIsLoading(false);
    }
  };
  const renderChangeCarrierModal = () => {
    return (
      <Modal open={visibleChangeCarrier}>
        <Box
          sx={{
            ...style,
            minHeight: "80vmin",
            position: "relative",
          }}
        >
          <div
            style={{
              float: "right",
              cursor: "pointer",
              textAlign: "end",
              padding: "10px 10px 0px 10px",
            }}
            onClick={() => {
              setVisibleChangeCarrier(false);
            }}
          >
            <CloseIcon sx={{ color: "#8ca3ba" }} />
          </div>
          <div style={{ padding: "0px 20px 100px 20px" }}>
            <div style={{ textAlign: "center" }}>ย้ายสังกัด</div>
            <div>
              <div style={{ marginTop: -20 }}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputNormal
                      label="รหัสผู้ใช้งาน*"
                      onChange={onChange}
                      value={value}
                      error={errors.carrier_code}
                    />
                  )}
                  name="carrier_code"
                  defaultValue=""
                />
              </div>
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={() => {
                    getUser();
                  }}
                >
                  ค้นหา
                </Button>
              </div>
              {watchCompanyName ? (
                <div>
                  <InputDisable
                    label={"ชื่อโปรไฟล์"}
                    value={watchCompanyName}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div
            style={{
              // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: 20,
              // borderRadius: 20,
              bottom: 0,
              position: "absolute",
              textAlign: "center",
              width: "100%",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outline"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    color: "#8ca3ba",
                    borderColor: "#8ca3ba",
                    height: 50,
                  }}
                  color="secondary"
                  onClick={() => {
                    setVisibleChangeCarrier(false);
                  }}
                >
                  ยกเลิก
                </Button>
              </div>

              <div style={{ flex: 1, marginLeft: 20 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  style={{ borderRadius: "10px", marginTop: 20 }}
                  sx={{ height: 50 }}
                  color="secondary"
                  onClick={handleSubmit(changeCarrierFunc)}
                >
                  ยืนยัน
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  return !isLoading ? (
    <>
      <Container>{renderSubMenu()}</Container>
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateTruck();
        })}
      >
        {renderColumn1()}
        {renderColumn2()}
        {renderColumn3()}
        {renderColumn4()}
        {renderColumn5()}
        {renderColumn6()}
        {renderColumn7()}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />
      </form>

      {renderModal()}
      {renderSelectTruckModal()}
      {renderChangeCarrierModal()}
    </>
  ) : (
    <Loading />
  );
};

export default TruckInfo;
