import * as React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import Title from "./Title";
import "./chart.css";
import { linearRegression, mean } from "simple-statistics";
import * as currency from "currency.js";

// const data = [
//   {
//     name: "string",
//     value: "number"
//     data: "json"
//   },
// ];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload.length > 0) {
    const desc = payload[0].payload.data;
    const desc_lr = payload[0].payload.linear;

    return (
      <div className="chart-tooltip">
        {Object.keys(desc).map((key, index) => (
          <div key={key}>
            <p className="chart-tooltip-text">
              {key}: {desc[key]}
            </p>
          </div>
        ))}
        <div>
          <p className="chart-tooltip-text">
            เส้นคาดการณ์: {currency(desc_lr, { symbol: "" }).format()}
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default function Chart({ title, data, color }) {
  const [rawData, setRawData] = React.useState(data);
  const [maxY, setMaxY] = React.useState(0);
  const [rSquared, setRSquared] = React.useState(0);

  React.useEffect(() => {
    const calculateLinearRegression = () => {
      const regression = linearRegression(
        data.map((item, index) => [index, parseInt(item.value)])
      );

      const minX = 0;
      const maxX = data.length - 1;
      const regressionData = [];
      for (let x = minX; x <= maxX; x++) {
        const y = regression.m * x + regression.b;
        const name = data[x].name;
        regressionData.push({ name, y });
      }

      const yActualMean =
        data.reduce((sum, item) => sum + parseInt(item.value), 0) / data.length;
      const yActualVariance = data.reduce(
        (sum, item) => sum + Math.pow(parseInt(item.value) - yActualMean, 2),
        0
      );

      const yPredictedVariance = regressionData.reduce(
        (sum, item) => sum + Math.pow(item.y - yActualMean, 2),
        0
      );

      // คำนวณ R-squared
      const rSquared = yPredictedVariance / yActualVariance;
      setRSquared(currency(rSquared).value);

      const filterRegressionData = regressionData.map((item) => {
        if (item.y < 0) {
          return {
            name: item.name,
            y: 0,
          };
        }
        return item;
      });
      for (const key in filterRegressionData) {
        rawData[key].linear = filterRegressionData[key].y;
      }
    };

    const calculateMovingAverages = async () => {
      const ma7 = await calculateMA(data, 7);
      const ma30 = await calculateMA(data, 30);
      console.log({ ma7, ma30 });
      for (const key in ma7) {
        const val = ma7[key];
        const filterKey = "name";
        const filterValue = val.name;
        let filteredIndexes = -1;
        const arrayOfObjects = rawData;
        for (let i = 0; i < arrayOfObjects.length; i++) {
          if (arrayOfObjects[i][filterKey] === filterValue) {
            filteredIndexes = i;
            break;
          }
        }
        if (filteredIndexes !== -1) {
          rawData[filteredIndexes].ma7 = val.ma;
        }
      }
      for (const key in ma30) {
        const val = ma30[key];
        const filterKey = "name";
        const filterValue = val.name;
        let filteredIndexes = -1;
        const arrayOfObjects = rawData;
        for (let i = 0; i < arrayOfObjects.length; i++) {
          if (arrayOfObjects[i][filterKey] === filterValue) {
            filteredIndexes = i;
            break;
          }
        }
        if (filteredIndexes !== -1) {
          rawData[filteredIndexes].ma30 = val.ma;
        }
      }
    };

    const calculateMA = (data, period) => {
      const maData = [];
      for (let i = 0; i < data.length; i++) {
        if (i >= period - 1) {
          const values = data
            .slice(i - period + 1, i + 1)
            .map((item) => parseInt(item.value));
          maData.push({
            name: data[i].name,
            ma: mean(values),
          });
        }
      }
      return maData;
    };

    const y = data.reduce((oldVal, newVal) => {
      const numberVal = parseInt(newVal.value);
      if (numberVal > oldVal) {
        return numberVal;
      }
      return oldVal;
    }, 0);
    setMaxY(y);

    calculateLinearRegression();
    calculateMovingAverages();

    return () => {
      setRawData(rawData);
    };
  }, [data]);

  let subColor2499EF = "27CE88";
  if (title === "รายได้จากงานที่จับคู่แล้ว") {
    subColor2499EF = "8C8DFF";
  }
  if (title === "จำนวนผู้ใช้งาน") {
    subColor2499EF = "FFC675";
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title>{title}</Title>
        <b>
          R<sup>2</sup> : {rSquared}
        </b>
      </div>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={400}
          data={rawData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 10,
          }}
        >
          <defs>
            <linearGradient id="color2499EF" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2499EF" stopOpacity={1} />
              <stop
                offset="95%"
                stopColor={`#${subColor2499EF}`}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorFF316F" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF316F" stopOpacity={1} />
              <stop offset="95%" stopColor="#FFC675" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color000000" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#000000" stopOpacity={0.0005} />
              <stop offset="95%" stopColor="#000000" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, maxY]} />
          <Tooltip content={<CustomTooltip />} />
          <Area
            type="linear"
            dataKey="value"
            stroke={`#${color}`}
            fillOpacity={1}
            fill={`url(#color${color})`}
          />
          <Area
            type="linear"
            dataKey="linear"
            stroke={`#000000`}
            fillOpacity={1}
            fill={`url(#color000000)`}
          />
          <Area
            type="linear"
            dataKey="ma7"
            stroke={`#ff316f`}
            fillOpacity={1}
            fill={`url(#color000000)`}
          />
          <Area
            type="linear"
            dataKey="ma30"
            stroke={`#a020f0`}
            fillOpacity={1}
            fill={`url(#color000000)`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
