import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import Title from "./Title";
import "./chart.css";
import CircleIcon from "@mui/icons-material/Circle";

// const data = [
//   {
//     name: "string",
//     value: "array[number]"
//     data: "json"
//   },
// ];

function getRandomColorCode() {
  const letters = "0123456789ABCDEF";
  let colorCode = "#";
  for (let i = 0; i < 6; i++) {
    colorCode += letters[Math.floor(Math.random() * 16)];
  }
  return colorCode;
}

export default function Chart({ title, data }) {
  const color = data.reduce((acc, item) => {
    const valueKeys = Object.keys(item.value);
    valueKeys.forEach((key) => {
      if (!acc[key]) {
        acc[key] = getRandomColorCode();
      }
    });
    return acc;
  }, {});

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0) {
      const desc = payload[0].payload.value;
      return (
        <div className="chart-tooltip">
          {Object.keys(desc).map((key) => (
            <p key={key} className="chart-tooltip-text">
              <CircleIcon sx={{ fontSize: "10px", color: color[key] }} /> {key}:{" "}
              {desc[key]}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <ResponsiveContainer width="100%" height={260}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 15,
            left: 15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {Object.keys(color).map((key) => (
            <Bar
              key={key}
              dataKey={`value.${key}`}
              stackId="a"
              fill={color[key]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
