import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import TemplateForm from "components/templateForm/TemplateForm";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import { getUserById } from "utils/api/user/user";
import helper from "utils/helper";
import styles from "./ProfileJoinCarrierInfo.module.css";
import {
  getProfileJoinCarrier,
  updateProfileJoinCarrier,
} from "utils/api/profile/profileJoinCarrier";
import Button from "components/button/Button";

const ProfileJoinCarrierInfo = () => {
  const { user_id, profile_id, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [pageType, setPageType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState(``);
  const [userInfo, setUserInfo] = useState({});
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    checkPageType();
    handleGetProfileJoinCarrier();
    handleGetUserInfo();

    // eslint-disable-next-line
  }, []);

  const checkPageType = () => {
    const page_type = location.pathname
      .split("/")
      .find((item) => item === "participate-runner-project");

    if (!R.isNil(page_type)) {
      setPageType("participate-runner-project");
    } else {
      setPageType("co-runner-project");
    }
  };

  const handleGetProfileJoinCarrier = async () => {
    setIsLoading(true);
    try {
      const resp = await getProfileJoinCarrier(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(data.company_name);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getProfileJoinCarrier: ", error);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
      // navigate("/truck/truck-check-project");
    }
  };

  const handleGetUserInfo = async () => {
    try {
      const resp = await getUserById(user_id);
      setUserInfo(resp.data.data);
    } catch (error) {
      console.log("error handleGetUserInfo: ", error);
    }
  };

  const handleUpdateProfileJoinCarrier = async () => {
    setIsLoading(true);
    try {
      const data = getValues();

      const body = {
        owner_id: data.owner_profile.id,
        profile_id: data.co_runner_profile.id,
        active: data.active === `true` ? true : false,
      };

      const update_resp = await updateProfileJoinCarrier(id, body);
      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message
          ? error.response.data.description.message
          : error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  const renderSubMenu = () => {
    let label = {
      menu_label: "",
      profile_user_code: "",
    };
    if (pageType === `participate-runner-project`) {
      label = {
        menu_label: "รายละเอียดข้อมูลผู้ขนส่งร่วมวิ่ง",
        profile_user_code:
          getValues().co_runner_profile &&
          getValues().co_runner_profile.user_code
            ? getValues().co_runner_profile.user_code
            : ``,
      };
    } else if (pageType === `co-runner-project`) {
      label = {
        menu_label: "รายละเอียดข้อมูลการร่วมวิ่งกับผู้ขนส่งอื่น",
        profile_user_code:
          getValues().owner_profile && getValues().owner_profile.user_code
            ? getValues().owner_profile.user_code
            : ``,
      };
    }

    return (
      <SubMenu
        label={label.menu_label}
        pathbackward={location.pathname.split("/detail")[0]}
        menus={[
          {
            label: label.menu_label,
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label: userInfo ? userInfo.user_code : ``,
            path: null,
          },
          {
            label: "ข้อมูลผู้ขนส่งร่วมวิ่ง",
            path: null,
          },
          {
            label: label.profile_user_code,
            path: null,
          },
          {
            label: label.menu_label,
            path: null,
          },
        ]}
      />
    );
  };

  const renderColumn1 = () => {
    const form1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          required: "กรุณาเลือกสถานะใช้งาน",
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
        disabled: pageType === `participate-runner-project` ? false : true,
      },
      {
        name:
          pageType === `participate-runner-project`
            ? `co_runner_profile.company_name`
            : pageType === `co-runner-project`
            ? `owner_profile.company_name`
            : ``,
        field_type: "text",
        field_label: "ชื่อโปรไฟล์*",
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {form1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}

        <Button
          type={`button`}
          onClick={() => {
            if (pageType === `participate-runner-project`) {
              navigate(
                `/user/${user_id}/profile/${getValues().co_runner_profile.id}`
              );
            } else {
              navigate(
                `/user/${user_id}/profile/${getValues().owner_profile.id}`
              );
            }
          }}
        >{`ดูโปรไฟล์`}</Button>
      </div>
    );
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateProfileJoinCarrier();
        })}
      >
        {renderColumn1()}

        {pageType === `participate-runner-project` ? (
          <FloatingButton
            button_type="save"
            onClick={() => {
              // updateTruckType();
            }}
          />
        ) : null}

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default ProfileJoinCarrierInfo;
