import React, { useEffect, useRef, useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import { useCookies } from "react-cookie";
import { useForm, Controller } from "react-hook-form";
import "./Login.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckboxOutline from "components/input/checkboxOutline";
import auth from "utils/api/auth/auth";
import LoadingBackground from "components/loading/LoadingBackground";
import GrayButton from "components/button/GrayButton";
import OtpInput from "components/input/otpInput";
import Swal from "sweetalert2";
let api = auth.create();
export default function ForgetPassword(props) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkOTP, setCheckOTP] = useState(false);
  const [delay, setDelay] = useState(60);
  // const delay = useRef(60);
  let inDelay;
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({});
  const watchEmail = watch("email");
  const watchOTP = watch("otp");
  const watchRef = watch("ref");
  const watchDelay = watch("delay");
  const error = {
    email: "กรุณากรอกอีเมลให้ถูกต้อง",
    otp: "รหัส OTP ไม่ถูกต้องหรือหมดอายุแล้ว",
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (cookies.access_token) {
      // removeCookie("access_token");
      window.location.href = "/";
      // setCheckLogin(true);
    }
  }, [cookies]);

  useEffect(() => {
    // console.log("here");
    if (watchDelay) {
      let interval = setInterval(() => {
        // console.log("watchDelay ?? ", watchDelay);
        // delay.current = delay.current - 1;
        if (watchDelay > 0) {
          setValue("delay", watchDelay - 1);
        } else {
          // clearInterval(inDelay);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [watchDelay]);
  const reset = (data) => {
    // console.log(data);
    const body = {
      email: data.email,
    };
    setLoading(true);
    api
      .resetPassowrd(body)
      .then((response) => {
        console.log(response);
        setLoading(false);
        setValue("ref", response.data.results.ref);
        setCheckOTP(true);
        setValue("delay", 60);
        // setDelay(response.data.results.delay);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code === 4101) {
          setError("email");
        } else {
          alert("เกิดข้อผิดพลาด");
        }
        setLoading(false);
      });
  };
  const sendOtp = (data) => {
    let otp = "";
    data.otp.forEach((ele) => {
      otp += ele;
    });
    let body = {
      otp_ref: data.ref,
      otp: otp,
    };
    // console.log("body", body);
    setLoading(true);
    api
      .checkOtp(body)
      .then((response) => {
        console.log(response);
        setLoading(false);
        window.location.href =
          "/reset-password?reset_code=" + response.data.results.reset_code;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code === 4101) {
          setError("otp");
        }
        if (error.response.data.code === 4103) {
          setError("otp");
        } else {
          Swal.fire({
            timer: 2000,
            title: "error: " + error.response.data.code,
            text: error.response.data.description.message,
            icon: "error",
            showConfirmButton: false,
          });
        }
        setLoading(false);
      });
  };

  const renew = () => {
    reset({ email: watchEmail });
  };
  return (
    <div>
      {loading ? <LoadingBackground /> : null}

      <div className="loginBody">
        <div className="loginBodySlide">
          <img
            src={require("../../assets/image/ResetSlide.png")}
            alt="Slide"
            height={"40%"}
          />
        </div>
        {checkOTP ? (
          <div className="loginBodyForm">
            <div className="loginBodySubForm">
              <img
                src={require("../../assets/image/WemoveLogo.png")}
                alt="logo"
                height={80}
              />
              <Typography component="h1" variant="h4">
                ยืนยันรหัส OTP
              </Typography>
              <label>โปรดระบุรหัส OTP ที่คุณได้รับทางอีเมล</label>
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: () => {
                    if (watchOTP.length === 6) {
                      let check = true;
                      watchOTP.forEach((element) => {
                        if (element === "") {
                          check = false;
                        }
                      });
                      return check;
                    } else return false;
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <OtpInput
                    onChange={(value) => {
                      setValue("otp", value);
                    }}
                    error={errors.otp}
                    value={value}
                    otpRef={watchRef}
                  />
                )}
                name="otp"
                defaultValue=""
              />
              {errors.otp && (
                <div
                  style={
                    {
                      // display: "flex",
                      // alignItems: "center",
                      // textAlign: "start",
                    }
                  }
                >
                  <label
                    style={{
                      color: "#ff316f",
                      fontSize: 10,
                    }}
                  >
                    {error.otp}
                  </label>
                </div>
              )}

              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit(sendOtp)}
                >
                  ยืนยัน OTP
                </Button>
              </div>
              <label style={{ fontSize: 14 }}>
                ขอรหัส OTP อีกครั้งภายใน {watchDelay} วินาที
              </label>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={watchDelay > 0}
                  size="large"
                  color="primary"
                  onClick={() => {
                    renew();
                  }}
                >
                  ขอรหัส OTP ใหม่
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="loginBodyForm">
            <div className="loginBodySubForm">
              <img
                src={require("../../assets/image/WemoveLogo.png")}
                alt="logo"
                height={80}
              />
              <Typography component="h1" variant="h4">
                ลืมรหัสผ่าน
              </Typography>
              <label>โปรดระบุอีเมลสำหรับรับ OTP เพื่อตั้งรหัสผ่านใหม่</label>
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: () => {
                    if (
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]/.test(
                        watchEmail
                      )
                    )
                      return true;
                    else return false;
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="normal"
                  >
                    <InputLabel
                      htmlFor="standard-adornment-username"
                      color={errors.email ? "error" : "primary"}
                    >
                      อีเมล
                    </InputLabel>
                    <Input
                      type={"email"}
                      error={errors.email}
                      id="email"
                      label="อีเมล"
                      value={value}
                      name="email"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="email"
                defaultValue=""
              />
              {errors.email && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                  }}
                >
                  <label
                    style={{
                      color: "#ff316f",
                      fontSize: 10,
                    }}
                  >
                    {error.email}
                  </label>
                </div>
              )}

              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit(reset)}
                >
                  ยืนยัน
                </Button>
              </div>
              <GrayButton
                onClick={() => {
                  window.history.back();
                }}
              >
                ยกเลิก
              </GrayButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
