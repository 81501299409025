import SubMenu from "components/layout/SubMenu.js";
import SubMenuCustom from "components/layout/SubMenu.js/SubMenuCustom";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import "./PostWork.css";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import InputDisable from "components/input/inputDisable";
import ButtonSwitch from "components/input/buttonSwitch";
import SelectLocation from "components/work/selectLocation";
import Line from "components/layout/line";
import SelectTruck from "components/work/selectTruck";
import SelectProduct from "components/work/selectProduct";
import SelectService from "components/work/selectService";
import SelectDateTime from "components/work/selectDateTime";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import SelectBilling from "components/work/selectBilling";
import SelectRemark from "components/work/selectRemark";
import SelectPostName from "components/work/selectPostName";
import SelectFavCarrier from "components/work/selectFavCarrier";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { getTruckType } from "utils/api/masterData/truckType";
import InputNormal from "components/input/inputNormal";
import API from "../../utils/api/work/work";
import Swal from "sweetalert2";
import InputSelect from "components/input/inputSelect";
import HelperMap from "utils/helperMap";
import masterProduct from "utils/api/masterData/productType";
import masterProvice from "utils/api/masterData/provice";
import ModalWork from "components/work/modalWork";
import helper from "utils/helper";
import InputUpload from "components/input/inputUpload";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import io from "socket.io-client";
import { styled } from "@mui/material/styles";
import TableCustom from "components/table/TableCustom";
import ENV from "utils/environment";
const api = API.create();
const menus = [
  {
    label: "ติดตามคนขับทั้งหมด",
    path: "/tracking-driver",
    active: true,
  },
];

const breadcrumbs = [
  {
    label: "ติดตามคนขับทั้งหมด",
    path: null,
  },
];

export default function TrackingDriver() {
  const [truckType, setTruckType] = useState([]);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState();

  //   const [circle, setCircle] = useState();

  const getTruck = async () => {
    let truck = await getTruckType({
      sort_field: "order_number",
      sort_order: 1,
    });
    console.log(truck);
    let mapPv = truck.data.data.results.map((ele) => {
      return { ...ele, item: ele.name, value: ele.id };
    });
    console.log("mapPv", mapPv);
    setTruckType(mapPv);
  };
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      // lat: 14.9037279,
      // long: 102.0525054,
      // radius: 30,
    },
  });
  const watchRadius = watch("radius");
  const watchLat = watch("lat");
  const watchLong = watch("long");

  useEffect(() => {
    getTruck();
  }, []);
  const getTracking = async (data) => {
    console.log("get,", data);
    await setData();
    await map.Overlays.clear();
    await renderRadius();
    await api
      .getTracking(data)
      .then((response) => {
        console.log("response get", response);
        setTotal(response.data.total);
        setData(response.data.results);
      })
      .catch((e) => {
        Swal.fire({
          timer: 2000,
          title: "error: " + e.response.data.code,
          text: e.response.data.description,
          icon: "error",
          showConfirmButton: false,
        });
      });
  };
  const initMap = () => {
    console.log("init map");
  };
  useEffect(() => {
    if (data) {
      renderMarker(data);
    }
  }, [data]);
  const renderMarker = async (data) => {
    if (data.length > 0) {
      let mark = [];
      for (const { ele, index } of data.map((ele, index) => ({
        ele,
        index,
      }))) {
        const dot = await new longdo.Marker(
          { lat: ele.lat, lon: ele.long },
          {
            draggable: false,
            clickable: true,
            weight: longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class=""><img src="' +
                require("../../assets/image/work/tracking.png") +
                '" style="height: 30px"/></div>',
              // url:
              //   value.type === 0
              //     ? require("../../assets/image/work/pickup_pin.png")
              //     : require("../../assets/image/work/send_pin.png"),
              // offset: { x: 12, y: 45 },
            },
          }
        );
        mark.push({ mark: dot, data: ele });
        await map.Overlays.add(dot);
      }
      await map.Event.bind("overlayClick", async function (overlay) {
        if (mark) {
          let check = mark?.filter((e) => e.mark === overlay);
          if (check.length > 0) {
            setShow(true);
            if (check[0].data.driver_work) {
              if (!Array.isArray(check[0].data.driver_work)) {
                check[0].data.driver_work =
                  check[0].data.driver_work.split(",");
              }
            }
            setShowData(check[0].data);
          }
        }
      });
      console.log(mark);
    }
  };
  const renderRadius = async () => {
    let location = {
      lat: watchLat,
      lon: watchLong,
    };
    let circle = await HelperMap.renderCircle(watchRadius, location);
    await map.location(location, true);
    await map.Overlays.add(circle);
  };
  return (
    <div className="post-work-page">
      <div className="post-work-body">
        <div className="post-work-body-flex">
          <div style={{ width: 400 }}>
            <SubMenu menus={menus} />
          </div>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <SubMenu breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className="post-work-body-flex">
          <div className="post-work-box">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="พิกัด lat*"
                  onChange={onChange}
                  value={value}
                  type="number"
                  error={errors.lat}
                />
              )}
              name="lat"
              defaultValue=""
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="พิกัด long*"
                  onChange={onChange}
                  value={value}
                  error={errors.long}
                  type="number"
                />
              )}
              name="long"
              defaultValue=""
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNormal
                  label="รัศมี*"
                  onChange={onChange}
                  error={errors.radius}
                  value={value}
                  type="number"
                />
              )}
              name="radius"
              defaultValue=""
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  value={value}
                  label={"สถานะคนขับ*"}
                  options={[{ value: 0, item: "ทั้งหมด" }]}
                  onChange={onChange}
                  error={errors.driver_status_id}
                  subFirst={"(ค่าเริ่มต้น:ทั้งหมด)"}
                />
              )}
              name="driver_status"
              defaultValue={0}
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  value={value}
                  label={"ประเภทรถที่ขับ*"}
                  error={errors.truck_id}
                  options={truckType}
                  onChange={onChange}
                />
              )}
              name="truck_id"
              defaultValue=""
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              color="secondary"
              onClick={handleSubmit(getTracking)}
            >
              ค้นหา
            </Button>

            <div style={{ marginTop: 20, textAlign: "center" }}>
              ผลลัพธ์​ {total} รายการ
            </div>
          </div>
          <div style={{ flex: 1 }} className="tracking-body-map">
            <LongdoMap
              id={"tracking-map"}
              callback={initMap()}
              map_key={ENV.MAP_KEY[ENV.ENV].KEY_3}
            />
          </div>
        </div>
      </div>
      <ModalWork open={show} minHeight enableClose close={() => setShow(false)}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>ข้อมูลคนขับ</div>
          <Line />
          <InputDisable
            label="ชื่อคนขับ"
            value={
              showData?.user_info.firstname + " " + showData?.user_info.lastname
            }
          />
          <InputDisable
            label="เบอร์โทรศัพท์"
            value={showData?.user_info.phone}
          />
          {/* <InputDisable
            label="โปรไฟล์ที่สังกัด"
            value={showData?.user_info.user_profile.profile.company_name}
          />
          <InputDisable
            label="เบอร์โทรโปรไฟล์"
            value={showData?.user_info.phone}
          /> */}
          <InputDisable
            label="จำนวนงานที่กำลังถูกมอบหมายแล้วยังไม่เสร็จสิ้นหรือยกเลิก"
            value={
              showData?.driver_work
                ? showData?.driver_work.length + " งาน"
                : "0 งาน"
            }
          />
        </div>
      </ModalWork>
    </div>
  );
}
