import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 40,
  borderRadius: 10,
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "white",
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#121f43" : "#308fe8",
  },
}));

function CustomLinearProgress({ progress }) {
  return (
    <div style={{ position: "relative", marginTop: 20 }}>
      <BorderLinearProgress variant="determinate" value={progress} />
      <div
        style={{
          width: "100%",
          position: "absolute",
          top: 8,
          color: "white",
          textAlign: "center",
          fontSize: 18,
          "-webkit-text-stroke-width": "0.5px",
          "-webkit-text-stroke-color": "black",
        }}
      >
        {progress} {"%"}
      </div>
    </div>
  );
}

export default CustomLinearProgress;
