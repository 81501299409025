import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import FilterTab from "components/filterTab/FilterTab";
import {
  downloadExcelServiceArea,
  getAllServiceArea,
  uploadServiceAreaFromExcel,
} from "utils/api/masterData/serviceArea";
import { subMenuMasterData } from "utils/submenuoptions";
import TableActiveIcon from "components/tableActiveIcon";
import TableCustom from "components/table/TableCustom";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSelectArea from "components/field/FieldSelectArea";
import Loading from "components/loading/Loading";
import AlertModal from "components/modal/AlertModal";
import helper from "utils/helper";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "country",
    label: "ประเทศ",
    align: "center",
    format: (value) => {
      return value.country;
    },
  },
  {
    id: "zone",
    label: "ภูมิภาค",
    align: "center",
    format: (value) => {
      return value.zone;
    },
  },
  {
    id: "province",
    label: "จังหวัด",
    align: "center",
    format: (value) => {
      return value.province;
    },
  },
  {
    id: "district",
    label: "อำเภอ",
    align: "center",
    format: (value) => {
      return value.district;
    },
  },
  {
    id: "subdistrict",
    label: "ตำบล",
    align: "center",
    format: (value) => {
      return value.subdistrict;
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const ServiceArea = () => {
  const navigate = useNavigate();
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `province`,
    search_val: ``,
    special_active: `all`,
    zone: [],
    province: [],
    district: [],
    subdistrict: [],
  };

  const [serviceArea, setServiceArea] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });

    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
    type = `getAllServiceArea`,
    file_upload = null,
  }) => {
    let params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      [filter.search_field]: filter.search_val,
      special_active:
        filter.special_active === `all` ? null : filter.special_active,
      zone: filter.zone,
      province: !R.isEmpty(filter.province)
        ? filter.province
        : filter.search_field === `province` && !R.isEmpty(filter.search_val)
        ? filter.search_val
        : ``,
      // province: filter.province,
      district: filter.district,
      subdistrict: filter.subdistrict,
      page: page,
      per_page: per_page,
    };

    if (type === `getAllServiceArea`) {
      handleGetAllServiceArea({
        page: page,
        per_page: per_page,
        new_params: params,
      });
    } else if (type === `downloadServiceAreaExcel`) {
      params = {
        ...params,
        sort_field: ["id"],
        sort_order: [1],
      };
      handleDownloadServiceAreaExcel({
        page: null,
        per_page: null,
        new_params: params,
      });
    } else if (type === `uploadServiceAreaExcel`) {
      if (!R.isNil(file_upload)) {
        handleUploadServiceAreaExcel({ file: file_upload });
      }
    }

    setParams(params);
  };

  const handleUploadServiceAreaExcel = async ({ file = null }) => {
    setIsFileLoading(true);
    try {
      const is_excel_file = helper.isCsvOrXlsxfile({ file: file });
      if (is_excel_file === true) {
        const upload_response = await uploadServiceAreaFromExcel({
          service_area_file: file,
        });
        if (upload_response.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `อัพโหลดสำเร็จ`,
            description: `อัพโหลดข้อมูลสำเร็จ`,
          });
        }
      } else {
        setAlertModal({
          open: true,
          title: `พบปัญหาขณะอัพโหลดไฟล์`,
          description: `กรุณาใส่ไฟล์ .csv | .xlsx | .xls`,
        });
      }

      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      setAlertModal({
        open: true,
        title: `พบปัญหาขณะอัพโหลดไฟล์`,
        description: error.response.data.description,
      });
      console.log(`error handleDownloadServiceAreaExcel:`, error);
    }
  };

  const handleDownloadServiceAreaExcel = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsFileLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: null, per_page: null };
      } else {
        new_params = { ...new_params, page: null, per_page: null };
      }

      const download_response = await downloadExcelServiceArea(new_params);
      if (download_response.status === 200) {
        helper.downloadFile({
          api_response: download_response,
        });
      }

      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      console.log(`error handleDownloadServiceAreaExcel:`, error);
    }
  };

  const handleGetAllServiceArea = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }

      const resp = await getAllServiceArea(new_params);
      setServiceArea(resp.data.data.results);
      setPagination({
        page: page,
        per_page: per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllServiceArea: ", error);
      setIsLoading(false);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`พื้นที่ให้บริการ`}
        menus={subMenuMasterData.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `พื้นที่ให้บริการ` ? true : false,
          };
        })}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "พื้นที่ให้บริการ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchValueOption={{
          onPressEnter: (field_type, val) => {
            handleGetDataByNewParams({ page: 1 });
            setPagination({ ...pagination, page: 1 });
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchFieldOption={{
          value: filter.search_field,
          options: [{ value: "province", name: "ชื่อจังหวัด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchButtonOption={{
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
            setPagination({ ...pagination, page: 1 });
          },
        }}
        downloadButtonOption={{
          onClick: () => {
            handleGetDataByNewParams({ type: `downloadServiceAreaExcel` });
          },
        }}
        uploadButtonOption={{
          onClick: (file) => {
            if (!R.isNil(file)) {
              handleGetDataByNewParams({
                type: `uploadServiceAreaExcel`,
                file_upload: file,
              });
            }
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetDataByNewParams({ page: 1, per_page: value });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
            setPagination({ ...pagination, page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    return (
      <div>
        <FieldSelect
          name="กรองและจัดเรียงตาม"
          value={"updated_at"}
          options={[
            {
              value: "updated_at",
              name: "วันที่แก้ไขข้อมูลล่าสุด",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("updated_at", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldDateRangePicker
          name={`วันเริ่มต้น - วันสิ้นสุด`}
          popupClassName="FieldDateRangePicker-drawer-popup"
          value={{
            start_date: filter.start_date,
            end_date: filter.end_date,
          }}
          onChange={(val) => {
            onChangeFilter(`date_duration`, val);
          }}
          style={{ width: `100%`, marginBottom: 26 }}
        />

        <FieldSelect
          name="อำเภอพิเศษ"
          value={filter.special_active}
          options={[
            {
              value: "all",
              name: "ทั้งหมด",
            },
            {
              value: true,
              name: "ใช่",
            },
            {
              value: false,
              name: "ไม่ใช่",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("special_active", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldSelectArea
          levelArea={["provinces", "districts", "subdistricts"]}
          name="พื้นที่รับสินค้า"
          onChange={(_, list_selected) => {
            const { zone, province, district, subdistrict } = list_selected;

            setFilter({
              ...filter,
              zone: zone,
              province: province,
              district: district,
              subdistrict: subdistrict,
            });
          }}
          value={[
            ...filter.zone.map((zone_id) => {
              return `z${zone_id}`;
            }),
            ...filter.province,
            ...filter.district,
            ...filter.subdistrict,
          ]}
        />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={serviceArea}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          handleGetAllServiceArea({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        href={`/master-data/service-area/`}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <Container>
      {renderSubMenu()}
      {renderFilterTab()}
      {isFileLoading === true ? <Loading /> : renderTable()}
      {renderModal()}
    </Container>
  );
};

export default ServiceArea;
