import React from "react";
import { Controller } from "react-hook-form";
import * as R from "ramda";
import { UploadFile } from "@mui/icons-material";
import FieldInput from "components/field/FieldInput";
import FieldSelect from "components/field/FieldSelect";
import FieldUpload from "components/field/FieldUpload";
import styles from "./TemplateForm.module.css";
import FieldDatePicker from "components/field/FieldDatePicker";

const TemplateForm = ({
  form_info = {},
  index = 0,
  control = null,
  errors = null,
  trigger = null,
}) => {
  if (!R.isNil(control) && !R.isNil(errors)) {
    if (form_info.field_type === `upload`) {
      return (
        <Controller
          key={index}
          name={form_info.name}
          control={control}
          rules={form_info.rules}
          render={({ field }) => {
            return (
              <div>
                <FieldUpload
                  className={styles.field}
                  icon={<UploadFile />}
                  name={form_info.field_label}
                  nameDescription={form_info.nameDescription}
                  readOnly={form_info.disabled}
                  value={!R.isNil(field.value) ? field.value : ""}
                  onChange={(e) => {
                    field.onChange(e);

                    if (!R.isNil(form_info.onChange)) {
                      form_info.onChange(e);
                    }
                    if (!R.isNil(trigger)) {
                      trigger(form_info.name);
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur(e);

                    if (!R.isNil(form_info.onBlur)) {
                      form_info.onBlur(e);
                    }
                    // if (!R.isNil(trigger)) {
                    //   trigger(form_info.name);
                    // }
                  }}
                  error={
                    form_info.error
                      ? form_info.error
                      : errors[form_info.name]
                      ? true
                      : false
                  }
                  textError={
                    form_info.helperText
                      ? form_info.helperText
                      : errors[form_info.name]
                      ? errors[form_info.name].message
                      : false
                  }
                  // error={errors[form_info.name] ? true : false}
                  // textError={
                  //   errors[form_info.name]
                  //     ? errors[form_info.name].message
                  //     : false
                  // }
                />
              </div>
            );
          }}
        />
      );
    } else if (form_info.field_type === `select`) {
      return (
        <Controller
          key={index}
          name={form_info.name}
          control={control}
          rules={form_info.rules}
          render={({ field }) => {
            return (
              <div>
                <FieldSelect
                  className={styles.field}
                  variant={
                    !R.isNil(form_info.variant) && !R.isEmpty(form_info.variant)
                      ? form_info.variant
                      : "standard"
                  }
                  name={form_info.field_label}
                  nameDescription={form_info.nameDescription}
                  descriptionUnderField={form_info.descriptionUnderField}
                  readOnly={form_info.disabled}
                  // value={!R.isNil(field.value) ? field.value : ""}
                  value={
                    !R.isNil(field.value)
                      ? field.value
                      : form_info.multiSelect
                      ? []
                      : ""
                  }
                  multiSelect={form_info.multiSelect}
                  onChange={(e) => {
                    field.onChange(e);

                    if (!R.isNil(form_info.onChange)) {
                      form_info.onChange(e);
                    }
                  }}
                  onClose={(e) => {
                    // field.onClose(e);

                    if (!R.isNil(form_info.onClose)) {
                      form_info.onClose(e);
                    }
                    if (!R.isNil(trigger)) {
                      trigger(form_info.name);
                    }
                  }}
                  options={form_info.options ? form_info.options : []}
                  error={
                    form_info.error
                      ? form_info.error
                      : errors[form_info.name]
                      ? true
                      : false
                  }
                  errorText={
                    form_info.helperText
                      ? form_info.helperText
                      : errors[form_info.name]
                      ? errors[form_info.name].message
                      : false
                  }
                  // error={errors[form_info.name] ? true : false}
                  // errorText={
                  //   errors[form_info.name]
                  //     ? errors[form_info.name].message
                  //     : false
                  // }
                />
              </div>
            );
          }}
        />
      );
    } else if (form_info.field_type === `datePicker`) {
      return (
        <Controller
          key={index}
          name={form_info.name}
          control={control}
          rules={form_info.rules}
          render={({ field }) => {
            return (
              <div className={styles.field_wrapper}>
                <FieldDatePicker
                  className={styles.field}
                  label={form_info.field_label}
                  // subLabel={form_info.subLabel}
                  subLabel={form_info.nameDescription}
                  value={
                    !R.isNil(field.value) && !R.isEmpty(field.value)
                      ? field.value
                      : ""
                  }
                  showTime={form_info.showTime}
                  disabledDay={form_info.disabledPreviousDay}
                  onChange={(e) => {
                    field.onChange(e);

                    if (!R.isNil(form_info.onChange)) {
                      form_info.onChange(e);
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur(e);

                    if (!R.isNil(form_info.onBlur)) {
                      form_info.onBlur(e);
                    }
                    if (!R.isNil(trigger)) {
                      trigger(form_info.name);
                    }
                  }}
                  readOnly={form_info.disabled}
                  error={
                    form_info.error
                      ? form_info.error
                      : errors[form_info.name]
                      ? true
                      : false
                  }
                  helperText={
                    form_info.helperText
                      ? form_info.helperText
                      : errors[form_info.name]
                      ? errors[form_info.name].message
                      : false
                  }
                  // error={errors[form_info.name] ? true : false}
                  // helperText={
                  //   form_info.helperText
                  //     ? form_info.helperText
                  //     : errors[form_info.name]
                  //     ? errors[form_info.name].message
                  //     : false
                  // }
                />
              </div>
            );
          }}
        />
      );
    } else {
      return (
        <Controller
          key={index}
          name={form_info.name}
          control={control}
          rules={form_info.rules}
          render={({ field }) => {
            return (
              <div>
                <FieldInput
                  className={styles.field}
                  readOnly={form_info.disabled}
                  label={form_info.field_label}
                  subLabel={form_info.nameDescription}
                  descriptionUnderField={form_info.descriptionUnderField}
                  value={
                    field.value || field.value === 0
                      ? field.value
                      : form_info.defaultValues
                      ? form_info.defaultValues
                      : ""
                  }
                  onChange={(e) => {
                    field.onChange(e);

                    if (!R.isNil(form_info.onChange)) {
                      form_info.onChange(e);
                    }
                  }}
                  onBlur={(e) => {
                    field.onBlur(e);

                    if (!R.isNil(form_info.onBlur)) {
                      form_info.onBlur(e);
                    }
                    if (!R.isNil(trigger)) {
                      trigger(form_info.name);
                    }
                  }}
                  error={
                    form_info.error
                      ? form_info.error
                      : errors[form_info.name]
                      ? true
                      : false
                  }
                  helperText={
                    form_info.helperText
                      ? form_info.helperText
                      : errors[form_info.name]
                      ? errors[form_info.name].message
                      : false
                  }
                />
              </div>
            );
          }}
        />
      );
    }
  }

  return null;
};

export default TemplateForm;
