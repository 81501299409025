import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import FormModal from "components/modal/FormModal";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import {
  createAccessory,
  getAllAccessory,
} from "utils/api/masterData/accessory";
import helper from "utils/helper";
import styles from "./CreateAccessoryModal.module.css";

const CreateAccessoryModal = ({
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const form_confim_btn = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: {},
  });

  const handlecreateAccessory = async () => {
    setIsLoading(true);

    try {
      let data = getValues();

      data = { ...data, active: true };

      await createAccessory(data).then((create_resp) => {
        if (create_resp.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
      });
      // .catch((error) => {
      //   setAlertModal({
      //     open: true,
      //     title: `บันทึกไม่สำเร็จ`,
      //     description: error.response.data.description.error.sqlMessage,
      //   });
      // });

      setOpen(false);
    } catch (error) {
      if (error.response.data.description.error.sqlMessage) {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error.response.data.description.error.sqlMessage,
        });
      } else {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error.response.data.description,
        });
      }
      setOpen(false);
    }
  };

  const renderForm = () => {
    const list_form = [
      {
        name: "name",
        field_label: "อุปกรณ์เสริม*",
        rules: {
          required: "กรุณาใส่อุปกรณ์เสริม",
        },
        onBlur: (e) => {
          getAllAccessory({
            page: null,
            per_page: null,
          }).then((resp) => {
            if (resp.data.code === 1000) {
              const list_accessories = resp.data.data.results;

              const same_accessory_name = list_accessories.filter(
                (accessory) => {
                  return (
                    accessory.name === e.target.value
                    // && accessory.name !== currentAccessoryName
                  );
                }
              );

              if (same_accessory_name.length > 0) {
                setError(`name`, {
                  message: `อุปกรณ์เสริม ${getValues().name} มีอยู่ในระบบแล้ว`,
                });
              } else {
                clearErrors(`name`);
              }
            }
          });
        },
      },
    ];

    return (
      <div>
        {list_form.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
              // trigger={trigger}
            />
          );
        })}
      </div>
    );
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  return (
    <>
      <FormModal
        title={`เพิ่มอุปกรณ์เสริมติดรถ`}
        open={open}
        confirmTextBtn={"บันทึก"}
        cancelTextBtn={"ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          reset();
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handlecreateAccessory();
          })}
        >
          {renderForm()}

          <button
            ref={form_confim_btn}
            disabled={!R.isEmpty(errors) ? true : false}
            style={{ display: "none" }}
          >
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateAccessoryModal;
