// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchLocation_search_location_container__nyi1N {
  background-color: white;
  min-width: 400px;
  min-width: 400px;
  min-height: 80.5vmin;
  max-height: 80.5vmin;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  border-radius: 20px;
  overflow: scroll;
}

.SearchLocation_select_location_route_container__3-zth {
  display: flex;
  align-items: center;
  gap: 10px 10px;
  cursor: pointer;
  transition: 0.5s all ease-out;
  opacity: 0.3;
  width: max-content;
  margin: 10px 0px;
}
.SearchLocation_select_location_route_container__3-zth:hover {
  opacity: 1;
}

.SearchLocation_field_container__7Sm09 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.SearchLocation_field_container__7Sm09 .SearchLocation_input_longdo__\\+gA5O {
  width: 90%;
}

.SearchLocation_field_container__7Sm09 .SearchLocation_delete_button__NuXKM {
  width: 10%;
  margin-top: 25px;
}

.SearchLocation_start_adornment_container__Ypa5U {
  color: #121f43;
  font-size: 32px;
  font-weight: 500;
  line-height: 47.84px;
  margin-top: -10px;
}

@media screen and (max-width: 1000px) {
  .SearchLocation_search_location_container__nyi1N {
    min-width: 100%;
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/work/RouteOptimiza/SearchLocation.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE;IACE,eAAe;IACf,eAAe;EACjB;AACF","sourcesContent":[".search_location_container {\n  background-color: white;\n  min-width: 400px;\n  min-width: 400px;\n  min-height: 80.5vmin;\n  max-height: 80.5vmin;\n  display: flex;\n  flex-direction: column;\n  padding: 10px 30px;\n  border-radius: 20px;\n  overflow: scroll;\n}\n\n.select_location_route_container {\n  display: flex;\n  align-items: center;\n  gap: 10px 10px;\n  cursor: pointer;\n  transition: 0.5s all ease-out;\n  opacity: 0.3;\n  width: max-content;\n  margin: 10px 0px;\n}\n.select_location_route_container:hover {\n  opacity: 1;\n}\n\n.field_container {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  gap: 5px;\n}\n\n.field_container .input_longdo {\n  width: 90%;\n}\n\n.field_container .delete_button {\n  width: 10%;\n  margin-top: 25px;\n}\n\n.start_adornment_container {\n  color: #121f43;\n  font-size: 32px;\n  font-weight: 500;\n  line-height: 47.84px;\n  margin-top: -10px;\n}\n\n@media screen and (max-width: 1000px) {\n  .search_location_container {\n    min-width: 100%;\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search_location_container": `SearchLocation_search_location_container__nyi1N`,
	"select_location_route_container": `SearchLocation_select_location_route_container__3-zth`,
	"field_container": `SearchLocation_field_container__7Sm09`,
	"input_longdo": `SearchLocation_input_longdo__+gA5O`,
	"delete_button": `SearchLocation_delete_button__NuXKM`,
	"start_adornment_container": `SearchLocation_start_adornment_container__Ypa5U`
};
export default ___CSS_LOADER_EXPORT___;
