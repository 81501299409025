import instance from "utils/axiosInstance";

export const getProjectCarrierProgression = async (projectId, params = {}) => {
  return await instance.get(`api/project/${projectId}/carrier-progression`, {
    params: params,
  });
};

export const createProjectCarrierProgression = async (projectId, data) => {
  const formData = new FormData();
  formData.append("progressionType", data.progressionType);
  formData.append("remark", data.remark);
  formData.append("file", data.file);

  return await instance.post(
    `api/project/${projectId}/carrier-progression`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getProjectCarrierProgressionDetail = async (
  projectId,
  progressionId
) => {
  return await instance.get(
    `api/project/${projectId}/carrier-progression/${progressionId}`
  );
};

export const updateProjectCarrierProgression = async (
  projectId,
  progressionId,
  data
) => {
  const formData = new FormData();
  formData.append("active", data.active);
  formData.append("progressionType", data.progressionType);
  formData.append("remark", data.remark);
  formData.append("file", data.file);

  return await instance.patch(
    `api/project/${projectId}/carrier-progression/${progressionId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
