import instance from "utils/axiosInstance";

export const getProjectProgression = async (projectId, params = {}) => {
  return await instance.get(`api/project/${projectId}/progression`, {
    params: params,
  });
};

export const createProjectProgression = async (projectId, data) => {
  const formData = new FormData();
  formData.append("progressionType", data.progressionType);
  formData.append("remark", data.remark);
  formData.append("file", data.file);

  return await instance.post(`api/project/${projectId}/progression`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getProjectProgressionDetail = async (projectId, progressionId) => {
  return await instance.get(
    `api/project/${projectId}/progression/${progressionId}`
  );
};

export const updateProjectProgression = async (
  projectId,
  progressionId,
  data
) => {
  const formData = new FormData();
  formData.append("active", data.active);
  formData.append("progressionType", data.progressionType);
  formData.append("remark", data.remark);
  formData.append("file", data.file);

  return await instance.patch(
    `api/project/${projectId}/progression/${progressionId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
