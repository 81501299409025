import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import Title from "./Title";
import "./chart.css";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid } from "@mui/material";

export default function Chart({ title, data }) {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0) {
      const desc = payload[0].payload;
      return (
        <div className="chart-tooltip">
          <Grid container spacing={2} sx={{ padding: 0 }}>
            <Grid item>
              <p className="chart-tooltip-text">บุคคลทั่วไป</p>
              <p className="chart-tooltip-text">
                <CircleIcon sx={{ fontSize: "10px", color: "#FF316F" }} />
                ผู้ขนส่ง {desc.profile_shipper}
              </p>
              <p className="chart-tooltip-text">
                <CircleIcon sx={{ fontSize: "10px", color: "#F9A38A" }} />
                ลูกค้า {desc.profile_carrier}
              </p>
              <p className="chart-tooltip-text">
                <CircleIcon sx={{ fontSize: "10px", color: "#FFC675" }} />
                คนขับรถ {desc.profile_driver}
              </p>
            </Grid>
            <Grid item>
              <p className="chart-tooltip-text">นิติบุคคล</p>
              <p className="chart-tooltip-text">
                <CircleIcon sx={{ fontSize: "10px", color: "#27CE88" }} />
                ผู้ขนส่ง {desc.profile_shipper_corporate}
              </p>
              <p className="chart-tooltip-text">
                <CircleIcon sx={{ fontSize: "10px", color: "#2499EF" }} />
                ลูกค้า {desc.profile_carrier_corporate}
              </p>
              <p className="chart-tooltip-text">
                <CircleIcon sx={{ fontSize: "10px", color: "#8C8DFF" }} />
                คนขับรถ {desc.profile_driver_corporate}
              </p>
            </Grid>
          </Grid>
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <ResponsiveContainer width="100%" height={260}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 15,
            left: 15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey={`profile_driver`} stackId="a" fill={`#FFC675`} />
          <Bar dataKey={`profile_carrier`} stackId="a" fill={`#F9A38A`} />
          <Bar dataKey={`profile_shipper`} stackId="a" fill={`#FF316F`} />
          <Bar
            dataKey={`profile_driver_corporate`}
            stackId="b"
            fill={`#8C8DFF`}
          />
          <Bar
            dataKey={`profile_carrier_corporate`}
            stackId="b"
            fill={`#2499EF`}
          />
          <Bar
            dataKey={`profile_shipper_corporate`}
            stackId="b"
            fill={`#27CE88`}
          />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
