import instance from "../../axiosInstance";

export const getAllListTruckDrivingLicense = async (params = {}) => {
  try {
    return await instance.get(`api/master/driving-license`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
