import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import "./selectLocation.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlaceIcon from "@mui/icons-material/Place";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModalSelectMap from "./modalSelectMap";
import { LongdoMap, map, longdo } from "components/map/LongdoMapSec";
import Line from "components/layout/line";
import SelectTruck from "./selectTruck";
import { RemoveCircle, RemoveCircleOutline } from "@mui/icons-material";
import ModalWork from "./modalWork";
import InputDisable from "components/input/inputDisable";
import InputSelect from "components/input/inputSelect";
import Master from "utils/master";
import ModalSelectProduct from "./modalSelectProduct";
import ENV from "utils/environment";

const SelectLocation = (props) => {
  const {
    value,
    onChange,
    is_detail = false,
    workType,
    closeFav = false,
    closeCurrent = false,
  } = props;
  const [data, setData] = useState(null);
  const [showLocDetail, setShowLocDetail] = useState(false);
  const [showProduct, setShowProduct] = useState(false);

  const callback = async () => {
    console.log("is_detail");
  };
  return (
    <div className="select-location">
      {value.map((ele, index) => {
        // console.log(ele);
        // console.log(
        //   ele.place_name !== ""
        //     ? ele.place_name
        //     : ele.address !== ""
        //     ? ele.address
        //     : ele.type === 0
        //     ? "จุดรับสินค้า?*"
        //     : "จุดส่งสินค้า?*"
        // );
        return (
          <div className="select-location-route" key={"route" + index}>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              onClick={() => {
                setData({ value: ele, index: index });
              }}
            >
              <PlaceIcon
                sx={{ color: ele.type === 0 ? "#121f43" : "#ff316f" }}
              />
              <MoreVertIcon sx={{ opacity: 0.3 }} />
            </div>
            <div
              style={{ flex: 1, paddingLeft: 10 }}
              onClick={() => {
                setData({ value: ele, index: index });
              }}
            >
              <div
                style={{
                  color:
                    // ele.subdistrict !== "" &&
                    ele.district !== "" && ele.province !== ""
                      ? "#121f43"
                      : "#ff316f",
                }}
              >
                {ele.place_name !== ""
                  ? ele.place_name
                  : ele.address !== ""
                  ? ele.address
                  : ele.type === 0
                  ? "จุดรับสินค้า?*"
                  : "จุดส่งสินค้า?*"}
              </div>
              <div
                style={{
                  fontSize: 10,
                  opacity:
                    // ele.subdistrict !== "" &&
                    ele.district !== "" && ele.province !== "" ? 1 : 0.3,
                }}
              >
                {ele.subdistrict !== "" &&
                ele.district !== "" &&
                ele.province !== ""
                  ? ele.subdistrict + " " + ele.district + " " + ele.province
                  : "(เลือกสถานที่ที่คุณต้องการมให้" +
                    (ele.type === 0 ? "เข้ารับ" : "จัดส่ง") +
                    "สินค้า)"}
              </div>
            </div>
            {index > 1 && !is_detail ? (
              <div
                style={{ alignSelf: "center", cursor: "pointer" }}
                onClick={() => {
                  let tmp = [...value];
                  tmp.splice(index, 1);
                  onChange(tmp);
                }}
              >
                <RemoveCircleOutline sx={{ color: "#121f43" }} />
              </div>
            ) : null}
            <div
              style={{ alignSelf: "center" }}
              onClick={() => {
                setData({ value: ele, index: index });
              }}
            >
              <ArrowForwardIosIcon sx={{ color: "#121f43", opacity: 0.3 }} />
            </div>
          </div>
        );
      })}
      {is_detail ? null : workType === "stl" ? null : (
        <div>
          <div
            className="select-location-route select-location-add"
            onClick={() => {
              value.push({
                type: 1,
                name: "",
                lat: "",
                long: "",
                address: "",
                district: "",
                subdistrict: "",
                province: "",
                postal_code: "",
                contact_name: "",
                contact_phone: "",
                place_name: "",
                place_type_id: 0,
                remark: "",
                document: "",
                document_file: [],
                product: [
                  {
                    product_id: "",
                    product_name: "",
                    product_size: "",
                    product_width: "",
                    product_length: "",
                    product_height: "",
                    product_volume: "",
                    product_weight: "",
                    product_count_type: "",
                    product_amount: "",
                    product_image: [null, null, null, null, null],
                    product_image_per_route: [],
                  },
                ],
              });
              onChange(value);
            }}
            style={{ paddingBottom: 10 }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <AddCircleIcon />
            </div>
            <div style={{ flex: 1, paddingLeft: 10, alignSelf: "center" }}>
              <div>เพิ่มจุดส่งสินค้า</div>
            </div>
          </div>
        </div>
      )}
      {is_detail ? (
        <ModalWork
          close={() => {
            setData(null);
          }}
          open={data ? true : false}
          workType={workType}
          minHeight
          enableClose
        >
          <div style={{ padding: "0px 20px 20px 20px", height: "80vmin" }}>
            <InputDisable
              value={data?.value.place_name ?? data?.value.address}
              label={
                "จุด" +
                (data?.value.type === 0 ? "จุดรับสินค้า" : "จุดส่งสินค้า")
              }
            />
            <div
              style={{
                flex: 1,
                position: "relative",
                // marginTop: 20,
                // marginBottom: 20,
                height: "100%",
              }}
            >
              <div style={{ height: "100%" }}>
                {data ? (
                  <LongdoMap
                    id={"post-work-modal-map"}
                    style={{ borderRadius: 20 }}
                    callback={callback}
                    map_key={ENV.MAP_KEY[ENV.ENV].KEY_1}
                  />
                ) : null}
              </div>

              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 1,
                  // display: "flex",
                  // height: "60vmin",
                  backgroundColor: "white",
                  bottom: -1,
                  padding: "10px 10px 80px 10px",
                  borderRadius: "20px 20px 0px 0px",
                  cursor: "pointer",
                  overflow: "scroll",
                  // textAlign: "center",
                  justifyContent: "center",
                  boxShadow: "rgba(115, 115, 115, 0.1) 0px -1px 10px",
                }}
                id="post-work-botbar"
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let ele = document.getElementById("post-work-modal-map");
                    // console.log(ele.offsetHeight);
                    if (
                      document.getElementById("post-work-botbar")
                        .offsetHeight === ele.offsetHeight
                    ) {
                      document.getElementById("post-work-botbar").style.height =
                        null;
                      setShowLocDetail(false);
                    } else {
                      document.getElementById("post-work-botbar").style.height =
                        ele.offsetHeight + "px";
                      setShowLocDetail(true);
                    }
                  }}
                >
                  <div
                    style={{
                      width: 50,
                      height: 5,
                      backgroundColor: "#8ca3ba",
                      position: "absolute",
                      top: 2.5,
                      transform: "translate(-50%, -50%)",
                      borderRadius: "0px 0px 10px 10px",
                      left: "50%",
                    }}
                  ></div>
                  <div style={{ color: "#8ca3ba", textAlign: "center" }}>
                    ข้อมูลติดต่อ
                    {data?.value?.type === 0 ? "จุดรับสินค้า" : "จุดส่งสินค้า"}
                  </div>
                </div>
                {showLocDetail ? (
                  <div>
                    <Line />
                    <InputDisable
                      value={data?.value?.contact_name}
                      label={"ชื่อผู้ติดต่อ*"}
                      onChange={(val) => {
                        onChange({ ...value, contact_name: val.target.value });
                      }}
                      subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                    />
                    <InputDisable
                      value={data?.value?.contact_phone}
                      extraType={"phone"}
                      maxLength={10}
                      label={"เบอร์โทรผู้ติดต่อ*"}
                      onChange={(val) => {
                        onChange({ ...value, contact_phone: val.target.value });
                      }}
                      subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                    />
                    <InputDisable
                      value={data?.value?.place_name}
                      // extraType={"phone"}
                      // maxLength={10}
                      label={"ชื่อสถานที่*"}
                      onChange={(val) => {
                        onChange({ ...value, place_name: val.target.value });
                      }}
                      // subFirst={"(ค่าเริ่มต้น: ระบบจะดึงจากโปรไฟล์)"}
                    />
                    <InputDisable
                      value={
                        Master.place_type.filter(
                          (e) => e.value === data?.value?.place_type_id
                        ).length > 0
                          ? Master.place_type.filter(
                              (e) => e.value === data?.value?.place_type_id
                            )[0].item
                          : ""
                      }
                      label={"ประเภทสถานที่*"}
                      subFirst={"(ค่าเริ่มต้น: ที่พักอาศัย)"}
                      // options={Master.place_type}
                      onChange={(val) => {
                        onChange({ ...value, place_type_id: val.target.value });
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: 10, flex: 1 }}>
                        <InputDisable
                          value={data?.value?.document}
                          label={"เอกสารอื่นๆ*"}
                          onChange={(val) => {
                            onChange({ ...value, document: val.target.value });
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <InputDisable
                          id={"document_file" + Math.random(10)}
                          value={data?.value?.document_file}
                          label={"เอกสารอื่นๆ*"}
                          onChange={(val) => {
                            console.log(val);
                            onChange({
                              ...value,
                              document_file: val,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <InputDisable
                      value={data?.value?.remark}
                      label={"หมายเหตุ*"}
                      onChange={(val) => {
                        onChange({ ...value, remark: val.target.value });
                      }}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      style={{ borderRadius: "10px", marginTop: 20 }}
                      sx={{ height: 50 }}
                      color="secondary"
                      onClick={() => {
                        setShowProduct(true);
                      }}
                    >
                      ข้อมูลรับสินค้า ณ สถานที่ที่นี้
                    </Button>
                  </div>
                ) : null}
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      style={{ borderRadius: "10px", marginTop: 20 }}
                      sx={{ height: 50 }}
                      color="secondary"
                      onClick={() => {
                        setData(null);
                      }}
                    >
                      ปิด
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalWork>
      ) : (
        <ModalSelectMap
          close={() => {
            setData(null);
          }}
          value={data ? data.value : null}
          onChange={(val) => {
            setData({ ...data, value: val });
          }}
          confirm={() => {
            let tmp = [...value];
            tmp[data.index] = data.value;
            onChange(tmp);
            setData(null);
          }}
          show={data ? true : false}
          workType={workType}
          profile_id={props.profile_id}
          is_detail={is_detail}
          closeFav={closeFav}
          closeCurrent={closeCurrent}
        />
      )}
      <ModalSelectProduct
        open={showProduct}
        value={data?.value?.product}
        onChange={(val) => {
          //  onChange({ ...value, product: val });
        }}
        close={() => {
          setShowProduct(false);
        }}
        confirm={() => {
          setShowProduct(false);
        }}
        workType={workType}
        is_detail={is_detail}
      />
    </div>
  );
};

export default SelectLocation;
