import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import api from "../../../../utils/api/masterData/productType";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./ProductTypeInfo.module.css";

const ProductTypeInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetProductType();

    // eslint-disable-next-line
  }, []);

  const handleGetProductType = async () => {
    setIsLoading(true);

    try {
      const resp = await api.getProductType(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(getValues().name);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getProductType: ", error);
      navigate("/master-data/product-type");
    }
  };

  const handleUpdateProductType = async () => {
    setIsLoading(true);

    try {
      let data = getValues();

      if (typeof data.image === `string`) {
        data = R.omit(["image"], data);
      }
      // if (typeof data.example === `string`) {
      //   data = R.omit(["example"], data);
      // }

      data = {
        ...data,
        active: data.active === `true` ? true : false,
      };

      const update_resp = await api.updateProductType(id, data);

      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะใช้งาน";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "code",
        field_type: "text",
        field_label: "รหัสสินค้า*",
        rules: {
          required: "กรุณาใส่ประเภทสินค้า",
        },
      },
      {
        name: "name",
        field_type: "text",
        field_label: "ประเภทสินค้า*",
        rules: {
          required: "กรุณาใส่ประเภทสินค้า",
        },
      },
      {
        name: "image",
        nameDescription: "ขนาด 175 x 50 พิกเซล หรือ อัตราส่วน 2:7 เท่านั้น",
        field_type: "upload",
        field_label: "รูปภาพสินค้า*",
        rules: {
          validate: async (file) => {
            if (typeof file !== "string") {
              const { width: image_width, height: image_height } =
                await helper.convertFileSizeToPixel({ file: file });

              if (!helper.isJpgFile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
              }
              if (helper.isFileOverSize({ file: file, size: 10 })) {
                return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
              } else if (image_width !== 175 && image_height !== 50) {
                return "กรุณาใส่รูปภาพขนาด 175 x 50 พิกเซล";
              }
            }
            return true;
          },
        },
      },
      {
        name: "example",
        field_type: "text",
        field_label: "ตัวอย่างชนิดสินค้า*",
        rules: {
          required: "กรุณาใส่ตัวอย่างชนิดสินค้า",
        },
      },
      // {
      //   name: "example",
      //   nameDescription: "ขนาด 175 x 50 พิกเซล หรือ อัตราส่วน 2:7 เท่านั้น",
      //   field_type: "upload",
      //   field_label: "รูปภาพตัวอย่างสินค้า*",
      //   rules: {
      //     validate: async (file) => {
      //       if (typeof file !== "string") {
      //         const { width: image_width, height: image_height } =
      //           await helper.convertFileSizeToPixel({ file: file });

      //         if (!helper.isJpgFile({ file: file })) {
      //           return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
      //         }
      //         if (helper.isFileOverSize({ file: file, size: 10 })) {
      //           return "อัพโหลดไฟล์นามสกุล JPG เท่านั้น และขนาดไม่เกิน 10 MB";
      //         } else if (image_width !== 175 && image_height !== 50) {
      //           return "กรุณาใส่รูปภาพขนาด 175 x 50 พิกเซล";
      //         }
      //       }
      //       return true;
      //     },
      //   },
      // },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดอุปกรณ์เสริมติดรถ`}
        pathbackward={`/master-data/product-type`}
        menus={[
          {
            label: "รายละเอียดประเภทสินค้า",
            path: `#`,
            active: true,
          },
          {
            label: "จัดการลำดับประเภทสินค้า",
            path: `/master-data/product-type/order-list`,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "ประเภทสินค้า",
            path: null,
          },
          {
            label: getValues().name,
            path: null,
          },
          {
            label: "รายละเอียดประเภทสินค้า",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateProductType();
        })}
      >
        {renderColumn1()}

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default ProductTypeInfo;
