// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldSelectArea_custom-list-item__xebmT {
  height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/components/field/FieldSelectArea.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".custom-list-item {\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-list-item": `FieldSelectArea_custom-list-item__xebmT`
};
export default ___CSS_LOADER_EXPORT___;
