import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  Drawer,
  Avatar,
  Button,
  useTheme,
  TableRow,
  TableHead,
  TableBody,
  TextField,
  Container,
  Typography,
  InputAdornment,
  TableContainer,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Add,
  AddCircle,
  Search,
  RotateRight,
  FilterAlt,
  CheckCircle,
  Timer,
  RateReviewRounded,
  Cancel,
  Error,
  UploadFile as IconUploadFile,
} from "@mui/icons-material";
import moment from "moment";
import styles from "./TableUser.module.css";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
//component in project
import Logo from "assets/image/WemoveLogo.svg";
import helper from "utils/helper";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import FieldSelect from "components/field/FieldSelect";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import ButtonCustom from "components/ButtonCustom";
import FieldUpload from "components/field/FieldUpload";
import TableCustom from "components/table/TableCustom";
import {
  ApproveStatusFilter,
  GradeType,
  PaymentType,
  TruckManual,
} from "utils/enum";
import { subMenuUser } from "utils/submenuoptions";
import ModalCreateUser from "./ModalCreateUser";
import TableActiveIcon from "components/tableActiveIcon";
import FieldSelectArea from "components/field/FieldSelectArea";
import PaginationComponent from "components/pagination/Pagination";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import {
  getAllProductType,
  getAllOptional,
  getAllQualification,
  getAllAssociation,
} from "utils/api/masterData/service";
import { getTruckType as getTruckTypeMS } from "utils/api/masterData/truckType";
import { getAllAccessory as getAccessoriesMS } from "utils/api/masterData/accessory";
import {
  getAllUser as getAllUserAPI,
  getDrivingLicense,
} from "utils/api/user/user";
import masterProvice from "utils/api/masterData/provice";
import ModalUploadFile from "./ModalUploadFile";
import SelectRowValue from "components/button/SelectRowValue";

const TableUser = () => {
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [data, setData] = useState([]);
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [successAlert, setSuccessAlert] = useState(false);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [openModalCreateUser, setOpenModalCreateUser] = useState(false);

  //master data
  const [province, setProvince] = useState([]);
  const [optional, setOptional] = useState([]);
  const [truckType, setTruckType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [association, setAssociation] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [masterDrivingLicense, setMasterDrivingLicense] = useState([]);

  const [openModalUploadUser, setOpenModalUploadUser] = useState(false);

  // sort & filter
  const [filterUser, setFilterUser] = useState({
    sort_by: "updated_at",
    search_by: "user_name",
    search_val: "",
    start_date: "",
    end_date: "",
    page: 1,
    per_page: 5,
    total: 0,
    is_main: [],
    profile_type: [],
    role: [],
    active: [],
  });
  const [filterUserShipper, setFilterUserShipper] = useState({
    shipper_grade_type: [],
    shipper_approved_status: [],
    shipper_payment_type: [],
    shipper_load_province: [],
    shipper_unload_province: [],
    profile_shipper_product_type: [],
    profile_shipper_truck_type: [],
    profile_shipper_optional: [],
    profile_shipper_accessories: [],
    profile_shipper_qualification: [],
  });
  const [filterUserCarrier, setFilterUserCarrier] = useState({
    carrier_grade_type: [],
    carrier_approved_status: [],
    carrier_payment_type: [],
    carrier_load_province: [],
    carrier_unload_province: [],
    profile_carrier_product_type: [],
    profile_carrier_truck_type_manual: [],
    profile_carrier_optional: [],
    profile_carrier_accessories: [],
    profile_carrier_qualification: [],
    profile_carrier_association: [],
  });
  const [filterUserDriver, setFilterUserDriver] = useState({
    driver_approved_status: [],
    driving_license: [],
    driver_truck_id: [],
  });

  useEffect(() => {
    getAllUser(filterUser);
  }, [filterUser.page, filterUser.per_page]);

  useEffect(() => {
    console.log("filterUser", filterUser);
    console.log("filterUserShipper", filterUserShipper);
    console.log("filterUserCarrier", filterUserCarrier);
    console.log("filterUserDriver", filterUserDriver);
  }, [filterUser, filterUserShipper, filterUserCarrier, filterUserDriver]);

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: null,
    },
  ];

  const columns = [
    {
      id: "created_at",
      label: "วันที่สร้างรายการ",
      align: "center",
      //   width: "13%",
      format: (value) => helper.ToConvertDateTimeForTable(value.created_at),
    },
    {
      id: "updated_at",
      label: "วันที่แก้ไขข้อมูลล่าสุด",
      align: "center",
      //   width: "15%",
      format: (value) => helper.ToConvertDateTimeForTable(value.updated_at),
    },
    {
      id: "user_code",
      label: "รหัสผู้ใช้งาน",
      align: "center",
      //   width: "12%",
    },
    {
      id: "company_name",
      label: "ชื่อบริษัท",
      align: "left",
      format: (value) => value.user_profile.profile.company_name,
    },
    {
      label: "ชื่อผู้ใช้งาน",
      align: "left",
      //   width: "12%",
      format: ({ prefix, firstname, lastname }) =>
        `${prefix.name}${firstname} ${lastname}`,
    },
    {
      id: "user_profile",
      label: "ประเภทผู้ใช้งาน",
      align: "center",
      //   width: "12%",
      format: (value) =>
        value.is_main === true ? "ผู้ใช้งานหลัก" : "ผู้ใช้งานรอง",
    },
    {
      id: "is_main",
      label: "บทบาทที่ใช้งาน",
      align: "center",
      //   width: "12%",
      format: (value) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {value.user_profile.user_profile_shipper.is_shipper === true ? (
            <div
              style={{
                fontWeight: 500,
                color:
                  value.user_profile.profile.shipper_approved_status === 0
                    ? "#2499EF"
                    : value.user_profile.profile.shipper_approved_status === 1
                    ? "#27CE88"
                    : value.user_profile.profile.shipper_approved_status === 2
                    ? "#FFC675"
                    : value.user_profile.profile.shipper_approved_status === 3
                    ? "#FF316F"
                    : value.user_profile.profile.shipper_approved_status === 4
                    ? "#8CA3BA"
                    : "#CC99FF",
              }}
            >
              SH&nbsp;
            </div>
          ) : (
            ""
          )}
          {value.user_profile.user_profile_carrier.is_carrier === true ? (
            <div
              style={{
                fontWeight: 500,
                color:
                  value.user_profile.profile.carrier_approved_status === 0
                    ? "#2499EF"
                    : value.user_profile.profile.carrier_approved_status === 1
                    ? "#27CE88"
                    : value.user_profile.profile.carrier_approved_status === 2
                    ? "#FFC675"
                    : value.user_profile.profile.carrier_approved_status === 3
                    ? "#FF316F"
                    : value.user_profile.profile.carrier_approved_status === 4
                    ? "#8CA3BA"
                    : "#CC99FF",
              }}
            >
              CR&nbsp;
            </div>
          ) : (
            ""
          )}

          {value.user_profile.user_profile_driver.is_driver === true ? (
            <div
              style={{
                fontWeight: 500,
                color:
                  value.driver_approved_status === 0
                    ? "#2499EF"
                    : value.driver_approved_status === 1
                    ? "#27CE88"
                    : value.driver_approved_status === 2
                    ? "#FFC675"
                    : value.driver_approved_status === 3
                    ? "#FF316F"
                    : value.driver_approved_status === 4
                    ? "#8CA3BA"
                    : "#CC99FF",
              }}
            >
              DR&nbsp;
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      id: "active",
      label: "สถานะใช้งาน",
      align: "center",
      width: "10%",
      format: (value) => {
        return <TableActiveIcon active_status={value.active} />;
      },
    },
  ];

  const filterGeneral = [
    {
      name: "ประเภทผู้ใช้งาน",
      value: "is_main",
      options: [
        { value: true, name: "ผู้ใช้งานหลัก" },
        { value: false, name: "ผู้ใช้งานรอง" },
      ],
    },
    {
      name: "ประเภทโปรไฟล์",
      value: "profile_type",
      options: [
        { value: 0, name: "บุคคลธรรมดา" },
        { value: 1, name: "นิติบุคคล" },
      ],
    },
    {
      name: "บทบาทผู้ใช้งาน",
      value: "role",
      options: [
        { value: "shipper", name: "ลูกค้า" },
        { value: "carrier", name: "ผู้ขนส่ง" },
        { value: "driver", name: "พนักงานขับรถ" },
      ],
    },
    {
      name: "สถานะใช้งาน",
      value: "active",
      options: [
        { value: true, name: "ใช้งาน" },
        { value: false, name: "ระงับ" },
      ],
    },
  ];

  const filterShipper = [
    {
      name: "เกรดลูกค้า",
      value: "shipper_grade_type",
      type: "select",
      options: GradeType,
    },
    {
      name: "สถานะการตรวจสอบเอกสาร",
      value: "shipper_approved_status",
      type: "select",
      options: ApproveStatusFilter,
    },
    {
      name: "ประเภทการชำระเงิน",
      value: "shipper_payment_type",
      type: "select",
      options: PaymentType,
    },
    {
      name: "พื้นที่รับสินค้า",
      value: "shipper_load_province",
      type: "select_area",
      options: province,
    },
    {
      name: "พื้นที่ส่งสินค้า",
      value: "shipper_unload_province",
      type: "select_area",
      options: province,
    },
    {
      name: "ประเภทสินค้าที่ขนส่ง",
      value: "profile_shipper_product_type",
      type: "select",
      options: productType,
    },
    {
      name: "ประเภทรถที่ใช้",
      value: "profile_shipper_truck_type",
      type: "select",
      options: truckType,
    },
    {
      name: "บริการเสริม",
      value: "profile_shipper_optional",
      type: "select",
      options: optional,
    },
    {
      name: "อุปกรณ์เสริมติดรถ",
      value: "profile_shipper_accessories",
      type: "select",
      options: accessories,
    },
    {
      name: "คุณสมบัติที่เกี่ยวข้องที่ต้องการของลูกค้า",
      value: "profile_shipper_qualification",
      type: "select",
      options: qualification,
    },
  ];

  const filterCarrier = [
    {
      name: "เกรดผู้ขนส่ง",
      value: "carrier_grade_type",
      type: "select",
      options: GradeType,
    },
    {
      name: "สถานะการตรวจสอบเอกสาร",
      value: "carrier_approved_status",
      type: "select",
      options: ApproveStatusFilter,
    },
    {
      name: "ประเภทการชำระเงิน",
      value: "carrier_payment_type",
      type: "select",
      options: PaymentType,
    },
    {
      name: "พื้นที่รับสินค้า",
      value: "carrier_load_province",
      type: "select_area",
      options: province,
    },
    {
      name: "พื้นที่ส่งสินค้า",
      value: "carrier_unload_province",
      type: "select_area",
      options: province,
    },
    {
      name: "ประเภทสินค้าที่ขนส่ง",
      value: "profile_carrier_product_type",
      type: "select",
      options: productType,
    },
    {
      name: "ประเภทรถที่มี (Manual)",
      value: "profile_carrier_truck_type_manual",
      type: "select",
      options: TruckManual,
    },
    {
      name: "บริการเสริม",
      value: "profile_carrier_optional",
      type: "select",
      options: optional,
    },
    {
      name: "อุปกรณ์เสริมติดรถ",
      value: "profile_carrier_accessories",
      type: "select",
      options: accessories,
    },
    {
      name: "คุณสมบัติที่เกี่ยวข้องที่ต้องการของผู้ขนส่ง",
      value: "profile_carrier_qualification",
      type: "select",
      options: qualification,
    },
    {
      name: "สังกัดสมาคม",
      value: "profile_carrier_association",
      type: "select",
      options: association,
    },
  ];

  const filterDriver = [
    {
      name: "สถานะการอนุมัติคนขับ",
      value: "driver_approved_status",
      type: "select",
      options: ApproveStatusFilter,
    },
    {
      name: "ประเภทใบอนุญาตขับขี่",
      value: "driving_license",
      type: "select",
      options: masterDrivingLicense,
    },
    {
      name: "ประเภทรถที่ขับ",
      value: "driver_truck_id",
      type: "select",
      options: truckType,
    },
  ];
  useEffect(() => {
    getProvince();
    getOptional();
    getTruckType();
    getAssociation();
    getProductType();
    getAccessories();
    getQualification();
    getDrivingLicenseMS();
  }, []);

  const getProvince = async () => {
    await masterProvice
      .Province()
      .then((response) => {
        let results = response.data.data.results;
        setProvince(
          ...province,
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDrivingLicenseMS = async () => {
    await getDrivingLicense()
      .then((response) => {
        let results = response.data.data.results;
        setMasterDrivingLicense(
          ...masterDrivingLicense,
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //get master data
  const getProductType = async () => {
    await getAllProductType()
      .then((response) => {
        let results = response?.data.data.results;
        setProductType(
          results?.map((res) => ({
            value: res?.id,
            name: res?.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTruckType = async () => {
    const params = {
      sort_field: "created_at",
      sort_order: 1,
    };
    await getTruckTypeMS(params)
      .then((response) => {
        let results = response.data.data.results;
        setTruckType(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAccessories = async () => {
    await getAccessoriesMS()
      .then((response) => {
        let results = response.data.data.results;
        setAccessories(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getOptional = async () => {
    await getAllOptional()
      .then((response) => {
        let results = response.data.data.results;
        setOptional(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getQualification = async () => {
    await getAllQualification()
      .then((response) => {
        let results = response.data.data.results;
        setQualification(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAssociation = async () => {
    await getAllAssociation()
      .then((response) => {
        let results = response.data.data.results;
        setAssociation(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowContextMenu = (event, id) => {
    event.preventDefault();
    window.open(`admin/${id}`, "_blank"); // Open link in new tab
  };

  const handleRowClick = (event, id) => {
    if (event.button === 0) {
      // Left-click: Open link in new tab
      navigate(`/admin/${id}`);
    }
  };

  useEffect(() => {
    const hasShipperValues = Object.keys(filterUserShipper).some(
      (key) => filterUserShipper[key].length > 0
    );
    const hasCarrierValues = Object.keys(filterUserCarrier).some(
      (key) => filterUserCarrier[key].length > 0
    );
    const hasDriverValues = Object.keys(filterUserDriver).some(
      (key) => filterUserDriver[key].length > 0
    );
    if (hasShipperValues && hasCarrierValues && hasDriverValues) {
      setFilterUser({ ...filterUser, role: ["shipper", "carrier", "driver"] });
    } else if (hasShipperValues && hasCarrierValues) {
      setFilterUser({ ...filterUser, role: ["shipper", "carrier"] });
    } else if (hasShipperValues && hasDriverValues) {
      setFilterUser({ ...filterUser, role: ["shipper", "driver"] });
    } else if (hasCarrierValues && hasDriverValues) {
      setFilterUser({ ...filterUser, role: ["carrier", "driver"] });
    } else if (hasShipperValues) {
      setFilterUser({ ...filterUser, role: ["shipper"] });
    } else if (hasCarrierValues) {
      setFilterUser({ ...filterUser, role: ["carrier"] });
    } else if (hasDriverValues) {
      setFilterUser({ ...filterUser, role: ["driver"] });
    } else {
      setFilterUser({ ...filterUser, role: [] });
    }
  }, [filterUserShipper, filterUserCarrier, filterUserDriver]);

  const getAllUser = async (filter) => {
    const body = {
      shipper: filterUserShipper,
      carrier: filterUserCarrier,
      driver: filterUserDriver,
    };

    for (const role in body) {
      for (const prop in body[role]) {
        if (body[role][prop].length === 0) {
          delete body[role][prop];
        }
      }
    }

    setLoading(true);
    await getAllUserAPI(filter, body)
      .then((response) => {
        console.log(response.data.data);
        setLoading(false);
        setAdvanceFilter(false);
        setData(response.data.data);
        setFilterUser({ ...filterUser, total: response.data.total });
      })
      .catch(async (error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.code === 4106) {
          setErrorAlert({
            open: true,
            desc: error.response.data.description,
          });
        } else if (error.response.data.code === "4105") {
          removeAccessToken("access_token");
          window.location.reload();
        }
      });
  };

  const filterBar = () => {
    return (
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: "25px",
          boxShadow: `0px 4px 4px 0px rgba(140, 163, 186, 0.20)`,
          zIndex: 2,
          flexGrow: 1,
        }}
      >
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"กรองและจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"updated_at"}
              value={filterUser.sort_by}
              onChange={(val) => setFilterUser({ ...filterUser, sort_by: val })}
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterUser?.start_date ?? dateFrom,
                end_date: filterUser?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterUser({
                  ...filterUser,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <FieldSelect
              name={"ค้นหาจาก"}
              options={[
                { value: "company_name", name: "ชื่อบริษัท" },
                { value: "user_name", name: "ชื่อผู้ใช้งาน" },
                { value: "phone", name: "เบอร์" },
                { value: "user_code", name: "รหัสผู้ใช้งาน" },
                { value: "citizen_id", name: "เลขบัตรประชาชน" },
                { value: "tax_id", name: "เลขนิติบุคคล" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              defaultValue={"name"}
              value={filterUser.search_by}
              onChange={(val) =>
                setFilterUser({ ...filterUser, search_by: val })
              }
              closePaddingBottom={true}
            />
          </Grid>
          <Grid className={styles.gridItem} item xs={12} sm={6} md={6} lg={2.5}>
            <TextField
              id="search_val"
              variant="standard"
              style={{
                paddingTop: "16px",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filterUser.search_val ? filterUser.search_val : ""}
              onChange={(val) =>
                setFilterUser({ ...filterUser, search_val: val.target.value })
              }
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
            justifyContent="center"
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                setOpenModalUploadUser(true);
              }}
            >
              <IconUploadFile sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>

            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => {
                const filter = { ...filterUser, page: 1 };
                getAllUser(filter);
              }}
            >
              <Search sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
            <SelectRowValue
              onSelect={(val) => {
                setFilterUser({ ...filterUser, per_page: val });
              }}
            />
            {/* <FieldSelect
              name={"จำนวนแถว"}
              options={[
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 20, name: "20" },
                { value: 50, name: "50" },
                { value: 100, name: "100" },
              ]}
              defaultValue={5}
              value={filterUser.per_page}
              onChange={(val) => {
                setFilterUser({ ...filterUser, per_page: val });
              }}
              closePaddingBottom={true}
            /> */}
            <Avatar
              variant="rounded"
              sx={{
                width: "55px",
                height: "57px",
                // boxShadow: `0px 2px 2px 0px rgba(0, 0, 0, 0.15)`,
                filter: "drop-shadow(0px 4px 4px rgba(140, 163, 186, 0.2))",
                bgcolor: "white",
              }}
              onClick={() => setAdvanceFilter(true)}
            >
              <FilterAlt sx={{ color: "secondary.main", fontSize: 35 }} />
            </Avatar>
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={successAlert}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setSuccessAlert(false);
          const filter = { ...filterUser, page: 1 };
          getAllUser(filter);
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate("/");
        }}
      />
      <Drawer
        anchor="right"
        open={advanceFilter}
        // open={true}
        onClose={() => {
          setAdvanceFilter(false);
          const filter = { ...filterUser, page: 1 };
          getAllUser(filter);
        }}
        sx={{
          width: "424px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            width: "424px",
            display: "flex",
            padding: "34px",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <img
            src={CloseDrawer}
            height={"30px"}
            onClick={() => setAdvanceFilter(false)}
            alt=""
            style={{ transform: "scaleX(-1)" }}
          />
          <div style={{ padding: "10px", width: "100%" }}>
            <Typography
              fontSize={"20px"}
              fontWeight={500}
              sx={{ padding: "26px 0" }}
            >
              การกรองและการจัดเรียง
            </Typography>
            <FieldSelect
              name={"กรองและการจัดเรียงตาม"}
              options={[
                { value: "created_at", name: "วันที่สร้างรายการ" },
                { value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" },
              ]}
              style={{
                width: "-webkit-fill-available",
              }}
              value={filterUser.sort_by}
              onChange={(val) => setFilterUser({ ...filterUser, sort_by: val })}
              closePaddingBottom={true}
            />
            <FieldDateRangePicker
              className="dateSelect"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: filterUser?.start_date ?? dateFrom,
                end_date: filterUser?.end_date ?? dateTo,
              }}
              style={{ width: "-webkit-fill-available" }}
              onChange={(val) =>
                setFilterUser({
                  ...filterUser,
                  start_date: val[0],
                  end_date: val[1],
                })
              }
            />

            <div className="general">
              <Typography
                fontSize={"20px"}
                fontWeight={500}
                sx={{ padding: "20px 0" }}
              >
                ทั่วไป
              </Typography>
              {filterGeneral &&
                filterGeneral.map((value) => {
                  return (
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filterUser[value?.value]}
                      defaultValue={[]}
                      onChange={(val) => {
                        setFilterUser({ ...filterUser, [value?.value]: val });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      multiSelect={true}
                      showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  );
                })}
            </div>
            <div className="shipper">
              <Typography
                fontSize={"20px"}
                fontWeight={500}
                sx={{ padding: "20px 0" }}
              >
                ลูกค้า
              </Typography>
              {filterShipper &&
                filterShipper.map((value) => {
                  return value?.type === "select" ? (
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filterUserShipper[value?.value]}
                      defaultValue={[]}
                      onChange={(val) => {
                        setFilterUserShipper({
                          ...filterUserShipper,
                          [value?.value]: val,
                        });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      multiSelect={true}
                      showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  ) : (
                    // <FieldSelectArea
                    //   name={value?.name}
                    //   levelArea={["provinces"]}
                    //   onChange={(val) => {
                    //     setFilterUserShipper({
                    //       ...filterUserShipper,
                    //       [value?.value]: val,
                    //     });
                    //   }}
                    //   value={filterUserShipper[value?.value]}
                    //   showChild={true}
                    //   closePaddingBottom={true}
                    // />
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filterUserShipper[value?.value]}
                      // defaultValue={[]}
                      onChange={(val) => {
                        setFilterUserShipper({
                          ...filterUserShipper,
                          [value?.value]: [val],
                        });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      // multiSelect={true}
                      // showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  );
                })}
            </div>
            <div className="carrier">
              <Typography
                fontSize={"20px"}
                fontWeight={500}
                sx={{ padding: "20px 0" }}
              >
                ผู้ขนส่ง
              </Typography>
              {filterCarrier &&
                filterCarrier.map((value) => {
                  return value?.type === "select" ? (
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filterUserCarrier[value?.value]}
                      defaultValue={[]}
                      onChange={(val) => {
                        setFilterUserCarrier({
                          ...filterUserCarrier,
                          [value?.value]: val,
                        });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      multiSelect={true}
                      showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  ) : (
                    // <FieldSelectArea
                    //   name={value?.name}
                    //   levelArea={["provinces"]}
                    //   onChange={(val) => {
                    //     setFilterUserCarrier({
                    //       ...filterUserCarrier,
                    //       [value?.value]: val,
                    //     });
                    //   }}
                    //   value={filterUserCarrier[value?.value]}
                    //   showChild={true}
                    //   closePaddingBottom={true}
                    // />
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filterUserCarrier[value?.value]}
                      // defaultValue={[]}
                      onChange={(val) => {
                        setFilterUserCarrier({
                          ...filterUserCarrier,
                          [value?.value]: [val],
                        });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      // multiSelect={true}
                      // showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  );
                })}
            </div>
            <div className="driver">
              <Typography
                fontSize={"20px"}
                fontWeight={500}
                sx={{ padding: "20px 0" }}
              >
                พนักงานขับรถ
              </Typography>
              {filterDriver &&
                filterDriver.map((value) => {
                  return (
                    <FieldSelect
                      name={value?.name}
                      options={value?.options}
                      value={filterUserDriver[value?.value]}
                      defaultValue={[]}
                      onChange={(val) => {
                        setFilterUserDriver({
                          ...filterUserDriver,
                          [value?.value]: val,
                        });
                      }}
                      style={{
                        width: "-webkit-fill-available",
                      }}
                      multiSelect={true}
                      showSelectAll={true}
                      closePaddingBottom={true}
                    />
                  );
                })}
            </div>
            <div style={{ width: "100%", paddingTop: "26px" }}>
              <ButtonCustom
                name={"ล้างการกรอง"}
                onClick={() => {
                  setFilterUser({
                    sort_by: "updated_at",
                    search_by: "user_name",
                    search_val: "",
                    start_date: dateFrom,
                    end_date: dateTo,
                    page: 1,
                    per_page: 5,
                    total: 0,
                    is_main: [],
                    profile_type: [],
                    role: [],
                    active: [],
                  });
                  setFilterUserShipper({
                    shipper_grade_type: [],
                    shipper_approved_status: [],
                    shipper_payment_type: [],
                    shipper_load_province: [],
                    shipper_unload_province: [],
                    profile_shipper_product_type: [],
                    profile_shipper_truck_type: [],
                    profile_shipper_optional: [],
                    profile_shipper_accessories: [],
                    profile_shipper_qualification: [],
                  });
                  setFilterUserCarrier({
                    carrier_grade_type: [],
                    carrier_approved_status: [],
                    carrier_payment_type: [],
                    carrier_load_province: [],
                    carrier_unload_province: [],
                    profile_carrier_product_type: [],
                    profile_carrier_truck_type_manual: [],
                    profile_carrier_optional: [],
                    profile_carrier_accessories: [],
                    profile_carrier_qualification: [],
                    profile_carrier_association: [],
                  });
                  setFilterUserDriver({
                    driver_approved_status: [],
                    driving_license: [],
                    driver_truck_id: [],
                  });
                }}
                fullWidth={true}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Container>
        <SubMenu
          menus={subMenuUser.map((menu) => {
            return {
              label: menu.label,
              path: menu.path,
              active: menu.label === `ข้อมูลผู้ใช้งาน` ? true : false,
            };
          })}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
        {filterBar()}
        <div>
          <TableCustom
            columns={columns}
            dataSource={data}
            pagination={filterUser}
            setPagination={(val) => {
              setFilterUser({
                ...filterUser,
                ...val,
              });
            }}
            onSelectedRow={(val) => console.log(val)}
            href={`/user/`}
            // checkBox={true}
          />
        </div>

        <Avatar
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
          }}
          onClick={() => setOpenModalCreateUser(true)}
        >
          <Add sx={{ color: "white", fontSize: "90px" }} />
        </Avatar>
      </Container>

      {openModalCreateUser ? (
        <ModalCreateUser
          open={openModalCreateUser}
          handleClose={() => setOpenModalCreateUser(false)}
          alertSuccess={() => {
            setSuccessAlert(true);
          }}
          alertError={(e) => {
            setErrorAlert(e);
          }}
        />
      ) : (
        ""
      )}
      <ModalUploadFile
        open={openModalUploadUser}
        setOpen={setOpenModalUploadUser}
      />
    </React.Fragment>
  );
};

export default TableUser;
