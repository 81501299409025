import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm, Controller } from "react-hook-form";
import Loading from "components/loading/LoadingBackground";
import AlertModal from "components/modal/AlertModal";
import { Container, Avatar, Typography } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import { Save } from "@mui/icons-material";
import styles from "../TableAllProjectInProfile.module.css";
import {
  editCarrierInProject,
  getCarrierInProjectById,
  calculateCreditProject,
} from "utils/api/project/project";
import helper from "utils/helper";
import FieldSelect from "components/field/FieldSelect";
import FieldInput from "components/field/FieldInput";
import ButtonCustom from "components/ButtonCustom";
import { ActiveStatus, PaymentType, ReminderDate } from "utils/enum";
import FieldUpload from "components/field/FieldUpload";
const DetailCarrierInProject = () => {
  const { project_id, carrier_in_project_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [creditInfo, setCreditInfo] = useState(null);
  const [serviceMaster, setServiceMaster] = useState([]);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const [initialFormValues, setInitialFormValues] = useState({});
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: "",
    desc: "",
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();
  const carrier_payment_type = watch("carrier_payment_type");
  const MenuProject = [
    {
      label: "รายละเอียดผู้ขนส่งในโครงการ",
      path: "/",
      active: true,
    },
    {
      label: "ใบเสนอราคาตามอัตราน้ำมัน",
      path: "/",
    },
  ];

  const breadcrumbs = [
    {
      label: "ข้อมูลผู้ใช้งาน",
      path: "/user",
    },
    {
      label: `${dataDetail?.project.profile.user_info.user_code}`,
      path: "",
    },
    {
      label: "ข้อมูลโครงการ",
      path: "",
    },
    {
      label: `${dataDetail?.project.project_code}`,
      path: "",
    },
    {
      label: "ผู้ขนส่งในโครงการ",
      path: "",
    },
    {
      label: `${dataDetail?.profile.user_info.user_code}`,
      path: "",
    },
    {
      label: "รายละเอียดผู้ขนส่งในโครงการ",
      path: "",
    },
  ];

  useEffect(() => {
    if (carrier_in_project_id) {
      getDetailCarrierInProject(carrier_in_project_id);
      getCreditInfo();
    }
  }, []);

  useEffect(() => {
    console.log("creditInfo", creditInfo);
  }, [creditInfo]);

  useEffect(() => {
    console.log("dataDetail", dataDetail);
    if (dataDetail) {
      const newInitialFormValues = {};
      newInitialFormValues.active = String(dataDetail?.active) ?? "";
      newInitialFormValues.carrier_payment_type =
        dataDetail?.carrier_payment_type ?? "";
      newInitialFormValues.credit_term = dataDetail?.credit_term ?? "";
      newInitialFormValues.credit_length_date =
        dataDetail?.credit_length_date ?? "";
      newInitialFormValues.credit_bill_date =
        dataDetail?.credit_bill_date ?? "";
      newInitialFormValues.credit_remark = dataDetail?.credit_remark ?? "";
      newInitialFormValues.reminder_date =
        dataDetail?.project_reminder_carrier ?? "";
      newInitialFormValues.contract_file = dataDetail?.contract_file ?? "";
      newInitialFormValues.quotation_file = dataDetail?.quotation_file ?? "";
      newInitialFormValues.credit_limit = dataDetail?.credit_limit ?? "";

      setInitialFormValues(newInitialFormValues);
      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [dataDetail, setValue]);

  const getCreditInfo = async () => {
    if (project_id) {
      setLoading(true);
      const param = {
        project_payment_type: "carrier",
        carrier_in_project_id: carrier_in_project_id,
      };
      await calculateCreditProject(project_id, param)
        .then((response) => {
          if (response.data.code === 1000) {
            const rest = response.data.data;
            setCreditInfo(rest);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    }
  };

  const getDetailCarrierInProject = async (carrier_in_project_id) => {
    setLoading(true);
    await getCarrierInProjectById(carrier_in_project_id)
      .then((response) => {
        if (response.data.code === 1000) {
          const rest = response.data.data;
          const remind = [];
          rest.project_reminder_carrier.map((val) => {
            remind.push(val.reminder_date);
          });
          rest["project_reminder_carrier"] = remind;
          setDataDetail(rest);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const renderCard1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Typography fontSize={"20px"} fontWeight={500} sx={{ p: "13px" }}>
          {dataDetail?.profile.user_info.user_code || ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่สร้างรายการ{" "}
          {dataDetail?.created_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.created_at)
            : ""}
        </Typography>
        <Typography
          fontSize={"14px"}
          fontWeight={300}
          fontStyle={"italic"}
          color={"grey.main"}
        >
          วันที่แก้ไขข้อมูลล่าสุด{" "}
          {dataDetail?.updated_at
            ? helper.ToConvertDateTimeForTable(dataDetail?.updated_at)
            : ""}
        </Typography>
        <Controller
          control={control}
          name="active"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="สถานะการใช้งาน*"
                nameDescription="(ค่าเริ่มต้น:ใช้งาน)"
                options={ActiveStatus}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <div style={{ width: "100%" }}>
          <FieldInput
            label="ชื่อโปรไฟล์"
            readOnly={true}
            value={dataDetail?.profile.company_name}
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ paddingBottom: "23px", width: "100%" }}>
          <ButtonCustom
            name="ดูโปรไฟล์"
            fullWidth={true}
            onClick={() => {
              navigate(
                `/user/${dataDetail?.profile.user_info.id}/profile/${dataDetail?.profile_id}`
              );
            }}
          />
        </div>
        <Controller
          control={control}
          name="carrier_payment_type"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="ประเภทการรับเงินของผู้ขนส่ง*"
                nameDescription="(ค่าเริ่มต้น:เงินสด)"
                options={PaymentType}
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_term"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="จำนวนวันเครดิตเทอม*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                options={PaymentType}
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_length_date"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="รอบเวลาที่นับการใช้งาน*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                options={PaymentType}
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_bill_date"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="วันที่วางบิลของทุกเดือน*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                options={PaymentType}
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_remark"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="หมายเหตุเครดิต"
                options={PaymentType}
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="reminder_date"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="วันที่ต้องการเตือนวางบิล*"
                nameDescription="(บังคับถ้าเป็นเครดิต)"
                options={ReminderDate}
                value={value}
                onChange={onChange}
                defaultValue={[]}
                multiSelect={true}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
      </div>
    );
  };

  const renderCard2 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          control={control}
          name="contract_file"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%", paddingTop: "20px" }}>
              <FieldUpload name="ไฟล์สัญญา" value={value} onChange={onChange} />
            </div>
          )}
        />
        <Controller
          control={control}
          name="quotation_file"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldUpload
                name="ไฟล์ใบเสนอราคา"
                value={value}
                onChange={onChange}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="credit_limit"
          render={({ field: { value, onChange } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="วงเงินเครดิตที่ให้วีมูฟฯ*"
                subLabel="(บังคับถ้าเป็นเครดิต)"
                value={value}
                type="number"
                onChange={onChange}
                style={{ width: "100%" }}
                readOnly={carrier_payment_type === 1 ? false : true}
              />
            </div>
          )}
        />
        <div style={{ width: "100%" }}>
          <FieldInput
            label="วงเงินเครดิตที่ใช้ไป"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addCommaWith2digit(creditInfo?.credit_used)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label="วงเงินเครดิตคงเหลือ"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addCommaWith2digit(creditInfo?.credit_balance)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label="จำนวนการรับงานทั้งหมด"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addComma(creditInfo?.all_work) === "-"
                  ? "0"
                  : helper.addComma(creditInfo?.all_work)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label="จำนวนงานที่ถูกยกเลิก"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addComma(creditInfo?.work_cancel) === "-"
                  ? "0"
                  : helper.addComma(creditInfo?.work_cancel)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label="จำนวนงานที่กำลังดำเนินการ"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addComma(creditInfo?.work_in_progress) === "-"
                  ? "0"
                  : helper.addComma(creditInfo?.work_in_progress)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label="จำนวนงานที่เสร็จสิ้น"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addComma(creditInfo?.work_success) === "-"
                  ? "0"
                  : helper.addComma(creditInfo?.work_success)
                : ""
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <FieldInput
            label="จำนวนเงินค้างรับรวม"
            style={{ width: "100%" }}
            readOnly={true}
            value={
              carrier_payment_type == 1
                ? helper.addCommaWith2digit(creditInfo?.all_work_unpaid)
                : ""
            }
          />
        </div>
      </div>
    );
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    console.log("initialFormValues", initialFormValues);
    setLoading(true);
    const changedData = {};
    //check array
    if (
      data.reminder_date &&
      JSON.stringify(data.reminder_date) !==
        JSON.stringify(initialFormValues.reminder_date)
    ) {
      changedData.reminder_date = data.reminder_date;
    }

    Object.keys(data).forEach((fieldName) => {
      if (
        Array.isArray(data[fieldName]) &&
        JSON.stringify(data[fieldName]) !==
          JSON.stringify(initialFormValues[fieldName])
      ) {
        changedData[fieldName] = data[fieldName];
      } else if (
        !Array.isArray(data[fieldName]) &&
        data[fieldName] !== initialFormValues[fieldName]
      ) {
        changedData[fieldName] = data[fieldName];
        if (data[fieldName] === "") {
          changedData[fieldName] = null;
        } else {
          changedData[fieldName] = data[fieldName];
        }
      }
    });

    console.log("changedData", changedData);
    const GForm = new FormData();
    Object.keys(changedData).forEach((key) => {
      if (Array.isArray(changedData[key])) {
        GForm.append(key, JSON.stringify(changedData[key]));
      } else if (changedData[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, changedData[key]);
      }
    });

    if (carrier_in_project_id) {
      await editCarrierInProject(carrier_in_project_id, GForm)
        .then((response) => {
          console.log("response", response);
          if (response.data.code === 1000) {
            setLoading(false);
            setAlertModal({
              open: true,
              title: "บันทึก",
              desc: "บันทึกข้อมูลสำเร็จ",
            });
            console.log("edit Success");
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              setErrorAlert({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window?.location?.reload();
            }
          }
        });
    }
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      {alertModal.open === true && (
        <AlertModal
          open={alertModal.open}
          title={alertModal.title}
          description={alertModal.desc}
          onClose={() => {
            setAlertModal({
              open: false,
              title: "",
              desc: "",
            });
          }}
        />
      )}
      {errorAlert.open === true && (
        <AlertModal
          open={errorAlert.open}
          title="เกิดข้อผิดพลาด"
          description={errorAlert.desc}
          onClose={() => {
            setErrorAlert({
              open: false,
              desc: "",
            });
            navigate(-1);
          }}
        />
      )}
      <Container>
        <SubMenu
          menus={MenuProject}
          breadcrumbs={breadcrumbs}
          pathbackward={-1}
        />
      </Container>
      <form
        className={styles.containerDetailProject}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderCard1()}
        {renderCard2()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: "white", fontSize: "70px" }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default DetailCarrierInProject;
