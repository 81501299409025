import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import "./inputDisable.css";
export default function InputSelect({
  error,
  value,
  onChange,
  label,
  id,
  name,
  subFirst,
  subSec,
  type = "text",
  options = [],
  allowNull = false,
  disable = false,
}) {
  // console.log("error", error);
  return (
    <div
      className={
        "inputNormal " +
        (error ? "errorLabel" : "") +
        (disable ? " inputDisable" : "")
      }
    >
      <FormControl variant="standard" autoFocus fullWidth margin="none">
        <InputLabel color={error ? "error" : "primary"}>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          label={label}
          error={error}
          disabled={disable}
          sx={{
            backgroundColor: disable ? "rgb(140,163,186,0.2)" : "",
          }}
        >
          {allowNull ? (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          ) : null}
          {options.map((ele, index) => {
            return (
              <MenuItem key={index + ele.value + ele.item} value={ele.value}>
                {ele.item}
              </MenuItem>
            );
          })}
        </Select>
        <div className="inputNormalSub">
          <div>{subFirst}</div>
        </div>
        <div className="inputNormalSubSec">
          <div>{subSec}</div>
        </div>
      </FormControl>
    </div>
  );
}
