import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Space, Tag } from "antd";
import { Container } from "@mui/material";
import {
  getAllTruck,
  getAllTruckInProfile,
  uploadExcelTruck,
} from "../../utils/api/truck/truck";
import TableActiveIcon from "components/tableActiveIcon";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FloatingButton from "components/button/FloatingButton";
import TableCustom from "components/table/TableCustom";
import SubMenu from "components/layout/SubMenu.js";
import { subMenuTruck } from "utils/submenuoptions";
import FilterTab from "components/filterTab/FilterTab";
import { getTruckType } from "utils/api/masterData/truckType";
import {
  cover_insurance_product,
  cover_rate_insurance_product,
  truck_document_approve_status,
} from "utils/enum";
import { getAllAccessory } from "utils/api/masterData/accessory";
import { getTruckBrand } from "utils/api/masterData/truckBrand";
import helper from "../../utils/helper";
import CreateTruckModal from "./CreateTruckModal";
import SlideTransition from "../../components/transition/SlideTransition";
import { getUserProfile } from "utils/api/profile/profile";
import UserDetailSubMenu from "components/layout/SubMenu.js/UserDetailSubMenu";
import { getValue } from "@testing-library/user-event/dist/utils";
import { getUserById } from "utils/api/user/user";
import AlertModal from "components/modal/AlertModal";
import Loading from "components/loading/Loading";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "truck_type.name",
    label: "ประเภทรถ",
    align: "center",
    format: (value) => {
      return value.truck_type.name;
    },
  },
  {
    // id: "license_plate_head",
    label: "ทะเบียนรถ",
    align: "center",
    format: (value) => {
      return (
        <div
          style={{
            fontWeight: 500,
            color:
              value.document_approve_status_id === 0
                ? "#2499EF"
                : value.document_approve_status_id === 1
                ? "#27CE88"
                : value.document_approve_status_id === 2
                ? "#FFC675"
                : value.document_approve_status_id === 3
                ? "#FF316F"
                : value.document_approve_status_id === 4
                ? "#8CA3BA"
                : "#CC99FF",
          }}
        >
          <div>{value.license_plate_head}</div>
          {!R.isNil(value.license_plate_tail) && (
            <div>{value.license_plate_tail}</div>
          )}
        </div>
      );
    },
  },
  {
    id: "profile.company_name",
    label: "ชื่อโปรไฟล์",
    align: "center",
    format: (value) => {
      return value.profile.company_name;
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const Truck = () => {
  const { user_id, profile_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `license_plate`,
    search_val: ``,
    active: "all",
    document_approve_status: -1,
    truck_type_id: -1,
    truck_insurance_head: "all",
    truck_insurance_tail: "all",
    cover_insurance: "ทั้งหมด",
    truck_brand_head: [-1],
    truck_brand_tail: [-1],
    truck_tax_head: "all",
    truck_tax_tail: "all",
    truck_accessories: [-1],
  };

  const [truck, setTruck] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [allTruckType, setAllTruckType] = useState([]);
  const [allAccessories, setAllAccessories] = useState([]);
  const [allTruckBrand, setAllTruckBrand] = useState([]);
  const [profile, setProfile] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });
    handleGetProfile();
    handleGetUserInfo();
    handleGetAlltruckType();
    handleGetAllAccessories();
    handleGetTruckBrand();
    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
  }) => {
    const params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      [filter.search_field]: filter.search_val,
      active:
        filter.active !== `all`
          ? filter.active === `true`
            ? true
            : false
          : null,
      document_approve_status:
        Number(filter.document_approve_status) !== -1
          ? Number(filter.document_approve_status)
          : null,
      truck_type_id: filter.truck_type_id !== -1 ? filter.truck_type_id : null,
      truck_insurance_head:
        filter.truck_insurance_head !== `all`
          ? filter.truck_insurance_head === `true`
            ? true
            : false
          : null,
      truck_insurance_tail:
        filter.truck_insurance_tail !== `all`
          ? filter.truck_insurance_tail === `true`
            ? true
            : false
          : null,
      cover_insurance:
        filter.cover_insurance !== "ทั้งหมด" ? filter.cover_insurance : null,
      truck_brand_head: !filter.truck_brand_head.includes(-1)
        ? filter.truck_brand_head
        : null,
      truck_brand_tail: !filter.truck_brand_tail.includes(-1)
        ? filter.truck_brand_tail
        : null,
      truck_tax_head:
        filter.truck_tax_head !== `all`
          ? filter.truck_tax_head === `true`
            ? true
            : false
          : null,
      truck_tax_tail:
        filter.truck_tax_tail !== `all`
          ? filter.truck_tax_tail === `true`
            ? true
            : false
          : null,
      truck_accessories: !filter.truck_accessories.includes(-1)
        ? filter.truck_accessories
        : null,
      page: page,
      per_page: per_page,
    };

    console.log("params: ", params);
    handleGetAllTruck({
      page: page,
      per_page: per_page,
      new_params: params,
    });
    setParams(params);
  };

  const handleGetAllTruck = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }

      let resp = null;
      if (location.pathname === `/truck`) {
        resp = await getAllTruck(new_params);
      } else {
        resp = await getAllTruckInProfile(profile_id, new_params);
      }

      setTruck(resp.data.data.results);
      setPagination({
        page: page,
        per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllTruck: ", error);
      setIsLoading(false);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
    }
  };

  const handleGetProfile = async () => {
    try {
      if (!R.isNil(profile_id) && !R.isEmpty(profile_id)) {
        const resp = await getUserProfile(profile_id);
        setProfile(resp.data.data);
      }
    } catch (error) {
      console.log("error getProfile: ", error);
    }
  };

  const handleGetUserInfo = async () => {
    try {
      if (!R.isNil(user_id)) {
        const resp = await getUserById(user_id);
        setUserInfo(resp.data.data);
      }
    } catch (error) {
      console.log("error handleGetUserInfo: ", error);
    }
  };

  const handleGetAlltruckType = async () => {
    try {
      const resp = await getTruckType({
        sort_field: [`order_number`],
        sort_order: [1],
      });
      setAllTruckType(resp.data.data.results);
    } catch (error) {
      console.log("error getAllTruckType: ", error);
    }
  };

  const handleGetAllAccessories = async () => {
    try {
      const resp = await getAllAccessory({
        sort_field: [`created_at`],
        sort_order: [1],
      });

      setAllAccessories(resp.data.data.results);
    } catch (error) {
      console.log("error getAllAccessories: ", error);
    }
  };

  const handleGetTruckBrand = async () => {
    try {
      const resp = await getTruckBrand({
        page: null,
        per_page: null,
        active: true,
        sort_field: ["id"],
        sort_order: [1],
      });
      const data = resp.data.data.results;
      setAllTruckBrand(data);
    } catch (error) {
      console.log("error getTruckBrand: ", error);
    }
  };

  const handleUploadTruckExcel = async ({ file = null }) => {
    setIsFileLoading(true);
    try {
      const is_excel_file = helper.isCsvOrXlsxfile({
        file: file,
      });

      if (is_excel_file === true) {
        const upload_response = await uploadExcelTruck({
          truck_excel: file,
        });

        if (upload_response.data.code === 1000) {
          handleGetDataByNewParams({ page: 1 });
          setAlertModal({
            open: true,
            title: `อัพโหลดสำเร็จ`,
            description: `อัพโหลดข้อมูลสำเร็จ`,
          });
        }
      } else {
        setAlertModal({
          open: true,
          title: `พบปัญหาขณะอัพโหลดไฟล์`,
          description: `กรุณาใส่ไฟล์ .csv | .xlsx | .xls`,
        });
      }

      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
      setAlertModal({
        open: true,
        title: `พบปัญหาขณะอัพโหลดไฟล์`,
        description: error.response.data.description,
      });
      console.log(`error handleUploadTruckExcel:`, error);
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else if (
      field_type === `truck_accessories` ||
      field_type === `truck_brand_head` ||
      field_type === `truck_brand_tail`
    ) {
      const last_val = val[val.length - 1];
      if (last_val === -1) {
        val = [-1];
      } else {
        val = val.filter((item) => item !== -1);
      }
      setFilter({ ...filter, [field_type]: val });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    if (location.pathname === `/truck`) {
      return (
        <SubMenu
          label={`ข้อมูลรถ`}
          menus={subMenuTruck.map((menu) => {
            return {
              label: menu.label,
              path: menu.path,
              active: menu.label === `ข้อมูลรถ` ? true : false,
            };
          })}
          breadcrumbs={[
            {
              label: "ข้อมูลรถ",
              path: null,
            },
          ]}
        />
      );
    } else {
      return (
        <UserDetailSubMenu
          label={`ข้อมูลรถในโปรไฟล์`}
          pathbackward={-1}
          profile_id={profile_id}
          user_id={user_id}
          user_code={userInfo.user_code ? userInfo.user_code : null}
          breadcrumbs={[
            {
              label: "ข้อมูลรถ",
              path: null,
            },
            {
              label:
                profile && profile.user_info && profile.user_info.user_code
                  ? profile.user_info.user_code
                  : ``,
              path: null,
            },
            {
              label: "ข้อมูลรถในโปรไฟล์",
              path: null,
            },
          ]}
        />
      );
    }
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchFieldOption={{
          value: filter.search_field,
          options: [{ value: "license_plate", name: "ทะเบียนรถ" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchValueOption={{
          onPressEnter: (field_type, val) => {
            handleGetDataByNewParams({ page: 1 });
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        uploadButtonOption={{
          onClick: (file) => {
            if (!R.isNil(file)) {
              handleUploadTruckExcel({ file: file });
            }
          },
        }}
        searchButtonOption={{
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetAllTruck({
              page: 1,
              per_page: value,
            });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    const list_form = [
      {
        name: "กรองและจัดเรียงตาม",
        field_type: "select",
        value: "updated_at",
        options: [
          {
            value: "updated_at",
            name: "วันที่แก้ไขข้อมูลล่าสุด",
          },
        ],
        onChange: (val) => {
          onChangeFilter("updated_at", val);
        },
      },
      {
        name: "วันเริ่มต้น - วันสิ้นสุด",
        field_type: "dateRangePicker",
        value: { start_date: filter.start_date, end_date: filter.end_date },
        onChange: (val) => {
          onChangeFilter(`date_duration`, val);
        },
      },
      {
        name: "สถานะใช้งาน",
        field_type: "select",
        value: filter.active,
        options: [
          {
            value: "all",
            name: "ทั้งหมด",
          },
          {
            value: "true",
            name: "ใช้งาน",
          },
          {
            value: "false",
            name: "ไม่ใช้งาน",
          },
        ],
        onChange: (val) => {
          onChangeFilter(`active`, val);
        },
      },
      {
        name: "สถานะการตรวจสอบเอกสาร",
        field_type: "select",
        value: filter.document_approve_status,
        options: [
          { id: -1, name: "ทั้งหมด" },
          ...truck_document_approve_status,
          { id: 5, name: "รอเพิ่มข้อมูล" },
        ].map((approve_status) => {
          return {
            value: String(approve_status.id),
            name: approve_status.name,
          };
        }),
        onChange: (val) => {
          onChangeFilter(`document_approve_status`, val);
        },
      },
      {
        name: "ประเภทรถ",
        field_type: "select",
        value: filter.truck_type_id,
        options: [{ id: -1, name: "ทั้งหมด" }, ...allTruckType].map(
          (truck_type) => {
            return {
              value: truck_type.id,
              name: truck_type.name,
            };
          }
        ),
        onChange: (val) => {
          onChangeFilter(`truck_type_id`, val);
        },
      },
      {
        name: "สถานะการมีประกันภัยสินค้าส่วนหัว",
        field_type: "select",
        value: filter.truck_insurance_head,
        options: [
          {
            value: "all",
            name: "ทั้งหมด",
          },
          {
            value: "true",
            name: "มี",
          },
          {
            value: "false",
            name: "ไม่มี",
          },
        ],
        onChange: (val) => {
          onChangeFilter(`truck_insurance_head`, val);
        },
      },
      {
        name: "สถานะการมีประกันภัยสินค้าส่วนหาง",
        field_type: "select",
        value: filter.truck_insurance_tail,
        options: [
          {
            value: "all",
            name: "ทั้งหมด",
          },
          {
            value: "true",
            name: "มี",
          },
          {
            value: "false",
            name: "ไม่มี",
          },
        ],
        onChange: (val) => {
          onChangeFilter(`truck_insurance_tail`, val);
        },
      },
      {
        name: "จำนวนทุนประกันภัยสินค้า",
        field_type: "select",
        value: filter.cover_insurance,
        options: [
          {
            id: -1,
            name: "ทั้งหมด",
          },
          ...cover_rate_insurance_product,
        ].map((insure) => {
          return {
            value: insure.name,
            name: insure.name,
          };
        }),
        onChange: (val) => {
          onChangeFilter(`cover_insurance`, val);
        },
      },
      {
        name: "ยี่ห้อรถส่วนหัว",
        field_type: "select",
        multi_select: true,
        value: filter.truck_brand_head,
        options: [
          { id: -1, truck_brand_name: `ทั้งหมด` },
          ...allTruckBrand,
        ].map((brand) => {
          return {
            value: brand.id,
            name: brand.truck_brand_name,
          };
        }),
        onChange: (val) => {
          onChangeFilter(`truck_brand_head`, val);
        },
      },
      {
        name: "ยี่ห้อรถส่วนหาง",
        field_type: "select",
        multi_select: true,
        value: filter.truck_brand_tail,
        options: [
          { id: -1, truck_brand_name: `ทั้งหมด` },
          ...allTruckBrand,
        ].map((brand) => {
          return {
            value: brand.id,
            name: brand.truck_brand_name,
          };
        }),
        onChange: (val) => {
          onChangeFilter(`truck_brand_tail`, val);
        },
      },
      {
        name: "สถานะการมีภาษีรถส่วนหัว",
        field_type: "select",
        value: filter.truck_tax_head,
        options: [
          {
            value: "all",
            name: "ทั้งหมด",
          },
          {
            value: "true",
            name: "มี",
          },
          {
            value: "false",
            name: "ไม่มี",
          },
        ],
        onChange: (val) => {
          onChangeFilter(`truck_tax_head`, val);
        },
      },
      {
        name: "สถานะการมีภาษีรถส่วนหาง",
        field_type: "select",
        value: filter.truck_tax_tail,
        options: [
          {
            value: "all",
            name: "ทั้งหมด",
          },
          {
            value: "true",
            name: "มี",
          },
          {
            value: "false",
            name: "ไม่มี",
          },
        ],
        onChange: (val) => {
          onChangeFilter(`truck_tax_tail`, val);
        },
      },
      {
        name: "อุปกรณ์เสริมติดรถ",
        field_type: "select",
        multi_select: true,
        value: filter.truck_accessories,
        options: [{ id: -1, name: `ทั้งหมด` }, ...allAccessories].map(
          (accessory) => {
            return {
              value: accessory.id,
              name: accessory.name,
            };
          }
        ),
        onChange: (val) => {
          onChangeFilter(`truck_accessories`, val);
        },
      },
    ];

    return (
      <div>
        {list_form.map((menu, index) => {
          if (menu.field_type === `select`) {
            return (
              <FieldSelect
                key={index}
                name={menu.name ? menu.name : ""}
                multiSelect={menu.multi_select}
                value={menu.value ? menu.value : ""}
                options={menu.options ? menu.options : []}
                onChange={menu.onChange}
                style={{ width: `100%` }}
              />
            );
          } else if (menu.field_type === `dateRangePicker`) {
            return (
              <FieldDateRangePicker
                key={index}
                name={menu.name}
                popupClassName="FieldDateRangePicker-drawer-popup"
                value={menu.value}
                onChange={menu.onChange}
                style={{ width: `100%`, marginBottom: 26 }}
              />
            );
          }
        })}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={truck}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          handleGetAllTruck({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        href={
          location.pathname === `/truck`
            ? `/truck/`
            : `/user/${user_id}/profile/${profile_id}/truck-in-profile/detail/`
        }
      />
    );
  };
  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <>
      <Container>
        {renderSubMenu()}
        {renderFilterTab()}
        {isFileLoading === true ? <Loading /> : renderTable()}
        {/* {renderTable()} */}

        {location.pathname !== `/truck` && (
          <FloatingButton
            button_type="add"
            onClick={() => {
              setOpenCreateModal(true);
            }}
          />
        )}
      </Container>

      {location.pathname !== `/truck` && (
        <CreateTruckModal
          open={openCreateModal}
          setOpen={setOpenCreateModal}
          profile_id={profile_id}
          updateTableData={async () => {
            handleGetAllTruck({ page: 1 });
          }}
        />
      )}

      {renderModal()}
    </>
  );
};

export default Truck;
