// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreatePrivacyPolicyModal_field__3ZzT- {
  margin: 3px 0px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/masterData/privacyPolicy/CreatePrivacyPolicyModal/CreatePrivacyPolicyModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".field {\n  margin: 3px 0px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `CreatePrivacyPolicyModal_field__3ZzT-`
};
export default ___CSS_LOADER_EXPORT___;
