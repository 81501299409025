import React, { useEffect, useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import { useCookies } from "react-cookie";
import { useForm, Controller } from "react-hook-form";
import "./Login.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckboxOutline from "components/input/checkboxOutline";
import auth from "utils/api/auth/auth";
import LoadingBackground from "components/loading/LoadingBackground";
import FieldInput from "components/field/FieldInput";
let api = auth.create();
export default function Login(props) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkLogin, setCheckLogin] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();
  const error = {
    username: "ชื่อผู้ใช้ไม่ถูกต้อง",
    password: "รหัสผ่านไม่ถูกต้อง",
  };
  const watchUsername = watch("username");
  const watchPassword = watch("password");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (cookies.access_token) {
      // removeCookie("access_token");
      window.location.href = "/";
      // setCheckLogin(true);
    } else {
      setCheckLogin(true);
    }
  }, [cookies]);
  useEffect(() => {
    setTimeout(() => {
      remember();
    }, 500);
  }, []);
  const remember = async () => {
    let username = await localStorage.getItem("username");
    let password = await localStorage.getItem("password");
    if (username && password) {
      setValue("username", username);
      setValue("password", password);
      setRememberPassword(true);
    }
  };
  const login = (data) => {
    console.log(data);
    setLoading(true);
    api
      .login(data)
      .then((response) => {
        if (rememberPassword) {
          localStorage.setItem("username", watchUsername);
          localStorage.setItem("password", watchPassword);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
        console.log(response);
        setLoading(false);
        setCookie("access_token", response.data.results.access_token, {
          path: "/",
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code === 4101) {
          setError("username");
          setError("password");
        } else {
          alert("เกิดข้อผิดพลาด");
        }
        setLoading(false);
      });
  };
  return (
    <div>
      {loading ? <LoadingBackground /> : null}
      {checkLogin ? (
        <div className="loginBody">
          <div className="loginBodySlide">
            <img
              src={require("../../assets/image/LoginSlide.png")}
              alt="Slide"
              height={"40%"}
            />
          </div>
          <div className="loginBodyForm">
            <div className="loginBodySubForm">
              <img
                src={require("../../assets/image/WemoveLogo.png")}
                alt="logo"
                height={80}
              />
              <Typography component="h1" variant="h4">
                เข้าสู่ระบบ WeMove Admin
              </Typography>
              <label>กรอกชื่อผู้ใช้งานและรหัสผ่าน</label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="normal"
                    // size="medium"
                  >
                    <InputLabel
                      htmlFor="standard-adornment-username"
                      color={errors.username ? "error" : "primary"}
                    >
                      ชื่อผู้ใช้งาน
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.username}
                      id="username"
                      label="ชื่อผู้ใช้งาน"
                      value={value}
                      name="username"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="username"
                defaultValue=""
              />
              {errors.username && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                  }}
                >
                  <label
                    style={{
                      color: "#ff316f",
                      fontSize: 10,
                    }}
                  >
                    {error.username}
                  </label>
                </div>
              )}

              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl variant="standard" fullWidth margin="none">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      color={errors.password ? "error" : "primary"}
                    >
                      รหัสผ่าน
                    </InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      label="รหัสผ่าน"
                      name="password"
                      value={value}
                      id="password"
                      error={errors.password}
                      autoComplete="current-password"
                      onChange={onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon
                                sx={{
                                  color: errors.password
                                    ? "#ff316f"
                                    : "#8ca3ba",
                                }}
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                sx={{
                                  color: errors.password
                                    ? "#ff316f"
                                    : "#8ca3ba",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
                name="password"
                defaultValue=""
              />
              {errors.password && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                  }}
                >
                  <label style={{ color: "#ff316f", fontSize: 10 }}>
                    {error.password}
                  </label>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "start",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <CheckboxOutline
                  label={"จดจำรหัสผ่าน"}
                  value={rememberPassword}
                  onClick={() => {
                    setRememberPassword(!rememberPassword);
                  }}
                />
                <label
                  style={{ color: "#121f43", cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = "/forget-password";
                  }}
                >
                  ลืมรหัสผ่าน
                </label>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmit(login)}
              >
                เข้าสู่ระบบ
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
