// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputDisable .Mui-disabled:before {
  border-bottom-style: solid;
}

.inputNormal .MuiInputBase-root {
  padding: 10px 0px 10px 10px;
}
.errorLabel .MuiFormLabel-root {
  color: #ff316f;
}
.errorLabel .MuiSelect-iconStandard {
  color: #ff316f;
}
.inputNormal .MuiFormLabel-root {
  padding: 10px 0px 10px 10px;
}
.inputNormalSub {
  font-size: 10px;
  margin-left: 10px;
  position: absolute;
  color: #8ca3ba;
  text-align: start;
  /* top: -16px; */
  bottom: 0px;
}
.inputNormalSubSec {
  font-size: 10px;
  margin-left: 10px;
  position: absolute;
  color: #8ca3ba;
  text-align: start;
  /* top: -16px; */
  bottom: -16px;
}
.inputDisable .MuiInput-input {
  -webkit-text-fill-color: #121f43 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/inputDisable.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,2CAA2C;AAC7C","sourcesContent":[".inputDisable .Mui-disabled:before {\n  border-bottom-style: solid;\n}\n\n.inputNormal .MuiInputBase-root {\n  padding: 10px 0px 10px 10px;\n}\n.errorLabel .MuiFormLabel-root {\n  color: #ff316f;\n}\n.errorLabel .MuiSelect-iconStandard {\n  color: #ff316f;\n}\n.inputNormal .MuiFormLabel-root {\n  padding: 10px 0px 10px 10px;\n}\n.inputNormalSub {\n  font-size: 10px;\n  margin-left: 10px;\n  position: absolute;\n  color: #8ca3ba;\n  text-align: start;\n  /* top: -16px; */\n  bottom: 0px;\n}\n.inputNormalSubSec {\n  font-size: 10px;\n  margin-left: 10px;\n  position: absolute;\n  color: #8ca3ba;\n  text-align: start;\n  /* top: -16px; */\n  bottom: -16px;\n}\n.inputDisable .MuiInput-input {\n  -webkit-text-fill-color: #121f43 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
