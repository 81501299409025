// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalCreateUser_modalStyle__-6\\+x3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 400px;
  min-height: 800px;
  padding: 25px;
  /* border: 2px solid #000; */
}

.ModalCreateUser_modalStyle__-6\\+x3 :focus-visible {
  outline: none;
}

.ModalCreateUser_titleUserRole__Td97x {
  width: 100%;
  padding: 13px;
  text-align: center;
  border-bottom: 2.5px solid var(--mui-palette-grey-main);
}
`, "",{"version":3,"sources":["webpack://./src/pages/user/ModalCreateUser.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uDAAuD;AACzD","sourcesContent":[".modalStyle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  min-width: 400px;\n  min-height: 800px;\n  padding: 25px;\n  /* border: 2px solid #000; */\n}\n\n.modalStyle :focus-visible {\n  outline: none;\n}\n\n.titleUserRole {\n  width: 100%;\n  padding: 13px;\n  text-align: center;\n  border-bottom: 2.5px solid var(--mui-palette-grey-main);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalStyle": `ModalCreateUser_modalStyle__-6+x3`,
	"titleUserRole": `ModalCreateUser_titleUserRole__Td97x`
};
export default ___CSS_LOADER_EXPORT___;
