import React, { useEffect, useState } from "react";
import "./input.css";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import { IconButton } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ModalWork from "components/work/modalWork";
export default function InputImageShow({
  value = [null, null, null, null, null],
  onChange,
  onClick,
  id = 0,
  disable = false,
}) {
  const [img, setImg] = useState([null, null, null, null, null]);
  const [showImage, setShowImage] = useState(null);
  const [add, setAdd] = useState([]);
  const addImage = (index) => {
    document.getElementById(id + "upload_img_product" + index).click();
  };
  useEffect(() => {
    if (value?.length < 5) {
      let tmp = [];
      for (let i = value.length; i < 5; i++) {
        tmp.push(null);
      }
      setAdd(tmp);
      // console.log("value", value);
    }
  }, [value]);
  const onFileChange = (e, index) => {
    // console.log(e, index);
    if (e.target.files || e.target.files.length > 0) {
      const file = e.target.files[0];
      if (!value || value === "" || value === undefined) {
        value = [null, null, null, null, null];
      }
      let tmp = [...value];
      if (tmp[index]) {
        tmp[index] = {
          src: URL.createObjectURL(file),
          file,
        };
      } else {
        let notChange = true;
        tmp.forEach((ele, i) => {
          if (notChange && !ele) {
            console.log("here");
            tmp[i] = {
              src: URL.createObjectURL(file),
              file,
            };
            notChange = false;
          }
          // if (ele && i === index) {
        });
      }
      // setImg(tmp);
      // console.log("tmp", tmp);
      onChange(tmp);
      // let tmp = [...value];
      // tmp.push({
      //   src: URL.createObjectURL(file),
      //   file,
      // });
      //
      // console.log(this.state.img_product)
    }
  };

  const removeImage = (index) => {
    let tmp = [...value];
    tmp.splice(index, 1);
    tmp.push(null);
    onChange(tmp);
  };
  return (
    <div className="inputImageShow">
      {value
        ? value?.map((ele, index) => {
            return (
              <IconButton
                size="large"
                className="inputImageBox"
                onClick={() =>
                  disable
                    ? ele?.src
                      ? setShowImage(ele.src)
                      : null
                    : ele
                    ? removeImage(index)
                    : addImage(index)
                }
                key={index + "image"}
              >
                {ele ? (
                  <div
                    style={{
                      position: "relative",
                      height: 54,
                      width: 54,
                    }}
                  >
                    <img
                      src={ele.src}
                      alt="product"
                      height={53}
                      width={53}
                      style={{
                        objectFit: "cover",
                      }}
                      className="inputImageBox"
                    />
                    {disable ? null : (
                      <div
                        style={{
                          position: "absolute",
                          height: 53,
                          width: 53,
                          top: 13.25,
                        }}
                      >
                        <RemoveCircleOutlineIcon
                          sx={{ fontSize: 30, cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <CameraAltRoundedIcon
                    fontSize="inherit"
                    sx={{ color: "#8ca3ba", fontSize: 30 }}
                  />
                )}
                <input
                  type={"file"}
                  id={id + "upload_img_product" + index}
                  accept="image/*"
                  onChange={(val) => onFileChange(val, index)}
                  style={{ display: "none" }}
                />
              </IconButton>
            );
          })
        : img?.map((ele, index) => {
            return (
              <IconButton
                size="large"
                className="inputImageBox"
                onClick={() => addImage(index)}
                key={index + "image"}
              >
                {ele ? (
                  <img src={ele.src} alt="product" height={30} width={30} />
                ) : (
                  <CameraAltRoundedIcon
                    fontSize="inherit"
                    sx={{ color: "#8ca3ba", fontSize: 30 }}
                  />
                )}
                <input
                  type={"file"}
                  id={id + "upload_img_product" + index}
                  accept="image/*"
                  onChange={(val) => onFileChange(val, index)}
                  style={{ display: "none" }}
                />
              </IconButton>
            );
          })}
      {add.length > 0
        ? add.map((ele, index) => {
            return (
              <IconButton
                size="large"
                className="inputImageBox"
                key={index + "image"}
              >
                {ele ? (
                  <div
                    style={{
                      position: "relative",
                      height: 54,
                      width: 54,
                    }}
                  >
                    <img
                      src={ele.src}
                      alt="product"
                      height={53}
                      width={53}
                      className="inputImageBox"
                    />
                    {disable ? null : (
                      <div
                        style={{
                          position: "absolute",
                          height: 53,
                          width: 53,
                          top: 13.25,
                        }}
                      >
                        <RemoveCircleOutlineIcon
                          sx={{ fontSize: 30, cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <CameraAltRoundedIcon
                    fontSize="inherit"
                    sx={{ color: "#8ca3ba", fontSize: 30 }}
                  />
                )}
                <input
                  type={"file"}
                  id={id + "upload_img_product" + index}
                  accept="image/*"
                  onChange={(val) => onFileChange(val, index)}
                  style={{ display: "none" }}
                />
              </IconButton>
            );
          })
        : null}
      <ModalWork
        open={showImage ? true : false}
        close={() => setShowImage(null)}
        enableClose
        // width={"50%"}
      >
        <img
          src={showImage}
          alt="img"
          style={{
            width: "100%",
            height: "auto",
            padding: "0px 20px 0px 20px",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(
              showImage,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        />
      </ModalWork>
    </div>
  );
}
