// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RouteProvinceInfo_main_container__gHEwH {
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 0;
  gap: 40px;
}

.RouteProvinceInfo_field_container__7fXTk {
  min-width: 400px;
  min-height: 80vmin;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);
  padding: 20px 30px;
  margin: 10px 0px;
}

.RouteProvinceInfo_field_container__7fXTk .RouteProvinceInfo_header_wrapper__els51 {
  text-align: center;
  margin-bottom: 10px;
}

.RouteProvinceInfo_field_container__7fXTk .RouteProvinceInfo_header_wrapper__els51 h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 5px;
}

.RouteProvinceInfo_field_container__7fXTk .RouteProvinceInfo_header_wrapper__els51 h3 {
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  color: #8ca3ba;
  margin: 2px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/masterData/routeProvince/RouteProvinceInfo/RouteProvinceInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,SAAS;EACT,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,oDAAoD;EACpD,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".main_container {\n  display: flex;\n  flex-direction: row;\n  overflow: auto;\n  margin: 0;\n  gap: 40px;\n}\n\n.field_container {\n  min-width: 400px;\n  min-height: 80vmin;\n  border-radius: 20px;\n  background: #fff;\n  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);\n  padding: 20px 30px;\n  margin: 10px 0px;\n}\n\n.field_container .header_wrapper {\n  text-align: center;\n  margin-bottom: 10px;\n}\n\n.field_container .header_wrapper h2 {\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  margin: 0px 0px 5px;\n}\n\n.field_container .header_wrapper h3 {\n  font-size: 14px;\n  font-style: italic;\n  font-weight: 300;\n  line-height: normal;\n  color: #8ca3ba;\n  margin: 2px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `RouteProvinceInfo_main_container__gHEwH`,
	"field_container": `RouteProvinceInfo_field_container__7fXTk`,
	"header_wrapper": `RouteProvinceInfo_header_wrapper__els51`
};
export default ___CSS_LOADER_EXPORT___;
