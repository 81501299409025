import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FilledInput, IconButton } from "@mui/material";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import "./modalSelectMap.css";
import helper from "utils/helper";
import HelperMap from "utils/helperMap";
import FieldIconLeftRight from "components/field/FieldIconLeftRight";
import Line from "components/layout/line";
import InputNormal from "components/input/inputNormal";
import InputDisable from "components/input/inputDisable";
import InputSelect from "components/input/inputSelect";
import InputImageShow from "components/input/InputImageShow";
import ProductList from "components/input/productList";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import masterProvice from "utils/api/masterData/provice";
import Swal from "sweetalert2";
import InputUpDown from "components/input/inputUpDown";
// const google = window.google;
const isMobile = window.innerWidth <= 768 ? true : false;
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: isMobile ? 350 : 400,
  maxWidth: isMobile ? 350 : 400,
  flexDirection: "column",
  height: "80vh",
  //   height: 200,
  bgcolor: "background.paper",
  borderRadius: 5,
  color: "#8ca3ba",
  boxShadow: 24,
  // pt: 3,
  // px: 3,
  // pb: 3,
};
const ModalSelectService = (props) => {
  const {
    value,
    close,
    open,
    onChange,
    confirm,
    truck,
    is_detail = false,
    service_price = null,
    is_public = false,
    close_price = false,
  } = props;
  const [checkData, setCheckData] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showUpDown, setShowUpDown] = useState(false);
  const [province, setProvice] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);

  const [provinceMaster, setProviceMaster] = useState();
  const [districtMaster, setDistrictMaster] = useState();
  const [subDistrictMaster, setSubDistrictMaster] = useState();
  console.log("modal service", value);

  const onClose = () => {
    setCheckData(false);
    setShowDoc(false);
    setShowUpDown(false);
    close();
  };
  useEffect(() => {
    if (showDoc && province.length === 0) {
      getProvince();
    }
  }, [showDoc, province]);
  useEffect(() => {
    if (showDoc) {
      if (value.document_return_contact_name === "") {
        let tmp = {
          ...value,
          document_return_contact_name: "บริษัท วีมูฟแพลตฟอร์ม จำกัด",
          document_return_contact_phone: "0842893653",
          document_return_address: "172/1-2 ถ.ประดิพัทธ์",
          document_return_province: 10,
          document_return_district: 1014,
          document_return_subdistrict: 101402,
          document_return_postal_code: "10400",
        };
        onChange(tmp);
      }
    }
  }, [showDoc]);
  useEffect(() => {
    if (value.document_return_province !== "") {
      getDistrict();
    }
    if (value.document_return_district !== "") {
      getSubDistrict();
    }
  }, [value]);

  const getProvince = async () => {
    if (!provinceMaster) {
      let pv = await masterProvice.Province();
      setProviceMaster(pv.data.data.results);
      let mapPv = pv.data.data.results.map((ele) => {
        return { ...ele, item: ele.name, value: ele.id };
      });
      // console.log(mapPv);
      setProvice(mapPv);
    } else {
      let mapPv = provinceMaster.map((ele) => {
        return { ...ele, item: ele.name, value: ele.id };
      });
      // console.log(mapPv);
      setProvice(mapPv);
    }
  };
  const getDistrict = async () => {
    if (!districtMaster) {
      let dis = await masterProvice.District();
      setDistrictMaster(dis.data.data.results);
      let mapDis = dis.data.data.results
        .filter((ele) => ele.province_id === value.document_return_province)
        .map((ele) => {
          return {
            ...ele,
            item: ele.name,
            value: ele.id,
          };
        });
      // console.log(mapDis);
      setDistrict(mapDis);
    } else {
      let mapDis = districtMaster
        .filter((ele) => ele.province_id === value.document_return_province)
        .map((ele) => {
          return {
            ...ele,
            item: ele.name,
            value: ele.id,
          };
        });
      // console.log(mapDis);
      setDistrict(mapDis);
    }
  };
  const getSubDistrict = async () => {
    if (!subDistrictMaster) {
      let subdis = await masterProvice.SubDistrict();
      setSubDistrictMaster(subdis.data.data.results);

      let mapDis = subdis.data.data.results
        .filter((ele) => ele.district_id === value.document_return_district)
        .map((ele) => {
          return {
            ...ele,
            item: ele.name,
            value: ele.id,
          };
        });
      // console.log(mapDis);
      setSubDistrict(mapDis);
    } else {
      console.log(
        "value.document_return_subdistrict",
        value.document_return_subdistrict
      );
      let mapDis = subDistrictMaster
        .filter((ele) => ele.district_id === value.document_return_district)
        .map((ele) => {
          // console.log(ele);
          return {
            ...ele,
            item: ele.name,
            value: ele.id,
          };
        });
      // console.log(mapDis);

      setSubDistrict(mapDis);
    }
  };

  const serviceCost = () => {
    let cost = 0;
    if (is_detail) {
      // console.log(service_price);
      cost +=
        parseFloat(service_price?.shipper?.shipper_labor_load_fee) *
        value.labor_load_amount;
      cost +=
        parseFloat(service_price?.shipper?.shipper_labor_unload_fee) *
        value.labor_unload_amount;
      cost += parseFloat(service_price?.shipper?.shipper_document_return_fee);
    } else {
      if (value.has_document_return) {
        cost += 50;
      }
      if (value.has_labor) {
        // console.log(truck, "truck");
        cost += value.labor_load_amount * truck.labor_fee;
        cost += value.labor_unload_amount * truck.labor_fee;
      }
    }
    return cost;
  };

  const confirmCheck = () => {
    let missing = false;

    if (showDoc) {
      if (value.document_return_address === "") {
        missing = true;
      }
      if (value.document_return_district === "") {
        missing = true;
      }
      if (value.document_return_subdistrict === "") {
        missing = true;
      }
      if (value.document_return_province === "") {
        missing = true;
      }
      if (value.document_return_postal_code === "") {
        missing = true;
      }
      if (value.document_return_contact_name === "") {
        missing = true;
      }
      if (
        value.document_return_contact_phone === "" &&
        value.document_return_contact_phone.length < 9
      ) {
        missing = true;
      }
      if (missing) {
        setCheckData(true);
      }
    }

    return missing;
  };
  return (
    <div>
      <Modal open={open} className="modal-select-truck">
        <Box sx={{ ...style }}>
          <div
            style={{
              float: "right",
              cursor: "pointer",
              textAlign: "end",
              padding: 10,
            }}
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon sx={{ color: "#8ca3ba" }} />
          </div>
          {!showDoc && !showUpDown ? (
            <div
              style={{
                flex: 1,
                overflow: "scroll",
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
              }}
            >
              <div style={{ textAlign: "center" }}>บริการเสริม</div>
              <Line width={3} opacity={0.4} />
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "10px 0px 10px 0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let tmp = {
                    ...value,
                    has_document_return: is_public
                      ? !value.has_document_return
                      : true,
                  };
                  onChange(tmp);
                  if (!is_public) {
                    setShowDoc(true);
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px 10px 5px 10px",
                  }}
                >
                  <img
                    src={
                      value && value.image
                        ? value.image
                        : require("../../assets/image/work/mail.png")
                    }
                    height={40}
                    alt={"mail"}
                  />
                </div>
                <div style={{ flex: 1, textAlignLast: "start" }}>
                  <div
                    style={{
                      fontWeight: 500,
                      color: value.has_document_return ? "black" : "",
                    }}
                  >
                    {"ต้องการส่งเอกสารนำกลับ"}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      color: value.has_document_return ? "black" : "",
                    }}
                  >
                    {value.has_document_return ? (
                      <div>
                        {value.has_document_return ? (
                          <div>
                            {value.document_return_contact_name +
                              " " +
                              value.document_return_contact_phone}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      "ระบุที่อยู่ในการส่งเอกสารนำกลับ"
                    )}
                  </div>
                </div>
                <div style={{ alignSelf: "center" }}>
                  {!is_public && (
                    <ArrowForwardIosIcon
                      sx={{ color: "#121f43", opacity: 0.4 }}
                    />
                  )}
                </div>
              </div>
              <Line />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "10px 0px 10px 0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let tmp = {
                    ...value,
                    has_labor: true,
                  };
                  onChange(tmp);
                  setShowUpDown(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px 10px 5px 10px",
                  }}
                >
                  <img
                    src={
                      value && value.image
                        ? value.image
                        : require("../../assets/image/work/box.png")
                    }
                    height={40}
                    alt={"mail"}
                  />
                </div>
                <div style={{ flex: 1, textAlignLast: "start" }}>
                  <div
                    style={{
                      fontWeight: 500,
                      color: value.has_labor ? "black" : "",
                    }}
                  >
                    {"คนงานยกสินค้าขึ้น-ลง"}
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      color: value.has_labor ? "black" : "",
                    }}
                  >
                    {value.has_labor ? (
                      <div>
                        {value.has_labor && value.labor_load_amount > 0 ? (
                          <div>
                            ต้องการคนงานยกสินค้าขึ้น {value.labor_load_amount}{" "}
                            คน
                          </div>
                        ) : null}
                        {value.has_labor && value.labor_unload_amount > 0 ? (
                          <div>
                            ต้องการคนงานยกสินค้าลง {value.labor_unload_amount}{" "}
                            คน
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      "ระบุจำนวนคนงานยกสินค้าชึ้น-ลง"
                    )}
                  </div>
                </div>
                <div style={{ alignSelf: "center" }}>
                  <ArrowForwardIosIcon
                    sx={{ color: "#121f43", opacity: 0.4 }}
                  />
                </div>
              </div>
              <Line />
            </div>
          ) : null}
          {showDoc ? (
            <div
              style={{
                flex: 1,
                overflow: "scroll",
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
              }}
            >
              <div style={{ textAlign: "center" }}>
                ที่อยู่ในการจัดส่งเอกสารนำกลับ
              </div>
              <Line width={3} opacity={0.4} />
              <div style={{ position: "relative" }}>
                <InputNormal
                  value={value.document_return_contact_name}
                  onChange={(val) => {
                    let tmp = {
                      ...value,
                      document_return_contact_name: val.target.value,
                    };

                    onChange(tmp);
                  }}
                  error={checkData && value.document_return_contact_name === ""}
                  label={"ชื่อผู้ติดต่อ*"}
                  disable={is_detail}
                />
                {checkData && value.document_return_contact_name === "" ? (
                  <div
                    style={{
                      color: "#ff316f",
                      position: "absolute",
                      fontSize: 12,
                    }}
                  >
                    กรุณากรอกข้อมูล
                  </div>
                ) : null}
              </div>
              <div style={{ position: "relative" }}>
                <InputNormal
                  value={value.document_return_contact_phone}
                  onChange={(val) => {
                    let tmp = {
                      ...value,
                      document_return_contact_phone: val.target.value,
                    };
                    onChange(tmp);
                  }}
                  extraType={"phone"}
                  label={"เบอร์โทรผู้ติดต่อ*"}
                  maxLength={10}
                  error={
                    checkData &&
                    value.document_return_contact_phone === "" &&
                    value.document_return_contact_phone.length < 9
                  }
                  disable={is_detail}
                />
                {checkData &&
                value.document_return_contact_phone === "" &&
                value.document_return_contact_phone.length < 9 ? (
                  <div
                    style={{
                      color: "#ff316f",
                      position: "absolute",
                      fontSize: 12,
                    }}
                  >
                    กรุณากรอกข้อมูล
                  </div>
                ) : null}
              </div>

              <div style={{ position: "relative" }}>
                <InputNormal
                  value={value.document_return_address}
                  onChange={(val) => {
                    let tmp = {
                      ...value,
                      document_return_address: val.target.value,
                    };
                    onChange(tmp);
                  }}
                  label={"ที่อยู่*"}
                  error={checkData && value.document_return_address === ""}
                  disable={is_detail}
                />
                {checkData && value.document_return_address === "" ? (
                  <div
                    style={{
                      color: "#ff316f",
                      position: "absolute",
                      fontSize: 12,
                    }}
                  >
                    กรุณากรอกข้อมูล
                  </div>
                ) : null}
              </div>
              <div style={{ position: "relative" }}>
                <InputSelect
                  label={"จังหวัด"}
                  options={province}
                  // value={}
                  onChange={(val) => {
                    let tmp = {
                      ...value,
                      document_return_province: val.target.value,
                      document_return_district: "",
                      document_return_subdistrict: "",
                      document_return_postal_code: "",
                    };
                    onChange(tmp);
                  }}
                  error={checkData && value.document_return_province === ""}
                  value={value.document_return_province}
                  disable={is_detail}
                />
                {checkData && value.document_return_province === "" ? (
                  <div
                    style={{
                      color: "#ff316f",
                      position: "absolute",
                      fontSize: 12,
                    }}
                  >
                    กรุณากรอกข้อมูล
                  </div>
                ) : null}
              </div>

              {value.document_return_province !== "" ? (
                <div style={{ position: "relative" }}>
                  <InputSelect
                    label={"เขต/อำเภอ"}
                    options={district}
                    onChange={(val) => {
                      let tmp = {
                        ...value,
                        document_return_district: val.target.value,
                        document_return_subdistrict: "",
                        document_return_postal_code: "",
                      };
                      onChange(tmp);
                    }}
                    value={value.document_return_district}
                    error={checkData && value.document_return_district === ""}
                    disable={is_detail}
                  />
                  {checkData && value.document_return_district === "" ? (
                    <div
                      style={{
                        color: "#ff316f",
                        position: "absolute",
                        fontSize: 12,
                      }}
                    >
                      กรุณากรอกข้อมูล
                    </div>
                  ) : null}
                </div>
              ) : null}
              {value.document_return_district !== "" ? (
                <div style={{ position: "relative" }}>
                  <InputSelect
                    label={"แขวง/ตำบล"}
                    options={subDistrict}
                    onChange={(val) => {
                      let postal_code = subDistrict.filter(
                        (ele) => ele.id === val.target.value
                      )[0].postal_code;
                      let tmp = {
                        ...value,
                        document_return_subdistrict: val.target.value,
                        document_return_postal_code: postal_code,
                      };
                      onChange(tmp);
                    }}
                    value={value.document_return_subdistrict}
                    error={
                      checkData && value.document_return_subdistrict === ""
                    }
                    disable={is_detail}
                  />
                  {checkData && value.document_return_subdistrict === "" ? (
                    <div
                      style={{
                        color: "#ff316f",
                        position: "absolute",
                        fontSize: 12,
                      }}
                    >
                      กรุณากรอกข้อมูล
                    </div>
                  ) : null}
                </div>
              ) : null}
              {value.document_return_subdistrict !== "" ? (
                <InputDisable
                  value={value.document_return_postal_code}
                  label={"รหัสไปรษณีย์"}
                  subFirst={"ระบบจะดึงจากฐานข้อมูล"}
                />
              ) : null}
            </div>
          ) : null}
          {showUpDown ? (
            <div
              style={{
                flex: 1,
                overflow: "scroll",
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 20,
              }}
            >
              <div style={{ textAlign: "center" }}>คนงานยกสินค้าขึ้น-ลง</div>
              <Line width={3} opacity={0.4} />
              <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: 500 }}>
                    จำนวนคนงานยกสินค้าขึ้นรถ
                  </div>
                  <label style={{ fontSize: 12 }}>
                    (ค่าเริ่มต้น: ระบบจะดึงจากฐานข้อมูล)
                  </label>
                </div>
                <div>
                  <InputUpDown
                    value={value.labor_load_amount}
                    onChange={(val) => {
                      let tmp = {
                        ...value,
                        labor_load_amount: val,
                        // has_labor:
                        //   val > 0 && value.labor_unload_amount > 0
                        //     ? true
                        //     : false,
                      };

                      onChange(tmp);
                    }}
                    max={truck?.max_labor_load ?? 0}
                    min={0}
                    disable={is_detail}
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <div style={{ flex: 1 }}>
                  <div style={{ fontWeight: 500 }}>จำนวนคนงานยกสินค้าลงรถ</div>
                  <label style={{ fontSize: 12 }}>
                    (ค่าเริ่มต้น: ระบบจะดึงจากฐานข้อมูล)
                  </label>
                </div>
                <div>
                  <InputUpDown
                    value={value.labor_unload_amount}
                    onChange={(val) => {
                      let tmp = {
                        ...value,
                        labor_unload_amount: val,
                        // has_labor:
                        //   val > 0 && value.labor_unload_amount > 0
                        //     ? true
                        //     : false,
                      };

                      onChange(tmp);
                    }}
                    max={truck?.max_labor_unload ?? 0}
                    min={0}
                    disable={is_detail}
                  />
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div style={{ textAlign: "center", fontWeight: 500 }}>
                  เงื่อนไขคนงานยกสินค้าขึ้น-ลง
                </div>
                <table>
                  <tr>
                    <td style={{ verticalAlign: "top" }}>1</td>
                    <td style={{ fontSize: 12 }}>
                      พนักงานยกสินค้ามีหน้าที่ในการขึ้นสินค้าและจัดเรียงสินค้าบนรถบรรทุก,
                      ลงสินค้าจากรถบรรทุกและจัดวางสินค้าเพื่อส่งมอบ
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top" }}>2</td>
                    <td style={{ fontSize: 12 }}>
                      จำกัดน้ำหนักสินค้าต่อชิ้นไม่เกิน 30 กิโลกรัม
                      หรือขนาดสินค้าไม่เกิน 50x50x50 เซนติเมตรต่อการยก 1 คน
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top" }}>3</td>
                    <td style={{ fontSize: 12 }}>
                      จำกัดระยะทางในการขนย้ายสินค้าจากรถบรรทุกไปยังจุดขึ้น-จุดลงสินค้าไม่เกิน
                      30 เมตร ภายในชั้นเดียวเท่านั้น หากขึ้นหรือลงมากกว่า 1
                      ชั้นโดยไม่มีลิฟต์ขนถ่ายสินค้า ขอสงวนสิทธิ์ในการให้บริการ{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top" }}>4</td>
                    <td style={{ fontSize: 12 }}>
                      กรุณาเลือกจำนวนพนักงานขึ้น-ลงสินค้าให้เหมาะสมตามปริมาณ
                      ขนาด น้ำหนักและสภาพแวดล้อมของการขึ้น-ลงสินค้า
                      มิฉะนั้นผู้ขนส่งมีสิทธิจะปฏิเสธหรือคิดค่าบริการเพิ่มเติม
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top" }}>5</td>
                    <td style={{ fontSize: 12 }}>
                      กรณีใช้บริการเกินระยะเวลาขึ้นสินค้าหรือลงสินค้าที่กำหนดไว้
                      ผู้ขนส่งมีสิทธิ์คิดอัตราค่าบริการเพิ่ม
                      โดยอัตราขั้นต่ำชั่วโมงละ 100 บาทต่อคน
                      (ผู้ขนส่งเป็นคนกำหนดราคาส่วนเพิ่ม)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "top" }}>6</td>
                    <td style={{ fontSize: 12 }}>
                      หากต้องการใช้การบริการนอกเหนือจากการบริการปกติที่กำหนด
                      ลูกค้ากรุณาติดต่อผู้ขนส่งโดยตรง
                      หากท่านกดยืนยันเพื่อดำเนินการต่อไป
                      ถือว่ายอมรับเงื่อนไขที่กำหนดไว้ทุกประการ
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          ) : null}
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              padding: 20,
              borderRadius: 20,
              textAlign: "center",
            }}
          >
            {!close_price && <div>รวมค่าบริการเสริม {serviceCost()} บาท</div>}
            {is_detail ? (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      if (showDoc) {
                        setShowDoc(false);
                      } else if (showUpDown) {
                        setShowUpDown(false);
                      } else {
                        confirm();
                      }
                    }}
                  >
                    {showUpDown || showDoc ? "ย้อนกลับ" : "ปิด"}
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outline"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      color: "#8ca3ba",
                      borderColor: "#8ca3ba",
                      height: 50,
                    }}
                    color="secondary"
                    onClick={() => {
                      onClose();
                      if (showDoc) {
                        let tmp = {
                          ...value,
                          has_document_return: false,
                        };
                        onChange(tmp);
                      } else {
                        let tmp = {
                          ...value,
                          labor_unload_amount: 0,
                          labor_load_amount: 0,
                          has_labor: false,
                        };
                        onChange(tmp);
                      }
                    }}
                  >
                    {showDoc ? "ไม่ส่งเอกสารกลับ" : "ย้อนกลับ"}
                  </Button>
                </div>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{ borderRadius: "10px", marginTop: 20 }}
                    sx={{ height: 50 }}
                    color="secondary"
                    onClick={() => {
                      if (!confirmCheck()) {
                        if (showDoc) {
                          setShowDoc(false);
                        } else if (showUpDown) {
                          if (
                            value.labor_load_amount === 0 &&
                            value.labor_unload_amount === 0
                          ) {
                            let tmp = { ...value };
                            tmp.has_labor = false;
                            onChange(tmp);
                          }
                          setShowUpDown(false);
                        } else {
                          confirm();
                        }
                      }
                    }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSelectService;
