import SubMenu from "components/layout/SubMenu.js";
import SubMenuCustom from "components/layout/SubMenu.js/SubMenuCustom";
import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import "./PostWork.css";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useForm, Controller } from "react-hook-form";
import InputDisable from "components/input/inputDisable";
import ButtonSwitch from "components/input/buttonSwitch";
import SelectLocation from "components/work/selectLocation";
import Line from "components/layout/line";
import SelectTruck from "components/work/selectTruck";
import SelectProduct from "components/work/selectProduct";
import SelectService from "components/work/selectService";
import SelectDateTime from "components/work/selectDateTime";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import SelectBilling from "components/work/selectBilling";
import SelectRemark from "components/work/selectRemark";
import SelectPostName from "components/work/selectPostName";
import SelectFavCarrier from "components/work/selectFavCarrier";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InputNormal from "components/input/inputNormal";
import API from "../../utils/api/work/work";
import Swal from "sweetalert2";
import InputSelect from "components/input/inputSelect";
import HelperMap from "utils/helperMap";
import { getTruckType } from "utils/api/masterData/truckType";
import masterProduct from "utils/api/masterData/productType";
import masterProvice from "utils/api/masterData/provice";
import ModalWork from "components/work/modalWork";
import helper from "utils/helper";
import SelectPrice from "components/work/selectPrice";
import MapWithCall from "components/map/MapWithCall";
import {
  getAllFuelRate,
  getFuelRate,
  getFuelRateFormPTT,
} from "utils/api/masterData/fuelRate";
import LoadingBackground from "components/loading/LoadingBackground";
import InputLongdoAutoComplete from "components/input/InputLongdoAutoComplete";
import { useParams } from "react-router-dom";
import { getProfileReceiptName } from "utils/api/profile/profileReceiptName";
const api = API.create();
const menus = [
  {
    label: "ประกาศงานรายการเดียว",
    path: "/post-work",
    active: true,
  },
  {
    label: "ประกาศงานแบบใช้ไฟล์นำเข้า",
    path: "/import-work",
  },
  {
    label: "จัดเส้นทาง",
    path: "/route-optimize",
  },
  {
    label: "จัดการงานประจำ",
    path: "/work-regular",
  },
];

const breadcrumbs = [
  {
    label: "ประกาศงาน",
    path: null,
  },
  {
    label: "รายการเดียว",
    path: null,
  },
];

export default function PostWork() {
  const [buttonWorkType, setButtonWorkType] = useState([
    {
      icon: require("../../assets/image/work/ftl.png"),
      title: "บริการเหมาคัน",
      subtitle: "ส่งของเยอะเหมาทั้งคัน",
      active: true,
    },
    {
      icon: require("../../assets/image/work/ltl.png"),
      title: "บริการฝากส่ง",
      subtitle: "ส่งของชิ้นใหญ่จำนวนน้อย",
      active: false,
    },
  ]);
  const [projectList, setProjectList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [truckType, setTruckType] = useState();
  const [productType, setProductType] = useState();
  const [loc, setLoc] = useState();

  const [showShipperPrice, setShowShipperPrice] = useState(false);
  const [workPriceSet, setWorkPriceSet] = useState(true);
  const [workPrice, setWorkPrice] = useState();
  const [loading, setLoading] = useState(false);
  const [showCarrierPrice, setShowCarrierPrice] = useState(false);
  const [call, setCall] = useState();
  const [province, setProvice] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subDistrict, setSubDistrict] = useState([]);
  const [generalPrice, setGeneralPrice] = useState();
  const [predictPrice, setPredictPrice] = useState();
  const [templatePrice, setTemplatPrice] = useState();
  const [carrierInProjectPrice, setCarrierInProjectPrice] = useState();

  const { id } = useParams();
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      shipper_code: "",
      location: [
        {
          type: 0,
          name: "",
          lat: "",
          long: "",
          address: "",
          district: "",
          subdistrict: "",
          province: "",
          postal_code: "",
          contact_name: "",
          contact_phone: "",
          place_name: "",
          place_type_id: 0,
          remark: "",
          document: "",
          document_file: [],
          product: [
            {
              name: "",
              product_id: "",
              product_name: "",
              product_size: "",
              product_width: "",
              product_length: "",
              product_height: "",
              product_volume: "",
              product_weight: "",
              product_count_type: "",
              product_amount: "",
              product_image: [null, null, null, null, null],
              product_image_per_route: [],
            },
          ],
        },
        {
          type: 1,
          name: "",
          lat: "",
          long: "",
          address: "",
          district: "",
          subdistrict: "",
          province: "",
          postal_code: "",
          contact_name: "",
          contact_phone: "",
          place_name: "",
          place_type_id: 0,
          remark: "",
          document: "",
          document_file: [],
          product: [
            {
              name: "",
              product_id: "",
              product_name: "",
              product_size: "",
              product_width: "",
              product_length: "",
              product_height: "",
              product_volume: "",
              product_weight: "",
              product_count_type: "",
              product_amount: "",
              product_image: [null, null, null, null, null],
              product_image_per_route: [],
            },
          ],
        },
      ],
      truck: null,
      // product: [
      //   {
      //     product_id: "",
      //     product_name: "",
      //     product_size: "",
      //     product_width: "",
      //     product_length: "",
      //     product_height: "",
      //     product_volume: "",
      //     product_weight: "",
      //     product_count_type: "",
      //     product_amount: "",
      //     product_image: [null,null,null,null,null],
      //     // product_image_per_route: [],
      //   },
      // ],
      service: {
        has_document_return: false,
        document_return_code: "",
        document_return_address: "",
        document_return_district: "",
        document_return_subdistrict: "",
        document_return_province: "",
        document_return_postal_code: "",
        document_return_contact_name: "",
        document_return_contact_phone: "",
        has_labor: false,
        labor_load_amount: 0,
        labor_unload_amount: 0,
      },
      price: {
        shipper: {
          shipper_document_return_fee: null, // ค่าเอกสารนำกลับ
          shipper_labor_load_fee: null, // ค่าพนักงานขึ้นสินค้า
          shipper_labor_unload_fee: null, // ค่าพนักงานลงสินค้า
          shipper_add_drop_point_fee: null, // ค่าเพิ่มจุดส่ง
          shipper_transport_fee: null, // ค่าจัดส่ง
          shipper_freight_before_tax: null, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
          shipper_freight_tax: null, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
          shipper_freight_net: null, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
          shipper_has_deposit: null, // มีเงินมัดจำ
          shipper_deposit_amount: null, // จำนวนเงินมัดจำ
          commission: null, //ค่านายหน้า
          shipper_channel_price_type: 0,
        },
        carrier: {
          carrier_freight: null, // ค่าจ้างผู้ขนส่ง
          carrier_saas_fee: null, // ค่าธรรมเนียมระบบ
          carrier_freight_before_tax: null, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
          carrier_freight_tax: null, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
          carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
          carrier_insurance_per_trip: null, // ค่าประกันภัยสินค้ารายเที่ยว
          carrier_transfer_fee: null, // ค่าธรรมเนียมการโอนเงิน
          carrier_freight_net: null, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
          carrier_channel_price_type: 0,
        },
        difference: {
          price_difference: null,
          price_difference_percentage: null,
        },
      },
    },
    deposit: {
      has_deposit: 0,
      deposit_amount: "",
    },
    // duration: "",
  });
  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);
  const error = {
    shipper_code: "รหัสผู้ใช้ไม่ถูกต้อง",
  };
  const watchProjectId = watch("project_id");
  const watchShipperId = watch("shipper_id");
  const watchTruck = watch("truck");
  const watchService = watch("service");
  const watchLocation = watch("location");
  const watchRemark = watch("remark");
  const watchPostName = watch("post_name");
  const watchFavCarrier = watch("fav_carrier");
  const watchDeposit = watch("deposit");
  const watchShipper = watch("shipper_code");
  const watchFuelRate = watch("fuel_rate");
  const watchDistance = watch("distance");
  const watchRouteId = watch("route_id");
  const watchPrice = watch("price");
  const watchBilling = watch("billing");
  // const watchDuration = watch("duration");

  // console.log("watchDistance", watchDistance);
  // console.log("watchTruck", watchTruck);

  useEffect(() => {
    if (!truckType) {
      getTruck();
    }
    if (!productType) {
      getProduct();
    }
    getProvince();
    getDistrict();
    getSubDistrict();
    getFuel();
    if (id) {
      console.log("work_id", id);
    }
  }, []);

  useEffect(() => {
    if (province && district && subDistrict && id) {
      getWorkDetail();
    }
  }, [province, district, subDistrict]);
  useEffect(() => {
    if (id && watchShipper) {
      getProject();
    }
  }, [watchShipper]);
  const getDetail = async (id) => {
    let rec = await getProfileReceiptName(id);
    // console.log("getDetail", rec);
    // setReceipt(rec.data.data.results);
    setValue("billing", rec.data.data.results);
  };
  const getWorkDetail = () => {
    setLoading(true);
    let body = {
      work_id: id,
    };
    api
      .getWorkDetail(body)
      .then(async (response) => {
        let work_detail = response.data.results;
        setValue("shipper_code", work_detail.shipper.user_info.user_code);
        setValue("fuel_rate", work_detail.fuel_rate);
        let loc = await helper.workDetailProduct(
          response.data.results.workLocations
        );
        setValue("location", loc);
        let latlong = loc.map((e) => {
          return { lat: e.lat, long: e.long, type: e.type };
        });
        setLoc(latlong);
        setValue("distance", {
          allInterval: work_detail.total_duration,
          allDistance: work_detail.total_distance,
          orderDistance: work_detail.total_distance,
          orderInterval: helper.secToText(work_detail.total_duration),
        });
        // setValue("duration", work_detail.total_duration);
        let document_return_province = "";
        let document_return_subdistrict = "";
        let document_return_district = "";
        if (work_detail.document_return_province) {
          document_return_province = province.filter(
            (e) => e.name === work_detail.document_return_province
          );
        }
        if (work_detail.document_return_subdistrict) {
          document_return_subdistrict = subDistrict.filter(
            (e) => e.name === work_detail.document_return_subdistrict
          );
        }
        console.log("district", district);
        if (work_detail.document_return_district) {
          document_return_district = district.filter(
            (e) => e.name === work_detail.document_return_district
          );
        }
        if (work_detail.service_id === 2) {
          let tmp = [...buttonWorkType];
          tmp[0].active = false;
          tmp[1].active = true;
          setButtonWorkType(tmp);
        }
        setValue("service", {
          has_document_return: work_detail.has_document_return,
          document_return_code: work_detail.document_return_code ?? "",
          document_return_address: work_detail.document_return_address ?? "",
          document_return_district:
            document_return_district.length > 0
              ? document_return_district[0].id
              : "",
          document_return_subdistrict:
            document_return_subdistrict.length > 0
              ? document_return_subdistrict[0].id
              : "",
          document_return_province:
            document_return_province.length > 0
              ? document_return_province[0].id
              : "",
          document_return_postal_code:
            work_detail.document_return_postal_code ?? "",
          document_return_contact_name:
            work_detail.document_return_contact_name ?? "",
          document_return_contact_phone:
            work_detail.document_return_contact_phone ?? "",
          has_labor: work_detail.has_labor,
          labor_load_amount: work_detail.labor_load_amount ?? 0,
          labor_unload_amount: work_detail.labor_unload_amount ?? 0,
        });
        work_detail.truck_type.image =
          helper.pathImage() + work_detail.truck_type.image;
        console.log(" work_detail.truck_type", work_detail.truck_type);
        setValue("truck", work_detail.truck_type);
        setValue("post_name", work_detail.work_name);
        setValue("remark", work_detail.work_remark);
        await getDetail(work_detail.profile_receipt_name_id);
        const price_difference =
          Math.ceil(
            (work_detail.shipper_freight_before_tax -
              work_detail.carrier_freight_before_tax) *
              100
          ) / 100;
        const price_difference_percentage =
          Math.ceil(
            (price_difference / work_detail.shipper_freight_before_tax) *
              100 *
              100
          ) / 100;

        setTimeout(() => {
          setValue("price", {
            shipper: {
              shipper_document_return_fee:
                work_detail.shipper_document_return_fee, // ค่าเอกสารนำกลับ
              shipper_labor_load_fee: work_detail.shipper_labor_load_fee, // ค่าพนักงานขึ้นสินค้า
              shipper_labor_unload_fee: work_detail.shipper_labor_unload_fee, // ค่าพนักงานลงสินค้า
              shipper_add_drop_point_fee:
                work_detail.shipper_add_drop_point_fee, // ค่าเพิ่มจุดส่ง
              shipper_transport_fee: work_detail.shipper_transport_fee, // ค่าจัดส่ง
              shipper_freight_before_tax:
                work_detail.shipper_freight_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
              shipper_freight_tax: work_detail.shipper_freight_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
              shipper_freight_net: work_detail.shipper_freight_net, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
              shipper_has_deposit: work_detail.shipper_has_deposit ? 1 : 0, // มีเงินมัดจำ
              shipper_deposit_amount: work_detail.shipper_deposit_amount, // จำนวนเงินมัดจำ
              commission: work_detail.commission, //ค่านายหน้า
              is_general:
                work_detail.shipper_channel_price_type === 0 ? true : false,
              shipper_channel_price_type:
                work_detail.shipper_channel_price_type,
            },
            carrier: {
              carrier_freight: work_detail.carrier_freight, // ค่าจ้างผู้ขนส่ง
              carrier_saas_fee: work_detail.carrier_saas_fee, // ค่าธรรมเนียมระบบ
              carrier_freight_before_tax:
                work_detail.carrier_freight_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
              carrier_freight_tax: work_detail.carrier_freight_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
              carrier_penalty: work_detail.carrier_penalty, // ค่าปรับกรณีทำผิดข้อตกลง
              carrier_insurance_per_trip:
                work_detail.carrier_insurance_per_trip, // ค่าประกันภัยสินค้ารายเที่ยว
              carrier_transfer_fee: work_detail.carrier_transfer_fee, // ค่าธรรมเนียมการโอนเงิน
              carrier_freight_net: work_detail.carrier_freight_net, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
              is_general:
                work_detail.carrier_channel_price_type === 0 ? true : false,
              carrier_channel_price_type:
                work_detail.carrier_channel_price_type,
            },
            difference: {
              price_difference: price_difference,
              price_difference_percentage: price_difference_percentage,
            },
          });
        }, 2000);
        // setWorkPriceSet(false);
        // setValue('price', )
        console.log("sss", response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        console.log(e);
      });
  };
  const getFuel = async () => {
    let fuel;
    await getFuelRateFormPTT().then((res) => {
      console.log(res.data.data.results);
      const data = res.data.data.results;
      const fuel_info = data.filter((ele) => ele.product === "Diesel");

      fuel = parseFloat(fuel_info[0]?.price).toFixed(2);
    });
    console.log("fuel", fuel);
    // let fuel = await getAllFuelRate({ page: 1, per_page: 1 });
    // console.log("fuel", fuel);
    if (fuel) {
      setValue("fuel_rate", String(fuel));
    }
  };
  const getProduct = async () => {
    let product = await masterProduct.ProductType({
      sort_field: "order_number",
      sort_order: 1,
      active: true,
    });
    // console.log(product);
    setProductType(product.data.data.results);
  };
  //WM60094428

  const initMap = () => {
    console.log("initMap!!");
  };
  const getTruck = async () => {
    let truck = await getTruckType({
      sort_field: "order_number",
      sort_order: 1,
      active: true,
    });
    console.log("truck", truck);
    let truck_default = truck.data.data.results.filter(
      (e) => e.order_number === 1
    );
    console.log("truck_default", truck_default[0]);
    if (truck_default.length > 0) {
      setValue("truck", truck_default[0]);
    }
    setTruckType(truck.data.data.results);
  };
  const getProject = async () => {
    // let body = { profile_id: watchShipper, page: 1, per_page: 9999 };
    await api
      .getUser({ shipper_code: watchShipper })
      .then(async (response) => {
        console.log("response,", response);
        setValue("profile_name", response.data.data.company_name);
        setValue("shipper_id", response.data.data.id);
        await api
          .project({
            profile_id: response.data.data.id,
            page: 1,
            active: true,
            per_page: 9999,
          })
          .then((res) => {
            console.log("response", res);
            let option = res.data.data.map((ele, index) => {
              return { ...ele, item: ele.name, value: ele.id };
            });
            if (option.length > 0) {
              setValue("project_id", option[0].id);
              setValue("project_code", option[0].project_code);
            }
            setProjectList(option);
          })
          .catch((error) => {
            setProjectList([]);
          });
      })
      .catch((error) => {
        // if (error.response.data.code === 4601) {
        Swal.fire({
          timer: 2000,
          title: "error: " + error.response.data.code,
          text:
            error.response.data.description?.message ??
            error.response.data.description ??
            "",
          icon: "error",
          showConfirmButton: false,
        });
        // } else {
        //   Swal.fire({
        //     timer: 2000,
        //     title: "error: " + error.response.data.code,
        //     text: "ไม่พบรหัสผู้ใช้งาน",
        //     icon: "error",
        //     showConfirmButton: false,
        //   });
        // }
        setValue("profile_name", "");
        setValue("shipper_id", "");
        setProjectList([]);
      });
  };
  useEffect(() => {
    console.log("watchFuelRate", watchFuelRate);
    console.log("watchProjectId", watchProjectId);
    if (watchFuelRate !== "" && watchProjectId && watchProjectId !== "") {
      getRoute();
    }
  }, [watchFuelRate, watchProjectId]);
  useEffect(() => {
    if (watchRouteId && watchRouteId !== "") {
      // console.log(watchRouteId);
      let select = routeList.filter((e) => e.id === watchRouteId)[0];
      console.log("select", select);
      if (select) {
        setSelectRoute(select);

        getCarrierInProject(watchRouteId);
      }
    }
  }, [watchRouteId]);

  const setSelectRoute = async (select) => {
    let loc = await helper.workDetailProduct(
      select.templateWorkLocations,
      true
    );
    let truck = truckType.filter((e) => e.id === select.truck_type_id)[0];
    console.log("loc", loc);
    console.log(
      "select.templateWork.has_document_return",
      select.has_document_return
    );
    if (select.has_document_return) {
      let dis = district.filter((d) =>
        d.name.includes(select.document_return_district)
      )[0];
      let subdis = subDistrict.filter((d) =>
        d.name.includes(select.document_return_subdistrict)
      )[0];
      let prov = province.filter((d) =>
        d.name.includes(select.document_return_province)
      )[0];
      console.log("subdis", subdis);
      let service = {
        has_document_return: select.has_document_return,
        document_return_code: select.document_return_code,
        document_return_address: select.document_return_address,
        document_return_district: dis.id,
        document_return_subdistrict: subdis.id,
        document_return_province: prov.id,
        document_return_postal_code: subdis.postal_code,
        document_return_contact_name: select.document_return_contact_name,
        document_return_contact_phone: select.document_return_contact_phone,
        has_labor: select.has_labor === 1 ? true : false,
        labor_load_amount: select.labor_load_amount,
        labor_unload_amount: select.labor_unload_amount,
      };
      setValue("service", service);
      console.log("service", service);
    }
    setValue("distance", {
      allInterval: select.total_duration,
      allDistance: select.total_distance,
      orderDistance: select.total_distance,
      orderInterval: helper.secToText(select.total_duration),
    });
    // setValue("duration", select.total_duration);
    // setValue("price", { ...watchPrice, price });
    setValue("location", loc);
    setValue("post_name", select.work_name);
    setValue("remark", select.work_remark);
    setValue("truck", truck);
    let latlong = loc.map((e) => {
      return { lat: e.lat, long: e.long, type: e.type };
    });
    setLoc(latlong);

    const price_difference =
      Math.ceil(
        (select.shipper_freight_before_tax - select.carrier_freight) * 100
      ) / 100;
    const price_difference_percentage =
      Math.ceil(
        (price_difference / select.shipper_freight_before_tax) * 100 * 100
      ) / 100;

    const truck_saas =
      select.shipper_freight_before_tax - select.carrier_freight;
    const carrier_price_before_tax =
      select.shipper_freight_before_tax - truck_saas;
    const carrier_tax = Math.ceil(carrier_price_before_tax * 0.01 * 100) / 100;
    const carrier_price_after_tax =
      Math.ceil((carrier_price_before_tax - carrier_tax) * 100) / 100;
    setTemplatPrice({
      shipper: {
        shipper_document_return_fee: select.shipper_document_return_fee, // ค่าเอกสารนำกลับ
        shipper_labor_load_fee: select.shipper_labor_load_fee, // ค่าพนักงานขึ้นสินค้า
        shipper_labor_unload_fee: select.shipper_labor_unload_fee, // ค่าพนักงานลงสินค้า
        shipper_add_drop_point_fee: select.shipper_add_drop_point_fee, // ค่าเพิ่มจุดส่ง
        shipper_transport_fee: select.shipper_transport_fee, // ค่าจัดส่ง
        shipper_freight_before_tax: select.shipper_freight_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
        shipper_freight_tax: select.shipper_freight_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
        shipper_freight_net: select.shipper_freight_net, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
        shipper_has_deposit: select.shipper_has_deposit ? 1 : 0, // มีเงินมัดจำ
        shipper_deposit_amount: select.shipper_deposit_amount, // จำนวนเงินมัดจำ
        commission: select.commission, //ค่านายหน้า
        is_general: select.shipper_channel_price_type === 0 ? true : false,
        shipper_channel_price_type: 2,
      },
      carrier: {
        carrier_freight: select.carrier_freight, // ค่าจ้างผู้ขนส่ง
        carrier_saas_fee: truck_saas, // ค่าธรรมเนียมระบบ
        carrier_freight_before_tax: carrier_price_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
        carrier_freight_tax: carrier_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
        carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
        carrier_insurance_per_trip: select.carrier_insurance_per_trip, // ค่าประกันภัยสินค้ารายเที่ยว
        carrier_transfer_fee: select.carrier_transfer_fee ?? 0, // ค่าธรรมเนียมการโอนเงิน
        carrier_freight_net: carrier_price_after_tax, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
        is_general: select.carrier_channel_price_type === 0 ? true : false,
        carrier_channel_price_type: 2,
      },
      difference: {
        price_difference: price_difference,
        price_difference_percentage: price_difference_percentage,
      },
    });

    // setTimeout(() => {
    //   setValue("price", {
    //     shipper: {
    //       shipper_document_return_fee: select.shipper_document_return_fee, // ค่าเอกสารนำกลับ
    //       shipper_labor_load_fee: select.shipper_labor_load_fee, // ค่าพนักงานขึ้นสินค้า
    //       shipper_labor_unload_fee: select.shipper_labor_unload_fee, // ค่าพนักงานลงสินค้า
    //       shipper_add_drop_point_fee: select.shipper_add_drop_point_fee, // ค่าเพิ่มจุดส่ง
    //       shipper_transport_fee: select.shipper_transport_fee, // ค่าจัดส่ง
    //       shipper_freight_before_tax: select.shipper_freight_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
    //       shipper_freight_tax: select.shipper_freight_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
    //       shipper_freight_net: select.shipper_freight_net, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
    //       shipper_has_deposit: select.shipper_has_deposit ? 1 : 0, // มีเงินมัดจำ
    //       shipper_deposit_amount: select.shipper_deposit_amount, // จำนวนเงินมัดจำ
    //       commission: select.commission, //ค่านายหน้า
    //       is_general: select.shipper_channel_price_type === 0 ? true : false,
    //     },
    //     carrier: {
    //       carrier_freight: select.carrier_freight, // ค่าจ้างผู้ขนส่ง
    //       carrier_saas_fee: truck_saas, // ค่าธรรมเนียมระบบ
    //       carrier_freight_before_tax: carrier_price_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
    //       carrier_freight_tax: carrier_tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
    //       carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
    //       carrier_insurance_per_trip: select.carrier_insurance_per_trip, // ค่าประกันภัยสินค้ารายเที่ยว
    //       carrier_transfer_fee: select.carrier_transfer_fee, // ค่าธรรมเนียมการโอนเงิน
    //       carrier_freight_net: carrier_price_after_tax, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
    //       is_general: select.carrier_channel_price_type === 0 ? true : false,
    //     },
    //     difference: {
    //       price_difference: price_difference,
    //       price_difference_percentage: price_difference_percentage,
    //     },
    //   });
    // }, 2000);
  };
  const getCarrierInProject = async (id) => {
    console.log("getCarrierInProject", id);
    await api
      .getTemplateCarrierFreight({ template_work_id: id })
      .then((response) => {
        console.log("getTemplateCarrierFreight", response);
        setCarrierInProjectPrice(response.data.results);
      })
      .catch((error) => {
        setCarrierInProjectPrice(null);
      });
  };
  const getRoute = async () => {
    await api
      .getRoute({ fuel: watchFuelRate, project_id: watchProjectId })
      .then((res) => {
        let option = res.data.results.map((ele, index) => {
          return {
            ...ele,
            item: ele?.route_name,
            value: ele?.id,
          };
        });
        console.log("option,", option);
        setRouteList(option);
      })
      .catch((error) => {
        setRouteList([]);
      });
  };

  useEffect(() => {
    if (loc && loc.length > 0) {
      setRoute();
    }
  }, [loc]);

  const setRoute = async () => {
    let has_marker = false;
    let list = [];
    for (const { ele, index } of loc.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.lat !== "" && ele.long !== "") {
        console.log(ele.lat, ele.long);
        const dot = await new window.longdo.Marker(
          { lat: ele.lat, lon: ele.long },
          {
            draggable: false,
            clickable: true,
            weight: window.longdo.OverlayWeight.Top,
            icon: {
              html:
                '<div class=""><img src="' +
                (ele.type === 0
                  ? require("../../assets/image/work/pickup_pin.png")
                  : require("../../assets/image/work/send_pin.png")) +
                '" style="height: 30px"/></div>',
            },
          }
        );
        list.push(dot);
        console.log("dot", dot);
        has_marker = true;
      }
    }
    // watchLocation.forEach((ele, index) => {});
    console.log("has_marker", has_marker);
    console.log("list", list);
    let dis = await HelperMap.getDistance(loc);
    console.log("dis", dis);
    // if (dis?.allDistance) {
    //   let interval = await HelperMap.ApproximateTime(
    //     dis.allDistance.toFixed(2),
    //     watchTruck?.average_speed_per_hour
    //   );

    //   setValue("duration", interval);
    // }
    setValue("distance", dis);
    setCall(list);

    // if (has_marker) {
    //   // await map.Overlays.clear();

    //   // await map.Route.clear();
    //   // for (const { e, index } of list.map((e, index) => ({
    //   //   e,
    //   //   index,
    //   // }))) {
    //   //   await map.Route.add(e);
    //   //   await map.Overlays.add(e);
    //   // }
    //   for (const { ele, index } of list.map((ele, index) => ({
    //     ele,
    //     index,
    //   }))) {
    //     await map.Overlays.add(ele);

    //     await map.Route.add(ele);
    //   }
    //   // list.forEach((e) => {

    //   // });

    //   await map.Route.line("road", { lineColor: "#121f43" });

    //   await map.Route.mode(longdo.RouteMode.Cost);
    //   await map.Route.enableRoute(longdo.RouteType.Tollway, false);
    //   await map.Route.label(longdo.RouteLabel.Hide, true);
    //   // console.log(longdo);
    //   await map.Route.search();
    // }
  };
  const getProvince = async () => {
    let pv = await masterProvice.Province();
    let mapPv = pv.data.data.results.map((ele) => {
      return { ...ele, item: ele.name, value: ele.id };
    });
    // console.log(mapPv);
    setProvice(mapPv);
  };
  const getDistrict = async () => {
    let dis = await masterProvice.District();
    let mapDis = dis.data.data.results.map((ele) => {
      return {
        ...ele,
        item: ele.name,
        value: ele.id,
      };
    });
    // console.log(mapDis);
    setDistrict(mapDis);
  };
  const getSubDistrict = async () => {
    let subdis = await masterProvice.SubDistrict();
    let mapDis = subdis.data.data.results.map((ele) => {
      return {
        ...ele,
        item: ele.name,
        value: ele.id,
      };
    });
    // console.log(mapDis);
    setSubDistrict(mapDis);
  };
  useEffect(() => {
    if (
      watchLocation &&
      watchTruck &&
      watchShipperId &&
      watchDistance &&
      watchService
    ) {
      console.log(
        "getGeneralPrice,",
        watchLocation,
        watchTruck,
        watchShipperId,
        watchDistance,
        watchService,
        watchBilling
      );
      let hasValue = true;
      let origin_province = [];
      let origin_district = [];
      let origin_subdistrict = [];
      let destination_province = [];
      let destination_district = [];
      let destination_subdistrict = [];
      watchLocation.forEach((ele, index) => {
        if (ele.province === "") {
          hasValue = false;
        } else {
          if (ele.type === 0) {
            origin_province.push(ele.province);
            origin_district.push(ele.district);
            origin_subdistrict.push(ele.subdistrict);
          } else {
            destination_province.push(ele.province);
            destination_district.push(ele.district);
            destination_subdistrict.push(ele.subdistrict);
          }
        }
      });
      if (!watchTruck.id) {
        hasValue = false;
      }
      if (!watchShipperId) {
        hasValue = false;
      }
      if (watchDistance?.allDistance === "") {
        hasValue = false;
      }
      // if (typeof watchBilling.profile_type === "undefined") {
      //   hasValue = false;
      // }
      if (buttonWorkType[1].active) {
        if (watchLocation[0].product[0].product_size === "") {
          hasValue = false;
        }
      }
      if (hasValue) {
        let body = {
          shipper_id: watchShipperId,
          distance:
            watchDistance?.allDistance > 0
              ? Math.ceil(watchDistance?.allDistance)
              : 1,
          truck_type: watchTruck.id,
          // truck_type: 1,
          origin_province: origin_province,
          origin_district: origin_district,
          origin_subdistrict: origin_subdistrict,
          destination_province: destination_province,
          destination_district: destination_district,
          destination_subdistrict: destination_subdistrict,
          doc_return: watchService?.has_document_return,
          load_amount: watchService?.labor_load_amount,
          unload_amount: watchService?.labor_unload_amount,
          service_id: buttonWorkType[0].active ? 1 : 2,
          profile_type: watchBilling?.profile_type,
        };
        if (body.service_id === 2) {
          body.stl_standard_size_id = watchLocation[0].product[0].product_size;
        }
        console.log(body);
        api
          .getGeneralPrice(body)
          .then((response) => {
            console.log("getGeneralPrice, ", response);
            let data = response.data.results.shipper;
            let dataCarrier = response.data.results.carrier;
            let dataDifference = response.data.results.difference;

            setValue("price", {
              ...watchPrice,
              shipper: {
                shipper_document_return_fee: data.doc_fee, // ค่าเอกสารนำกลับ
                shipper_labor_load_fee: data.labor_load_fee, // ค่าพนักงานขึ้นสินค้า
                shipper_labor_unload_fee: data.labor_unload_fee, // ค่าพนักงานลงสินค้า
                shipper_add_drop_point_fee: data.drop_point_fee, // ค่าเพิ่มจุดส่ง
                shipper_transport_fee: data.normal_price, // ค่าจัดส่ง
                shipper_freight_before_tax: data.price_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
                shipper_freight_tax: data.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
                shipper_freight_net: data.total_price, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
                shipper_has_deposit: false, // มีเงินมัดจำ
                shipper_deposit_amount: "0", // จำนวนเงินมัดจำ
                commission: "0", //ค่านายหน้า
                is_general: true,
                shipper_channel_price_type: 0,
              },
              carrier: {
                carrier_freight: dataCarrier.price_before_tax, // ค่าจ้างผู้ขนส่ง
                carrier_saas_fee: dataCarrier.saas, // ค่าธรรมเนียมระบบ
                carrier_freight_before_tax: dataCarrier.price_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
                carrier_freight_tax: dataCarrier.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
                carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
                carrier_insurance_per_trip: 0, // ค่าประกันภัยสินค้ารายเที่ยว
                carrier_transfer_fee: 0, // ค่าธรรมเนียมการโอนเงิน
                carrier_freight_net: dataCarrier.total_price, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
                is_general: true,
                carrier_channel_price_type: 0,
              },
              difference: {
                price_difference: dataDifference.price_difference,
                price_difference_percentage:
                  dataDifference.price_difference_percentage,
              },
            });
            setGeneralPrice({
              ...watchPrice,
              shipper: {
                shipper_document_return_fee: data.doc_fee, // ค่าเอกสารนำกลับ
                shipper_labor_load_fee: data.labor_load_fee, // ค่าพนักงานขึ้นสินค้า
                shipper_labor_unload_fee: data.labor_unload_fee, // ค่าพนักงานลงสินค้า
                shipper_add_drop_point_fee: data.drop_point_fee, // ค่าเพิ่มจุดส่ง
                shipper_transport_fee: data.normal_price, // ค่าจัดส่ง
                shipper_freight_before_tax: data.price_before_tax, // รวมราคาเรียกเก็บลูกค้าก่อนหักภาษี
                shipper_freight_tax: data.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาเรียกเก็บลูกค้าก่อนหักภาษี
                shipper_freight_net: data.total_price, // ค่าขนส่งสุทธิที่ต้องรับชำระลูกค้า (กรณีชำระเต็มจำนวน)
                shipper_has_deposit: false, // มีเงินมัดจำ
                shipper_deposit_amount: "0", // จำนวนเงินมัดจำ
                commission: "0", //ค่านายหน้า
                is_general: true,
                shipper_channel_price_type: 0,
              },
              carrier: {
                carrier_freight: dataCarrier.price_before_tax, // ค่าจ้างผู้ขนส่ง
                carrier_saas_fee: dataCarrier.saas, // ค่าธรรมเนียมระบบ
                carrier_freight_before_tax: dataCarrier.price_before_tax, // รวมราคาจ่ายผู้ขนส่งก่อนหักภาษี
                carrier_freight_tax: dataCarrier.tax, // ภาษีหัก ณ ที่จ่าย ที่ถูกหักจากราคาจ่ายผู้ขนส่งก่อนหักภาษี
                carrier_penalty: 0, // ค่าปรับกรณีทำผิดข้อตกลง
                carrier_insurance_per_trip: 0, // ค่าประกันภัยสินค้ารายเที่ยว
                carrier_transfer_fee: 0, // ค่าธรรมเนียมการโอนเงิน
                carrier_freight_net: dataCarrier.total_price, // ค่าขนส่งสุทธิที่ต้องชำระผู้ขนส่ง (กรณีชำระเต็มจำนวน)
                is_general: true,
                carrier_channel_price_type: 0,
              },
              difference: {
                price_difference: dataDifference.price_difference,
                price_difference_percentage:
                  dataDifference.price_difference_percentage,
              },
            });
          })
          .catch((error) => {
            console.log("error general price ", error);
            if (error.response.data.code === 4406) {
              Swal.fire({
                // timer: 2000,
                title: "error: " + error.response.data.code,
                text: error.response.data.description,
                icon: "error",
                // showConfirmButton: false,
              });
            }
          });
        console.log("has all value", body);
      }
    }
  }, [
    watchLocation,
    watchTruck,
    watchShipperId,
    watchDistance,
    watchService,
    watchBilling,
    buttonWorkType,
  ]);

  useEffect(() => {
    getPredictPrice();
  }, [watchLocation, watchTruck, watchService, watchDistance]);

  useEffect(() => {
    console.log("predictPrice", predictPrice);
  }, [predictPrice]);
  const getPredictPrice = async () => {
    const data = {
      distance: watchDistance?.orderDistance,
      work_type: buttonWorkType[0].active ? "ftl" : "stl",
      origin_province: [],
      destination_province: [],
      truck_type_id: watchTruck?.id,
    };
    for (const { ele, index } of watchLocation?.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.type === 0) {
        data.origin_province.push(ele.province.replace(",", ""));
      } else {
        data.destination_province.push(ele.province.replace(",", ""));
      }
    }

    console.log("getPredictPrice", data);
    api
      .predictPrice(data)
      .then((res) => {
        setPredictPrice(res.data.results);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const postWork = async (data) => {
    setLoading(true);
    console.log("postWork", data);
    let can_post = true;
    let list_error = [];
    let origin_location_name = [];
    let origin_location_lat = [];
    let origin_location_long = [];
    let origin_location_address = [];
    let origin_location_district = [];
    let origin_location_subdistrict = [];
    let origin_location_province = [];
    let origin_location_postal_code = [];
    let origin_location_contact_name = [];
    let origin_location_contact_phone = [];
    let origin_location_place_type_id = [];
    let origin_location_remark = [];
    let origin_location_document = [];
    let origin_location_document_file = [];
    let origin_product_id = [];
    let origin_product_name = [];
    let origin_product_size_id = [];
    let origin_product_width = [];
    let origin_product_length = [];
    let origin_product_height = [];
    let origin_product_volume = [];
    let origin_product_weight = [];
    let origin_product_count_type = [];
    let origin_product_amount = [];
    let origin_product_image = [];
    let origin_product_image_per_route = [];

    let destination_location_name = [];
    let destination_location_lat = [];
    let destination_location_long = [];
    let destination_location_address = [];
    let destination_location_district = [];
    let destination_location_subdistrict = [];
    let destination_location_province = [];
    let destination_location_postal_code = [];
    let destination_location_contact_name = [];
    let destination_location_contact_phone = [];
    let destination_location_place_type_id = [];
    let destination_location_remark = [];
    let destination_location_document = [];
    let destination_location_document_file = [];
    let destination_product_id = [];
    let destination_product_name = [];
    let destination_product_size_id = [];
    let destination_product_width = [];
    let destination_product_length = [];
    let destination_product_height = [];
    let destination_product_volume = [];
    let destination_product_weight = [];
    let destination_product_count_type = [];
    let destination_product_amount = [];
    let destination_product_image = [];
    let destination_product_image_per_route = [];
    for (const { ele, index } of data.location.map((ele, index) => ({
      ele,
      index,
    }))) {
      // data.location.forEach((ele, index) => {
      if (ele.lat !== "" && ele.long !== "") {
        if (ele.type === 0) {
          origin_location_name.push(
            ele.place_name === "" || !ele.place_name
              ? ele.subdistrict + " " + ele.district + " " + ele.province
              : ele.place_name
          );
          origin_location_lat.push(ele.lat);
          origin_location_long.push(ele.long);
          origin_location_address.push(ele.address.replace(",", "-"));
          origin_location_district.push(ele.district.replace(",", ""));
          origin_location_subdistrict.push(ele.subdistrict.replace(",", ""));
          origin_location_province.push(ele.province.replace(",", ""));
          origin_location_postal_code.push(
            ele.postal_code && ele.postal_code !== ""
              ? ele.postal_code
              : "00000"
          );
          origin_location_contact_name.push(ele.contact_name);
          origin_location_contact_phone.push(ele.contact_phone);
          origin_location_place_type_id.push(ele.place_type_id ?? 0);
          origin_location_remark.push(ele.remark);
          origin_location_document.push(ele.document);
          origin_location_document_file.push(ele.document_file);
          let prod_id = [];
          let prod_name = [];
          let prod_size_id = [];
          let prod_width = [];
          let prod_length = [];
          let prod_volume = [];
          let prod_height = [];
          let prod_weight = [];
          let prod_count_type = [];
          let prod_amount = [];
          let prod_img_route = [];
          let prod_img = [];

          for (const { pro, i } of ele.product.map((pro, i) => ({
            pro,
            i,
          }))) {
            // ele.product.forEach((pro, i) => {
            let img_route = 0;
            prod_id.push(pro.product_id);
            prod_name.push(pro.product_name);
            prod_size_id.push(pro.product_size);
            prod_width.push(pro.product_weight);
            prod_length.push(pro.product_length);
            prod_height.push(pro.product_height);
            prod_volume.push(pro.product_volume);
            prod_weight.push(pro.product_weight);
            prod_count_type.push(
              pro.product_count_type === "" ? 0 : pro.product_count_type
            );
            prod_amount.push(pro.product_amount);
            for (const { e, im } of pro.product_image.map((e, im) => ({
              e,
              im,
            }))) {
              // pro.product_image.forEach(async (e, im) => {
              if (e) {
                if (e.file) {
                  prod_img.push(e.file);
                } else {
                  const response = await fetch(helper.addhtpps(e.src));
                  const blob = await response.blob();
                  const file = new File([blob], "image" + im + ".jpg", {
                    type: blob.type,
                  });
                  console.log("file, file", file);
                  prod_img.push(file);
                  // prod_img.push({ name: "pro_img" + im + ".jpg", type: "image/jpg", uri: e.src });
                }
                // prod_img.push(e.file);

                img_route++;
              }
            }
            prod_img_route.push(img_route);
          }
          console.log("prod_img", prod_img);
          origin_product_image.push(prod_img);
          origin_product_id.push(prod_id);
          origin_product_name.push(prod_name);
          origin_product_size_id.push(prod_size_id);
          origin_product_width.push(prod_width);
          origin_product_length.push(prod_length);
          origin_product_height.push(prod_height);
          origin_product_volume.push(prod_volume);
          origin_product_weight.push(prod_weight);
          origin_product_count_type.push(prod_count_type);
          origin_product_amount.push(prod_amount);
          origin_product_image_per_route.push(prod_img_route);
        } else {
          destination_location_name.push(
            ele.place_name === "" || !ele.place_name
              ? ele.subdistrict + " " + ele.district + " " + ele.province
              : ele.place_name
          );
          // destination_location_name.push(ele.name);
          destination_location_lat.push(ele.lat);
          destination_location_long.push(ele.long);
          destination_location_address.push(ele.address.replace(",", "-"));
          destination_location_district.push(ele.district.replace(",", ""));
          destination_location_subdistrict.push(
            ele.subdistrict.replace(",", "")
          );
          destination_location_province.push(ele.province.replace(",", ""));
          destination_location_postal_code.push(
            ele.postal_code && ele.postal_code !== ""
              ? ele.postal_code
              : "00000"
          );
          destination_location_contact_name.push(ele.contact_name);
          destination_location_contact_phone.push(ele.contact_phone);
          destination_location_place_type_id.push(ele.place_type_id ?? 0);
          destination_location_remark.push(ele.remark);
          destination_location_document.push(ele.document);
          destination_location_document_file.push(ele.document_file);
          let prod_id = [];
          let prod_name = [];
          let prod_size_id = [];
          let prod_width = [];
          let prod_length = [];
          let prod_volume = [];
          let prod_height = [];
          let prod_weight = [];
          let prod_count_type = [];
          let prod_amount = [];
          let prod_img_route = [];
          let prod_img = [];
          // ele.product_image.forEach((e) => {
          //   if (e) {
          //     prod_img.push(e);
          //   }
          // });
          // destination_product_image.push(prod_img);
          for (const { pro, i } of ele.product.map((pro, i) => ({
            pro,
            i,
          }))) {
            let img_route = 0;
            prod_id.push(pro.product_id);
            prod_name.push(pro.product_name);
            prod_size_id.push(pro.product_size);
            prod_width.push(pro.product_weight);
            prod_length.push(pro.product_length);
            prod_height.push(pro.product_height);
            prod_volume.push(pro.product_volume);
            prod_weight.push(pro.product_weight);
            prod_count_type.push(
              pro.product_count_type === "" ? 0 : pro.product_count_type
            );
            prod_amount.push(pro.product_amount);
            for (const { e, im } of pro.product_image.map((e, im) => ({
              e,
              im,
            }))) {
              // pro.product_image.forEach(async (e, im) => {
              if (e) {
                if (e.file) {
                  prod_img.push(e.file);
                } else {
                  const response = await fetch(e.src);
                  const blob = await response.blob();
                  const file = new File([blob], "image" + im + ".jpg", {
                    type: blob.type,
                  });
                  console.log("file, file", file);
                  prod_img.push(file);
                  // prod_img.push({ name: "pro_img" + im + ".jpg", type: "image/jpg", uri: e.src });
                }
                // prod_img.push(e.file);

                img_route++;
              }
            }
            prod_img_route.push(img_route);
          }
          destination_product_image.push(prod_img);
          destination_product_id.push(prod_id);
          destination_product_name.push(prod_name);
          destination_product_size_id.push(prod_size_id);
          destination_product_width.push(prod_width);
          destination_product_length.push(prod_length);
          destination_product_height.push(prod_height);
          destination_product_volume.push(prod_volume);
          destination_product_weight.push(prod_weight);
          destination_product_count_type.push(prod_count_type);
          destination_product_amount.push(prod_amount);
          // destination_product_image.push(ele.product_image);
          destination_product_image_per_route.push(prod_img_route);
        }
      } else {
        can_post = false;
        list_error.push({ error: "สถานที่รับหรือส่ง" });
      }
    }
    if (can_post) {
      const formData = new FormData();
      formData.append("shipper_id", data.shipper_id);
      formData.append("project_id", data.project_id);
      formData.append("service_id", buttonWorkType[0].active ? 1 : 2);
      formData.append("fuel_rate", data.fuel_rate);

      formData.append("work_name", data.post_name ?? "");
      formData.append("work_type", buttonWorkType[0].active ? "ftl" : "stl");
      formData.append("distance", data.distance.allDistance);
      formData.append("duration", data.distance.allInterval);
      // formData.append("duration", data.duration);
      formData.append(
        "load_date_start",
        data.datetime.date.startDate.toISOString()
      );
      formData.append(
        "unload_date_start",
        data.datetime.date.startDate.toISOString()
      );
      formData.append(
        "load_date_end",
        data.datetime.date.endDate.toISOString()
      );
      formData.append(
        "unload_date_end",
        data.datetime.date.endDate.toISOString()
      );
      let timeStart = new Date();
      let timeStartSplit = data.datetime.time.time_start.split(":");
      // console.log("timeStart", timeStartSplit);
      timeStart.setHours(timeStartSplit[0]);
      timeStart.setMinutes(timeStartSplit[1]);
      timeStart.setSeconds(0);
      let timeEnd = new Date();
      let timeEndSplit = data.datetime.time.time_end.split(":");
      // console.log("timeStart", timeStartSplit);
      timeEnd.setHours(timeEndSplit[0]);
      timeEnd.setMinutes(timeEndSplit[1]);
      timeEnd.setSeconds(0);
      formData.append("load_time_start", timeStart.toISOString());
      formData.append("unload_time_start", timeStart.toISOString());
      formData.append("load_time_end", timeEnd.toISOString());
      formData.append("unload_time_end", timeEnd.toISOString());
      formData.append("work_remark", data.remark ?? "");
      formData.append("ref_customer_code_1", data.ref_customer_code_1);
      formData.append("ref_customer_code_2", data.ref_customer_code_2);
      formData.append("ref_customer_code_3", data.ref_customer_code_3);
      formData.append("ref_customer_code_4", data.ref_customer_code_4);
      formData.append("ref_customer_code_5", data.ref_customer_code_5);
      formData.append("ref_customer_code_6", data.ref_customer_code_6);
      formData.append("ref_customer_code_7", data.ref_customer_code_7);
      formData.append("ref_customer_code_8", data.ref_customer_code_8);
      formData.append("ref_customer_code_9", data.ref_customer_code_9);
      formData.append("ref_customer_code_10", data.ref_customer_code_10);
      formData.append("has_document_return", data.service.has_document_return);
      if (data.service.has_document_return) {
        formData.append(
          "ducument_return_code",
          data.service.ducument_return_code
        );
        formData.append(
          "document_return_address",
          data.service.document_return_address
        );
        let prov = province.filter(
          (e) => e.id === data.service.document_return_province
        )[0];
        formData.append("document_return_province", prov.name);
        let dist = district.filter(
          (e) => e.id === data.service.document_return_district
        )[0];
        formData.append("document_return_district", dist.name);
        let subDis = subDistrict.filter(
          (e) => e.id === data.service.document_return_subdistrict
        )[0];
        formData.append("document_return_subdistrict", subDis.name);
        formData.append(
          "document_return_postal_code",
          data.service.document_return_postal_code
        );
        formData.append(
          "document_return_contact_name",
          data.service.document_return_contact_name
        );
        formData.append(
          "document_return_contact_phone",
          data.service.document_return_contact_phone
        );
      }

      formData.append("has_labor", data.service.has_labor);
      formData.append("labor_load_amount", data.service.labor_load_amount);
      formData.append("labor_unload_amount", data.service.labor_unload_amount);
      formData.append("truck_type_id", data.truck.id);
      formData.append("has_accessory", false); //edit
      // formData.append("accessory_list", [] );//edit
      formData.append("origin_location_name", origin_location_name);
      formData.append("origin_location_lat", origin_location_lat);
      formData.append("origin_location_long", origin_location_long);
      formData.append("origin_location_address", origin_location_address);
      formData.append("origin_location_district", origin_location_district);
      formData.append(
        "origin_location_subdistrict",
        origin_location_subdistrict
      );
      formData.append("origin_location_province", origin_location_province);
      formData.append(
        "origin_location_postal_code",
        origin_location_postal_code
      );
      formData.append(
        "origin_location_contact_name",
        origin_location_contact_name
      );
      formData.append(
        "origin_location_contact_phone",
        origin_location_contact_phone
      );
      formData.append(
        "origin_location_place_type_id",
        origin_location_place_type_id
      );
      formData.append("origin_location_remark", origin_location_remark);
      formData.append("origin_location_document", origin_location_document);
      formData.append(
        "origin_location_document_file",
        origin_location_document_file
      );
      formData.append("origin_product_id", JSON.stringify(origin_product_id));
      formData.append(
        "origin_product_name",
        JSON.stringify(origin_product_name)
      );
      formData.append(
        "origin_product_size_id",
        JSON.stringify(origin_product_size_id)
      );
      formData.append(
        "origin_product_width",
        JSON.stringify(origin_product_width)
      );
      formData.append(
        "origin_product_length",
        JSON.stringify(origin_product_length)
      );
      formData.append(
        "origin_product_height",
        JSON.stringify(origin_product_height)
      );
      formData.append(
        "origin_product_volume",
        JSON.stringify(origin_product_volume)
      );
      formData.append(
        "origin_product_weight",
        JSON.stringify(origin_product_weight)
      );
      formData.append(
        "origin_product_count_type",
        JSON.stringify(origin_product_count_type)
      );
      formData.append(
        "origin_product_amount",
        JSON.stringify(origin_product_amount)
      );
      origin_product_image.forEach((e) => {
        // console.log("eeee", e);
        e.forEach((ee) => {
          formData.append("origin_product_image", ee);
        });
      });
      formData.append(
        "origin_product_image_per_route",
        JSON.stringify(origin_product_image_per_route)
      );
      formData.append("destination_location_name", destination_location_name);
      formData.append("destination_location_lat", destination_location_lat);
      formData.append("destination_location_long", destination_location_long);
      formData.append(
        "destination_location_address",
        destination_location_address
      );
      formData.append(
        "destination_location_district",
        destination_location_district
      );
      formData.append(
        "destination_location_subdistrict",
        destination_location_subdistrict
      );
      formData.append(
        "destination_location_province",
        destination_location_province
      );
      formData.append(
        "destination_location_postal_code",
        destination_location_postal_code
      );
      formData.append(
        "destination_location_contact_name",
        destination_location_contact_name
      );
      formData.append(
        "destination_location_contact_phone",
        destination_location_contact_phone
      );
      formData.append(
        "destination_location_place_type_id",
        destination_location_place_type_id
      );
      formData.append(
        "destination_location_remark",
        destination_location_remark
      );
      formData.append(
        "destination_location_document",
        destination_location_document
      );
      formData.append(
        "destination_location_document_file",
        destination_location_document_file
      );
      formData.append(
        "destination_product_id",
        JSON.stringify(destination_product_id)
      );
      formData.append(
        "destination_product_name",
        JSON.stringify(destination_product_name)
      );
      formData.append(
        "destination_product_size_id",
        JSON.stringify(destination_product_size_id)
      );
      formData.append(
        "destination_product_width",
        JSON.stringify(destination_product_width)
      );
      formData.append(
        "destination_product_length",
        JSON.stringify(destination_product_length)
      );
      formData.append(
        "destination_product_height",
        JSON.stringify(destination_product_height)
      );
      formData.append(
        "destination_product_volume",
        JSON.stringify(destination_product_volume)
      );
      formData.append(
        "destination_product_weight",
        JSON.stringify(destination_product_weight)
      );
      formData.append(
        "destination_product_count_type",
        JSON.stringify(destination_product_count_type)
      );
      formData.append(
        "destination_product_amount",
        JSON.stringify(destination_product_amount)
      );
      // console.log(destination_product_image);
      destination_product_image.forEach((e) => {
        // console.log("eeee", e);
        e.forEach((ee) => {
          formData.append("destination_product_image", ee);
        });
      });
      // formData.append("destination_product_image", destination_product_image);
      formData.append(
        "destination_product_image_per_route",
        JSON.stringify(destination_product_image_per_route)
      );
      if (data.fav_carrier) {
        formData.append("favorite_carrier_group", data.fav_carrier.id);

        formData.append("favorite_carrier", data.fav_carrier.select);
      }
      formData.append(
        "shipper_channel_price_type",
        data.price.shipper.shipper_channel_price_type
      ); //edit
      formData.append(
        "carrier_channel_price_type",
        data.price.carrier.carrier_channel_price_type
      ); //edit
      if (data.price.carrier.carrier_channel_price_type === 4) {
        let carrier = carrierInProjectPrice.filter((e) => e.select);

        formData.append(
          "choose_carrier_id",
          carrier[0].profile_carrier_in_project_id
        );
      }
      if (data.route_id) {
        formData.append("template_work_id", data.route_id);
      }
      formData.append(
        "shipper_freight_net",
        data.price.shipper.shipper_freight_net
      );
      formData.append(
        "shipper_document_return_fee",
        data.price.shipper.shipper_document_return_fee
      );
      formData.append(
        "shipper_labor_load_fee",
        data.price.shipper.shipper_labor_load_fee
      );
      formData.append(
        "shipper_labor_unload_fee",
        data.price.shipper.shipper_labor_unload_fee
      );
      formData.append(
        "shipper_add_drop_point_fee",
        data.price.shipper.shipper_add_drop_point_fee
      );
      formData.append(
        "shipper_transport_fee",
        data.price.shipper.shipper_transport_fee
      );
      formData.append(
        "shipper_freight_before_tax",
        data.price.shipper.shipper_freight_before_tax
      );
      formData.append(
        "shipper_freight_tax",
        data.price.shipper.shipper_freight_tax
      );
      formData.append(
        "shipper_has_deposit",
        data.price.shipper.shipper_has_deposit
      );
      formData.append(
        "has_deposit",
        data.deposit?.has_deposit && data.deposit?.has_deposit === 1
          ? true
          : false
      );
      formData.append(
        "shipper_deposit_amount",
        data?.deposit?.has_deposit ? data.deposit.deposit_amount : 0
      );
      formData.append("commission", data.price.difference.price_difference);
      formData.append("carrier_freight", data.price.carrier.carrier_freight);
      formData.append("carrier_saas_fee", data.price.carrier.carrier_saas_fee);
      formData.append(
        "carrier_freight_before_tax",
        data.price.carrier.carrier_freight_before_tax
      );
      formData.append(
        "carrier_freight_tax",
        data.price.carrier.carrier_freight_tax
      );
      formData.append(
        "carrier_penalty",
        data.price.carrier.carrier_penalty &&
          data.price.carrier.carrier_penalty !== ""
          ? data.price.carrier.carrier_penalty
          : 0
      );
      formData.append(
        "carrier_insurance_per_trip",
        data.price.carrier.carrier_insurance_per_trip &&
          data.price.carrier.carrier_insurance_per_trip !== ""
          ? data.price.carrier.carrier_insurance_per_trip
          : 0
      );
      formData.append(
        "carrier_transfer_fee",
        data.price.carrier.carrier_transfer_fee ?? 0
      );
      formData.append(
        "carrier_freight_net",
        data.price.carrier.carrier_freight_net
      );
      formData.append("receipt_name", data.billing.company_name); //edit
      formData.append("receipt_id_number", data.billing.id); //edit
      formData.append("receipt_address", data.billing.address ?? ""); //edit
      formData.append("receipt_province", data.billing.province?.name ?? ""); //edit
      formData.append("receipt_district", data.billing.district?.name ?? ""); //edit
      formData.append(
        "receipt_subdistrict",
        data.billing.subdistrict?.name ?? ""
      ); //edit
      formData.append(
        "receipt_postcode",
        data.billing.subdistrict?.postal_code ?? ""
      ); //edit
      let len = 0;
      for (var pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
        len++;
      }
      console.log("formdata len", len);
      // return;
      api
        .postWork(formData)
        .then((response) => {
          console.log("post work", response);
          setValue("fav_carrier", null);
          Swal.fire({
            // position: 'top-end',
            icon: "success",
            title: "ประกาศงานเสร็จสิ้น",
            text: "เลขที่งาน " + response.data.results,
            // showConfirmButton: false,
            // timer: 1500,
            willClose: () => {
              // window.location.href = "/work";
              setLoading(false);
            },
          });
        })
        .catch((e) => {
          console.log(e);

          setLoading(false);
          Swal.fire({
            timer: 2000,
            title: "error: " + e.response.data.code,
            text:
              e.response.data.description?.message ??
              e.response.data.description,
            icon: "error",
            showConfirmButton: false,
          });
        });
    }
  };
  return (
    <div className="post-work-page">
      {loading ? <LoadingBackground /> : null}
      <div className="post-work-body">
        <div className="post-work-body-flex">
          <div className="sub-menu-post-work">
            <SubMenu menus={menus} />
          </div>
          <div className="sub-menu-post-work-breadcrumbs">
            <SubMenu breadcrumbs={breadcrumbs} />
          </div>
          {/* <div style={{ width: 400 }}></div>
          <div style={{ width: 400 }}></div> */}
        </div>
        <div className="post-work-body-flex">
          <div className="post-work-box">
            {/* <InputLongdoAutoComplete /> */}
            <div style={{ marginBottom: 10 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      htmlFor="standard-adornment-shipper_code"
                      color={errors.shipper_code ? "error" : "primary"}
                    >
                      รหัสผู้ใช้งาน*
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.shipper_code}
                      id="shipper_code"
                      label="ชื่อผู้ใช้งาน"
                      value={value}
                      name="shipper_code"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="shipper_code"
                defaultValue=""
              />
              {errors.shipper_code && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                  }}
                >
                  <label
                    style={{
                      color: "#ff316f",
                      fontSize: 10,
                    }}
                  >
                    {error.shipper_code}
                  </label>
                </div>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                color="secondary"
                onClick={() => {
                  getProject();
                }}
              >
                ค้นหา
              </Button>
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputDisable
                    error={errors.profile_name}
                    value={value}
                    label={"ชื่อโปรไฟล์"}
                  />
                )}
                name="profile_name"
                defaultValue=""
              />
            </div>
            <div className="post-work-select-custom">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  // <InputSelect
                  //   label={"โครงการ*"}
                  //   value={value}
                  //   onChange={onChange}
                  //   options={projectList}
                  //   subFirst={"(ค่าเริ่มต้น: โครงการเริ่มต้น)"}
                  //   subSec={
                  //     "ในการสมัครสมาชิกทุกโปรไฟล์จะได้รับโครงการเริ่มต้นเป็นโครงการแรก"
                  //   }
                  // />
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel color={errors.project_id ? "error" : "primary"}>
                      โครงการ*
                    </InputLabel>
                    <Select
                      value={value}
                      onChange={(val) => {
                        console.log(val, "select");
                        setValue("project_id", val.target.value);
                        let code = projectList.filter(
                          (ele) => ele.id === val.target.value
                        )[0];
                        setValue("project_code", code.project_code);
                      }}
                      label="โครงการ"
                    >
                      {projectList.map((ele, index) => {
                        return (
                          <MenuItem key={"project" + ele.id} value={ele.id}>
                            {ele.item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="post-work-project-id">
                      <div>(ค่าเริ่มต้น: โครงการเริ่มต้น)</div>
                      <div>
                        ในการสมัครสมาชิกทุกโปรไฟล์จะได้รับโครงการเริ่มต้นเป็นโครงการแรก
                      </div>
                    </div>
                  </FormControl>
                )}
                name="project_id"
                defaultValue=""
              />
            </div>
            <div style={{ marginTop: -20 }}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputDisable
                    error={errors.project_code}
                    value={value}
                    label={"รหัสโครงการ"}
                  />
                )}
                name="project_code"
                defaultValue=""
              />
            </div>
            <div className="post-work-select-custom">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputNormal
                    value={value}
                    // onChange={(val) => {
                    //   setValue("fuel_rate", val.target.value);
                    //   // getRoute();
                    // }}
                    onChange={onChange}
                    label="อัตราน้ำมัน*"
                    subFirst={""}
                    error={errors.fuel_rate}
                    type={"number"}
                    // extraType={"phone"}
                  />
                  // <FormControl
                  //   variant="standard"
                  //   autoFocus
                  //   fullWidth
                  //   margin="none"
                  // >
                  //   <InputLabel color={errors.fuel_id ? "error" : "primary"}>
                  //     อัตราน้ำมัน*
                  //   </InputLabel>
                  //   <Select value={value} onChange={onChange} label="โครงการ">
                  //     <MenuItem value="">
                  //       <em>None</em>
                  //     </MenuItem>
                  //     <MenuItem value={10}>Ten</MenuItem>
                  //     <MenuItem value={20}>Twenty</MenuItem>
                  //     <MenuItem value={30}>Thirty</MenuItem>
                  //   </Select>
                  //   <div className="post-work-project-id">
                  //     <div>(ค่าเริ่มต้น: ระบบจะดึงจากฐานข้อมูล)</div>
                  //   </div>
                  // </FormControl>
                )}
                name="fuel_rate"
                defaultValue=""
              />
            </div>
            <div className="post-work-select-custom">
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel color={errors.route_id ? "error" : "primary"}>
                      ชื่อเส้นทาง
                    </InputLabel>
                    <Select value={value} onChange={onChange} label="โครงการ">
                      {routeList.map((ele, index) => {
                        return (
                          <MenuItem key={"route" + ele.id} value={ele.id}>
                            {ele.item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <div className="post-work-project-id">
                      <div>(ถ้าต้องใช้แม่แบบงาน)</div>
                    </div>
                  </FormControl>
                )}
                name="route_id"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_1 ? "error" : "primary"}
                    >
                      CS เลขที่เอกสารส่งมอบสินค้า
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_1}
                      id="ref_customer_code_1"
                      label="CS เลขที่เอกสารส่งมอบสินค้า"
                      value={value}
                      name="ref_customer_code_1"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_1"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_2 ? "error" : "primary"}
                    >
                      CS วันที่ในเอกสาร (เอกสารนำกลับ)
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_2}
                      id="ref_customer_code_2"
                      label="CS วันที่ในเอกสาร (เอกสารนำกลับ)"
                      value={value}
                      name="ref_customer_code_2"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_2"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_3 ? "error" : "primary"}
                    >
                      CS น้ำหนัก / จำนวนสินค้า
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_3}
                      id="ref_customer_code_3"
                      label="CS น้ำหนัก / จำนวนสินค้า"
                      value={value}
                      name="ref_customer_code_3"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_3"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_4 ? "error" : "primary"}
                    >
                      CS เลขที่ Shipment
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_4}
                      id="ref_customer_code_4"
                      label="CS เลขที่ Shipment"
                      value={value}
                      name="ref_customer_code_4"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_4"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_5 ? "error" : "primary"}
                    >
                      CS ชื่อร้านค้าที่ส่งสินค้า
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_5}
                      id="ref_customer_code_5"
                      label="CS ชื่อร้านค้าที่ส่งสินค้า"
                      value={value}
                      name="ref_customer_code_5"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_5"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_6 ? "error" : "primary"}
                    >
                      OP วันที่ในเอกสาร PO / PR
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_6}
                      id="ref_customer_code_6"
                      label="OP วันที่ในเอกสาร PO / PR"
                      value={value}
                      name="ref_customer_code_6"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_6"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_7 ? "error" : "primary"}
                    >
                      OP เลขที่ในเอกสาร PO / PR
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_7}
                      id="ref_customer_code_7"
                      label="OP เลขที่ในเอกสาร PO / PR"
                      value={value}
                      name="ref_customer_code_7"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_7"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_8 ? "error" : "primary"}
                    >
                      OP เรทราคาน้ำมัน
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_8}
                      id="ref_customer_code_8"
                      label="OP เรทราคาน้ำมัน"
                      value={value}
                      name="ref_customer_code_8"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_8"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_9 ? "error" : "primary"}
                    >
                      OP น้ำหนักที่ลูกค้าแจ้ง
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_9}
                      id="ref_customer_code_9"
                      label="OP น้ำหนักที่ลูกค้าแจ้ง"
                      value={value}
                      name="ref_customer_code_9"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_9"
                defaultValue=""
              />
            </div>
            <div>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    variant="standard"
                    autoFocus
                    fullWidth
                    margin="none"
                  >
                    <InputLabel
                      color={errors.ref_customer_code_10 ? "error" : "primary"}
                    >
                      OP อื่น ๆ
                    </InputLabel>
                    <Input
                      type={"text"}
                      error={errors.ref_customer_code_10}
                      id="ref_customer_code_10"
                      label="OP อื่น ๆ"
                      value={value}
                      name="ref_customer_code_10"
                      onChange={onChange}
                    />
                  </FormControl>
                )}
                name="ref_customer_code_10"
                defaultValue=""
              />
            </div>
          </div>
          <div className="post-work-box">
            <ButtonSwitch
              option={buttonWorkType}
              onClick={(value) => {
                let tmp = [...buttonWorkType];
                tmp.forEach((ele, i) => {
                  if (i === value) {
                    ele.active = true;
                  } else {
                    ele.active = false;
                  }
                });
                setButtonWorkType(tmp);
                if (value === 1) {
                  let tmp = [watchLocation[0], watchLocation[1]];
                  setValue("location", tmp);
                  let truck_default = truckType.filter(
                    (e) => e.order_number === 1
                  );
                  setValue("truck", truck_default[0]);
                }
              }}
            />
            <div>
              <SelectLocation
                value={watchLocation}
                onChange={(value) => {
                  setValue("location", value);
                  let location = value.map((e) => {
                    return { lat: e.lat, long: e.long, type: e.type };
                  });
                  console.log("loc", location);
                  setLoc(location);
                }}
                profile_id={watchShipperId}
                workType={buttonWorkType[0].active ? "ftl" : "stl"}
              />
              <Line />
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectTruck
                    value={value}
                    onChange={(val) => {
                      setValue("truck", val);
                      console.log("watchService before", watchService);
                      let tmp = { ...watchService };
                      tmp.has_labor = false;
                      tmp.labor_load_amount = 0;
                      tmp.labor_unload_amount = 0;
                      setValue("service", tmp);
                      console.log("watchService after", watchService);
                    }}
                    truckType={truckType}
                  />
                )}
                name="truck"
                defaultValue=""
              />
              <Line />
              {watchLocation ? (
                <SelectProduct
                  value={watchLocation[0].product ?? null}
                  onChange={(val) => {
                    console.log(val);
                    let tmp = [...watchLocation];
                    tmp[0].product = val;
                    setValue("location", tmp);
                    // setValue("product", val);
                  }}
                  workType={buttonWorkType[0].active ? "ftl" : "stl"}
                  productType={productType}
                />
              ) : null}
              {/* <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                 
                )}
                name="product"
                defaultValue=""
              /> */}
              <Line />
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectService
                    value={value}
                    onChange={(val) => {
                      setValue("service", val);
                    }}
                    truck={watchTruck}
                  />
                )}
                name="service"
                defaultValue=""
              />
              <Line />
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectDateTime
                    value={value}
                    onChange={(val) => {
                      setValue("datetime", val);
                    }}
                  />
                )}
                name="datetime"
                defaultValue=""
              />
              <Line />
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectBilling
                    value={value}
                    onChange={(val) => {
                      setValue("billing", val);
                    }}
                    profile_id={watchShipperId}
                  />
                )}
                name="billing"
                defaultValue=""
              />
              <Line />
              <SelectRemark
                value={watchRemark}
                onChange={(val) => {
                  setValue("remark", val);
                }}
              />
              <Line />
              <SelectPostName
                value={watchPostName}
                onChange={(val) => {
                  setValue("post_name", val);
                }}
              />
              <Line />
              <SelectFavCarrier
                value={watchFavCarrier}
                onChange={(val) => {
                  console.log("fav_carrier", val);
                  setValue("fav_carrier", val);
                }}
                profile_id={watchShipperId}
              />
              <Line />
            </div>
          </div>
          <div className="post-work-box-map">
            <div className="map-post-work">
              <MapWithCall call={call} />
            </div>

            {/* <LongdoMap
              id={"post-work-main"}
              style={{ borderRadius: 20 }}
              callback={initMap()}
            /> */}
            <div
              style={{
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                padding: 20,
                borderRadius: 20,
                textAlign: "center",
                position: "absolute",
                width: "100%",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <div>ระยะทางประมาณ {watchDistance?.orderDistance ?? 0} กม.</div>
              <div>
                ระยะเวลาเดินทางประมาณ{" "}
                {watchDistance?.orderInterval ?? "- ชั่วโมง"}
                {/*ระยะเวลาเดินทางประมาณ {watchDuration?.replace(/,/g, " ")}*/}
              </div>
            </div>
          </div>
          <div className="post-work-box">
            <SelectPrice
              data={watchPrice ?? null}
              hasDeposit={(val) => {
                setValue("deposit", {
                  ...watchDeposit,
                  has_deposit: val.target.value,
                });
              }}
              depositAmount={(val) => {
                setValue("deposit", {
                  ...watchDeposit,
                  deposit_amount: val.target.value,
                });
              }}
              onChange={(val) => {
                setValue("price", val);
              }}
              deposit={watchDeposit ?? null}
              destination={watchLocation.filter((e) => e.type === 1).length}
              doc_return={watchService.has_document_return}
              labor_load_amount={
                watchService.has_labor && watchService.labor_load_amount > 0
                  ? watchService.labor_load_amount
                  : 0
              }
              labor_unload_amount={
                watchService.has_labor && watchService.labor_unload_amount > 0
                  ? watchService.labor_unload_amount
                  : 0
              }
              saas={watchTruck?.saas ?? 0}
              generalPrice={generalPrice}
              profile_type={watchBilling?.profile_type ?? 0}
              predictPrice={predictPrice}
              setGeneralPrice={(type) => {
                if (type === "shipper") setValue("price", generalPrice);
                else
                  setValue("price", {
                    ...watchPrice,
                    [type]: generalPrice[type],
                    difference: generalPrice.difference,
                  });
              }}
              templatePrice={templatePrice}
              carrierInProjectPrice={carrierInProjectPrice}
              setCarrierInProjectPrice={(val) => {
                setCarrierInProjectPrice(val);
              }}
            />
            {/* <div style={{ padding: 10 }}>
              <div
                style={{
                  display: "flex",
                  fontWeight: 500,
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }}>ราคาลูกค้าทั่วไป</div>
                <div style={{}}>
                  {helper.addComma(
                    watchPrice?.shipper.shipper_freight_before_tax
                  ) ?? "-"}{" "}
                  บาท
                </div>
                <div style={{ padding: "0px 0px 0px 20px" }}>
                  <IconButton
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: "6px",
                      padding: "12px 10px 12px 10px",
                    }}
                    onClick={() => setShowShipperPrice(true)}
                  >
                    <img
                      src={require("../../assets/image/work/menubar.png")}
                      alt="menu"
                      width={22}
                    />
                  </IconButton>
                </div>
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>ราคาลูกค้า</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.shipper.shipper_transport_fee
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>ค่าเพิ่มจุดส่ง</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.shipper.shipper_add_drop_point_fee
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>ค่าเอกสารนำกลับ</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.shipper.shipper_document_return_fee
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>ค่าคนงานยกสินค้าขึ้น</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.shipper.shipper_labor_load_fee
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>ค่าคนงานยกสินค้าลง</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.shipper.shipper_labor_unload_fee
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>รวมราคาลูกค้าก่อนหักภาษี</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.shipper.shipper_freight_before_tax
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>หักภาษีหัก ณ ที่จ่าย</label>
                  <label>
                    {helper.addComma(watchPrice?.shipper.shipper_freight_tax) ??
                      "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>รวมราคาลูกค้าสุทธิ</label>
                  <label>
                    {helper.addComma(watchPrice?.shipper.shipper_freight_net) ??
                      "-"}{" "}
                    บาท
                  </label>
                </div>
              </div>
              <Line />
              <div
                style={{
                  display: "flex",
                  fontWeight: 500,
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={{ flex: 1 }}>ราคาผู้ขนส่งทั่วไป</div>
                <div style={{}}>
                  {helper.addComma(
                    watchPrice?.carrier.carrier_freight_before_tax
                  ) ?? "-"}{" "}
                  บาท
                </div>
                <div style={{ padding: "0px 0px 0px 20px" }}>
                  <IconButton
                    sx={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: "6px",
                      padding: "12px 10px 12px 10px",
                    }}
                  >
                    <img
                      src={require("../../assets/image/work/menubar.png")}
                      alt="menu"
                      width={22}
                    />
                  </IconButton>
                </div>
              </div>
              <Line />
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>ราคาผู้ขนส่ง</label>
                  <label>
                    {helper.addComma(watchPrice?.carrier.carrier_freight) ??
                      "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>รวมราคาผู้ขนส่งก่อนหักภาษี</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.carrier.carrier_freight_before_tax
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>หักภาษีหัก ณ ที่จ่าย</label>
                  <label>
                    {helper.addComma(watchPrice?.carrier.carrier_freight_tax) ??
                      "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>หักประกันสินค้ารายเที่ยว</label>
                  <label>
                    {helper.addComma(
                      watchPrice?.carrier.carrier_insurance_per_trip
                    ) ?? "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>หักค่าปรับอื่น ๆ</label>
                  <label>
                    {helper.addComma(watchPrice?.carrier.carrier_penalty) ??
                      "-"}{" "}
                    บาท
                  </label>
                </div>
                <div style={{ display: "flex" }}>
                  <label style={{ flex: 1 }}>รวมราคาผู้ขนส่งสุทธิ</label>
                  <label>
                    {helper.addComma(watchPrice?.carrier.carrier_freight_net) ??
                      "-"}{" "}
                    บาท
                  </label>
                </div>
              </div>
              <Line />
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div style={{ display: "flex", fontWeight: 500 }}>
                  <div style={{ flex: 1 }}>ส่วนต่าง</div>
                  <div>
                    {helper.addComma(watchPrice?.difference.price_difference) ??
                      "-"}{" "}
                    บาท{" "}
                  </div>
                  <div style={{ flex: 1, padding: "0px 0px 0px 10px" }}>
                    ส่วนต่าง
                  </div>
                  <div>
                    {helper.addComma(
                      watchPrice?.difference.price_difference_percentage
                    ) ?? "-"}{" "}
                    %
                  </div>
                </div>
              </div>
              <Line />
              <div className="post-work-select-custom">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <FormControl
                      variant="standard"
                      autoFocus
                      fullWidth
                      margin="none"
                    >
                      <InputLabel color={errors.fuel_id ? "error" : "primary"}>
                        ค่ามัดจำ*
                      </InputLabel>
                      <Select
                        value={watchDeposit?.has_deposit}
                        defaultValue={0}
                        onChange={(val) => {
                          console.log(val);
                          setValue("deposit", {
                            ...watchDeposit,
                            has_deposit: val.target.value,
                          });
                        }}
                        label="ค่ามัดจำ*"
                      >
                        <MenuItem value={0}>ไม่มี</MenuItem>
                        <MenuItem value={1}>มี</MenuItem>
                      </Select>
                      <div className="post-work-project-id">
                        <div>(ค่าเริ่มต้น: ไม่มี)</div>
                      </div>
                    </FormControl>
                  )}
                  name="deposit"
                  defaultValue=""
                />
              </div>
              <Controller
                control={control}
                rules={{
                  required: watchDeposit?.has_deposit === 1 ?? false,
                }}
                render={({ field: { onChange, onBlur, value } }) => {
                  if (watchDeposit?.has_deposit === 1) {
                    return (
                      <InputNormal
                        label="ค่ามัดจำ"
                        subFirst={"(ถ้าไม่มีค่ามัดจำจะไม่สามารถระบุได้)"}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  } else {
                    return (
                      <InputDisable
                        label="ค่ามัดจำ"
                        subFirst={"(ถ้าไม่มีค่ามัดจำจะไม่สามารถระบุได้)"}
                      />
                    );
                  }
                }}
                name="deposit.deposit_amount"
                defaultValue=""
              />
            </div> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              color="secondary"
              onClick={handleSubmit(postWork)}
              disabled={loading}
            >
              ลงประกาศ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
