import * as React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Area,
} from "recharts";
import Title from "./Title";
import "./chart.css";
import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";

// const data = [
//   {
//     name: "string",
//     value: "array[number]"
//     data: "json"
//   },
// ];

export default function Chart({ title, data, color }) {
  const [maxY, setMaxY] = React.useState(0);

  React.useEffect(() => {
    const y = data.reduce((oldVal, newVal) => {
      let convertedNumbers = newVal.value.map(Number);
      let numberVal = Math.max(...convertedNumbers);
      if (convertedNumbers.length === 0) {
        numberVal = 0;
      }
      if (numberVal > oldVal) {
        return numberVal;
      }
      return oldVal;
    }, 0);
    return () => {
      setMaxY(y);
    };
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload.length > 0) {
      const desc = payload[0].payload.data;
      return (
        <div className="chart-tooltip">
          {Object.keys(desc).map((key, index) => (
            <div key={key}>
              <p className="chart-tooltip-text">
                <CircleIcon
                  sx={{ fontSize: "10px", color: `#${color[index]}` }}
                />{" "}
                {key}: {desc[key]}
              </p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => {
    const desc = data[0].data;

    return (
      <Box component="div" px={{ width: "100%", textAlign: "center" }}>
        {Object.keys(desc).map((key, index) => (
          <span style={{ paddingRight: "20px" }} key={index}>
            <CircleIcon sx={{ fontSize: "10px", color: `#${color[index]}` }} />{" "}
            {key}
          </span>
        ))}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <CustomLegend />
      <ResponsiveContainer>
        <AreaChart
          width={800}
          height={380}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 30,
          }}
        >
          <defs>
            <linearGradient id="color2499EF" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#2499EF" stopOpacity={1} />
              <stop offset="95%" stopColor="#2499EF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorFF316F" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF316F" stopOpacity={1} />
              <stop offset="95%" stopColor="#FF316F" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorFFC675" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FFC675" stopOpacity={1} />
              <stop offset="95%" stopColor="#FFC675" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color27CE88" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#27CE88" stopOpacity={1} />
              <stop offset="95%" stopColor="#27CE88" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color8C8DFF" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8C8DFF" stopOpacity={1} />
              <stop offset="95%" stopColor="#8C8DFF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="color8C8DFF" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8CA3BA" stopOpacity={1} />
              <stop offset="95%" stopColor="#8CA3BA" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, maxY]} />
          <Tooltip content={<CustomTooltip />} />
          {Object.keys(data[0].value).map((key, index) => (
            <Area
              key={key}
              type="monotone"
              dataKey={`value.${key}`}
              stroke={`#${color[index]}`}
              fillOpacity={1}
              fill={`url(#color${color[index]})`}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
