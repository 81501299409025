import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Container } from "@mui/material";
import DragTable from "components/table/DragTable";
import SubMenu from "components/layout/SubMenu.js";
import api from "../../../../utils/api/masterData/productType";
import TableActiveIcon from "components/tableActiveIcon";
import helper from "utils/helper";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";

const columns = [
  {
    key: "created_at",
    title: "วันที่สร้างรายการ",
    align: "center",
    render: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    key: "updated_at",
    title: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    render: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    key: "code",
    title: "รหัสประเภทสินค้า",
    align: "center",
    render: (value) => {
      return value.code;
    },
  },
  {
    key: "name",
    title: "ประเภทสินค้า",
    align: "center",
    render: (value) => {
      return value.name;
    },
  },
  {
    key: "active",
    title: "สถานะใช้งาน",
    align: "center",
    render: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const OrderProductType = () => {
  const [productType, setProductType] = useState([]);
  const [listNewId, setListNewId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const handleGetAllProductType = async () => {
    setIsLoading(true);
    try {
      const params = {
        sort_field: ["order_number"],
        sort_order: [1],
        page: null,
        per_page: null,
      };
      const resp = await api.ProductType(params);

      setProductType(resp.data.data.results);
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllProductType: ", error);
      setProductType([]);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllProductType();
    // eslint-disable-next-line
  }, []);

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดประเภทรถ`}
        pathbackward={`/master-data/product-type`}
        menus={[
          {
            label: "จัดการลำดับประเภทสินค้า",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "ประเภทสินค้า",
            path: null,
          },
          {
            label: "จัดการลำดับประเภทสินค้า",
            path: null,
          },
        ]}
      />
    );
  };

  const renderTable = () => {
    return (
      <DragTable
        columns={columns}
        dataSource={productType}
        loading={isLoading}
        onDragChange={(val) => {
          setListNewId(val.map((data) => data.id));
        }}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  const handleUpdateOrderProductType = async () => {
    try {
      if (!R.isEmpty(listNewId)) {
        setIsLoading(true);
        const resp = await api.updateOrderProductType({
          list_product_type_id: listNewId,
        });

        if (resp.data.code === 1000) {
          setListNewId([]);
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
        setIsLoading(false);
      }
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Container>
      {renderSubMenu()}
      {renderTable()}
      {renderModal()}

      <FloatingButton
        button_type="save"
        onClick={() => {
          handleUpdateOrderProductType();
        }}
      />
    </Container>
  );
};

export default OrderProductType;
