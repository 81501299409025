// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WemoveVersionInfo_main_container__DURVs {
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 0;
  gap: 40px;
}

.WemoveVersionInfo_field_container__AZJOK {
  min-width: 400px;
  min-height: 80vmin;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);
  padding: 20px 30px;
  margin: 10px 0px;
}

.WemoveVersionInfo_field_container__AZJOK .WemoveVersionInfo_header_wrapper__vmrBy {
  text-align: center;
  margin-bottom: 10px;
}

.WemoveVersionInfo_field_container__AZJOK .WemoveVersionInfo_header_wrapper__vmrBy h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 5px;
}

.WemoveVersionInfo_field_container__AZJOK .WemoveVersionInfo_header_wrapper__vmrBy h3 {
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  color: #8ca3ba;
  margin: 2px 0px;
}

.WemoveVersionInfo_field_container__AZJOK .WemoveVersionInfo_field_wrapper__P1ZxP .WemoveVersionInfo_field__QvboG {
  margin: 2px 0px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/masterData/appVersion/WemoveVersion/WemoveVersionInfo/WemoveVersionInfo.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,SAAS;EACT,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,oDAAoD;EACpD,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".main_container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  overflow: auto;\r\n  margin: 0;\r\n  gap: 40px;\r\n}\r\n\r\n.field_container {\r\n  min-width: 400px;\r\n  min-height: 80vmin;\r\n  border-radius: 20px;\r\n  background: #fff;\r\n  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);\r\n  padding: 20px 30px;\r\n  margin: 10px 0px;\r\n}\r\n\r\n.field_container .header_wrapper {\r\n  text-align: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.field_container .header_wrapper h2 {\r\n  font-size: 20px;\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  line-height: normal;\r\n  margin: 0px 0px 5px;\r\n}\r\n\r\n.field_container .header_wrapper h3 {\r\n  font-size: 14px;\r\n  font-style: italic;\r\n  font-weight: 300;\r\n  line-height: normal;\r\n  color: #8ca3ba;\r\n  margin: 2px 0px;\r\n}\r\n\r\n.field_container .field_wrapper .field {\r\n  margin: 2px 0px;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `WemoveVersionInfo_main_container__DURVs`,
	"field_container": `WemoveVersionInfo_field_container__AZJOK`,
	"header_wrapper": `WemoveVersionInfo_header_wrapper__vmrBy`,
	"field_wrapper": `WemoveVersionInfo_field_wrapper__P1ZxP`,
	"field": `WemoveVersionInfo_field__QvboG`
};
export default ___CSS_LOADER_EXPORT___;
