import React from "react";
import {
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const FieldSearch = ({
  value = null,
  name = ``,
  placeholder = ``,
  onChange = () => {},
  onPressEnter = () => {},
  className = ``,
  style = {},
}) => {
  return (
    <FormControl variant="standard" className={className} style={style}>
      <InputLabel htmlFor="standard-adornment-password">{name}</InputLabel>
      <Input
        value={value}
        id="standard-adornment-password"
        type="text"
        placeholder={placeholder}
        sx={{
          ":before": { borderBottomColor: "#8CA3BA" },
          ":after": { borderBottomColor: "#8CA3BA" },
          ":hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottomColor: "#8CA3BA",
          },
        }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton edge="start">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onPressEnter(e.target.value);
          }
        }}
      />
    </FormControl>
  );
};

export default FieldSearch;
