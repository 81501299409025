import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Container } from "@mui/material";
import TableCustom from "components/table/TableCustom";
import TableActiveIcon from "components/tableActiveIcon";
import FilterTab from "components/filterTab/FilterTab";
import { subMenuMasterData } from "utils/submenuoptions";
import SubMenu from "components/layout/SubMenu.js";
import { getAllAnnualInsurance } from "utils/api/masterData/annualInsurance";
import helper from "utils/helper";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "-",
    label: "ชื่อตัวแทนประกันภัยสินค้า",
    align: "center",
    format: (value) => {
      //   return Number(value.rate).toFixed(2);
      return "yoyo";
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const AnnualInsurance = () => {
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `name`,
    search_val: ``,
  };

  const [annualInsurance, setAnnualInsurance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [filter, setFilter] = useState(initial_filter);

  useEffect(() => {
    getAllAnnualInsuranceData({ update_pagination: true });

    // eslint-disable-next-line
  }, []);

  const getAllAnnualInsuranceData = async ({ update_pagination = true }) => {
    setIsLoading(true);
    try {
      const params = {
        start_date:
          !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
            ? new Date(filter.start_date).toISOString()
            : null,
        end_date:
          !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
            ? new Date(filter.end_date).toISOString()
            : null,
        sort_field: filter.sort_field,
        sort_order: filter.sort_order,
        page: pagination.page,
        per_page: pagination.per_page,
        [filter.search_field]: filter.search_val,
      };

      const resp = await getAllAnnualInsurance(params);
      setAnnualInsurance(resp.data.data.results);
      setIsLoading(false);

      if (update_pagination === true) {
        setPagination({
          ...pagination,
          total: resp.data.data.total,
        });
      }
    } catch (error) {
      console.log("error getAllAnnaulInsurance: ", error);
      setIsLoading(false);
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`ประกันภัยสินค้ารายปี`}
        menus={subMenuMasterData.map((menu) => {
          return {
            label: menu.label,
            path: menu.path,
            active: menu.label === `ประกันภัยสินค้ารายปี` ? true : false,
          };
        })}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "ประกันภัยสินค้ารายปี",
            path: null,
          },
        ]}
      />
    );
  };
  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={annualInsurance}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          setPagination({
            ...pagination,
            ...val,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        href={`/master-data/annual-insurance/`}
      />
    );
  };

  return (
    <>
      <Container>
        {renderSubMenu()}
        {renderTable()}
      </Container>
    </>
  );
};

export default AnnualInsurance;
