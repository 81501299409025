// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginBody {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  flex: 1 1;
  height: 100%;
  overflow: scroll;
}

.loginBodySlide {
  background-color: #121f43;
  flex: 1 1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .loginBody {
    flex-direction: column;
  }
  .loginBodySlide {
    display: none;
  }
}

.loginBodyForm {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}
.loginBodySubForm {
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
}
.loginBodySubForm label {
  color: #8ca3ba;
}
.loginBodySubForm .MuiInputBase-root {
  padding: 10px 10px 10px 20px;
}

.loginBodySubForm .MuiFormLabel-root {
  padding: 10px 10px 10px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,WAAW;EACX,SAAO;EACP,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,SAAO;EACP,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE,SAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".loginBody {\n  display: flex;\n  flex-direction: row;\n  position: fixed;\n  width: 100%;\n  flex: 1;\n  height: 100%;\n  overflow: scroll;\n}\n\n.loginBodySlide {\n  background-color: #121f43;\n  flex: 1;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n@media (max-width: 1200px) {\n  .loginBody {\n    flex-direction: column;\n  }\n  .loginBodySlide {\n    display: none;\n  }\n}\n\n.loginBodyForm {\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 200px;\n}\n.loginBodySubForm {\n  text-align: center;\n  padding-left: 25%;\n  padding-right: 25%;\n}\n.loginBodySubForm label {\n  color: #8ca3ba;\n}\n.loginBodySubForm .MuiInputBase-root {\n  padding: 10px 10px 10px 20px;\n}\n\n.loginBodySubForm .MuiFormLabel-root {\n  padding: 10px 10px 10px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
