import React, { useState, useRef, useEffect } from "react";
import styles from "./ModalAddParking.module.css";
import { Modal, Typography, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import ButtonCustom from "components/ButtonCustom";
import { Controller, useForm } from "react-hook-form";
import Loading from "components/loading/LoadingBackground";
import FieldInput from "components/field/FieldInput";
import { LongdoMap, map, longdo } from "components/map/LongdoMap";
import helper from "utils/helper";
import HelperMap from "utils/helperMap";
import InputGoogleComplete from "components/input/inputGoogleComplete";
import FieldFindLocation from "components/field/FieldFindLocation";
import { addParking, editParking } from "utils/api/profile/parking";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import ENV from "utils/environment";
import InputLongdoAutoComplete from "components/input/InputLongdoAutoComplete";

const ModalAddParking = (props) => {
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const { profile_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [currentPin, setCurrentPin] = useState();
  const { ref } = useRef();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();

  const location_info = watch("location");
  const radius = watch("radius");

  useEffect(() => {
    console.log("location_info", location_info);
    console.log("radius", radius);
  }, [location_info, radius]);

  useEffect(() => {
    initMap();
  }, [props?.open]);

  //  initial
  useEffect(() => {
    const newInitialFormValues = {};
    const detailParking = props?.data;
    if (detailParking) {
      newInitialFormValues.location = {
        lat: Number(detailParking?.lat) || "",
        long: Number(detailParking?.long) || "",
        search: detailParking?.address || "",
      };
      newInitialFormValues.address = detailParking?.address;
      newInitialFormValues.name = detailParking?.name || "";
      newInitialFormValues.radius = Number(detailParking?.radius) || "";

      Object.keys(newInitialFormValues).forEach((fieldName) => {
        setValue(fieldName, newInitialFormValues[fieldName]);
      });
    }
  }, [props?.data]);

  const initMap = () => {
    console.log("initMap modal map");
    navigator.geolocation.getCurrentPosition(async function (position) {
      await map?.location(
        { lat: position.coords.latitude, lon: position.coords.longitude },
        true
      );

      const dot = await new longdo.Marker(
        { lat: position.coords.latitude, lon: position.coords.longitude },
        {
          title: "My Location",
          draggable: false,
          weight: longdo.OverlayWeight.Top,
        }
      );
      setCurrentPin(dot);
      await moveMap({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
      await map.Ui.Fullscreen.visible(true);
      await map.Ui.Zoombar.visible(true);
      // await map.Overlays.add(dot);
    });
  };
  useEffect(() => {
    if (
      map &&
      props?.open &&
      location_info &&
      location_info.lat !== "" &&
      location_info.long !== ""
    ) {
      markMap();
    }
  }, [location_info?.lat, location_info?.long, currentPin, radius]);

  const markMap = async () => {
    console.log("Do Mark Map");
    if (location_info.lat !== "" && location_info.long !== "") {
      if (currentPin) {
        await map.Overlays.clear();
      }
      const dot = await new longdo.Marker(
        { lat: location_info.lat, lon: location_info.long },
        {
          title: "My Location",
          draggable: false,
          clickable: false,
          weight: longdo.OverlayWeight.Top,
          icon: {
            html:
              '<div class="d-flex flex-row"><img src="' +
              require("../../assets/image/work/send_pin.png") +
              '" style="height: 30px"/></div>',
          },
        }
      );
      let radian = parseInt(radius) / 105; //เลขที่ถูกคือ 111.32 แต่เทสใน map แล้วไม่ตรง เลยปรับลงให้ใกล้เคียงที่สุด
      const circle = await new longdo.Circle(
        {
          lat: location_info?.lat,
          lon: location_info?.long,
        },
        radian,
        {
          title: "radius",
          detail: "-",
          lineWidth: 2,
          lineColor: "rgba(255, 0, 0, 0.8)",
          fillColor: "rgba(255, 0, 0, 0.4)",
        }
      );

      console.log(
        "location_info?.lat && location_info?.long",
        location_info?.lat,
        location_info?.long
      );
      if (location_info?.lat && location_info?.long) {
        await moveMap({ lat: location_info.lat, lon: location_info.long });
      }
      if (dot) {
        await map.Overlays.add(dot);
      }
      if (circle) {
        await map.Overlays.add(circle);
      }
      await map.zoom(13, true);
      await map.Ui.Fullscreen.visible(true);
      await map.Ui.Zoombar.visible(true);
      // await map.Overlays.add(currentPin);

      await map.Event.bind("overlayDrop", async function (overlay) {
        console.log(overlay.location());
        let data = await HelperMap.getGeocode({
          lat: overlay.location().lat,
          lng: overlay.location().lon,
        });
        let detail = await HelperMap.getDetailLocation(data, {
          lat: overlay.location().lat,
          lng: overlay.location().lon,
        });
        detail.search = detail.address;
        setValue("location", {
          ...location_info,
          ...detail,
        });
        await moveMap(overlay.location());
      });
    }
  };

  const moveMap = async (location) => {
    await map.location(location, true);
  };
  const onSubmit = async (data) => {
    console.log("data", data);
    const dataJson = {
      name: data?.name,
      radius: Number(data?.radius),
      address: data?.location.address,
      lat: Number(data?.location.lat),
      long: Number(data?.location.long),
      active: true,
    };
    console.log("dataJson", dataJson);
    if (profile_id && props.type === "edit") {
      if (props?.data) {
        await editParking(props.data.id, dataJson)
          .then((response) => {
            if (response.data.code === 1000) {
              props.handleClose();
              props.alertSuccess();
            }
          })
          .catch(async (error) => {
            console.log(error);
            if (error) {
              setLoading(false);
              if (error.response.data.code === 4106) {
                props.alertError({
                  open: true,
                  desc: error.response.data.description,
                });
              } else if (error.response.data.code === "4105") {
                removeAccessToken("access_token");
                window.location.reload();
              }
            }
          });
      }
    } else if (profile_id && !(props.type === "edit")) {
      await addParking(profile_id, dataJson)
        .then((response) => {
          if (response.data.code === 1000) {
            props.handleClose();
            setLoading(false);
            props.alertSuccess();
          }
        })
        .catch(async (error) => {
          console.log(error);
          if (error) {
            setLoading(false);
            if (error.response.data.code === 4106) {
              props.alertError({
                open: true,
                desc: error.response.data.description,
              });
            } else if (error.response.data.code === "4105") {
              removeAccessToken("access_token");
              window.location.reload();
            }
          }
        });
    }
  };
  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form id="form-content" onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.titleAddParking}>
                <Typography fontSize={14} color={"grey.main"} fontWeight={500}>
                  เพิ่มจุดจอด
                </Typography>
              </div>
              <Controller
                name="location"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    {/* <FieldFindLocation onChange={onChange} value={value} /> */}
                    <InputLongdoAutoComplete
                      value={value}
                      label={"ค้นหาจุดจอด"}
                      onChange={(val) => {
                        console.log("val", val);
                        onChange({
                          ...val,
                        });
                      }}
                      closeFav={true}
                    />
                  </div>
                )}
              />
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldInput
                      label="ชื่อจุดจอด"
                      style={{ width: "-webkit-fill-available" }}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
              <Controller
                name="radius"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldInput
                      label="รัศมีที่ต้องการรับงาน (กม.)"
                      type="number"
                      style={{ width: "-webkit-fill-available" }}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
            </form>
            <div
              style={{
                position: "relative",
                height: "350px",
              }}
            >
              <LongdoMap
                id={"post-work-main"}
                ref={ref}
                map_key={ENV.MAP_KEY[ENV.ENV].KEY_3}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ยกเลิก"
                  onClick={props?.handleClose}
                  type={"cancel"}
                />
              </div>
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name={"บันทึก"}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddParking;
