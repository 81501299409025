import { Navigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import helper from "./helper";

const PrivateRoutes = () => {
  let auth = { token: helper.getCookie("access_token") };

  return auth.token ? <MainLayout /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
