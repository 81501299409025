export default function TextField(theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: { color: theme.palette.grey.main, fontWeight: 500 },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       "& .MuiInput-root": {
    //         "&:before": { borderBottomColor: theme.palette.grey.main },
    //         "&:after": { borderBottomColor: theme.palette.grey.main },
    //         "&:hover:not(.Mui-disabled, .Mui-error):before": {
    //           borderBottomColor: theme.palette.grey.main,
    //         },
    //       },
    //     },
    //   },
    // },
  };
}
