import axios from "axios";
import React, { useEffect, useState } from "react";
import helper from "utils/helper";
import { Button } from "@mui/material";

//component in project
import AlertModal from "components/modal/AlertModal";
const User = () => {
  const [data, setData] = useState([]);
  const [openModalCreateAdmin, setOpenModalCreateAdmin] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  useEffect(() => {
    // TestApi();
  }, []);

  console.log(data);
  // const TestApi = async () => {
  //   let api = await helper.setApi();

  //   let data = await api.get("/api/user/12", {
  //     method: "GET",
  //     headers: {
  //       Authorization:
  //         "Bearer " +
  //         "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluMDEiLCJhZG1pbl9pZCI6NiwiaWF0IjoxNjg3NDA3NzE0LCJleHAiOjE3MTg5NDM3MTR9.k0yTtXM242AOedW7rdsrFasAVcj1YhAfjyo6hU2CnZ4",
  //     },
  //   });

  //   // const covertData = data.json();
  //   setData(data.data);
  // };
  return <React.Fragment></React.Fragment>;
};

export default User;
