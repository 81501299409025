import instance from "../../axiosInstance";
import helper from "utils/helper";

export const downloadExcelTruckFreight = async (truck_type_id = null) => {
  try {
    return await instance.get(
      `/api/master/truck-type/download-excel/${truck_type_id}`,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const uploadTruckFreightFromExcel = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/master/truck-type/upload-truck-freight-from-excel`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
