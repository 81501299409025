import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import "./input.css";
import "./daterange.css";

const InputDateRangeCalendar = (props) => {
  const { value, onChange } = props;
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  useEffect(() => {
    if (value) {
      setState([value]);
    }
  }, [value]);
  return (
    <div className="inputDateRangeCalendar">
      <div style={{ marginTop: 40, marginBottom: 40 }}>
        <DateRangePicker
          onChange={(item) => {
            // console.log(item);
            // setState(item.selection);
            onChange(item.selection);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="vertical"
          editableDateInputs={true}
          // disabledDates={[new Date()]}
          minDate={new Date()}
        />
      </div>
    </div>
  );
};

export default InputDateRangeCalendar;
