import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { UploadFile } from "@mui/icons-material";
import FormModal from "components/modal/FormModal";
import FieldInput from "components/field/FieldInput";
import AlertModal from "components/modal/AlertModal";
import FieldUpload from "components/field/FieldUpload";
import TemplateForm from "components/templateForm/TemplateForm";
import Button from "components/button/Button";
import helper from "utils/helper";
import { ProfileType } from "utils/enum";
import {
  getAllCountry,
  getAllProvince,
  getAllZone,
  getDistrict,
  getSubDistrict,
} from "utils/api/masterData/gis";
import { createProfileReceiptName } from "utils/api/profile/profileReceiptName";

const CreateProfileReceiptNameModal = ({
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const last_current_form = 2;
  const { user_id, profile_id } = useParams();
  const form_confim_btn = useRef(null);

  const [currentForm, setCurrentForm] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [allCountry, setAllCountry] = useState([]);
  const [allZone, setAllZone] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allDistrict, setAllDistrict] = useState([]);
  const [allSubDistrict, setAllSubDistrict] = useState([]);
  const [removeValidate, setRemoveValidate] = useState({
    tax_id: false,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    setValue("country.id", 1);
    handleGetCountry();
    handleGetZone();
    handleGetProvince();

    // eslint-disable-next-line
  }, []);

  const handleGetCountry = async () => {
    try {
      const resp = await getAllCountry();
      setAllCountry(resp.data.data.results);
    } catch (error) {
      console.log("error handleGetCountry: ", error);
    }
  };

  const handleGetZone = async () => {
    try {
      const resp = await getAllZone();
      setAllZone(resp.data.data.results);
    } catch (error) {
      console.log("error getAllProvince: ", error);
    }
  };

  const handleGetProvince = async () => {
    try {
      const resp = await getAllProvince();
      setAllProvince(resp.data.data.results);
    } catch (error) {
      console.log("error getAllProvince: ", error);
    }
  };

  const handleGetDistrict = async (province_id) => {
    try {
      const resp = await getDistrict(province_id);
      setAllDistrict(resp.data.data.results);
    } catch (error) {
      console.log("error handleGetDistrict: ", error);
    }
  };

  const handleGetSubDistrict = async (district_id) => {
    try {
      const resp = await getSubDistrict(district_id);
      setAllSubDistrict(resp.data.data.results);
    } catch (error) {
      console.log("error handleGetSubDistrict: ", error);
    }
  };

  const handleChangeAddress = (field, field_id) => {
    if (field === "province") {
      const selected_province = allProvince.find(
        (item) => item.id === field_id
      );
      const selected_zone = allZone.find(
        (item) => item.id === selected_province.zone_id
      );
      handleGetDistrict(field_id);

      setValue("zone", {
        id: selected_zone.id,
        name: selected_zone.name,
      });
      setValue("province", {
        id: selected_province.id,
        name: selected_province.name,
      });
      setValue("district", {
        id: null,
        name: "",
      });
      setValue("subdistrict", {
        id: null,
        name: "",
        postal_code: "",
        special_active: false,
      });
    } else if (field === "district") {
      const selected_district = allDistrict.find(
        (item) => item.id === field_id
      );
      handleGetSubDistrict(field_id);

      setValue("district", {
        id: selected_district.id,
        name: selected_district.name,
      });
      setValue("subdistrict", {
        id: null,
        name: "",
        postal_code: "",
        special_active: false,
      });
    } else if (field === "subdistrict") {
      const selected_subdistrict = allSubDistrict.find(
        (item) => item.id === field_id
      );
      setValue("subdistrict", {
        id: selected_subdistrict.id,
        name: selected_subdistrict.name,
        postal_code: selected_subdistrict.postal_code,
        special_active: selected_subdistrict.special_active,
      });
    }
  };

  const handleRemoveValidate = (logic_field, value) => {
    if (logic_field === "profile_type") {
      const profile_type_info = ProfileType.find(
        (item) => item.value === value
      );
      const is_remove_validate =
        profile_type_info.name === "บุคคลธรรมดา" ? true : false;
      setRemoveValidate({ ...removeValidate, tax_id: is_remove_validate });
      if (is_remove_validate === true) clearErrors("tax_id");
    }
    if (logic_field === "tax_id") {
      const profile_type_info = ProfileType.find(
        (item) => item.value === getValues().profile_type
      );
      if (profile_type_info.name === "บุคคลธรรมดา") {
        if (R.isEmpty(value)) {
          setRemoveValidate({ ...removeValidate, tax_id: true });
          clearErrors("tax_id");
        } else {
          setRemoveValidate({ ...removeValidate, tax_id: false });
        }
      } else {
        setRemoveValidate({ ...removeValidate, tax_id: false });
      }
    }
  };

  const renderForm1 = () => {
    const form_menu = [
      {
        name: "profile_type",
        field_type: "select",
        field_label: "ประเภทผู้ที่รับใบเสร็จ*",
        onChange: (value) => {
          handleRemoveValidate("profile_type", value);
        },
        rules: {
          required: "กรุณาใส่ประเภทผู้ที่รับใบเสร็จ",
        },
        options: ProfileType.map((profile_type) => {
          return { value: profile_type.value, name: profile_type.name };
        }),
      },
      {
        name: "company_name",
        field_type: "text",
        field_label: "ชื่อผู้รับใบเสร็จ*",
        rules: {
          required: "กรุณาใส่ชื่อผู้รับใบเสร็จ",
        },
      },
      {
        name: "branch_code",
        field_type: "text",
        field_label: "รหัสสาขา*",
        rules: {
          required: "กรุณาใส่รหัสสาขา",
        },
      },
      {
        name: "tax_id",
        field_type: "text",
        field_label: "เลขประจำตัวผู้เสียภาษี*",
        onBlur: (e) => {
          handleRemoveValidate("tax_id", e.target.value);
        },
        rules: {
          required:
            removeValidate.tax_id === false
              ? "กรุณาใส่เลขประจำตัวผู้เสียภาษี"
              : null,
          pattern:
            removeValidate.tax_id === false ||
            (removeValidate.tax_id === true &&
              getValues().tax_id &&
              getValues().tax_id.length > 0)
              ? {
                  value: /^\d{13}$/,
                  message:
                    "กรุณาใส่เลขประจำตัวผู้เสียภาษีเป็นตัวเลข และ 13 ตัว",
                }
              : null,
        },
      },
      {
        name: "phone_number",
        field_type: "text",
        field_label: "เบอร์โทร*",
        rules: {
          required: "กรุณาใส่เบอร์โทร",
          pattern: {
            value: helper.RegexValidate(`phone`),
            message: "กรุณาใส่เบอร์โทรให้ถูกต้อง",
            // value: /^\d{9,10}$/,
            // message: "กรุณาใส่เลขประจำตัวผู้เสียภาษีเป็นตัวเลข และ 9-10 ตัว",
          },
        },
      },
    ];

    return currentForm === 1
      ? form_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
              trigger={trigger}
            />
          );
        })
      : null;
  };

  const renderForm2 = () => {
    const form_menu = [
      {
        name: "address",
        field_type: "text",
        field_label: "ที่อยู่*",
        rules: {
          required: "กรุณาใส่ที่อยู่",
        },
      },
      {
        name: "country.id",
        field_type: "select",
        field_label: "ประเทศ*",
        rules: {
          required: "กรุณาเลือกประเทศ",
        },
        options: allCountry.map((country) => {
          return { value: country.id, name: country.name };
        }),
        error: errors["country"] && errors["country"].id ? true : false,
        helperText:
          errors["country"] && errors["country"].id
            ? errors["country"]["id"].message
            : "",
      },
      {
        name: "province.id",
        field_type: "select",
        field_label: "จังหวัด*",
        rules: {
          required: "กรุณาเลือกจังหวัด",
        },
        options: R.isEmpty(allProvince)
          ? []
          : allProvince.map((province) => {
              return { value: province.id, name: province.name };
            }),
        onChange: (value) => {
          handleChangeAddress(`province`, value);
        },
        error: errors["province"] && errors["province"].id ? true : false,
        helperText:
          errors["province"] && errors["province"].id
            ? errors["province"]["id"].message
            : "",
      },

      {
        name: "district.id",
        field_type: "select",
        field_label: "เขต/อำเภอ*",
        rules: {
          required: "กรุณาเลือกเขต/อำเภอ",
        },
        disabled:
          getValues().province && !getValues().province.id ? true : false,
        options: R.isEmpty(allDistrict)
          ? []
          : allDistrict.map((district) => {
              return { value: district.id, name: district.name };
            }),
        onChange: (value) => {
          handleChangeAddress("district", value);
        },
        error: errors["district"] && errors["district"].id ? true : false,
        helperText:
          errors["district"] && errors["district"].id
            ? errors["district"]["id"].message
            : "",
      },
      {
        name: "subdistrict.id",
        field_type: "select",
        field_label: "แขวง/ตำบล*",
        rules: {
          required: "กรุณาเลือกแขวง/ตำบล",
        },
        disabled:
          getValues().district && !getValues().district.id ? true : false,
        options: R.isEmpty(allSubDistrict)
          ? []
          : allSubDistrict.map((subdistrict) => {
              return { value: subdistrict.id, name: subdistrict.name };
            }),
        onChange: (value) => {
          handleChangeAddress("subdistrict", value);
        },
        error: errors["subdistrict"] && errors["subdistrict"].id ? true : false,
        helperText:
          errors["subdistrict"] && errors["subdistrict"].id
            ? errors["subdistrict"]["id"].message
            : "",
      },
      {
        name: "subdistrict.postal_code",
        field_type: "text",
        field_label: "รหัสไปรษณีย์",
        nameDescription: "(ระบบจะดึงจากฐานข้อมูล)",
        disabled: true,
      },
      {
        name: "email",
        field_type: "text",
        field_label: "อีเมล*",
        rules: {
          required: "กรุณาใส่อีเมล",
          pattern: {
            value: helper.RegexValidate(`email`),
            message: "กรุณาใส่อีเมลให้ถูกต้อง",
          },
        },
      },
    ];

    return currentForm === 2
      ? form_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
              trigger={trigger}
            />
          );
        })
      : null;
  };

  const handleCreateProfileReceiptName = async () => {
    if (currentForm < last_current_form) {
      setCurrentForm(currentForm + 1);
    } else {
      setIsLoading(true);

      try {
        const data = getValues();
        const body = {
          profile_id: Number(profile_id),
          company_name: data.company_name,
          profile_type: data.profile_type,
          tax_id: data.tax_id,
          address: data.address,
          country_id: data.country.id,
          zone_id: data.zone.id,
          province_id: data.province.id,
          district_id: data.district.id,
          subdistrict_id: data.subdistrict.id,
          phone_number: data.phone_number,
          branch_code: data.branch_code,
          email: data.email,
          active: true,
        };

        const create_resp = await createProfileReceiptName(body);
        if (create_resp.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }

        setOpen(false);
      } catch (error) {
        if (typeof error === `string`) {
          setAlertModal({
            open: true,
            title: `บันทึกไม่สำเร็จ`,
            description: error,
          });
        } else {
          setAlertModal({
            open: true,
            title: `บันทึกไม่สำเร็จ`,
            description: error.response.data.description,
          });
        }
        setOpen(false);
      }
    }
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            setCurrentForm(1);
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  return (
    <>
      <FormModal
        title={`เพิ่มข้อมูลใบเสร็จ`}
        open={open}
        confirmTextBtn={currentForm === last_current_form ? "บันทึก" : "ถัดไป"}
        cancelTextBtn={currentForm > 1 ? "ย้อนกลับ" : "ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          if (currentForm === 1) {
            reset();
            setOpen(false);
          } else {
            setCurrentForm(currentForm - 1);
          }
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handleCreateProfileReceiptName();
          })}
        >
          {renderForm1()}
          {renderForm2()}

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateProfileReceiptNameModal;
