// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-location-add {
  opacity: 0.3;
}
.select-location-add:hover {
  opacity: 1;
}
.select-location-route {
  padding: 10px 0px 0px 10px;
  display: flex;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/work/selectLocation.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,0BAA0B;EAC1B,aAAa;EACb,eAAe;AACjB","sourcesContent":[".select-location-add {\n  opacity: 0.3;\n}\n.select-location-add:hover {\n  opacity: 1;\n}\n.select-location-route {\n  padding: 10px 0px 0px 10px;\n  display: flex;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
