export default function ListItemButton() {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
            padding: 0
        },
      },
    },
  };
}
