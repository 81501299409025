import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import {
  getServiceArea,
  updateServiceArea,
} from "utils/api/masterData/serviceArea";
import { getAllService } from "utils/api/masterData/service";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./ServiceArea.module.css";

const ServiceAreaInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [h2Text, setH2Text] = useState("");
  const [listService, setListService] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetServiceArea();

    // eslint-disable-next-line
  }, []);

  const handleGetServiceArea = async () => {
    setIsLoading(true);

    try {
      const service_params = {
        sort_field: ["created_at"],
        sort_order: [1],
      };
      const service_resp = await getAllService(service_params);
      const service_data = service_resp.data.data.results;
      setListService(service_data);

      const resp = await getServiceArea(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else if (key === "subdistrict_service") {
            let list_subdistrict_service = {};
            service_data.forEach((service) => {
              const find_subdistrict_service = data[key].find((item) => {
                return (
                  item.service.code === service.code &&
                  item.service.name === service.name
                );
              });

              if (!R.isNil(find_subdistrict_service)) {
                list_subdistrict_service = {
                  ...list_subdistrict_service,
                  [service.code]: find_subdistrict_service,
                };
              } else {
                list_subdistrict_service = {
                  ...list_subdistrict_service,
                  [service.code]: {
                    id: -1,
                    charge: 0,
                    service: service,
                  },
                };
              }
              setValue(key, list_subdistrict_service);
            });
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setH2Text(getValues().subdistrict);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getServiceArea: ", error);
      navigate("/master-data/service-area");
    }
  };

  const handleUpdateServiceArea = async () => {
    setIsLoading(true);

    try {
      let data = getValues();

      data = {
        ...data,
        active: data.active === `true` ? true : false,
        special_active: data.special_active === `true` ? true : false,
        subdistrict_service: Object.values(data.subdistrict_service).map(
          (item) => {
            return {
              service_id: item.service.id,
              charge: item.charge,
            };
          }
        ),
      };

      const update_resp = await updateServiceArea(id, data);
      if (update_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setIsLoading(false);
    } catch (error) {
      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกสถานะใช้งาน";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "country",
        field_type: "text",
        field_label: "ประเทศ",
        disabled: true,
      },
      {
        name: "province",
        field_type: "text",
        field_label: "จังหวัด",
        disabled: true,
      },
      {
        name: "district",
        field_type: "text",
        field_label: "อำเภอ",
        disabled: true,
      },
      {
        name: "subdistrict",
        field_type: "text",
        field_label: "ตำบล",
        disabled: true,
      },
      {
        name: "special_active",
        field_type: "select",
        field_label: "อำเภอพิเศษ*",
        rules: {
          validate: (value) => {
            if (R.isEmpty(value)) {
              return "กรุณาเลือกอำเภอพิเศษ";
            }
            return true;
          },
        },
        options: [
          { value: "true", name: `ใช่` },
          { value: "false", name: `ไม่ใช่` },
        ],
      },
    ];

    const subdistrict_service_form1 = listService.map((service) => {
      return {
        name: `subdistrict_service[${service.code}].charge`,
        field_type: "text",
        field_label: `ค่าใช้จ่ายเพิ่มเติม ${service.name}*`,
        nameDescription: `(ค่าเริ่มต้ม 0)`,
        rules: {
          required: `กรุณาใส่ค่าใช้จ่ายเพิ่มเติม ${service.name}`,
          pattern: {
            value: helper.RegexValidate(`number`),
            message: "ระบุเป็นตัวเลขได้เท่านั้น",
          },
        },
        error:
          errors &&
          errors["subdistrict_service"] &&
          errors["subdistrict_service"][`${service.code}`]
            ? true
            : false,
        helperText:
          errors &&
          errors["subdistrict_service"] &&
          errors["subdistrict_service"][`${service.code}`]
            ? errors["subdistrict_service"][`${service.code}`].charge.message
            : null,
      };
    });

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}

        {subdistrict_service_form1.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดพื้นที่ให้บริการ`}
        pathbackward={`/master-data/service-area`}
        menus={[
          {
            label: "รายละเอียดพื้นที่ให้บริการ",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "Term&Condition",
            path: null,
          },
          {
            label: getValues().province,
            path: null,
          },
          {
            label: getValues().district,
            path: null,
          },
          {
            label: getValues().subdistrict,
            path: null,
          },
          {
            label: "รายละเอียดพื้นที่ให้บริการ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <form
      onSubmit={handleSubmit(() => {
        handleUpdateServiceArea();
      })}
    >
      <Container>
        {renderSubMenu()}
        <div className={styles.main_container}>{renderColumn1()}</div>

        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />
      </Container>

      {renderModal()}
    </form>
  ) : (
    <Loading />
  );
};

export default ServiceAreaInfo;
