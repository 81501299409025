import instance from "../../axiosInstance";
import axios from "axios";
export const getAllFuelRate = async (params = {}) => {
  try {
    return await instance.get(`api/master/fuel-rate`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getFuelRate = async (id = null) => {
  try {
    return await instance.get(`api/master/fuel-rate/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createFuelRate = async (body = {}) => {
  try {
    return await instance.post(`api/master/fuel-rate/`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getFuelRateFormPTT = async () => {
  try {
    return await instance.get(`/api/master/fuel-rate/get-current-oil-price`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getFuelRateFormPTTByProvince = async (param) => {
  try {
    return await instance.get(
      `/api/master/fuel-rate/get-current-oil-price-provincial`,
      {
        params: param,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
