import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { FormHelperText, TextField } from "@mui/material";
import { DatePicker } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import "./FieldDatePicker.css";
import dayjs from "dayjs";

//value เป็น string
//ถ้า datatimepicker เป็น "YYYY-MM-DD HH:mm"
//ถ้า datapicker เป็น "YYYY-MM-DD"
const FieldDatePicker = ({
  value = "",
  label = "Select Date",
  subLabel,
  defaultValue = "",
  onChange = () => {},
  readOnly = false,
  showTime = false,
  error = false,
  helperText = "",
  style = {},
  className = "",
  closePaddingBottom = false,
  disabledDay = false,
}) => {
  const [dataMui, setDataMui] = useState("");
  const [dataAndt, setDataAndt] = useState("");
  const [isError, setIsError] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateFormat, setDateFormat] = useState("");

  useEffect(() => {
    if (isDatePickerOpen) {
      var button = document.querySelector(".ant-picker-header-super-prev-btn");
      var button2 = document.querySelector(".ant-picker-header-super-next-btn");
      button.remove();
      button2.remove();

      if (!showTime) {
        var timePanel = document.querySelector(".ant-picker-time-panel");
        timePanel.remove();
      }
    }
  }, [isDatePickerOpen]);

  useEffect(() => {
    const _dateFormat = showTime === false ? `YYYY-MM-DD` : `YYYY-MM-DD HH:mm`;
    setDateFormat(_dateFormat);
    if (value) {
      const _selectedDate = dayjs(value).format(_dateFormat);
      setDataMui(_selectedDate);
      setDataAndt(_selectedDate);
      onChange(_selectedDate);
    } else {
      setDataMui("");
    }
  }, [value]);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const andtHandleOnOK = (date) => {
    const result = dayjs(date, dateFormat).format(dateFormat);
    setIsDatePickerOpen(false);
    setIsError(false);
    setDataMui(result);
    setDataAndt(result);
    onChange(result);
  };

  const andthandleOnSelect = (date) => {
    const result = dayjs(date, dateFormat).format(dateFormat);
    setIsError(false);
    setDataMui(result);
    setDataAndt(result);
    onChange(result);
  };

  const muiHandleOnChange = (value) => {
    setDataMui(value);
    if (value === "") {
      setIsError(false);
    } else {
      if (dayjs(value, dateFormat).isValid()) {
        setDataAndt(value);
        setIsError(false);
      } else {
        setDataAndt("");
        setIsError(true);
      }
    }
  };

  const muiHandleOnBlur = (value) => {
    if (!showTime) {
      setIsDatePickerOpen(false);
      if (value === "") {
        setDataMui(value);
        setDataAndt("");
        onChange("");
        setIsError(false);
      } else {
        const result = dayjs(value).format(dateFormat);
        setDataMui(value);
        setDataAndt(result);
        onChange(result);
        if (dayjs(value, dateFormat).isValid()) {
          setIsError(false);
        } else {
          setIsError(true);
        }
      }
    }
  };

  return (
    <div
      className="FieldDatePicker"
      style={{
        pointerEvents: readOnly ? "none" : "",
        paddingBottom: closePaddingBottom ? 0 : readOnly && !error ? "23px" : 0,
      }}
    >
      <TextField
        className={className}
        // id="standard-basic"
        disabled={readOnly}
        label={
          label && subLabel ? (
            <div style={{ paddingTop: -5 }}>
              <div
                style={{
                  display: "block",
                  marginBottom: "-5px",
                  // marginTop: "-5px",
                  marginTop: value ? "-5px" : 0,
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                {label}
              </div>
              <div
                style={{
                  fontSize: "10px",
                  display: "block",
                  lineHeight: 1,
                  margin: 0,
                }}
              >
                {subLabel}
              </div>
            </div>
          ) : (
            label
          )
        }
        value={dataMui}
        onChange={(e) => muiHandleOnChange(e.target.value)}
        onFocus={() => setIsDatePickerOpen(true)}
        onBlur={(e) => muiHandleOnBlur(e.target.value)}
        autoComplete="off"
        variant="standard"
        sx={{
          bgcolor: readOnly ? "rgba(140, 163, 186, 0.20)" : "white",
          // width: "-webkit-fill-available",
          "& .MuiInputBase-input": {
            padding: "4px 14px 5px 14px",
          },
          "& .MuiFormLabel-root[data-shrink=true]": {
            padding: "0 14px",
            marginTop: "0",
          },
          "& .MuiFormLabel-root[data-shrink=false]": {
            padding: "0 14px",
            marginTop: subLabel ? "-10px" : "-5px",
          },
          "& .MuiFormHelperText-root": {
            padding: "0 14px",
          },
          paddingBottom:
            error || readOnly || closePaddingBottom || isError ? 0 : "23px",
          ...style,
        }}
        error={isError === false ? error : true}
        helperText={
          isError ? "Incorrect Date Format." : error ? helperText : ""
        }
        InputProps={{
          // readOnly: readOnly,
          endAdornment: (
            <InputAdornment position="end">
              {isDatePickerOpen && (
                <DatePicker
                  popupClassName="FieldDatePicker-DatePicker"
                  value={
                    dayjs(dataAndt, dateFormat).isValid()
                      ? dayjs(dataAndt, dateFormat)
                      : dayjs()
                  }
                  format={dateFormat}
                  disabledDate={disabledDay ? disabledDate : false}
                  onOk={andtHandleOnOK}
                  disabled={readOnly}
                  open
                  showTime={true}
                  onSelect={andthandleOnSelect}
                />
              )}
              <IconButton
                onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                className="FieldDatePicker-icon"
              >
                <CalendarOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {/* {error && helperText ? (
        <FormHelperText sx={{ color: "error.main", padding: "0 14px" }}>
          {helperText}
        </FormHelperText>
      ) : descriptionUnderField ? (
        <FormHelperText
          sx={{
            color: value ? "primary.main" : "grey.main",
            fontSize: "11px",
            padding: "0 14px",
          }}
        >
          {descriptionUnderField}
        </FormHelperText>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default FieldDatePicker;
