import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import "./SubMenuCustom.css";
import { Box, Breadcrumbs, Typography } from "@mui/material";

export default function SubMenuCustom({
  label = "",
  menus = [],
  breadcrumbs = [],
}) {
  const navigate = useNavigate();
  let activeLabel = label;
  for (const menu of menus) {
    if (menu.active === true) {
      activeLabel = menu.label;
      break;
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };
  const handleClose = (path) => {
    setAnchorEl(null);
    if (typeof path === "string") {
      navigate(path);
    }
  };

  const Arrow = () => {
    return (
      <>
        {anchorEl === null ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              d="M6.92727 12L-0.000938416 0L13.8555 0L6.92727 12Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              d="M6.92727 0L-0.000938416 12L13.8555 12L6.92727 0Z"
              fill="white"
            />
          </svg>
        )}
      </>
    );
  };

  const renderBreadcrumb = () => {
    return (
      <Box component="div" className="Breadcrumb-custom">
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          {/* <Link to="/">หน้าแรก</Link> */}
          {breadcrumbs.map((breadcrumb, index) => {
            const isLast = index === breadcrumbs.length - 1;

            return isLast || !breadcrumb.path ? (
              <span key={index}>{breadcrumb.label}</span>
            ) : (
              <Link to={breadcrumb.path} key={index}>
                {breadcrumb.label}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Box>
    );
  };

  const renderMenu = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Box component="div" className="SubMenu-custom" style={{ flex: 1 }}>
          <Button
            className="SubMenu-button-custom"
            id="SubMenu-button"
            aria-controls={open ? "SubMenu-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<Arrow />}
            style={{ zIndex: open ? 1301 : "unset" }}
          >
            {activeLabel}
          </Button>
        </Box>
        <div style={{ flex: 1 }}>
          <Menu
            className="SubMenu-menu-custom"
            id="SubMenu-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "SubMenu-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <li className="SubMenu-backward-custom">{`< ย้อนกลับ`}</li>
            {menus.map((menu, index) => (
              <section key={index}>
                {menu.header && (
                  <MenuItem className="SubMenu-header-custom" disabled={true}>
                    <span>{menu.header}</span>
                  </MenuItem>
                )}
                <MenuItem
                  className="SubMenu-item-custom"
                  disabled={menu.disabled}
                  onClick={(e) => handleClose(menu.path)}
                >
                  <span>{menu.label}</span>
                </MenuItem>
              </section>
            ))}
          </Menu>
        </div>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", marginBottom: "4px" }}>
      {menus.length > 1 && renderMenu()}
      {breadcrumbs.length > 1 && renderBreadcrumb()}
    </div>
  );
}
