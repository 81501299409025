// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin_modalStyle__rUTUY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* min-height: 800px; */
  height: 80%;
  width: 80%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  /* border: 2px solid #000; */
}

.admin_modalStyle__rUTUY :focus-visible {
  outline: none;
}

.admin_leftBox__XlvYJ {
  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);
  border-radius: 20px;
  padding: 48px 25px 25px 25px;
  flex-direction: column;
  display: flex;
}

.admin_rightBox__qgm99 {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* .modalStyle thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root {
  height: 50px !important;
  background-color: var(--mui-palette-secondary-main) !important;
}
.modalStyle th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
  color: white;
}
.modalStyle tr.MuiTableRow-root.css-b4xrc3-MuiTableRow-root {
  border-bottom: none;
} */

.admin_listPermission__noPhR {
  padding: 15px;
  /* color: var(--mui-palette-grey-main); */
  font-size: 16px;
  font-weight: 500;
}

.admin_headPermission__9uH6G {
  padding: 0;
  color: white;
  font-size: 16px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/admin.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,uBAAuB;EACvB,WAAW;EACX,UAAU;EACV,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oDAAoD;EACpD,mBAAmB;EACnB,4BAA4B;EAC5B,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;;;;;;;;;GASG;;AAEH;EACE,aAAa;EACb,yCAAyC;EACzC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".modalStyle {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  /* min-height: 800px; */\n  height: 80%;\n  width: 80%;\n  background-color: white;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: row;\n  overflow: auto;\n  /* border: 2px solid #000; */\n}\n\n.modalStyle :focus-visible {\n  outline: none;\n}\n\n.leftBox {\n  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);\n  border-radius: 20px;\n  padding: 48px 25px 25px 25px;\n  flex-direction: column;\n  display: flex;\n}\n\n.rightBox {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n/* .modalStyle thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root {\n  height: 50px !important;\n  background-color: var(--mui-palette-secondary-main) !important;\n}\n.modalStyle th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {\n  color: white;\n}\n.modalStyle tr.MuiTableRow-root.css-b4xrc3-MuiTableRow-root {\n  border-bottom: none;\n} */\n\n.listPermission {\n  padding: 15px;\n  /* color: var(--mui-palette-grey-main); */\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.headPermission {\n  padding: 0;\n  color: white;\n  font-size: 16px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalStyle": `admin_modalStyle__rUTUY`,
	"leftBox": `admin_leftBox__XlvYJ`,
	"rightBox": `admin_rightBox__qgm99`,
	"listPermission": `admin_listPermission__noPhR`,
	"headPermission": `admin_headPermission__9uH6G`
};
export default ___CSS_LOADER_EXPORT___;
