import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import FieldInput from "components/field/FieldInput";
import FieldUpload from "components/field/FieldUpload";
import FieldSelect from "components/field/FieldSelect";
import FieldDatePicker from "components/field/FieldDatePicker";
import {
  getCarrierInFavoriteGroup,
  updateCarrierInFavoriteGroup,
} from "utils/api/profile/carrierInFavoriteGroup";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import TemplateForm from "components/templateForm/TemplateForm";
import { getUserById } from "utils/api/user/user";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import helper from "utils/helper";
import styles from "./CarrierInFavoriteGroupInfo.module.css";
import { getShipperFavoriteGroup } from "utils/api/profile/shipperFavoriteGroup";

const CarrierInFavoriteGroupInfo = () => {
  const {
    user_id,
    profile_id,
    id,
    shipper_favorite_group_id,
    carrier_in_favorite_group_id,
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [userInfo, setUserInfo] = useState({});
  const [groupInfo, setGroupInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetCarrierInFavGroup();
    handleGetFavShipperGroup();
    handleGetUserById();

    // eslint-disable-next-line
  }, []);

  const handleGetCarrierInFavGroup = async () => {
    setIsLoading(true);
    try {
      const resp = await getCarrierInFavoriteGroup(
        carrier_in_favorite_group_id
      );
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getCarrierInFavoriteGroup: ", error);
      navigate(-1);
    }
  };

  const handleGetFavShipperGroup = async () => {
    setIsLoading(true);
    try {
      const resp = await getShipperFavoriteGroup(shipper_favorite_group_id);
      const data = resp.data.data.results;
      setGroupInfo(data);
    } catch (error) {
      setIsLoading(false);
      console.log("error getShipperFavoriteGroup: ", error);
    }
  };

  const handleGetUserById = async () => {
    try {
      const resp = await getUserById(user_id);
      if (resp.data.code === 1000) {
        setUserInfo(resp.data.data);
      }
    } catch (error) {
      console.log("error getUserById: ", error);
    }
  };

  const handleUpdateCarrierInFavGroup = async () => {
    setIsLoading(true);
    try {
      const body = {
        active: getValues().active === `true` ? true : false,
      };

      await updateCarrierInFavoriteGroup(
        carrier_in_favorite_group_id,
        body
      ).then((update_resp) => {
        if (update_resp.data.code === 1000) {
          setAlertModal({
            open: true,
            title: `บันทึก`,
            description: `บันทึกข้อมูลสำเร็จ`,
          });
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log("error,", error);

      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setIsLoading(false);
    }
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "profile.company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{getValues().version}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    const segments = location.pathname.split("/");
    const updatedUrl = segments.slice(0, -1).join("/");

    return (
      <SubMenu
        label={`รายละเอียดสมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ`}
        // pathbackward={-1}
        pathbackward={updatedUrl}
        menus={[
          {
            label: "รายละเอียดสมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label:
              !R.isNil(userInfo) && !R.isEmpty(userInfo)
                ? userInfo.user_code
                : ``,
            path: null,
          },
          {
            label: `กลุ่มผู้ให้บริการที่ชื่นชอบ`,
            path: null,
          },
          {
            label:
              !R.isNil(groupInfo) && !R.isEmpty(groupInfo)
                ? groupInfo.name
                : ``,
            path: null,
          },
          {
            label: "สมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: null,
          },
          {
            label:
              !R.isNil(userInfo) && !R.isEmpty(userInfo)
                ? userInfo.user_code
                : ``,
            path: null,
          },
          {
            label: "รายละเอียดสมาชิกกลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateCarrierInFavGroup();
        })}
      >
        {renderColumn1()}

        {renderModal()}
        <FloatingButton
          button_type="save"
          onClick={() => {
            // updateTruckType();
          }}
        />

        {renderModal()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default CarrierInFavoriteGroupInfo;
