import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { getAllTruckInProfile } from "utils/api/truck/truck";
import Pagination from "components/pagination/Pagination";
import FieldSearch from "components/field/FieldSearch";
import Button from "components/button/Button";
import styles from "./SelectTruckInProfile.module.css";

const SelectTruckInProfile = ({
  profile_id = null,
  onSelect = () => {},
  truck_info = {},
  exception_truck_id = [],
}) => {
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `license_plate`,
    search_val: ``,
    // has_tail: `false`,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [allTruckInProfile, setAllTruckInProfile] = useState([]);
  const [filter, setFilter] = useState(initial_filter);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    handleGetAllTruckInProfile({ update_pagination: true });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleGetAllTruckInProfile({
      update_pagination: false,
    });

    // eslint-disable-next-line
  }, [pagination]);

  const handleGetAllTruckInProfile = async ({
    update_pagination = true,
    back_to_first_page = false,
  }) => {
    setIsLoading(true);

    try {
      if (!R.isNil(profile_id)) {
        const params = {
          start_date:
            !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
              ? new Date(filter.start_date).toISOString()
              : null,
          end_date:
            !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
              ? new Date(filter.end_date).toISOString()
              : null,
          sort_field: filter.sort_field,
          sort_order: filter.sort_order,
          page: pagination.page,
          per_page: pagination.per_page,
          has_tail: true,
          [filter.search_field]: filter.search_val,
          same_truck_type_id:
            !R.isEmpty(truck_info) && !R.isEmpty(truck_info.truck_type)
              ? [truck_info.truck_type.id]
              : null,
          exception_truck_id:
            !R.isEmpty(truck_info) && !R.isEmpty(truck_info.id)
              ? [truck_info.id, ...exception_truck_id]
              : null,
        };

        const resp = await getAllTruckInProfile(profile_id, params);
        if (resp.data.code === 1000) {
          const data = resp.data.data.results;
          setAllTruckInProfile(data);

          if (update_pagination === true) {
            setPagination({
              ...pagination,
              total: resp.data.data.total,
            });
          }
          if (back_to_first_page === true) {
            setPagination({
              ...pagination,
              page: 1,
              total: resp.data.data.total,
            });
          }
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log("error handleGetAllTruckInProfile:", error);
      setIsLoading(false);
    }
  };

  const renderSearch = () => {
    return (
      <div className={styles.searchContainer}>
        <FieldSearch
          onChange={(value) => {
            setFilter({ ...filter, search_val: value });
          }}
          style={{
            width: `95%`,
          }}
        />
        <Button
          type={`button`}
          onClick={() => {
            handleGetAllTruckInProfile({ back_to_first_page: true });
          }}
        >{`ค้นหา`}</Button>
      </div>
    );
  };

  const renderCard = () => {
    return (
      !R.isEmpty(allTruckInProfile) && (
        <div className={styles.listCardContainer}>
          {allTruckInProfile.map((truck) => {
            let total_insurance = 0;
            if (!R.isNil(truck.truck_insurance_head)) {
              total_insurance += truck.truck_insurance_head.cover_insurance;
            }
            if (!R.isNil(truck.truck_insurance_tail)) {
              total_insurance += truck.truck_insurance_tail.cover_insurance;
            }

            return (
              <div
                key={truck.id}
                className={styles.card}
                onClick={() => {
                  setSelectedData(truck);
                  onSelect(truck);
                }}
                style={{
                  boxShadow:
                    !R.isEmpty(selectedData) && selectedData.id === truck.id
                      ? `0px 10px 10px 0px lightgray`
                      : null,
                }}
              >
                <div className={styles.nameContainer}>
                  <p>{truck.truck_type.name}</p>
                  <p
                    style={{
                      marginLeft: 2,
                      fontWeight: `normal`,
                      color:
                        truck.document_approve_status === `รอการอนุมัติ`
                          ? `#8CA3BA`
                          : truck.document_approve_status === `อนุมัติแล้ว`
                          ? `#27CE88`
                          : truck.document_approve_status === `แก้ไขเพิ่มเติม`
                          ? `#0D19A3`
                          : `#FF316F`,
                    }}
                  >{`(${truck.document_approve_status})`}</p>
                </div>

                <div className={styles.licensePlateContainer}>
                  <p>
                    {`${truck.license_plate_head} ${
                      !R.isNil(truck.license_plate_tail) &&
                      !R.isEmpty(truck.license_plate_tail)
                        ? ` / ${truck.license_plate_tail}`
                        : ``
                    }`}
                  </p>
                </div>

                <div className={styles.insuranceContainer}>
                  <p>{`ทุนประกันสินค้ารวม ${total_insurance} บาท`}</p>
                </div>
              </div>
            );
          })}
        </div>
      )
    );
  };

  const renderPagination = () => {
    return <Pagination pagination={pagination} setPagination={setPagination} />;
  };

  return (
    <div className={styles.container}>
      {renderSearch()}
      {isLoading === false ? (
        <>
          {renderCard()}
          {renderPagination()}
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default SelectTruckInProfile;
