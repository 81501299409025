import helper from "utils/helper";
import instance from "../../axiosInstance";

const ProductType = async (params = {}) => {
  try {
    return await instance.get(`api/master/product-type`, {
      params: params,
      headers: {
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const getProductType = async (id = null) => {
  try {
    return await instance.get(`api/master/product-type/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createProductType = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(`api/master/product-type/`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const updateProductType = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.patch(`api/master/product-type/${id}`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const updateOrderProductType = async (body = {}) => {
  try {
    return await instance.patch(
      `api/master/product-type/order-product-type`,
      body,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const masterProduct = {
  ProductType,
  getProductType,
  createProductType,
  updateProductType,
  updateOrderProductType,
};

export default masterProduct;
