import instance from "../../axiosInstance";

const getAllFavoritePlace = async (profile_id, param) => {
  try {
    return instance.get(`/api/profile/all-favorite-place/${profile_id}`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const addFavoritePlace = async (profile_id, data) => {
  try {
    return instance.post(`/api/profile/favorite-place/${profile_id}`, data, {
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getFavoritePlaceId = async (favorite_place_id) => {
  try {
    return instance.get(`/api/profile/favorite-place/${favorite_place_id}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editFavoritePlace = async (favorite_place_id, data) => {
  try {
    return instance.patch(
      `/api/profile/favorite-place/${favorite_place_id}`,
      data,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getAllFavoritePlace,
  addFavoritePlace,
  getFavoritePlaceId,
  editFavoritePlace,
};
