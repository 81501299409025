// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-switch {
  display: flex;
}

.buttun-switch-option {
  flex: 1 1;
  padding: 10px;
  box-shadow: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
  display: flex;

  /* border-style: solid;
  border-width: 0.5px; */
}
.buttun-switch-option:hover {
  background-color: #8ca3ba;
}

.button-switch-otpion-active {
  border-bottom-style: solid;
  border-bottom-color: #121f43;
}

.button-switch-otpion-first {
  border-top-left-radius: 5px;
}

.button-switch-otpion-last {
  border-top-right-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/buttonSwitch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;EACP,aAAa;EACb,aAAa;EACb,2CAA2C;EAC3C,eAAe;EACf,aAAa;;EAEb;wBACsB;AACxB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".button-switch {\n  display: flex;\n}\n\n.buttun-switch-option {\n  flex: 1;\n  padding: 10px;\n  box-shadow: 1;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  cursor: pointer;\n  display: flex;\n\n  /* border-style: solid;\n  border-width: 0.5px; */\n}\n.buttun-switch-option:hover {\n  background-color: #8ca3ba;\n}\n\n.button-switch-otpion-active {\n  border-bottom-style: solid;\n  border-bottom-color: #121f43;\n}\n\n.button-switch-otpion-first {\n  border-top-left-radius: 5px;\n}\n\n.button-switch-otpion-last {\n  border-top-right-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
