// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RouteOptimize_main_container__s9Esd {
  padding-right: 50px;
}

.RouteOptimize_route_optimize_container__C6QR7 {
  display: flex;
  gap: 30px 20px;
}

@media screen and (max-width: 1000px) {
  .RouteOptimize_main_container__s9Esd {
    padding-left: 97px;
    padding-right: 97px;
  }
  .RouteOptimize_route_optimize_container__C6QR7 {
    flex-flow: column wrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/work/RouteOptimize.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".main_container {\n  padding-right: 50px;\n}\n\n.route_optimize_container {\n  display: flex;\n  gap: 30px 20px;\n}\n\n@media screen and (max-width: 1000px) {\n  .main_container {\n    padding-left: 97px;\n    padding-right: 97px;\n  }\n  .route_optimize_container {\n    flex-flow: column wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_container": `RouteOptimize_main_container__s9Esd`,
	"route_optimize_container": `RouteOptimize_route_optimize_container__C6QR7`
};
export default ___CSS_LOADER_EXPORT___;
