import React, { useEffect, useRef, useState } from "react";
import { FormControl, Input, InputLabel, TextField } from "@mui/material";
import "./buttonSwitch.css";
const ButtonSwitch = (props) => {
  const { option = [], onClick } = props;
  return (
    <div className="button-switch">
      {option.map((ele, index) => {
        return (
          <div
            key={index}
            className={
              "buttun-switch-option " +
              (index === 0
                ? "button-switch-otpion-first "
                : index + 1 === option.length
                ? "button-switch-otpion-last "
                : " ") +
              (ele.active ? "button-switch-otpion-active " : "")
            }
            onClick={() => {
              if (!ele.active) {
                onClick(index);
              }
            }}
          >
            <div>
              <img
                src={ele.icon}
                alt={ele.title}
                style={{ width: 30, objectFit: "contain" }}
              />
            </div>
            <div style={{ flex: 1, paddingLeft: 10 }}>
              <div style={{ fontSize: 14 }}>{ele.title}</div>
              <div style={{ fontSize: 10, opacity: ele.active ? 1 : 0.3 }}>
                {ele.subtitle}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ButtonSwitch;
