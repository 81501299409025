import React, { useEffect, useRef, useState } from "react";
import { FormControl, Input, InputLabel, TextField } from "@mui/material";
import "./inputDisable.css";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Avatar from "@mui/material/Avatar";
const EditButton = ({ onClick = () => {} }) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 10,
        right: 10,
        cursor: "pointer",
        // backgroundColor: "#121f43",
        // padding: 20,
        // borderRadius: 50,
      }}
      onClick={() => onClick()}
    >
      <Avatar sx={{ bgcolor: "#121f43", padding: 6 }}>
        <EditRoundedIcon sx={{ fontSize: 50, color: "white" }} />
      </Avatar>
    </div>
  );
};

export default EditButton;
