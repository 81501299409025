import instance from "../../axiosInstance";

export const getAllParticipateProject = async (
  profile_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `/api/project/profile-carrier-in-project/participate-project/${profile_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getParticipateProject = async (
  participate_project_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `/api/project/profile-carrier-in-project/participate-project/detail/${participate_project_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllFuelRateQuotation = async (
  project_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `/api/project/profile-carrier-in-project/participate-project/fuel-rate-quotation/${project_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getFuelRateQuotation = async (fuel_rate_quotation_id = null) => {
  try {
    return await instance.get(
      `/api/project/profile-carrier-in-project/participate-project/fuel-rate-quotation/detail/${fuel_rate_quotation_id}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
