import instance from "../../axiosInstance";

//MASTER DATA
const getMenuPermission = async (param) => {
  try {
    return instance.get(`/api/master/menu-permission`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const createAdmin = async (data) => {
  try {
    return instance.post(`/api/admin`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllAdmin = async (param) => {
  try {
    return instance.get(`/api/admin`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAdminById = async (id) => {
  try {
    return instance.get(`/api/admin/${id}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editAdmin = async (id, data) => {
  try {
    return instance.patch(`/api/admin/${id}`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAdminLoggedIn = async (id) => {
  try {
    return instance.get(`/api/logged-in`);
  } catch (error) {
    console.log("error", error);
  }
};

export {
  getMenuPermission,
  createAdmin,
  getAllAdmin,
  getAdminById,
  editAdmin,
  getAdminLoggedIn,
};
