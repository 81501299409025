import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "assets/image/WemoveLogo.svg";
import CloseDrawer from "assets/icons/closeDrawer.svg";
import {
  Person,
  HomeSharp,
  VerifiedUserSharp,
  PostAdd,
  DescriptionRounded,
  AdminPanelSettingsSharp,
  PinDropRounded,
  AccountBalanceRounded,
  AccountCircle,
  ExpandLess,
  ExpandMore,
  CurrencyExchangeOutlined,
} from "@mui/icons-material";
import { FaTruckLoading, FaUserTie } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import {
  BsFillBoxSeamFill,
  BsDatabaseFillGear,
  BsFillFuelPumpFill,
  BsCurrencyExchange,
} from "react-icons/bs";
import { HiPresentationChartLine } from "react-icons/hi";
import { GiSellCard } from "react-icons/gi";
import { SiDocsdotrs } from "react-icons/si";
import { IoStorefrontSharp } from "react-icons/io5";
import { useTheme, Typography, Collapse } from "@mui/material";
import { AdminRole } from "utils/enum";
import { useCookies } from "react-cookie";
import API from "utils/api/auth/auth";
import { useNavigate, useLocation } from "react-router-dom";
const api = API.create();

export default function DrawerContent(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [state, setState] = React.useState({
    left: false,
  });
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [openTransport, setOpenTransport] = React.useState(false);
  const [openTrade, setOpenTrade] = React.useState(false);

  const getColor = (path) => {
    return location.pathname === path
      ? theme.palette.primary.main
      : theme.palette.grey.main;
  };

  const [role, setRole] = React.useState("");
  React.useEffect(() => {
    console.log("props", props);
  }, [props]);
  React.useEffect(() => {
    if (props?.admin_info) {
      const admin_role = AdminRole?.find(
        (role) => props?.admin_info?.admin_role_type == role.value
      );
      console.log("admin_role", admin_role);
      setRole(admin_role?.name);
    }
  }, [props?.admin_info, AdminRole]);

  const handleTransport = () => {
    setOpenTransport(!openTransport);
  };
  const handleTrade = () => {
    setOpenTrade(!openTrade);
  };
  const handleLogout = async () => {
    await api
      .logout()
      .then((res) => {
        removeAccessToken("access_token");
        navigate("/login");
      })
      .catch((e) => console.log(e));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 270 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemButton href="/" style={{}}>
            <ListItemIcon size="medium" style={{ padding: "5px" }}>
              <HomeSharp fontSize="large" sx={{ color: getColor("/") }} />
            </ListItemIcon>
            <ListItemText sx={{ color: getColor("/") }}>หน้าแรก</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ display: "flex", flexDirection: "column" }}>
          <ListItemButton onClick={handleTransport} sx={{ width: "100% " }}>
            <ListItemIcon size="medium" style={{ padding: "5px" }}>
              <FaTruckLoading
                fontSize="30px"
                style={{ color: theme.palette.grey.main }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: "grey.main" }}>
              จัดการงานขนส่ง
            </ListItemText>
            {openTransport ? (
              <ExpandLess color={"grey.main"} />
            ) : (
              <ExpandMore color={"grey.main"} />
            )}
          </ListItemButton>
          <Collapse
            in={openTransport}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/user">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <Person
                      fontSize="large"
                      sx={{ color: getColor("/user") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/user") }}>
                    ข้อมูลผู้ใช้งาน
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/project">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <DescriptionRounded
                      fontSize="large"
                      sx={{ color: getColor("/project") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/project") }}>
                    ข้อมูลโครงการ
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/truck">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <FaTruckFast
                      fontSize="30px"
                      style={{ color: getColor("/truck") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/truck") }}>
                    ข้อมูลรถ
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <VerifiedUserSharp
                      fontSize="large"
                      sx={{ color: "grey.main" }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: "grey.main" }}>
                    ประกันภัยสินค้า
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/post-work">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <PostAdd
                      fontSize="large"
                      sx={{ color: getColor("/post-work") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/post-work") }}>
                    ประกาศงาน
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/work">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <BsFillBoxSeamFill
                      fontSize="30px"
                      style={{ color: getColor("/work") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/work") }}>
                    ข้อมูลงาน
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/tracking-driver">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <PinDropRounded
                      fontSize="large"
                      sx={{ color: getColor("/tracking-driver") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/tracking-driver") }}>
                    ติดตามคนขับทั้งหมด
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/finance/shipper/unpaid">
                  <ListItemIcon size="medium" style={{ padding: "5px" }}>
                    <AccountBalanceRounded
                      fontSize="large"
                      sx={{ color: getColor("/finance/shipper/unpaid") }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: getColor("/finance/shipper/unpaid") }}
                  >
                    ข้อมูลการเงิน
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
        <ListItem sx={{ display: "flex", flexDirection: "column" }}>
          <ListItemButton onClick={handleTrade} sx={{ width: "100% " }}>
            <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
              <GiSellCard
                fontSize="30px"
                style={{ color: theme.palette.grey.main }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: "grey.main" }}>
              จัดการการซื้อขาย
            </ListItemText>
            {openTrade ? (
              <ExpandLess color={"grey.main"} />
            ) : (
              <ExpandMore color={"grey.main"} />
            )}
          </ListItemButton>
          <Collapse
            in={openTrade}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <SiDocsdotrs
                      fontSize="30px"
                      style={{ color: getColor("/trade") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade") }}>
                    ทำรายการซื้อขาย
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade/customer">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <FaUserTie
                      fontSize="30px"
                      style={{ color: getColor("/trade/customer") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade/customer") }}>
                    รายชื่อผู้ซื้อ
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade/jobber">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <IoStorefrontSharp
                      fontSize="30px"
                      style={{ color: getColor("/trade/jobber") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade/jobber") }}>
                    รายชื่อผู้ขาย
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem sx={{ pl: 3 }}>
                <ListItemButton href="/trade-finance">
                  <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
                    <BsCurrencyExchange
                      fontSize="30px"
                      style={{ color: getColor("/trade-finance") }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: getColor("/trade-finance") }}>
                    ข้อมูลการเงินซื้อขาย
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
        </ListItem>
        <ListItem>
          <ListItemButton href="/dashboard/work">
            <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
              <HiPresentationChartLine
                fontSize="30px"
                style={{ color: getColor("/dashboard/work") }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: getColor("/dashboard/work") }}>
              แผนภูมิและกราฟ
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/admin">
            <ListItemIcon size="medium" style={{ padding: "5px" }}>
              <AdminPanelSettingsSharp
                fontSize="large"
                sx={{ color: getColor("/admin") }}
              />
            </ListItemIcon>
            <ListItemText sx={{ color: getColor("/admin") }}>
              จัดการผู้ดูแลระบบ
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton href="/master-data/term-and-condition">
            <ListItemIcon size="medium" style={{ padding: "5px 8px" }}>
              <BsDatabaseFillGear
                fontSize="30px"
                style={{ color: getColor("/master-data/term-and-condition") }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{ color: getColor("/master-data/term-and-condition") }}
            >
              จัดการ Master Data
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="left"
          open={props.openDrawer}
          onClose={() => {
            props.onClose();
          }}
        >
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={Logo} height={"37px"} alt="logo wemove platform" />
            <img
              src={CloseDrawer}
              height={"30px"}
              onClick={() => {
                props.onClose();
              }}
              alt=""
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", padding: "0 10px" }}
          >
            <div>
              {props?.admin_info?.image ? (
                <img
                  src={`http://${props?.admin_info?.image}`}
                  width={"75px"}
                  height={"75px"}
                  alt="image profile"
                  style={{
                    objectFit: "cover",
                    borderRadius: "10px",
                    display: "block",
                    margin: "auto",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: "75px",
                    height: "75px",
                    backgroundColor: `rgba(140, 163, 186, 0.2)`,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <AccountCircle
                    sx={{
                      fontSize: 48,
                      color: "grey.main",
                    }}
                  />
                </div>
              )}
            </div>
            <div style={{ padding: "0 10px" }}>
              <Typography sx={{ fontWeight: "500" }}>
                {props?.admin_info?.firstname || ""}{" "}
                {props?.admin_info?.lastname || ""}
              </Typography>
              <Typography sx={{ fontWeight: "300" }}>
                {props?.admin_info?.username || ""}
              </Typography>
              <Typography sx={{ fontWeight: "400", color: "grey.main" }}>
                {role || ""}
              </Typography>
            </div>
          </div>
          {list("left")}
          <List
            sx={{ marginTop: "auto", display: "flex", alignItems: "flex-end" }}
          >
            <ListItem>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemText
                  sx={{
                    color: "grey.main",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  ออกจากระบบ
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
