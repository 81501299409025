import instance from "utils/axiosInstance";

export const getUnpaid = async (params = {}) => {
  return await instance.get(`api/finance/tps/unpaid`, {
    params: params,
  });
};

export const getInvoice = async (params = {}) => {
  return await instance.get(`api/finance/tps/invoice`, {
    params: params,
  });
};

export const getInvoiceXlsx = async (params = {}) => {
  return await instance.get(`api/finance/carrier/invoice/xlsx`, {
    params: params,
    responseType: "blob",
  });
};

export const getInvoiceGroup = async (params = {}) => {
  return await instance.get(`api/finance/tps/invoice-group`, {
    params: params,
  });
};

export const createInvoice = async (data = {}) => {
  const formData = new FormData();

  data.work_code.forEach((code, index) => {
    formData.append(`work_code[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("file", data.file);

  return await instance.post(`api/finance/tps/invoice`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cancelInvoice = async (invoiceCode) => {
  return await instance.post(`api/finance/tps/invoice/${invoiceCode}/cancel`);
};

export const createBillingNote = async (data) => {
  console.log({ data });
  const formData = new FormData();

  data.invoice_code.forEach((code, index) => {
    formData.append(`invoice_code[${index}]`, code);
  });

  formData.append("actual_date", data.actual_date);
  formData.append("file", data.file);

  return await instance.post(`api/finance/carrier/billingnote`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
