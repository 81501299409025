import instance from "../../axiosInstance";

export const getReceiptName = async (profileId) => {
  try {
    return await instance.get(`/api/master/profile-receipt-name/${profileId}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
