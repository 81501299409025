import axios from "axios";
import { withCookies, Cookies } from "react-cookie";
import helper from "utils/helper";
import qs from "qs";

const setAPI = async () => {
  // console.log(ENV.API_ENDPOINT_01);
  return axios.create({
    baseURL: process.env.REACT_APP_API_ENPOINT,
    timeout: 100000,
  });
};

const create = () => {
  const createKey = async (data) => {
    let api = await setAPI();
    return api.post("api/utils/create-third-party-key", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };
  const getKey = async (data) => {
    let api = await setAPI();
    let param = "?";
    param += helper.getParam(data);
    return api.get("api/utils/get-third-party-key" + param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const editKey = async (data, id) => {
    let api = await setAPI();
    return api.patch("api/utils/edit-third-party-key/" + id, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + helper.getCookie("access_token"),
      },
    });
  };

  const getKeyParam = async (type, param) => {
    let token = helper.getCookie("access_token");
    let auth_token = token ? token : process.env.REACT_APP_PUBLIC_TOKEN;

    let api = await setAPI();
    return api.get(
      "api/utils/get-third-party-key-param/" + type + "/" + param,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth_token,
        },
      }
    );
  };
  return {
    createKey,
    getKey,
    editKey,
    getKeyParam,
  };
};

export default { create };
