import React from "react";
import { Check, Clear } from "@mui/icons-material";
// import "./styles.css";
import styles from "./tableActiveIcon.module.css";

const TableActiveIcon = ({ active_status = false }) => {
  return (
    <div className={styles.tableActiveIconContainer}>
      <i
        className={`${styles.iconWrapper} ${
          active_status === true ? styles.activeColor : styles.inActiveColor
        }`}
      >
        {active_status === true ? (
          <Check fontSize="small" sx={{ color: "white" }} />
        ) : (
          <Clear fontSize="small" sx={{ color: "white" }} />
        )}
      </i>
    </div>
  );
};

export default TableActiveIcon;
