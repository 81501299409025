import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Container } from "@mui/material";
import * as R from "ramda";
// import io from "socket.io-client";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import FloatingButton from "components/button/FloatingButton";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import CustomModal from "components/modal/CustomModal";
import Button from "components/button/Button";
import helper from "utils/helper";
import styles from "./FuelRateQuotationInfo.module.css";
import { getUserById } from "utils/api/user/user";
import { getFuelRateQuotation } from "utils/api/project/profileCarrierInProject";
import {
  checkErrorFieldsInTemplateCarrierFrieghtFile,
  createTemplateCarrierFreight,
  downloadTemplateFuelRateQuotation,
  getTemplateCarrierFreightExcel,
  updateFuelRateQuotation,
  exportCarrierFreightExcel,
} from "utils/api/templateWork/template_quotation_excel";
import CustomLinearProgress from "components/feedback/CustomLinearProgress";
import TableCustom from "components/table/TableCustom";

const columns = [
  {
    id: "row_number",
    label: "ลำดับแถว",
    align: "center",
    format: (value) => {
      return value.row_number ? value.row_number : "-";
    },
  },
  {
    id: "col_letter",
    label: "คอลัมน์ที่ผิดพลาด",
    align: "center",
    format: (value) => {
      return value.col_letter ? value.col_letter : "-";
    },
  },
  {
    id: "message",
    label: "ข้อผิดพลาด",
    align: "center",
    format: (value) => {
      return value.message ? value.message : "-";
    },
  },
  {
    id: "code",
    label: "ERROR CODE",
    align: "center",
    format: (value) => {
      return value.code ? value.code : "-";
    },
  },
];

const FuelRateQuotationInfo = () => {
  const { user_id, fuel_rate_quotation_id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const [userInfo, setUserInfo] = useState({});
  const [h2Text, setH2Text] = useState(``);
  const [socketId, setSocketId] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [
    listErrorFieldsInTemplateCarrierFrieghtFile,
    setListErrorFieldsInTemplateCarrierFrieghtFile,
  ] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 6,
    total: 0,
  });
  const [disabledUploadBtn, setDisabledUploadBtn] = useState(true);
  const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
  const [disabledSeeFuelRateQuotation, setDisabledSeeFuelRateQuotation] =
    useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetFuelRateQuotation();
    handleGetUserInfo();
    handleConnectSockerIo();
    handleGetTemplateCarrierFreightExcel();

    // eslint-disable-next-line
  }, []);

  const handleGetTemplateCarrierFreightExcel = async () => {
    try {
      const resp = await getTemplateCarrierFreightExcel(fuel_rate_quotation_id);
      if (resp.status === 200) {
        const blob = new Blob([resp.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `carrier_freight_${fuel_rate_quotation_id}.xlsx`;
        setValue("template_work_file", downloadLink);
      }
    } catch (error) {
      console.log("error getTemplateCarrierFreightExcel: ", error);
    }
  };

  const handleGetFuelRateQuotation = async () => {
    setIsLoading(true);
    try {
      const resp = await getFuelRateQuotation(fuel_rate_quotation_id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });

        if (
          !R.isNil(data.template_carrier_freight) &&
          !R.isEmpty(data.template_carrier_freight)
        ) {
          setDisabledSeeFuelRateQuotation(false);
        } else {
          setDisabledSeeFuelRateQuotation(true);
        }
      }

      setH2Text(
        `${data.fuel_rate_start_shipper} - ${data.fuel_rate_end_shipper}`
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getFuelRateQuotation: ", error);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
      // navigate("/truck/truck-check-project");
    }
  };

  const handleGetUserInfo = async () => {
    try {
      const resp = await getUserById(user_id);
      setUserInfo(resp.data.data);
    } catch (error) {
      console.log("error handleGetUserInfo: ", error);
    }
  };

  const handleCheckErrorInFuelRateQuotationField = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    update_progress_bar = false,
  }) => {
    try {
      let body = {
        client_id: update_progress_bar === true ? socketId : null,
        project_fuel_rate_id: fuel_rate_quotation_id,
        // quotation_carrier_freight_file: getValues().template_work_file,
        quotation_carrier_freight_file: getValues().show_fuel_rate_quotation,
      };
      const params = {
        page: page,
        per_page: per_page,
      };

      const resp = await checkErrorFieldsInTemplateCarrierFrieghtFile(
        body,
        params
      );
      if (resp.data.code === 1000) {
        const data = resp.data.data.results;
        setListErrorFieldsInTemplateCarrierFrieghtFile(data);
        setPagination({
          page: page,
          per_page: per_page,
          total: resp.data.data.total,
        });

        if (R.isEmpty(data)) {
          setDisabledSaveBtn(false);
        }
      }
    } catch (error) {
      console.log("error handleCheckErrorInFuelRateQuotationField: ", error);
    }
  };

  const handleUpdateFuelRateQuotation = async () => {
    setIsLoading(true);
    try {
      let body = {
        // client_id: socketId,
        project_fuel_rate_id: fuel_rate_quotation_id,
        // quotation_carrier_freight_file: getValues().template_work_file,
        quotation_carrier_freight_file: getValues().show_fuel_rate_quotation,
        active: getValues().active === `true` ? true : false,
      };
      if (
        typeof body.quotation_carrier_freight_file === `string` ||
        (R.isNil(body.quotation_carrier_freight_file) &&
          R.isEmpty(body.quotation_carrier_freight_file))
      ) {
        body = R.omit(["quotation_carrier_freight_file"], body);
      }

      // const resp = await createTemplateCarrierFreight(body);
      await updateFuelRateQuotation(fuel_rate_quotation_id, body).then(
        (resp) => {
          if (resp.data.code === 1000) {
            setAlertModal({
              open: true,
              title: `บันทึก`,
              description: `บันทึกข้อมูลสำเร็จ`,
            });
            setDisabledSeeFuelRateQuotation(false);
            setValue("show_fuel_rate_quotation", null);
          }
        }
      );

      setIsLoading(false);
    } catch (error) {
      if (
        error.response.data.description &&
        error.response.data.description.message
      ) {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error.response.data.description.message,
        });
      } else {
        setAlertModal({
          open: true,
          title: `บันทึกไม่สำเร็จ`,
          description: error.response.data.description,
        });
      }
      setIsLoading(false);
    }
  };

  const handleConnectSockerIo = () => {
    try {
      const socket = helper.connectSocketio();
      socket.on("message", (data) => {
        setSocketId(socket.id);
      });
      socket.on("download", (data) => {
        setDownloadProgress(data);
      });
      // socket.disconnect();
    } catch (error) {
      console.log("error handleConnectSockerIo:", error);
    }
  };

  const handleDownloadTemplateFuelRateQuotation = async () => {
    try {
      const download_response = await downloadTemplateFuelRateQuotation(
        fuel_rate_quotation_id
      );

      helper.downloadFile({
        api_response: download_response,
      });
    } catch (error) {
      console.log("error handleDownloadTemplateFuelRateQuotation:", error);
      setAlertModal({
        open: true,
        title: "พบปัญหาขณะดาวน์โหลดแม่แบบใบเสนอราคา",
        description: error.message,
      });
    }
  };

  const handleExportCarrierFreightExcel = async () => {
    try {
      const resp = await exportCarrierFreightExcel(fuel_rate_quotation_id);
      if (resp.status === 200 && R.isEmpty(resp.data)) {
      } else if (resp.status === 200 && !R.isEmpty(resp.data)) {
        helper.downloadFile({
          api_response: resp,
        });
      }
    } catch (error) {
      console.log("error handleexportCarrierFreightExcel: ", error);
      setAlertModal({
        open: true,
        title: "พบปัญหาขณะดาวน์โหลดไฟล์ใบเสนอราคา",
        description: error.message,
      });
    }
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียดใบเสนอราคาตามอัตรานํ้ามัน`}
        pathbackward={location.pathname.split("/detail")[0]}
        menus={[
          {
            label: "รายละเอียดใบเสนอราคาตามอัตรานํ้ามัน",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label: userInfo ? userInfo.user_code : ``,
            path: null,
          },
          {
            label: "ข้อมูลโครงการที่เข้าร่วม",
            path: null,
          },
          {
            label:
              getValues().project && getValues().project.project_code
                ? getValues().project.project_code
                : ``,
            path: null,
          },
          {
            label: "ใบเสนอราคาตามอัตรานํ้ามัน",
            path: null,
          },
          {
            label:
              getValues().fuel_rate_start_shipper &&
              getValues().fuel_rate_end_shipper
                ? `${getValues().fuel_rate_start_shipper} - ${
                    getValues().fuel_rate_end_shipper
                  }`
                : ``,
            path: null,
          },
          {
            label: "รายละเอียดใบเสนอราคาตามอัตรานํ้ามัน",
            path: null,
          },
        ]}
      />
    );
  };

  const renderColumn1 = () => {
    const form1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน",
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        // name: "template_work_file",
        name: "show_fuel_rate_quotation",
        field_type: "upload",
        field_label: "ใบเสนอราคา*",
        rules: {
          validate: async (file) => {
            // if (typeof file !== "string") {
            if (!R.isNil(file) && !R.isNil(file.lastModified)) {
              if (!helper.isCsvOrXlsxfile({ file: file })) {
                return "อัพโหลดไฟล์นามสกุล CSV หรือ XLSX เท่านั้น และขนาดไม่เกิน 10 MB";
              }
              if (helper.isFileOverSize({ file: file, size: 10 })) {
                return "อัพโหลดไฟล์นามสกุล CSV หรือ XLSX เท่านั้น และขนาดไม่เกิน 10 MB";
              }
            }
            return true;
          },
        },
        onChange: (value) => {
          setValue("show_fuel_rate_quotation", value);
          setDisabledUploadBtn(false);
          setDisabledSaveBtn(true);
        },
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{h2Text}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {form1_menu.map((menu, index) => {
          return (
            <div key={index}>
              {menu.field_label === "ใบเสนอราคา*" && (
                <Button
                  type={`button`}
                  onClick={async () => {
                    await handleDownloadTemplateFuelRateQuotation();
                  }}
                  style={{
                    margin: `0px 0px 10px`,
                  }}
                >{`ดาวน์โหลดแม่แบบใบเสนอราคา`}</Button>
              )}

              <TemplateForm
                form_info={menu}
                // index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />

              {menu.field_label === "สถานะใช้งาน" && (
                <Button
                  type={`button`}
                  disabled={disabledSeeFuelRateQuotation}
                  onClick={async () => {
                    await handleExportCarrierFreightExcel();
                  }}
                  style={{
                    margin: `0px 0px 10px`,
                  }}
                >{`ดูไฟล์ใบเสนอราคา`}</Button>
              )}
            </div>
          );
        })}

        <Button
          type={`button`}
          onClick={async () => {
            if (disabledUploadBtn === false) {
              handleCheckErrorInFuelRateQuotationField({
                page: 1,
                per_page: pagination.per_page,
                update_progress_bar: true,
              });
            }
          }}
          disabled={disabledUploadBtn}
          style={{
            margin: `0px 0px 10px`,
          }}
        >{`อัพโหลด`}</Button>

        <CustomLinearProgress progress={downloadProgress} />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div
        className={styles.field_container}
        style={{
          background: `transparent`,
          boxShadow: `none`,
          minWidth: `700px`,
        }}
      >
        <TableCustom
          columns={columns}
          dataSource={listErrorFieldsInTemplateCarrierFrieghtFile}
          pagination={pagination}
          // loading={isLoading}
          setPagination={(val) => {
            handleCheckErrorInFuelRateQuotationField({
              page: val.page,
              per_page: val.per_page,
            });
          }}
          hrefDisabled={true}
          // onSelectedRow={(val) => console.log(val)}
          // href={`/master-data/fuel-rate/`}
        />
      </div>
    );
  };

  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          handleUpdateFuelRateQuotation();
        })}
      >
        {renderColumn1()}
        {renderTable()}

        {R.isEmpty(errors) &&
        R.isEmpty(listErrorFieldsInTemplateCarrierFrieghtFile) &&
        disabledSaveBtn === false ? (
          <FloatingButton
            button_type="save"
            onClick={() => {
              // updateTruckType();
            }}
          />
        ) : null}
      </form>

      {renderModal()}
    </Container>
  ) : (
    <Loading />
  );
};

export default FuelRateQuotationInfo;
