export default function ListItem(){
    return {
        MuiListItem:{
            styleOverrides:{
                root:{
                    padding: "5px 10px"
                }
            }
        }
    }
}