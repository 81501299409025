import instance from "../../axiosInstance";

export const getAllAccessory = async (params = {}) => {
  try {
    return await instance.get(`api/master/accessories`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAccessory = async (id = null) => {
  try {
    return await instance.get(`api/master/accessories/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createAccessory = async (body = {}) => {
  try {
    return await instance.post(`api/master/accessories`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateAccessory = async (id = null, body = {}) => {
  try {
    return await instance.patch(`api/master/accessories/${id}`, body, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
