import instance from "../../axiosInstance";

export const getAllCarrierInFavoriteGroup = async (
  profile_shipper_favorite_group_id = null,
  params = {}
) => {
  try {
    return await instance.get(
      `api/profile/favorite-group/carrier-in-favorite-group/${profile_shipper_favorite_group_id}`,
      {
        params: params,
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getCarrierInFavoriteGroup = async (id = null) => {
  try {
    return await instance.get(
      `api/profile/favorite-group/carrier-in-favorite-group/detail/${id}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createCarrierInFavoriteGroup = async (body = {}) => {
  try {
    return await instance.post(
      `api/profile/favorite-group/carrier-in-favorite-group/`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateCarrierInFavoriteGroup = async (id = null, body = {}) => {
  try {
    return await instance.patch(
      `api/profile/favorite-group/carrier-in-favorite-group/${id}`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
