import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as R from "ramda";
import { Container } from "@mui/material";
import { Space, Tag } from "antd";
import TableActiveIcon from "components/tableActiveIcon";
import FieldSearch from "components/field/FieldSearch";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FloatingButton from "components/button/FloatingButton";
import TableCustom from "components/table/TableCustom";
import FilterTab from "components/filterTab/FilterTab";
import { subMenuTruck } from "utils/submenuoptions";
import { getAllDriverTrainingProject, getUserById } from "utils/api/user/user";
import helper from "../../utils/helper";
import CreateDriverTrainingProjectModal from "./CreateDriverTrainingProjectModal";
import UserDetailSubMenu from "components/layout/SubMenu.js/UserDetailSubMenu";
import AlertModal from "components/modal/AlertModal";

const columns = [
  {
    id: "created_at",
    label: "วันที่สร้างรายการ",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.created_at);
    },
  },
  {
    id: "updated_at",
    label: "วันที่แก้ไขข้อมูลล่าสุด",
    align: "center",
    format: (value) => {
      return helper.ToConvertDateTimeForTable(value.updated_at);
    },
  },
  {
    id: "project.project_code",
    label: "รหัสโครงการ",
    align: "center",
    format: (value) => {
      return value.project.project_code;
    },
  },
  {
    id: "project.name",
    label: "ชื่อโครงการ",
    align: "center",
    format: (value) => {
      return value.project.name;
    },
  },
  {
    id: "project.profile.company_name",
    label: "ชื่อโปรไฟล์",
    align: "center",
    format: (value) => {
      return value.project.profile.company_name;
    },
  },
  {
    id: "active",
    label: "สถานะใช้งาน",
    align: "center",
    format: (value) => {
      return <TableActiveIcon active_status={value.active} />;
    },
  },
];

const DriverTrainingProject = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();
  const initial_filter = {
    sort_field: [`updated_at`],
    sort_order: [-1],
    start_date: ``,
    end_date: ``,
    search_field: `project_name`,
    search_val: ``,
    active: "all",
  };
  const [driverTrainingProject, setDriverTrainingProject] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 5,
    total: 0,
  });
  const [userInfo, setUserInfo] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [filter, setFilter] = useState(initial_filter);
  const [params, setParams] = useState({});
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  useEffect(() => {
    handleGetDataByNewParams({ page: 1 });
    handleGetUserInfo();

    // eslint-disable-next-line
  }, []);

  const handleGetDataByNewParams = ({
    page = pagination.page,
    per_page = pagination.per_page,
  }) => {
    const params = {
      start_date:
        !R.isNil(filter.start_date) && !R.isEmpty(filter.start_date)
          ? new Date(filter.start_date).toISOString()
          : null,
      end_date:
        !R.isNil(filter.end_date) && !R.isEmpty(filter.end_date)
          ? new Date(filter.end_date).toISOString()
          : null,
      sort_field: filter.sort_field,
      sort_order: filter.sort_order,
      [filter.search_field]: filter.search_val,
      active:
        filter.active !== `all`
          ? filter.active === `true`
            ? true
            : false
          : null,
      page: page,
      per_page: per_page,
    };

    handleGetAllDriverTrainingProject({
      page: page,
      per_page: per_page,
      new_params: params,
    });
    setParams(params);
  };

  const handleGetAllDriverTrainingProject = async ({
    page = pagination.page,
    per_page = pagination.per_page,
    new_params = null,
  }) => {
    setIsLoading(true);
    try {
      if (R.isNil(new_params)) {
        new_params = { ...params, page: page, per_page: per_page };
      }

      const resp = await getAllDriverTrainingProject(user_id, new_params);
      setDriverTrainingProject(resp.data.data.results);
      setPagination({
        page: page,
        per_page: per_page,
        total: resp.data.data.total,
      });
      setIsLoading(false);
    } catch (error) {
      console.log("error getAllDriverTrainingProject: ", error);
      setIsLoading(false);
      setAlertModal({
        open: true,
        title: `เกิดข้อผิดพลาด`,
        description: error.response.data.description,
      });
    }
  };

  const handleGetUserInfo = async () => {
    try {
      const resp = await getUserById(user_id);
      setUserInfo(resp.data.data);
    } catch (error) {
      console.log("error handleGetUserInfo: ", error);
    }
  };

  const onChangeFilter = (field_type = `sort`, val = ``) => {
    if (field_type === `sort`) {
      setFilter({ ...filter, sort_field: [val], sort_order: [-1] });
    } else if (field_type === `date_duration`) {
      const start_date = val[0] ? helper.toGMTPlus7(val[0]) : ``;
      const end_date = val[1] ? helper.toGMTPlus7(val[1]) : ``;

      setFilter({ ...filter, start_date: start_date, end_date: end_date });
    } else {
      setFilter({ ...filter, [field_type]: val });
    }
  };

  const renderSubMenu = () => {
    const profile_id =
      userInfo && userInfo.user_profile && userInfo.user_profile.profile
        ? userInfo.user_profile.profile.id
        : null;

    return (
      <UserDetailSubMenu
        label={`การอบรมพนักงานขับรถ`}
        pathbackward={-1}
        profile_id={profile_id}
        user_id={user_id}
        user_code={userInfo.user_code ? userInfo.user_code : null}
        breadcrumbs={[
          {
            label: "ข้อมูลผู้ใช้งาน",
            path: null,
          },
          {
            label: userInfo ? userInfo.user_code : ``,
            path: null,
          },
          {
            label: "การอบรมพนักงานขับรถ",
            path: null,
          },
        ]}
      />
    );
  };

  const renderTable = () => {
    return (
      <TableCustom
        columns={columns}
        dataSource={driverTrainingProject}
        pagination={pagination}
        loading={isLoading}
        setPagination={(val) => {
          handleGetAllDriverTrainingProject({
            page: val.page,
            per_page: val.per_page,
          });
        }}
        // onSelectedRow={(val) => console.log(val)}
        href={`/user/${user_id}/driver-traning-project/detail/`}
      />
    );
  };

  const renderFilterTab = () => {
    return (
      <FilterTab
        sortOption={{
          value: "updated_at",
          options: [{ value: "updated_at", name: "วันที่แก้ไขข้อมูลล่าสุด" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchFieldOption={{
          value: filter.search_field,
          options: [{ value: "project_name", name: "ชื่อโครงการ" }],
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        dateRangePickerOption={{
          value: {
            start_date: filter.start_date,
            end_date: filter.end_date,
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchValueOption={{
          onPressEnter: (field_type, val) => {
            handleGetDataByNewParams({ page: 1 });
          },
          onChange: (field_type, val) => {
            onChangeFilter(field_type, val);
          },
        }}
        searchButtonOption={{
          // onChange: (field_type, val) => {
          //   onChangeFilter(field_type, val);
          // },
          onClick: () => {
            handleGetDataByNewParams({ page: 1 });
          },
        }}
        perPageSelectedOption={{
          value: pagination.per_page,
          onChange: (value) => {
            handleGetAllDriverTrainingProject({
              page: 1,
              per_page: value,
            });
          },
        }}
        advanceSearchButtonOption={{
          onSearch: () => {
            handleGetDataByNewParams({ page: 1 });
          },
          onReset: () => {
            setFilter(initial_filter);
          },
          children: advanceSearchForm(),
        }}
      />
    );
  };

  const advanceSearchForm = () => {
    return (
      <div>
        <FieldSelect
          name="กรองและจัดเรียงตาม"
          value={"updated_at"}
          options={[
            {
              value: "updated_at",
              name: "วันที่แก้ไขข้อมูลล่าสุด",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("updated_at", val);
          }}
          style={{ width: `100%` }}
        />

        <FieldDateRangePicker
          name={`วันเริ่มต้น - วันสิ้นสุด`}
          popupClassName="FieldDateRangePicker-drawer-popup"
          value={{
            start_date: filter.start_date,
            end_date: filter.end_date,
          }}
          onChange={(val) => {
            onChangeFilter(`date_duration`, val);
          }}
          style={{ width: `100%`, marginBottom: 26 }}
        />

        <FieldSelect
          name="สถานะใช้งาน"
          value={filter.active}
          options={[
            {
              value: "all",
              name: "ทั้งหมด",
            },
            {
              value: "true",
              name: "ใช้งาน",
            },
            {
              value: "false",
              name: "ไม่ใช้งาน",
            },
          ]}
          onChange={(val) => {
            onChangeFilter("active", val);
          }}
          style={{ width: `100%` }}
        />
      </div>
    );
  };
  const renderModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          if (alertModal.description === "ไม่มีสิทธ์ในการเข้าถึง") {
            navigate("/");
          }

          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });
        }}
      />
    ) : null;
  };

  return (
    <>
      <Container>
        {renderSubMenu()}
        {renderFilterTab()}
        {renderTable()}

        <FloatingButton
          button_type="add"
          onClick={() => {
            setOpenCreateModal(true);
          }}
        />
      </Container>

      <CreateDriverTrainingProjectModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        updateTableData={async () => {
          handleGetAllDriverTrainingProject({ page: 1 });
        }}
      />

      {renderModal()}
    </>
  );
};

export default DriverTrainingProject;
