import React, { useState, useEffect } from "react";
import styles from "../TableTrade.module.css";
import { Modal, Typography } from "@mui/material";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import FieldSelect from "components/field/FieldSelect";
import ButtonCustom from "components/ButtonCustom";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { createJobber, editJobber, getJobberById } from "utils/api/trade/trade";
import FieldUpload from "components/field/FieldUpload";
import { UploadFile } from "@mui/icons-material";
import {
  getAllCountry,
  getAllZone,
  getProvince,
  getDistrict,
  getSubDistrict,
} from "utils/api/masterData/gis";
import { getAllBank } from "utils/api/masterData/service";

const ModalJobber = (props) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm();

  const [country, setListCountry] = useState([]);
  const [zone, setListZone] = useState([]);
  const [province, setListProvince] = useState([]);
  const [district, setListDistrict] = useState([]);
  const [subDistrict, setListSubDistrict] = useState([]);
  const [bank, setBank] = useState([]);
  const country_id = watch("country_id");
  const zone_id = watch("zone_id");
  const province_id = watch("province_id");
  const district_id = watch("district_id");
  const subdistrict_id = watch("subdistrict_id");

  console.log("props", props);
  useEffect(() => {
    if (props.idJobber && props.type === "edit") {
      getJobber(props.idJobber);
    }
  }, [props.idJobber]);
  useEffect(() => {
    getCountry();
    getBank();
  }, []);
  useEffect(() => {
    if (country_id) {
      getZone();
    }
  }, [country_id]);
  useEffect(() => {
    if (zone_id) {
      getProvoinceByZone(zone_id);
    }
  }, [zone_id]);
  useEffect(() => {
    if (province_id) {
      getDistrictByProvince(province_id);
    }
  }, [province_id]);
  useEffect(() => {
    if (district_id) {
      getSubDistrictByDistrict(district_id);
    }
  }, [district_id]);
  useEffect(() => {
    if (subdistrict_id && subDistrict) {
      const postcode = subDistrict.find(
        (element) => element.value == subdistrict_id
      );
      setValue("postal_code", postcode?.postal_code);
    }
  }, [subdistrict_id, subDistrict]);

  const getBank = async () => {
    await getAllBank()
      .then((response) => {
        let results = response.data.data.results;
        setBank(
          results?.map((res) => ({
            value: res.id,
            name: res.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCountry = async () => {
    await getAllCountry().then((res) => {
      const results = res.data.data.results;
      setListCountry(
        results?.map((res) => ({ value: res.id, name: res.name }))
      );
    });
  };
  const getZone = async () => {
    await getAllZone().then((res) => {
      const results = res.data.data.results;
      setListZone(results?.map((res) => ({ value: res.id, name: res.name })));
    });
  };
  const getProvoinceByZone = async (id) => {
    await getProvince(id).then((res) => {
      const results = res.data.data.results;
      setListProvince(
        results?.map((res) => ({ value: res.id, name: res.name }))
      );
    });
  };
  const getDistrictByProvince = async (id) => {
    await getDistrict(id).then((res) => {
      const results = res.data.data.results;
      setListDistrict(
        results?.map((res) => ({
          value: res.id,
          name: res.name,
        }))
      );
    });
  };
  const getSubDistrictByDistrict = async (id) => {
    await getSubDistrict(id).then((res) => {
      const results = res.data.data.results;
      setListSubDistrict(
        results?.map((res) => ({
          value: res.id,
          name: res.name,
          postal_code: res?.postal_code,
        }))
      );
    });
  };

  const getJobber = async (id_cus) => {
    await getJobberById(id_cus)
      .then((res) => {
        const results = res.data.results;

        console.log("results", results);
        setValue("jobber_code", results?.jobber_code);
        setValue("jobber_name", results?.jobber_name);
        setValue("tax_id", results?.tax_id);
        setValue("certificate_file", results?.certificate_file);
        setValue("branch_code", results?.branch_code);
        setValue("address", results?.address);
        setValue("bank_id", results?.bank_id);
        setValue("bank_account_number", results?.bank_account_number);
        setValue("country_id", results?.country_id);
        setValue("zone_id", results?.zone_id);
        setValue("province_id", results?.province_id);
        setValue("district_id", results?.district_id);
        setValue("subdistrict_id", results?.subdistrict_id);
        setValue("active", results?.active);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const onSubmit = async (data) => {
    if (props.type === "create") {
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          GForm.delete(key);
        } else {
          GForm.append(key, data[key]);
        }
      });

      GForm.delete("postal_code");
      await createJobber(GForm)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
          setError("tax_id", {
            type: "error",
            message: error.response.data.description,
          });
        });
    } else if (props.type === "edit") {
      const GForm = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          GForm.delete(key);
        } else {
          GForm.append(key, data[key]);
        }
      });
      GForm.delete("postal_code");
      await editJobber(props.idJobber, GForm)
        .then((res) => {
          if (res.data.code === 1000) {
            props?.handleClose();
            reset();
          }
        })
        .catch((error) => {
          console.log(error);
          setError("tax_id", {
            type: "error",
            message: error.response.data.description,
          });
        });
    }
  };

  return (
    <div>
      <Modal
        open={props?.open}
        onClose={() => {
          props?.handleClose();
          reset();
        }}
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={() => {
                props?.handleClose();
                reset();
              }}
            />
          </div>
          <div className={styles.titleUserRole}>
            <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
              {props.type === "create"
                ? "เพิ่มรายชื่อผู้ขายน้ำมัน"
                : "แก้ไขรายชื่อผู้ขายน้ำมัน"}
            </Typography>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {props.type === "edit" && (
                <Controller
                  control={control}
                  name="jobber_code"
                  render={({ field: { onChange, value } }) => (
                    <div style={{ paddingTop: "20px" }}>
                      <FieldInput
                        label="รหัสผู้ขาย"
                        readOnly={true}
                        value={value}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}

              <Controller
                control={control}
                name="jobber_name"
                rules={{
                  required:
                    props.type === "create" ? "กรุณากรอกชื่อบริษัท" : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div
                    style={{ paddingTop: props.type === "create" ? "20px" : 0 }}
                  >
                    <FieldInput
                      label="ชื่อบริษัท"
                      onChange={onChange}
                      value={value}
                      error={!!errors.jobber_name}
                      helperText={
                        errors.jobber_name ? errors.jobber_name.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="tax_id"
                rules={{
                  required:
                    props.type === "create"
                      ? "กรุณากรอกเลขประจําตัวผู้เสียภาษี"
                      : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="เลขประจําตัวผู้เสียภาษี"
                      onChange={onChange}
                      value={value}
                      type="number"
                      error={!!errors.tax_id}
                      helperText={errors.tax_id ? errors.tax_id.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="certificate_file"
                rules={{
                  required: props.type === "create" ? "กรุณาเลือกไฟล์" : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <FieldUpload
                    name={"ไฟล์หนังสือรับรอง"}
                    // accept="image/jpeg,image/png"
                    icon={<UploadFile />}
                    value={value} //คลิกเพื่อเปิด
                    onChange={onChange}
                    // url={dataDetail.image}
                    style={{
                      width: "-webkit-fill-available",
                    }}
                    error={!!errors.certificate_file}
                    textError={
                      errors.certificate_file
                        ? errors.certificate_file.message
                        : ""
                    }
                  />
                )}
              />
              <Controller
                control={control}
                name="branch_code"
                rules={{
                  required:
                    props.type === "create" ? "กรุณากรอกรหัสสาขา" : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="รหัสสาขา"
                      onChange={onChange}
                      value={value}
                      error={!!errors.branch_code}
                      helperText={
                        errors.branch_code ? errors.branch_code.message : ""
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />

              <Controller
                name="bank_id"
                control={control}
                rules={{
                  // required: checkRoles?.includes("carrier") ? "กรุณากรอก" : false,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "ระบุเป็นตัวเลขได้เท่านั้น",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldSelect
                      name={`ธนาคาร*`}
                      nameDescription="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                      options={bank}
                      value={value}
                      onChange={onChange}
                      style={{ width: "-webkit-fill-available" }}
                      error={!!errors.bank_id}
                      errorText={errors.bank_id ? errors.bank_id.message : ""}
                    />
                  </div>
                )}
              />
              <Controller
                name="bank_account_number"
                control={control}
                // rules={{
                //   required: checkRoles?.includes("carrier") ? "กรุณากรอก" : false,
                // }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: "100%" }}>
                    <FieldInput
                      label="เลขที่บัญชีธนาคาร*"
                      subLabel="(บังคับเฉพาะถ้าผู้ใช้หลักมีบทบาทเป็นผู้ขนส่ง)"
                      value={value}
                      onChange={onChange}
                      style={{ width: "-webkit-fill-available" }}
                      error={!!errors.bank_account_number}
                      helperText={
                        errors.bank_account_number
                          ? errors.bank_account_number.message
                          : ""
                      }
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="address"
                rules={{
                  required:
                    props.type === "create" ? "กรุณากรอกที่อยู่" : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldInput
                      label="ที่อยู่"
                      onChange={onChange}
                      value={value}
                      error={!!errors.address}
                      helperText={errors.address ? errors.address.message : ""}
                      style={{ width: "100%" }}
                    />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="country_id"
                rules={{
                  required:
                    props.type === "create" ? "กรุณาเลือกประเทศ" : false,
                }}
                render={({ field: { onChange, value } }) => (
                  <div>
                    <FieldSelect
                      name="ประเทศ"
                      onChange={onChange}
                      options={country ?? []}
                      value={value}
                      style={{ width: "100%" }}
                      error={!!errors.country_id}
                      errorText={
                        errors.country_id ? errors.country_id.message : ""
                      }
                    />
                  </div>
                )}
              />
              {country_id && (
                <Controller
                  control={control}
                  name="zone_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณาเลือกภูมิภาค" : false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="ภูมิภาค"
                        onChange={onChange}
                        options={zone ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.zone_id}
                        errorText={errors.zone_id ? errors.zone_id.message : ""}
                      />
                    </div>
                  )}
                />
              )}
              {zone_id && (
                <Controller
                  control={control}
                  name="province_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณาเลือกจังหวัด" : false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="จังหวัด"
                        onChange={onChange}
                        options={province ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.province_id}
                        errorText={
                          errors.province_id ? errors.province_id.message : ""
                        }
                      />
                    </div>
                  )}
                />
              )}
              {province_id && (
                <Controller
                  control={control}
                  name="district_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณาเลือกอําเภอ" : false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="อําเภอ"
                        onChange={onChange}
                        options={district ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.district_id}
                        errorText={
                          errors.district_id ? errors.district_id.message : ""
                        }
                      />
                    </div>
                  )}
                />
              )}
              {district_id && (
                <Controller
                  control={control}
                  name="subdistrict_id"
                  rules={{
                    required:
                      props.type === "create" ? "กรุณาเลือกตําบล" : false,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="ตําบล"
                        onChange={onChange}
                        options={subDistrict ?? []}
                        value={value}
                        style={{ width: "100%" }}
                        error={!!errors.subdistrict_id}
                        errorText={
                          errors.subdistrict_id
                            ? errors.subdistrict_id.message
                            : ""
                        }
                      />
                    </div>
                  )}
                />
              )}
              {subdistrict_id && (
                <Controller
                  control={control}
                  name="postal_code"
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldInput
                        label="รหัสไปรษณีย์"
                        // onChange={onChange}
                        readOnly={true}
                        value={value}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}
              {props.type === "edit" && (
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <FieldSelect
                        name="สถานะการใช้งาน"
                        onChange={onChange}
                        options={[
                          { value: true, name: "ใช้งาน" },
                          { value: false, name: "ระงับ" },
                        ]}
                        value={value}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                />
              )}
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ยกเลิก"
                  onClick={() => {
                    props?.handleClose();
                    reset();
                  }}
                  fullWidth={true}
                  type={"cancel"}
                />
              </div>

              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name={"บันทึก"}
                  // type="submit"
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalJobber;
