import React, { useState, useEffect } from "react";
import SubMenu from "./index";

const UserDetailSubMenu = ({
  label = "",
  pathbackward = -1,
  profile_id = null,
  user_id = null,
  user_code = "",
  breadcrumbs = [],
}) => {
  return (
    <div>
      <SubMenu
        label={label}
        pathbackward={pathbackward}
        breadcrumbs={breadcrumbs}
        menus={[
          {
            label: "ข้อมูลส่วนตัว",
            path: `/user/${user_id}`,
            header: "ข้อมูลทั่วไป",
          },
          {
            label: "ข้อมูลโปรไฟล์",
            path: `/user/${user_id}/profile/${profile_id}`,
          },
          {
            label: "ข้อมูลผู้ใช้รองในโปรไฟล์",
            path: `/user/${user_id}/${user_code}/profile/${profile_id}/sub-user`,
          },
          {
            label: "ข้อมูลโครงการ",
            path: `/user/${user_id}/${user_code}/profile/${profile_id}/project`,
            header: "สำหรับลูกค้า",
          },
          {
            label: "สถานที่ที่บับทึกไว้",
            path: `/user/${user_id}/${user_code}/profile/${profile_id}/favorite-place`,
          },
          {
            label: "กลุ่มผู้ให้บริการที่ชื่นชอบ",
            path: `/user/${user_id}/profile/${profile_id}/shipper-favorite-group`,
          },
          {
            label: "ข้อมูลใบเสร็จ",
            path: `/user/${user_id}/profile/${profile_id}/profile-receipt-name`,
          },
          {
            label: "ข้อมูลรถในโปรไฟล์",
            path: `/user/${user_id}/profile/${profile_id}/truck-in-profile`,
            header: "สำหรับผู้ขนส่ง",
          },
          {
            label: "ข้อมูลโครงการที่เข้าร่วม",
            path: `/user/${user_id}/profile/${profile_id}/participate-project`,
          },
          {
            label: "ข้อมูลผู้ขนส่งร่วมวิ่ง",
            path: `/user/${user_id}/profile/${profile_id}/participate-runner-project`,
          },
          {
            label: "ข้อมูลการร่วมวิ่งกับผู้ขนส่งอื่น",
            path: `/user/${user_id}/profile/${profile_id}/co-runner-project`,
          },
          {
            label: "การอบรมพนักงานขับรถ",
            path: `/user/${user_id}/driver-traning-project`,
            header: "สำหรับพนักงานขับรถ",
          },
          {
            label: "การตรวจสุขภาพพนักงานขับรถ",
            path: `/user/${user_id}/driver-health-project`,
          },
        ]}
      />
    </div>
  );
};

export default UserDetailSubMenu;
