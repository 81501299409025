import instance from "../../axiosInstance";

const addParking = async (profile_id, data) => {
  try {
    return instance.post(`/api/profile/parking/${profile_id}`, data, {
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllParking = async (profile_id) => {
  try {
    return instance.get(`/api/profile/all-parking/${profile_id}`);
  } catch (error) {
    console.log("error", error);
  }
};

const editParking = async (parking_id, data) => {
  try {
    return instance.patch(`/api/profile/parking/${parking_id}`, data, {
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const deleteParking = async (parking_id) => {
  try {
    return instance.delete(`/api/profile/parking/${parking_id}`);
  } catch (error) {
    console.log("error", error);
  }
};
export { addParking, getAllParking, editParking, deleteParking };
