// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerExpandedTable .ant-table-thead .ant-table-cell {
  background-color: #dee4ec !important;
  color: #121f43 !important;
  border-radius: 0;
  padding: 0 10px;
  font-size: small;
  text-align: center;
}

.headerExpandedTable .ant-table-container {
  height: auto;
}
.ant-table-container {
  overflow: auto;
  max-height: 60vh;
}

tr.ant-table-row.ant-table-row-level-0.ant-table-row-selected {
  color: #121f43;
}
td.ant-table-row.ant-table-row-level-0.ant-table-row-selected {
  border: 2px solid #121f43;
}

.headerOutsideTable .ant-table table {
  border-spacing: 0;
  background-color: white;
  font-size: small;
}
.headerOutsideTable .ant-table-thead .ant-table-cell {
  text-align: center;
}

/* .headerOutsideTable .ant-table-tbody .ant-table-cell {
  border-top: 1px solid red;
}

.headerExpandedTable .ant-table-tbody .ant-table-cell {
  border-top: none;
}
.headerExpandedTable .ant-table-thead .ant-table-cell {
  border-top: none;
} */
/* .headerExpandedTable .ant-table-expanded-row ant-table-expanded-row-level-1 {
  background-color: red;
} */
.headerExpandedTable .ant-table table {
  background-color: rgba(140, 163, 186, 0.1);
}

/* 
@media screen and (max-width: 768px) {
  .headerExpandedTable .ant-table-container {
    height: auto;
  }
  .ant-table-container {
    height: 30vh !important;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/components/table/TableAntd/TableAntdCustom.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;;AAEA;;;;;;;;;GASG;AACH;;GAEG;AACH;EACE,0CAA0C;AAC5C;;AAEA;;;;;;;;GAQG","sourcesContent":[".headerExpandedTable .ant-table-thead .ant-table-cell {\n  background-color: #dee4ec !important;\n  color: #121f43 !important;\n  border-radius: 0;\n  padding: 0 10px;\n  font-size: small;\n  text-align: center;\n}\n\n.headerExpandedTable .ant-table-container {\n  height: auto;\n}\n.ant-table-container {\n  overflow: auto;\n  max-height: 60vh;\n}\n\ntr.ant-table-row.ant-table-row-level-0.ant-table-row-selected {\n  color: #121f43;\n}\ntd.ant-table-row.ant-table-row-level-0.ant-table-row-selected {\n  border: 2px solid #121f43;\n}\n\n.headerOutsideTable .ant-table table {\n  border-spacing: 0;\n  background-color: white;\n  font-size: small;\n}\n.headerOutsideTable .ant-table-thead .ant-table-cell {\n  text-align: center;\n}\n\n/* .headerOutsideTable .ant-table-tbody .ant-table-cell {\n  border-top: 1px solid red;\n}\n\n.headerExpandedTable .ant-table-tbody .ant-table-cell {\n  border-top: none;\n}\n.headerExpandedTable .ant-table-thead .ant-table-cell {\n  border-top: none;\n} */\n/* .headerExpandedTable .ant-table-expanded-row ant-table-expanded-row-level-1 {\n  background-color: red;\n} */\n.headerExpandedTable .ant-table table {\n  background-color: rgba(140, 163, 186, 0.1);\n}\n\n/* \n@media screen and (max-width: 768px) {\n  .headerExpandedTable .ant-table-container {\n    height: auto;\n  }\n  .ant-table-container {\n    height: 30vh !important;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
