import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import FormModal from "components/modal/FormModal";
import FieldInput from "components/field/FieldInput";
import FieldDatePicker from "components/field/FieldDatePicker";
import AlertModal from "components/modal/AlertModal";
import TemplateForm from "components/templateForm/TemplateForm";
import { createShipperFavoriteGroup } from "utils/api/profile/shipperFavoriteGroup";
import helper from "utils/helper";
import styles from "./CreateCarrierInFavoriteGroupModal.module.css";
import Button from "components/button/Button";
import { createCarrierInFavoriteGroup } from "utils/api/profile/carrierInFavoriteGroup";
import { getUserByCode } from "utils/api/user/user";

const CreateCarrierInFavoriteGroupModal = ({
  shipper_favorite_group_id = null,
  open = false,
  setOpen = () => {},
  updateTableData = () => {},
}) => {
  const form_confim_btn = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm({
    defaultValues: {},
  });

  const handleGetUserInfo = async () => {
    setIsLoading(true);
    // WM60094428
    try {
      const user_code = getValues().user_code ? getValues().user_code : null;

      if (!R.isNil(user_code) && !R.isEmpty(user_code)) {
        const resp = await getUserByCode(user_code);
        if (resp.data.code === 1000) {
          const data = resp.data.data.results;
          setValue(`profile_id`, data.user_profile.profile.id);
          setValue(`company_name`, data.user_profile.profile.company_name);
          clearErrors(`user_code`);
        }
      } else {
        throw `กรุณาใส่รหัสผู้ใช้งาน`;
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.description
      ) {
        setError(`user_code`, {
          type: "error",
          message: error.response.data.description,
        });
        // });
      } else {
        // setAlertModal({
        //   open: true,
        //   title: `ค้นหาไม่สำเร็จ`,
        //   description: error,
        // });
      }
      setValue(`profile_id`, null);
      setValue(`company_name`, null);
      // setOpen(false);
    }
  };

  const renderForm = () => {
    const list_form = [
      {
        name: "user_code",
        field_type: "text",
        field_label: "รหัสผู้ใช้งานหลัก*",
        rules: {
          required: "รหัสผู้ใช้งานหลัก",
        },
        error: errors[`user_code`] ? errors[`user_code`] : false,
        errorText: errors[`user_code`] ? errors[`user_code`].message : null,
      },
      {
        name: "company_name",
        field_type: "text",
        field_label: "ชื่อโปรไฟล์",
        disabled: true,
      },
    ];

    return (
      <div>
        {list_form.map((menu, index) => {
          return (
            <>
              <TemplateForm
                form_info={menu}
                index={index}
                control={control}
                errors={errors}
                trigger={trigger}
              />

              {menu.name === "user_code" && (
                <div
                  style={{
                    marginBottom: `23px`,
                  }}
                >
                  <Button
                    type={`button`}
                    onClick={() => {
                      handleGetUserInfo();
                    }}
                  >{`ค้นหา`}</Button>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const handleCreateCarrierInFavShipperGroup = async () => {
    setIsLoading(true);
    try {
      const body = {
        profile_shipper_favorite_group_id: Number(shipper_favorite_group_id),
        profile_id: Number(getValues().profile_id),
        active: true,
      };
      const create_resp = await createCarrierInFavoriteGroup(body);
      if (create_resp.data.code === 1000) {
        setAlertModal({
          open: true,
          title: `บันทึก`,
          description: `บันทึกข้อมูลสำเร็จ`,
        });
      }

      setOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log("error,", error);

      setAlertModal({
        open: true,
        title: `บันทึกไม่สำเร็จ`,
        description: error.response.data.description,
      });
      setOpen(false);
      setIsLoading(false);
    }
  };

  const renderAlertModal = () => {
    return alertModal.open ? (
      <AlertModal
        open={alertModal.open}
        title={alertModal.title}
        description={alertModal.description}
        onClose={() => {
          let alert_modal_description = alertModal.description;
          setAlertModal({
            open: false,
            title: ``,
            description: ``,
          });

          if (alert_modal_description === `บันทึกข้อมูลสำเร็จ`) {
            updateTableData();
            reset();
          } else {
            setOpen(true);
          }
        }}
      />
    ) : null;
  };

  return (
    <>
      <FormModal
        title={`เพิ่มสมาชิกผู้ให้บริการที่ชื่นชอบ`}
        open={open}
        confirmTextBtn={"บันทึก"}
        cancelTextBtn={"ยกเลิก"}
        // disabledCancelBtn={isLoading === true}
        disabledComfirmBtn={!R.isEmpty(errors) && !isLoading}
        onConfirm={() => {
          if (form_confim_btn.current) {
            form_confim_btn.current.click(); // Trigger form_confim_btn's click event
          }
        }}
        onCancel={() => {
          reset();
          setOpen(false);
        }}
      >
        <form
          onSubmit={handleSubmit(() => {
            handleCreateCarrierInFavShipperGroup();
          })}
        >
          {renderForm()}

          <button ref={form_confim_btn} style={{ display: "none" }}>
            Confirm
          </button>
        </form>
      </FormModal>

      {renderAlertModal()}
    </>
  );
};

export default CreateCarrierInFavoriteGroupModal;
