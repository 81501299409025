import instance from "../../axiosInstance";
import helper from "utils/helper";

//MASTER DATA
const getMenuPermission = async (param) => {
  try {
    return instance.get(`/api/master/menu-permission`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllUser = async (param, body) => {
  try {
    return instance.post(`/api/user/get-user`, body, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getUserPermission = async () => {
  const param = {
    sort_field: "id",
    sort_order: 1,
    page: 1,
    per_page: 10,
  };

  try {
    return instance.get(`/api/master/user-permission`, {
      params: param,
    });
  } catch (error) {
    console.log("error", error);
  }
};

//Master Data
const getDrivingLicense = async () => {
  try {
    return instance.get(`/api/master/driving-license`);
  } catch (error) {
    console.log("error", error);
  }
};

const checkDrivingLicense = async (param) => {
  try {
    return instance.get(`/api/master/truck-driving-license`, {
      params: param,
    });
  } catch (error) {
    console.log(error);
  }
};

const createUser = async (data) => {
  try {
    return instance.post(`api/user/create-user`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const checkUniqueFields = async (data) => {
  try {
    return instance.post(`/api/user/check-unique-fields-user`, data, {
      headers: {
        "content-type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const getUserById = async (id) => {
  try {
    return instance.get(`/api/user/get-user-by-id/${id}`);
  } catch (error) {
    console.log(error);
  }
};

const editUser = async (id, data) => {
  try {
    return instance.patch(`/api/user/edit-user-by-id/${id}`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getAllDriverTrainingProject = async (user_id, params) => {
  try {
    return instance.get(`/api/user/user-driver-training-project/${user_id}`, {
      params: params,
    });
  } catch (error) {
    console.log(error);
  }
};

const getDriverTrainingProject = async (id) => {
  try {
    return instance.get(`/api/user/user-driver-training-project/detail/${id}`);
  } catch (error) {
    console.log(error);
  }
};

const createDriverTrainingProject = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/user/user-driver-training-project/`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const updateDriverTrainingProject = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.patch(
      `/api/user/user-driver-training-project/${id}`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const getAllDriverHealthProject = async (user_id, params) => {
  try {
    return instance.get(`/api/user/user-driver-health-project/${user_id}`, {
      params: params,
    });
  } catch (error) {
    console.log(error);
  }
};

const getDriverHealthProject = async (id) => {
  try {
    return instance.get(`/api/user/user-driver-health-project/detail/${id}`);
  } catch (error) {
    console.log(error);
  }
};

const createDriverHealthProject = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/user/user-driver-health-project/`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const updateDriverHealthProject = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.patch(
      `/api/user/user-driver-health-project/${id}`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

const getUserByCode = async (user_code) => {
  try {
    return instance.get(`/api/user/get-user-by-code/${user_code}`);
  } catch (error) {
    console.log(error);
  }
};

const uploadUser = async (data) => {
  try {
    return instance.post(`/api/user/excel-insert`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log("error", error);
  }
};

const getCountUser = async () => {
  try {
    return instance.get(`/api/user/count-all-users`);
  } catch (error) {
    console.log(error);
  }
};
export {
  getMenuPermission,
  getAllUser,
  getUserPermission,
  getDrivingLicense,
  checkDrivingLicense,
  createUser,
  getUserById,
  editUser,
  checkUniqueFields,
  getAllDriverTrainingProject,
  getDriverTrainingProject,
  createDriverTrainingProject,
  updateDriverTrainingProject,
  getAllDriverHealthProject,
  getDriverHealthProject,
  createDriverHealthProject,
  updateDriverHealthProject,
  getUserByCode,
  uploadUser,
  getCountUser,
};
