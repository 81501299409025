import instance from "../../axiosInstance";
import helper from "utils/helper";

export const getTruckType = async (params = {}) => {
  try {
    return await instance.get(`api/master/truck-type`, {
      params: params,
      //   headers:{
      //     "Content-Type"
      //   }
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getTruckTypeById = async (id = null) => {
  try {
    return await instance.get(`api/master/truck-type/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createTruckType = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(`api/master/truck-type/`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateTruckType = async (id = null, body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.patch(`api/master/truck-type/${id}`, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const checkTruckTypeFromTruckCode = async (truck_code = "") => {
  try {
    return await instance.get(
      `api/master/truck-type/check-data-from-truck-code/${truck_code}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateOrderTruckType = async (body = {}) => {
  try {
    return await instance.patch(
      `api/master/truck-type/order-truck-type`,
      body,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
