import helper from "utils/helper";
import instance from "../../axiosInstance";

export const getAllRouteStlStandardSize = async (params = {}) => {
  try {
    return await instance.get(`api/master/route-stl-standard-size`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getRouteStlStandardSize = async (id = null) => {
  try {
    return await instance.get(`api/master/route-stl-standard-size/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const updateRouteStlStandardSize = async (id = null, body = {}) => {
  try {
    return await instance.patch(
      `api/master/route-stl-standard-size/${id}`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const downloadExcelRouteStlStandardSize = async (params = {}) => {
  try {
    return await instance.get(
      `/api/master/route-stl-standard-size/download-excel`,
      {
        params: params,
        responseType: "arraybuffer",
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const uploadExcelRouteStlStandardSize = async (body = {}) => {
  try {
    const form_data = helper.ConvertJsonToFormData(body);

    return await instance.post(
      `/api/master/route-stl-standard-size/upload-route-stl-standard-size-from-excel`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
