import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
export default function InputUpDown(props) {
  const { value, onChange, max, min, disable = false } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", minWidth: 120 }}>
      <div>
        <IconButton
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
          }}
          size="small"
          onClick={() => {
            if (value !== min && !disable) onChange(value - 1);
          }}
        >
          <RemoveIcon
            sx={{ fontSize: 20, color: disable ? "rgba(0,0,0,0.3)" : "black" }}
          />
        </IconButton>
      </div>
      <div
        style={{ flex: "1", textAlign: "center", color: "black", fontSize: 24 }}
      >
        {value}
      </div>
      <div>
        {" "}
        <IconButton
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
          }}
          size="small"
          onClick={() => {
            if (value !== max && !disable) onChange(value + 1);
          }}
        >
          <AddIcon
            sx={{ fontSize: 20, color: disable ? "rgba(0,0,0,0.3)" : "black" }}
          />
        </IconButton>
      </div>
    </div>
  );
}
