import Line from "components/layout/line";
import React, { useState, useEffect } from "react";
import masterProduct from "utils/api/masterData/productType";
import "./input.css";
import helper from "utils/helper";
export default function ProductList(props) {
  const [productType, setProductType] = useState(props.product);

  useEffect(() => {
    if (!productType) {
      getProduct();
    }
  }, [productType]);

  const getProduct = async () => {
    let product = await masterProduct.ProductType({
      sort_field: "order_number",
      sort_order: 1,
    });
    // console.log(product);
    setProductType(product.data.data.results);
  };
  return (
    <div
      style={{
        overflow: "scroll",
        maxHeight: 400,
        borderRadius: 10,
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        margin: 5,
      }}
    >
      {productType?.map((ele, index) => {
        return (
          <div key={index + "productList"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                props.onChange(ele);
              }}
              className="productList"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flex: 1,
                  // padding: "5px 20px 5px 10px",
                }}
              >
                <img
                  src={
                    ele && ele.image
                      ? helper.addhtpps(ele.image)
                      : require("../../assets/image/work/product.png")
                  }
                  height={40}
                  alt={"product"}
                />
              </div>
              <div style={{ flex: 1, textAlignLast: "start" }}>
                <div style={{ fontWeight: 500 }}>
                  {ele?.name ?? "ประเภทสินค้า*"}
                </div>
                <div style={{ fontSize: 13, opacity: 0.3 }}>
                  {ele?.example ?? ""}
                </div>
              </div>
            </div>
            <Line />
          </div>
        );
      })}
    </div>
  );
}
