// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterTab_filterTabContainer__FG6Hi {
  background-color: #fff;
  margin-top: 5px;
  /* padding: 20px 10px; */
  padding: 0px 10px;
  max-height: max-content;
  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  min-height: 100px;
}
.FilterTab_filterTabSection__MwLRj {
  width: 100%;
  padding-bottom: 0px;
}

@media screen and (min-width: 1400px) {
  .FilterTab_SearchSelectWrapper__z1G3X {
    min-width: 260px;
  }
  .FilterTab_SearchFieldWrapper__yYAwZ {
    width: 360px;
  }
}

@media screen and (min-width: 1200px) {
  .FilterTab_SearchSelectWrapper__z1G3X {
    min-width: 200px;
  }
  .FilterTab_SearchFieldWrapper__yYAwZ {
    width: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/filterTab/FilterTab.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,eAAe;EACf,wBAAwB;EACxB,iBAAiB;EACjB,uBAAuB;EACvB,oDAAoD;EACpD,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".filterTabContainer {\n  background-color: #fff;\n  margin-top: 5px;\n  /* padding: 20px 10px; */\n  padding: 0px 10px;\n  max-height: max-content;\n  box-shadow: 0px 4px 4px 0px rgba(140, 163, 186, 0.2);\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  border-radius: 10px;\n  min-height: 100px;\n}\n.filterTabSection {\n  width: 100%;\n  padding-bottom: 0px;\n}\n\n@media screen and (min-width: 1400px) {\n  .SearchSelectWrapper {\n    min-width: 260px;\n  }\n  .SearchFieldWrapper {\n    width: 360px;\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .SearchSelectWrapper {\n    min-width: 200px;\n  }\n  .SearchFieldWrapper {\n    width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterTabContainer": `FilterTab_filterTabContainer__FG6Hi`,
	"filterTabSection": `FilterTab_filterTabSection__MwLRj`,
	"SearchSelectWrapper": `FilterTab_SearchSelectWrapper__z1G3X`,
	"SearchFieldWrapper": `FilterTab_SearchFieldWrapper__yYAwZ`
};
export default ___CSS_LOADER_EXPORT___;
