import React, { useEffect, useState } from "react";
// import Avatar from "@material-ui/core/Avatar";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  IconButton,
  Container,
  FormControl,
  FormGroup,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Input,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
// import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useCookies } from "react-cookie";
import { useForm, Controller } from "react-hook-form";
import "./Login.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckboxOutline from "components/input/checkboxOutline";
import auth from "utils/api/auth/auth";
import LoadingBackground from "components/loading/LoadingBackground";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
let api = auth.create();
export default function ResetPassword(props) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(["access_token"]);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkLogin, setCheckLogin] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({});
  const watchPassword = watch("password");
  const watchPasswordConfirm = watch("passwordConfirm");
  const error = {
    password: "รหัสผ่านไม่ถูกต้อง",
    passwordConfirm: "รหัสผ่านไม่ตรงกัน",
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (cookies.access_token) {
      // removeCookie("access_token");
      window.location.href = "/";
      // setCheckLogin(true);
    } else {
      setCheckLogin(true);
    }
  }, [cookies]);
  useEffect(() => {
    if (!searchParams.get("reset_code")) {
      window.location.href = "/";
    }
  }, [searchParams]);
  const reset = (data) => {
    console.log(data);
    setLoading(true);
    console.log(searchParams.get("reset_code"));
    const body = {
      password: data.password,
      reset_code: searchParams.get("reset_code"),
    };
    api
      .setNewPassword(body)
      .then((response) => {
        console.log(response);
        setLoading(false);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "เปลี่ยนรหัสผ่านเสร็จสิ้น",
        }).then(() => {
          window.location.href = "/login";
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code === 4101) {
          setError("otp");
        }
        if (error.response.data.code === 4103) {
          alert(error.response.data.description);
        } else {
          alert("เกิดข้อผิดพลาด");
        }
        setLoading(false);
      });
  };
  return (
    <div>
      {loading ? <LoadingBackground /> : null}
      {checkLogin ? (
        <div className="loginBody">
          <div className="loginBodySlide">
            <img
              src={require("../../assets/image/PasswordSlide.png")}
              alt="Slide"
              height={"40%"}
            />
          </div>
          <div className="loginBodyForm">
            <div className="loginBodySubForm">
              <img
                src={require("../../assets/image/WemoveLogo.png")}
                alt="logo"
                height={80}
              />
              <Typography component="h1" variant="h4">
                ตังรหัสผ่านใหม่
              </Typography>
              <label>โปรดตั้งรหัสผ่านใหม่</label>

              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: () => {
                    if (
                      /[A-Z]+/.test(watchPassword) &&
                      /[0-9]+/.test(watchPassword) &&
                      watchPassword.length >= 6
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl variant="standard" fullWidth margin="none">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      color={errors.password ? "error" : "primary"}
                    >
                      รหัสผ่านใหม่
                    </InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      label="รหัสผ่าน"
                      name="password"
                      value={value}
                      id="password"
                      error={errors.password}
                      autoComplete="current-password"
                      onChange={onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon
                                sx={{
                                  color: errors.password
                                    ? "#ff316f"
                                    : "#8ca3ba",
                                }}
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                sx={{
                                  color: errors.password
                                    ? "#ff316f"
                                    : "#8ca3ba",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
                name="password"
                defaultValue=""
              />
              {errors.password && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                  }}
                >
                  <label style={{ color: "#ff316f", fontSize: 10 }}>
                    {error.password}
                  </label>
                </div>
              )}
              <Controller
                control={control}
                rules={{
                  required: true,
                  validate: () => {
                    if (watchPassword === watchPasswordConfirm) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl variant="standard" fullWidth margin="none">
                    <InputLabel
                      htmlFor="standard-adornment-password"
                      color={errors.passwordConfirm ? "error" : "primary"}
                    >
                      ยืนยันรหัสผ่านใหม่
                    </InputLabel>
                    <Input
                      type={showPasswordConfirm ? "text" : "password"}
                      label="รหัสผ่าน"
                      name="passwordConfirm"
                      value={value}
                      id="passwordConfirm"
                      error={errors.passwordConfirm}
                      autoComplete="current-password"
                      onChange={onChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirm}
                            onMouseDown={handleMouseDownPasswordConfirm}
                          >
                            {showPasswordConfirm ? (
                              <VisibilityOffOutlinedIcon
                                sx={{
                                  color: errors.passwordConfirm
                                    ? "#ff316f"
                                    : "#8ca3ba",
                                }}
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                sx={{
                                  color: errors.passwordConfirm
                                    ? "#ff316f"
                                    : "#8ca3ba",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
                name="passwordConfirm"
                defaultValue=""
              />
              {errors.passwordConfirm && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "start",
                  }}
                >
                  <label style={{ color: "#ff316f", fontSize: 10 }}>
                    {error.passwordConfirm}
                  </label>
                </div>
              )}
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: 12,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: /[A-Z]+/.test(watchPassword)
                        ? "limegreen"
                        : "#8ca3ba",
                    }}
                  />
                  <label
                    style={{
                      color: /[A-Z]+/.test(watchPassword)
                        ? "limegreen"
                        : "#8ca3ba",
                    }}
                  >
                    มีตัวอักษร A-Z และเป็นตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว
                  </label>
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: /[0-9]+/.test(watchPassword)
                        ? "limegreen"
                        : "#8ca3ba",
                    }}
                  />
                  <label
                    style={{
                      color: /[0-9]+/.test(watchPassword)
                        ? "limegreen"
                        : "#8ca3ba",
                    }}
                  >
                    มีตัวเลข 0-9 อย่างน้อย 1 ตัว
                  </label>
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color:
                        watchPassword?.length >= 6 ? "limegreen" : "#8ca3ba",
                    }}
                  />
                  <label
                    style={{
                      color:
                        watchPassword?.length >= 6 ? "limegreen" : "#8ca3ba",
                    }}
                  >
                    มีตัวอักษรและตัวเลขอย่างน้อย 6 ตัว
                  </label>
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color:
                        watchPassword && watchPassword === watchPasswordConfirm
                          ? "limegreen"
                          : "#8ca3ba",
                    }}
                  />
                  <label
                    style={{
                      color:
                        watchPassword && watchPassword === watchPasswordConfirm
                          ? "limegreen"
                          : "#8ca3ba",
                    }}
                  >
                    รหัสผ่านและยืนยันรหัสผ่านตรงกัน
                  </label>
                </label>
              </div>
              <div style={{ marginTop: 20 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={handleSubmit(reset)}
                >
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
