const Master = {
  place_type: [
    {
      value: 0,
      item: "ที่พักอาศัย",
    },
    {
      value: 1,
      item: "บริษัทห้างร้าน",
    },
    {
      value: 2,
      item: "ห้างสรรพสินค้า",
    },
    {
      value: 3,
      item: "มีชานชลาขึ้น-ลงสินค้า / รถโฟคลิฟต",
    },
    {
      value: 4,
      item: "โรงพยาบาล / สถานบริการทางการแพทย",
    },
    {
      value: 5,
      item: "ศาสนสถาน / วัด / โบสถ์ / มัสยิด",
    },
    {
      value: 6,
      item: "หน่วยงานราชการ",
    },
    {
      value: 7,
      item: "ท่าเรือ / สนามบิน",
    },
    {
      value: 8,
      item: "หน้างานก่อสร้าง",
    },
    {
      value: 9,
      item: "อื่น ๆ",
    },
  ],
  prefix: [
    { value: 0, item: "นาย" },
    { value: 1, item: "นางสาว" },
    { value: 2, item: "นาง" },
  ],
};
export default Master;
