import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  useTheme,
  Checkbox,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import helper from "utils/helper";
import PaginationComponent from "components/pagination/Pagination";
import styles from "./TableCustom.module.css";
import Loading from "components/loading/Loading";
const TableCustomWork = ({
  columns = [],
  dataSource = [],
  onSelectedRow = () => {},
  loading = false,
  pagination = {
    page: 1,
    per_page: 10,
    total: 100,
  },
  checkBox = false,
  checkBoxValidation,
  lastParamsVariable = null,
  href = ``,
  setPagination = () => {},
  refresh,
  checkOneOnly = [],
}) => {
  const theme = useTheme();
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sameValidation, setSameValidation] = useState(false);
  // Calculate the maximum number of cells in any row
  const maxCells = Math.max(...columns.map((item) => Object.keys(item).length));

  // Calculate the width for each header cell
  const headerCellWidth = `${100 / maxCells}%`;

  //   useEffect(() => {
  //     console.log("checkedItems", checkedItems);
  //   }, [, checkedItems]);

  useEffect(() => {
    setSelectAll(!selectAll);
  }, [pagination.page]);

  useEffect(() => {
    if (refresh) {
      setSelectAll(false);
      setCheckedItems([]);
      setSameValidation(false);
    }
  }, [refresh]);
  useEffect(() => {
    if (
      dataSource.length > 0 &&
      checkBoxValidation &&
      checkBoxValidation.length > 0
    ) {
      let val = checkBoxValidation.map((e) => {
        let returnVal = [];
        dataSource.forEach((element) => {
          // returnVal[element] = e[element];
          returnVal.push(element[e]);
          // returnVal.push(e[element]);
        });
        return returnVal;
      });
      let isSame = true;
      val.forEach((element) => {
        let returnVal = element.filter((ele, index, array) => {
          return helper.onlyUnique(ele, index, array);
        });
        if (returnVal.length !== 1) {
          isSame = false;
        }
      });
      // console.log("val", val);
      if (isSame) {
        setSameValidation(true);
      } else {
        setSameValidation(false);
      }
    }
  }, [dataSource, checkBoxValidation]);
  const handleCheckboxChange = (row) => {
    if (checkBoxValidation) {
      if (checkedItems.length === 0) {
        if (checkedItems.some((checkedItem) => checkedItem.id === row.id)) {
          setCheckedItems(checkedItems.filter((item) => item.id !== row.id));
          onSelectedRow(checkedItems.filter((item) => item.id !== row.id));
        } else {
          setCheckedItems([...checkedItems, row]);
          onSelectedRow([...checkedItems, row]);
        }
      } else {
        if (!checkSame(row)) {
          if (checkedItems.some((checkedItem) => checkedItem.id === row.id)) {
            setCheckedItems(checkedItems.filter((item) => item.id !== row.id));
            onSelectedRow(checkedItems.filter((item) => item.id !== row.id));
          } else {
            setCheckedItems([...checkedItems, row]);
            onSelectedRow([...checkedItems, row]);
          }
        }
      }
    } else {
      if (checkedItems.some((checkedItem) => checkedItem.id === row.id)) {
        setCheckedItems(checkedItems.filter((item) => item.id !== row.id));
        onSelectedRow(checkedItems.filter((item) => item.id !== row.id));
      } else {
        setCheckedItems([...checkedItems, row]);
        onSelectedRow([...checkedItems, row]);
      }
    }
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    let newCheckedItems = [...checkedItems];

    if (isChecked) {
      const checkDupicate = dataSource.filter(
        (row) => !checkedItems.some((item) => item.id === row.id)
      );

      // Add rows on the current page to the selection
      newCheckedItems = [...newCheckedItems, ...checkDupicate];
    } else {
      // Remove rows on the current page from the selection
      newCheckedItems = newCheckedItems.filter(
        (item) => !dataSource.some((row) => row.id === item.id)
      );
    }

    setCheckedItems(newCheckedItems);
    onSelectedRow(newCheckedItems);
  };
  const checkSame = (row) => {
    // console.log("checkedItems", checkedItems);
    if (checkedItems.length > 0) {
      let disable = false;
      checkBoxValidation.forEach((e) => {
        if (checkedItems[0][e] !== row[e]) {
          disable = true;
        }
      });
      return disable;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <div style={{ overflow: "auto", paddingTop: "20px" }}>
        <Table
          sx={{
            tableLayout: "fixed",
          }}
        >
          <TableHead sx={{ height: "74px" }}>
            <TableRow className={styles.rowCheck}>
              {checkBox ? (
                <TableCell
                  sx={{
                    fontSize: "18px",
                    bgcolor: "secondary.main",
                    color: "white",
                  }}
                  padding="checkbox"
                >
                  <Checkbox
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "white",
                      },
                      "&.Mui-disabled": {
                        color: "gray",
                      },
                    }}
                    checked={
                      checkedItems.length > 0 &&
                      dataSource.every((row) =>
                        checkedItems.some((item) => item.id === row.id)
                      )
                    }
                    onChange={handleSelectAllChange}
                    // disabled={sameValidation ? false : true}
                  />
                </TableCell>
              ) : (
                ""
              )}

              {columns?.map((column, i) => {
                return (
                  <TableCell
                    key={i}
                    align="center"
                    sx={{
                      fontSize: "20px",
                      bgcolor: "secondary.main",
                      color: "white",
                      wordWrap: "break-word",
                      padding: `16px 5px 16px 5px`,
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        </Table>
        <div style={{ overflow: "auto", height: "500px" }}>
          <Table
            sx={{
              tableLayout: "fixed",
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableBody>
              {loading && <Loading loading={loading} />}
              {dataSource?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    className={
                      checkedItems.some(
                        (checkedItem) => checkedItem.id === row.id
                      )
                        ? styles.rowChecked
                        : styles.rowCheck
                    }
                    sx={{
                      bgcolor:
                        checkBoxValidation &&
                        checkedItems.length > 0 &&
                        checkSame(row)
                          ? "rgba(0,0,0,0.2)"
                          : "white",
                      paddingTop: "20px",
                    }}
                  >
                    {checkBox ? (
                      <TableCell
                        padding="checkbox"
                        sx={{
                          height: "74px",
                        }}
                      >
                        <Checkbox
                          checked={checkedItems.some(
                            (checkedItem) => checkedItem.id === row.id
                          )}
                          onChange={() => handleCheckboxChange(row)}
                        />
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {columns?.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={i}
                          align={column.align}
                          sx={{
                            fontSize: "18px",
                            fontWeight: 300,
                            height: "74px",
                            // flex: 1,
                          }}
                        >
                          <a
                            href={
                              lastParamsVariable
                                ? `${href}${row[lastParamsVariable]}`
                                : `${href}${row.id}`
                            }
                            style={{
                              color: theme.palette.secondary.main,
                              textDecoration: "none",
                              wordWrap: "break-word",
                            }}
                          >
                            {column.format ? column.format(row) : value}
                          </a>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <Typography color={"grey.main"}>
        แสดงผล{" "}
        {pagination.page !== 1
          ? parseInt(pagination.per_page) * parseInt(pagination.page) +
            1 -
            parseInt(pagination.per_page)
          : 1}
        -
        {pagination.page !== 1
          ? parseInt(pagination.per_page) * parseInt(pagination.page) +
              parseInt(pagination.per_page) -
              parseInt(pagination.per_page) >
            parseInt(pagination.total)
            ? pagination.total
            : parseInt(pagination.per_page) * parseInt(pagination.page) +
              parseInt(pagination.per_page) -
              parseInt(pagination.per_page)
          : parseInt(pagination.per_page) > parseInt(pagination.total)
          ? parseInt(pagination.total)
          : parseInt(pagination.per_page)}{" "}
        จาก {pagination.total}{" "}
        {checkedItems.length > 0
          ? `(เลือกแล้ว ${checkedItems.length} รายการ)`
          : ""}
      </Typography>
      {!helper.IsEmptyFunction(setPagination) && (
        <PaginationComponent
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};

export default TableCustomWork;
