// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.third-party-body {
  flex: 1 1;
  overflow: scroll;
  padding-left: 80px;
  padding-right: 80px;
  align-items: center;
  min-width: 100vmin;
  max-height: 90.5vmax;
  color: #121f43;
  /* height: 100%; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/project/template/template.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".third-party-body {\r\n  flex: 1;\r\n  overflow: scroll;\r\n  padding-left: 80px;\r\n  padding-right: 80px;\r\n  align-items: center;\r\n  min-width: 100vmin;\r\n  max-height: 90.5vmax;\r\n  color: #121f43;\r\n  /* height: 100%; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
