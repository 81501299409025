import React from "react";
import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <CircularProgress
      style={{
        position: "absolute",
        top: "50vh",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 100,
      }}
    />
  );
};

export default Loading;
