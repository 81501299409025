import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as R from "ramda";
import { Container } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import FieldInput from "components/field/FieldInput";
import FieldUpload from "components/field/FieldUpload";
import FieldSelect from "components/field/FieldSelect";
import FieldDatePicker from "components/field/FieldDatePicker";
import { getPrivacyPolicy } from "../../../../utils/api/masterData/privacyPolicy";
import SubMenu from "components/layout/SubMenu.js";
import Loading from "components/loading/Loading";
import TemplateForm from "components/templateForm/TemplateForm";
import helper from "utils/helper";
import styles from "./PrivacyPolicyInfo.module.css";

const PrivacyPolicyInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alertModal, setAlertModal] = useState({
    open: false,
    title: ``,
    description: ``,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    handleGetPrivacyPolicy();

    // eslint-disable-next-line
  }, []);

  const handleGetPrivacyPolicy = async () => {
    setIsLoading(true);
    try {
      const resp = await getPrivacyPolicy(id);
      const data = resp.data.data.results;

      if (!R.isEmpty(data)) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === "boolean") {
            const bool_val = data[key] === true ? `true` : `false`;
            setValue(key, bool_val);
          } else {
            setValue(key, data[key]);
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error getPrivacyPolicy: ", error);
      navigate("/master-data/privacy-policy");
    }
  };

  const renderColumn1 = () => {
    const col1_menu = [
      {
        name: "active",
        field_type: "select",
        field_label: "สถานะใช้งาน*",
        disabled: true,
        options: [
          { value: "true", name: `ใช้งาน` },
          { value: "false", name: `ไม่ใช้งาน` },
        ],
      },
      {
        name: "end_date",
        field_type: "datePicker",
        field_label: "วันเวลาสิ้นสุดการใช้งาน*",
        subLabel: "(ระบบจะไม่แสดงจนกว่าจะมีรายการล่าสุดเพิ่มเข้ามา)",
        helperText:
          "วันเวลาที่ Inactive จะเท่ากับวันเวลาที่เริ่มใช้งานของรายการถัดไป",
        disabled: true,
        showTime: false,
      },
      {
        name: "start_date",
        field_type: "datePicker",
        field_label: "วันเวลาเริ่มต้นการใช้งาน*",
        subLabel: "(ค่าเริ่มต้น: 00.00 น. ของวันถัดไป)",
        disabled: true,
        showTime: false,
      },
      {
        name: "version",
        field_type: "text",
        field_label: "เวอร์ชัน*",
        disabled: true,
      },
      {
        name: "detail_file",
        field_type: "upload",
        field_label: "ไฟล์ Privacy Policy*",
        disabled: true,
      },
    ];

    return (
      <div className={styles.field_container}>
        <div className={styles.header_wrapper}>
          <h2>{getValues().version}</h2>
          <h3>
            วันที่สร้างรายการ{" "}
            {helper.ToConvertDateTimeForTable(getValues().created_at)}
          </h3>
          <h3>
            วันที่แก้ไขข้อมูลล่าสุด{" "}
            {helper.ToConvertDateTimeForTable(getValues().updated_at)}
          </h3>
        </div>

        {col1_menu.map((menu, index) => {
          return (
            <TemplateForm
              form_info={menu}
              index={index}
              control={control}
              errors={errors}
            />
          );
        })}
      </div>
    );
  };

  const renderSubMenu = () => {
    return (
      <SubMenu
        label={`รายละเอียด Privacy Policy`}
        pathbackward={`/master-data/privacy-policy`}
        menus={[
          {
            label: "รายละเอียด Provacy Policy",
            path: `#`,
            active: true,
          },
        ]}
        breadcrumbs={[
          {
            label: "จัดการ Master Data",
            path: null,
          },
          {
            label: "Privacy Policy",
            path: null,
          },
          {
            label: getValues().version,
            path: null,
          },
          {
            label: "รายละเอียด Privacy Policy",
            path: null,
          },
        ]}
      />
    );
  };

  return !isLoading ? (
    <Container>
      {renderSubMenu()}
      <form
        className={styles.main_container}
        onSubmit={handleSubmit(() => {
          // updateTruckType();
        })}
      >
        {renderColumn1()}
      </form>
    </Container>
  ) : (
    <Loading />
  );
};

export default PrivacyPolicyInfo;
