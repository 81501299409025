import { Box, Container, Grid, Paper, TextField } from "@mui/material";
import SubMenu from "components/layout/SubMenu.js";
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import AreaChart from "components/chart/AreaChart";
import PieChart from "components/chart/PieChart";
import StackedBarChart from "components/chart/StackedBarChart";
import { subMenuDasdboard } from "utils/submenuoptions";
import moment from "moment";
import FieldSelect from "components/field/FieldSelect";
import FieldDateRangePicker from "components/field/FieldDateRangePicker";
import FieldSearch from "components/field/FieldSearch";
import SearchButton from "components/button/SearchButton";
import ResetButton from "components/button/ResetButton";
import Loading from "components/loading/Loading";
import { getDashbaordTruck } from "utils/api/dashbaord";
import FieldAutoComplete from "components/field/FieldAutoComplete";
import { getAllCarrier } from "utils/api/masterData/service";

const data = [
  {
    name: "01/2023",
    value: 4000,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
  {
    name: "02/2023",
    value: 3000,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
  {
    name: "03/2023",
    value: 2000,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
  {
    name: "04/2023",
    value: 2780,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
  {
    name: "05/2023",
    value: 1890,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
  {
    name: "06/2023",
    value: 2390,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
  {
    name: "07/2023",
    value: 3490,
    data: {
      ปริมาณงานที่จับคู่แล้ว: "140000",
      เป้าหมาย: "200,000",
      ส่วนต่างจากเป้าหมาย: "-30%",
      ปริมาณงานที่จับคู่แล้วก่อนหน้า: "100,000",
      ส่วนต่างจากปริมาณงานที่จับคู่แล้วก่อนหน้า: "+40%",
    },
  },
];

const data2 = [
  {
    name: "01/2023",
    value: [100, 200, 300],
    data: {
      บุคคลทั่วไป: "100",
      นิติบุคคล: "200",
      รวม: "300",
    },
  },
  {
    name: "02/2023",
    value: [200, 400, 600],
    data: {
      บุคคลทั่วไป: "200",
      นิติบุคคล: "400",
      รวม: "600",
    },
  },
  {
    name: "03/2023",
    value: [300, 100, 400],
    data: {
      บุคคลทั่วไป: "300",
      นิติบุคคล: "100",
      รวม: "400",
    },
  },
];

const data3 = [
  {
    name: "01/2023",
    value: [100, 200, 300],
    data: {
      บริการเหมาคัน: "100",
      บริการฝากส่ง: "200",
      รวม: "300",
    },
  },
  {
    name: "02/2023",
    value: [200, 400, 600],
    data: {
      บริการเหมาคัน: "200",
      บริการฝากส่ง: "400",
      รวม: "600",
    },
  },
  {
    name: "03/2023",
    value: [300, 100, 400],
    data: {
      บริการเหมาคัน: "300",
      บริการฝากส่ง: "100",
      รวม: "400",
    },
  },
];

const data4 = [
  { name: "บุคคลทั่วไป", value: 200, percent: 20, color: "FFC675" },
  { name: "นิติบุคคล", value: 800, percent: 80, color: "8C8DFF" },
];

const data5 = [
  {
    name: "01/2023",
    value: {
      เฟอร์นิเจอร์: 100,
      "เครื่องใช้ไฟฟ้า เครื่องจักร": 90,
      เครื่องใช้ครัวเรือน: 80,
      "แก้ว กระจก เซรามิก": 70,
      "อาหาร เครื่่องดื่ม สำเร็จรูป": 60,
      โลหะ: 50,
      ยาง: 40,
      วัสดุก่อสร้าง: 30,
      สินค้าเกษตร: 20,
      สิ่งทอ: 10,
      อื่นๆ: 5,
    },
  },
  {
    name: "02/2023",
    value: {
      เฟอร์นิเจอร์: 100,
      "เครื่องใช้ไฟฟ้า เครื่องจักร": 90,
      เครื่องใช้ครัวเรือน: 80,
      "แก้ว กระจก เซรามิก": 70,
      "อาหาร เครื่่องดื่ม สำเร็จรูป": 60,
      โลหะ: 50,
      ยาง: 40,
      วัสดุก่อสร้าง: 30,
      สินค้าเกษตร: 20,
      สิ่งทอ: 10,
      อื่นๆ: 5,
    },
  },
  {
    name: "03/2023",
    value: {
      เฟอร์นิเจอร์: 100,
      "เครื่องใช้ไฟฟ้า เครื่องจักร": 90,
      เครื่องใช้ครัวเรือน: 80,
      "แก้ว กระจก เซรามิก": 70,
      "อาหาร เครื่่องดื่ม สำเร็จรูป": 60,
      โลหะ: 50,
      ยาง: 40,
      วัสดุก่อสร้าง: 30,
      สินค้าเกษตร: 20,
      สิ่งทอ: 10,
      อื่นๆ: 5,
    },
  },
];

const data6 = [
  { name: "เฟอร์นิเจอร์", percent: 100, value: 4500 },
  { name: "เครื่องใช้ไฟฟ้า เครื่องจักร", percent: 95, value: 3500 },
  { name: "เครื่องใช้ครัวเรือน", percent: 90, value: 3000 },
  { name: "แก้ว กระจก เซรามิก", percent: 85, value: 2500 },
  { name: "อาหาร เครื่่องดื่ม สำเร็จรูป", percent: 80, value: 2000 },
  { name: "เฟอร์นิเจอร์", percent: 80, value: 2000 },
  { name: "เครื่องใช้ไฟฟ้า", percent: 80, value: 2000 },
  { name: "เครื่องใช้ครัวเรือน", percent: 80, value: 2000 },
  { name: "แก้ว", percent: 80, value: 2000 },
  { name: "อาหาร", percent: 80, value: 2000 },
];

const DashboardTruck = () => {
  const [isReady, setIsReady] = useState(false);
  const [subMenu_menus, setSubMenuMenus] = useState([...subMenuDasdboard]);
  const [subMenu_breadcrumbs, setSubMenuBreadcrumbs] = useState([]);
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD 00:00:00")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD 23:59:59"));
  const [groupBy, setGroupBy] = useState(4);
  const [filterBy, setFilterBy] = useState("profileID");
  const [filter, setFilter] = useState(null);
  const [dashboard1, setDashboard1] = useState(null);
  const [dashboard2, setDashboard2] = useState(null);
  const [dashboard3, setDashboard3] = useState(null);
  const [dashboard4, setDashboard4] = useState(null);
  const [acCarrier, setAcCarrier] = useState([]);

  useEffect(() => {
    setActiveSubMenu(3);
    setSubMenuBreadcrumbs([
      {
        label: "แผนภูมิและกราฟ",
        path: null,
      },
      {
        label: "แผนภูมิและกราฟรถ",
        path: null,
      },
    ]);
    fetchDashboard();
    getAutoCompleteCarrier();
  }, []);

  async function getAutoCompleteCarrier() {
    try {
      const res = await getAllCarrier();
      if (res) {
        setAcCarrier(res.data.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const monthToNumber = (month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames.indexOf(month);
  };

  const getWeekNumber = (date) => {
    const d = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNumber < 10 ? "0" + weekNumber : weekNumber.toString();
  };

  const generateDates = (dateFrom, dateTo, groupBy) => {
    const fromDate = new Date(dateFrom);
    const toDate = new Date(dateTo);
    const generatedDates = [];

    if (groupBy === 1) {
      for (
        let year = fromDate.getFullYear();
        year <= toDate.getFullYear();
        year++
      ) {
        generatedDates.push(year.toString());
      }
    } else if (groupBy === 2) {
      const startYear = fromDate.getFullYear();
      const endYear = toDate.getFullYear();
      const startMonth = fromDate.getMonth();
      const endMonth = toDate.getMonth();

      for (let year = startYear; year <= endYear; year++) {
        const monthStart = year === startYear ? startMonth : 0;
        const monthEnd = year === endYear ? endMonth : 11;

        for (let month = monthStart; month <= monthEnd; month++) {
          const date = new Date(year, month, 1);
          generatedDates.push(formatDate2(date));
        }
      }
    } else if (groupBy === 3) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        const weekNumber = getWeekNumber(currentDate);
        const year = currentDate.getFullYear();
        generatedDates.push(`w${weekNumber}/${year}`);
        currentDate.setDate(currentDate.getDate() + 7);
      }
    } else if (groupBy === 4) {
      const currentDate = new Date(dateFrom);
      while (currentDate <= toDate) {
        generatedDates.push(formatDate4(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
    return generatedDates;
  };

  const formatDate1 = (dateString) => {
    return moment(dateString).format("YYYY");
  };

  const formatDate2 = (dateString) => {
    return moment(dateString).format("MMM YYYY");
  };
  const formatDate3 = (dateString) => {
    return dateString;
  };

  const formatDate4 = (dateString) => {
    return moment(dateString).format("DD MMM YYYY");
  };

  async function fetchDashboard() {
    setIsReady(false);
    const requestData = {
      dateFrom: moment(dateFrom).toISOString(),
      dateTo: moment(dateTo).toISOString(),
      groupBy: groupBy,
    };
    if (filterBy !== "" && filter !== null) {
      requestData[filterBy] = filter.value;
    }
    try {
      const response = await getDashbaordTruck(requestData);
      if (response.status === 200) {
        const res = response.data.data;
        const rawData = res.map((item) => {
          if (groupBy === 1) {
            item.groupdate = formatDate1(item.groupdate);
          } else if (groupBy === 2) {
            item.groupdate = formatDate2(item.groupdate);
          } else if (groupBy === 3) {
            item.groupdate = formatDate3(item.groupdate);
          } else if (groupBy === 4) {
            item.groupdate = formatDate4(item.groupdate);
          }
          return item;
        });
        const generatedDates = generateDates(dateFrom, dateTo, groupBy);
        generatedDates.forEach((generatedDate) => {
          if (!rawData.some((item) => item.groupdate === generatedDate)) {
            rawData.push({
              groupdate: generatedDate,
              truck: "0",
              truck_approve_approved: "0",
              truck_approve_editing: "0",
              truck_approve_pending: "0",
              truck_approve_rejected: "0",
              truck_type: {},
            });
          }
        });

        if (groupBy === 2) {
          rawData.sort((a, b) => {
            const [aMonth, aYear] = a.groupdate.split(" ");
            const [bMonth, bYear] = b.groupdate.split(" ");
            const aMonthNumber = monthToNumber(aMonth);
            const bMonthNumber = monthToNumber(bMonth);

            if (aYear === bYear) {
              return aMonthNumber - bMonthNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 3) {
          rawData.sort((a, b) => {
            const aWeekYear = a.groupdate.split(" ")[0];
            const bWeekYear = b.groupdate.split(" ")[0];
            const [aWeek, aYear] = aWeekYear.split("/");
            const [bWeek, bYear] = bWeekYear.split("/");
            const aWeekNumber = parseInt(aWeek.split("w")[1]);
            const bWeekNumber = parseInt(bWeek.split("w")[1]);
            if (aYear === bYear) {
              return aWeekNumber - bWeekNumber;
            } else {
              return aYear.localeCompare(bYear);
            }
          });
        } else if (groupBy === 4) {
          rawData.sort((a, b) => {
            const dateA = new Date(a.groupdate);
            const dateB = new Date(b.groupdate);
            return dateA - dateB;
          });
        } else {
          rawData.sort((a, b) => a.groupdate.localeCompare(b.groupdate));
        }

        const data = rawData.map((value, index) => {
          const target = 10;
          const targetDiff = (100 / target) * value.truck - 100;
          const prevProfile = index === 0 ? "NaN" : rawData[index - 1].truck;
          const prevDiff = (100 / prevProfile) * value.truck - 100;
          const targetDiffText =
            targetDiff >= 0
              ? `+${targetDiff.toFixed(2)}%`
              : `${targetDiff.toFixed(2)}%`;
          const prevDiffText =
            prevDiff >= 0
              ? `+${prevDiff.toFixed(2)}%`
              : `${prevDiff.toFixed(2)}%`;
          return {
            name: value.groupdate,
            value: value.truck,
            data: {
              จำนวนรถ: value.truck,
              เป้าหมาย: `${target}`,
              ส่วนต่างจากเป้าหมาย: targetDiffText,
              จำนวนรถก่อนหน้า: `${prevProfile}`,
              ส่วนต่างจากจำนวนรถก่อนหน้า: prevDiffText,
            },
          };
        });

        const arrTruckType = rawData.map((value, index) => {
          return Object.keys(value.truck_type);
        });
        const uniqueTruckType = [...new Set(arrTruckType.flat())];
        const zeroValue = {};
        uniqueTruckType.forEach((truckType) => {
          zeroValue[truckType] = 0;
        });

        console.log({ uniqueTruckType });

        const data2 = rawData.map((value, index) => {
          const tmp = { ...zeroValue };
          for (const key of uniqueTruckType) {
            tmp[key] += value.truck_type[key] || 0;
          }
          return {
            name: value.groupdate,
            value: tmp,
          };
        });

        const data3 = rawData.reduce(
          (prev, curr) => {
            for (const key in prev) {
              prev[key] += curr.truck_type[key] || 0;
            }
            return prev;
          },
          { ...zeroValue }
        );

        function getRandomColor() {
          const letters = "0123456789ABCDEF";
          let color = "";
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        const newData3 = Object.entries(data3).map(([name, value]) => {
          const total = Object.values(data3).reduce(
            (acc, curr) => acc + curr,
            0
          );
          const percent = ((value / total) * 100).toFixed(2);
          const color = getRandomColor(); // สร้างสีสุ่ม

          return {
            name,
            value,
            percent: parseFloat(percent),
            color,
          };
        });

        let data4 = rawData.reduce(
          (prev, curr) => {
            return [
              prev[0] + parseFloat(curr.truck_approve_approved),
              prev[1] + parseFloat(curr.truck_approve_editing),
              prev[2] + parseFloat(curr.truck_approve_pending),
              prev[3] + parseFloat(curr.truck_approve_rejected),
            ];
          },
          [0, 0, 0, 0]
        );

        console.log({ newData3 });
        setDashboard1(data);
        setDashboard2(data2);
        setDashboard3(newData3);
        setDashboard4([
          {
            name: "รอการอนุมัติ",
            value: data4[0],
            percent:
              (data4[0] / (data4[0] + data4[1] + data4[2] + data4[3])) * 100,
            color: "FFC675",
          },
          {
            name: "อนุมัติแล้ว",
            value: data4[1],
            percent:
              (data4[1] / (data4[0] + data4[1] + data4[2] + data4[3])) * 100,
            color: "FF316F",
          },
          {
            name: "แก้ไขเพิ่มเติม",
            value: data4[2],
            percent:
              (data4[2] / (data4[0] + data4[1] + data4[2] + data4[3])) * 100,
            color: "F9A38A",
          },
          {
            name: "ไม่อนุมัติ",
            value: data4[3],
            percent:
              (data4[3] / (data4[0] + data4[1] + data4[2] + data4[3])) * 100,
            color: "8C8DFF",
          },
        ]);
      }
      setIsReady(true);
    } catch (error) {
      setIsReady(true);
      console.error("Error fetching Dashboard:", error);
    }
  }

  const setActiveSubMenu = (index) => {
    const updatedMenus = subMenu_menus.map((menu, idx) => {
      return { ...menu, active: idx === index };
    });
    setSubMenuMenus(updatedMenus);
  };

  const handleGroupby = (val) => {
    setGroupBy(val);
  };

  const handleDate = (val) => {
    setDateFrom(val[0]);
    setDateTo(val[1]);
  };

  const handleFilterBy = (val) => {
    setFilterBy(val);
  };

  const handleFilter = (val) => {
    setFilter(val);
  };

  const handleSearch = () => {
    fetchDashboard();
  };

  const handleReset = () => {
    setDateFrom(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setDateTo(moment().format("YYYY-MM-DD"));
    setGroupBy(4);
    setFilterBy("");
    setFilter("");
  };

  const renderFilterBar = () => {
    return (
      <Box
        sx={{
          height: "100px",
          width: "100%",
          padding: "26px 37px",
          borderRadius: "10px",
          background: "#fff",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`กรองตาม`}
              options={[
                { value: 1, name: "ปี" },
                { value: 2, name: "เดือน" },
                { value: 3, name: "สัปดาห์" },
                { value: 4, name: "วัน" },
              ]}
              value={groupBy}
              onChange={handleGroupby}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldDateRangePicker
              className="w-100"
              name={`วันเริ่มต้น - วันสิ้นสุด`}
              value={{
                start_date: dateFrom,
                end_date: dateTo,
              }}
              onChange={handleDate}
            />
          </Grid>
          <Grid item xs={2}>
            <FieldSelect
              className="w-100"
              name={`ค้นหาจาก`}
              options={[
                { value: "", name: "" },
                { value: "profileID", name: "ชื่อผู้ขนส่ง" },
              ]}
              value={filterBy}
              onChange={handleFilterBy}
            />
          </Grid>
          <Grid item xs={2}>
            {/* <FieldSearch
              className="w-100"
              value={filter}
              onChange={handleFilter}
            /> */}
            <FieldAutoComplete
              className="w-100"
              value={filter}
              onChange={handleFilter}
              options={acCarrier}
            />
          </Grid>
          <Grid item xs={2}>
            <SearchButton onClick={handleSearch} />
            <ResetButton styles={{ marginLeft: "5px" }} onClick={handleReset} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderDasdboard = () => {
    return (
      <div className="div-chart">
        <Box
          className="box-inline box-white"
          sx={{
            height: "625px",
            minWidth: "1150px",
            padding: "18px 25px",
          }}
        >
          <AreaChart title="จำนวนรถ" data={dashboard1} color="FF316F" />
        </Box>

        <Box className="box-inline" px={{ marginLeft: "30px" }}>
          <Box
            className="box-white"
            px={{ width: "800px", height: "300px", padding: "18px 25px" }}
          >
            <StackedBarChart
              title="รายได้จากงานที่จับคู่แล้วแยกตามประเภทสินค้า"
              data={dashboard2}
            />
          </Box>
          <Box className="box-inline">
            <Box
              className="box-white box-inline"
              px={{
                width: "325px",
                height: "300px",
                padding: "18px 25px",
                marginTop: "25px",
              }}
            >
              <PieChart
                data={dashboard3}
                title="สัดส่วนจำนวนรถแยก"
                title2="ตามระเภทรถ"
                unit="คัน"
              />
            </Box>
            <Box
              className="box-white box-inline"
              px={{
                width: "450px",
                height: "300px",
                padding: "18px 25px",
                marginTop: "25px",
                marginLeft: "30px",
              }}
            >
              <PieChart
                data={dashboard4}
                title="สัดส่วนจำนวนผู้ใช้งาน"
                title2="แตกตามประเภทการตรวจสอบเอกสาร"
                unit="คัน"
              />
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <Box className="DashbordWork">
      <Container>
        <SubMenu menus={subMenu_menus} breadcrumbs={subMenu_breadcrumbs} />
        {renderFilterBar()}
      </Container>
      {isReady === false ? <Loading /> : renderDasdboard()}
    </Box>
  );
};

export default DashboardTruck;
