import { AddCircle } from "@mui/icons-material";
import Line from "components/layout/line";
import React, { useEffect, useState } from "react";
import ModalWork from "./modalWork";
import { Button, TextField } from "@mui/material";
import InputImageShow from "components/input/InputImageShow";
import InputSelect from "components/input/inputSelect";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useForm, Controller } from "react-hook-form";
import InputUpload from "components/input/inputUpload";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import API from "../../utils/api/work/work";
import Swal from "sweetalert2";
import helper from "utils/helper";
import InputNormal from "components/input/inputNormal";
const noteTypeOptions = [
  { value: "0", item: "ลูกค้า (Shipper)" },
  { value: "1", item: "ผู้ขนส่ง (Carrier)" },
  { value: "2", item: "คนขับ (Driver)" },
  { value: "3", item: "ผู้ดูแลระบบ (Admin)" },
];
const api = API.create();
export default function AdminNote(props) {
  const { refresh, work_id, note = [] } = props;
  const [showModal, setShowModal] = useState(false);
  const [showNote, setShowNote] = useState(false);

  const [confirm, setConfirm] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    setError,
  } = useForm({});

  const save = async (data) => {
    if (!confirm) {
      setConfirm(true);
      return;
    }
    console.log(data);
    let formData = new FormData();
    formData.append("note_type", data.note_type);
    if (data.image && data.image.file)
      formData.append("image", data.image?.file);
    formData.append("detail", data.detail);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    await api
      .adminNote(work_id, formData)
      .then((response) => {
        console.log("res", response);
        reset();
        refresh();

        setShowModal(false);
        setConfirm(false);
      })
      .catch((error) => {
        if (error.response?.data)
          Swal.fire({
            timer: 2000,
            title: "error: " + error.response.data.code,
            text: error.response.data.description ?? "",
            icon: "error",
            showConfirmButton: false,
          });
        else
          Swal.fire({
            timer: 2000,
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาลองใหม่ภายหลัง",
            icon: "error",
            showConfirmButton: false,
          });
      });
  };
  return (
    <div>
      <div style={{ textAlign: "center", padding: "20px 0px 10px 0px" }}>
        บันทึกผู้ดูแลระบบ
      </div>
      <Line />
      <div style={{ marginTop: 10 }}>
        {note.map((ele, index) => {
          return (
            <div key={"status" + index} style={{ display: "flex" }}>
              <div style={{ flex: 1, display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0px 10px 10px 10px",
                  }}
                >
                  <TripOriginIcon
                    sx={{ fontSize: 16, opacity: index === 0 ? 1 : 0.5 }}
                  />
                  <MoreVertIcon sx={{ opacity: index === 0 ? 1 : 0.5 }} />
                </div>
                <div style={{ flex: 1, textAlign: "start" }}>
                  <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                    {noteTypeOptions.filter(
                      (e) => e.value === ele.note_type?.toString()
                    ).length > 0
                      ? noteTypeOptions.filter(
                          (e) => e.value === ele.note_type.toString()
                        )[0].item
                      : ""}
                  </div>
                  <div style={{ opacity: index === 0 ? 1 : 0.5 }}>
                    {helper.momentDateTime(ele.created_at, "short", false)}{" "}
                    {": " +
                      ele.createdBy?.firstname +
                      " " +
                      ele.createdBy?.lastname}
                  </div>
                </div>
              </div>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  //   showStatus(ele);
                  setShowNote(ele);
                }}
              >
                <ArrowForwardIosIcon sx={{ opacity: 0.3 }} />
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          borderStyle: "dotted",
          borderRadius: 10,
          borderWidth: 1,
          padding: 10,
          marginTop: 10,
          textAlign: "center",
          opacity: 0.5,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <AddCircle />
        </div>
        <div style={{ paddingLeft: 10, alignSelf: "center" }}>
          <div>เพิ่มบันทึก</div>
        </div>
      </div>
      <ModalWork
        open={showModal}
        close={() => {
          setShowModal(false);
        }}
        enableClose
      >
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>เพิ่มบันทึก</div>
          <Line />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputSelect
                value={value}
                label={"ประเภทบันทึก"}
                options={noteTypeOptions}
                error={errors.note_type}
                onChange={onChange}
              />
            )}
            name="note_type"
            defaultValue=""
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                fullWidth
                id="standard-multiline-static"
                label="รายละเอียด"
                value={value}
                multiline
                onChange={onChange}
                rows={4}
                variant="standard"
                error={errors.detail}
              />
            )}
            name="detail"
            defaultValue={""}
          />

          <div style={{ marginTop: 10 }}>
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputUpload
                  value={value}
                  label={"รูปประกอบ"}
                  onChange={onChange}
                  error={errors.imge}
                />
              )}
              name="image"
              defaultValue={null}
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            style={{ borderRadius: "10px", marginTop: 20 }}
            sx={{ height: 50 }}
            color="secondary"
            onClick={handleSubmit(save)}
          >
            <div>บันทึก</div>
          </Button>
        </div>
      </ModalWork>
      <ModalWork open={confirm}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: "#121f43" }}>บันทึก</h3>
            <label>ยืนยันการบันทึก?</label>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
                onClick={() => {
                  setConfirm(false);
                }}
              >
                ยกเลิก
              </Button>
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={handleSubmit(save)}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
      <ModalWork
        open={showNote ? true : false}
        close={() => {
          setShowNote(false);
        }}
        enableClose
      >
        {showNote ? (
          <div style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ textAlign: "center" }}>บันทึก</div>
            <Line />
            <InputSelect
              value={showNote?.note_type}
              label={"ประเภทบันทึก"}
              options={noteTypeOptions}
              // error={errors.note_type}
              // onChange={onChange}
              disable
            />

            <div style={{ marginTop: 10 }}>
              <InputNormal
                fullWidth
                id="standard-multiline-static"
                label="รายละเอียด"
                value={showNote?.detail}
                multiline
                // onChange={onChange}
                rows={4}
                variant="standard"
                error={errors.detail}
                disable
              />
            </div>

            {/* <div style={{ marginTop: 10 }}> */}

            <InputUpload
              value={showNote?.image ? helper.addhtpps(showNote?.image) : ""}
              label={"รูปประกอบ"}
              //   onChange={onChange}
              error={errors.imge}
              disable
            />
            {/* </div> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              style={{ borderRadius: "10px", marginTop: 20 }}
              sx={{ height: 50 }}
              color="secondary"
              onClick={() => {
                setShowNote(false);
              }}
            >
              <div>ปิด</div>
            </Button>
          </div>
        ) : null}
      </ModalWork>
    </div>
  );
}
