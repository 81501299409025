import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormHelperText } from "@mui/material";

const testOptions = [
  { label: "The Shawshank Redemption", value: 1994 },
  { label: "The Godfather", value: 1972 },
  { label: "The Godfather: Part II", value: 1974 },
  { label: "The Dark Knight", value: 2008 },
  { label: "12 Angry Men", value: 1957 },
  { label: "Schindler's List", value: 1993 },
  { label: "Pulp Fiction", value: 1994 },
];

export default function FieldAutoComplete2({
  options = testOptions,
  value = null,
  onChange = () => {},
  label = " ",
  subLabel = "",
  error = false,
  helperText = "",
  readOnly = false,
  style = {},
  closePaddingBottom = false,
  className = ``,
  descriptionUnderField = ``,
}) {
  const selectedOption =
    options.find((option) => option.value === value) || null;

  return (
    <div
      style={{
        pointerEvents: readOnly ? "none" : "",
        paddingBottom:
          closePaddingBottom || descriptionUnderField !== ``
            ? 0
            : readOnly && !error
            ? "23px"
            : 0,
      }}
    >
      <Autocomplete
        options={options}
        value={selectedOption}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.value : "");
        }}
        className={className}
        sx={{
          bgcolor: readOnly ? "rgba(140, 163, 186, 0.20)" : "white",
          "& .MuiInputBase-input": {
            padding: "4px 14px 5px 14px !important",
          },
          "& .MuiFormLabel-root[data-shrink=true]": {
            padding: "0 14px",
            marginTop: "0",
          },
          "& .MuiFormLabel-root[data-shrink=false]": {
            padding: "0 14px",
            marginTop: subLabel ? "-10px" : "-5px",
          },
          "& .MuiFormHelperText-root": {
            padding: "0 14px",
          },
          paddingBottom:
            error || readOnly || closePaddingBottom || descriptionUnderField
              ? 0
              : "23px",
          ...style,
        }}
        disabled={readOnly}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={
              label && subLabel ? (
                <section style={{ lineHeight: 1.2, fontSize: "16px" }}>
                  {label}
                  <br />
                  <p style={{ fontSize: "10px", margin: 0 }}>{subLabel}</p>
                </section>
              ) : (
                label
              )
            }
            error={error}
          />
        )}
        getOptionLabel={(option) => option.label}
      />
      {error && helperText ? (
        <FormHelperText sx={{ color: "error.main", padding: "0 14px" }}>
          {helperText}
        </FormHelperText>
      ) : descriptionUnderField ? (
        <FormHelperText
          sx={{
            color: value ? "primary.main" : "grey.main",
            fontSize: "11px",
            padding: "0 14px",
          }}
        >
          {descriptionUnderField}
        </FormHelperText>
      ) : (
        ""
      )}
    </div>
  );
}
