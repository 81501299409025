import React, { useEffect, useState } from "react";
import Loading from "components/loading/LoadingBackground";
import SubMenu from "components/layout/SubMenu.js";
import { Avatar, Container } from "@mui/material";
import styles from "../TableTrade.module.css";
import { useCookies } from "react-cookie";
import AlertModal from "components/modal/AlertModal";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Save, UploadFile } from "@mui/icons-material";
import {
  getAllCountry,
  getAllZone,
  getDistrict,
  getProvince,
  getSubDistrict,
} from "utils/api/masterData/gis";
import FieldInput from "components/field/FieldInput";
import FieldUpload from "components/field/FieldUpload";
import FieldSelect from "components/field/FieldSelect";
import { editCustomer, getCustomerById } from "utils/api/trade/trade";

const DetailCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ open: false, desc: "" });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const [country, setListCountry] = useState([]);
  const [zone, setListZone] = useState([]);
  const [province, setListProvince] = useState([]);
  const [district, setListDistrict] = useState([]);
  const [subDistrict, setListSubDistrict] = useState([]);

  const country_id = watch("country_id");
  const zone_id = watch("zone_id");
  const province_id = watch("province_id");
  const district_id = watch("district_id");
  const subdistrict_id = watch("subdistrict_id");
  useEffect(() => {
    getCountry();
    getCustomer();
  }, []);

  useEffect(() => {
    if (country_id) {
      getZone();
    }
  }, [country_id]);
  useEffect(() => {
    if (zone_id) {
      getProvoinceByZone(zone_id);
    }
  }, [zone_id]);
  useEffect(() => {
    if (province_id) {
      getDistrictByProvince(province_id);
    }
  }, [province_id]);
  useEffect(() => {
    if (district_id) {
      getSubDistrictByDistrict(district_id);
    }
  }, [district_id]);

  useEffect(() => {
    if (subdistrict_id) {
      const postcode = subDistrict.find(
        (element) => element.value == subdistrict_id
      );
      setValue("postal_code", postcode?.postal_code);
    }
  }, [subdistrict_id, subDistrict]);

  const getCountry = async () => {
    await getAllCountry().then((res) => {
      const results = res.data.data.results;
      setListCountry(
        results?.map((res) => ({ value: res.id, name: res.name }))
      );
    });
  };
  const getZone = async () => {
    await getAllZone().then((res) => {
      const results = res.data.data.results;
      setListZone(results?.map((res) => ({ value: res.id, name: res.name })));
    });
  };
  const getProvoinceByZone = async (id) => {
    await getProvince(id).then((res) => {
      const results = res.data.data.results;
      setListProvince(
        results?.map((res) => ({ value: res.id, name: res.name }))
      );
    });
  };
  const getDistrictByProvince = async (id) => {
    await getDistrict(id).then((res) => {
      const results = res.data.data.results;
      setListDistrict(
        results?.map((res) => ({
          value: res.id,
          name: res.name,
        }))
      );
    });
  };
  const getSubDistrictByDistrict = async (id) => {
    await getSubDistrict(id).then((res) => {
      const results = res.data.data.results;
      setListSubDistrict(
        results?.map((res) => ({
          value: res.id,
          name: res.name,
          postal_code: res.postal_code,
        }))
      );
    });
  };

  const getCustomer = async () => {
    setLoading(true);
    await getCustomerById(id).then((res) => {
      const results = res.data.results;

      console.log("results", results);
      setValue("company_name", results.company_name);
      setValue("tax_id", results.tax_id);
      setValue("certificate_file", results.certificate_file);
      setValue("branch_code", results.branch_code);
      setValue("address", results.address);
      setValue("country_id", results.country_id);
      setValue("zone_id", results.zone_id);
      setValue("province_id", results.province_id);
      setValue("district_id", results.district_id);
      setValue("subdistrict_id", results.subdistrict_id);
      setLoading(false);
    });
  };
  const subMenu = [
    {
      label: "รายละเอียดลูกค้า",
      path: null,
      active: true,
    },
    {
      label: "ชื่อโครงการน้ำมัน",
      path: "/trade/customer/project",
    },
  ];
  const breadcrumbs = [
    {
      label: "รายชื่อผู้ซื้อ",
      path: null,
    },
    {
      label: "รายละเอียดลูกค้า",
      path: null,
    },
  ];

  const renderPage1 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          control={control}
          name="company_name"
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: "20px", width: "100%" }}>
              <FieldInput
                label="ชื่อบริษัท"
                onChange={onChange}
                value={value}
                error={!!errors.company_name}
                errorText={
                  errors.company_name ? errors.company_name.message : ""
                }
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="tax_id"
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="เลขประจําตัวผู้เสียภาษี"
                onChange={onChange}
                value={value}
                type="number"
                error={!!errors.tax_id}
                errorText={errors.tax_id ? errors.tax_id.message : ""}
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="certificate_file"
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldUpload
                name={"ไฟล์หนังสือรับรอง"}
                // accept="image/jpeg,image/png"
                icon={<UploadFile />}
                value={value} //คลิกเพื่อเปิด
                onChange={onChange}
                // url={dataDetail.image}
                style={{
                  width: "-webkit-fill-available",
                }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="branch_code"
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldInput
                label="รหัสสาขา"
                onChange={onChange}
                value={value}
                error={!!errors.branch_code}
                errorText={errors.branch_code ? errors.branch_code.message : ""}
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
      </div>
    );
  };
  const renderPage2 = () => {
    return (
      <div className={styles.cardDetail}>
        <Controller
          control={control}
          name="address"
          render={({ field: { onChange, value } }) => (
            <div style={{ paddingTop: "20px", width: "100%" }}>
              <FieldInput
                label="ที่อยู่"
                onChange={onChange}
                value={value}
                error={!!errors.address}
                errorText={errors.address ? errors.address.message : ""}
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="country_id"
          render={({ field: { onChange, value } }) => (
            <div style={{ width: "100%" }}>
              <FieldSelect
                name="ประเทศ"
                onChange={onChange}
                options={country ?? []}
                value={value}
                style={{ width: "100%" }}
              />
            </div>
          )}
        />
        {country_id && (
          <Controller
            control={control}
            name="zone_id"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: "100%" }}>
                <FieldSelect
                  name="ภูมิภาค"
                  onChange={onChange}
                  options={zone ?? []}
                  value={value}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          />
        )}
        {zone_id && (
          <Controller
            control={control}
            name="province_id"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: "100%" }}>
                <FieldSelect
                  name="จังหวัด"
                  onChange={onChange}
                  options={province ?? []}
                  value={value}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          />
        )}
        {province_id && (
          <Controller
            control={control}
            name="district_id"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: "100%" }}>
                <FieldSelect
                  name="อําเภอ"
                  onChange={onChange}
                  options={district ?? []}
                  value={value}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          />
        )}
        {district_id && (
          <Controller
            control={control}
            name="subdistrict_id"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: "100%" }}>
                <FieldSelect
                  name="ตําบล"
                  onChange={onChange}
                  options={subDistrict ?? []}
                  value={value}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          />
        )}
        {subdistrict_id && (
          <Controller
            control={control}
            name="postal_code"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: "100%" }}>
                <FieldInput
                  label="รหัสไปรษณีย์"
                  // onChange={onChange}
                  readOnly={true}
                  value={value}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          />
        )}
      </div>
    );
  };

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    const GForm = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        GForm.delete(key);
      } else {
        GForm.append(key, data[key]);
      }
    });
    GForm.delete("postal_code");
    await editCustomer(id, GForm)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          getCustomer(id);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading && <Loading />}
      <AlertModal
        open={alertModal}
        title="บันทึก"
        description="บันทึกข้อมูลสำเร็จ"
        onClose={() => {
          setAlertModal(false);
          window.location.reload();
        }}
      />
      <AlertModal
        open={errorAlert.open}
        title="เกิดข้อผิดพลาด"
        description={errorAlert.desc}
        onClose={() => {
          setErrorAlert({
            open: false,
            desc: "",
          });
          navigate(-1);
        }}
      />
      <Container>
        <SubMenu menus={subMenu} breadcrumbs={breadcrumbs} pathbackward={-1} />
      </Container>
      <form
        className={styles.containerDetail}
        onSubmit={handleSubmit(onSubmit)}
      >
        {renderPage1()}
        {renderPage2()}
        <Avatar
          alt="Avatar Save"
          sx={{
            position: "fixed",
            bottom: "35px",
            right: "97px",
            width: 100,
            height: 100,
            bgcolor: "secondary.main",
            cursor: "pointer",
            zIndex: 200,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          <Save sx={{ color: "white", fontSize: "70px" }} />
        </Avatar>
      </form>
    </React.Fragment>
  );
};

export default DetailCustomer;
