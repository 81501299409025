import instance from "../../axiosInstance";
import helper from "utils/helper";

export const getAllWemovedriveVersion = async (params = {}) => {
  try {
    return await instance.get(`/api/master/app-version/wemovedrive-version`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getWemovedriveVersion = async (id = null) => {
  try {
    return await instance.get(
      `/api/master/app-version/wemovedrive-version/${id}`
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createWemovedriveVersion = async (body = {}) => {
  try {
    return await instance.post(
      `/api/master/app-version/wemovedrive-version`,
      body
    );
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getAllWemoveVersion = async (params = {}) => {
  try {
    return await instance.get(`/api/master/app-version/wemove-version`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const getWemoveVersion = async (id = null) => {
  try {
    return await instance.get(`/api/master/app-version/wemove-version/${id}`);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};

export const createWemoveVersion = async (body = {}) => {
  try {
    return await instance.post(`/api/master/app-version/wemove-version`, body);
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
