const theme = {
  token: {
    fontFamily: "Kanit, sans-serif",
    colorPrimary: "#121F43",
    colorInfo: "#121F43",
  },
  components: {
    Radio: {
      colorPrimary: "#121F43",
    },
    Checkbox: {
      colorPrimary: "#121F43",
    },
    DatePicker: {
      // colorPrimary: "#121f43",
      // backgroundColor: "#121f43", // Example background color
    },
    TreeSelect: {
      colorPrimary: "#121F43",
      colorTextBase: "#121F43",
      colorPrimaryHover: "#121F43",
      // colorTextPlaceholder: "#8CA3BA",
    },
    Pagination: {
      colorPrimary: "#121F43",
      colorPrimaryHover: "rgba(18, 31, 67, 0.4)",
    },
  },
};

export default theme;
