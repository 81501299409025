import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/loading/LoadingBackground";
import { Modal, Typography } from "@mui/material";
import styles from "../TableAllProjectInProfile.module.css";
import CloseIcon from "@mui/icons-material/Close";
import ButtonCustom from "components/ButtonCustom";
import { useForm, Controller } from "react-hook-form";
import FieldSelect from "components/field/FieldSelect";
import { AdminRole, PaymentType, ReminderDate } from "utils/enum";
import { addCarrierInProject } from "utils/api/project/project";
import { useCookies } from "react-cookie";
import { findAdminByRole, addProjectAdmin } from "utils/api/project/project";

const ModalAddProjectAdmin = (props) => {
  const { project_id } = useParams();
  const [, , removeAccessToken] = useCookies(["access_token"]);
  const [loading, setLoading] = useState(false);
  const [userCode, setUserCode] = useState("");
  // const [adminRoleSelected, setAdminRoleSelected] = useState(null);
  const [adminOptions, setAdminOptions] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useForm();
  const adminRoleSelected = watch("admin_role");

  useEffect(() => {
    getAdminName();
  }, [adminRoleSelected]);

  const getAdminName = async () => {
    if (adminRoleSelected !== undefined) {
      const param = { role: adminRoleSelected };
      await findAdminByRole(param)
        .then((response) => {
          if (response.data.code === 1000) {
            const rest = response.data.data;
            console.log("rest", rest);
            setAdminOptions(
              rest?.map((res) => ({
                value: res?.id,
                name: `${res?.firstname} ${res?.lastname}`,
              }))
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    delete data.admin_role;
    await addProjectAdmin(project_id, data)
      .then((response) => {
        console.log(response);
        if (response.data.code === 1000) {
          props.handleClose();
          setLoading(false);
          props.alertSuccess();
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        if (error) {
          setLoading(false);
          if (error.response.data.code === 4106) {
            props.alertError({
              open: true,
              desc: error.response.data.description,
            });
          } else if (error.response.data.code === "4105") {
            removeAccessToken("access_token");
            window.location.reload();
          }
        }
      });
  };

  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Modal
        open={props?.open}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? props?.handleClose : ""
        }
        sx={{ zIndex: 1000 }}
      >
        <div className={styles.modalStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              sx={{ color: "grey.main" }}
              onClick={props?.handleClose}
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <form>
              <div className={styles.titleCard}>
                <Typography fontSize={16} color={"grey.main"} fontWeight={500}>
                  เพิ่มผู้รับผิดชอบในโครงการ
                </Typography>
              </div>
              <Controller
                name="admin_role"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div style={{ paddingTop: "20px" }}>
                    <FieldSelect
                      name="บทบาทผู้ดูแลระบบ*"
                      options={AdminRole}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
              <Controller
                name="admin_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div>
                    <FieldSelect
                      name="ชื่อผู้ดูแลระบบ*"
                      options={adminOptions}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                )}
              />
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name="ย้อนกลับ"
                  onClick={() => {
                    props.handleClose();
                  }}
                  type={"cancel"}
                />
              </div>

              <div style={{ flex: 0.5 }}>
                <ButtonCustom
                  name={"บันทึก"}
                  onClick={handleSubmit(onSubmit)}
                  fullWidth={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAddProjectAdmin;
