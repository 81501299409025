import React from "react";
import { Modal, Button, Typography, Box, IconButton } from "@mui/material";
import helper from "utils/helper";

const FormModal = ({
  open = false,
  title = "",
  onConfirm = () => {},
  onCancel = () => {},
  cancelTextBtn = "ยกเลิก",
  confirmTextBtn = "ยินยัน",
  disabledCancelBtn = false,
  disabledComfirmBtn = false,
  children,
  setIsModalOpen = () => {},
  setDisableConfirm = () => {},
  style = {},
  className = "",
}) => {
  const handleConfirm = () => {
    setDisableConfirm(true);
    onConfirm();
  };

  const handleCancel = () => {
    if (!helper.IsEmptyFunction(onCancel)) {
      onCancel();
    } else {
      setIsModalOpen(false);
    }
  };

  const renderXBtn = () => (
    <IconButton
      onClick={handleCancel}
      // onClick={() => {
      //   setIsModalOpen(false);
      // }}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M8.73655 7.5L14.9548 0.30138C15.059 0.181749 14.9714 0 14.8103 0H12.92C12.8086 0 12.702 0.0483128 12.6286 0.131135L7.5 6.06902L2.37142 0.131135C2.30035 0.0483128 2.19375 0 2.08005 0H0.189699C0.0286162 0 -0.0590318 0.181749 0.0451981 0.30138L6.26346 7.5L0.0451981 14.6986C0.0218496 14.7253 0.00687072 14.7579 0.00203912 14.7926C-0.00279249 14.8273 0.00272616 14.8626 0.0179404 14.8943C0.0331547 14.926 0.0574256 14.9529 0.0878709 14.9716C0.118316 14.9903 0.153657 15.0002 0.189699 15H2.08005C2.19139 15 2.29798 14.9517 2.37142 14.8689L7.5 8.93098L12.6286 14.8689C12.6996 14.9517 12.8062 15 12.92 15H14.8103C14.9714 15 15.059 14.8182 14.9548 14.6986L8.73655 7.5Z"
          fill="#8CA3BA"
        />
      </svg>
    </IconButton>
  );

  return (
    <Modal open={open}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          minWidth: "00px",
          borderRadius: "20px",
          background: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(140, 163, 186, 0.20)",
          padding: "25px",
          ...style,
        }}
        className={className}
      >
        {renderXBtn()}
        <Box>
          {title !== "" && (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: "#8CA3BA",
                  textAlign: "center",
                  fontFamily: "Kanit",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {title}
              </Typography>
              <hr />
            </>
          )}
        </Box>
        <div
          style={{
            overflowY: "auto",
            height: "72vh",
            padding: "10px 0px",
          }}
        >
          <Box>{children}</Box>
        </div>
        <Box className={`text-center`}>
          <Button
            variant="outlined"
            color="secondary"
            style={{
              width: "160px",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #8CA3BA",
            }}
            onClick={handleCancel}
            disabled={disabledCancelBtn}
          >
            {cancelTextBtn}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            style={{
              width: "160px",
              height: "50px",
              borderRadius: "10px",
              border: "1px solid #8CA3BA",
              marginLeft: "15px",
            }}
            disabled={disabledComfirmBtn}
          >
            {confirmTextBtn}
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default FormModal;
