import Line from "components/layout/line";
import React, { useEffect } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { Button, TextField } from "@mui/material";
import ModalWork from "./modalWork";
import LoadingBackground from "components/loading/LoadingBackground";
import API from "../../utils/api/work/work";
import Swal from "sweetalert2";
import "./rateUser.css";
const api = API.create();
const labels: { [index: string]: string } = {
  0: "ความพึงพอใจ",
  0.5: "ปรับปรุงอย่างมาก",
  1: "ปรับปรุง",
  1.5: "ปรับปรุงเล็กน้อย",
  2: "พอใช้",
  2.5: "พอใช้",
  3: "พอใช้",
  3.5: "ดี",
  4: "ดีมาก",
  4.5: "ค่อนข้างพึงพอใจ",
  5: "พึงพอใจมาก",
};
function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function RateUser(props) {
  const {
    type = "shipper",
    user_id,
    work_id,
    rate,
    refresh,
    is_success,
  } = props;

  const [value, setValue] = React.useState(rate?.score);
  const [hover, setHover] = React.useState(0);
  const [remark, setRemark] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  console.log("rate user", props);
  useEffect(() => {
    if (rate?.score) {
      setRemark(rate.remark);
      console.log(rate, "rate");
      //   setValue(rate.score);
    }
  }, [rate]);
  const save = async () => {
    if (!is_success) {
      Swal.fire({
        timer: 2000,
        title: "ขออภัย",
        text: "สามารถให้คะแนนได้เมื่องานเสร็จสิ้นเท่านั้น",
        icon: "error",
        showConfirmButton: false,
      });
      setShowModal(false);
    } else {
      let body = {
        type: type,
        rate: value,
        user_id: user_id,
        remark: remark,
      };
      console.log("save", body);
      await api
        .rateUser(work_id, body)
        .then((response) => {
          console.log("res", response);
          refresh();
        })
        .catch((error) => {
          if (error.response?.data)
            Swal.fire({
              timer: 2000,
              title: "error: " + error.response.data.code,
              text: error.response.data.description ?? "",
              icon: "error",
              showConfirmButton: false,
            });
          else
            Swal.fire({
              timer: 2000,
              title: "เกิดข้อผิดพลาด",
              text: "กรุณาลองใหม่ภายหลัง",
              icon: "error",
              showConfirmButton: false,
            });
        });
      setShowModal(false);
    }
  };
  return (
    <div style={{}}>
      <div style={{ textAlign: "center", padding: "20px 0px 10px 0px" }}>
        {type === "shipper"
          ? "ผู้ขนส่งให้คะแนนลูกค้า"
          : "ลูกค้าให้คะแนนผู้ขนส่งและคนขับ"}
      </div>
      <Line />
      <Box
        sx={{
          //   width: 200,
          display: "flex",
          alignItems: "center",
          width: "100%",
          padding: "20px 0px 0px 0px",
        }}
        className={"rate"}
      >
        <Rating
          name="hover-feedback"
          value={value}
          //   defaultValue={rate?.score}
          precision={0.5}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
          size={"large"}
          disabled={rate?.score}
        />
        {value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>
      <TextField
        fullWidth
        id="standard-multiline-static"
        label="หมายเหตุ"
        value={remark}
        multiline
        onChange={(val) => {
          setRemark(val.target.value);
        }}
        disabled={rate?.score}
        rows={4}
        // defaultValue="Default Value"
        variant="standard"
      />
      {rate.score ? null : (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          style={{ borderRadius: "10px", marginTop: 20 }}
          sx={{ height: 50 }}
          color="secondary"
          onClick={() => {
            // editWork();
            setShowModal(true);
          }}
        >
          <div>
            บันทึก
            <div style={{ fontSize: 10 }}>(บันทึกได้ครั้งเดียว)</div>
          </div>
        </Button>
      )}
      <ModalWork open={showModal}>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ textAlign: "center" }}>
            <h3 style={{ color: "#121f43" }}>บันทึก</h3>
            <label>ยืนยันการบันทึก?</label>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Button
                type="submit"
                fullWidth
                variant="outline"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  color: "#8ca3ba",
                  borderColor: "#8ca3ba",
                  height: 50,
                }}
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                ยกเลิก
              </Button>
            </div>
            <div style={{ flex: 1, marginLeft: 20 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                style={{ borderRadius: "10px", marginTop: 20 }}
                sx={{ height: 50 }}
                color="secondary"
                onClick={() => {
                  save();
                }}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </ModalWork>
    </div>
  );
}
